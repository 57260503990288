import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import OrderHistoryListContainer from './OrderHistoryListContainer';
import * as actions from './actions';
import OrderDetailsContainer from '../OrderDetails/OrderDetailsContainer';
const OrderHistoryContainer = ({
  updatedRequestedParams,
  currentPage,
  updateCurrentPage,
  updateOrderId,
  selectedOrderId,
  history,
}) => {
  useEffect(() => {
    return () => {
      updatedRequestedParams({
        page_number: 1,
        page_size: '25',
        sorting_option: 'title-asc',
        search_string: '',
        has_next_page: false,
      });
    };
  }, []);
  if (currentPage === 'list') {
    return (
      <OrderHistoryListContainer
        setCurrentPage={updateCurrentPage}
        updateOrderId={updateOrderId}
        orderId={selectedOrderId}
        // {...props}
      />
    );
  } else if (currentPage === 'detail') {
    return (
      <OrderDetailsContainer
        orderId={selectedOrderId}
        currentPage={currentPage}
        setCurrentPage={updateCurrentPage}
        history={history}
      />
    );
  }
};

const mapStateToProps = (state) => ({
  currentPage: state.orderHistory.currentPage,
  requestedParams: state.orderHistory.requestedParams,
  selectedOrderId: state.orderHistory.selectedOrderId,
  customers: state.customerListReducer.customers,
});
export default connect(mapStateToProps, actions)(OrderHistoryContainer);
