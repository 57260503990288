import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled, { withTheme } from 'styled-components';
import { ErrorMessage } from 'merchant-portal-components';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { TextField, FormControl, makeStyles } from '@material-ui/core';
import { string } from 'prop-types';

const filter = createFilterOptions();

const CategorySelectionWrapper = styled.div`
  margin-bottom: ${(props) => (props.inLine ? '12px' : '')};
`;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minWidth: '100%',
  },
  autocompleteRoot: (props) => ({
    backgroundColor: props.theme.white,
  }),
}));

const CustomCreatableSelect = ({
  error,
  label,
  placeholder,
  inLine,
  touched,
  whiteBackground,
  small,
  topMod,
  pagination,
  onChange,
  isMulti,
  getOptionValue,
  getOptionLabel,
  value = null,
  isClearable,
  isSearchable = true,
  isDisabled,
  options,
  noOptionsMessage,
  onInputChange,
  onBlur,
  theme,
  setTouched,
  name,
  createbleMessage = 'Search for "(value)"',
  numOfChars = 3,
  ...rest
}) => {
  value = !isMulti && Array.isArray(value) ? value[0] : value;

  const [internalValue, setInternalValue] = useState(isMulti ? [] : '');

  useEffect(() => {
    setInternalValue(value || (isMulti ? [] : ''));
  }, [value]);

  const classes = useStyles({ small, theme });
  const hasError = touched && error && error != '';
  const events = Object.keys(rest)
    .filter((key) => key.startsWith('on'))
    .reduce((acc, key) => {
      acc[key] = rest[key];
      return acc;
    }, {});

  const onInputChangeModified = (e, inputValue) =>
    onInputChange && onInputChange(inputValue);
  const resolveOptionLabel = (option) => {
    if (typeof option === 'string') return option;
    if (option?.inputValue) return option?.inputValue;
    return !option
      ? ''
      : getOptionLabel
      ? getOptionLabel(option)
      : option?.label || option?.name;
  };
  const renderOption = (option) => {
    return !option
      ? ''
      : getOptionLabel
      ? getOptionLabel(option)
      : option?.label || option?.name;
  };
  const onValueChange = (selectedValue) => {
    setInternalValue(selectedValue);
    onChange && onChange(selectedValue);
  };
  const onBlurModified = () => {
    setTouched && name && setTouched(_.set(touched, name, true));
    onBlur && onBlur();
  };

  return (
    <CategorySelectionWrapper pagination={pagination} inLine={inLine}>
      <FormControl variant='outlined' className={classes.wrapper}>
        <Autocomplete
          {...events}
          classes={{ root: classes.autocompleteRoot }}
          disableClearable={!isClearable}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={label}
                error={hasError}
                placeholder={placeholder}
                variant='outlined'
              />
            );
          }}
          getOptionLabel={resolveOptionLabel}
          value={internalValue}
          onBlur={onBlurModified}
          multiple={isMulti}
          onChange={(e, selectedValue) => onValueChange(selectedValue)}
          onInputChange={onInputChangeModified}
          options={options}
          renderOption={renderOption}
          getOptionDisabled={(option) => option?.isDisabled}
          getOptionSelected={(option, value) => _.isEqual(option, value)}
          noOptionsText={noOptionsMessage}
          disabled={new Boolean(isDisabled).valueOf()}
          forcePopupIcon={true}
          freeSolo
          selectOnFocus
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue && params.inputValue.length >= numOfChars) {
              filtered.push({
                inputValue: params.inputValue,
                label: createbleMessage.replace('(value)', params.inputValue),
              });
            }
            return filtered;
          }}
        />
        {hasError && <ErrorMessage>{error}</ErrorMessage>}
      </FormControl>
    </CategorySelectionWrapper>
  );
};

export default withTheme(CustomCreatableSelect);
