import React, { Fragment, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { Collapse } from '@material-ui/core';
import { RenderSelect } from '../../../../../../components/FormElements/FormikElements';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button, ButtonRow } from 'merchant-portal-components';
import { EditIcon } from 'merchant-portal-components';
import ConfirmCancelModal from '../../components/ConfirmCancelModal';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
import FilterButton from '../../../../../../components/FilterModal/FilterButton';
import LocationFilterModal from '../../../../../../components/FilterModal/LocationFilterModal';
const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;
const AvailableBusinessWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  padding: 0;
  gap: 50px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ProductTagsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-top: 40px;

  .react-select__control {
    background-color: white;
    border-radius: 6px;
  }

  .react-select__single-value {
    font-weight: 600;
    font-size: 14px;
  }

  .react-select__multi-value {
    background-color: #e6e6e6;
    border-radius: 15px;
    padding: 1px 3px;
    font-weight: 400;
    font-size: 14px;
  }
`;

let ProductBusinessSelectionFormComponent = ({
  disabled,
  isExpanded,
  setIsExpanded,
  initialValues,
  allBusinesses,
  setConfirmCancelModalIsVisible,
  confirmCancelModalIsVisible,
  putProductBusinesses,
  isAvailableAllLocations,
  setIsAvailableAllLocations,
  allInitialBusinesses,
  productId,
}) => {
  const [permittedInitialValues, setPermittedInitialValues] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const updateInitialValue = useCallback(() => {
    // filter is because of user (roles) that have a limited access to locations.
    const locationListIDs = allBusinesses.map((item) => item.business_id);
    const permittedInitialValue = { ...initialValues };
    const permittedFilteredLocations =
      permittedInitialValue.productSelectedBusinesses.filter((item) =>
        locationListIDs.includes(item)
      );
    permittedInitialValue.productSelectedBusinesses =
      permittedFilteredLocations;
    setPermittedInitialValues(permittedInitialValue);
  }, [initialValues]);
  useEffect(() => {
    updateInitialValue();
  }, [updateInitialValue]);
  return (
    <Card
      disabled={disabled}
      onClick={() => !disabled && setIsExpanded(!isExpanded)}
    >
      <Formik initialValues={permittedInitialValues} enableReinitialize={true}>
        {({ dirty, values, setValues, setFieldValue }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title>Assign Businesses - Optional</Title>
              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={disabled}
                  onClick={() => setIsExpanded(true)}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => setIsExpanded(false)}
                />
              )}
            </TitleAndCollapseButton>
            <Collapse in={isExpanded || dirty}>
              <AvailableBusinessWrapper>
                <div>
                  <SmallToggleSwitch
                    checked={
                      allBusinesses.length ===
                      values.productSelectedBusinesses?.length
                    }
                    label='All Locations'
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFieldValue(
                          'productSelectedBusinesses',
                          allBusinesses.map((item) => item.business_id)
                        );
                      } else {
                        setIsOpen(true);
                      }
                    }}
                  />
                </div>
                <div>
                  <FilterButton
                    message={
                      allBusinesses?.length ===
                      values.productSelectedBusinesses?.length
                        ? 'All Locations Selected'
                        : `Selected Locations (${
                            values.productSelectedBusinesses?.length || 0
                          })`
                    }
                    height='55px'
                    setIsOpen={setIsOpen}
                  />
                  {isOpen && (
                    <LocationFilterModal
                      placeholder='Enter Internal Location Name'
                      searchLabel='Search by Internal Location Name'
                      filteredLocations={values.productSelectedBusinesses}
                      setFilteredLocations={setFieldValue}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      formField='productSelectedBusinesses'
                      emptyIsEqualToAll={false}
                      isEmptyAccepted={false}
                    />
                  )}
                </div>
              </AvailableBusinessWrapper>
              <ButtonRow
                justifyContent='space-between'
                style={{
                  marginTop: '24px',
                }}
              >
                <Button
                  secondary
                  onClick={(e) => {
                    e.stopPropagation();
                    dirty
                      ? setConfirmCancelModalIsVisible(true)
                      : setIsExpanded(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!dirty}
                  onClick={(e) => {
                    e.stopPropagation();
                    putProductBusinesses(
                      values.productSelectedBusinesses.map(
                        (productBusinessId) => {
                          const foundBusiness = allBusinesses.find(
                            (business) =>
                              business.business_id === productBusinessId
                          );
                          return {
                            business_id: foundBusiness.business_id,
                            is_active: foundBusiness.is_active,
                            is_available: foundBusiness.is_available,
                          };
                        }
                      ),
                      () => {
                        setIsExpanded(false);
                      }
                    );
                  }}
                >
                  Save
                </Button>
              </ButtonRow>
              {confirmCancelModalIsVisible && (
                <ConfirmCancelModal
                  onCancel={() => setConfirmCancelModalIsVisible(false)}
                  onConfirm={() => {
                    setValues(permittedInitialValues);
                    setConfirmCancelModalIsVisible(false);
                    setIsExpanded(false);
                  }}
                />
              )}
            </Collapse>
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};

export default ProductBusinessSelectionFormComponent;
