import React from 'react';
import { Icon } from 'merchant-portal-components';

const EditIcon = (props) => (
  <Icon
    className={`${props.type ? props.type : 'fal'} fa-pencil ${
      props.size ? props.size : ''
    }`}
    {...props}
  />
);

export default EditIcon;
