import React, { useState } from "react";
import SpecialDayTimeSelectionModal from "./SpecialDayTimeSelectionModal";
import SpecialHoursList from "./SpecialHoursList";
import {
  Wrapper,
  AddSpecialHoursButton,
} from "./styles/specialHoursContainerStyles";
import SpecialDayTimeCreateModal from "./SpecialDayTimeCreateModal";
const SpecialHoursContainer = ({
  businessId,
  specialHoursForm,
  setSpecialHoursForm,
  formatedSpecialHours,
  patchSpecialHour,
  deleteSelectedSpecialHours,
  postSpecialHour,
}) => {
  const [isCreateSpecialHoursModalOpen, setIsCreateSpecialHoursModalOpen] =
    useState(false);
  return (
    <Wrapper>
      {formatedSpecialHours?.length > 0 ? (
        <SpecialHoursList
          formatedSpecialHours={formatedSpecialHours}
          specialHoursForm={specialHoursForm}
          setSpecialHoursForm={setSpecialHoursForm}
          businessId={businessId}
          isCreateSpecialHoursModalOpen={isCreateSpecialHoursModalOpen}
          setIsCreateSpecialHoursModalOpen={setIsCreateSpecialHoursModalOpen}
          postSpecialHour={postSpecialHour}
          patchSpecialHour={patchSpecialHour}
          deleteSelectedSpecialHours={deleteSelectedSpecialHours}
        />
      ) : (
        <AddSpecialHoursButton
          onClick={() => setIsCreateSpecialHoursModalOpen(true)}
        >
          {"+ Add Special Hours"}
        </AddSpecialHoursButton>
      )}
      {isCreateSpecialHoursModalOpen && (
        <SpecialDayTimeCreateModal
          isOpen={isCreateSpecialHoursModalOpen}
          setIsOpen={setIsCreateSpecialHoursModalOpen}
          postSpecialHour={postSpecialHour}
          businessId={businessId}
        />
      )}
    </Wrapper>
  );
};

export default SpecialHoursContainer;
