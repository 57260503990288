import React from 'react';
import Modal from '../../../../../components/Modal/Modal';
import { Button } from '../../../../../components/Button/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';

import {
  ModalWrapper,
  ModalTitle,
  ButtonRowWrapper,
  CancelButton,
} from '../styles/styles';

const RefundOrderModal = (props) => {
  const {
    customerName,
    isOrderCancelBoxChecked,
    requestingCancelOrderDetails,
    handleVisibilityCancelOrderModal,
    handleIsOrderCancelBoxChecked,
    handleOrderCancel,
  } = props;
  return (
    <Modal fullWidth maxWidth='sm'>
      <ModalWrapper>
        <ModalTitle>Refund Order to Customer?</ModalTitle>
        <FormControlLabel
          control={
            <Checkbox
              checked={isOrderCancelBoxChecked}
              onChange={() => handleIsOrderCancelBoxChecked()}
              name='checkedB'
              color='primary'
            />
          }
          label='I understand this action cannot be undone'
        />

        <ButtonRowWrapper>
          <div>
            <CancelButton onClick={() => handleVisibilityCancelOrderModal()}>
              Cancel
            </CancelButton>
          </div>
          <Button
            disabled={!isOrderCancelBoxChecked || requestingCancelOrderDetails}
            onClick={() => handleOrderCancel()}
          >
            {requestingCancelOrderDetails ? 'Loading...' : 'Yes, Refund Order'}
          </Button>
        </ButtonRowWrapper>
      </ModalWrapper>
    </Modal>
  );
};

export default RefundOrderModal;
