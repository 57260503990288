import {
  GET_APP_FEED_REQUEST,
  GET_APP_FEED_SUCCESS,
  CREATE_APP_FEED_REQUEST,
  CREATE_APP_FEED_SUCCESS,
  CREATE_APP_FEED_FAILURE,
  OPEN_CREATE_FEED_ROW_FORM,
  CANCEL_CREATE_FEED_ROW_FORM,
  SET_SORT_APP_FEED,
  UNDO_APP_FEED_CHANGES,
  OPEN_EDIT_FEED_ROW_FORM,
  CANCEL_EDIT_FEED_ROW_FORM,
  UPDATE_FEED_ROW,
  DELETE_FEED_ROW,
  OPEN_CREATE_CARD_FORM,
  CLOSE_CREATE_CARD_FORM,
  CREATE_CARD,
  SET_SORT_CARDS,
  OPEN_EDIT_CARD_FORM,
  CLOSE_EDIT_CARD_FORM,
  LIVE_EDIT_CARD,
  EDIT_CARD,
  DELETE_CARD,
  HIDE_FEED_ROW_FORM,
  RESET_APP_FEED_REDUCER_STATE,
} from './appFeedTypes';

import moment from 'moment';
const defaultState = {
  feedRows: [],
  feedRowsOriginalOrder: [],
  feedStyles: {
    corner_radius: '0',
    border_width: '0',
    border_color: 'white',
    background_to_cards: null,
    font_color: null,
    fonts_for_fields: null,
    image_overlay: null,
    shadow_params: null,
  },
  feedRowFormIsVisible: false,
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_APP_FEED_SUCCESS:
      let sortedItems = action.payload.data.map((row) =>
        row.items.sort(function (a, b) {
          return a.position - b.position;
        })
      );
      let sortedRows = action.payload.data
        .map((row, index) => (row = { ...row, items: sortedItems[index] }))
        .sort((a, b) => a.position - b.position);
      return {
        ...state,
        feedRows: sortedRows,
        feedRowsOriginalOrder: sortedRows,
        feedRowFormIsVisible: false,
      };

    case CREATE_APP_FEED_REQUEST:
      return {
        ...state,
        requestingCreateAppFeed: true,
      };
    case CREATE_APP_FEED_SUCCESS:
      return {
        ...state,
        requestingCreateAppFeed: false,
        feedRowsOriginalOrder: action.appFeed,
        error: false,
      };
    case CREATE_APP_FEED_FAILURE:
      return {
        ...state,
        requestingCreateAppFeed: false,
        error: action.error,
      };
    case OPEN_CREATE_FEED_ROW_FORM:
      return {
        ...state,
        feedRows: [...state.feedRows, action.initialFeedRow],
        feedRowFormIsVisible: true,
        createFeedRowInitialValues: action.initialFeedRow,
      };
    case CANCEL_CREATE_FEED_ROW_FORM:
      return {
        ...state,
        feedRowFormIsVisible: false,
        feedRowIsEditing: false,
        feedRowBeingEdited: false,
        feedRows: state.feedRows.filter(
          (feedRow) => feedRow.id != action.feedRowId
        ),
        createFeedRowInitialValues: null,
      };
    case SET_SORT_APP_FEED:
      return {
        ...state,
        feedRows: action.newSortedAppFeed,
      };
    case UNDO_APP_FEED_CHANGES:
      return {
        ...state,
        feedRows: state.feedRowsOriginalOrder,
      };
    case OPEN_EDIT_FEED_ROW_FORM:
      return {
        ...state,
        feedRowIsEditing: true,
        feedRowBeingEdited: action.feedRow,
        feedRowFormIsVisible: true,
      };
    case CANCEL_EDIT_FEED_ROW_FORM:
      return {
        ...state,
        feedRowIsEditing: false,
        feedRowBeingEdited: null,
        feedRowFormIsVisible: false,
      };
    case UPDATE_FEED_ROW:
      const newFeedRows = state.feedRows.map((feedRow) => {
        if (feedRow.id !== action.feedRow.id) {
          return feedRow;
        }
        return {
          ...action.feedRow,
          start_date: moment(action.feedRow.start_date).format('YYYY-MM-DD'),
          end_date: moment(action.feedRow.end_date).format('YYYY-MM-DD'),
        };
      });
      return {
        ...state,
        feedRows: newFeedRows,
        createFeedRowInitialValues: null,
      };
    case DELETE_FEED_ROW:
      return {
        ...state,
        feedRows: state.feedRows
          .filter((feedRow) => feedRow.id != action.feedRow.id)
          .map((row, index) => ({ ...row, position: index + 1 })),
      };
    case OPEN_CREATE_CARD_FORM:
      return {
        ...state,
        showCreateCardForm: true,
      };
    case CLOSE_CREATE_CARD_FORM:
      return {
        ...state,
        showCreateCardForm: false,
      };
    case CREATE_CARD:
      return {
        ...state,
        showCreateCardForm: false,
      };
    case SET_SORT_CARDS:
      return {
        ...state,
        feedRows: state.feedRows.map((feedRow) => {
          if (feedRow.id !== action.newSortedCards[0].feed_id) {
            return feedRow;
          }
          return {
            ...feedRow,
            items: action.newSortedCards,
          };
        }),
      };
    case OPEN_EDIT_CARD_FORM:
      return {
        ...state,
        editingCard: action.card,
      };
    case CLOSE_EDIT_CARD_FORM:
      return {
        ...state,
        editingCard: false,
        feedRows: state.feedRows.map((feedRow) => {
          if (feedRow.id !== action.originalCard.feed_id) {
            return feedRow;
          }
          return {
            ...feedRow,
            items: feedRow.items.map((item) => {
              if (item.id !== action.originalCard.id) {
                return item;
              }
              return action.originalCard;
            }),
          };
        }),
      };
    case LIVE_EDIT_CARD:
      return {
        ...state,
        feedRows: state.feedRows.map((feedRow) => {
          if (feedRow.id !== action.card.feed_id) {
            return feedRow;
          }
          return {
            ...feedRow,
            items: feedRow.items.map((item) => {
              if (item.id !== action.card.id) {
                return item;
              }
              return action.card;
            }),
          };
        }),
      };
    case EDIT_CARD:
      return {
        ...state,
        editingCard: false,
      };
    case DELETE_CARD:
      return {
        ...state,
        feedRows: state.feedRows.map((feedRow) => {
          if (feedRow.id !== action.card.feed_id) {
            return feedRow;
          }
          return {
            ...feedRow,
            items: feedRow.items.filter((item) => item.id != action.card.id),
          };
        }),
      };
    case HIDE_FEED_ROW_FORM:
      return {
        ...state,
        feedRowFormIsVisible: false,
        feedRowIsEditing: false,
        feedRowBeingEdited: null,
      };
    case RESET_APP_FEED_REDUCER_STATE:
      return defaultState;
    default:
      return state;
  }
}
