import api from '../../api';
import { createAlert } from '../Alert/actions';
import { formatErrorMessage } from '../../utils/formatErrorMessage';

export function updateOrderStatus(orderId, orderStatus, message, delay = 0) {
  return function (dispatch) {
    return api
      .post(`/orders/${orderId}/prep-status`, {
        status: orderStatus,
        status_change_message: message,
        delay,
      })
      .then(() => {
        if (orderStatus === -1) {
          api.post(`/orders/${orderId}/refund`).then(() => {
            dispatch(
              createAlert({
                type: 'success',
                message: 'Order status updated succesfully',
              })
            );
          });
        } else {
          dispatch(
            createAlert({
              type: 'success',
              message: 'Order status updated succesfully',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: formatErrorMessage(error) ||
              "You don't have permission to update the status for this location.",
            dangerouslySetInnerHTML: true,
          })
        );
      });
  };
}
