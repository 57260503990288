import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Wrapper } from 'merchant-portal-components';
import CustomerOfferCreditTable from './CustomerOfferCreditTable';
import CustomerOfferCreditBalanceTable from './CustomerOfferCreditBalanceTable';

const Title = styled.div`
  font-weight: 600;
  margin: 0 45px 45px 0;
  font-size: 20px;
`;

const NotFaundWrapper = styled.div`
  text-align: center;
  font-weight: 500;
`;

const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
  margin: 50px 37px;
`;

const CustomerOfferCreditInfo = (props) => {
  const {
    offerCredits,
    offerCreditBalance
  } = props;
  return (
    <Fragment>
      <Wrapper>
        <Title data-test='title'>Customer Credit Codes Balance</Title>
        {offerCreditBalance ? (
          <>
            <CustomerOfferCreditTable
              offerCredits={offerCreditBalance}
            />
          </>
        ) : (
          <NotFaundWrapper>No Record Found</NotFaundWrapper>
        )}
      </Wrapper>
      <HorizontalLine />
      <Wrapper>
        <Title data-test='title'>Customer Offer Credit Balance</Title>
        {offerCredits ? (
          <>
            <CustomerOfferCreditBalanceTable
              offerCreditBalance={offerCredits}
            />
          </>
        ) : (
          <NotFaundWrapper>No Record Found</NotFaundWrapper>
        )}
      </Wrapper>
    </Fragment>
  );
};

export default CustomerOfferCreditInfo;
