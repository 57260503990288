import styled from 'styled-components';

export const PageWrapper = styled.div`
  text-align: center;
`;
export const ConentWrapper = styled.div`
  display: inline-block;
  margin: 25px 0;
  text-align: left;
`;
export const BackToListLink = styled.div`
  display: grid;
  grid-template-columns: 10px 290px;
  grid-gap: 10px;
  align-items: center;
`;

export const Chevron = styled.i`
  cursor: pointer;
  height: 15px;
  margin: 2px 12px 2px 0;
  font-family: FontAwesome5Pro;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
`;
export const NavigateText = styled.span`
  cursor: pointer;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 175px;
  grid-gap: 50px;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 15px;
`;
export const Title = styled.span`
  height: 39px;
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  color: #191818;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
`;
export const DateRangeWrapper = styled.span`
  width: 100%;
  height: 19px;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
`;

export const TotalSummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-items: center;
  margin: 25px 0;
`;
export const TotalSummaryCard = styled.div`
  width: 100%;
  height: 140px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
`;
export const TotalSummaryTitle = styled.div`
  font-family: Montserrat;
  width: 100%;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  text-align: center;
  color: #191818;
`;
export const DetailCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  margin: 25px 0;
  border-radius: 10px;
  align-items: center;
  padding-top: 25px;
  padding-bottom: ${(props) => props.noPaddingBottom || '25px'};
`;
export const DetailCardRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
  grid-gap: 50px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  padding: 0 32px;
  //
  font-family: Montserrat;
  font-size: ${(props) => (props.isBold ? '17px' : '15px')};
  font-weight: ${(props) => (props.isBold ? '600' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
`;
export const CollapsedCardRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px 3px;
  grid-gap: 10px;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  padding: 0 32px;
  //
  font-family: Montserrat;
  font-size: ${(props) => (props.isBold ? '17px' : '15px')};
  font-weight: ${(props) => (props.isBold ? '600' : 'normal')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
`;
export const MiddleTitle = styled.div`
  height: 20px;
  margin: 50px 0 25px 0;
  padding-left: 25px;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
`;
export const NoteWrapper = styled.div`
  height: 20px;
  margin-bottom: 25px;
  padding-left: 25px;
`;
export const Note = styled.div`
  margin: 10px 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
`;
export const Divider = styled.hr`
  border: 1px solid #e1e1e1;
`;
export const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  gap: 5px;
`;
export const Subtitle = styled.span`
  font-family: 'Montserrat';
  font-size: ${(props) => (props.isSummary ? '18px' : '16px')};
  font-weight: ${(props) => (props.isSummary ? 600 : 'normal')};
`;
export const ValueWrapper = styled.div`
  display: table-cell;
  font-family: 'Montserrat';
  height: 22px;
  vertical-align: middle;
  text-align: right;
  padding-top: 2px;
  font-size: ${(props) => (props.isSummary ? '18px' : '16px')};
  font-weight: ${(props) => (props.isSummary ? 600 : 'normal')};
`;
export const SignIcon = styled.span`
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: ${(props) => (props.isSummary ? 600 : 'normal')};
`;
