import React, { useState, useEffect } from 'react';
import { Button, ButtonRow } from '../../../../../components/Button/Button';
import styled from 'styled-components';
import { Card, TitleDiv, Title, Description } from '../styles/styles';
import MessagePreview from './MessagePreview';
import PersonalizationModalContainer from './PersonalizationModalContainer';
import ChooseImage from '../../../../MediaModal/components/ChooseImage';
import ReactGA from 'react-ga';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  makeStyles,
} from '@material-ui/core';

const FieldLabel = styled.label`
  min-width: 100%;
  margin: 2px 0 2px 0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #6f6f76;
`;
const Option = styled.option`
  min-width: 100%;
  margin: 2px 0 0;
  padding: 15px 8px 15px 12px;
  border-radius: 6px;
  border: solid 1px #cdcdcd;
  background-color: #ffffff;
`;

const MainWrapper = styled.div`
  width: 100%;
  padding-right: 35px;
  margin: 0;
`;
const PreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 60px;
  border-left: 1px solid #e1e1e1;
  margin: 0;
`;
export const ImageDiv = styled.div`
  display: grid;
  grid-gap: 20px;
`;
const SectionTitle = styled.div`
  margin: 46.9px 0 20px 0;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  color: #000000;
`;
const CustomWrapper = styled.div`
  display: block;
  position: relative;
  min-width: 100%;
  margin: 0;
`;
const TitleInput = styled.p`
  width: 100%;
  min-height: 18px;
  font-size: 18px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 45px;
  padding-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 6px;
`;
const MessageInput = styled.p`
  width: 100%;
  min-height: 120px;
  font-size: 18px;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 45px;
  border: 1px solid lightgray;
  border-radius: 6px;
`;
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
}));
const GenericDetailsTemplate = ({ data, saveMessage, appIdentifier }) => {
  const titleElementId = data.notification_type.name.replaceAll(' ', '_');
  const messageElementId = data.notification_type.event_type;
  const dataTokens = data.notification_type.tokens;
  const classes = useStyles();
  const referralType = Object.keys(data.messages)[0];
  const initialTitle = data.messages[referralType].title
    ? data.messages[referralType].title
    : '';
  const initialMessage = data.messages[referralType].message
    ? data.messages[referralType].message
    : '';
  const initialImageURL = data.messages[referralType].push_image_url
    ? data.messages[referralType].push_image_url
    : '';
  const initialActionLink = data.messages[referralType].push_action_link
    ? data.messages[referralType].push_action_link
    : '';
  const tokenList = Object.keys(data.notification_type.tokens).map((item) => {
    return item.replaceAll('$', '');
  });
  const [rawTitle, setRawTitle] = useState(initialTitle);
  const [rawMessage, setRawMessage] = useState(initialMessage);
  const [imageURL, setImageURL] = useState(initialImageURL);
  const [actionLink, setActionLink] = useState(initialActionLink);
  const [titleError, setTitleError] = useState('');
  const [messageError, setMessageError] = useState('');
  //
  const [titleCaretPosition, setTitleCaretPosition] = useState(null);
  const [messageCaretPosition, setMessageCaretPosition] = useState(null);
  const [currentSelection, setCurrentSelection] = useState('');
  const tokenRegex = /(\$\$+[^\$]+(?=\$[^]*$)+\$\$)/; //every word between $$ $$ is a token
  useEffect(() => {
    if (tokenRegex.test(initialTitle)) {
      const tokenKey = initialTitle.match(tokenRegex)[0];
      const personalizedId = data.notification_type.tokens[tokenKey];
      const tilteElement = initialTitle.replace(
        new RegExp(tokenRegex, 'g'),
        `<span disabled contenteditable="false" style="border-radius: 11.5px;background-color: #e6ebff; color: #2440ae;margin:5px;width:153px;height:18px;pointer-events: none;"><a contenteditable="false" disabled style="margin: 10px;pointer-events: none;color: blue">${personalizedId}</a></span> `
      );
      document.getElementById(titleElementId).innerHTML = '';
      document.getElementById(titleElementId).innerHTML = tilteElement;
    } else {
      document.getElementById(titleElementId).innerText = initialTitle;
    }

    if (tokenRegex.test(initialMessage)) {
      const tokenKey = initialMessage.match(tokenRegex)[0];
      const personalizedId = data.notification_type.tokens[tokenKey];
      const messageElement = initialMessage.replace(
        new RegExp(tokenRegex, 'g'),
        `<span disabled contenteditable="false" style="border-radius: 11.5px;background-color: #e6ebff; color: #2440ae;margin:5px;width:153px;height:18px;pointer-events: none;"><a contenteditable="false" disabled style="margin: 10px;pointer-events: none;color: blue">${personalizedId}</a></span> `
      );
      document.getElementById(messageElementId).innerHTML = '';
      document.getElementById(messageElementId).innerHTML = messageElement;
    } else {
      document.getElementById(messageElementId).innerText = initialMessage;
    }
    setRawTitle(document.getElementById(titleElementId).innerText);
    setRawMessage(document.getElementById(messageElementId).innerText);
    ReactGA.pageview(
      window.location.pathname,
      undefined,
      'Automated Message Form'
    );
  }, []);

  useEffect(() => {
    if (rawTitle?.length > 250) {
      setTitleError('No mor than 256 chareters');
    } else {
      setTitleError('');
    }
    if (rawTitle?.length > 250) {
      setTitleError('No mor than 1000 chareters');
    } else if (rawTitle?.length < 20) {
      setTitleError('At least 20 charecters');
    } else if (rawTitle?.includes('$$')) {
      setTitleError('Entered token is not valid');
    } else {
      setTitleError('');
    }
    if (rawMessage?.length > 1000) {
      setMessageError('No mor than 1000 chareters');
    } else if (rawMessage?.length < 20) {
      setMessageError('At least 20 charecters');
    } else if (rawMessage?.includes('$$')) {
      setMessageError('Entered token is not valid');
    } else {
      setMessageError('');
    }
  }, [rawMessage, rawTitle]);

  const getSpan = (value) => {
    const att = `<span disabled contenteditable="false" style="border-radius: 11.5px;background-color: #e6ebff; color: #2440ae;margin:5px;width:153px;height:18px;pointer-events: none;"><a contenteditable="false" disabled style="margin: 10px;pointer-events: none;color: blue">${value}</a></span> `;
    return att;
  };
  const getCursorAttribute = (element) => {
    const sel = document.getSelection();
    var range = window.getSelection().getRangeAt(0);
    let position = 0;
    sel.modify('extend', 'backward', 'paragraphboundary');
    let childNodes = sel.anchorNode.parentNode.childNodes;
    for (var i = 0; i < childNodes.length; i++) {
      if (childNodes[i] == sel.anchorNode) {
        break;
      }
      if (childNodes[i].outerHTML) {
        position += childNodes[i].outerHTML.length;
      } else if (childNodes[i].nodeType == 3) {
        position += childNodes[i].textContent.length;
      }
    }
    const currentPosition = range.startOffset + position;
    if (element.toLowerCase() === 'title') {
      setTitleCaretPosition(currentPosition);
    } else {
      setMessageCaretPosition(currentPosition);
    }
    if (sel.anchorNode != undefined) sel.collapseToEnd();
  };
  const handleInsertingPersonalizationTitle = (token) => {
    let localCaretPosition = titleCaretPosition;
    const personalizedElement = data.notification_type.tokens[token];
    let innerHTML = document.getElementById(titleElementId).innerHTML;
    const HTMLToStr = innerHTML
      .toString()
      .replaceAll(/\&nbsp;/g, ' ')
      // .replaceAll(/\s+/g, ' ')
      .replaceAll('<br>', '')
      .replaceAll('</br>', '');
    const firstSlice = HTMLToStr.slice(0, localCaretPosition);
    const lastSlice = HTMLToStr.slice(localCaretPosition, HTMLToStr.length);
    let titleElement = firstSlice + getSpan(personalizedElement) + lastSlice;
    document.getElementById(titleElementId).innerHTML = '';
    document.getElementById(titleElementId).innerHTML = titleElement;
    //
    const titleEdited = document.getElementById(titleElementId).innerText;
    setRawTitle(titleEdited);
  };
  const handleTypingPersonalizationTitle = (e) => {
    e.persist();
    getCursorAttribute('title');
    let innerHTMLElement = document.getElementById(titleElementId).innerHTML;
    const HTMLToStr = innerHTMLElement
      .toString()
      .replaceAll(/\&nbsp;/g, ' ')
      // .replaceAll(/\s+/g, ' ')
      .replaceAll('<br>', '')
      .replaceAll('</br>', '');
    if (tokenRegex.test(HTMLToStr)) {
      let typedToken = HTMLToStr.substring(
        HTMLToStr.indexOf('$$') + 2,
        HTMLToStr.lastIndexOf('$$')
      );
      if (tokenList.includes(typedToken)) {
        const tokenKey = HTMLToStr.match(tokenRegex)[0];
        const personalizedId = data.notification_type.tokens[tokenKey];
        const titleElement = HTMLToStr.replace(
          new RegExp(tokenRegex, 'g'),
          `<span disabled contenteditable="false" style="border-radius: 11.5px;background-color: #e6ebff; color: #2440ae;margin:5px;width:153px;height:18px;pointer-events: none;"><a contenteditable="false" disabled style="margin: 10px;pointer-events: none;color: blue">${personalizedId}</a></span> `
        );
        let selectedChildNodeIndex = '';
        const spanLength =
          `<span disabled contenteditable="false" style="border-radius: 11.5px;background-color: #e6ebff; color: #2440ae;margin:5px;width:153px;height:18px;pointer-events: none;"><a contenteditable="false" disabled style="margin: 10px;pointer-events: none;color: blue">${personalizedId}</a></span> `.toString()
            .length + 2;
        const caretIndex =
          titleCaretPosition - personalizedId.length + spanLength;
        let position = 0;
        document.getElementById(titleElementId).innerHTML = '';
        document.getElementById(titleElementId).innerHTML = titleElement;
        var el = document.getElementById(titleElementId);
        var range = document.createRange();
        var sel = document.getSelection();
        const childNodes = el.childNodes;
        const childNodesLength = el.childNodes.length;
        for (var i = 0; i < childNodesLength; i++) {
          if (childNodes[i].nodeType === 1) {
            position += childNodes[i].outerHTML.length;
            if (position === caretIndex) {
              selectedChildNodeIndex = i;
            }
          } else if (childNodes[i].nodeType === 3) {
            position += childNodes[i].textContent.length;
          }
        }
        if (selectedChildNodeIndex) {
          range.setStart(el.childNodes[selectedChildNodeIndex + 1], 1);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          el.focus();
        }
      }
    }
    const titleEdited = document.getElementById(titleElementId).innerText;
    setRawTitle(titleEdited);
  };
  const handleInsertingPersonalizationMessage = (token) => {
    let localCaretPosition = messageCaretPosition;
    const personalizedElement = data.notification_type.tokens[token];
    let innerHTML = document.getElementById(messageElementId).innerHTML;
    const HTMLToStr = innerHTML
      .toString()
      .replaceAll(/\&nbsp;/g, ' ')
      // .replaceAll(/\s+/g, ' ')
      .replaceAll('<br>', '')
      .replaceAll('</br>', '');
    const firstSlice = HTMLToStr.slice(0, localCaretPosition);
    const lastSlice = HTMLToStr.slice(localCaretPosition, HTMLToStr.length);
    let messageElement = firstSlice + getSpan(personalizedElement) + lastSlice;
    document.getElementById(messageElementId).innerHTML = '';
    document.getElementById(messageElementId).innerHTML = messageElement;
    //
    const messageEdited = document.getElementById(messageElementId).innerText;
    setRawMessage(messageEdited);
  };

  const handleTypingPersonalizationMessage = (e) => {
    e.persist();
    getCursorAttribute('message');
    let innerHTMLElement = document.getElementById(messageElementId).innerHTML;
    const HTMLToStr = innerHTMLElement
      .toString()
      .replaceAll(/\&nbsp;/g, ' ')
      // .replaceAll(/\s+/g, ' ')
      .replaceAll('<br>', '')
      .replaceAll('</br>', '');
    if (tokenRegex.test(HTMLToStr)) {
      let typedToken = HTMLToStr.substring(
        HTMLToStr.indexOf('$$') + 2,
        HTMLToStr.lastIndexOf('$$')
      );
      if (tokenList.includes(typedToken)) {
        const tokenKey = HTMLToStr.match(tokenRegex)[0];
        const personalizedId = data.notification_type.tokens[tokenKey];
        const messageElement = HTMLToStr.replace(
          new RegExp(tokenRegex, 'g'),
          `<span disabled contenteditable="false" style="border-radius: 11.5px;background-color: #e6ebff; color: #2440ae;margin:5px;width:153px;height:18px;pointer-events: none;"><a contenteditable="false" disabled style="margin: 10px;pointer-events: none;color: blue">${personalizedId}</a></span> `
        );
        let selectedChildNodeIndex = '';
        const spanLength =
          `<span disabled contenteditable="false" style="border-radius: 11.5px;background-color: #e6ebff; color: #2440ae;margin:5px;width:153px;height:18px;pointer-events: none;"><a contenteditable="false" disabled style="margin: 10px;pointer-events: none;color: blue">${personalizedId}</a></span> `.toString()
            .length + 2;
        const caretIndex =
          messageCaretPosition - personalizedId.length + spanLength;
        let position = 0;
        document.getElementById(messageElementId).innerHTML = '';
        document.getElementById(messageElementId).innerHTML = messageElement;
        var el = document.getElementById(messageElementId);
        var range = document.createRange();
        var sel = document.getSelection();
        const childNodes = el.childNodes;
        const childNodesLength = el.childNodes.length;
        for (var i = 0; i < childNodesLength; i++) {
          if (childNodes[i].nodeType === 1) {
            position += childNodes[i].outerHTML.length;
            if (position === caretIndex) {
              selectedChildNodeIndex = i;
            }
          } else if (childNodes[i].nodeType === 3) {
            position += childNodes[i].textContent.length;
          }
        }
        if (selectedChildNodeIndex) {
          range.setStart(el.childNodes[selectedChildNodeIndex + 1], 1);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          el.focus();
        }
      }
    }
    const messageEdited = document.getElementById(messageElementId).innerText;
    setRawMessage(messageEdited);
  };
  const handleActionLinkSelect = (e) => {
    setActionLink(e.target.value);
  };
  const handleSave = () => {
    let newTitle = rawTitle;
    let newMessage = rawMessage;
    for (let value of Object.values(dataTokens)) {
      if (rawTitle.includes(value)) {
        const key = Object.keys(dataTokens).find(
          (key) => dataTokens[key] === value
        );
        newTitle = rawTitle.replaceAll(value, '$' + key + '$');
      }
      if (rawMessage.includes(value)) {
        const key = Object.keys(dataTokens).find(
          (key) => dataTokens[key] === value
        );
        newMessage = rawMessage.replaceAll(value, '$' + key + '$');
      }
    }
    const messages = {
      configuration: data.configurations,
      messages: {
        default: {
          title: newTitle,
          message: newMessage,
          push_image_url: imageURL,
          push_action_link: actionLink,
        },
      },
    };
    if (!messageError && !titleError) saveMessage(messages, data.id);
  };
  const handleEnterKey = (e) => {
    var keycode = e.charCode || e.keyCode;
    if (keycode === 13) {
      e.preventDefault();
      return false;
    }
  };
  return (
    <div>
      <Card>
        <TitleDiv>
          <Title>{data.notification_type.name}</Title>
          <Description>{data.notification_type.description}</Description>
        </TitleDiv>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <MainWrapper>
              <div style={{ marginTop: '15px' }}>
                <FieldLabel>Title</FieldLabel>
                <CustomWrapper>
                  <TitleInput
                    id={titleElementId.toString()}
                    contentEditable={true}
                    suppressContentEditableWarning
                    onClick={() => getCursorAttribute('title')}
                    onKeyUp={handleTypingPersonalizationTitle}
                    onKeyPress={handleEnterKey}
                  ></TitleInput>
                  <PersonalizationModalContainer
                    tokens={data.notification_type.tokens}
                    insertPersonalization={handleInsertingPersonalizationTitle}
                    bottom="15px"
                    caretPosition={titleCaretPosition}
                  />
                </CustomWrapper>
                {titleError && (
                  <div style={{ color: 'red', marginTop: '4px' }}>
                    {titleError}
                  </div>
                )}
              </div>
              <div style={{ marginTop: '46px' }}>
                <FieldLabel>Message</FieldLabel>
                <CustomWrapper>
                  <MessageInput
                    id={messageElementId}
                    contentEditable={true}
                    suppressContentEditableWarning
                    onClick={() => getCursorAttribute('message')}
                    onKeyUp={handleTypingPersonalizationMessage}
                    onKeyPress={handleEnterKey}
                  ></MessageInput>
                  <PersonalizationModalContainer
                    tokens={data.notification_type.tokens}
                    insertPersonalization={
                      handleInsertingPersonalizationMessage
                    }
                    bottom="10px"
                    caretPosition={messageCaretPosition}
                  />
                </CustomWrapper>
                {messageError && (
                  <div style={{ color: 'red', marginTop: '4px' }}>
                    {messageError}
                  </div>
                )}
              </div>
              {
                // Images is hiding for now because order status doesnt have image
              }
              {/* <ImageDiv>
                <SectionTitle>Image (optional)</SectionTitle>
                <ChooseImage
                  imageUrl={imageURL}
                  setIsImageGallaryVisible={true}
                  isColumn
                  imgWidth={'32px'}
                  imgHeight={'28px'}
                  imgWrapperWidth={'55px'}
                />
              </ImageDiv> */}
              <SectionTitle>Message Action</SectionTitle>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Message Action
                </InputLabel>
                <Select
                  value={actionLink}
                  onChange={handleActionLinkSelect}
                  label="Select Personalization"
                  inputProps={{
                    name: 'Message Action',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <MenuItem value={`${appIdentifier}://homefeed`}>
                    Home Page
                  </MenuItem>
                </Select>
              </FormControl>
            </MainWrapper>
          </Grid>
          <Grid item xs={5}>
            <PreviewWrapper>
              <SectionTitle>Preview</SectionTitle>
              <MessagePreview
                title={rawTitle}
                message={rawMessage}
                dataTokens={dataTokens}
              />
            </PreviewWrapper>
          </Grid>
          <Grid item xs={12}>
            <ButtonRow
              style={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              <Button
                onClick={handleSave}
                disabled={messageError || titleError}
              >
                Save
              </Button>
            </ButtonRow>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default GenericDetailsTemplate;
