import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import TextFieldPOS from '../../components/TextFieldPOS';
import ToogleFieldPOS from '../../components/ToogleFieldPOS';
import Pagination from '../../../../../components/Pagination/Pagination';
import CreatableSelect from '../../../../../components/Select/CreatableSelect';
import StickyButtons from '../../../../../components/StickyButtons';
import ConfirmUnsavedChangesModal from '../../components/ConfirmUnsavedChangesModal';
import { discountOptionsModified } from '../../utils/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  MainDiv,
  PaginationWrapper,
  Body,
  DiscountDiv,
  DiscountNameCell,
  POSDiv,
  DiscountHeader,
  POSHeader,
  POSFieldWrapper,
  POSValueCell,
  LineDivider,
  ToogleFieldPOSWrapper,
  SelectDiv,
} from '../../styles/styles';

const CombosMappingTable = ({
  combos,
  meta,
  requestParams,
  updateRequestParams,
  initialRequestParams,
  dirty,
  handleReset,
  submitPOSDiscountValuesCombos,
  values,
  initialValues,
  type,
  getCombos,
  selectedValuePOS,
  setSelectedValuePOS,
  allCombos,
  posDiscountsMappingFields,
  posDiscountsFieldsNum,
  CombosMappingTable,
  selectedComboId,
  specificPOSCombos,
  getSpecificCombos,
  setSelectedComboId,
}) => {
  const discountOptions = discountOptionsModified(combos, type);
  const [newValue, setNewValue] = useState(null);
  const [newSearch, setNewSearch] = useState(null);
  const [newPage, setNewPage] = useState(null);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [diplayedComboList, setDiplayedComboList] = useState([]);
  // useEffect(() => {}, [specificPOSCombos]);
  const handleComboSelect = useCallback(() => {
    if (specificPOSCombos?.length > 0) {
      setNewValue(specificPOSCombos[0]);
      setDiplayedComboList(specificPOSCombos);
    } else {
      setDiplayedComboList(combos);
    }
  }, [specificPOSCombos]);
  useEffect(() => {
    handleComboSelect();
  }, [handleComboSelect]);
  const handleChangeCombo = (newValue) => {
    if (!newValue) {
      getCombos(initialRequestParams);
      setSelectedComboId(null);
    } else {
      getSpecificCombos(newValue);
    }
    setNewValue(newValue);
  };
  return (
    <MainDiv>
      <SelectDiv>
        <Autocomplete
          id='combo-box-demo'
          options={discountOptions}
          getOptionLabel={(option) => option.name}
          onChange={(event, newValue) => {
            handleChangeCombo(newValue);
          }}
          style={{ width: '100%' }}
          value={newValue}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search for combos'
              variant='outlined'
            />
          )}
        />
      </SelectDiv>

      <Body>
        <DiscountDiv>
          <DiscountHeader>
            <div>{type.charAt(0).toUpperCase() + type.slice(1)} Name</div>
          </DiscountHeader>
          <LineDivider />
          {diplayedComboList.map((combo, index) => {
            return (
              <div key={combo.id}>
                <DiscountNameCell>
                  {diplayedComboList[index].name}
                </DiscountNameCell>
              </div>
            );
          })}
        </DiscountDiv>

        <POSDiv>
          <POSFieldWrapper fieldsNumber={posDiscountsFieldsNum}>
            {Object.keys(posDiscountsMappingFields).map((item, key) => {
              return <POSHeader key={key}>{item}*</POSHeader>;
            })}
            <LineDivider fieldsNumber={posDiscountsFieldsNum} />

            <FieldArray
              name='combos'
              render={(arrayHelpers) => (
                <Fragment>
                  {diplayedComboList.map((combo, index) => {
                    return (
                      <Fragment key={`c_${combo.id}`}>
                        {Object.keys(posDiscountsMappingFields).map((key) => {
                          return (
                            <POSValueCell key={`p_${combo.id}_${key}`}>
                              {posDiscountsMappingFields[key].type ===
                              'string' ? (
                                <TextFieldPOS
                                  name={`combos[${index}].pos_mapping['${key}']`}
                                  value={
                                    combo?.pos_mapping
                                      ? combo?.pos_mapping[key]
                                      : ''
                                  }
                                  setFieldValue={
                                    arrayHelpers.form.setFieldValue
                                  }
                                />
                              ) : (
                                <ToogleFieldPOSWrapper>
                                  <ToogleFieldPOS
                                    name={`combos[${index}].pos_mapping['${key}']`}
                                    onChange={arrayHelpers.form.setFieldValue}
                                  />
                                </ToogleFieldPOSWrapper>
                              )}
                            </POSValueCell>
                          );
                        })}
                      </Fragment>
                    );
                  })}
                  <StickyButtons
                    isVisible={dirty}
                    handleDiscard={() => handleReset()}
                    handleSave={() => {
                      submitPOSDiscountValuesCombos(
                        values,
                        type,
                        initialValues,
                        selectedValuePOS,
                        null,
                        null,
                        meta.page_size,
                        meta.page_number,
                        posDiscountsMappingFields
                      );
                      setNewValue(null);
                    }}
                    title='Combo POS Mapping'
                  />
                </Fragment>
              )}
            />
          </POSFieldWrapper>
        </POSDiv>
      </Body>
      {/* 
      <PaginationWrapper>
        <Pagination
          response={{
            page_number: meta.page_number,
            page_size: meta.page_size,
            count: meta.count,
          }}
          requestParams={requestParams}
          updateRequestParams={updateRequestParams}
          handlePreviousPageClick={() => {
            if (dirty) {
              setNewPage(meta.page_number - 1);
              setIsConfirmModal(true);
            } else {
              getCombos({
                searchString: '',
                page_number: meta.page_number - 1,
                page_size: meta.page_size,
              });
            }
          }}
          handleNextPageClick={() => {
            if (dirty) {
              setNewPage(meta.page_number + 1);
              setIsConfirmModal(true);
            } else {
              getCombos({
                searchString: '',
                page_number: meta.page_number + 1,
                page_size: meta.page_size,
              });
            }
          }}
          isResultsPerPageDisabled={false}
        />
      </PaginationWrapper> */}

      {isConfirmModal && (
        <ConfirmUnsavedChangesModal
          toggleConfirmModal={() => setIsConfirmModal(false)}
          handleSave={() => {
            setIsConfirmModal(false);
            submitPOSDiscountValuesCombos(
              values,
              type,
              initialValues,
              selectedValuePOS,
              newValue,
              newSearch,
              meta.page_size,
              newPage ? newPage : meta.page_number,
              posDiscountsMappingFields
            );
            setNewSearch(null);
            setNewPage(null);
          }}
        />
      )}
    </MainDiv>
  );
};

export default CombosMappingTable;
