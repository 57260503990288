import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ComboItems from '../components/ComboItems';
import {
  getProductsVariants,
  getProductsCategories,
  getComboItems,
  getCombosOptionsOverrides,
  handleComboItemsSubmit,
  handleSortComboGroupsOnly,
  handleReorderComboGroupProdItems,
  getAllProducts,
  getSharedOptionsComboItems,
  updateIsComboItemSubmit,
  handleSortingComboItemsSubmit,
  disableComboProdReord,
} from '../../../combosAction';
import { LoadingBlueLarge } from 'merchant-portal-components';

const ComboItemsContainer = ({
  disableComboProdReord,
  isComboProdReordDisab,
  showLoader,
  initValues,
  products,
  variants,
  comboItems,
  getProductsVariants,
  productsVariants,
  getProductsCategories,
  productsCategories,
  getComboItems,
  getCombosOptionsOverrides,
  combosOptionsOverride,
  isRequestingGetCombosOptionsOverrides,
  options,
  isRequestingGetComboItems,
  isRequestingGetProductsVariants,
  handleComboItemsSubmit,
  handleSortComboGroupsOnly,
  handleReorderComboGroupProdItems,
  allProducts,
  getAllProducts,
  getSharedOptionsComboItems,
  isRequestingGetSharedOptionsCombos,
  sharedOptionsCombos,
  submitComboItemsIsRequesting,
  isComboItemsSubmitSuccess,
  isRequestingGetAllProducts,
  updateIsComboItemSubmit,
  isSavingComboItemsPositions,
  handleSortingComboItemsSubmit,
}) => {
  useEffect(() => {
    initValues && getComboItems(products, variants, initValues);
    initValues && getSharedOptionsComboItems(comboItems, options, initValues);
  }, [initValues]);

  const show =
    !initValues || (initValues && initValues.id && !isRequestingGetComboItems);

  return (
    <div>
      {show ? (
        <ComboItems
          isComboProdReordDisab={isComboProdReordDisab}
          showLoader={showLoader}
          disableComboProdReord={disableComboProdReord}
          initValues={initValues}
          products={products}
          variants={variants}
          comboItems={comboItems}
          getProductsVariants={getProductsVariants}
          productsVariants={productsVariants}
          getProductsCategories={getProductsCategories}
          productsCategories={productsCategories}
          getCombosOptionsOverrides={getCombosOptionsOverrides}
          combosOptionsOverride={combosOptionsOverride}
          isRequestingGetCombosOptionsOverrides={
            isRequestingGetCombosOptionsOverrides
          }
          isRequestingGetComboItems={isRequestingGetComboItems}
          handleComboItemsSubmit={handleComboItemsSubmit}
          handleSortComboGroupsOnly={handleSortComboGroupsOnly}
          handleReorderComboGroupProdItems={handleReorderComboGroupProdItems}
          isRequestingGetProductsVariants={isRequestingGetProductsVariants}
          options={options}
          allProducts={allProducts}
          getAllProducts={getAllProducts}
          getSharedOptionsComboItems={getSharedOptionsComboItems}
          isRequestingGetSharedOptionsCombos={
            isRequestingGetSharedOptionsCombos
          }
          sharedOptionsCombos={sharedOptionsCombos}
          submitComboItemsIsRequesting={submitComboItemsIsRequesting}
          disabled={!initValues}
          isComboItemsSubmitSuccess={isComboItemsSubmitSuccess}
          isRequestingGetAllProducts={isRequestingGetAllProducts}
          updateIsComboItemSubmit={updateIsComboItemSubmit}
          isSavingComboItemsPositions={isSavingComboItemsPositions}
          handleSortingComboItemsSubmit={handleSortingComboItemsSubmit}
        />
      ) : (
        <LoadingBlueLarge />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isComboProdReordDisab:state.combosReducer.isComboProdReordDisab,
  showLoader:state.combosReducer.showLoader,
  initValues: state.combosReducer.editingCombo,
  comboItems: state.combosReducer.comboItems,
  isRequestingGetComboItems: state.combosReducer.isRequestingGetComboItems,
  productsCategories: state.combosReducer.productsCategories,
  productsVariants: state.combosReducer.productsVariants,
  isRequestingGetProductsVariants:
    state.combosReducer.isRequestingGetProductsVariants,
  products: state.productsReducer.allProducts,
  variants: state.variantsReducer.allVariants,
  combosOptionsOverride: state.combosReducer.combosOptionsOverrides,
  isRequestingGetCombosOptionsOverrides:
    state.combosReducer.isRequestingGetCombosOptionsOverrides,
  options: state.optionReducer.options,
  allProducts: state.combosReducer.allProducts,
  isRequestingGetSharedOptionsCombos:
    state.combosReducer.isRequestingGetSharedOptionsCombos,
  sharedOptionsCombos: state.combosReducer.sharedOptionsCombos,
  submitComboItemsIsRequesting:
    state.combosReducer.submitComboItemsIsRequesting,
  isComboItemsSubmitSuccess: state.combosReducer.isComboItemsSubmitSuccess,
  isRequestingGetAllProducts: state.combosReducer.isRequestingGetAllProducts,
  isSavingComboItemsPositions: state.combosReducer.isSavingComboItemsPositions,
});

export default connect(mapStateToProps, {
  getProductsVariants,
  getProductsCategories,
  getComboItems,
  getCombosOptionsOverrides,
  handleComboItemsSubmit,
  handleSortComboGroupsOnly,
  handleReorderComboGroupProdItems,
  getAllProducts,
  getSharedOptionsComboItems,
  updateIsComboItemSubmit,
  handleSortingComboItemsSubmit,
  disableComboProdReord
})(ComboItemsContainer);
