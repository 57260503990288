import React from 'react';
import styled from 'styled-components';
import {
  TableWithButtonRow,
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../../components/Icon';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import NoDataMessage from '../../../../../components/NoDataMessage/NoDataMessage';

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const StyledDiv = styled.div`
  position: relative;
  bottom: 80px;
  right: 38px;
  height:0px;
  z-index:1;

  @media (min-width: 750px) and (max-width: 800px){
    position:relative;
    bottom: 50px;
    padding-top:40px;
    right: 30px;
   }


  @media (min-width: 800px) and (max-width: 850px){
    position:relative;
    bottom: 10px;
    padding-top:40px;
    right: 8px;
    top:-19px;
    margin-bottom:25px;
   }
 
   
   @media (min-width: 850px) and (max-width: 900px){
    position:relative;
    bottom: 50px;
    padding-top:40px;
    right: 56px;
   }

   @media (min-width: 900px) and (max-width: 950px){
    position:relative;
    bottom: 50px;
    padding:10px;
    right: 112px;
   }

   @media (min-width: 950px) and (max-width: 1000px){
    position:relative;
    bottom: 50px;
    padding:10px;
    right: 160px;
   }

   @media (min-width: 1000px) and (max-width: 1100px){
    position:relative;
    bottom: 50px;
    padding:10px;
    right: 200px;
   }

   
   @media (min-width: 1100px) and (max-width: 1200px){
    position:relative;
    bottom: 50px;
    padding:10px;
    right: 215px;
   }

   @media (min-width: 1200px) and (max-width: 1230px){
    position:relative;
    bottom: 50px;
    padding:10px;
    right: 215px;
   }
`;

const SortDiv = styled.div`
  right: 10px;
  padding: 0 40px 25px;
  text-align: right;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;

const styles = {
  switchBase: {
    transition: 'none',
  },
  switchBaseDefault: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const CategoryList = ({
  showOnlyWhenNotSearched,
  categories,
  getCategories,
  toggleCategory,
  initiateEditCategory,
  requestParams,
  allCategoriesLength,
  showActiveData,
}) => {
  const renderHeaders = () => {
    return (
      <TrHead>
        <Th
          align='left'
          style={{ paddingLeft: '37px' }}
          value='name'
          sortingOption={requestParams.sorting_option}
        >
          Name
        </Th>
        <Th
          style={{ minWidth: '150px' }}
          align='left'
          value='subLength'
          sortingOption={requestParams.sorting_option}
        >
          SubCategories
        </Th>
        <Th
          align='right'
          style={{ paddingRight: '45px' }}
          value='is_active'
          sortingOption={requestParams.sorting_option}
        >
          Active
        </Th>
      </TrHead>
    );
  };

  const renderSubCategoryString = (category) => {
    if (category.sub_categories.length === 0) return 'No Subcategories';
    if (category.sub_categories.length === 1) return '1 Subcategory';
    else {
      return category.sub_categories.length + ' ' + 'Subcategories';
    }
  };

  const renderRows = () => {
    return categories.map((category, index) => (
      <Tr
        onClick={() => initiateEditCategory(category)}
        key={index}
        style={{ cursor: 'pointer', height: '78px' }}
      >
        <Td padding={'0px 0px 0px 37px'}>{category.name}</Td>
        <Td>{renderSubCategoryString(category)}</Td>
        <Td padding={'0px 37px 0px 0px'} style={{ textAlign: 'right' }}>
          <Switch
            checked={category.is_active}
            onClick={(e) => e.stopPropagation()}
            onChange={() => toggleCategory(category, !category.is_active)}
            value='checkedB'
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Td>
      </Tr>
    ));
  };
  return (
    <Wrapper>
      {/* {showOnlyWhenNotSearched &&  categories.length > 1 && (
        <SortDiv>
          <SortWrapper onClick={() => setIsSortModalOpen(true)}>
            <i style={{color:'#2440AE'}} className="far fa-sort-amount-down" /> 
            <span style={{color:'#2440AE'}}>Reorder Categories1</span>
            <Tooltip
              style={{ marginRight: 'auto'  }}
              title="Edit the position of categories to change the order that they are presented in to the user"
            >
              <span> 
                <TooltipIcon  style={{color:'#2440AE'}} marginLeft />
              </span>
            </Tooltip>
          </SortWrapper>
        </SortDiv>
      )}  */}
      {showOnlyWhenNotSearched && (
        <StyledDiv>
          <PaginationControl
            response={{
              ...categories,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: allCategoriesLength,
            }}
            requestParams={requestParams}
            updateAction={getCategories}
            type='categories'
          />
        </StyledDiv>
      )}

      <TableWithButtonRow marginLeft='37px' marginRight='37px'>
        <TableWrapper noShadow>
          <Table noBorder style={{ backgroundColor: 'yellow' }}>
            <thead>{renderHeaders()}</thead>
            <TBody striped>{renderRows()}</TBody>
          </Table>
          <NoDataMessage
            len={categories.length}
            type='Categories'
            isActive={showActiveData}
          />
        </TableWrapper>
      </TableWithButtonRow>
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...categories,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: allCategoriesLength,
          }}
          requestParams={requestParams}
          updateAction={getCategories}
          type='categories'
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default withStyles(styles)(CategoryList);
