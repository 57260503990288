import React, { Fragment, useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Select } from 'merchant-portal-components';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  useFormControl,
} from '@material-ui/core';
import { updateCombosRequestParams } from '../../../combosAction';
// import PaginationControl from '../../../../../../components/Pagination/PaginationControl';

import PaginationControlProduct from '../../../../../../components/Pagination/PaginationControlProduct';
const PaginationWrapper = styled.div`
  padding: 20px 15px;
`;
const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px 0;
`;

const ModalBody = styled.div``;

const ProductDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const LineDivider = styled.hr`
  margin-top: 11px;
  margin-bottom: 11px;
  border: solid 0.5px #ebebeb;
`;

const ProductImg = styled.img`
  width: 35px;
  height: 35px;
  background-color: #d3d3d3;
`;

const CategorySelect = styled.div`
  margin-bottom: 20px;
  width: 250px;
`;

const ProductImageRow = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 50px 1fr;
  margin-bottom: 10px;
  margin-top: 30px;
`;

const TextSearch = styled.div`
  padding: 10px 0;
`;

const RemoveCatFilter = styled.button`
  font-style: italic;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  display: block;
`;

const AddProductsModal = ({
  productsModalState,
  getProductsCategories,
  productsCategories,
  getProductsVariants,
  isRequestingGetProductsVariants,
  productsVariants,
  toggleProductModal,
  comboItem,
  getAllProducts,
  allProducts,
  index,
  productVariantIndex,
  setFieldValue,
  arrayHelpers,
  isRequestingGetAllProducts,
  requestParams,
  updateCombosRequestParams,
}) => {
  const [searchString, setSearchString] = useState('');
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  let productsVariantsModified = [];
  const [filterState, setFilterState] = useState({
    search: '',
    filteredProducts: isCategorySelected ? productsVariants : allProducts,
  });
  const initialFormState = { mySelectKey: null };
  const [myForm, setMyForm] = useState(initialFormState);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === '') {
        getAllProducts(newRequestParams);
      }
      updateCombosRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString]);
  useEffect(() => {
    return () => {
      updateCombosRequestParams({
        page_number: 1,
        page_size: '25',
        sorting_option: 'title-asc',
        search_string: '',
        has_next_page: true,
      });
    };
  }, []);

  const updateFilterState = useCallback(() => {
    setFilterState({
      search: filterState.search,
      filteredProducts: isCategorySelected ? productsVariants : allProducts,
    });
  }, [allProducts, isRequestingGetAllProducts, productsVariants]);
  useEffect(() => {
    updateFilterState();
  }, [updateFilterState]);
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };
  const [selectedProductVariants, setSelectedProductVariants] = useState({});
  useEffect(() => {
    const existingProducts = comboItem.productVariants;
    let newSelectedProductVariants = [];
    existingProducts.forEach((item) => {
      if (newSelectedProductVariants[item.productId])
        newSelectedProductVariants[item.productId].push(item.variantId);
      else newSelectedProductVariants[item.productId] = [item.variantId];
    });
    setSelectedProductVariants(newSelectedProductVariants);
    // setSelectedProductVariants([]);
  }, [productsVariants]);
  const updateWithOldVariants = (newProductVariants, productId, variantId) => {
    let newVariantList = JSON.parse(JSON.stringify(comboItem.productVariants));

    const foundItemExist = newVariantList.find(
      (oldItem) =>
        oldItem.productId === productId && oldItem.variantId === variantId
    );
    if (foundItemExist) {
      const index = newVariantList.findIndex(
        (oldItem) =>
          oldItem.productId === productId && oldItem.variantId === variantId
      );
      newVariantList.splice(index, 1);
    } else {
      newProductVariants?.forEach((newItem) => {
        const foundItem = newVariantList.find(
          (oldItem) =>
            oldItem.productId === newItem.productId &&
            oldItem.variantId === newItem.variantId
        );
        if (!foundItem) {
          newVariantList.push(newItem);
        }
      });
    }
    return newVariantList;
  };
  const getFinalState = (selectedVariants, productId, variantId) => {
    const allVariants = allProducts;
    const finalState = [];
    Object.keys(selectedVariants)?.forEach((productId) => {
      selectedVariants[productId]?.forEach((variantId) => {
        allVariants?.forEach((product) => {
          product.productVariants.map((variant) => {
            if (
              product.productId == productId &&
              variant.variantId == variantId
            ) {
              const newObj = {
                productId: product.productId,
                productIsActive: product.productIsActive,
                productName: product.productName,
                productImage: product.productImageUrl,
                productExtraPrice: null,
                variantId: variant.variantId,
                variantName: variant.variantName,
                variantPrice: variant.variantPrice,
              };

              arrayHelpers.form.values.comboItems[
                index
              ].productVariants?.forEach((item) => {
                if (
                  item.productId === newObj.productId &&
                  item.variantId === newObj.variantId
                ) {
                  newObj.productExtraPrice = item.productExtraPrice;
                }
              });
              finalState.push(newObj);
            }
          });
        });
      });
    });
    return updateWithOldVariants(finalState, productId, variantId);
  };
  //dropdown categories name
  const renderCategories = () => {
    return productsCategories
      .map((category) => {
        if (category.sub_categories.length != 0) {
          return category.sub_categories.map((subcategory) => ({
            name: category.name + ' - ' + subcategory.name,
            id: subcategory.id,
          }));
        } else {
          return {
            name: category.name,
            id: category.id,
          };
        }
      })
      .flat();
  };

  const categoriesList = renderCategories();

  const onToggleSelect = (productId, variantId) => {
    let newSelectedProductVariants;

    const allProductIds = Object.keys(selectedProductVariants).map((id) =>
      parseInt(id)
    );
    let selectedProductsId = JSON.parse(JSON.stringify(allProductIds));

    if (!selectedProductsId.includes(parseInt(productId))) {
      const newProductVariant = [variantId];
      newSelectedProductVariants = {
        ...selectedProductVariants,
        [productId]: newProductVariant,
      };
    } else {
      const variantIds = selectedProductVariants[productId];
      if (variantIds.includes(variantId)) {
        const index = variantIds.indexOf(variantId);
        variantIds.splice(index, 1);
        newSelectedProductVariants = JSON.parse(
          JSON.stringify(selectedProductVariants)
        );

        if (variantIds.length > 0) {
          newSelectedProductVariants[productId] = variantIds;
        } else {
          if (allProductIds.length > 1) {
            delete newSelectedProductVariants[productId];
          } else {
            newSelectedProductVariants = {};
          }
        }
      } else {
        newSelectedProductVariants = JSON.parse(
          JSON.stringify(selectedProductVariants)
        );
        variantIds.push(variantId);
        newSelectedProductVariants[productId] = variantIds;
      }
      // const index = selectedProductsId.indexOf(parseInt(productId));
      // selectedProductsId.splice(index, 1);
    }
    setSelectedProductVariants(newSelectedProductVariants);
    setFieldValue(
      `comboItems[${index}].productVariants`,
      getFinalState(newSelectedProductVariants, productId, variantId)
    );
  };
  const isVariantSelected = (productId, variantId) => {
    let newIsVariantSelected = false;
    const isProductExist = Object.keys(selectedProductVariants)?.includes(
      String(productId)
    );
    if (!isProductExist) {
      newIsVariantSelected = false;
    } else {
      if (selectedProductVariants[productId]?.includes(variantId)) {
        newIsVariantSelected = true;
      }
    }
    return newIsVariantSelected;
  };
  return (
    <Modal maxWidth="sm" fullWidth>
      <DialogTitle>
        <ModalTitle>
          <div>{productsModalState.itemLabel}</div>
          <div
            onClick={() => toggleProductModal()}
            style={{ cursor: 'pointer', justifySelf: 'right' }}
          >
            <i className="fas fa-times" />
          </div>
        </ModalTitle>
        <LineDivider />
        <TextSearch>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fal fa-search" />
                </InputAdornment>
              ),
            }}
            style={{ width: '100%' }}
            variant="outlined"
            type="text"
            onChange={(e) => handleSearch(e)}
            placeholder="Search by Product Name"
            value={searchString}
          />
        </TextSearch>
      </DialogTitle>
      <DialogContent>
        <ModalBody>
          {/* <CategorySelect>
            <Select
              options={renderCategories()}
              // value={categoriesList.filter(
              //   value => value === myForm.mySelectKey
              // )}
              value={myForm.mySelectKey}
              getOptionLabel={(category) => category.name}
              getOptionValue={(category) => category}
              onChange={(category) => {
                setIsCategorySelected(true);
                getProductsVariants(category);
                setMyForm({ ...myForm, mySelectKey: category });
              }}
              valueKey="id"
              placeholder="Filter By Category"
              required
            />
          </CategorySelect> */}
          {/* <RemoveCatFilter
            onClick={(e) => {
              setIsCategorySelected(false);
              setFilterState({
                search: e.target.value,
                filteredProducts: allProducts.filter((product) =>
                  product.productName.includes(e.target.value)
                ),
              });
              setMyForm(initialFormState);
            }}
          >
            Remove Category filter
          </RemoveCatFilter> */}
          {!isCategorySelected &&
          allProducts &&
          filterState.filteredProducts ? (
            filterState.filteredProducts.map((productVariants, index) => {
              return (
                <ProductDiv key={index}>
                  <ProductImageRow>
                    <ProductImg src={productVariants.productImageUrl} />
                    <div
                      style={
                        !productVariants.productIsActive
                          ? {
                              color: '#979797',
                              display: 'inline-flex',
                            }
                          : { color: 'inherit' }
                      }
                    >
                      {productVariants.productName}
                      {!productVariants.productIsActive && (
                        <Tooltip title="The product is inactive">
                          <div>
                            <Icon
                              className="fal fa-info-circle"
                              style={{
                                cursor: 'inherit',
                                fontSize: '16px',
                                marginLeft: '10px',
                                marginTop: '5px',
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </ProductImageRow>
                  {productVariants.productVariants.map((productVariant, i) => {
                    return (
                      <div
                        style={{ marginLeft: '30px' }}
                        key={productVariant.variantId}
                      >
                        <p>
                          <Checkbox
                            checked={isVariantSelected(
                              productVariants.productId,
                              productVariant.variantId
                            )}
                            onChange={
                              () =>
                                onToggleSelect(
                                  productVariants.productId,
                                  productVariant.variantId
                                )
                              // setFieldValue(
                              //   `comboItems[${index}].productVariants`,
                              //   {
                              //     productId: productVariants.id,
                              //     variantId: productVariant.variantId
                              //   }
                              // )
                            }
                            color="primary"
                          />
                          {productVariant.variantName}
                        </p>
                        <LineDivider />
                      </div>
                    );
                  })}
                </ProductDiv>
              );
            })
          ) : isRequestingGetProductsVariants ? (
            <LoadingBlueLarge />
          ) : !productsVariants.length ? (
            <span style={{ fontStyle: 'italic' }}>
              There are no products in selected category
            </span>
          ) : (
            (filterState.search === ''
              ? (productsVariantsModified = productsVariants)
              : (productsVariantsModified = filterState.filteredProducts),
            productsVariantsModified.map((productVariants, index) => {
              // productsVariants.map((productVariants, index) => {
              return (
                <ProductDiv key={index}>
                  <ProductImageRow>
                    <ProductImg src={productVariants.productImageUrl} />
                    <div
                      style={
                        !productVariants.productIsActive
                          ? {
                              color: '#979797',
                              display: 'inline-flex',
                            }
                          : { color: 'inherit' }
                      }
                    >
                      {productVariants.productName}
                      {!productVariants.productIsActive && (
                        <Tooltip title="The product is inactive">
                          <div>
                            <Icon
                              className="fal fa-info-circle"
                              style={{
                                cursor: 'inherit',
                                fontSize: '16px',
                                marginLeft: '10px',
                                marginTop: '5px',
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </ProductImageRow>
                  {productVariants.productVariants.map((productVariant, i) => {
                    return (
                      <div
                        style={{ marginLeft: '30px' }}
                        key={productVariant.variantId}
                      >
                        <p>
                          <Checkbox
                            // name={`comboItems[${index}].productVariants[${productVariantIndex}].variantPrice`}
                            checked={
                              !!(
                                selectedProductVariants[
                                  productVariants.productId
                                ] &&
                                selectedProductVariants[
                                  productVariants.productId
                                ].includes(productVariant.variantId)
                              )
                            }
                            onChange={
                              () =>
                                onToggleSelect(
                                  productVariants.productId,
                                  productVariant.variantId
                                )
                              // setFieldValue(
                              //   `comboItems[${index}].productVariants`,
                              //   {
                              //     productId: productVariants.id,
                              //     variantId: productVariant.variantId
                              //   }
                              // )
                            }
                            color="primary"
                          />
                          {productVariant.variantName}
                        </p>
                        <LineDivider />
                      </div>
                    );
                  })}
                </ProductDiv>
              );
            }))
          )}
        </ModalBody>
      </DialogContent>
      <PaginationWrapper>
        <PaginationControlProduct
          response={{
            ...filterState.filteredProducts,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: filterState?.filteredProducts?.length,
          }}
          requestParams={requestParams}
          updateAction={getAllProducts}
          type="combos"
        />
      </PaginationWrapper>
      <DialogActions>
        <ButtonsWrapper>
          {/* <Button secondary onClick={() => toggleProductModal()}>
            Cancel
          </Button> */}
          <Button
            onClick={() => {
              toggleProductModal();
              setFieldValue(
                `comboItems[${index}].productVariants`,
                getFinalState(selectedProductVariants)
              );
            }}
          >
            Close
          </Button>
        </ButtonsWrapper>
      </DialogActions>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  requestParams: state.combosReducer.requestParams,
});
export default connect(mapStateToProps, { updateCombosRequestParams })(
  AddProductsModal
);
