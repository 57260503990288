import React from 'react';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import Card from '../../../../components/Card/Card';
import ProductCard from './ProductCard';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import { Button, ButtonRow } from 'merchant-portal-components';

const CardWrapper = styled(Card)`
  display: grid;
  grid-gap: 32px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;

const ProductsWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 20px;
  border-radius: 4px;
`;

const AddProductBox = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 2px;
  height: 200px;
  width: 200px;
  border: dashed 1px #8a8e8c;
  cursor: pointer;
  padding: 20px;
  color: ${(props) => props.theme.blue};
  font-weight: 600;
`;

const SortableItem = SortableElement(
  ({ value, removeProductFromUpsellMenuDraft }) => (
    <li style={{ position: 'relative', listStyle: 'none', cursor: 'move' }}>
      <ProductCard
        clickToRemove={() => removeProductFromUpsellMenuDraft(value.id)}
        imageUrl={value.image_url}
        productName={value.name}
        onHover
      />
    </li>
  )
);

const SortableList = SortableContainer(
  ({ items, toggleProductSelector, removeProductFromUpsellMenuDraft }) => {
    return (
      <ProductsWrapper>
        <AddProductBox onClick={toggleProductSelector}>
          Add Products to Upsell Menu +
        </AddProductBox>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            removeProductFromUpsellMenuDraft={removeProductFromUpsellMenuDraft}
          />
        ))}
      </ProductsWrapper>
    );
  }
);

const UpsellIndex = ({
  upsellMenu,
  upsellMenuDraft,
  removeProductFromUpsellMenuDraft,
  sortUpsellMenuDraft,
  toggleProductSelector,
  undoUnsavedUpsellMenuChanges,
  editUpsellMenu,
}) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    sortUpsellMenuDraft(arrayMove(upsellMenuDraft, oldIndex, newIndex));
  };

  const changesHaveBeenMadeToUpsellMenu =
    JSON.stringify(upsellMenu) !== JSON.stringify(upsellMenuDraft);

  return (
    <CardWrapper>
      <div>
        <div style={{ fontWeight: 'bold', marginBottomn: '5px' }}>
          View and Sort Upsell Products
        </div>
        <div>Have these products show up on checkout </div>
      </div>
      <div style={{ height: '45px' }}>
        <Collapse in={changesHaveBeenMadeToUpsellMenu} style={{ width: '90%' }}>
          <ButtonRow justifyContent='space-between'>
            <Button secondary onClick={undoUnsavedUpsellMenuChanges}>
              Cancel
            </Button>
            <Button onClick={() => editUpsellMenu(upsellMenuDraft)}>
              Save
            </Button>
          </ButtonRow>
        </Collapse>
      </div>

      <SortableList
        items={upsellMenuDraft}
        onSortEnd={onSortEnd}
        axis='xy'
        distance={1}
        toggleProductSelector={toggleProductSelector}
        removeProductFromUpsellMenuDraft={removeProductFromUpsellMenuDraft}
      />
    </CardWrapper>
  );
};

export default UpsellIndex;
