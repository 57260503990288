import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, Field, FieldArray } from 'formik';
import {
  submitPOSDiscountValuesCombos,
  getCombos,
  getComboItemGroup,
  getComboItemGroupType,
  getComboItemGroupPOSMapping,
  updateComboItemGroupPOSMapping,
} from '../posAction';
import { LoadingBlueLarge } from 'merchant-portal-components';
import fetchOnParamChange from '../../../HOCs/fetchOnParamChange';
import ComboItemGroupMappingTable from './components/ComboItemGroupMappingTable';

const Wrapper = styled.div`
  width: 1000px;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-top: 24px;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;
const mappingSchema = Yup.object().shape({
  mappings: Yup.array()
    .of(
      Yup.object().shape({
        pos_component_id: Yup.string().required('Required'),
      })
    )
    .min(1, 'Required'),
});
let CombosMappingContainer = ({
  meta,
  selectedOption,
  isRequestingGetCombos,
  getCombos,
  updateRequestParams,
  requestParams,
  submitPOSDiscountValuesCombos,
  type,
  selectedValuePOS,
  setSelectedValuePOS,
  allCombos,
  getComboItemGroupType,
  comboItemsGroupType,
  getComboItemGroup,
  comboItemsGroup,
  getComboItemGroupPOSMapping,
  comboItemsGroupPOSMapping,
  updateComboItemGroupPOSMapping,
  selecteItemGroup,
}) => {
  const [initialValues, setInitialValues] = useState([]);
  const [posMappoingFileds, setposMappoingFileds] = useState();
  const selectedComboId = selectedOption?.id;
  useEffect(() => {
    if (selectedComboId) {
      getComboItemGroupType();
      getComboItemGroup(selectedComboId);
    }
  }, [selectedComboId]);
  const initializePOSValues = useCallback(() => {
    let initialVal;
    const newMappings = [];
    comboItemsGroup.forEach((item) => {
      const newPOS = {
        combo_item_group_id: item.id,
        pos_component_id: item.pos_mappings[0]?.pos_component_id || '',
      };
      newMappings.push(newPOS);
    });
    initialVal = { mappings: newMappings };
    setInitialValues(initialVal);
  }, [comboItemsGroup, selecteItemGroup]);
  useEffect(() => {
    initializePOSValues();
  }, [initializePOSValues]);

  useEffect(() => {
    const fields = comboItemsGroupType.reduce((acc, cur, i) => {
      acc[cur.key] = cur;
      return acc;
    }, {});
    setposMappoingFileds(fields);
  }, [comboItemsGroupType]);
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={mappingSchema}
    >
      {({ values, handleReset, dirty, errors, setFieldValue, handleSave }) => (
        <Fragment>
          <Wrapper>
            {!isRequestingGetCombos && posMappoingFileds ? (
              <ComboItemGroupMappingTable
                meta={meta}
                requestParams={requestParams}
                updateRequestParams={updateRequestParams}
                initialRequestParams={initialRequestParams}
                dirty={dirty}
                handleReset={handleReset}
                submitPOSDiscountValuesCombos={submitPOSDiscountValuesCombos}
                values={values}
                initialValues={initialValues}
                getCombos={getCombos}
                type={type}
                selectedValuePOS={selectedValuePOS}
                setSelectedValuePOS={setSelectedValuePOS}
                allCombos={allCombos}
                posDiscountsMappingFields={posMappoingFileds}
                posDiscountsFieldsNum={
                  posMappoingFileds && Object.keys(posMappoingFileds).length
                }
                comboItemsGroup={comboItemsGroup}
                selectedComboId={selectedComboId}
                comboItemsGroupType={comboItemsGroupType}
                comboItemsGroupPOSMapping={comboItemsGroupPOSMapping}
                getComboItemGroupPOSMapping={getComboItemGroupPOSMapping}
                updateComboItemGroupPOSMapping={updateComboItemGroupPOSMapping}
                errors={errors}
                setFieldValue={setFieldValue}
              />
            ) : (
              <LoadingBlueLarge />
            )}
          </Wrapper>
        </Fragment>
      )}
    </Formik>
  );
};

const initialRequestParams = {
  page_number: 1,
  page_size: 2000,
  searchString: '',
};

CombosMappingContainer = fetchOnParamChange(
  CombosMappingContainer,
  getCombos,
  initialRequestParams
);

const mapStateToProps = (state) => ({
  meta: state.posReducer.meta,
  isRequestingGetCombos: state.posReducer.isRequestingGetCombos,
  combos: state.posReducer.combos,
  allCombos: state.combosReducer.combosList,
  selectedValuePOS: state.posReducer.selectedValuePOS,
  comboItemsGroupType: state.posReducer.comboItemsGroupType,
  comboItemsGroup: state.posReducer.comboItemsGroup,
  comboItemsGroupPOSMapping: state.posReducer.comboItemsGroupPOSMapping,
  selecteItemGroup: state.posReducer.selecteItemGroup,
});

export default connect(mapStateToProps, {
  getCombos,
  submitPOSDiscountValuesCombos,
  getComboItemGroup,
  getComboItemGroupType,
  getComboItemGroupPOSMapping,
  updateComboItemGroupPOSMapping,
})(CombosMappingContainer);
