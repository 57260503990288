// Global roles
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_BUSINESS_ADMIN = 'ROLE_BUSINESS_ADMIN';
export const ROLE_MARKETING_ADMIN = 'ROLE_MARKETING_ADMIN';
export const ROLE_FINANCE_ADMIN = 'ROLE_FINANCE_ADMIN';
export const ROLE_BUSINESS_SMOOTHPAY = 'ROLE_BUSINESS_SMOOTHPAY';
export const ROLE_LOYALTY_ADMIN = 'ROLE_LOYALTY_ADMIN';

// Roles pertaining to certain clients only
export const ROLE_OPERATOR = 'ROLE_OPERATOR';
const roles = {
  ROLE_SUPER_ADMIN,
  ROLE_ADMIN,
  ROLE_BUSINESS_ADMIN,
  ROLE_MARKETING_ADMIN,
  ROLE_FINANCE_ADMIN,
  ROLE_BUSINESS_SMOOTHPAY,
  ROLE_OPERATOR,
  ROLE_LOYALTY_ADMIN,
};
export default roles;
