import React from 'react';
import { StyledText } from '../../../../../../components/StyledText/StyledText';
import toPriceFormat from '../../../../../../utils/toPriceFormat';

const VariantSummary = ({ productVariantsInitialValues, allVariants }) => {
  let variantsToRender = [];
  productVariantsInitialValues.map((originalProductVariant) => {
    let foundGlobalVariant = allVariants.find(
      (globalVariant) => globalVariant.id === originalProductVariant.product_variant_id
    );
    if (foundGlobalVariant) {
      variantsToRender = [
        ...variantsToRender,
        {
          name: foundGlobalVariant.name,
          label: foundGlobalVariant.label,
          price: toPriceFormat(originalProductVariant.price),
          is_default: originalProductVariant.is_default,
          barcode: originalProductVariant.barcode,
        },
      ];
    } else {
      variantsToRender = [...variantsToRender, `Variant Deleted`];
    }
  });
  return (
    <div style={{ display: 'grid', gridGap: '8px' }}>
      {variantsToRender.map((variant, index) => (
        <div key={index} style={{ display: 'grid', gridTemplateColumns: '5fr 2fr 5fr 2fr' }}>
          <div>
            {`${variant.name} - `}
            <StyledText fontWeight="300" fontStyle="italic">
              {variant.label}
            </StyledText>
          </div>
          <div>{variant.price}</div>
          <div>{variant.barcode}</div>
          {variant.is_default && <div>- Default</div>}
        </div>
      ))}
    </div>
  );
};

export default VariantSummary;
