import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as mediaActions from '../mediaActions';
import ImageForm from '../components/ImageForm';

class CreateImage extends Component {
  state = {
    name: '',
    altText: '',
    src: '',
    file: null,
    croppedImageUrl: '',
    crop: {
      unit: '%',
      width: 30,
      aspect: null,
    },
  };

  componentDidMount() {
    const { imageBeingEdited } = this.props;
    this.setState({
      name: imageBeingEdited.name,
      altText: imageBeingEdited.alt_text,
      url: imageBeingEdited.url,
      mediaId: imageBeingEdited.media_id,
      src: imageBeingEdited.url,
    });
  }

  onSubmit = () => {
    if (this.state.mediaId && this.state.name) {
      this.props.editImage(
        this.state.name,
        this.state.altText,
        this.state.mediaId
      );
    }
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({
      crop: {
        ...percentCrop,
        aspect: null,
      },
    });
  };

  onChangeToIncompleteCropClick = (aspectRatio) => {
    this.setState({
      crop: {
        aspect: aspectRatio,
        unit: '%',
        width: 100,
      },
    });
  };

  makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg').then(
        (croppedImageUrl) => this.setState({ croppedImageUrl })
      );
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  handleCropSubmit = () => {
    this.setState({
      file: this.state.croppedImageUrl,
      src: null,
      croppedImageUrl: null,
    });
  };

  changeImageName = (event) => {
    const maxLength = 30;
    if (event.target.value.length <= maxLength) {
      this.setState({ name: event.target.value });
    } else if (event.target.value.length > maxLength) {
      this.setState({
        nameError: `Image name can't be more than ${maxLength} characters`,
      });
    }
    if (event.target.value.length < maxLength) {
      this.setState({ nameError: '' });
    }
  };

  handleCropCancel = () => {
    this.setState({ src: null, croppedImageUrl: null });
  };

  changeAltText = (event) => {
    const maxLength = 255;
    if (event.target.value.length <= maxLength) {
      this.setState({ altText: event.target.value });
    } else if (event.target.value.length > maxLength) {
      this.setState({
        altError: `Image alt text can't be more than ${maxLength} characters`,
      });
    }
    if (event.target.value.length < maxLength) {
      this.setState({ altError: '' });
    }
  };

  render() {
    const { hideEditImageForm } = this.props;
    const submitIsDisabled = !this.state.name;

    return (
      <ImageForm
        subTitle='Edit Existing Media'
        subHeading='Edit the Name or Alt Text of your uploaded images. You are not able to
          delete or replace images that have already been uploaded at this time.'
        cancelForm={hideEditImageForm}
        submitIsDisabled={submitIsDisabled}
        changeImageName={this.changeImageName}
        nameError={this.state.nameError}
        altError={this.state.altError}
        changeAltText={this.changeAltText}
        onSubmit={this.onSubmit}
        formValues={this.state}
        isEditing={true}
        submitButtonText='Update'
        crop={this.state.crop}
        onImageLoaded={this.onImageLoaded}
        onCropComplete={this.onCropComplete}
        onChangeToIncompleteCropClick={this.onChangeToIncompleteCropClick}
        onCropChange={this.onCropChange}
        handleCropSubmit={this.handleCropSubmit}
        handleCropCancel={this.handleCropCancel}
        src={this.state.src}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    imageBeingEdited: state.mediaReducer.imageBeingEdited,
  };
}

export default connect(mapStateToProps, mediaActions)(CreateImage);
