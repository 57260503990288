import React from "react";
import styled from "styled-components";
import { Field } from "formik";
import {
  RenderTextField,
  required
} from "../../../../components/FormElements/FormikElements";

const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 32px;
  background: white;
  display: grid;
  grid-gap: 24px;
`;

const NameAndNotesForm = ({}) => {
  return (
    <Card>
      <div style={{ width: "50%" }}>
        <Field
          name="template_name"
          component={RenderTextField}
          label="Layout Name"
          required
          validate={required}
        />
      </div>
      <div>
        <Field
          name="notes"
          component={RenderTextField}
          label="Add Notes (Optional)"
        />
      </div>
    </Card>
  );
};

export default NameAndNotesForm;
