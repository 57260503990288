import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.darkBlue};
  text-align: center;
`;

const NoDataFound = (props) => (
  <Wrapper>
    <div>
      <h1>{props.message || 'No Data Found'}</h1>
      <Link to={props.rootItem.pathname}>Go to {props.rootItem.name}</Link>
    </div>
  </Wrapper>
);

export default NoDataFound;
