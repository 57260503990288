import api from '../../../api';
import { createAlert } from '../../Alert/actions';
import moment from 'moment';
import { formatErrorMessage } from '../../../utils/formatErrorMessage';

import {
  GET_COUPONS_REQUEST,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_FAILURE,
  INITIATE_CREATE_COUPON,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAILURE,
  CANCEL_COUPON,
  INITIATE_EDIT_COUPON,
  EDIT_COUPON_REQUEST,
  EDIT_COUPON_SUCCESS,
  EDIT_COUPON_FAILURE,
  TOGGLE_IS_ACTIVE_COUPON,
  TOGGLE_IS_ACTIVE_COUPON_SUCCESS,
  TOGGLE_IS_ACTIVE_COUPON_FAILURE,
  SEARCH_CUSTOMER_REQUEST,
  SEARCH_CUSTOMER_SUCCESS,
  CLEAR_CUSTOMER_SEARCH,
  GET_CUSTOMER_DETAIL_REQUEST,
  GET_CUSTOMER_DETAIL_SUCCESS,
  GET_CUSTOMER_DETAIL_FAILURE,
  UPDATE_COUPONS_REQUEST_PARAMS,
  UPDATE_SORTED_COUPONS_LIST,
  RESET_STORE_VALUE,
} from './types';
import { getSearchUrl } from '../../../utils/purePayload';

export function fetchCoupons(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch) {
    dispatch({ type: GET_COUPONS_REQUEST });
    const urlwithOutSearchString = `${ROOT_URL}/coupons?page_number=${requestParams.page_number}&page_size=${requestParams.page_size}`;
    const url = getSearchUrl(
      urlwithOutSearchString,
      requestParams.search_string,
      3
    );
    return api.get(url).then(
      (response) => {
        const hasNextPage = response.data.meta.has_next_page;
        const keepSortedCoupon = JSON.parse(JSON.stringify(response.data.data));
        dispatch({
          type: GET_COUPONS_SUCCESS,
          payload: response.data,
          keepSortedCoupon: keepSortedCoupon,
          hasNextPage: hasNextPage,
        });
      },
      (error) => {
        dispatch({ type: GET_COUPONS_FAILURE, payload: error });
      }
    );
  };
}

export function createRefCouponPayload(coupon) {
  let payload = { ...coupon };
  delete payload.coupon_code;
  delete payload.coupon_id;
  delete payload.coupon_redeemed;
  return { coupon_details: payload };
}
export function createCouponPayload(coupon) {
  let payload = { ...coupon };
  if (payload.coupon_details.template_values.start_date) {
    payload.coupon_details.template_values.start_date = moment(
      payload.coupon_details.template_values.start_date
    ).format('YYYY-MM-DD');
  }
  if (payload.coupon_details.template_values.end_date) {
    payload.coupon_details.template_values.end_date = moment(
      payload.coupon_details.template_values.end_date
    ).format('YYYY-MM-DD');
  }
  const segmentsData = payload.selectedSegments;
  delete payload.coupon_details.coupon_redeemed;
  delete payload.selectedSegments;
  delete payload.allUsers;
  delete payload.unlimitedRedemption;

  payload.coupon_id && delete payload.coupon_id;
  payload.coupon_details.coupon_id && delete payload.coupon_details.coupon_id;
  payload.segments = segmentsData;
  return payload;
}

export function createCoupon(coupon) {
  return function (dispatch) {
    if (
      coupon.coupon_details.template_values.start_date &&
      coupon.coupon_details.template_values.end_date
    ) {
      if (
        moment(
          coupon.coupon_details.template_values.end_date,
          'YYYY-MM-DD'
        ).isBefore(coupon.coupon_details.template_values.start_date)
      ) {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Credit code expiry date must be before the start date',
          })
        );
        return;
      }
    }

    dispatch({ type: CREATE_COUPON_REQUEST });

    return api.post('/coupons', createCouponPayload(coupon)).then(
      (response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Credit code successfully created.',
          })
        );
        dispatch({ type: CREATE_COUPON_SUCCESS });
      },
      (error) => {
        dispatch(
          createAlert({
            type: 'error',
            message:
              formatErrorMessage(error) ||
              'An error occurred trying to create credit code.',
            dangerouslySetInnerHTML: true,
          })
        );
        dispatch({ type: CREATE_COUPON_FAILURE, error });
      }
    );
  };
}

// export const createCouponStructure = coupon => {
//   let template_name = 'unlimited-time';

//   if (!coupon.unlimited_time) {
//     template_name = 'limited-time';
//   }

//   let template_values = {
//     total_amount: coupon.total_amount,
//     type: coupon.type
//   };
//   if (coupon.limit_redemption !== null) {
//     template_values = {
//       ...template_values,
//       limit_redemption: parseInt(coupon.limit_redemption)
//     };
//   }

//   let newCouponStructure = {
//     coupon_details: {
//       coupon_code: coupon.coupon_code,
//       template_name: template_name,
//       template_values: template_values,
//       label: coupon.label,
//       description: coupon.description,
//       is_active: coupon.is_active ? true : false
//     },
//     businesses: coupon.businesses,
//     customers: coupon.customers
//   };

//   if (!coupon.unlimited_time) {
//     newCouponStructure = {
//       ...newCouponStructure,
//       coupon_details: {
//         ...newCouponStructure.coupon_details,
//         template_values: {
//           ...newCouponStructure.coupon_details.template_values,
//           start_date: moment(coupon.start_date).format('YYYY-MM-DD'),
//           end_date: moment(coupon.end_date).format('YYYY-MM-DD')
//         }
//       }
//     };
//   }
//   return newCouponStructure;
// };

export function initiateCreateCoupon() {
  return function (dispatch) {
    dispatch({ type: INITIATE_CREATE_COUPON });
  };
}

export function editRefCoupon(coupon) {
  if (coupon.template_name !== 'referral') {
    delete coupon.referral_message;
  }
  return function (dispatch) {
    dispatch({ type: EDIT_COUPON_REQUEST });
    return api
      .patch(
        `${ROOT_URL}/coupons/${coupon.coupon_id}`,
        createRefCouponPayload(coupon)
      )
      .then(
        (response) => {
          dispatch(
            createAlert({
              type: 'success',
              message: 'Referral Credit Code successfully updated.',
            })
          );
          dispatch({ type: EDIT_COUPON_SUCCESS });
        },
        (error) => {
          dispatch(
            createAlert({
              type: 'error',
              message:
                error?.response?.data?.errors?.message ||
                'An error occurred trying to update Referral Credit Code.',
            })
          );
          dispatch({ type: EDIT_COUPON_FAILURE, error });
        }
      );
  };
}

export function editCoupon(coupon) {
  if (coupon.coupon_details.template_name !== 'referral') {
    delete coupon.coupon_details.referral_message;
  }
  return function (dispatch) {
    if (
      coupon.coupon_details.template_values.start_date &&
      coupon.coupon_details.template_values.end_date
    ) {
      if (
        moment(
          coupon.coupon_details.template_values.end_date,
          'YYYY-MM-DD'
        ).isBefore(coupon.coupon_details.template_values.start_date)
      ) {
        dispatch(
          createAlert({
            type: 'error',
            message: 'Credit Code expiry date must be before the start date',
          })
        );
        return;
      }
    }
    dispatch({ type: EDIT_COUPON_REQUEST });
    return api
      .patch(
        `${ROOT_URL}/coupons/${coupon.coupon_id}`,
        createCouponPayload(coupon)
      )
      .then(
        (response) => {
          dispatch(
            createAlert({
              type: 'success',
              message: 'Credit Code successfully updated.',
            })
          );
          dispatch({ type: EDIT_COUPON_SUCCESS });
        },
        (error) => {
          dispatch(
            createAlert({
              type: 'error',
              message:
                formatErrorMessage(error) ||
                'An error occurred trying to update credit code.',
              dangerouslySetInnerHTML: true,
            })
          );
          dispatch({ type: EDIT_COUPON_FAILURE, error });
        }
      );
  };
}

export function toggleIsActiveCoupon(coupon) {
  return function (dispatch) {
    dispatch({ type: TOGGLE_IS_ACTIVE_COUPON, payload: coupon });
    return api
      .patch(`/coupons/${coupon.coupon_details.coupon_id}`, {
        coupon_details: {
          coupon_code: coupon.coupon_details.coupon_code,
          label: coupon.coupon_details.label,
          template_name: coupon.coupon_details.template_name,
          template_values: coupon.coupon_details.template_values,
          is_active: !coupon.coupon_details.is_active,
        },
      })
      .then(
        () => {
          dispatch(
            createAlert({
              type: 'success',
              message: 'Credit code updated successfully.',
            })
          );
          dispatch({ type: TOGGLE_IS_ACTIVE_COUPON_SUCCESS, payload: coupon });
        },
        (error) => {
          dispatch(
            createAlert({
              type: 'error',
              message:
                formatErrorMessage(error) ||
                'An error occurred trying to delete credit code.',
              dangerouslySetInnerHTML: true,
            })
          );
          dispatch({
            type: TOGGLE_IS_ACTIVE_COUPON_FAILURE,
            payload: coupon,
          });
        }
      );
  };
}

export function cancelCoupon() {
  return function (dispatch) {
    dispatch({ type: CANCEL_COUPON });
  };
}

export function initiateEditCoupon(coupon) {
  return function (dispatch) {
    dispatch({ type: INITIATE_EDIT_COUPON, payload: coupon });
  };
}

export function searchCustomers(queryString, segments) {
  return function (dispatch) {
    dispatch({ type: SEARCH_CUSTOMER_REQUEST });
    const page_number = 1;
    const page_size = 100;
    const search_string = queryString; //'Bob';
    if (segments && segments.length) {
      segments = `&segments=${segments}`;
    } else {
      segments = '';
    }
    const urlwithOutSearchString = `/customers?page_number=${page_number}&page_size=${page_size}`;
    const url = getSearchUrl(urlwithOutSearchString, search_string, 3);

    return api
      .get(
        `${url}${segments}` //`&segments=${segments}`
      )
      .then((response) => {
        dispatch({ type: SEARCH_CUSTOMER_SUCCESS, payload: response.data });
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
}

export function clearCustomerSearch() {
  return function (dispatch) {
    dispatch({ type: CLEAR_CUSTOMER_SEARCH });
  };
}
export function updateCouponsRequestParams(requestParams) {
  return {
    type: 'UPDATE_COUPONS_REQUEST_PARAMS',
    payload: requestParams,
  };
}

export function updateSortedCouponsList(couponsList, sortOrder) {
  return {
    type: 'UPDATE_SORTED_COUPONS_LIST',
    couponsList: couponsList,
    sortOrder: sortOrder,
  };
}

export function getCustomerDetail(couponId) {
  return function (dispatch) {
    dispatch({
      type: GET_CUSTOMER_DETAIL_REQUEST,
    });
    api.get(`/coupons/${couponId}/customers`).then((response) => {
      dispatch({
        type: GET_CUSTOMER_DETAIL_SUCCESS,
        payload: response.data.data,
      });
    });
  };
}

export function resetStoreValue() {
  return {
    type: RESET_STORE_VALUE,
  };
}
