import styled from "styled-components";

export const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-bottom: 25px;
  text-transform: capitalize;
`;
export const Wrapper = styled.div`
  padding: 20px;
  position: relative;
`;
export const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 35px;
  margin-bottom: 15px;
  width: 100%;
`;
export const TimePickerWrapper = styled.div`
  margin-bottom: 5px;
`;
export const TimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 30px;
  grid-gap: 10px;
  margin-bottom: 12px;
`;
export const DayButton = styled.button`
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: none;
  cursor: pointer;
  color: ${(props) => (props.isSelected ? "#ffffff" : "#000000")};
  background-color: ${(props) => (props.isSelected ? "#2440ae" : "#ebebeb")};
  &:hover {
    color: #ffffff;
    background-color: #3151d4;
  }
  &:disabled {
    color: #ffffff;
    background-color: #969eaa;
  }
`;
export const ClosedWrapper = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 10px;
`;
export const CloseIcon = styled.i`
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;
export const RemoveTimeIcon = styled.i`
  font-size: 16px;
  cursor: pointer;
  margin: auto;
`;
export const ButtonRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.align === "left" || props.align === undefined
      ? "flex-start"
      : "flex-end"};
`;
export const AddHoursButton = styled.button`
  color: #2440ae;
  font-weight: bold;
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  &:hover {
    color: #2f4fcc;
  }
`;
export const CopyButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 15px 15px 0;
  margin-top: 15px;
`;
export const CopyHourButton = styled.button`
  color: #2440ae;
  font-weight: bold;
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  &:hover {
    color: #2f4fcc;
  }
`;
export const CopyHoursWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
`;
export const HoursRange = styled.div`
  display: flex;
  align-items: center;
`;
export const Divider = styled.hr`
  border-top: 1px solid #e1e1e1;
`;
export const CopyButton = styled.button`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  border-radius: 28px;
  cursor: pointer;
  background-color: #2440ae;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  &:hover {
    background-color: #2f4fcc;
  }
  &:disabled {
    background-color: #969eaa;
  }
`;
