import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import {
  RenderTextField,
  required,
  RenderSelect,
} from '../../../../../components/FormElements/FormikElements';
import { HrLine, StyledLabel } from 'merchant-portal-components';


const FieldWrapper = styled.div`
  grid-column: span ${(props) => props.col};
`;

const RewardOptionsReferralForm = ({
  programType,
  setIsEditingCustomers,
}) => {
  return (
    <Fragment>
      <FieldWrapper col={4}>
        <StyledLabel>Reward Options</StyledLabel>
      </FieldWrapper>
      <FieldWrapper col={1}>
        <Field
          name='coupon_details.template_values.rewards_type'
          component={RenderSelect}
          style={{ position: 'relative', top: '-4px' }}
          options={
            programType === 'points'
              ? [
                { value: 'money', label: 'Fixed Dollar Credit' },
                {
                  value: 'points',
                  label: 'Fixed Loyalty Credit (points)',
                },
              ]
              : [{ value: 'money', label: 'Fixed Dollar Credit' }]
          }
          placeholder='Type Of Reward *'
          validate={required}
          onChange={() => {
            setIsEditingCustomers(true);
          }}
        />
      </FieldWrapper>
      <FieldWrapper col={1}>
        <Field
          name='coupon_details.template_values.conditions.referee.reward'
          label='Referee Reward Value *'
          component={RenderTextField}
          type='number'
          min='0'
          validate={required}
          onChange={() => {
            setIsEditingCustomers(true);
          }}
        />
      </FieldWrapper>
      <FieldWrapper col={1}>
        <Field
          name='coupon_details.template_values.conditions.referrer.reward'
          label='Referrer Reward Value *'
          component={RenderTextField}
          type='number'
          min='0'
          validate={required}
          onChange={() => {
            setIsEditingCustomers(true);
          }}
        />
      </FieldWrapper>
      <FieldWrapper col={4}>
        <HrLine />
      </FieldWrapper>
    </Fragment>
  );
};
export default RewardOptionsReferralForm;
