import React from 'react';
import styled from 'styled-components';

const NoDataMessageWrapper = styled.div`
  min-width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
`;

const NoDataMessage = ({  isActive, type, len, lenObj, searchString, showArchivedData }) => {
  const ifOnActiveTab = searchString
    ? `No active ${type} found for '${searchString}'. Try checking the Inactive and Archive tabs for more results.`
    : `No active ${type} found. Try checking the Inactive and Archive tabs for more results.`;

  const ifOnInactiveTab = searchString
    ? `No inactive ${type}  found for '${searchString}'. Try checking the Active and Archive tabs for more results.`
    : `No inactive ${type} found. Try checking the Active or Archive tabs for more results.`;

  const ifOnArchivedTab = searchString
    ? `No archived ${type} found for '${searchString}'. Try checking the Active and Inactive tabs for more results.`
    : `No archived ${type} found. Try checking the Active or Inactive tabs for more results.`;
  let messageToShow;
  if (len === 0) {

    if (showArchivedData) {
      messageToShow = ifOnArchivedTab;
    } else if (isActive) {
      messageToShow = ifOnActiveTab;
    } else {
      messageToShow = ifOnInactiveTab;
    }

    return (
      <NoDataMessageWrapper>
        {messageToShow}
      </NoDataMessageWrapper>
    );
  } else {
    if (lenObj?.act == 0 && isActive == true) {
      messageToShow = ifOnActiveTab;
    } else if (lenObj?.inact == 0 && !isActive) {
      messageToShow = ifOnInactiveTab;
    }

    return (
      <NoDataMessageWrapper>
        {messageToShow}
      </NoDataMessageWrapper>
    );
  }

  return null;
};

export default NoDataMessage;
