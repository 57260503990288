import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import fetchOnParamChange from '../../HOCs/fetchOnParamChange';
import exportCSVWrapper from '../../HOCs/exportCSVWrapper';
import * as actions from './feedbackActions';
import {
  updateOrderId,
  updateCurrentPage,
} from '../../OrderManager/OrderHistoryList/actions';
import {
  updateCustomerCurrentPage,
  updateCustomerId,
} from '../../CustomerManager/CustomerList/customerListActions';
import FeedbackHistory from './components/FeedbackHistory';
import { handleRedirectFromCustomerDetail } from '../../CustomerManager/CustomerDetails/customerActions';
import { handleRedirectFromOrderHistoryDetail } from '../../OrderManager/OrderDetails/actions';

export class FeedbackContainer extends Component {
  componentDidMount() {
    track.page('customer-service-feedback');
  }
  handleActionButtonClick = (orderId) => {
    this.props.handleRedirectFromOrderHistoryDetail('customer-feedback');
    this.props.updateOrderId(orderId);
    this.props.updateCurrentPage('detail');
    this.props.history.push(`/order-history`);
  };
  handleRedirectToCustomerDetail = (customerId) => {
    this.props.handleRedirectFromCustomerDetail('customer-feedback');
    this.props.updateCustomerId(customerId);
    this.props.updateCustomerCurrentPage('detail');
    this.props.history.push(`/customers`);
  };

  handleExportClick = () => {
    let params = {
      page_number: this.props.meta.page_number,
      page_size: this.props.meta.page_size,
    };
    let paramsCSV = '';

    Object.entries(params).forEach(([key, value]) => {
      paramsCSV += `${key}=${value}_`;
    });

    paramsCSV = paramsCSV.slice(0, -1);

    this.props.exportCSV({
      requestPath: 'reviews',
      fileName: `feedback-report__${paramsCSV}`,
      requestParams: params,
    });
  };

  render() {
    const { feedbackReducer } = this.props;
    return (
      <Fragment>
        <FeedbackHistory
          data-test="component-FeedbackHistory"
          getCustomerReviewsIsRequesting={
            feedbackReducer.getCustomerReviewsIsRequesting
          }
          response={{
            page_number: this.props.requestParams.page_number,
            page_size: this.props.requestParams.page_size,
          }}
          allBusinesses={this.props.allBusinesses}
          reviews={feedbackReducer.reviews}
          requestParams={this.props.requestParams}
          updateRequestParams={this.props.updateRequestParams}
          handleActionButtonClick={this.handleActionButtonClick}
          handleRedirectToCustomerDetail={this.handleRedirectToCustomerDetail}
          meta={feedbackReducer.meta}
          handleExportClick={this.handleExportClick}
          requestingCSV={this.props.requestingCSV}
        />
      </Fragment>
    );
  }
}

const initialRequestParams = {
  page_number: 1,
  page_size: 25,
};

let FeedbackContainerExport = exportCSVWrapper(FeedbackContainer);

let FeedbackContainerPagination = fetchOnParamChange(
  FeedbackContainerExport,
  actions.getreviews,
  initialRequestParams
);

function mapStateToProps(state) {
  return {
    currentPage: state.orderHistory.currentPage,
    selectedOrderId: state.orderHistory.selectedOrderId,
    feedbackReducer: state.feedbackReducer,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}

export default connect(mapStateToProps, {
  ...actions,
  updateOrderId,
  updateCurrentPage,
  updateCustomerId,
  updateCustomerCurrentPage,
  handleRedirectFromCustomerDetail,
  handleRedirectFromOrderHistoryDetail,
})(FeedbackContainerPagination);
