import {
  REQUEST_SETTLEMENTS,
  RECEIVE_SETTLEMENTS,
  HIDE_TEMPEROARY_SETTLEMENT_BANNER,
} from './types';

export default function (
  state = {
    isFetchingSettlements: false,
    response: null,
    temperoaryShowSettlementBanner: true,
  },
  action
) {
  switch (action.type) {
    case HIDE_TEMPEROARY_SETTLEMENT_BANNER:
      return {
        ...state,
        temperoaryShowSettlementBanner: action.temperoaryShowSettlementBanner,
      };
    case REQUEST_SETTLEMENTS:
      return { ...state, isFetchingSettlements: true };
    case RECEIVE_SETTLEMENTS:
      return {
        ...state,
        isFetchingSettlements: false,
        response: action.payload,
      };
    default:
      return state;
  }
}
