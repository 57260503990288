import React, { useState, useEffect, useCallback } from 'react';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { InputAdornment } from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import {
  Subtitle,
  TimeWrapper,
  ClosedWrapper,
  RemoveTimeIcon,
  AddHoursButton,
  CopyHourButton,
  ButtonWrapper,
} from './styles/specialDayTimeSelectionModalStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CopyHoursModal from '../CopyHoursModal';
const SpecialDayTimeCreatetab = ({
  day,
  openTime1,
  closeTime1,
  openTime2,
  closeTime2,
  openOrderingTime1,
  closeOrderingTime1,
  openOrderingTime2,
  closeOrderingTime2,
  setOpenTime1,
  setCloseTime1,
  setOpenTime2,
  setCloseTime2,
  setOpenOrderingTime1,
  setCloseOrderingTime1,
  setOpenOrderingTime2,
  setCloseOrderingTime2,
  daysRange,
  isLocationClosed,
  setIsLocationClosed,
  isOrderingClosed,
  setIsOrderingClosed,
  handleCloseLocation,
  handleCloseOrdering,
  isLocationHoursSplited,
  setIsLocationHoursSplited,
  isOrderingHoursSplited,
  setIsOrderingHoursSplited,
  error,
  copyLocationHours,
  copyOrderingHours,
  handleCopyHours,
  copyModalType,
  setCopyModalType,
}) => {
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  return (
    <div>
      {daysRange.length > 0 && (
        <>
          <Subtitle>Location Hours</Subtitle>
          <ClosedWrapper>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLocationClosed}
                  onChange={(e) =>
                    handleCloseLocation(
                      e,
                      setOpenTime1,
                      setCloseTime1,
                      setOpenTime2,
                      setCloseTime2,
                      setIsLocationClosed
                    )
                  }
                  name='checkedB'
                  color='primary'
                />
              }
              label='Location Closed'
            />
            {/* <ButtonWrapper align="right">
              {copyLocationHours?.length > 0 && (
                <CopyHourButton
                  onClick={() => {
                    setIsCopyModalOpen(true);
                    setCopyModalType("Location");
                  }}
                >
                  Copy Hours
                </CopyHourButton>
              )}
            </ButtonWrapper> */}
          </ClosedWrapper>
          {!isLocationClosed && daysRange.length > 0 && (
            <>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <TimeWrapper>
                  <KeyboardTimePicker
                    label={'Opening Time'}
                    placeholder='08:00'
                    inputVariant='outlined'
                    // ampm={false}
                    mask='__:__'
                    openTo='hours'
                    format='HH:mm'
                    value={openTime1}
                    onChange={(date) =>
                      setOpenTime1(moment(date).set({ second: 0 }))
                    }
                    keyboardIcon={
                      <InputAdornment>
                        <i className='fa fa-clock'></i>
                      </InputAdornment>
                    }
                    // minutesStep={15}
                    error={error[`openTime${day}1`]}
                    helperText={error[`openTime${day}1`]}
                  />
                  <KeyboardTimePicker
                    label={'Close Time'}
                    inputVariant='outlined'
                    placeholder='17:00'
                    // ampm={false}
                    mask='__:__'
                    openTo='hours'
                    format='HH:mm'
                    value={closeTime1}
                    onChange={(date) =>
                      setCloseTime1(moment(date).set({ second: 0 }))
                    }
                    keyboardIcon={
                      <InputAdornment>
                        <i className='fa fa-clock'></i>
                      </InputAdornment>
                    }
                    error={error[`closeTime${day}1`]}
                    helperText={error[`closeTime${day}1`]}
                  />
                </TimeWrapper>
              </MuiPickersUtilsProvider>
              {isLocationHoursSplited && (
                <>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <TimeWrapper>
                      <KeyboardTimePicker
                        label={'Opening Time'}
                        placeholder='08:00'
                        inputVariant='outlined'
                        // ampm={false}
                        mask='__:__'
                        openTo='hours'
                        format='HH:mm'
                        value={openTime2}
                        onChange={(date) =>
                          setOpenTime2(moment(date).set({ second: 0 }))
                        }
                        keyboardIcon={
                          <InputAdornment>
                            <i className='fa fa-clock'></i>
                          </InputAdornment>
                        }
                        error={error[`openTime${day}2`]}
                        helperText={error[`openTime${day}2`]}
                      />
                      <KeyboardTimePicker
                        label={'Close Time'}
                        inputVariant='outlined'
                        placeholder='17:00'
                        // ampm={false}
                        mask='__:__'
                        openTo='hours'
                        format='HH:mm'
                        value={closeTime2}
                        onChange={(date) =>
                          setCloseTime2(moment(date).set({ second: 0 }))
                        }
                        keyboardIcon={
                          <InputAdornment>
                            <i className='fa fa-clock'></i>
                          </InputAdornment>
                        }
                        error={error[`closeTime${day}2`]}
                        helperText={error[`closeTime${day}2`]}
                      />
                      <RemoveTimeIcon
                        className='fal fa-times'
                        onClick={() => setIsLocationHoursSplited(false)}
                      />
                    </TimeWrapper>
                  </MuiPickersUtilsProvider>
                </>
              )}
              {!isLocationHoursSplited && (
                <AddHoursButton onClick={() => setIsLocationHoursSplited(true)}>
                  Split Hours
                </AddHoursButton>
              )}
            </>
          )}
          {!isLocationClosed && openTime1 && closeTime1 && (
            <>
              <Subtitle margin='20px 0 0 0'>Ordering Hours</Subtitle>
              <ClosedWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isOrderingClosed}
                      onChange={(e) =>
                        handleCloseOrdering(
                          e,
                          setOpenOrderingTime1,
                          setCloseOrderingTime1,
                          setOpenOrderingTime2,
                          setCloseOrderingTime2,
                          setIsOrderingClosed
                        )
                      }
                      name='checkedB'
                      color='primary'
                    />
                  }
                  label='Ordering Closed'
                />
                {/* <ButtonWrapper align="right">
                  {copyOrderingHours?.length > 0 && (
                    <CopyHourButton
                      onClick={() => {
                        setIsCopyModalOpen(true);
                        setCopyModalType("Ordering");
                      }}
                    >
                      Copy Hours
                    </CopyHourButton>
                  )}
                </ButtonWrapper> */}
              </ClosedWrapper>

              {!isOrderingClosed && daysRange.length > 0 && (
                <>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                  >
                    <TimeWrapper>
                      <KeyboardTimePicker
                        label={'Opening Time'}
                        placeholder='08:00'
                        inputVariant='outlined'
                        // ampm={false}
                        mask='__:__'
                        openTo='hours'
                        format='HH:mm'
                        value={openOrderingTime1}
                        onChange={(date) =>
                          setOpenOrderingTime1(moment(date).set({ second: 0 }))
                        }
                        keyboardIcon={
                          <InputAdornment>
                            <i className='fa fa-clock'></i>
                          </InputAdornment>
                        }
                        error={error[`openOrderingTime${day}1`]}
                        helperText={error[`openOrderingTime${day}1`]}
                        // minutesStep={15}
                      />
                      <KeyboardTimePicker
                        label={'Close Time'}
                        inputVariant='outlined'
                        placeholder='17:00'
                        // ampm={false}
                        mask='__:__'
                        openTo='hours'
                        format='HH:mm'
                        value={closeOrderingTime1}
                        onChange={(date) =>
                          setCloseOrderingTime1(moment(date).set({ second: 0 }))
                        }
                        keyboardIcon={
                          <InputAdornment>
                            <i className='fa fa-clock'></i>
                          </InputAdornment>
                        }
                        error={error[`closeOrderingTime${day}1`]}
                        helperText={error[`closeOrderingTime${day}1`]}
                      />
                    </TimeWrapper>
                  </MuiPickersUtilsProvider>
                  {isOrderingHoursSplited && (
                    <>
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={MomentUtils}
                      >
                        <TimeWrapper>
                          <KeyboardTimePicker
                            label={'Opening Time'}
                            placeholder='08:00'
                            inputVariant='outlined'
                            // ampm={false}
                            mask='__:__'
                            openTo='hours'
                            format='HH:mm'
                            value={openOrderingTime2}
                            onChange={(date) =>
                              setOpenOrderingTime2(
                                moment(date).set({ second: 0 })
                              )
                            }
                            keyboardIcon={
                              <InputAdornment>
                                <i className='fa fa-clock'></i>
                              </InputAdornment>
                            }
                            error={error[`openOrderingTime${day}2`]}
                            helperText={error[`openOrderingTime${day}2`]}
                          />
                          <KeyboardTimePicker
                            label={'Close Time'}
                            inputVariant='outlined'
                            placeholder='17:00'
                            // ampm={false}
                            mask='__:__'
                            openTo='hours'
                            format='HH:mm'
                            value={closeOrderingTime2}
                            onChange={(date) =>
                              setCloseOrderingTime2(
                                moment(date).set({ second: 0 })
                              )
                            }
                            keyboardIcon={
                              <InputAdornment>
                                <i className='fa fa-clock'></i>
                              </InputAdornment>
                            }
                            error={error[`closeOrderingTime${day}2`]}
                            helperText={error[`closeOrderingTime${day}2`]}
                          />
                          <RemoveTimeIcon
                            className='fal fa-times'
                            onClick={() => setIsOrderingHoursSplited(false)}
                          />
                        </TimeWrapper>
                      </MuiPickersUtilsProvider>
                    </>
                  )}
                  {!isOrderingHoursSplited && (
                    <AddHoursButton
                      onClick={() => setIsOrderingHoursSplited(true)}
                    >
                      Split Hours
                    </AddHoursButton>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {isCopyModalOpen && (
        <CopyHoursModal
          selectedHoursType={copyModalType}
          isCopyModalOpen={isCopyModalOpen}
          setIsCopyModalOpen={setIsCopyModalOpen}
          copyCondidateHours={
            copyModalType === 'Location' ? copyLocationHours : copyOrderingHours
          }
          handleCopyHours={handleCopyHours}
        />
      )}
    </div>
  );
};

export default SpecialDayTimeCreatetab;
