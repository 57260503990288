const getPageName = (pathName) => {
  let pageName = '';
  if (pathName === '/transaction-report') {
    pageName = 'Transaction Report';
  } else if (pathName === '/loyalty-report') {
    pageName = 'Loyalty Program Financial Summary';
  } else if (pathName === '/product-performance-report') {
    pageName = 'Product Performance Report';
  } else if (pathName === '/combo-performance-report') {
    pageName = 'Combo Performance Report';
  } else if (pathName === '/location-transaction-report') {
    pageName = 'Location Transaction Report';
  } else if (pathName === '/customer-report') {
    pageName = 'Customer Report';
  }
  return pageName;
};
export default getPageName;
