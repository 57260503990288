import {
  LOAD_DETAIL_FEE_PAGE,
  LOAD_LIST_FEE_PAGE,
  GET_FEES_SUCCESS,
  CREATE_FEE_SUCCESS,
  PATCH_ACTIVE_SERVICEFEE,
  EDIT_FEE_SUCCESS,
  UPDATE_FEES_REQUEST_PARAMS,
  GET_FEES_OVERRIDE_SUCCESS,
  GET_SPECIFIC_OVERRIDE_SUCCESS,
  EMPTY_SELECTED_OVERRIDE,
  GET_SPECIFIC_FEE_SUCCESS,
  GET_REQUEST_FAILURE,
  UPDATE_FEES_OVERRIDE_LIST,
} from './feesTypes';

const initialState = {
  currentPage: 'list',
  allFees: [],
  allFeeOverrides: [],
  selectedFeeOverride: {},
  isEditing: false,
  requestingGetFees: true,
  meta: { page_number: 1, page_size: 25 },
  editingFee: null,
  productsAssociatedToFee: [],
  createPatchFeeIsRequesting: false,
  isSortingRequested: false,
  subFeeHoursError: [],
  productFeeAssignmentModalIsVisible: false,
  requestParams: {
    page_number: 1,
    page_size: '10',
    sorting_option: 'title-asc',
  },
  serviceFeeIsActive: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_DETAIL_FEE_PAGE:
      return {
        ...state,
        currentPage: 'detail',
        isEditing: action.payload.isEditing,
        editingFee: action.payload.editingFee,
      };
    case LOAD_LIST_FEE_PAGE:
      return {
        ...state,
        currentPage: 'list',
        editingFee: null,
      };
    case GET_SPECIFIC_FEE_SUCCESS:
      return {
        ...state,
        editingFee: action.editingFee,
      };
    case GET_FEES_SUCCESS:
      return {
        ...state,
        allFees: action.allFees,
      };
    case CREATE_FEE_SUCCESS:
      return {
        ...state,
        editingFee: action.editingFee,
        isEditing: action.isEditing,
      };
    case PATCH_ACTIVE_SERVICEFEE:
      return {
        ...state,
        allFees: state.allFees.map((fee) => {
          if (fee.id === action.payload.id) {
            return { ...fee, is_active: action.payload.isActive };
          }
          return fee;
        }),
      };
    case EDIT_FEE_SUCCESS:
      return {
        ...state,
        editingFee: action.payload.editingFee,
      };
    case UPDATE_FEES_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case GET_FEES_OVERRIDE_SUCCESS:
      return {
        ...state,
        allFeeOverrides: action.allFeeOverrides,
      };
    case EMPTY_SELECTED_OVERRIDE:
      return {
        ...state,
        selectedFeeOverride: {},
      };
    case UPDATE_FEES_OVERRIDE_LIST:
      return {
        ...state,
        allFeeOverrides: action.allFeeOverrides,
      };
    case GET_SPECIFIC_OVERRIDE_SUCCESS:
      return {
        ...state,
        selectedFeeOverride: action.selectedFeeOverride,
      };
    case GET_REQUEST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
