import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BusinessLocationsListContainer from './LocationListManager/BusinessLocationsListContainer';
import BusinessWizardContainer from './LocationDetailsManager/BusinessWizard/BusinessWizardContainer';
import { withRouter } from 'react-router';
import * as actions from './LocationListManager/actions';

let BusinessLocationsContainer = ({
  currentPage,
  role,
  isCreatingLocation,
  isEditingLocation,
  updateBusinessLocationsRequestParams,
  getBusinessLocations,
  requestParams,
  ...props
}) => {
  const isBusinessAdminRole =
    role === 'ROLE_BUSINESS_ADMIN' ||
    localStorage.getItem('role') === 'ROLE_BUSINESS_ADMIN';

  const isLoyaltyAdminRole =
    role === 'ROLE_LOYALTY_ADMIN' ||
    localStorage.getItem('role') === 'ROLE_LOYALTY_ADMIN';

  const [searchString, setSearchString] = useState('');
  const [recentSearchKeyEntered, setRecentSearchKeyEntered] = useState(false);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2) {
        setRecentSearchKeyEntered(true);
        getBusinessLocations(newRequestParams);
      }
      if (recentSearchKeyEntered && searchString === '') {
        getBusinessLocations(newRequestParams);
        setRecentSearchKeyEntered(false);
      }
      updateBusinessLocationsRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString, currentPage]);

  useEffect(() => {
    return () => {
      updateBusinessLocationsRequestParams({
        ...requestParams,
        page_number: 1,
      });
      setSearchString('');
    };
  }, []);

  return !isCreatingLocation && !isEditingLocation ? (
    <BusinessLocationsListContainer
      {...props}
      searchString={searchString}
      setSearchString={setSearchString}
      isBusinessAdminRole={isBusinessAdminRole}
    />
  ) : (
    <BusinessWizardContainer
      isBusinessAdminRole={isBusinessAdminRole}
      isLoyaltyAdminRole={isLoyaltyAdminRole}
    />
  );
};

const mapStateToProps = (state) => ({
  role: state.accountReducer.role,
  isCreatingLocation: state.businessLocationDetails.isCreatingLocation,
  isEditingLocation: state.businessLocationDetails.isEditingLocation,

  requestParams: state.businessLocationsList.requestParams,
  currentPage: state.businessLocationsList.currentPage,
});

BusinessLocationsContainer = withRouter(BusinessLocationsContainer);
export default connect(mapStateToProps, actions)(BusinessLocationsContainer);
