import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PaginationControl from '../../../../components/Pagination/PaginationControl';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  TdFlex,
} from 'merchant-portal-components';
import { StyledP, ComponentCard } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import _ from 'lodash';
import { Checkbox } from '@material-ui/core';
import { Icon } from 'merchant-portal-components';
import { TextField, InputAdornment } from '@material-ui/core';

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;

const ContainerDiv = styled.div`
  padding: ${(props) => props.padding || '0 0 0 0'};
  margin: ${(props) => props.margin || '0 0 0 0'};
  width: ${(props) => props.width || 'inherit'};
  min-height: ${(props) => props.height || 'inherit'};
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;
const SearchAndButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 0px 30px 30px 30px;
`;
const StyledTd = styled(Td)`
  padding: 10px 30px 10px 20px;
  text-align: ${(props) => props.align || 'left'};
  /* width: ${(props) => props.width || '100%'}; */
`;

const BusinessLocationsList = (props) => {
  const {
    businessLocationsOfMerchant,
    handleBusinessLocationAction,
    requestingGetBusinessLocationsOfMerchant,
    isBusinessAdminRole,
    requestParams,
    getBusinessLocations,
    allBusinessLocations,
    allBusinessesLength,
    getArchivedBusinesses,
    searchString,
    setSearchString,
    accountReducer,
    token,
  } = props;

  const [sortedBusinesses, setSortedBusinesses] = useState([]);
  const [sorted, setSorted] = useState(['', '']);
  const [sortIndex, setSortIndex] = useState(-1);
  const [sortColumn, setSortColumn] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const updateBusinessList = useCallback(() => {
    // setIsLoading(true);
    let soretedBusinesses;
    if (sorted[0] || sorted[1]) {
      soretedBusinesses = [...allBusinessLocations];
      soretedBusinesses = _.orderBy(
        soretedBusinesses,
        [sortColumn],
        sorted[sortIndex]
      );
    } else {
      soretedBusinesses = [...allBusinessLocations];
    }
    setSortedBusinesses(soretedBusinesses);
  }, [allBusinessLocations, sorted]);

  useEffect(() => {
    updateBusinessList();
  }, [updateBusinessList]);

  const handleSorting = (sortColumn) => {
    setSortColumn(sortColumn);
    const newSortIndex = sortColumn === 'is_active' ? 0 : 1;
    setSortIndex(newSortIndex);
    let newSort = ['', ''];
    if (!sorted[0] && !sorted[1]) {
      newSort[0] = newSortIndex === 0 ? 'asc' : '';
      newSort[1] = newSortIndex === 1 ? 'asc' : '';
    } else if (newSortIndex === 0) {
      newSort[0] =
        sorted[newSortIndex] === ''
          ? 'asc'
          : sorted[newSortIndex] === 'asc'
          ? 'desc'
          : 'asc';
      newSort[1] = '';
    } else if (newSortIndex === 1) {
      newSort[0] = '';
      newSort[1] =
        sorted[newSortIndex] === ''
          ? 'asc'
          : sorted[newSortIndex] === 'asc'
          ? 'desc'
          : 'asc';
    }
    setSorted(newSort);
  };

  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };
  const handleClearSearch = () => {
    setSearchString('');
  };
  useEffect(() => {}, [searchString]);
  // console.log('sortedBusinesses', sortedBusinesses);
  // console.log('isLoading', isLoading);
  // console.log(
  //   'requestingGetBusinessLocationsOfMerchant',
  //   requestingGetBusinessLocationsOfMerchant
  // );
  return (
    <ComponentCard
      style={{
        borderRadius: '12px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.09)',
      }}
    >
      <ContainerDiv padding='32px 32px 0 32px'>
        <StyledP semiBold>Find, Manage, and Edit Locations</StyledP>
        <StyledP>
          Search by Location Name or browse the list to find a Location. Click
          the Edit icon to go to that Location’s Detail Page, where you can edit
          Location info, set Regular and Special Hours, and manage Delivery
          Zones.
        </StyledP>
      </ContainerDiv>
      <ContainerDiv padding='24px 32px 5px  32px'>
        <StyledP semiBold>Search by Internal Location Name</StyledP>
      </ContainerDiv>
      <SearchAndButtonWrapper>
        <TextField
          id='location-search'
          type='text'
          style={{ width: '100%' }}
          variant='outlined'
          value={searchString}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Icon className='fal fa-search' />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='start'>
                <Icon
                  className='fas fa-times-circle'
                  style={{ color: '#193053' }}
                  onClick={handleClearSearch}
                />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          placeholder='Search locations'
        />
        <Button
          style={{ height: '60px' }}
          onClick={() => props.initiateCreateLocation()}
          disabled={isBusinessAdminRole}
        >
          Add Location
        </Button>
      </SearchAndButtonWrapper>

      <div
        style={styles.container}
        onClick={() => {
          getArchivedBusinesses();

          setTimeout(() => {
            props.history.push('/archivedlocations');
          }, [1000]);
        }}
      >
        <svg
          width='12px'
          height='12px'
          style={{ flexShrink: 0 }}
          fill='#2440AE'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 12 12'
        >
          <path d='M11.9475 2.27625L10.9475 0.27625C10.9059 0.19318 10.842 0.123333 10.763 0.0745386C10.684 0.0257446 10.5929 -6.63752e-05 10.5 1.28188e-07H1.5C1.40711 -6.63752e-05 1.31604 0.0257446 1.237 0.0745386C1.15795 0.123333 1.09407 0.19318 1.0525 0.27625L0.0525 2.27625C0.0180355 2.34581 7.03031e-05 2.42237 0 2.5V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H11C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11V2.5C11.9999 2.42237 11.982 2.34581 11.9475 2.27625ZM1.80875 1H10.1912L10.6912 2H1.30875L1.80875 1ZM11 11H1V3H11V11ZM8.35375 7.14625C8.40024 7.19269 8.43712 7.24783 8.46228 7.30853C8.48744 7.36923 8.50039 7.43429 8.50039 7.5C8.50039 7.56571 8.48744 7.63077 8.46228 7.69147C8.43712 7.75217 8.40024 7.80731 8.35375 7.85375L6.35375 9.85375C6.30731 9.90024 6.25217 9.93712 6.19147 9.96228C6.13077 9.98744 6.06571 10.0004 6 10.0004C5.93429 10.0004 5.86923 9.98744 5.80853 9.96228C5.74783 9.93712 5.69269 9.90024 5.64625 9.85375L3.64625 7.85375C3.55243 7.75993 3.49972 7.63268 3.49972 7.5C3.49972 7.36732 3.55243 7.24007 3.64625 7.14625C3.74007 7.05243 3.86732 6.99972 4 6.99972C4.13268 6.99972 4.25993 7.05243 4.35375 7.14625L5.5 8.29313V4.5C5.5 4.36739 5.55268 4.24021 5.64645 4.14645C5.74021 4.05268 5.86739 4 6 4C6.13261 4 6.25979 4.05268 6.35355 4.14645C6.44732 4.24021 6.5 4.36739 6.5 4.5V8.29313L7.64625 7.14625C7.69269 7.09976 7.74783 7.06288 7.80853 7.03772C7.86923 7.01256 7.93429 6.99961 8 6.99961C8.06571 6.99961 8.13077 7.01256 8.19147 7.03772C8.25217 7.06288 8.30731 7.09976 8.35375 7.14625Z' />
        </svg>
        <span style={styles.text}>View Archived Locations</span>
      </div>
      {requestingGetBusinessLocationsOfMerchant || isLoading ? (
        <MessageWrapper>Loading...</MessageWrapper>
      ) : sortedBusinesses.length === 0 && searchString ? (
        <MessageWrapper>
          No matching locations found for your search. Please check the archived
          locations as well.
        </MessageWrapper>
      ) : (
        <ContainerDiv height='400px'>
          <Table noBorder>
            <TBody striped>
              {sortedBusinesses.length > 0 &&
                renderHeaders(handleSorting, sorted)}
              {renderRows(
                sortedBusinesses,
                handleBusinessLocationAction,
                setSearchString
              )}
            </TBody>
          </Table>
        </ContainerDiv>
      )}
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...allBusinessLocations,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: allBusinessesLength,
          }}
          requestParams={requestParams}
          updateAction={getBusinessLocations}
          type='business_locations'
        />
      </PaginationWrapper>
    </ComponentCard>
  );
};

const renderHeaders = (handleSorting, sorted) => {
  return (
    <TrHead
      style={{
        position: 'sticky',
        position: '-webkit-sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 10,
      }}
    >
      <Th align='left'>Internal Location Name</Th>
      <Th align='left'>Location Address</Th>
      <Th align='left'>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div>Status</div>
          <div style={{ cursor: 'pointer' }}>
            {!sorted[0] && (
              <i
                className='fa fa-sort'
                onClick={() => handleSorting('is_active')}
              ></i>
            )}
            {sorted[0] === 'asc' && (
              <i
                className='fa fa-sort-up'
                onClick={() => handleSorting('is_active')}
              ></i>
            )}
            {sorted[0] === 'desc' && (
              <i
                className='fa fa-sort-down'
                onClick={() => handleSorting('is_active')}
              ></i>
            )}
          </div>
        </div>
      </Th>
      <Th align='left' width='270px'>
        <div style={{ display: 'flex', gap: '5px' }}>
          <div>Order Ahead Enabled</div>
          <div style={{ cursor: 'pointer' }}>
            {!sorted[1] && (
              <i
                className='fa fa-sort'
                onClick={() => handleSorting('is_order_ahead_enabled')}
              ></i>
            )}
            {sorted[1] === 'asc' && (
              <i
                className='fa fa-sort-up'
                onClick={() => handleSorting('is_order_ahead_enabled')}
              ></i>
            )}
            {sorted[1] === 'desc' && (
              <i
                className='fa fa-sort-down'
                onClick={() => handleSorting('is_order_ahead_enabled')}
              ></i>
            )}
          </div>
        </div>
      </Th>
    </TrHead>
  );
};

const renderRows = (
  sortedBusinesses,
  handleBusinessLocationAction,
  setSearchString
) => {
  return sortedBusinesses?.map((businessLocation, index) => {
    return (
      <Tr
        onClick={() => {
          setSearchString('');
          handleBusinessLocationAction(businessLocation.id);
        }}
        style={{ cursor: 'pointer' }}
        key={index}
        //key={businessLocation.id}
      >
        <StyledTd align='left'>
          <StyledP>{businessLocation.business_name}</StyledP>
        </StyledTd>
        <StyledTd align='left'>
          <StyledP>{businessLocation.address}</StyledP>
        </StyledTd>
        <StyledTd align='center'>
          <StyledP>
            {businessLocation.is_active ? 'ACTIVE' : 'INACTIVE'}
          </StyledP>
        </StyledTd>
        <StyledTd align='center' width='200px'>
          <Checkbox
            checked={businessLocation.is_order_ahead_enabled}
            disabled
          />
          {/* <StyledP>{`${businessLocation.is_order_ahead_enabled}`}</StyledP> */}
          {/* <StyledP>{`${
            businessLocation.is_order_ahead_enabled ? 'True' : 'False'
          }`}</StyledP> */}
        </StyledTd>
      </Tr>
    );
  });
};

export default BusinessLocationsList;

const styles = {
  container: {
    marginLeft: '30px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  text: {
    color: '#2440AE',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px',
    marginLeft: '8px',
  },
};
