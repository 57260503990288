import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Collapse } from '@material-ui/core';

import ChevronDownIcon from './../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from './../../../components/Icon/ChevronUpIcon';

const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1.3fr 1fr;
  grid-gap: 0;
  margin: 0;
  padding: 7px 30px;
  font-size: 18px;
  font-weight: 600;
`;

const ArchivedTableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1.3fr 1fr;
  grid-gap: 0;
  margin: 0;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  border-top: 1px solid #e1e1e1;
`;

const RoleName = styled.span`
  font-weight: 600;
  text-transform: capitalize;
`;

const UnarchiveButton = styled.button`
  background-color: #e0e0e0;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #c8c8c8;
  }
`;

const ArchivedSectionHeader = styled.h3`
  padding: 20px 0;
  font-weight: 600;
`;

const ArchivedUsersSection = ({ archivedAccountsData, handleUnarchive }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card>
      <TitleAndCollapseButton>
        <Title>Archived Users</Title>
        {!isExpanded ? (
          <ChevronDownIcon onClick={() => setIsExpanded(true)} />
        ) : (
          <ChevronUpIcon onClick={() => setIsExpanded(false)} />
        )}
      </TitleAndCollapseButton>

      <Collapse in={isExpanded}>
        {/* <ArchivedSectionHeader>Archived Users</ArchivedSectionHeader> */}
        <div style={{ borderLeft: 'none', borderRight: 'none' }}>
          {
            archivedAccountsData.length > 0 && 
            <TableHeader>
            <div align="left">Username</div>
            <div align="left">Role</div>
            <div align="center">Display Name</div>
            <div></div>
          </TableHeader>
          }
          {
            archivedAccountsData.length <= 0 && <div style={{textAlign:'center'}}>No archived users</div>
          }
          {archivedAccountsData.map((account, index) => (
            <ArchivedTableRow key={index}>
              <div>{account.username}</div>
              <div>
                <RoleName>{account.role}</RoleName>
              </div>
              <div style={{textAlign:'center'}}>{account.display_name}</div>
              <div align="left">

              <button style={styles.button} onClick={() => handleUnarchive(account)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  style={styles.icon}
                >
                  <path
                    d="M12.2041 4.24172L11.3291 2.49172C11.2927 2.41903 11.2368 2.35792 11.1676 2.31522C11.0985 2.27253 11.0188 2.24994 10.9375 2.25H3.0625C2.98122 2.24994 2.90153 2.27253 2.83237 2.31522C2.76321 2.35792 2.70731 2.41903 2.67094 2.49172L1.79594 4.24172C1.76578 4.30258 1.75006 4.36958 1.75 4.4375V11.875C1.75 12.1071 1.84219 12.3296 2.00628 12.4937C2.17038 12.6578 2.39294 12.75 2.625 12.75H11.375C11.6071 12.75 11.8296 12.6578 11.9937 12.4937C12.1578 12.3296 12.25 12.1071 12.25 11.875V4.4375C12.2499 4.36958 12.2342 4.30258 12.2041 4.24172ZM3.33266 3.125H10.6673L11.1048 4H2.89516L3.33266 3.125ZM11.375 11.875H2.625V4.875H11.375V11.875ZM9.05953 7.62797C9.10018 7.66862 9.13242 7.71687 9.15442 7.76998C9.17642 7.82309 9.18774 7.88001 9.18774 7.9375C9.18774 7.99499 9.17642 8.05191 9.15442 8.10502C9.13242 8.15813 9.10018 8.20638 9.05953 8.24703C9.01888 8.28768 8.97063 8.31992 8.91752 8.34192C8.86441 8.36392 8.80749 8.37524 8.75 8.37524C8.69251 8.37524 8.63559 8.36392 8.58248 8.34192C8.52937 8.31992 8.48112 8.28768 8.44047 8.24703L7.4375 7.24352V10.5625C7.4375 10.6785 7.39141 10.7898 7.30936 10.8719C7.22731 10.9539 7.11603 11 7 11C6.88397 11 6.77269 10.9539 6.69064 10.8719C6.60859 10.7898 6.5625 10.6785 6.5625 10.5625V7.24352L5.55953 8.24703C5.51888 8.28768 5.47063 8.31992 5.41752 8.34192C5.36441 8.36392 5.30749 8.37524 5.25 8.37524C5.19251 8.37524 5.13559 8.36392 5.08248 8.34192C5.02937 8.31992 4.98112 8.28768 4.94047 8.24703C4.89982 8.20638 4.86758 8.15813 4.84558 8.10502C4.82358 8.05191 4.81226 7.99499 4.81226 7.9375C4.81226 7.88001 4.82358 7.82309 4.84558 7.76998C4.86758 7.71687 4.89982 7.66862 4.94047 7.62797L6.69047 5.87797C6.7311 5.83729 6.77935 5.80502 6.83246 5.78301C6.88558 5.76099 6.94251 5.74966 7 5.74966C7.05749 5.74966 7.11442 5.76099 7.16754 5.78301C7.22065 5.80502 7.2689 5.83729 7.30953 5.87797L9.05953 7.62797Z"
                    fill="white"
                  />
                </svg>
                <span style={styles.text}>Unarchive</span>
              </button>
              </div>
            </ArchivedTableRow>
          ))}
        </div>
      </Collapse>
    </Card>
  );
};

export default ArchivedUsersSection;
const styles = {
  button: {
    width: '124px',
    padding: '4px 10px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    borderRadius: '5px',
    background: 'rgba(0, 0, 0, 0.60)',
    border: 'none',
    cursor: 'pointer',
  },
  icon: {
    width: '14px',
    height: '14px',
  },
  text: {
    color: 'white',
    fontSize: '14px',
  },
};