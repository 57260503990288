import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../customerActions';
import CustomerOfferCreditInfo from '../components/CustomerOfferCreditInfo';

class CustomerOfferCreditContainer extends Component {
  state = { height: null };
 
  render() {
    const {
        offerCredits,
        offerCreditBalance
    } = this.props.customerReducer;
    return (
      <Fragment>
        <CustomerOfferCreditInfo
          offerCredits={offerCredits}
          offerCreditBalance={offerCreditBalance}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customerReducer: state.customerReducer,
});

export default connect(mapStateToProps, actions)(CustomerOfferCreditContainer);
