import React from 'react';
import styled from 'styled-components';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import PaginationControl from '../../../../components/Pagination/PaginationControl';

import moment from 'moment';
const Wrapper = styled.div`
  margin-top: 30px;
`;
const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;
const TableWrapper = styled.div`
  overflow-x: auto;
  border-radius: 12px;
`;
const TitleWrapper = styled.div`
  font-weight: 600;
  margin: 0 45px 45px 35px;
  font-size: 20px;
`;
const NotFaundWrapper = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 200px;
  font-size: 38px;
  color: #d8d8d8;
`;
const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
  margin: 50px 37px;
`;
const renderList = (customerAudits, requestParams) => {
  return (
    <TableWrapper>
      <Table style={{ border: 'none' }}>
        <TBody>
          {renderTableHeader(requestParams)}
          {customerAudits.length &&
            requestParams &&
            renderTableRows(customerAudits)}
        </TBody>
      </Table>
    </TableWrapper>
  );
};

const renderTableHeader = (requestParams) => {
  return (
    <TrHead>
      <Th
        align='left'
        value='name'
        sortingOption={requestParams.sorting_option}
        style={{ fontSize: '16px' }}
      >
        Entity
      </Th>
      <Th align='left' style={{ fontSize: '16px' }}>
        Action
      </Th>
      <Th align='left' style={{ fontSize: '16px' }}>
        Was Action Successful?
      </Th>
      <Th align='left' style={{ fontSize: '16px' }}>
        User Agent
      </Th>
      <Th align='left' style={{ fontSize: '16px' }}>
        IP Address
      </Th>
      <Th align='left' style={{ fontSize: '16px' }}>
        Additional Comment
      </Th>
      <Th align='left' style={{ fontSize: '16px' }}>
        Date and Time (UTC)
      </Th>
    </TrHead>
  );
};
const renderTableRows = (customerAudits) => {
  return customerAudits.map((order, index) => (
    <Tr
      key={index}
      style={{ height: '45px', color: '#191818', cursor: 'pointer' }}
    >
      <Td>{order.entity}</Td>
      <Td>{order.action}</Td>
      <Td>{order.was_successful ? 'Yes' : 'No'}</Td>
      <Td>{order.user_agent}</Td>
      <Td>{order.client_ip}</Td>
      <Td>{order.reason}</Td>
      <Td>{order.created_at}</Td>
    </Tr>
  ));
};

const CustomerAuditHistory = ({
  customerAudits,
  requestParams,
  getCustomerAudit,
  updateCurrentPage,
}) => {
  return (
    <Wrapper>
      <TitleWrapper>Customer Activity Logs</TitleWrapper>
      {customerAudits?.length ? (
        <>
          {renderList(customerAudits, requestParams, null)}
          <PaginationWrapper>
            <PaginationControl
              response={{
                ...customerAudits,
                page: requestParams.page_number,
                page_size: requestParams.page_size,
                count: customerAudits,
              }}
              requestParams={requestParams}
              updateAction={getCustomerAudit}
              type='auditHistory'
            />
          </PaginationWrapper>
        </>
      ) : (
        <NotFaundWrapper>No Record Found</NotFaundWrapper>
      )}
      <HorizontalLine />
    </Wrapper>
  );
};

export default CustomerAuditHistory;
