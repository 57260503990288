import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../../../../../../components/Pagination/PaginationControl';
import { paginate } from '../../../../../../components/FilterModal/paginate';
import { Button } from 'merchant-portal-components';
import { Checkbox, TextField, InputAdornment } from '@material-ui/core';
import { formatLocations } from '../../../../../../utils/childLocations/formatLocations';
import * as actions from '../../../../../BusinessLocationDetails/LocationListManager/actions';
import { updateBusinessInformation } from '../../../../../BusinessLocationDetails/LocationDetailsManager/actions';
import { Dialog } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
const CustomSwitch = withStyles({
  switchBase: {
    color: '#CCCCCC',
    '&$checked': {
      color: '#2440AF',
    },
    '&$checked + $track': {
      backgroundColor: '#2440AF',
    },
  },
  checked: {},
  track: {},
})(Switch);
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  CloseIcon,
} from '../styles/locationAvailibilityModalStyles';

const initiallRequestParams = {
  page_number: 1,
  page_size: '10',
  sorting_option: 'title-asc',
};
const LocationAvailibilityModal = ({
  locationList,
  requestParams,
  isOpen,
  setIsOpen,
  placeholder,
  searchLabel,
  updateLocationRequestParams,
  productSelectedBusinessesInitialValues,
  putProductBusinesses,
  selectedProduct,
}) => {
  const [originalLocationList, setOriginalLocationList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [businessListLength, setBusinessListLength] = useState([]);
  // search
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    const filteredList = originalLocationList.filter((item) =>
      item.business_name?.toLowerCase().includes(searchString?.toLowerCase())
    );
    const paginatedList = paginate(filteredList, 1, requestParams.page_size);
    updateLocationRequestParams({ ...requestParams, page_number: 1 });
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [searchString]);
  // update original location list
  const updateOriginalLocationList = useCallback(() => {
    // console.log("1111=>", productSelectedBusinessesInitialValues);
    let newOriginalList = [];
    productSelectedBusinessesInitialValues?.forEach((product, index) => {
      const filteredBusiness = locationList.find(
        (location) => location.id === product?.business_id
      );
      if (filteredBusiness) {
        const newProductBusiness = {
          ...product,
          business_name: filteredBusiness?.business_name,
          display_name: filteredBusiness?.display_name,
        };
        newOriginalList.push(newProductBusiness);
      }
    });
    // console.log("newOriginalList=>", newOriginalList);
    setOriginalLocationList(newOriginalList);
  }, [locationList, productSelectedBusinessesInitialValues, isOpen]);
  useEffect(() => {
    updateOriginalLocationList();
  }, [updateOriginalLocationList]);
  //
  const filterBusinessList = useCallback(() => {
    let filteredList = originalLocationList;
    if (searchString) {
      filteredList = filteredList?.filter((item) =>
        item.business_name?.toLowerCase().includes(searchString?.toLowerCase())
      );
    }
    let paginatedList = paginate(
      filteredList,
      requestParams.page_number,
      requestParams.page_size
    );
    setBusinessList(paginatedList);
    setBusinessListLength(filteredList.length);
  }, [
    requestParams.page_number,
    requestParams.page_size,
    JSON.stringify(originalLocationList),
  ]);
  useEffect(() => {
    updateLocationRequestParams(initiallRequestParams);
    return () => {
      updateLocationRequestParams(initiallRequestParams);
    };
  }, []);
  useEffect(() => {
    filterBusinessList();
  }, [filterBusinessList]);
  const handleUpdate = (business_id, key, value) => {
    const modifiedOriginalLocationList = originalLocationList.map((item) => {
      return {
        business_id: item?.business_id,
        is_active: item?.is_active,
        is_available: item?.is_available,
      };
    });
    const index = modifiedOriginalLocationList.findIndex(
      (item) => item.business_id === business_id
    );
    modifiedOriginalLocationList[index] = {
      ...modifiedOriginalLocationList[index],
      [key]: value,
    };
    // console.log("modifiedOriginalLocationList=>", modifiedOriginalLocationList);
    putProductBusinesses(modifiedOriginalLocationList);
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <StyledCard>
        <HeaderWrapper padding='0'>
          <SectionTitle>{`Locations for ${selectedProduct?.name}`}</SectionTitle>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={() => setIsOpen(false)}
            ></CloseIcon>
          </div>
        </HeaderWrapper>
        <SearchWrapper>
          <TextField
            id='outlined-basic'
            label={searchLabel}
            placeholder={placeholder}
            variant='outlined'
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fa fa-search' style={{ color: '#b5b5b5' }}></i>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  {`${businessListLength} results`}
                </InputAdornment>
              ),
            }}
          />
        </SearchWrapper>
        <RowWrapper isHeader={true}>
          <TH>Select All Locations</TH>
          <TH textAlign='center'>{/* Is Active */}</TH>
          <TH textAlign='center'>Is Available</TH>
        </RowWrapper>
        {businessList.map((businessLocation, index) => (
          <RowWrapper key={index} hoverBGColor='#f1f1fa'>
            <TD>{businessLocation?.business_name}</TD>
            <TD textAlign='center'>
              {/* <CustomSwitch
                checked={businessLocation?.is_active}
                onChange={(e) =>
                  handleUpdate(
                    businessLocation?.business_id,
                    "is_active",
                    e.target.checked
                  )
                }
                name="gilad"
              /> */}
            </TD>
            <TD textAlign='center'>
              <CustomSwitch
                checked={businessLocation?.is_available}
                onChange={(e) =>
                  handleUpdate(
                    businessLocation?.business_id,
                    'is_available',
                    e.target.checked
                  )
                }
                name='gilad'
              />
            </TD>
          </RowWrapper>
        ))}
        <PaginationWrapper>
          <PaginationControl
            response={{
              ...businessList,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: businessListLength,
            }}
            requestParams={requestParams}
            type='locationFiltering'
          />
        </PaginationWrapper>
      </StyledCard>
    </Dialog>
  );
};
function mapStateToProps(state) {
  return {
    requestingGetBusinessLocationsOfMerchant:
      state.businessLocationsList.requestingGetBusinessLocationsOfMerchant,
    locationList: state.businessLocationsList.businessLocationsOfMerchant,
    requestParams: state.businessLocationsList.requestParams,
    productSelectedBusinessesInitialValues:
      state.productsReducer.productSelectedBusinessesInitialValues,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  updateBusinessInformation,
})(LocationAvailibilityModal);
