import React, { useEffect, Fragment, useState } from 'react';
import DeliveryZonesView from './components/DeliveryZonesView';
import { connect } from 'react-redux';
import * as actions from './actions';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Collapse } from '@material-ui/core';
import ChevronUpIcon from '../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../components/Icon/ChevronDownIcon';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

export const Card = styled.div`
  background-color: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  color: ${(props) => (props.disabled ? '#191818' : 'inherit')};
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;
  padding-right: 20px;
`;

export const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 20px 30px 20px 40px;
  margin: 10px;
`;

export const Title = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : 'none')};
  font-weight: 600;
  font-size: 21px;
`;

const DeliveryZonesViewContainer = (props) => {
  useEffect(() => {
    track.page('app-settings-delivery-zones');
    if (props.latitude) {
      props.getDeliveryZoneObjects(props.businessId);
    }
  }, [props.latitude]);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleDeliveryZoneVisibility = (deliveryZoneObject, event) => {
    deliveryZoneObject.isVisible = event.target.checked;
    props.updateDeliveryZoneObject(deliveryZoneObject);
  };
  const handleCreateNewButtonClick = () => {
    props.history.push(`/locations/${props.businessId}/delivery-zones/create/`);
  };

  const handleEditIconClick = (deliveryZoneId) => {
    props.history.push(
      `/locations/${props.businessId}/delivery-zones/edit/${deliveryZoneId}`
    );
  };

  const handleDeleteIconClick = (deliveryZoneId) => {
    props.deleteDeliveryZoneObjectById(props.businessId, deliveryZoneId);
  };

  const {
    shouldComponentBeLoading,
    deliveryZoneObjects,
    editingColour,
    displayName,
    locationCoords,
  } = props.businessLocationDeliveryZones;
  if (shouldComponentBeLoading) {
    return <LoadingBlueLarge />;
  }
  const pageName = 'Location Delivery Zones';
  return (
    <Fragment>
      <Card>
        <TitleAndCollapseButton>
          <Title
            style={{ textTransform: 'capitalize' }}
            // disabled={
            //   !props.deactivateSection && props.latitude ? false : 'true'
            // }
          >
            Delivery Zones
          </Title>
          {!isExpanded ? (
            <ChevronDownIcon
              // disabled={
              //   // !props.deactivateSection && props.latitude ? false : true
              // }
              // style={{
              //   opacity:
              //     !props.deactivateSection && props.latitude ? 'none' : '0.5',
              // }}
              onClick={() => {
                setIsExpanded(true);
              }}
            />
          ) : (
            <ChevronUpIcon
              // disabled={dirty}
              onClick={() => {
                setIsExpanded(false);
              }}
            />
          )}
        </TitleAndCollapseButton>

        <Collapse in={isExpanded}>
          {deliveryZoneObjects && (
            <DeliveryZonesView
              deliveryZoneObjects={deliveryZoneObjects}
              editingColour={editingColour}
              handleDeliveryZoneVisibility={handleDeliveryZoneVisibility}
              handleCreateNewButtonClick={handleCreateNewButtonClick}
              handleEditIconClick={handleEditIconClick}
              handleDeleteIconClick={handleDeleteIconClick}
              locationCoords={locationCoords}
              displayName={displayName}
              businessId={props.businessId}
              isBusinessAdminRole={props.isBusinessAdminRole}
            />
          )}
        </Collapse>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    businessLocationDeliveryZones: state.businessLocationDeliveryZones,
    businessId: state.businessLocationDetails.businessId,
    latitude:
      state.businessLocationDetails.addressInformationInitialValues.address
        .latitude,
    longitude:
      state.businessLocationDetails.addressInformationInitialValues.address
        .longitude,
  };
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(DeliveryZonesViewContainer));
