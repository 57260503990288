import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from '../customerActions';
import CustomerCredits from '../components/CustomerCredits';
import { LoadingBlueLarge } from 'merchant-portal-components';
import SendRewardsModal from '../components/modals/SendRewardsModal';

const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
  margin: 50px 37px;
`;
/**
 * Class based component handling how components work
 */
class CustomerListContainer extends Component {
  state = { height: null };

  /**
   * Handle visibility of sending credits modal/form
   */
  toggleSendCreditsModal = () => {
    this.props.SendCreditsModalIsOpen(
      !this.props.customerReducer.SendCreditsModalIsOpen
    );
  };

  /**
   * Handle credits form submit button click functionality
   */
  handleSendCreditsClick = (values) => {
    this.validateRewardValues(values)
      .then((validatedValues) => {
        this.props.postSendCredits(this.props.customerId, validatedValues);
      })
      .catch((error) => {
        this.props.errorAlert(error.message);
      });
  };

  /**
   * Return a Promise state with errors or values
   * @param {Object} values - form values to validate
   * @returns {Promise} - resolve: values. reject: error message
   */
  validateRewardValues = (values) => {
    let validatedValues = {};
    return new Promise((resolve, reject) => {
      if (!values) {
        reject({ message: 'Fields are empty' });
      } else if (!values.amountOfReward) {
        reject({ message: 'Amount must be price unit' });
      } else if (!values.rewardNotes) {
        reject({ message: 'You must put a note' });
      }
      validatedValues.amount = parseFloat(
        values.amountOfReward.replace('$', '')
      );
      if (validatedValues.amount < 0 || validatedValues.amount > 200) {
        reject({ message: 'Amount must be between 1-200' });
      }
      validatedValues.comment =
        localStorage.getItem('displayName') + '\n' + values.rewardNotes;
      resolve(validatedValues);
    });
  };

  /**
   * update view components height to use for loading height
   */
  updateHeight = (element) => {
    if (
      element &&
      element.offsetHeight &&
      this.state.height != element.offsetHeight
    ) {
      this.setState({ height: element.offsetHeight + 'px' });
    }
  };

  render() {
    const {
      getCustomerGlobalCreditsIsRequesting,
      currentCreditsBalance,
      lifetimeCreditsBalance,
      SendCreditsModalIsOpen,
      postSendCreditsIsRequesting,
      customerDetails,
      customerGlobalCredits,
    } = this.props.customerReducer;

    return (
      <Fragment>
        {getCustomerGlobalCreditsIsRequesting ? (
          <LoadingBlueLarge height={this.state.height} />
        ) : (
          <div ref={this.updateHeight}>
            <CustomerCredits
              customerDetails={customerDetails}
              toggleSendCreditsModal={this.toggleSendCreditsModal}
              currentCreditsBalance={currentCreditsBalance}
              lifetimeCreditsBalance={lifetimeCreditsBalance}
              customerGlobalCredits={customerGlobalCredits}
              customerDelete={this.props.customerIsdelete}
            />
          </div>
        )}
        {SendCreditsModalIsOpen ? (
          <SendRewardsModal
            typeOfReward='Credits'
            handleSendRewardsClick={this.handleSendCreditsClick}
            toggleModalVisibility={this.toggleSendCreditsModal}
            customerName={customerDetails.customerName()}
            isRequesting={postSendCreditsIsRequesting}
            amountRange={(value) =>
              value.replace('$', '') > 0 && value.replace('$', '') <= 200
                ? undefined
                : 'Amount must be between 1-200'
            }
            normalize={(value) => {
              if (/^-?[0-9]\d*\.?\d*$/.test(value) || value == '') {
                return value;
              } else if (
                value[0] == '$' &&
                value.substring(1, value.length) == ''
              ) {
                return value;
              } else if (
                value[0] == '$' &&
                /^-?[0-9]\d*\.?\d*$/.test(value.substring(1, value.length))
              ) {
                return value;
              }
            }}
          />
        ) : null}
        <HorizontalLine />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customerReducer: state.customerReducer,
});

export default connect(mapStateToProps, actions)(CustomerListContainer);
