import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RenderTextField,
  required,
  RenderSelect,
  RenderTimePicker,
  RenderSingleDatePicker,
  pointsCostValidation,
} from '../../../../../components/FormElements/FormikElements';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import moment from 'moment';
import Conditions from './Conditions';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-rows: 120px auto;
`;

const Subtitle = styled.div`
  font-weight: 600;
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px;
`;

const OptionGridWrapper = styled.div`
  display: grid;
  grid-gap: 20px;
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  align-items: center;
`;

const FreeItemOffer = ({
  allProducts,
  allVariants,
  setFieldValue,
  auto_redeem,
  reusable,
  apply_multiple,
  expiry_date,
  conditions,
  templateName,
  touched,
  errors,
}) => {
  const validateDiscountAmount = (value) => {
    let error;
    if (!value && value !== 0) {
      error = 'Required';
    } else if (parseFloat(value) > 100000000) {
      error = 'Entered amount is not acceptable.';
    } else if (parseFloat(value) < 0) {
      error = 'No less than Zero';
    } else if (parseFloat(value) === 0) {
      error = 'More than zero is required';
    }
    return error;
  };
  return (
    <Wrapper>
      <Conditions
        templateName={templateName}
        allProducts={allProducts}
        allVariants={allVariants}
        conditions={conditions}
        setFieldValue={setFieldValue}
      />
      <Subtitle>Free Item Options</Subtitle>
      <OptionsWrapper>
        <OptionGridWrapper>
          <Field
            name='offer_details.template_values.max_discount'
            component={RenderTextField}
            type='number'
            label='*Max Discount'
            validate={validateDiscountAmount}
            onKeyDown={(evt) =>
              (evt.key === 'e' || evt.key === '-' || evt.key === '+') &&
              evt.preventDefault()
            }
          />
          {!errors &&
            !touched &&
            errors.offer_details.template_values.max_discount &&
            touched.offer_details.template_values.max_discount && (
              <div>{errors.offer_details.template_values.max_discount}</div>
            )}
          <Field
            name='offer_details.template_values.points_cost'
            component={RenderTextField}
            type='number'
            label='*Points Cost'
            validate={pointsCostValidation}
          />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridGap: '10px',
              alignItems: 'center',
            }}
          >
            <SmallToggleSwitch
              checked={expiry_date}
              label='Expiry Date'
              onChange={() =>
                setFieldValue(
                  'offer_details.template_values.expiry_date',
                  expiry_date ? null : moment().format('YYYY-MM-DD')
                )
              }
            />
            {expiry_date && (
              <Field
                name='offer_details.template_values.expiry_date'
                component={RenderSingleDatePicker}
                hasTime={true}
                type='text'
                validate={required}
              />
            )}
          </div>
        </OptionGridWrapper>
        <OptionGridWrapper>
          <CheckboxWrapper>
            <Checkbox
              onChange={(e) =>
                setFieldValue('offer_details.auto_redeem', e.target.checked)
              }
              checked={auto_redeem}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
            <div>Auto Redeem</div>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox
              onChange={(e) =>
                setFieldValue('offer_details.reusable', e.target.checked)
              }
              checked={reusable}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
            <div>Reusable</div>
          </CheckboxWrapper>
          {/* <CheckboxWrapper>
            <Checkbox
              onChange={(e) =>
                setFieldValue(
                  'offer_details.template_values.apply_multiple',
                  e.target.checked
                )
              }
              checked={apply_multiple}
              inputProps={{
                'aria-label': 'secondary checkbox',
              }}
            />
            <div>Apply Multiple</div>
          </CheckboxWrapper> */}
        </OptionGridWrapper>
      </OptionsWrapper>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  allProducts: state.productsReducer.allProducts,
  allVariants: state.variantsReducer.allVariants,
});

export default connect(mapStateToProps)(FreeItemOffer);
