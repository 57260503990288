import moment from 'moment';
export const timeErrors = (
  day,
  error,
  initialError,
  openTime1,
  closeTime1,
  openTime2,
  closeTime2,
  openOrderingTime1,
  closeOrderingTime1,
  openOrderingTime2,
  closeOrderingTime2
) => {
  const formatedOpenTime1 = openTime1?.format('HH:mm:ss');
  const formatedCloseTime1 = closeTime1?.format('HH:mm:ss');
  const formatedOpenTime2 = openTime2?.format('HH:mm:ss');
  const formatedCloseTime2 = closeTime2?.format('HH:mm:ss');
  const formatedOpenOrderingTime1 = openOrderingTime1?.format('HH:mm:ss');
  const formatedCloseOrderingTime1 = closeOrderingTime1?.format('HH:mm:ss');
  const formatedOpenOrderingTime2 = openOrderingTime2?.format('HH:mm:ss');
  const formatedCloseOrderingTime2 = closeOrderingTime2?.format('HH:mm:ss');
  const isOpen2BeforeClose1 = moment(openTime2).isSameOrBefore(closeTime1);
  const isOrderingOpen2BeforeClose1 =
    moment(openOrderingTime2).isSameOrBefore(closeOrderingTime1);
  if (formatedOpenTime1 === 'Invalid date') {
    error[`openTime${day}1`] = 'Invalid Time';
  } else if (formatedCloseTime1 === 'Invalid date') {
    error[`closeTime${day}1`] = 'Invalid Time';
  } else if (formatedOpenTime2 === 'Invalid date') {
    error[`openTime${day}2`] = 'Invalid Time';
  } else if (formatedCloseTime2 === 'Invalid date') {
    error[`closeTime${day}2`] = 'Invalid Time';
  } else if (formatedOpenTime2 && isOpen2BeforeClose1) {
    error[`openTime${day}2`] = `The time should be after ${formatedCloseTime1}`;
  } else if (formatedOpenTime1 && !formatedCloseTime1) {
    error[`openTime${day}1`] = '';
    error[`closeTime${day}1`] = 'Required';
  } else if (formatedOpenTime2 && !formatedCloseTime2) {
    error[`openTime${day}2`] = '';
    error[`closeTime${day}2`] = 'Required';
  } else if (formatedOpenOrderingTime1 === 'Invalid date') {
    // ordering
    error[`openOrderingTime${day}1`] = 'Invalid Time';
  } else if (formatedCloseOrderingTime1 === 'Invalid date') {
    error[`closeOrderingTime${day}1`] = 'Invalid Time';
  } else if (formatedOpenOrderingTime2 === 'Invalid date') {
    error[`openOrderingTime${day}2`] = openOrderingTime2 = 'Invalid Time';
  } else if (formatedCloseOrderingTime2 === 'Invalid date') {
    error[`closeOrderingTime${day}2`] = 'Invalid Time';
  } else if (formatedOpenOrderingTime2 && isOrderingOpen2BeforeClose1) {
    error[
      `openOrderingTime${day}2`
    ] = `The time should be after ${formatedCloseOrderingTime1}`;
  } else if (formatedOpenOrderingTime1 && !formatedCloseOrderingTime1) {
    error[`openOrderingTime${day}1`] = '';
    error[`closeOrderingTime${day}1`] = 'Required';
  } else if (formatedOpenOrderingTime2 && !formatedCloseOrderingTime2) {
    error[`openOrderingTime${day}2`] = '';
    error[`closeOrderingTime${day}2`] = 'Required';
  } else {
    error[`openTime${day}1`] = '';
    error[`closeTime${day}1`] = '';
    error[`openTime${day}2`] = '';
    error[`closeTime${day}2`] = '';
    error[`openOrderingTime${day}1`] = '';
    error[`closeOrderingTime${day}1`] = '';
    error[`openOrderingTime${day}2`] = '';
    error[`closeOrderingTime${day}2`] = '';
  }
  return error;
};
