// get from here: https://github.com/evansiroky/timezone-boundary-builder/blob/master/timezones.json

const timezones = [
  'America/St_Johns',
  'America/Halifax',
  'America/Vancouver',
  'America/Toronto',
  'America/Regina',
  'America/Edmonton',
  'America/Winnipeg',
  'America/Moncton',
  'America/Thunder_Bay',
  'America/Yellowknife',
  'America/Fort_Nelson',
];

export default timezones;
