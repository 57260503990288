import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Formik, Field } from 'formik';
import { hoursFormatter } from '../utils/helper.js';
import { comboAvailableAllTime } from '../utils/helper.js';
import { isComboAvailableAllTimeFunction } from '../utils/helper.js';
import {
  Wrapper,
  IsActiveToggleWrapper,
  TitleAndCollapseButton,
  Title,
  ButtonWrapper,
  ComboAvailibilityWrapper,
} from '../styles/style.js';
import { RenderCheckBox } from '../../../../../../components/FormElements/FormikElements';
import AvailableDaysRow from './AvailableDaysRow';
import DatesRow from './DatesRow';
import ComboLocationsRow from './ComboLocationsRow';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';
import { Button } from 'merchant-portal-components';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Collapse, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FilterButton from '../../../../../../components/FilterModal/FilterButton.js';
import LocationFilterModal from '../../../../../../components/FilterModal/LocationFilterModal.js';
import _ from 'lodash';
import moment from 'moment';

const ComboAvailability = ({
  businesses,
  initValues,
  comboBusinesses,
  comboAvailabilities,
  handleComboAvailabilitiesSubmit,
  submitAvailabilitiesIsRequesting,
  disabled,
  orderTypesList,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [comboBusinessesModified, setComboBusinessesModified] = useState(
    comboBusinesses.map((comboBusiness) => comboBusiness.business_id)
  );
  const initialValues = {
    comboNeverExpired: initValues.expiry_date === null,
    comboExpiryDate: initValues.expiry_date,
    comboStartDate: initValues.start_date,
    comboOrderTypes: initValues.order_types,
    comboIsActive: initValues.is_active,
    comboLocations: comboBusinessesModified,
    comboAvailableAllLocations:
      businesses.length === comboBusinessesModified.length,
    comboIsComboAvailableAllTime:
      isComboAvailableAllTimeFunction(comboAvailabilities),
    comboAvailability: hoursFormatter(comboAvailabilities, initValues),
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const [locationModalState, setLocationModalState] = useState(false);
  const [expiryFocusedState, setexpiryFocusedState] = useState(false);
  const [startDateFocusedState, setstartDateFocusedState] = useState(false);
  const [availabilityModalState, setAvailabilityModalState] = useState(false);
  const [emptyAvailableAllTime, setEmptyAvailableAllTime] = useState();

  const [isComboAvailableAllTime, setIsComboAvailableAllTime] = useState(
    isComboAvailableAllTimeFunction(comboAvailabilities)
  );
  useEffect(() => {
    let formatedComboAvailableHours = [];
    for (let i = 1; i < 8; i++) {
      formatedComboAvailableHours.push({
        combo_id: initialValues?.comboAvailability[i]?.combo_id,
        start_day_of_week: i,
        end_time: moment('23:59:59.000000', 'HH:mm:ss'),
        id: undefined,
        start_time: null,
        isAvailableAllDay: true,
      });
    }
    setEmptyAvailableAllTime(formatedComboAvailableHours);
  }, []);
  useEffect(() => {}, [comboAvailabilities]);
  const updateIsAllTimeAvailabe = useCallback(() => {
    const isAllTimeAvailable =
      isComboAvailableAllTimeFunction(comboAvailabilities);
    setIsComboAvailableAllTime(isAllTimeAvailable);
  }, [comboAvailabilities]);
  useEffect(() => {
    updateIsAllTimeAvailabe();
  }, [updateIsAllTimeAvailabe]);
  const handleIsAvailableAllLocations = (checked, setFieldValue) => {
    if (checked) {
      setFieldValue(
        'comboLocations',
        businesses.map((businessId) => businessId.id)
      );
      setAvailableAllLocations(true);
    } else {
      setFieldValue('comboLocations', comboBusinessesModified);
      setAvailableAllLocations(false);
    }
    setFieldValue('comboAvailableAllLocations', checked);
  };

  const toggleLocationsModal = () => {
    setLocationModalState(!locationModalState);
  };

  useEffect(() => {
    setComboBusinessesModified(
      comboBusinesses.map((comboBusiness) => comboBusiness.business_id)
    );
  }, [comboBusinesses]);

  useEffect(() => {
    setAvailableAllLocations(
      businesses.length === comboBusinessesModified.length
    );
  }, [comboBusinessesModified]);

  const toggleAvailableHoursModal = (newComboAvailabilities) => {
    const isAvailableAllTime = isComboAvailableAllTimeFunction(
      newComboAvailabilities
    );
    if (isAvailableAllTime) {
      setIsComboAvailableAllTime(true);
    }
    setAvailabilityModalState(!availabilityModalState);
  };

  const allTimeCombo = comboAvailableAllTime();

  const handleisAvailableAllTime = (checked, setFieldValue) => {
    if (checked) {
      setFieldValue('comboAvailability', emptyAvailableAllTime);
      setIsComboAvailableAllTime(true);
      setAvailabilityModalState(false);
    } else {
      setIsComboAvailableAllTime(false);
      setAvailabilityModalState(true);
    }
    setFieldValue('comboIsComboAvailableAllTime', checked);
  };

  const getExistingBusinesses = (businesses, comboLocations) => {
    const existingBusinesses = [];
    businesses.forEach((business) => {
      comboLocations.map((comboBusinessId) => {
        if (business.id == comboBusinessId) {
          existingBusinesses.push({
            value: business.id,
            label: business.business_name,
          });
        }
      });
    });
    return existingBusinesses;
  };

  // logic for order type toggle
  const orderTypeHandleChange = (
    checked,
    value,
    setFieldValue,
    comboOrderTypes
  ) => {
    if (checked) setFieldValue('comboOrderTypes', [...comboOrderTypes, value]);
    else
      setFieldValue(
        'comboOrderTypes',
        comboOrderTypes.filter((type) => type !== value)
      );
  };

  const [expire, setExpire] = useState(initValues.expiry_date !== null);

  const handleChangeExpiry = (checked, setFieldValue) => {
    if (checked) {
      setExpire(false);
    } else {
      setFieldValue(
        'comboExpiryDate',
        initValues.expiry_date === null ? new Date() : initValues.expiry_date
      );
      setExpire(true);
    }
    setFieldValue('comboNeverExpired', checked);
  };

  const [availableAllLocations, setAvailableAllLocations] = useState(
    businesses.length === comboBusinessesModified.length
  );
  return (
    <Wrapper disabled={disabled}>
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validate={(values) => {
            let errors = {};
            if (!values.comboOrderTypes.length) {
              errors.comboOrderTypes = 'At least one selection is required';
            }
            return errors;
          }}
        >
          {({
            values,
            handleReset,
            dirty,
            errors,
            setFieldValue,
            resetForm,
          }) => (
            <Fragment>
              <TitleAndCollapseButton
                onClick={() => !disabled && setIsExpanded(!isExpanded)}
              >
                <Title>Combo Availabilities </Title>
                {!isExpanded ? (
                  <ChevronDownIcon
                    disabled={disabled}
                    onClick={() => setIsExpanded(true)}
                  />
                ) : (
                  <ChevronUpIcon
                    disabled={dirty}
                    onClick={() => setIsExpanded(false)}
                  />
                )}
              </TitleAndCollapseButton>
              <Collapse in={isExpanded}>
                <div style={{ paddingRight: '48px' }}>
                  <div style={{ width: '100%' }}>
                    <div style={{ marginBottom: '15px' }}>Order Type*</div>
                    <Field
                      component={RenderCheckBox}
                      name='comboOrderTypes'
                      options={orderTypesList}
                      error={errors.comboOrderTypes}
                    />
                  </div>
                  <AvailableDaysRow
                    values={values}
                    setFieldValue={setFieldValue}
                    isComboAvailableAllTime={isComboAvailableAllTime}
                    handleisAvailableAllTime={handleisAvailableAllTime}
                    setIsComboAvailableAllTime={setIsComboAvailableAllTime}
                    availabilityModalState={availabilityModalState}
                    toggleAvailableHoursModal={toggleAvailableHoursModal}
                    initValues={initValues}
                    handleReset={handleReset}
                  />
                  <DatesRow
                    values={values}
                    setFieldValue={setFieldValue}
                    startDateFocusedState={startDateFocusedState}
                    setstartDateFocusedState={setstartDateFocusedState}
                    expiryFocusedState={expiryFocusedState}
                    setexpiryFocusedState={setexpiryFocusedState}
                    handleChangeExpiry={handleChangeExpiry}
                    expire={expire}
                  />
                  <ComboAvailibilityWrapper>
                    <SmallToggleSwitch
                      checked={
                        businesses.length === values.comboLocations.length
                      }
                      label='All Locations'
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue(
                            'comboLocations',
                            businesses.map((item) => item.id)
                          );
                        } else {
                          setIsOpen(true);
                        }
                      }}
                    />
                    <FilterButton
                      message={
                        businesses?.length === values.comboLocations?.length
                          ? 'All Locations Selected'
                          : `Selected Locations (${
                              values.comboLocations?.length || 0
                            })`
                      }
                      height='55px'
                      setIsOpen={setIsOpen}
                    />
                  </ComboAvailibilityWrapper>
                  <IsActiveToggleWrapper>
                    <SmallToggleSwitch
                      checked={values.comboIsActive}
                      onChange={() =>
                        setFieldValue('comboIsActive', !values.comboIsActive)
                      }
                      value='checkedB'
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      label='Activate'
                      name='comboIsActive'
                    />
                  </IsActiveToggleWrapper>
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        handleComboAvailabilitiesSubmit(initValues, values);
                        resetForm(values);
                      }}
                      disabled={
                        !dirty ||
                        !values.comboLocations.length ||
                        submitAvailabilitiesIsRequesting ||
                        !values.comboOrderTypes.length
                      }
                    >
                      Save
                    </Button>
                  </ButtonWrapper>
                </div>
              </Collapse>
              {isOpen && (
                <LocationFilterModal
                  placeholder='Enter Internal Location Name'
                  searchLabel='Search by Internal Location Name'
                  filteredLocations={values.comboLocations}
                  setFilteredLocations={setFieldValue}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  formField='comboLocations'
                  emptyIsEqualToAll={false}
                  isEmptyAccepted={false}
                />
              )}
            </Fragment>
          )}
        </Formik>
      </div>
    </Wrapper>
  );
};

export default ComboAvailability;
