import React, { useState, useEffect, useCallback } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  Wrapper,
  Title,
  CloseIcon,
  CopyButton,
  CopyButtonWrapper,
  CopyHoursWrapper,
  HoursRange,
  Divider,
} from "./RegularHours/styles/regularDayTimeSelectionModalStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
const CopyHoursModal = ({
  isCopyModalOpen,
  setIsCopyModalOpen,
  copyCondidateHours,
  handleCopyHours,
  selectedHoursType,
}) => {
  const [copyFromDay, setCopyFromDay] = useState();
  useEffect(() => {
    setCopyFromDay(copyCondidateHours[0]?.dayId.toString());
  }, []);
  return (
    <Dialog
      open={isCopyModalOpen}
      onClose={() => setIsCopyModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <Wrapper>
        <Title>{`Copy ${selectedHoursType} Hours`}</Title>
        <CloseIcon
          className="fa fa-times"
          onClick={() => setIsCopyModalOpen(false)}
        />
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="hours"
            name="hours"
            value={copyFromDay}
            onChange={(e) => setCopyFromDay(e.target.value)}
          >
            {copyCondidateHours?.map((candidate) => (
              <CopyHoursWrapper>
                <FormControlLabel
                  style={{ fontWeight: "bold" }}
                  key={candidate.dayId}
                  value={candidate.dayId.toString()}
                  control={<Radio color="primary" />}
                  label={candidate.dayName}
                />
                <HoursRange>{candidate.value}</HoursRange>
              </CopyHoursWrapper>
            ))}
          </RadioGroup>
        </FormControl>
        <Divider />
        <CopyButtonWrapper>
          <CopyButton
            onClick={() => {
              handleCopyHours(parseInt(copyFromDay));
              setIsCopyModalOpen(false);
            }}
            disabled={!copyFromDay}
          >
            Copy Hour
          </CopyButton>
        </CopyButtonWrapper>
      </Wrapper>
    </Dialog>
  );
};

export default CopyHoursModal;
