import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { Field, FieldArray } from 'formik';
import {
  RenderTextField,
  RenderToggleSwitch,
} from '../../../../../components/FormElements/FormikElements';
import MediaModalContainer from '../../../../MediaModal/MediaModalContainer';
import Icon from '../../../../../components/Icon/Icon';
import Modal from '../../../../../components/Modal/Modal';
import { Button } from '../../../../../components/Button/Button';
import { TextField, Switch, Radio, Tooltip } from '@material-ui/core';

// Hided on column for release 2.8.0
const Wrapper = styled.div`
  display: grid;
  grid-gap: 25px;
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  grid-template-columns: 50px 3fr 2fr 2fr 1fr 2fr 1fr;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template: 50px / 50px auto 1fr;
  align-items: center;
  height: 50px;
`;

const MediaDefaultImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Image = styled.img`
  height: 50px;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
  background-color: #d3d3d3;
`;

const Caption = styled.div`
  color: #6f6f76;
  font-size: 14px;
  cursor: pointer;
  // border-bottom: 2px dashed #6f6f76;
`;

const OtherSelection = styled.div`
  position: absolute;
  width: 181px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  right: 5px;
`;

const OtherSelectionOption = styled.div`
  padding: 12px;
  font-size: 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;

  &:hover {
    background-color: #e8f4f8;
  }
`;

const ModalWrapper = styled.div`
  width: 600px;
  padding: 20px 30px;
  display: grid;
  grid-gap: 20px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const ItemForm = ({
  item,
  optionIndex,
  itemIndex,
  subItemIndex,
  arrayHelpers,
  isSubItem,
  subItemIsSortable,
  disableChildItems,
  option,
}) => {
  const [mediaSelectionModalIsVisible, setMediaSelectionModalIsVisible] =
    useState(false);

  const [userDescription, setUserDescription] = useState('');

  const [otherSelectionIsOpen, setOtherSelectionIsOpen] = useState(false);

  const [
    editItemDescriptionModalIsVisible,
    setEditItemDescriptionModalIsVisible,
  ] = useState(false);

  const baseFieldValue = isSubItem
    ? `options[${optionIndex}].items[${itemIndex}].sub_items[${subItemIndex}]`
    : `options[${optionIndex}].items[${itemIndex}]`;

  const baseTouchedValue = isSubItem
    ? arrayHelpers.form.touched.options &&
      arrayHelpers.form.touched.options[optionIndex] &&
      arrayHelpers.form.touched.options[optionIndex].items &&
      arrayHelpers.form.touched.options[optionIndex].items[itemIndex] &&
      arrayHelpers.form.touched.options[optionIndex].items[itemIndex]
        .sub_items &&
      arrayHelpers.form.touched.options[optionIndex].items[itemIndex].sub_items[
        subItemIndex
      ]
    : arrayHelpers.form.touched.options &&
      arrayHelpers.form.touched.options[optionIndex] &&
      arrayHelpers.form.touched.options[optionIndex].items &&
      arrayHelpers.form.touched.options[optionIndex].items[itemIndex];

  const baseErrorValue = isSubItem
    ? arrayHelpers.form.errors.options &&
      arrayHelpers.form.errors.options[optionIndex] &&
      arrayHelpers.form.errors.options[optionIndex].items &&
      arrayHelpers.form.errors.options[optionIndex].items[itemIndex] &&
      arrayHelpers.form.errors.options[optionIndex].items[itemIndex]
        .sub_items &&
      arrayHelpers.form.errors.options[optionIndex].items[itemIndex].sub_items[
        subItemIndex
      ]
    : arrayHelpers.form.errors.options &&
      arrayHelpers.form.errors.options[optionIndex] &&
      arrayHelpers.form.errors.options[optionIndex].items &&
      arrayHelpers.form.errors.options[optionIndex].items[itemIndex];

  const handleIsDefault = (
    selectedOptionIndex,
    secetedItemIndex,
    secetedSubItemIndex
  ) => {
    for (let i = 0; i < option.items.length; i++) {
      arrayHelpers.form.setFieldValue(
        `options[${selectedOptionIndex}].items[${i}].is_default`,
        false
      );
      if (option.items[i].sub_items) {
        for (let j = 0; j < option.items[i].sub_items.length; j++) {
          arrayHelpers.form.setFieldValue(
            `options[${selectedOptionIndex}].items[${i}].sub_items[${j}].is_default`,
            false
          );
        }
      }
    }
    if (secetedSubItemIndex >= 0) {
      arrayHelpers.form.setFieldValue(
        `options[${selectedOptionIndex}].items[${secetedItemIndex}].sub_items[${secetedSubItemIndex}].is_default`,
        true
      );
    } else {
      arrayHelpers.form.setFieldValue(
        `options[${selectedOptionIndex}].items[${secetedItemIndex}].is_default`,
        true
      );
    }
  };
  return (
    <Wrapper onMouseLeave={() => setOtherSelectionIsOpen(false)}>
      <div>
        {!isSubItem && (
          <div
            onClick={() => setMediaSelectionModalIsVisible(true)}
            style={{ cursor: 'pointer' }}
          >
            {!item.image_url ? (
              <MediaDefaultImage src="https://s3.amazonaws.com/assets.smoothpay.com/web/portal-assets/image-icon%402x.png" />
            ) : (
              <Image src={item.image_url} />
            )}
          </div>
        )}
      </div>
      <div>
        <Field
          name={`${baseFieldValue}.name`}
          component={RenderTextField}
          error={
            baseTouchedValue &&
            baseTouchedValue.name &&
            baseErrorValue &&
            baseErrorValue.name
          }
        />
        {item.user_description && (
          <Caption onClick={() => setEditItemDescriptionModalIsVisible(true)}>
            Edit Item Description
          </Caption>
        )}
        {/* {item.sub_items && !isSubItem && (
          <SubItemAmount>
            {item.sub_items.length} Sub-Items{' '}
            <i className="fal fa-chevron-down" />
          </SubItemAmount>
        )} */}
      </div>
      <Fragment>
        <Field
          name={`${baseFieldValue}.maximum_pick`}
          component={RenderTextField}
          error={
            baseTouchedValue &&
            baseTouchedValue.maximum_pick &&
            baseErrorValue &&
            baseErrorValue.maximum_pick
          }
        />

        {isSubItem || !item.sub_items ? (
          <Field
            name={`${baseFieldValue}.count_as`}
            component={RenderTextField}
            error={
              baseTouchedValue &&
              baseTouchedValue.count_as &&
              baseErrorValue &&
              baseErrorValue.count_as
            }
          />
        ) : (
          <div />
        )}
      </Fragment>

      {/* <div style={{ textAlign: 'center' }}>
        <Radio
          name={`${baseFieldValue}.is_default`}
          value={`${baseFieldValue}.is_default`}
          checked={
            isSubItem
              ? item.sub_items[subItemIndex].is_default || false
              : item.is_default
          }
          onChange={(e) =>
            handleIsDefault(
              optionIndex,
              itemIndex,
              subItemIndex,
              e.target.checked,
              isSubItem
            )
          }
          disabled={isSubItem ? false : item.sub_items ? true : false}
        />
      </div> // Hided on column for release 2.8.0 */}
      <div style={{ textAlign: 'center', margin: '0' }}>
        <Switch
          name={`${baseFieldValue}.is_active`}
          checked={
            isSubItem ? item.sub_items[subItemIndex].is_active : item.is_active
          }
          onChange={(e) => {
            arrayHelpers.form.setFieldValue(
              `${baseFieldValue}.is_active`,
              e.target.checked
            );
            if (!e.target.checked && disableChildItems) {
              disableChildItems();
            }
          }}
          color="primary"
        />
      </div>
      <div style={{ textAlign: 'center' }}>
        {!isSubItem ? (
          <Icon
            className="far fa-plus"
            onClick={() => {
              arrayHelpers.form.setFieldValue(`${baseFieldValue}.count_as`, 1);
              arrayHelpers.form.setFieldValue(
                `${baseFieldValue}.is_default`,
                false
              );
              item.sub_items
                ? arrayHelpers.form.setFieldValue(
                    `${baseFieldValue}.sub_items`,
                    [
                      ...item.sub_items,
                      {
                        parent_id: null,
                        name: '',
                        user_description: null,
                        maximum_pick: null,
                        image_url: null,
                        count_as: null,
                        position: null,
                        is_available: true,
                        is_active: false,
                        is_default: false,
                      },
                    ]
                  )
                : arrayHelpers.form.setFieldValue(
                    `${baseFieldValue}.sub_items`,
                    [
                      {
                        parent_id: null,
                        name: '',
                        user_description: null,
                        maximum_pick: null,
                        image_url: null,
                        count_as: null,
                        position: null,
                        is_available: true,
                        is_active: false,
                        is_default: false,
                      },
                    ]
                  );
            }}
          />
        ) : (
          <div />
        )}
      </div>
      <div style={{ textAlign: 'center' }}>
        {!isSubItem ? (
          <div>
            <Icon
              className="fas fa-ellipsis-v"
              onClick={() => setOtherSelectionIsOpen(!otherSelectionIsOpen)}
            />
            {otherSelectionIsOpen && (
              <div style={{ position: 'relative' }}>
                <OtherSelection>
                  {item.is_available ? (
                    <OtherSelectionOption
                      onClick={() => {
                        arrayHelpers.form.setFieldValue(
                          `${baseFieldValue}.is_available`,
                          false
                        );
                        setOtherSelectionIsOpen(false);
                      }}
                    >
                      <i className="fa fa-ban" />
                      <div>Make Unavailable</div>
                    </OtherSelectionOption>
                  ) : (
                    <OtherSelectionOption
                      onClick={() => {
                        arrayHelpers.form.setFieldValue(
                          `${baseFieldValue}.is_available`,
                          true
                        );
                        setOtherSelectionIsOpen(false);
                      }}
                    >
                      <i className="fa fa-check" />
                      <div>Make Available</div>
                    </OtherSelectionOption>
                  )}
                  <OtherSelectionOption
                    onClick={() =>
                      setEditItemDescriptionModalIsVisible(
                        !editItemDescriptionModalIsVisible
                      )
                    }
                  >
                    <i className="fal fa-pencil" />
                    <div>
                      {item.user_description
                        ? 'Edit Item Description'
                        : 'Add Item Description'}
                    </div>
                  </OtherSelectionOption>

                  <OtherSelectionOption
                    onClick={() => {
                      subItemIsSortable();
                      setOtherSelectionIsOpen(false);
                    }}
                  >
                    <i className="fa fa-sort-amount-down" />
                    {!item.sub_items ? (
                      <Tooltip title="There are no sub items to reorder">
                        <div style={{ opacity: '0.5' }}>Reorder Sub-Items</div>
                      </Tooltip>
                    ) : (
                      <div>
                        <div>Reorder Sub-Items</div>
                      </div>
                    )}
                  </OtherSelectionOption>
                </OtherSelection>
              </div>
            )}
          </div>
        ) : (
          <div />
        )}
      </div>
      {mediaSelectionModalIsVisible && (
        <MediaModalContainer
          closeModal={() => setMediaSelectionModalIsVisible(false)}
          selectProduct={(id, url) =>
            arrayHelpers.form.setFieldValue(
              `options[${optionIndex}].items[${itemIndex}].image_url`,
              url
            )
          }
        />
      )}
      {editItemDescriptionModalIsVisible && (
        <Modal>
          <ModalWrapper>
            <Title>
              {item.user_description ? 'Edit Description' : 'Add Description'}
            </Title>
            <TextField
              defaultValue={item.user_description}
              variant="outlined"
              style={{ width: '100%' }}
              multiline={true}
              rows={5}
              onChange={(e) => setUserDescription(e.target.value)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                secondary
                onClick={() => setEditItemDescriptionModalIsVisible(false)}
              >
                Cancel
              </Button>

              <Button
                onClick={() => {
                  arrayHelpers.form.setFieldValue(
                    `options[${optionIndex}].items[${itemIndex}].user_description`,
                    userDescription
                  );
                  setEditItemDescriptionModalIsVisible(false);
                }}
              >
                Save
              </Button>
            </div>
          </ModalWrapper>
        </Modal>
      )}
    </Wrapper>
  );
};

export default ItemForm;
