import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../Pagination/PaginationControl';
import { paginate } from './paginate';
import { Button } from 'merchant-portal-components';
import {
  Checkbox,
  TextField,
  InputAdornment,
  FormControlLabel,
} from '@material-ui/core';
import { formatLocations } from '../../utils/childLocations/formatLocations';
import * as actions from '../../modules/BusinessLocationDetails/LocationListManager/actions';
import { updateBusinessInformation } from '../../modules/BusinessLocationDetails/LocationDetailsManager/actions';
import { Dialog } from '@material-ui/core';
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  LocationsCounter,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  CloseIcon,
  PageSelectionWrapper,
} from './filterModalStyles';

const initialRequestParams = {
  page_number: 1,
  page_size: '10',
  sorting_option: 'title-asc',
};
const FilterLocationModal = ({
  locationList,
  requestParams,
  filteredLocations,
  setFilteredLocations,
  isOpen,
  setIsOpen,
  placeholder,
  searchLabel,
  requestingGetBusinessLocationsOfMerchant,
  updateLocationRequestParams,
  formField,
  emptyIsEqualToAll,
  isEmptyAccepted,
}) => {
  const [searchString, setSearchString] = useState('');
  // filter is because of user (roles) that have a limited access to locations.
  const [selectedLocations, setSelectedLocations] = useState({
    selectedLocationsIds: [],
    selectedLocationsQTY: 0,
  });

  const displayBusinesses = useMemo(() => {
    let searchedLocations = [...locationList];
    if (searchString) {
      searchedLocations = locationList?.filter((location) =>
        location.business_name
          ?.toLowerCase()
          .includes(searchString?.toLowerCase())
      );
    }
    let paginatedList = paginate(
      searchedLocations,
      requestParams.page_number,
      requestParams.page_size
    );
    return {
      currentPageLocations: paginatedList,
      searchedLocations: searchedLocations,
      locationsQty: searchedLocations?.length,
    };
  }, [requestParams.page_number, requestParams.page_size, searchString]);

  const locationsSelectionStatus = useMemo(() => {
    const isAllLocationsSelected = displayBusinesses.searchedLocations.every(
      (location) => selectedLocations.selectedLocationsIds.includes(location.id)
    );

    const isCurrentPageSelected = displayBusinesses.currentPageLocations.every(
      (location) => selectedLocations.selectedLocationsIds.includes(location.id)
    );

    return {
      isAllLocationsSelected,
      isCurrentPageSelected,
    };
  }, [
    JSON.stringify(selectedLocations.selectedLocationsIds),
    displayBusinesses.currentPageLocations,
    displayBusinesses.searchedLocations,
  ]);

  const updateSelectedLocations = useCallback(
    (checked, applyToCurrentPage, id) => {
      const newSelectedLocations = { ...selectedLocations };
      const locationsSource = applyToCurrentPage
        ? displayBusinesses.currentPageLocations
        : displayBusinesses.searchedLocations;

      if (id) {
        // single candidate selection
        if (checked) {
          newSelectedLocations.selectedLocationsIds.push(id);
        } else {
          newSelectedLocations.selectedLocationsIds =
            selectedLocations.selectedLocationsIds.filter(
              (locationId) => locationId !== id
            );
        }
      } else {
        if (applyToCurrentPage) {
          if (checked) {
            newSelectedLocations.selectedLocationsIds.push(
              ...locationsSource.map((item) => item.id)
            );
          } else {
            const currentPageLocationsIds =
              displayBusinesses.currentPageLocations.map(
                (location) => location.id
              );
            newSelectedLocations.selectedLocationsIds =
              selectedLocations.selectedLocationsIds.filter(
                (id) => !currentPageLocationsIds.includes(id)
              );
          }
        } else {
          newSelectedLocations.selectedLocationsIds = [];
          if (checked) {
            newSelectedLocations.selectedLocationsIds = locationsSource.map(
              (item) => item.id
            );
          }
        }
      }
      newSelectedLocations.selectedLocationsQTY =
        newSelectedLocations.selectedLocationsIds.length;
      setSelectedLocations(newSelectedLocations);
    },
    [selectedLocations, displayBusinesses]
  );

  const handleSave = () => {
    let newSelectedBusinessIds = [...selectedLocations.selectedLocationsIds];
    if (
      locationsSelectionStatus.isAllLocationsSelected &&
      emptyIsEqualToAll &&
      selectedLocations.selectedLocationsIds.length === locationList.length
    ) {
      newSelectedBusinessIds = [];
    }

    if (formField) {
      setFilteredLocations(formField, newSelectedBusinessIds);
    } else {
      setFilteredLocations(newSelectedBusinessIds);
    }
    setIsOpen(false);
  };

  const handleReset = useCallback(() => {
    setSelectedLocations({
      selectedLocationsIds: _.cloneDeep(filteredLocations),
      selectedLocationsQTY: filteredLocations.length,
    });
  }, [filteredLocations]);

  const isSaveDisabled = useMemo(() => {
    const newFilteredLocations = _.cloneDeep(filteredLocations);
    const isEqual = _.isEqual(
      newFilteredLocations.sort(),
      selectedLocations.selectedLocationsIds.slice().sort()
    );

    return !(
      (!isEqual && selectedLocations.selectedLocationsIds.length > 0) ||
      (isEqual &&
        selectedLocations.selectedLocationsIds.length === 0 &&
        isEmptyAccepted)
    );
  }, [
    JSON.stringify(selectedLocations.selectedLocationsIds),
    filteredLocations,
  ]);

  useEffect(() => {
    updateLocationRequestParams(initialRequestParams);
    return () => {
      updateLocationRequestParams(initialRequestParams);
    };
  }, [updateLocationRequestParams]);

  useEffect(() => {
    const newFilteredLocations =
      emptyIsEqualToAll && filteredLocations.length === 0
        ? locationList.map((item) => item.id)
        : _.cloneDeep(filteredLocations);

    const permittedLocationIds = newFilteredLocations.filter((item) =>
      locationList.map((item) => item.id).includes(item)
    );
    setSelectedLocations({
      selectedLocationsIds: permittedLocationIds,
      selectedLocationsQTY: permittedLocationIds.length,
    });
  }, []);

  // console.log('displayBusinesses', displayBusinesses);
  // console.log('requestParams', requestParams);
  const requestParamsNoNextPage = useMemo(() => {
    const { has_next_page, ...rest } = requestParams;
    return rest;
  }, [requestParams]);
  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <StyledCard>
        <HeaderWrapper padding='0'>
          <div>
            <SectionTitle>Locations</SectionTitle>

            <LocationsCounter>
              {`${selectedLocations.selectedLocationsQTY} locations selected`}
            </LocationsCounter>
          </div>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={() => setIsOpen(false)}
            ></CloseIcon>
          </div>
        </HeaderWrapper>

        <SearchWrapper>
          <TextField
            id='outlined-basic'
            label={searchLabel}
            placeholder={placeholder}
            variant='outlined'
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fa fa-search' style={{ color: '#b5b5b5' }}></i>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <i
                    className='fa fa-times'
                    onClick={() => setSearchString('')}
                    style={{
                      color: '#b5b5b5',
                      cursor: 'pointer',
                      marginRight: 10,
                    }}
                  ></i>
                  {`${displayBusinesses.locationsQty} results`}
                </InputAdornment>
              ),
            }}
          />
        </SearchWrapper>
        <PageSelectionWrapper>
          <FormControlLabel
            control={
              <Checkbox
                checked={locationsSelectionStatus.isAllLocationsSelected}
                onChange={(e) => updateSelectedLocations(e.target.checked)}
                color='primary'
                style={{
                  transform: 'scale(1.3)',
                }}
              />
            }
            sx={{
              '.MuiFormControlLabel-label': {
                fontWeight: 'bold',
              },
            }}
            label='Select all locations'
          />
        </PageSelectionWrapper>
        <RowWrapper>
          <TH>
            <Checkbox
              checked={locationsSelectionStatus.isCurrentPageSelected}
              onChange={(e) => updateSelectedLocations(e.target.checked, true)}
              color='primary'
              style={{
                transform: 'scale(1.3)',
              }}
            />
          </TH>
          <TH>Select results of current page</TH>
        </RowWrapper>
        {displayBusinesses.currentPageLocations.map(
          (businessLocation, index) => (
            <RowWrapper key={index} hoverBGColor='#f1f1fa'>
              <TD bgColor='yellow'>
                <Checkbox
                  checked={selectedLocations.selectedLocationsIds.includes(
                    businessLocation.id
                  )}
                  onChange={(e) =>
                    updateSelectedLocations(
                      e.target.checked,
                      false,
                      businessLocation.id
                    )
                  }
                  color='primary'
                  style={{
                    transform: 'scale(1.3)',
                  }}
                />
              </TD>
              <TD
                isAssigned={displayBusinesses.selectedLocationsIds?.includes(
                  businessLocation.id
                )}
              >
                {businessLocation.business_name}
              </TD>
            </RowWrapper>
          )
        )}

        <PaginationWrapper>
          <PaginationControl
            response={{
              ...displayBusinesses.searchedLocations,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: displayBusinesses.locationsQty,
            }}
            requestParams={requestParamsNoNextPage}
            type='locationFiltering'
          />
        </PaginationWrapper>
        <ButtonWrapper>
          <Left>
            <CancelButton onClick={handleReset}>Cancel</CancelButton>
          </Left>
          <Right>
            <Button onClick={handleSave} disabled={isSaveDisabled}>
              Save
            </Button>
          </Right>
        </ButtonWrapper>
        {requestingGetBusinessLocationsOfMerchant ? <LoadingBlueLarge /> : null}
      </StyledCard>
    </Dialog>
  );
};
function mapStateToProps(state) {
  return {
    requestingGetBusinessLocationsOfMerchant:
      state.businessLocationsList.requestingGetBusinessLocationsOfMerchant,
    locationList: state.businessLocationsList.businessLocationsOfMerchant,
    requestParams: state.businessLocationsList.requestParams,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  updateBusinessInformation,
})(FilterLocationModal);
