import React, { useState, useEffect, Fragment, useCallback } from 'react';
import styled from 'styled-components';
import TextFieldPOS from '../../components/TextFieldPOS';
import ToogleFieldPOS from '../../components/ToogleFieldPOS';
import { Select } from 'merchant-portal-components';
import { Formik, FieldArray } from 'formik';
import StickyButtons from '../../../../../components/StickyButtons';
import ConfirmUnsavedChangesModal from '../../components/ConfirmUnsavedChangesModal';

const MainDiv = styled.div``;

const ProductsDiv = styled.div``;

const POSDiv = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const ProductsHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  height: 25px;
`;

const POSHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #2440ae;
  height: 25px;
`;

const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const POSFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.fieldsNumber}, 180px)`};
`;

const VariantCell = styled.div`
  height: 80px;
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
`;

const POSValueCell = styled.div`
  height: 80px;
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0 10px 15px 0;
`;

const LineDivider = styled.hr`
  margin-top: 15px;
  border-color: rgba(0, 0, 0, 0.1);
  grid-column: span ${(props) => `${props.fieldsNumber}`};
`;

const CardTitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: #191818;
  margin-bottom: 26px;
`;

const ToogleFieldPOSWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
`;

const ProductsMappingTable = ({
  products,
  productsMappingFields,
  handleProductsSearch,
  handleGetPosMappings,
  currentProductMappingValues,
  setIsRequestingProductPosMappings,
  isRequestingProductPosMappings,
  submitProductPosMappingValues,
  paramsProductId,
}) => {
  const beautifyProductVariantNames = (name) => {
    return name
      .toLowerCase()
      .split(' ')
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(' ');
  };

  const productsModified = products.map((product) => {
    return {
      ...product,
      value: product.productId,
      label: product.productName + ' - ' + product.productId,
    };
  });

  const [selectedValue, setSelectedValue] = useState(null);
  const [nextSelectedValue, setNextSelectedValue] = useState(null);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const updatePOSFromHistory = useCallback(() => {
    if (paramsProductId && products?.length > 0) {
      let selectedProd = products?.find(
        (pro) => pro.productId === paramsProductId
      );
      selectedProd = {
        ...selectedProd,
        value: selectedProd?.productId,
        label: selectedProd?.productName + ' - ' + selectedProd?.productId,
      };
      setSelectedValue(selectedProd);
      handleGetPosMappings(selectedProd, productsMappingFields);
    }
  }, [paramsProductId, products]);
  useEffect(() => {
    updatePOSFromHistory();
  }, [updatePOSFromHistory]);
  const handleChange = (newValue, dirty) => {
    if (dirty) {
      setIsConfirmModal(true);
      setNextSelectedValue(newValue);
    } else {
      setIsRequestingProductPosMappings();
      handleGetPosMappings(newValue, productsMappingFields);
      setSelectedValue(newValue);
    }
  };

  const handleInputChange = (searchStr) => {
    handleProductsSearch(searchStr);
  };

  const setNextSelectedValueCallback = () => {
    if (nextSelectedValue) {
      let newSelectedValue = nextSelectedValue;
      setIsRequestingProductPosMappings();
      setSelectedValue(nextSelectedValue);
      setNextSelectedValue(null);
      return newSelectedValue.productId;
    }
    return selectedValue.productId;
  };
  const handleFormSubmit = (values) => {
    submitProductPosMappingValues(
      selectedValue,
      productsMappingFields,
      values,
      setNextSelectedValueCallback
    );
  };

  const posFieldNums = Object.keys(productsMappingFields).length;
  return (
    <MainDiv>
      <Formik
        initialValues={currentProductMappingValues}
        enableReinitialize={true}
      >
        {({
          values,
          isValid,
          handleReset,
          dirty,
          errors,
          handleSave,
          validateForm,
        }) => (
          <Fragment>
            <div style={{ marginBottom: '50px' }}>
              <CardTitle>Please select or search for a product</CardTitle>
              <Select
                value={selectedValue}
                isClearable={selectedValue}
                onChange={(newValue) => handleChange(newValue, dirty)}
                onInputChange={handleInputChange}
                options={productsModified}
                placeholder='Please select or search for a product'
                noOptionsMessage={'No Products'}
              />
            </div>
            {selectedValue && !isRequestingProductPosMappings && (
              <Fragment>
                <Body>
                  <ProductsDiv>
                    <ProductsHeader>
                      <div>Variant Name</div>
                    </ProductsHeader>
                    <LineDivider />
                    {selectedValue.productVariants.map((variant) => {
                      return (
                        <VariantCell key={variant.variantId}>
                          <span>
                            {beautifyProductVariantNames(variant.variantName)}
                          </span>
                        </VariantCell>
                      );
                    })}
                  </ProductsDiv>
                  <POSDiv>
                    <POSFieldWrapper fieldsNumber={posFieldNums}>
                      {Object.values(productsMappingFields).map((item) => {
                        return (
                          <POSHeader key={`c_${item['key']}`}>
                            {item['label']}
                            {!item.optional && '*'}
                          </POSHeader>
                        );
                      })}
                      <LineDivider fieldsNumber={posFieldNums} />
                      <FieldArray
                        name='mappings'
                        render={(arrayHelpers) => {
                          return selectedValue.productVariants.map(
                            (variant) => {
                              const variant_values =
                                currentProductMappingValues[
                                  variant.variantId
                                ] || [];
                              return (
                                <Fragment key={`v_${variant?.variantId}`}>
                                  {Object.keys(productsMappingFields).map(
                                    (key) => {
                                      const value = variant_values[key] || '';
                                      const maxLength = key.includes(
                                        'product_name'
                                      )
                                        ? 50
                                        : 10;
                                      return (
                                        <POSValueCell
                                          key={`p_${variant?.variantId}_${key}`}
                                        >
                                          {productsMappingFields[key]['type'] ==
                                          'boolean' ? (
                                            <ToogleFieldPOSWrapper>
                                              <ToogleFieldPOS
                                                name={`mappings[${variant?.variantId}][${key}]`}
                                                onChange={
                                                  arrayHelpers.form
                                                    .setFieldValue
                                                }
                                              />
                                            </ToogleFieldPOSWrapper>
                                          ) : (
                                            <>
                                              <TextFieldPOS
                                                maxLength={maxLength}
                                                styles={{ minWidth: '100px' }}
                                                name={`mappings[${variant.variantId}][${key}]`}
                                                value={
                                                  currentProductMappingValues
                                                    ?.mappings[
                                                    variant?.variantId
                                                  ]
                                                    ? currentProductMappingValues
                                                        ?.mappings[
                                                        variant?.variantId
                                                      ][key]
                                                    : ''
                                                }
                                                setFieldValue={
                                                  arrayHelpers.form
                                                    .setFieldValue
                                                }
                                              />
                                            </>
                                          )}
                                        </POSValueCell>
                                      );
                                    }
                                  )}
                                </Fragment>
                              );
                            }
                          );
                        }}
                      />
                    </POSFieldWrapper>
                  </POSDiv>
                </Body>
                <StickyButtons
                  isVisible={dirty}
                  handleDiscard={() => handleReset()}
                  handleSave={() => {
                    validateForm().then(() => handleFormSubmit(values));
                  }}
                  title='Product POS Mapping'
                />
              </Fragment>
            )}
            {isConfirmModal && (
              <ConfirmUnsavedChangesModal
                toggleConfirmModal={() => {
                  setNextSelectedValue();
                  setIsConfirmModal(false);
                }}
                handleSave={() => {
                  setIsConfirmModal(false);
                  validateForm().then(() => {
                    if (isValid) {
                      handleFormSubmit(values);
                    }
                  });
                }}
              />
            )}
          </Fragment>
        )}
      </Formik>
    </MainDiv>
  );
};

export default ProductsMappingTable;
