const appIdentifier = localStorage.getItem('appIdentifier');
export const isMaryBrowns = appIdentifier === 'mary_browns';
export const newOfferEngineClients = [
  'chaiiwala',
  'daily_ritual_cafe',
  'double_pizza',
  'smooth_cafe',
  'mary_browns',
  'bigwheelburger',
  'harvestcleaneats',
  'toppers',
  'onekingwest_amenities',
];
export const multipleAppFeedItemClients = ['deloitte'];

export const brinkClients = ['madradish', 'chillipeppers', 'good_earth'];
export const orderManagementClients = [
  'feastify',
  'basilbox_catering',
  'onekingwest_amenities',
];
export const isParentChildClient = ['feastify'].includes(appIdentifier);

export const isExportReportClient = [
  'mary_browns',
  'onekingwest_amenities',
].includes(appIdentifier);
