import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import * as actions from '../offerActions';
import OfferDetails from './OfferDetails';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import BackButton from '../../../../components/BackButton';
import { getCustomersByQuery } from '../../../CustomerSearchManager/actions';
import { getSegments } from '../../../Segmentation/segmentationActions';
import { getProducts } from '../../../MenuManagement/Products/productsActions';
const BackText = styled.div`
  margin-bottom: 15px;
  margin-right: auto;
  &:hover {
    color: #3051d4;
  }
`;
const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;
const OfferDetailsContainer = ({
  createOffer,
  requestingCreateOffer,
  editingOffer,
  editOffer,
  requestingEditOffer,
  resetOfferStates,
  appMetaData,
  allBusinesses,
  customerSearchManager,
  getCustomersByQuery,
  getSegments,
  allSegments,
  allProducts,
  getProducts,
}) => {
  useEffect(() => {
    getProducts({
      page_number: 1,
      page_size: '4000',
      sorting_option: 'title-asc',
      search_string: '',
      has_next_page: true,
    });
    getSegments();
    if (editingOffer) {
      track.page('engagement-offers-edit');
    } else {
      track.page('engagement-offers-create');
    }
    ReactGA.pageview(window.location.pathname, undefined, 'Offer Detail');
    return () => resetOfferStates();
  }, []);
  const handleSubmit = (offer) => {
    editingOffer ? editOffer(offer) : createOffer(offer);
  };

  const isPointsEnabled =
    appMetaData.configuration.program_type &&
    appMetaData.configuration.program_type == 'points';
  return (
    <Fragment>
      <BreadCrumbWrapper>
        <BackButton pageName='Offers List' onClick={() => resetOfferStates()} />
        {/* <BackText>
          <div style={{ cursor: 'pointer' }} onClick={() => resetOfferStates()}>
            &lt; Back To Offers List
          </div>
        </BackText> */}
        <BreadCrumb
          style={{ margin: ' 0px' }}
          pageName={editingOffer ? 'Edit Offer' : 'Create Offer'}
        />
      </BreadCrumbWrapper>

      <OfferDetails
        handleSubmit={handleSubmit}
        isRequesting={requestingCreateOffer || requestingEditOffer}
        editingOffer={editingOffer}
        isPointsEnabled={isPointsEnabled}
        allBusinesses={allBusinesses}
        customerSearchManager={customerSearchManager}
        getCustomersByQuery={getCustomersByQuery}
        allSegments={allSegments}
        allProducts={allProducts}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  requestingEditOffer: state.offersReducer.requestingEditOffer,
  requestingCreateOffer: state.offersReducer.requestingCreateOffer,
  editingOffer: state.offersReducer.editingOffer,
  appMetaData: state.accountReducer.appMetaData,
  allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  customerSearchManager: state.customerSearchManager,
  allSegments: state.segments.availableSegments,
  allProducts: state.productsReducer.allProducts,
});
export default connect(mapStateToProps, {
  ...actions,
  getCustomersByQuery,
  getSegments,
  getProducts,
})(OfferDetailsContainer);
