import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  RenderSelect,
  required,
} from "../../../../../components/FormElements/FormikElements";
import { Select } from "merchant-portal-components";

const Wrapper = styled.div`
  align-items: center;
`;

const SourceOptions = ({
  options,
  setFieldValue,
  conditions,
  templateName,
  isFormReset,
}) => {
  const getInitalSource = () =>
    conditions.map((condition) => {
      return { label: condition.source, value: condition.source };
    });

  const initialConditionObj = {};
  conditions.map((condition) => {
    return (initialConditionObj[condition.source] = { condition });
  });

  let prefix = "";
  if (
    templateName === "discounted-item" ||
    templateName === "birthday-discounted-item"
  ) {
    prefix = "discounted";
  } else {
    prefix = "eligible";
  }

  const [sourceArr, setSourceArr] = useState(getInitalSource());

  useEffect(() => {
    if (isFormReset) {
      setSourceArr(getInitalSource());
    }
  }, [isFormReset]);

  useEffect(() => {
    setSourceArr(getInitalSource());
  }, [templateName]);

  useEffect(() => {
    const newConditionArr = sourceArr.map((source) => {
      if (source.value in initialConditionObj) {
        return initialConditionObj[source.value].condition;
      } else if (templateName === "birthday-free-item") {
        return {
          is_active: true,
          source: source.value,
          item_conditions: {
            [prefix + "_products"]: [],
          },
        };
      } else {
        return {
          is_active: true,
          source: source.value,
          item_conditions: {
            [prefix + "_products"]: [],
            required_products: [],
            [prefix + "_variants"]: [],
            required_variants: [],
          },
        };
      }
    });

    setFieldValue("conditions", newConditionArr);
  }, [sourceArr]);

  return (
    <Wrapper>
      <div style={{ margin: "15px 0 10px 0" }}>*Offer Source</div>
      <Select
        value={sourceArr}
        isMulti
        placeholder="options"
        options={options || []}
        getOptionLabel={(label) => label.label}
        getOptionValue={(value) => value.value}
        validate={required}
        onChange={(e) => {
          setSourceArr(e);
        }}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  options: state.offersReducer.options,
});

export default connect(mapStateToProps, null)(SourceOptions);
