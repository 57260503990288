import React from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 0.7fr 0.5fr 1.5fr 1.5fr 0.5fr 1.25fr;
  grid-gap: 10px;
  border-bottom: 1px solid #ededed;
  padding: 10px 0;
  background-color: white;
`;
const CellWrapper = styled.div`
  width: 100%;
  padding: 0;
  font-weight: ${(props) => (props.isBold ? '600' : 'normal')};
`;

const Badge = styled.span`
  width: 100%;
  border-radius: 12px;
  padding: 4px 15px;
  color: '#191818';
  border: ${(props) =>
    props.status === -1
      ? '1px solid #CECECE'
      : props.status === 0
      ? 'none'
      : props.status === 1
      ? 'none'
      : props.status === 2
      ? 'none'
      : props.status === 3
      ? 'none'
      : props.status === 4
      ? 'none'
      : props.status === 5
      ? 'none'
      : 'none'};
  background-color: ${(props) =>
    props.status === -1
      ? 'transparent'
      : props.status === 0
      ? '#ffe047'
      : props.status === 1
      ? '#6ea7fe'
      : props.status === 2
      ? '#00dac6'
      : props.status === 3
      ? '#ff9f0a'
      : props.status === 4
      ? '#b305ad'
      : props.status === 5
      ? '#CECECE'
      : 'white'};
`;
const getStatus = (status) => {
  switch (status) {
    case -1:
      return 'Refounded';
    case 0:
      return 'New';
    case 1:
      return 'In Progress';
    case 2:
      return 'Ready For Pick up';
    case 3:
      return 'Rejected';
    case 4:
      return 'On Delivery';
    case 5:
      return 'Delivered';
    default:
      return 'Undefined Status';
  }
};
const PastOrdersList = ({ orderList }) => {
  return (
    <>
      {orderList?.map((order) => (
        <RowWrapper key={order.order_id}>
          <CellWrapper>
            <Checkbox
              defaultChecked
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </CellWrapper>
          <CellWrapper>
            {order.order_created_at.format('DD/MM/YYYY')}
          </CellWrapper>
          <CellWrapper>{order.order_type}</CellWrapper>
          <CellWrapper>{order.order_id}</CellWrapper>
          <CellWrapper>{order.business_name}</CellWrapper>
          <CellWrapper>{order.customer_name}</CellWrapper>
          <CellWrapper>{order.total}</CellWrapper>
          <CellWrapper>
            <Badge status={order.status}>{getStatus(order.status)}</Badge>
          </CellWrapper>
        </RowWrapper>
      ))}
    </>
  );
};

export default PastOrdersList;
