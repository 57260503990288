export function formatProductBusinessesAssigned(
  comboBusinesses,
  allBusinesses
) {
  const comboSelectedBusinessesInitialValues = comboBusinesses.map(
    (business) => business.business_id
  );

  let finalBusinessesArray;
  const set = new Set();
  comboSelectedBusinessesInitialValues.forEach((data) => {
    set.add(data);
  });

  //isDisabled field - a business is assigned or not
  finalBusinessesArray = allBusinesses.map((business) => ({
    ...business,
    ...((!set.has(business.id) || !business.is_active) && { isDisabled: true }),
  }));

  return finalBusinessesArray;
}

export const formatDataSummaryView = (comboOverrides) => {
  const groupedByBusinesses = {};

  comboOverrides.forEach((override) => {
    const key = `${override.combo_id}_${override.fixed_price}`;

    if (groupedByBusinesses[key]) {
      groupedByBusinesses[key].businesses.push(override.business_id);
    } else {
      groupedByBusinesses[key] = {
        combo_id: override.combo_id,
        fixed_price: override.fixed_price,
        businesses: [override.business_id],
      };
    }
  });

  return groupedByBusinesses;
};

export const finalStructureSummaryData = (
  comboOverrides,
  formattedBusinesses
) => {
  const response = formatDataSummaryView(comboOverrides);

  let finalDataStructure = [];
  if (formattedBusinesses) {
    const formattedBusinessesModified = formattedBusinesses.reduce(
      (acc, val) => {
        acc[val.id] = val.business_name;
        return acc;
      },
      {}
    );

    let responseArray = Object.values(response);

    finalDataStructure = responseArray.map((val) => ({
      ...val,
      businesses: val.businesses.map((businessId) => ({
        id: businessId,
        name: formattedBusinessesModified[businessId],
      })),
    }));
  }
  return finalDataStructure;
};
