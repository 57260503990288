import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './variantsActions';
import VariantDetailsContainer from './VariantDetails/VarianDetailsContainer';
import VariantListContainer from './VariantList/VariantListContainer';
import { LoadingBlueLarge } from 'merchant-portal-components';

const VariantsContainer = ({
  componentType,
  variantToEdit,
  // resetToInitialValues,
  getVariants,
  // isVariantAvailable,
  requestParams,
  updateVariantsRequestParams,
}) => {
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    return () => {
      updateVariantsRequestParams({
        ...requestParams,
        page_number: 1,
      });
      setSearchString('');
    };
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === '') {
        getVariants(newRequestParams);
      }
      updateVariantsRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString, componentType]);
  return (
    <div>
      {componentType === 'list' ? (
        <VariantListContainer
          searchString={searchString}
          setSearchString={setSearchString}
        ></VariantListContainer>
      ) : componentType === 'create' ? (
        <VariantDetailsContainer></VariantDetailsContainer>
      ) : (
        <VariantDetailsContainer
          variantToEdit={variantToEdit}
        ></VariantDetailsContainer>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  componentType: state.variantsReducer.componentType,
  requestParams: state.variantsReducer.requestParams,
  variantToEdit: state.variantsReducer.variantToEdit,
  isVariantAvailable: state.variantsReducer.isVariantAvailable,
});
export default connect(mapStateToProps, actions)(VariantsContainer);
