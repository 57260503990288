import React, { Component } from 'react';
import { CardRow, FlexRow } from './components/AppFeedElements';
import { connect } from 'react-redux';
import * as actions from './appFeedActions';

import CardForm from './components/CardForm';
import { IconWrapper, EditIcon, DeleteIcon } from '../../components/Icon';
import { multipleAppFeedItemClients } from '../../utils/clientCustomizations';
class SortableCard extends Component {
  removeCard() {
    this.props.changed(true);
    const { fields, card, deleteCard } = this.props;
    fields.remove(card.slice(-2, -1));
    const cardValues = fields.get(card.slice(-2, -1));
    deleteCard(cardValues);
  }

  renderOrHideActionIcons() {
    const { editingCard, fields, card, appIdentifier } = this.props;
    const cardValues = fields.get(card.slice(-2, -1));
    if (!editingCard) {
      return (
        <IconWrapper>
          <div>
            {multipleAppFeedItemClients.includes(appIdentifier) && (
              <DeleteIcon onClick={() => this.removeCard()} />
            )}
          </div>
          <EditIcon onClick={() => this.props.openEditCardForm(cardValues)} />
        </IconWrapper>
      );
    }
  }

  cancelEditCard() {
    const { fields, card, editingCard } = this.props;
    const index = card.slice(-2, -1);
    fields.remove(index);
    fields.insert(index, editingCard);
    this.props.closeEditCardForm(editingCard);
  }

  cancelCreateCard() {
    this.props.closeCreateCardForm();
    this.removeCard();
  }

  cancel() {
    this.props.editingCard ? this.cancelEditCard() : this.cancelCreateCard();
  }

  done() {
    this.props.editingCard
      ? this.props.editCard()
      : this.props.closeCreateCardForm();
  }

  render() {
    const { fields, card, editingCard, showCreateCardForm } = this.props;
    const index = card.slice(-2, -1);
    const cardValues = fields.get(index);
    if (
      (showCreateCardForm && fields.length == parseInt(index) + 1) ||
      (editingCard && editingCard.id === cardValues.id)
    ) {
      return (
        <CardForm
          card={card}
          cancel={() => this.cancel()}
          done={() => this.done()}
          formValues={this.props.formValues}
          changed={this.props.changed}
          {...this.props}
        />
      );
    }
    return (
      <CardRow sortDisabled={editingCard} is_visible={cardValues.is_visible}>
        <FlexRow
          style={{
            height: '100%',

            // margin: '0',
            // padding: '0',
          }}
        >
          <div
          // style={{
          //   textOverflow: 'ellipsis',
          //   whiteSpace: 'nowrap',
          //   overflow: 'hidden'
          // }}
          >
            {cardValues.title
              ? cardValues.title.length > 30
                ? cardValues.title.slice(0, 30).concat('...')
                : cardValues.title
              : cardValues.title}
          </div>
          {this.renderOrHideActionIcons()}
        </FlexRow>
      </CardRow>
    );
  }
}

function mapStateToProps(state) {
  const appFeedDetails = state.appFeed.data;
  return {
    editingCard: state.appFeed.editingCard,
    showCreateCardForm: state.appFeed.showCreateCardForm,
  };
}

SortableCard = connect(mapStateToProps, actions)(SortableCard);

export default SortableCard;
