import React, { Fragment } from 'react';
import styled from 'styled-components';
import CustomerLoyaltyPointsTable from './CustomerLoyaltyPointsTable';
import CustomerLoyaltyPointsTier from './CustomerLoyaltyPointsTier';
import { Wrapper } from 'merchant-portal-components';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const Title = styled.div`
  font-weight: 600;
  margin: 0 45px 45px 0;
  font-size: 20px;
`;
const LoyaltiPointWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BalanceContainerWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas: 'a b c';
  grid-gap: 75px;
  @media screen and (max-width: 1232px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'a c';
  }
`;

const BalanceContainer = styled.div``;

const SendPoints = styled.div`
  color: ${(props) => (props.disabled ? '#919FD6' : props.theme.blue)};
  font-weight: 600;
  padding: 10px 0;
  grid-area: c;
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
  text-transform: uppercase;
`;

const BalanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 10px;
  padding: 5px;
`;
const NotFaundWrapper = styled.div`
  text-align: center;
  font-weight: 500;
`;
const BalanceName = styled.div`
  font-size: 14px;
`;

const BalanceValue = styled.div`
  font-weight: 600;
  font-size: 14px;
  min-width: 120px;
`;

const HorizontalLine = styled.hr`
  border: 0;
  border-top: 2px solid #d8d8d8;
  opacity: 0.4;
  margin: 50px 37px;
`;

const CustomerLoyaltyPoints = (props) => {
  const {
    customerDetails,
    toggleSendLoyaltyModal,
    loyaltyPoints,
    loyaltyPointsAccumulation,
    loyaltyTier,
    customerdelete,
  } = props;
  const useStyles = makeStyles((theme) => ({
    tooltip: {
      maxWidth: '453px',
      borderRadius: '7px',
      padding: '8px 9px 9px 12px',
      borderRadius: '7px',
      fontSize: '12px',
      marginTop: '40px',
      marginLeft: '515px',
      fontFamily: 'Montserrat',
      backgroundColor: 'rgba(0, 0, 0, 0.8);',
      color: '#ffffff',
    },
    button: {
      backgroundColor: '#2440ae',
      color: '#ffffff',
      maxHeight: '40px',
      width: '100%',
      borderRadius: '28px',
      fontSize: '14px',
      fontWeight: '600',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      textAlign: 'right',
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3151d4',
      },
    },
  }));
  const classes = useStyles();

  function customerDeletehandle() {
    if (!customerdelete) {
      toggleSendLoyaltyModal();
    }
  }

  return (
    <Fragment>
      <Wrapper>
        <Title data-test='title'>Customer Loyalty History</Title>
        {loyaltyPoints ? (
          <>
            <BalanceContainerWrapper>
              <BalanceContainer>
                <BalanceWrapper>
                  <BalanceName data-test='label-currentPointsBalance'>
                    Current Points Balance
                  </BalanceName>
                  <BalanceValue data-test='value-currentPointsBalance'>
                    {loyaltyPoints.current_points} pts
                  </BalanceValue>
                </BalanceWrapper>
                <BalanceWrapper>
                  <BalanceName data-test='label-lifetimeAccumulation'>
                    Lifetime Accumulation
                  </BalanceName>
                  <BalanceValue data-test='value-lifetimeAccumulation'>
                    {loyaltyPoints.lifetime_points} pts
                  </BalanceValue>
                </BalanceWrapper>
                {loyaltyTier && (
                  <CustomerLoyaltyPointsTier loyaltyTier={loyaltyTier} />
                )}
              </BalanceContainer>
            </BalanceContainerWrapper>
            <LoyaltiPointWrapper>
              {customerdelete ? (
                <Tooltip
                  classes={classes}
                  title='Action unavailable because this account is deleted.'
                  placement='right'
                >
                  <SendPoints
                    data-test='button-sendPoints'
                    onClick={!customerDetails?.is_guest && customerDeletehandle}
                    disabled={customerdelete || customerDetails?.is_guest}
                  >
                    + Send loyalty points
                  </SendPoints>
                </Tooltip>
              ) : (
                <SendPoints
                  data-test='button-sendPoints'
                  onClick={!customerDetails?.is_guest && customerDeletehandle}
                  disabled={customerDetails?.is_guest}
                >
                  + Send loyalty points
                </SendPoints>
              )}
            </LoyaltiPointWrapper>
            <CustomerLoyaltyPointsTable
              loyaltyPointsAccumulation={loyaltyPointsAccumulation}
            />
          </>
        ) : (
          <NotFaundWrapper>No Record Found</NotFaundWrapper>
        )}
      </Wrapper>
      <HorizontalLine />
    </Fragment>
  );
};

export default CustomerLoyaltyPoints;
