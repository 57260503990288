import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import GoogleMapsWithOverlayZones from '../../../../../../GoogleMapsWithOverlayZones/GoogleMapsWithOverlayZones';
import {
  ControlShowShapesContainer,
  ShapeNameGrid,
  ShapeName,
  SubHeaderWrapper,
  InteractWithGoogleMapsGridContainer,
  StyledP,
} from '../../common/styledComponents';

let BusinessLocationDeliveryZonesEditShape = (props) => {
  const {
    deliveryZoneObjects,
    handleDeliveryZoneVisibility,
    editingColour,
    zoneFormName,
    locationCoords,
  } = props;

  return (
    <Fragment>
      <SubHeaderWrapper>
        <StyledP semiBold size='18px'>
          Draw Delivery Zone
        </StyledP>
      </SubHeaderWrapper>
      <InteractWithGoogleMapsGridContainer>
        <ControlShowShapesContainer>
          <StyledP semiBold>Show Other Zones:</StyledP>

          {deliveryZoneObjects.map((deliveryZone, index) => (
            <ShapeNameGrid key={index}>
              <Checkbox
                color='primary'
                checked={deliveryZone.isVisible}
                onChange={(event) =>
                  handleDeliveryZoneVisibility(deliveryZone, event)
                }
              />
              <ShapeName>
                <StyledP
                  semiBold
                  isVisible={deliveryZone.isVisible}
                  isEditable={deliveryZone.isEditable}
                >
                  {deliveryZone.isEditable
                    ? zoneFormName
                    : deliveryZone.zoneName}
                </StyledP>
              </ShapeName>
            </ShapeNameGrid>
          ))}
        </ControlShowShapesContainer>
        {deliveryZoneObjects.length ? (
          <GoogleMapsWithOverlayZones
            overlayObjects={deliveryZoneObjects}
            maxDrawnOverlaysAllowed={1}
            editingColour={editingColour}
            allowDrawing
            center={locationCoords}
            selectedLocations={[locationCoords]}
            zoom={15}
          />
        ) : null}
      </InteractWithGoogleMapsGridContainer>
    </Fragment>
  );
};

BusinessLocationDeliveryZonesEditShape = connect((state) => {
  return { zoneFormName: state.form.editDeliveryZoneForm.values.zoneName };
})(BusinessLocationDeliveryZonesEditShape);

export default BusinessLocationDeliveryZonesEditShape;
