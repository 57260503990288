import {
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
  FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS,
  GET_BUSINESSES_LOCATIONS,
  GET_ARCHIVED_BUSINESSES_SUCCESS,
} from './types';

export default function (
  state = {
    archivedBusinesses: [],
    currentPage: 'list',
    allBusinessLocations: [],
    meta: { page_number: 1, page_size: 25 },
    requestingGetBusinessLocationsOfMerchant: false,
    businessLocationsOfMerchant: [],
    subBusinessLocationsOfMerchant: [],
    selectedBusinessLocationsOfMerchant: [],
    requestParams: {
      page_number: 1,
      page_size: 25,
      has_next_page: false,
    },
  },
  action
) {
  switch (action.type) {
    case GET_ARCHIVED_BUSINESSES_SUCCESS:
      return {
        ...state,
        archivedBusinesses: action.payload,
        meta: action.meta,
      };
    case GET_BUSINESSES_LOCATIONS:
      return {
        ...state,
        allBusinessLocations: action.payload,
        businessLocationsOfMerchant: action.payload,
        meta: action.meta,
        requestParams: {
          ...state.requestParams,
          has_next_page: action.meta.has_next_page,
        },
        requestingGetBusinessLocationsOfMerchant: false,
      };
    case 'UPDATE_BUSINESS_LOCATIONS_REQUEST_PARAMS':
      const newRequestParams = {
        page_number: action.payload.page_number,
        page_size: action.payload.page_size,
      };
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...newRequestParams,
        },
      };
    case GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST:
      return {
        ...state,
        requestingGetBusinessLocationsOfMerchant: true,
        businessLocationsOfMerchant: [],
        subBusinessLocationsOfMerchant: [],
      };
    case GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS:
      return {
        ...state,
        requestingGetBusinessLocationsOfMerchant: false,
        businessLocationsOfMerchant: action.businessLocationsOfMerchant,
        subBusinessLocationsOfMerchant: action.subBusinessLocationsOfMerchant,
        selectedBusinessLocationsOfMerchant:
          action.selectedBusinessLocationsOfMerchant,
      };
    case FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS:
      return {
        ...state,
        selectedBusinessLocationsOfMerchant: [action.payload],
      };
    case 'UPDATE_LOCATION_FILTERING_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
