import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../OfferEngineActions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { Button } from 'merchant-portal-components';
import Promolist from './OffersEnginePromo';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PromoList from './OffersEnginePromo';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  padding: 37px 0;
`;
const MessageWrapper = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;
const TextSearch = styled.div``;
const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  margin: 0 37px 37px 37px;
`;

let PromoListContainer = ({
  values,
  offerPromoCode,
  requestParamsPromo,
  isGetVariantRequest,
  toggleIsActive,
  isVariantAvailable,
  setEditVariant,
  variantIsSaving,
  getVariants,
  searchString,
  setSearchString,
  getPromos,
  setAvailabilityModalState,
  availabilityModalState,
  setFieldValue,
  setSelectedPromoCode,
  updatePromoRequestParams,
}) => {
  useEffect(() => {
    const debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParamsPromo,
        page_number: 1,
        search_string: searchString,
      };
      if (values?.offer_details?.offer_id) {
        if (searchString.length > 2 || searchString === '') {
          getPromos(newRequestParams, values.offer_details.offer_id);
        }
      }
      updatePromoRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString]);
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };
  const handleClearSearch = () => {
    setSearchString('');
  };

  return (
    <Fragment>
      <Wrapper>
        <TopDiv>
          <TextSearch>
            <TextField
              type='text'
              style={{ width: '100%' }}
              variant='outlined'
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon className='fal fa-search' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='start'>
                    <Icon
                      className='fas fa-times-circle'
                      style={{ color: '#193053' }}
                      onClick={handleClearSearch}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder='Search promo code(s)'
            />
          </TextSearch>
          <Button
            style={{ width: '100%' }}
            onClick={() => setAvailabilityModalState(true)}
          >
            Add Promo Codes
          </Button>
        </TopDiv>
        {!isGetVariantRequest && offerPromoCode.length > 0 ? (
          <PromoList
            variantIsSaving={variantIsSaving}
            offerPromoCode={offerPromoCode}
            requestParamsPromo={requestParamsPromo}
            isGetVariantRequest={isGetVariantRequest}
            toggleIsActive={toggleIsActive}
            setEditVariant={setEditVariant}
            isVariantAvailable={isVariantAvailable}
            searchString={searchString}
            getVariants={getVariants}
            getPromos={getPromos}
            setFieldValue={setFieldValue}
            setAvailabilityModalState={setAvailabilityModalState}
            availabilityModalState={availabilityModalState}
            setSelectedPromoCode={setSelectedPromoCode}
          />
        ) : offerPromoCode.length === 0 && !isGetVariantRequest ? (
          <MessageWrapper>No promo code found.</MessageWrapper>
        ) : (
          <LoadingBlueLarge />
        )}
      </Wrapper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  requestParamsPromo: state.OfferEngineReducer.requestParamsPromo,
  isGetVariantRequest: state.OfferEngineReducer.isGetVariantRequest,
  //isVariantAvailable: state.OfferEngineReducer.isVariantAvailable,
});
export default connect(mapStateToProps, actions)(PromoListContainer);
