import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonRow } from 'merchant-portal-components';
import * as actions from './actions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import CreateCouponContainer from './CreateCoupon/CreateCouponContainer';
import { CouponPromoIndex } from '../CouponPromo/components/CouponPromoIndex';
import { Icon } from 'merchant-portal-components';
import { SmallToggleSwitch } from '../../../components/ToggleSwitch/ToggleSwitch';
import { sort } from '../../../utils/sort/sort';

class Coupon extends Component {
  state = {
    couponItemToDelete: null,
    searchString: '',
    allCouponsLength: '',
  };

  componentDidMount() {
    track.page('engagement-coupons-list');
    window.scrollTo(0, 0);
    this.props.updateCouponsRequestParams({
      ...this.props.requestParams,
    });
    this.props.fetchCoupons();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.token && this.props.token) {
      this.props.fetchCoupons();
    }
    if (
      prevProps.coupons.response !== this.props.coupons.response &&
      this.props.coupons.response
    ) {
      this.setState({
        allCouponsLength: this.props.coupons.response.data.length,
      });
    }
    if (
      prevState.searchString !== this.state.searchString &&
      this.props.coupons.response
    ) {
      if (this.props.coupons.response.data?.length > 0) {
        const couponsDataLength = this.props.coupons.response.data?.filter(
          (coupon) =>
            coupon.coupon_details?.label
              ?.toLowerCase()
              .indexOf(this.state.searchString.toLowerCase()) > -1
        ).length;
        this.setState({ allCouponsLength: couponsDataLength });
      }
    }
    if (this.state.couponToDelete) {
      if (
        prevProps.coupons.isDeleting.includes(this.state.couponToDelete) &&
        !this.props.coupons.isDeleting.includes(this.state.couponToDelete)
      ) {
        this.setState({ couponToDelete: null });
      }
    }
    if (
      prevProps.coupons.requestingCreateCoupon &&
      !this.props.requestingCreateCoupon &&
      !this.props.coupons.createCoupon
    ) {
      this.props.fetchCoupons();
    }
    if (
      prevProps.coupons.requestingEditCoupon &&
      !this.props.requestingEditCoupon &&
      !this.props.coupons.editCoupon
    ) {
      this.props.fetchCoupons();
    }
  }

  initiateEditCoupon = (coupon) => {
    if (!this.props.coupons.isFetching) {
      this.props.initiateEditCoupon(coupon);
    }
  };

  toggleIsActiveCoupon = (coupon_id) => {
    if (!this.props.coupons.isFetching) {
      this.props.toggleIsActiveCoupon(coupon_id);
    }
  };

  initiateCreateCoupon = () => {
    this.props.initiateCreateCoupon();
  };

  handleCouponsSearch = (searchString) => {
    const newRequestParams = {
      ...this.props.requestParams,
      page_number: 1,
      page_size: 25,
      search_string: searchString,
    };
    if (newRequestParams.page_size === undefined) {
      newRequestParams.page_size === 25;
    }
    if (searchString.length > 2 || searchString === '') {
      this.props.updateCouponsRequestParams(newRequestParams);
      this.props.fetchCoupons(newRequestParams);
    }
    this.props.updateCouponsRequestParams(newRequestParams);
    this.setState({ searchString: searchString });
  };

  handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray =
      this.props.coupons.requestParams.sorting_option.split('-');
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (this.props.coupons.response.data) {
      const result = sort(this.props.coupons.response.data, {
        ...sortArgs,
        sortOrder: `${order}`,
      });
      this.props.updateCouponsRequestParams({
        page_number: 1,
        page_size: this.props.coupons.requestParams.page_size,
      });
      this.props.updateSortedCouponsList(result, sortOrder);
    }
  };
  handleClearSearch = () => {
    const newRequestParamss = {
      ...this.props.requestParams,
      page_number: 1,
      page_size: 25,
      search_string: '',
    };
    this.props.updateCouponsRequestParams(newRequestParamss);
    this.setState({ searchString: '' });
    this.props.fetchCoupons(newRequestParamss);
  };

  allCouponsLength = () => {
    let couponLength = this.props.coupons?.filter(
      (coupons) =>
        coupons.coupon_details.label
          .toLowerCase()
          .indexOf(this.state.searchString.toLowerCase()) > -1
    ).length;
    return couponLength;
  };
  render() {
    const { requestParams } = this.props.coupons;

    const { coupons } = this.props;
    if (this.props.coupons.editCoupon) {
      return (
        <CreateCouponContainer edit allSegments={this.props.allSegments} />
      );
    }
    if (this.props.coupons.createCoupon) {
      return <CreateCouponContainer allSegments={this.props.allSegments} />;
    }
    return (
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr' }}>
          <BreadCrumb pageName='Credit Codes' />
        </div>
        <CouponPromoIndex
          {...this.props}
          requestParams={requestParams}
          coupons={coupons}
          initiateCreateCoupon={this.initiateCreateCoupon}
          fetchCoupons={this.props.fetchCoupons}
          getCoupons={this.props.fetchCoupons}
          searchString={this.state.searchString}
          handleCouponsSearch={this.handleCouponsSearch}
          toggleIsActiveCoupon={this.toggleIsActiveCoupon}
          handleSort={this.handleSort}
          allCouponsLength={this.state.allCouponsLength}
          handleClearSearch={this.handleClearSearch}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    coupons: state.coupons,
    token: state.accountReducer.token,
    allSegments: state.segments.availableSegments,
  };
}

export default connect(mapStateToProps, actions)(Coupon);
