import React, { Component } from 'react';
import styled from 'styled-components';
import * as actions from './actions';
import { connect } from 'react-redux';
import { Charts } from '../components/DashboardElements';
import { Button, LoadingBlueLarge } from 'merchant-portal-components';
import Transactions from './components/Transactions';
import Sales from './components/Sales';
import NewUserTransactions from './components/NewUserTransactions';
import TransactionsVsTotal from './components/TransactionsVsTotal';
import intervalOptions from '../utils/intervalOptions';
import ReactGA from 'react-ga';
import DashboardDateSelection from '../components/DashboardDateSelection';
import moment from 'moment';

const PageTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
`;

const DateSelectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 380px 1fr auto;
  grid-gap: 20px;
  align-items: flex-end;
`;
const DateWrapper = styled.div`
  max-width: 380px;
  grid-column: 1;
`;
const ButtonWrapper = styled.div`
  grid-column: 3;
  margin-bottom: 5px;
`;
const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
`;
const MessageWrapper = styled.div`
  font-size: 28px;
  font-weight: 600;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class TopLocationsDashboard extends Component {
  state = {
    intervalTypeAndValue: intervalOptions[1],
    role: localStorage.getItem('role'),
    timePeriodType: 'fixed-period',
    startDate: null,
    endDate: null,
    hasError: false,
    message: '',
  };

  componentDidMount() {
    this.getAllTopLocationData();
    track.page('dashboard-top-locations');
    ReactGA.pageview(
      window.location.pathname,
      undefined,
      'Top Locations Summary'
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.timePeriodType !== this.state.timePeriodType) {
      this.props.resetTopLocationDashboardData();
      if (this.state.timePeriodType === 'custom-date') {
        this.setState({
          message: 'Select the date range to view dashboard reports',
        });
      } else {
        this.setState({
          message: 'Select the interval to view dashboard reports',
        });
      }
    }

    if (
      prevState.timePeriodType !== this.state.timePeriodType ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.intervalTypeAndValue !== this.state.intervalTypeAndValue
    ) {
      if (this.state.timePeriodType === 'custom-date') {
        if (this.state.startDate && this.state.endDate) {
          this.setState({ hasError: false });
        } else {
          this.setState({ hasError: true });
        }
      }
      if (this.state.timePeriodType === 'fixed-period') {
        this.getAllTopLocationData();
      }
    }
  }

  getAllTopLocationData = () => {
    const { intervalTypeAndValue, startDate, endDate } = this.state;
    const payload =
      startDate && endDate
        ? {
            start_datetime: moment(startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DD HH:mm:ss'),
            end_datetime: moment(endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format('YYYY-MM-DD HH:mm:ss'),
          }
        : intervalTypeAndValue?.value;
    this.props.getTopLocationTransactions(payload);
    this.props.getTopLocationSales(payload);
    this.props.getTopLocationRefund(payload);
    this.props.getTopLocationNewUserTransactions(payload);
    this.setState({ hasError: false, message: '' });
  };

  handleTimeIntervalChange = (value) => {
    this.setState({ intervalTypeAndValue: value });
  };

  handleDateError = (error) => {
    if (error || !(this.state.startDate && this.state.endDate)) {
      this.setState({ hasError: true });
    } else {
      this.setState({ hasError: false });
    }
  };
  render() {
    const {
      transactionsData,
      salesData,
      refundData,
      newUserTransactionsData,
      loading,
    } = this.props.topLocationsDashboard;
    const { role, startDate, endDate, hasError, message, timePeriodType } =
      this.state;
    return (
      <div>
        <PageTitle>Top Locations</PageTitle>
        <DateSelectionWrapper>
          <DateWrapper>
            <DashboardDateSelection
              timePeriodType={this.state.timePeriodType}
              setTimePeriodType={(newValue) =>
                this.setState({ timePeriodType: newValue })
              }
              startDate={startDate}
              endDate={endDate}
              setStartDate={(date) => this.setState({ startDate: date })}
              setEndDate={(date) => this.setState({ endDate: date })}
              intervalTypeAndValue={this.state.intervalTypeAndValue}
              setIntervalTypeAndValue={(newIntervalTypeAndValue) =>
                this.setState({ intervalTypeAndValue: newIntervalTypeAndValue })
              }
              handleDateError={this.handleDateError}
            />
          </DateWrapper>
          {timePeriodType !== 'fixed-period' && (
            <ButtonWrapper>
              <Button onClick={this.getAllTopLocationData} disabled={hasError}>
                View Stats
              </Button>
            </ButtonWrapper>
          )}
        </DateSelectionWrapper>
        {message ? (
          <MessageWrapper>{message}</MessageWrapper>
        ) : (
          <>
            {transactionsData && loading ? (
              <div>
                <Charts>
                  <ChartsWrapper>
                    {transactionsData && (
                      <Transactions data={transactionsData} />
                    )}
                    {salesData && <Sales data={salesData} />}
                  </ChartsWrapper>
                </Charts>
                <Charts>
                  <ChartsWrapper>
                    {role !== 'ROLE_LOYALTY_ADMIN' && refundData && (
                      <TransactionsVsTotal data={refundData} />
                    )}

                    {newUserTransactionsData && (
                      <NewUserTransactions data={newUserTransactionsData} />
                    )}
                  </ChartsWrapper>
                </Charts>
              </div>
            ) : (
              <LoadingBlueLarge />
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    topLocationsDashboard: state.topLocationsDashboard,
    token: state.accountReducer.token,
  };
}

export default connect(mapStateToProps, actions)(TopLocationsDashboard);
