export const firebaseProductionConfigs = {
  basilbox_catering: {
    apiKey: 'AIzaSyCojxPCDH0av-QAoyaNKT7JZdLfi_xU4mQ',
    authDomain: 'prod-basilboxcatering.firebaseapp.com',
    databaseURL: 'https://prod-basilboxcatering-tracking.firebaseio.com/',
    projectId: 'prod-basilboxcatering',
    storageBucket: 'prod-basilboxcatering.appspot.com',
    messagingSenderId: '765866299489',
    appId: '1:765866299489:web:e1a01235981b0208071a20',
    measurementId: 'G-FWJMP1CC0B',
  },
  onekingwest_amenities: {
    apiKey: 'AIzaSyCuMPrOJ9FzCrSxxs0Y_2w3BAdqIjGVAQ0',
    authDomain: 'one-king-west-amenities.firebaseapp.com',
    databaseURL: 'https://one-king-west-amenities-tracking.firebaseio.com',
    projectId: 'one-king-west-amenities',
    storageBucket: 'one-king-west-amenities.appspot.com',
    messagingSenderId: '342237379135',
    appId: '1:342237379135:web:f7a7915559279fa355d68a',
    measurementId: 'G-K9B17FTXHK',
  },
};

export const firebaseSandboxConfigs = {
  basilbox_catering: {
    apiKey: 'AIzaSyC8znDoRlbeSXMFfwLS2HnZSNDzO89VCY8',
    authDomain: 'sandbox-env-2.firebaseapp.com',
    databaseURL: 'https://sandbox-env-2-tracking.firebaseio.com',
    projectId: 'sandbox-env-2',
    storageBucket: 'sandbox-env-2.appspot.com',
    messagingSenderId: '12880788970',
    appId: '1:12880788970:web:f5312b6a1d0676bc008693',
    measurementId: 'G-TYKD0JTQTS',
  },
  onekingwest_amenities: {
    apiKey: 'AIzaSyC8znDoRlbeSXMFfwLS2HnZSNDzO89VCY8',
    authDomain: 'sandbox-env-2.firebaseapp.com',
    databaseURL: 'https://sandbox-env-2-tracking.firebaseio.com',
    projectId: 'sandbox-env-2',
    storageBucket: 'sandbox-env-2.appspot.com',
    messagingSenderId: '12880788970',
    appId: '1:12880788970:web:f5312b6a1d0676bc008693',
    measurementId: 'G-TYKD0JTQTS',
  },
};

export const firebaseStagingConfigs = {
  basilbox_catering: {
    apiKey: 'AIzaSyCAdMaNffvBJZz6is15Yk9fnsXfblO1G8c',
    authDomain: 'staging-env-2.firebaseapp.com',
    databaseURL: 'https://staging-env-2-tracking.firebaseio.com',
    projectId: 'staging-env-2',
    storageBucket: 'staging-env-2.appspot.com',
    messagingSenderId: '1043032324683',
    appId: '1:1043032324683:web:527a86fc8221bc8f8a6bce',
    measurementId: 'G-R6HD0RG7JS',
  },
  onekingwest_amenities: {
    apiKey: 'AIzaSyCAdMaNffvBJZz6is15Yk9fnsXfblO1G8c',
    authDomain: 'staging-env-2.firebaseapp.com',
    databaseURL: 'https://staging-env-2-tracking.firebaseio.com',
    projectId: 'staging-env-2',
    storageBucket: 'staging-env-2.appspot.com',
    messagingSenderId: '1043032324683',
    appId: '1:1043032324683:web:527a86fc8221bc8f8a6bce',
    measurementId: 'G-R6HD0RG7JS',
  },
};
export const firebaseDevelopmentConfigs = {
  basilbox_catering: {
    apiKey: 'AIzaSyBlHQkKhaYL8k9EJMnk0y0_SCAqhTXdPOA',
    authDomain: 'develop-orders.firebaseapp.com',
    databaseURL: 'https://develop-orders-a6c93.firebaseio.com',
    projectId: 'develop-orders',
    storageBucket: 'develop-orders.appspot.com',
    messagingSenderId: '128462065576',
    appId: '1:128462065576:web:f1955dacfda39eef7e179d',
    measurementId: 'G-1TKR1H5360',
  },
  onekingwest_amenities: {
    apiKey: 'AIzaSyBlHQkKhaYL8k9EJMnk0y0_SCAqhTXdPOA',
    authDomain: 'develop-orders.firebaseapp.com',
    databaseURL: 'https://develop-orders-a6c93.firebaseio.com',
    projectId: 'develop-orders',
    storageBucket: 'develop-orders.appspot.com',
    messagingSenderId: '128462065576',
    appId: '1:128462065576:web:f1955dacfda39eef7e179d',
    measurementId: 'G-1TKR1H5360',
  },
};
