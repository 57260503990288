import React, { useState } from 'react';
import { Field } from 'formik';
import {
  RenderTextField,
  required,
} from '../../../../../../components/FormElements/FormikElements';
import {
  AddProductsButton,
  ComboItemDiv,
  ItemNameField,
  ShowComboItemsText,
  IconEllipsis,
  RemoveComboGroupName,
  ItemRequiredWrapper,
  ComboItemsWrapper,
  ItemMoveWrapper,
  ItemContentWrapper,
  ButtonLikeWrapper,
} from '../styles/style';
import AddProductsModal from './AddProductsModal';
import ComboOverridesModal from './ComboOverridesModal';
import SingleComboProductsDiv from './SingleComboProductsDiv';
import { Button, Icon } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';
import { Switch } from '@material-ui/core';
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import styled from 'styled-components';
import { TooltipIcon } from '../../../../../../components/Icon';
import { createAlert } from '../../../../../Alert/actions';

const SortDiv = styled.div`
  padding: 0 40px 25px;
  text-align: right;
  position: absolute;
    right: 0;
    top: -3px;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  color:rgb(36, 64, 174);
`;


// const DragHandle = sortableHandle(() => (
//   <span>
//      <div>
//     {showReorderIcon && (
//         <span style={{ cursor: 'pointer', position: 'absolute', right: '105px' }}>
//         <i className="fas fa-arrows-alt"></i>
//        </span>
//       )}
//      </div>
//    </span>
// ));

// const SortablePrItem = SortableElement(({ value }) => <li> <DragHandle />{value}</li>);
const SortablePrContainer = SortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});


const SortableItem = SortableElement(
  ({
    isComboProdReordDisab,
    showLoader,
    productVariantsData,
    handleProdVarIndexChange,
    reorderProdItemsStart,
    setReorderProdItemsStart,
    showComboGroupSorter,
    index,
    itemIndex,
    comboItem,
    comboItems,
    arrayHelpers,
    setCombosOverrideModalState,
    toggleCombosOverridesModal,
    isCombosOverridesModal,
    combosOverrideModalState,
    toggleShowProductsState,
    showProductsState,
    beautifyProductVariantNames,
    toggleChargeExtraModal,
    removeProductVariant,
    toggleProductModal,
    isChargeExtraModal,
    isProductModal,
    chargeExtraModalState,
    setChargeExtraModalState,
    initValues,
    setFieldValue,
    handleReset,
    values,
    productsModalState,
    setProductsModalState,
    getProductsCategories,
    productsCategories,
    getProductsVariants,
    productsVariants,
    isRequestingGetProductsVariants,
    allProducts,
    getAllProducts,
    getCombosOptionsOverrides,
    combosOptionsOverride,
    isRequestingGetCombosOptionsOverrides,
    options,
    initialValues,
    isComboItemsSubmitSuccess,
    isRequestingGetAllProducts,
    RenderTextField,
  }) => {
    const [showReorderIcon, setShowReorderIcon] = useState(false); 

    const DragHandle = sortableHandle(() => (
      <span>
         <div>
        {showReorderIcon && (
            <span style={{ cursor: 'pointer', position: 'absolute', right: '105px' }}>
            <i className="fas fa-arrows-alt"></i>
           </span>
          )}
         </div>
       </span>
    ));
    
    const SortablePrItem = SortableElement(({ value }) => <li> <DragHandle />{value}</li>);
return(
    <ComboItemsWrapper key={`comboItems_${itemIndex}`}>
      {isComboProdReordDisab  ?
        <ItemMoveWrapper>
          <Icon
            className='far fa-equals'
            style={{ cursor: 'move', textAlign: 'center' }}
          />
        </ItemMoveWrapper> :
        <div>

        </div>
      }
      <ItemContentWrapper>
        <ItemNameField>
          <Field
            name={`comboItems[${itemIndex}].label`}
            component={RenderTextField}
            label='Combo Group Name'
            error={
              arrayHelpers?.form?.touched?.comboItems &&
                arrayHelpers?.form?.touched?.comboItems[itemIndex] &&
                arrayHelpers?.form?.touched?.comboItems[itemIndex]?.label &&
                !arrayHelpers?.form?.values?.comboItems[itemIndex]?.label
                ? 'Required'
                : null
            }
            validate={required}
          />
          <div>
            <Tooltip title='Edit Customization Rules'>
              <span>
                <IconEllipsis
                  className='fas fa-ellipsis-v'
                  style={{ fontSize: '20px' }}
                  onClick={() => {
                    setCombosOverrideModalState({
                      comboItemId: comboItem.id,
                    });
                    toggleCombosOverridesModal();
                  }}
                  disabled={!comboItem.productVariants.length}
                />
              </span>
            </Tooltip>

            {isCombosOverridesModal &&
              combosOverrideModalState.comboItemId === comboItem.id && (
                <ComboOverridesModal
                  toggleCombosOverridesModal={toggleCombosOverridesModal}
                  comboItem={comboItem}
                  getCombosOptionsOverrides={getCombosOptionsOverrides}
                  combosOptionsOverride={combosOptionsOverride}
                  isRequestingGetCombosOptionsOverrides={
                    isRequestingGetCombosOptionsOverrides
                  }
                  options={options}
                  comboItemIndex={itemIndex}
                  comboItems={comboItems}
                  handleReset={handleReset}
                  initialValues={initialValues}
                  initValues={initValues}
                  setFieldValue={setFieldValue}
                  comboItemFormik={
                    arrayHelpers?.form?.values.comboItems[itemIndex]
                  }
                  arrayHelpers={arrayHelpers}
                />
              )}
          </div>
          <RemoveComboGroupName
            onClick={() => {
              if (comboItem.id) {
                setFieldValue(`comboItems[${itemIndex}].is_active`, false);
              } else {
                arrayHelpers.remove(itemIndex);
              }
            }}
          >
            <i className='fal fa-trash-alt'></i>
          </RemoveComboGroupName>
        </ItemNameField>
        <ItemRequiredWrapper>
          <span>Combo Group is Required</span>
          <Switch
            checked={comboItem.is_required}
            onChange={() =>
              setFieldValue(
                `comboItems[${itemIndex}].is_required`,
                !comboItem.is_required
              )
            }
            color='primary'
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ItemRequiredWrapper>
        <div>
          {!comboItem.productVariants.length ? (
            <p
              style={{
                fontStyle: 'italic',
                marginBottom: '20px',
                fontSize: '14px',
              }}
            >
              There are no customization for this combo group
            </p>
          ) : (
            <div style={{ position: 'relative' }}>
              <ShowComboItemsText
                type='button'
                onClick={() => {
                  toggleShowProductsState(comboItem.id);
                }}
                style={{ marginBottom: '20px' }}
              >
                {showProductsState.includes(comboItem.id) ? 'Hide' : 'Show'}{' '}
                {comboItem.productVariants.length} customization(s) included
                <i
                  style={{ marginLeft: '15px' }}
                  className={`fas ${showProductsState.includes(comboItem.id)
                      ? 'fa-sort-up'
                      : 'fa-sort-down'
                    }`}
                ></i>
              </ShowComboItemsText>
              {
                showProductsState.includes(comboItem.id) &&
                <SortDiv disabled={!isComboProdReordDisab}>
                  <SortWrapper onClick={() => {
                    if (!isComboProdReordDisab) {
                      setReorderProdItemsStart(!reorderProdItemsStart)
                      
                    } else {
                      createAlert({
                        type: 'error',
                        message: 'Combo group reordering in progress , try after combo groups are reordered',
                      })
                      return;
                    }
                  }
                  }>
                    <>
                          <i className='far fa-sort-amount-down' />
                          <span onClick={()=>{
                            setShowReorderIcon((prev) => !prev);
                          }}>Reorder Combo Products</span>
                          <Tooltip
                            style={{ marginRight: 'auto' }}
                            title='Edit the position of combo products to change the order that they are presented in to the user'
                          >
                            <span>
                              <TooltipIcon marginLeft />
                            </span>
                          </Tooltip>
                        </>
                  </SortWrapper>
                </SortDiv>
              }
              <SortablePrContainer onSortEnd={({ oldIndex, newIndex }) => {
                handleProdVarIndexChange(oldIndex, newIndex);
                
              }} useDragHandle>
                {showProductsState.includes(comboItem.id) &&
                  productVariantsData.map((productVariant, productVariantIndex) => (
                    <SortablePrItem key={`item-${productVariant}-${productVariantIndex}`} index={productVariantIndex}
                      value={
                        <SingleComboProductsDiv
                          reorderProdItemsStart={reorderProdItemsStart}
                          key={`comboItem_${comboItems}_${productVariantIndex}`}
                          productVariant={productVariant}
                          productVariantIndex={productVariantIndex}
                          comboItem={comboItem}
                          index={itemIndex}
                          arrayHelpers={arrayHelpers}
                          beautifyProductVariantNames={beautifyProductVariantNames}
                          toggleChargeExtraModal={toggleChargeExtraModal}
                          removeProductVariant={removeProductVariant}
                          isChargeExtraModal={isChargeExtraModal}
                          chargeExtraModalState={chargeExtraModalState}
                          setChargeExtraModalState={setChargeExtraModalState}
                          initValues={initValues}
                          setFieldValue={setFieldValue}
                          handleReset={handleReset}
                          values={values}
                        />
                      }
                    />
                  )
                  )




                }
              </SortablePrContainer>
            </div>
          )}
          <AddProductsButton
            onClick={() => {
              setProductsModalState({
                comboItemId: comboItem.id,
                productVariants: comboItem.productVariants,
                itemLabel: comboItem.label,
              });
              toggleProductModal();
            }}
            disabled={
              !comboItem.id &&
              !isComboItemsSubmitSuccess &&
              !comboItem.productVariants.length
            }
          >
            + Add Products
            {!comboItem.id && !isComboItemsSubmitSuccess && (
              <Tooltip title='Combo Group should be saved before adding products'>
                <Icon
                  className='fal fa-info-circle'
                  style={{
                    cursor: 'inherit',
                    marginLeft: '10px',
                    fontSize: '16px',
                  }}
                />
              </Tooltip>
            )}
          </AddProductsButton>

          {isProductModal &&
            productsModalState.comboItemId === comboItem.id && (
              <AddProductsModal
                toggleProductModal={toggleProductModal}
                productsModalState={productsModalState}
                getProductsCategories={getProductsCategories}
                productsCategories={productsCategories}
                getProductsVariants={getProductsVariants}
                productsVariants={productsVariants}
                isRequestingGetProductsVariants={
                  isRequestingGetProductsVariants
                }
                comboItem={comboItem}
                allProducts={allProducts}
                getAllProducts={getAllProducts}
                index={itemIndex}
                setFieldValue={setFieldValue}
                arrayHelpers={arrayHelpers}
                isRequestingGetAllProducts={isRequestingGetAllProducts}
              />
            )}
        </div>
      </ItemContentWrapper>
    </ComboItemsWrapper>
  )
}
);
const SortableList = SortableContainer(
  ({
    isComboProdReordDisab,
    showLoader,
    showComboGroupSorter,
    comboItems,
    arrayHelpers,
    setCombosOverrideModalState,
    toggleCombosOverridesModal,
    isCombosOverridesModal,
    combosOverrideModalState,
    toggleShowProductsState,
    showProductsState,
    beautifyProductVariantNames,
    toggleChargeExtraModal,
    removeProductVariant,
    toggleProductModal,
    isChargeExtraModal,
    isProductModal,
    chargeExtraModalState,
    setChargeExtraModalState,
    initValues,
    setFieldValue,
    handleReset,
    values,
    productsModalState,
    setProductsModalState,
    getProductsCategories,
    productsCategories,
    getProductsVariants,
    productsVariants,
    isRequestingGetProductsVariants,
    allProducts,
    getAllProducts,
    getCombosOptionsOverrides,
    combosOptionsOverride,
    isRequestingGetCombosOptionsOverrides,
    options,
    initialValues,
    isComboItemsSubmitSuccess,
    isRequestingGetAllProducts,
    RenderTextField,
    triggerParent
  }) => {
    const [reorderProdItemsStart, setReorderProdItemsStart] = useState(false);
    return (
      <ComboItemDiv>
        {comboItems.map((comboItem, index) => {
          let prodVarsInfo = [...comboItem.productVariants];
          const handleProdVarIndexChange = (oldIndex, newIndex) => {
            const newProdVarInfo = arrayMove(comboItem.productVariants, oldIndex, newIndex)
            prodVarsInfo = newProdVarInfo;
            const formattedData = newProdVarInfo.map((each, index) => {
              return {
                product_id: each.productId,
                product_variant_id: each.variantId,
                position: index
              }
            })
            triggerParent(formattedData, comboItem)
          }
          return (
            comboItem.is_active && (
              <SortableItem
                isComboProdReordDisab={isComboProdReordDisab}
                showLoader={showLoader}
                handleProdVarIndexChange={handleProdVarIndexChange}
                productVariantsData={prodVarsInfo}
                setReorderProdItemsStart={setReorderProdItemsStart}
                reorderProdItemsStart={reorderProdItemsStart}
                showComboGroupSorter={showComboGroupSorter}
                key={`item-${index}`}
                index={index}
                itemIndex={index}
                comboItems={comboItems}
                comboItem={comboItem}
                arrayHelpers={arrayHelpers}
                combosOverrideModalState={combosOverrideModalState}
                isCombosOverridesModal={isCombosOverridesModal}
                setCombosOverrideModalState={setCombosOverrideModalState}
                toggleShowProductsState={toggleShowProductsState}
                toggleCombosOverridesModal={toggleCombosOverridesModal}
                showProductsState={showProductsState}
                beautifyProductVariantNames={beautifyProductVariantNames}
                toggleChargeExtraModal={toggleChargeExtraModal}
                removeProductVariant={removeProductVariant}
                toggleProductModal={toggleProductModal}
                isChargeExtraModal={isChargeExtraModal}
                isProductModal={isProductModal}
                chargeExtraModalState={chargeExtraModalState}
                setChargeExtraModalState={setChargeExtraModalState}
                initValues={initValues}
                setFieldValue={setFieldValue}
                handleReset={handleReset}
                values={values}
                productsModalState={productsModalState}
                setProductsModalState={setProductsModalState}
                getProductsCategories={getProductsCategories}
                productsCategories={productsCategories}
                getProductsVariants={getProductsVariants}
                productsVariants={productsVariants}
                isRequestingGetProductsVariants={
                  isRequestingGetProductsVariants
                }
                allProducts={allProducts}
                getAllProducts={getAllProducts}
                getCombosOptionsOverrides={getCombosOptionsOverrides}
                combosOptionsOverride={combosOptionsOverride}
                isRequestingGetCombosOptionsOverrides={
                  isRequestingGetCombosOptionsOverrides
                }
                options={options}
                initialValues={initialValues}
                isComboItemsSubmitSuccess={isComboItemsSubmitSuccess}
                isRequestingGetAllProducts={isRequestingGetAllProducts}
                RenderTextField={RenderTextField}
                lock
              />
            )
          );
        })}
      </ComboItemDiv>
    );
  }
);

const SingleComboItem = ({
  isComboProdReordDisab,
  showLoader,
  handleSortComboGroupsOnly,
  triggerParent,
  showComboGroupSorter,
  comboItems,
  arrayHelpers,
  setCombosOverrideModalState,
  toggleCombosOverridesModal,
  isCombosOverridesModal,
  combosOverrideModalState,
  toggleShowProductsState,
  showProductsState,
  beautifyProductVariantNames,
  toggleChargeExtraModal,
  removeProductVariant,
  toggleProductModal,
  isChargeExtraModal,
  isProductModal,
  chargeExtraModalState,
  setChargeExtraModalState,
  initValues,
  setFieldValue,
  handleReset,
  values,
  productsModalState,
  setProductsModalState,
  getProductsCategories,
  productsCategories,
  getProductsVariants,
  productsVariants,
  isRequestingGetProductsVariants,
  allProducts,
  getAllProducts,
  getCombosOptionsOverrides,
  combosOptionsOverride,
  isRequestingGetCombosOptionsOverrides,
  options,
  initialValues,
  isComboItemsSubmitSuccess,
  isRequestingGetAllProducts,
}) => {

  const activeComboItems = comboItems.filter(
    (comboItem) => comboItem.is_active
  );
  return (
    <div style={{ marginBottom: '20px' }}>
      {activeComboItems.length !== 0 ? (
        <SortableList
          isComboProdReordDisab={isComboProdReordDisab}
          showLoader={showLoader}
          triggerParent={triggerParent}
          showComboGroupSorter={showComboGroupSorter}
          comboItems={comboItems}
          arrayHelpers={arrayHelpers}
          combosOverrideModalState={combosOverrideModalState}
          isCombosOverridesModal={isCombosOverridesModal}
          setCombosOverrideModalState={setCombosOverrideModalState}
          toggleShowProductsState={toggleShowProductsState}
          toggleCombosOverridesModal={toggleCombosOverridesModal}
          showProductsState={showProductsState}
          beautifyProductVariantNames={beautifyProductVariantNames}
          toggleChargeExtraModal={toggleChargeExtraModal}
          removeProductVariant={removeProductVariant}
          toggleProductModal={toggleProductModal}
          isChargeExtraModal={isChargeExtraModal}
          isProductModal={isProductModal}
          chargeExtraModalState={chargeExtraModalState}
          setChargeExtraModalState={setChargeExtraModalState}
          initValues={initValues}
          setFieldValue={setFieldValue}
          handleReset={handleReset}
          values={values}
          productsModalState={productsModalState}
          setProductsModalState={setProductsModalState}
          getProductsCategories={getProductsCategories}
          productsCategories={productsCategories}
          getProductsVariants={getProductsVariants}
          productsVariants={productsVariants}
          isRequestingGetProductsVariants={isRequestingGetProductsVariants}
          allProducts={allProducts}
          getAllProducts={getAllProducts}
          getCombosOptionsOverrides={getCombosOptionsOverrides}
          combosOptionsOverride={combosOptionsOverride}
          isRequestingGetCombosOptionsOverrides={
            isRequestingGetCombosOptionsOverrides
          }
          options={options}
          initialValues={initialValues}
          isComboItemsSubmitSuccess={isComboItemsSubmitSuccess}
          isRequestingGetAllProducts={isRequestingGetAllProducts}
          onSortEnd={({ oldIndex, newIndex }) => {
            if (oldIndex !== newIndex) {
              const newList = arrayMove(comboItems, oldIndex, newIndex);
              setFieldValue(`comboItems`, newList);
              //handleSortComboGroupsOnly(initValues.id,newList)
            }
          }}
          RenderTextField={RenderTextField}
          lockAxis='y'
          pressDelay={200}
        />
      ) : (
        <div style={{ paddingBottom: '15px' }}>
          There are no Combo Items to show
        </div>
      )}
    </div>
  );
};

export default SingleComboItem;
