import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 10px 0;
`;
export const AddSpecialHoursButton = styled.span`
  color: #2440af;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: #2b49c2;
  }
`;
