import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BusinessWizardIndex from './components/BusinessWizardIndex';
import * as actions from '../actions';
import * as businessActions from '../../LocationListManager/actions';
import { getBusinessLocations } from '../../LocationListManager/actions';
import { connect } from 'react-redux';
import { resetDeliveryZoneReducerState } from './DeliveryZoneInformation/actions';

const Wrapper = styled.div`
  // width: 1000px;
  // margin: 0 auto;
`;

const BusinessWizardContainer = ({
  businessLocationDetailsReducer,
  is_delivery_enabled,
  copyBusinessMenu,
  resetDeliveryZoneReducerState,
  resetBusinessLocationDetailsReducerState,
  getBusinessLocationDetailsByBusinessId,
  isBusinessAdminRole,
  isLoyaltyAdminRole,
  getBusinessLocations,
  updateBusinessLocationsRequestParams,
}) => {
  const {
    isCreatingLocation,
    shouldComponentBeLoading,
    isEditingLocation,
    businessId,
    businessInformationInitialValues,
  } = businessLocationDetailsReducer;

  useEffect(() => {
    track.page('app-settings-locations-detail');
    if (isEditingLocation) getBusinessLocationDetailsByBusinessId(businessId);
  }, []);

  return (
    <Wrapper>
      <BusinessWizardIndex
        getBusinessLocations={getBusinessLocations}
        isCreatingLocation={isCreatingLocation}
        resetBusinessLocationDetailsReducerState={
          resetBusinessLocationDetailsReducerState
        }
        shouldComponentBeLoading={shouldComponentBeLoading}
        resetDeliveryZoneReducerState={resetDeliveryZoneReducerState}
        is_delivery_enabled={is_delivery_enabled}
        businessInformationInitialValues={businessInformationInitialValues}
        businessId={businessId}
        copyBusinessMenu={copyBusinessMenu}
        isBusinessAdminRole={isBusinessAdminRole}
        isLoyaltyAdminRole={isLoyaltyAdminRole}
        updateBusinessLocationsRequestParams={
          updateBusinessLocationsRequestParams
        }
      />
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    businessLocationDetailsReducer: state.businessLocationDetails,
    is_delivery_enabled:
      state.accountReducer.appMetaData.configuration.is_delivery_enabled,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  ...businessActions,
  getBusinessLocations,
  resetDeliveryZoneReducerState,
})(BusinessWizardContainer);
