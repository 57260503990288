import React from 'react';
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import TextField from '@material-ui/core/TextField';
import BreadCrumb from '../BreadCrumb/BreadCrumb';
import { Select } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';

export const TableWrapper = styled.div`
  background: ${(props) => props.theme.white};
  font-size: 12px;
  display: grid;
  grid-gap: 15px;
  padding: 0;
  border-radius: 12px;
  box-shadow: ${(props) =>
    props.noShadow ? 'none' : '0 2px 4px 0 rgba(0, 0, 0, 0.09)'};
  overflow: visible !important;
  overflow-x: scroll;
`;

export const MoreEllipsisIcon = styled(Icon)`
  font-size: 25px;
  opacity: 0.2;
  &:hover {
    opacity: 0.7;
  }
`;

const SelectContainer = styled.div`
  display: grid;
  font-size: 16px;
  justify-content: flex-start;
  grid-template: ${(props) => (props.style ? props.style.gridTemplate : null)};
  grid-gap: ${(props) => (props.style ? '20px' : null)};
`;

export const TitleArea = (props) => {
  return (
    <div
      style={{
        display: 'grid',
      }}
    >
      <BreadCrumb style={{ margin: '12px 0px' }} pageName={props.pageName} />
      <div
        style={{
          width: '100%',
        }}
      >
        <SelectContainer
          style={{
            gridTemplate: props.gridTemplate ? props.gridTemplate : 'none',
          }}
        >
          {!props.secondSelectName ? (
            <div
              style={{
                width: '230px',
                marginTop: '-6px',
                marginBottom: '14px',
              }}
            >
              <Select
                isMulti={props.multiEnabled}
                name={props.selectName}
                label={props.selectLabel}
                value={props.selectValue}
                onChange={props.onChange}
                options={props.options}
                light
                inLine
                getOptionLabel={props.getOptionLabel}
                getOptionValue={props.getOptionValue}
              />
            </div>
          ) : (
            <Select
              isMulti={props.multiEnabled}
              name={props.selectName}
              label={props.selectLabel}
              value={props.selectValue}
              onChange={props.onChange}
              options={props.options}
              light
              inLine
              getOptionLabel={props.getOptionLabel}
              getOptionValue={props.getOptionValue}
            />
          )}

          {props.secondSelectName && (
            <Select
              name={props.secondSelectName}
              label={props.secondSelectLabel}
              value={props.secondSelectValue}
              onChange={props.secondOnChange}
              options={props.secondOptions}
              light
              inLine
            />
          )}

          {props.thirdSelectName && (
            <Select
              name={props.thirdSelectName}
              label={props.thirdSelectLabel}
              value={props.thirdSelectValue}
              onChange={props.thirdOnChange}
              options={props.thirdOptions}
              light
              inLine
            />
          )}
        </SelectContainer>
      </div>
    </div>
  );
};

export const SearchInputBar = (props) => {
  return (
    <TextField
      // style={{width: '100%'}}
      value={props.value}
      InputProps={
        props.cancel
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fal fa-search" />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <Icon className="fal fa-times" />
                </InputAdornment>
              ),
            }
          : {
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fal fa-search" />
                </InputAdornment>
              ),
            }
      }
      style={{ width: '100%' }}
      variant="outlined"
      type="text"
      onChange={props.onChange}
      placeholder={props.placeholder ? props.placeholder : 'Enter search terms'}
      disabled={props.disabled}
    />
  );
};

const renderSortIcon = (value, sortingOption) => {
  const sortingOptionAsArray = sortingOption.split('-');
  const iconNames = { desc: 'down', asc: 'up' };
  if (sortingOptionAsArray[0] === value) {
    return (
      <Tooltip title="Sort" className={value}>
        <SortIcon
          className={`fa fa-sort-${iconNames[sortingOptionAsArray[1]]}`}
          aria-hidden="true"
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Sort" className={value}>
      <SortIcon className="fa fa-sort" aria-hidden="true" />
    </Tooltip>
  );
};

export const Th = ({ children, ...props }) => {
  return (
    <StyledTh {...props}>
      {children}
      {props.isSortable && renderSortIcon(props.value, props.sortingOption)}
    </StyledTh>
  );
};

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-radius: 12px;
  border: ${(props) => (props.noBorder ? null : props.theme.border)};
  @media (max-width: 1024px) {
    font-size: 12px;
  }
  @media print {
    font-size: 10px;
  }
`;

export const Tr = styled.tr`
  line-height: 32px;
  background-color: ${(props) => props.theme.white};
  &:hover {
    background-color: ${(props) => props.theme.paleGray};
    transition: ${(props) =>
      !props.sortDisabled ? 'all 0.3s cubic-bezier(.25,.8,.25,1)' : 'none'};
  }
`;

export const TrHead = styled.tr`
  border-radius: 12px;
  color: ${(props) => props.theme.darkBlue};
  background: ${(props) => props.theme.white};
`;

export const TBody = styled.tbody`
  border-radius: 10px;

  /* tr:nth-child(even) {
    background-color: ${(props) =>
    props.striped ? props.theme.lightGray : 'white'};
  } */
`;

const StyledTh = styled.th`
  /* border-right: ${(props) => props.theme.border}; */
  text-align: ${(props) => props.align};
  padding: 10px 30px 10px 20px;
  font-weight: 600;
  font-size: 18px;
  cursor: ${(props) => (props.isSortable ? 'pointer' : 'cursor')};

  @media print {
    padding: 5px;
    font-size: 12px;
  }
`;

export const StickyTh = styled.th`
  text-align: ${(props) => props.align};
  padding: 10px 30px 10px 20px;
  font-weight: 600;
  font-size: 18px;
  position: -webkit-sticky;
  position: sticky;
  left: -1px;
  z-index: 2;
  //border-left: ${(props) => props.theme.border};
  border-left: ${(props) => (props.noBorderLeft ? 'none' : props.theme.border)};
  background-color: ${(props) => props.theme.white};
  @media print {
    padding: 5px;
    font-size: 12px;
  }
`;

export const Td = styled.td`
  padding: ${(props) => (props.padding ? props.padding : '5px 20px')};
  border-top: ${(props) => props.theme.border};
  /* border-right: ${(props) => props.theme.border}; */
  text-align: ${(props) => props.align};
  font-weight: ${(props) => (props.bold ? '700' : 'normal')};
  /* border-left: ${(props) =>
    props.borderLeft ? props.theme.border : 'none'}; */
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : 'initial'};
  font-size: 14px;
  background-color: inherit;
  @media print {
    padding: 5px;
    font-size: 12px;
  }
`;

export const StickyTd = styled(Td)`
  position: -webkit-sticky;
  position: sticky;
  left: -1px;
  background-color: inherit;
  z-index: 2;
  border-left: ${(props) => (props.noBorderLeft ? 'none' : props.theme.border)};
  //border-left: ${(props) => props.theme.border};

  /* &:hover {
    background-color: ${(props) => props.theme.paleGray};
    transition: ${(props) =>
    !props.sortDisabled ? 'all 0.3s cubic-bezier(.25,.8,.25,1)' : 'none'};
  } */
`;

export const TdFlex = Td.extend`
  display: flex;
  justify-content: space-around;
  border-bottom: none !important;
  border-top: none !important;
  border-right: none !important;
`;

export const SortIcon = styled.i`
  margin-left: 6px;
  cursor: pointer;
  display: inline !important;
`;

export const EmptyStateMessage = styled.p`
  margin-top: 60px;
  text-align: center;
  color: ${(props) => props.theme.gray};
`;

export const TableWithButtonRow = styled.div`
  display: grid;
  grid-gap: 15px;
`;
