import firebase, { app } from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyCAdMaNffvBJZz6is15Yk9fnsXfblO1G8c',
  authDomain: 'staging-env-2.firebaseapp.com',
  databaseURL: 'https://staging-env-2-default-rtdb.firebaseio.com',
  projectId: 'staging-env-2',
  storageBucket: 'staging-env-2.appspot.com',
  messagingSenderId: '1043032324683',
  appId: '1:1043032324683:web:527a86fc8221bc8f8a6bce',
  measurementId: 'G-R6HD0RG7JS',
};

const environment = process.env.NODE_ENV;

class Firebase {
  constructor() {
    var app = firebase.initializeApp(firebaseConfig);
    const url =
      environment === 'production' ? '/production-version' : '/staging-version';

    this.appVersion = null;

    const dataRef = app.database().ref(url);

    this.onAppVersionChange = (func) => {
      dataRef.on('value', (snapshot) => {
        if (!this.appVersion) {
          this.appVersion = snapshot.val();
        } else if (this.appVersion !== snapshot.val()) {
          this.appVersion = snapshot.val();
          func && func();
        }
      });
    };
  }
}

export default Firebase;
