import {
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST,
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE,
  IS_DELETE_SPECIAL_HOURS_CONFIRM_MODAL_OPEN,
  SPECIAL_HOURS_TO_DELETE,
  DELETE_SPECIAL_HOURS_REQUEST,
  DELETE_SPECIAL_HOURS_SUCCESS,
  IS_CREATE_AND_EDIT_SPECIAL_HOURS_MODAL_OPEN,
  SPECIAL_HOURS_TO_EDIT,
  UPDATE_SPECIAL_HOURS_REQUEST,
  UPDATE_SPECIAL_HOURS_SUCCESS,
  RESET_BUSINESS_LOCATION_DETAILS_REDUCER_STATE,
  SET_BUSINESS_WIZARD_IS_VISIBLE,
  CREATE_BUSINESS_LOCATION_REQUEST,
  CREATE_BUSINESS_SUCCESS,
  POST_BUSINESS_ADDRESS_AND_DETAILS_SUCCESS,
  PUT_REGULAR_HOURS_SUCCESS,
  INITIATE_CREATE_LOCATION,
  INITIATE_EDIT_LOCATION,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_SPECIAL_HOURS_FAILURE,
  PUT_REGULAR_HOURS_FAILURE,
  GET_PAYMENT_INTEGRATION_SCHEMA_SUCESS,
  GET_PAYMENT_INTEGRATION_SUCESS,
  UPDATE_PAYMENT_INTEGRATION_SUCESS,
  ARCHIVE_BUSINESS_SUCCESS,
  ARCHIVE_BUSINESS_FAILURE,
  GET_ARCHIVED_BUSINESSES_SUCCESS,
  GET_ARCHIVED_BUSINESSES_FAILURE,
  UNARCHIVE_BUSINESS_SUCCESS,
  UNARCHIVE_BUSINESS_FAILURE,
} from './types';
import {
  getArchivedBusinesses,
  getBusinessLocationsOfMerchant,
} from '../LocationListManager/actions';
import api from '../../../api';
import { createAlert } from '../../Alert/actions';
import Moment from 'moment';
import { getDayOfWeek } from './utils/actionsFormatters';
import {
  formatValuesForPatch,
  formatPostSpecialHour,
  formatPatchSpecialHour,
  formatRegularHours,
  formatPutRegularHours,
  constructBusinessLocationFormat,
} from './utils/actionsFormatters';
import { formatErrorMessage } from '../../../utils/formatErrorMessage';

import { getPurePayload, getSearchUrl } from '../../../utils/purePayload';
/*
 * API HANDLERS
 */

// Archive Business
export function archiveBusiness(businessIds, callback) {
  return function (dispatch) {
    return api
      .post('/businesses/archive', { business_ids: businessIds })
      .then((response) => {
        dispatch({
          type: ARCHIVE_BUSINESS_SUCCESS,
          businessIds,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Location archived successfully.',
          })
        );
        if (callback) callback();
      })
      .catch((error) => {
        dispatch({
          type: ARCHIVE_BUSINESS_FAILURE,
          error,
        });
        dispatch(
          createAlert({
            type: 'error',
            message: formatErrorMessage(error) || 'Failed to archive location.',
          })
        );
        if (callback) callback(error);
      });
  };
}

// Unarchive Business
export function unArchiveBusiness(businessIds, callback) {
  return function (dispatch) {
    return api
      .post('/businesses/unarchive', { business_ids: businessIds })
      .then((response) => {
        dispatch({
          type: UNARCHIVE_BUSINESS_SUCCESS,
          businessIds,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business unarchived successfully.',
          })
        );
        dispatch(getArchivedBusinesses());
        if (callback) callback();
      })
      .catch((error) => {
        dispatch({
          type: UNARCHIVE_BUSINESS_FAILURE,
          error,
        });
        dispatch(
          createAlert({
            type: 'error',
            message:
              formatErrorMessage(error) || 'Failed to unarchive business.',
          })
        );
        if (callback) callback(error);
      });
  };
}

export function getBusinessLocationDetailsByBusinessId(businessLocationId) {
  let formattedResponse;

  return function (dispatch) {
    dispatch({ type: GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST });
    return getBusinessLocationToggles(businessLocationId)
      .then((response) => {
        formattedResponse = constructBusinessLocationFormat(response.data.data);

        Promise.all([
          getBusinessLocationAddress(businessLocationId),
          getBusinessLocationDetails(businessLocationId),
        ])
          .then((addressResponse) => {
            formattedResponse = formattedResponse(
              addressResponse[0].data.data,
              addressResponse[1].data.data
            );
            Promise.all([
              getBusinessLocationRegularHours(businessLocationId),
              getBusinessLocationSpecialHours(businessLocationId),
            ]).then((hoursResponse) => {
              formattedResponse = formattedResponse(hoursResponse[0].data.data)(
                hoursResponse[1].data.data
              );
              dispatch({
                type: 'GET_BUSINESS_REGULAR_SPECIAL_HOURS',
                regularHours: hoursResponse[0].data.data,
                specialHours: hoursResponse[1].data.data,
              });
              dispatch({
                type: GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
                payload: formattedResponse,
              });
            });
          })
          .catch((error) => {
            formattedResponse = formattedResponse(null, null)(null)(null);
            dispatch({
              type: GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
              payload: formattedResponse,
            });
            // dispatch({
            //   type: INITIATE_CREATE_LOCATION,
            //   data: formattedResponse,
            // });
          });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error,
          })
        );
        console.log(error);
      });
  };
}

/// **************************************************************************
const formatSpecialHours = (specialHours) => {
  let formattedSpecialHours = [];
  specialHours.forEach((specialHour) => {
    let startEndDate = {
      startDate: specialHour.start_date,
      endDate: specialHour.end_date,
    };

    if (
      specialHour.order_start_time === '' ||
      specialHour.order_end_time === '' ||
      specialHour.order_start_time === '00:00:00' ||
      specialHour.order_end_time === '00:00:00'
    ) {
      specialHour = {
        ...specialHour,
        order_start_time: '',
        order_end_time: '',
      };
    }

    let found = false;
    formattedSpecialHours.forEach((formattedSpecialHour) => {
      if (
        formattedSpecialHour.startEndDate.startDate == startEndDate.startDate &&
        formattedSpecialHour.startEndDate.endDate == startEndDate.endDate
      ) {
        found = true;
        formattedSpecialHour.hours.push(specialHour);
      }
    });
    if (!found) {
      formattedSpecialHours.push({
        startEndDate: startEndDate,
        hours: [specialHour],
      });
    } else {
    }
  });

  return formattedSpecialHours;
};
export function getRegularHours(businessLocationId) {
  return function (dispatch) {
    return api
      .get(`businesses/${businessLocationId}/hours`)
      .then((hoursResponse) => {
        dispatch({
          type: 'GET_REGULAR_HOURS',
          copiedRegularHours: hoursResponse.data.data,
        });
      });
  };
}
export function getSpecialHours(businessLocationId, isForCopy) {
  return function (dispatch) {
    return api
      .get(`businesses/${businessLocationId}/hours-overrides`)
      .then((hoursResponse) => {
        let formatedSpecialHours = formatSpecialHours(hoursResponse.data.data);
        if (isForCopy) {
          dispatch({
            type: 'GET_SPECIAL_HOURS',
            copiedSpecialHours: hoursResponse.data.data,
          });
        } else {
          dispatch({
            type: 'GET_BUSINESS_SPECIAL_HOURS',
            specialHours: hoursResponse.data.data,
            formatedSpecialHours: formatedSpecialHours,
          });
        }
      });
  };
}

export function putRegularHours(businessId, regularHours) {
  const formattedRegularHours = [];
  regularHours?.forEach((item) => {
    const modifiedHours = {
      close_day_of_week: item?.close_day_of_week,
      close_time: item?.close_time,
      open_day_of_week: item?.open_day_of_week,
      open_time: item?.open_time,
      order_end_time: item?.order_end_time,
      order_start_time: item?.order_start_time,
    };
    formattedRegularHours.push(modifiedHours);
  });
  return function (dispatch) {
    api
      .put(`businesses/${businessId}/hours`, {
        hours: formattedRegularHours,
      })
      .then((respone) => {
        dispatch({
          type: 'PUT_REGULAR_HOURS_SUCCESS',
          regularHours: regularHours,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business hours updated successfully',
          })
        );
      })
      .catch((error) => {
        let errorMessage;
        let messages = [];
        if (Object.keys(error.response.data.errors.details).length > 0) {
          const indexes = Object.keys(error.response.data.errors.details).map(
            (message) => parseInt(message.charAt(7))
          );
          indexes.forEach((index) => {
            const dayId = formattedRegularHours[index].open_day_of_week;
            const dayName = getDayOfWeek(dayId);
            messages.push(
              `${dayName} : Ordering hours are not in location hours range.`
            );
          });
          messages = messages.join('\n');
          errorMessage = messages;
        } else {
          errorMessage = formatErrorMessage(error);
        }
        dispatch(
          createAlert({
            type: 'error',
            message: errorMessage,
            dangerouslySetInnerHTML: true,
          })
        );
        dispatch({
          type: PUT_REGULAR_HOURS_FAILURE,
        });
      });
  };
  // };
}

export function patchSpecialHour(businessLocationId, finalSpecialHours) {
  return function (dispatch) {
    finalSpecialHours.forEach((day, index) =>
      api
        .patch(
          `businesses/${businessLocationId}/hours-overrides/${day.id}`,
          day.hours
        )
        .then(() => {
          if (finalSpecialHours.length === index + 1) {
            dispatch(
              createAlert({
                type: 'success',
                message: 'Special Hours updated successfully',
              })
            );
            dispatch(getSpecialHours(businessLocationId));
          }
        })
        .catch((error) => {
          let errorMessage;
          errorMessage =
            error?.response?.data?.errors?.message ||
            'Order end time has to be before close time.';
          dispatch(
            createAlert({
              type: 'error',
              message: errorMessage,
              dangerouslySetInnerHTML: true,
            })
          );
        })
    );
  };
}
export function postSpecialHour(businessLocationId, finalSpecialHours) {
  return function (dispatch) {
    finalSpecialHours.forEach((day, index) =>
      api
        .post(`businesses/${businessLocationId}/hours-overrides`, day)
        .then(() => {
          if (finalSpecialHours.length === index + 1) {
            dispatch(
              createAlert({
                type: 'success',
                message: 'Special Hours saved successfully',
              })
            );
            dispatch(getSpecialHours(businessLocationId));
          }
        })
        .catch((error) => {
          errorMessage =
            error?.response?.data?.errors?.message ||
            'Order end time has to be before close time.';
          dispatch(
            createAlert({
              type: 'error',
              message: errorMessage,
              dangerouslySetInnerHTML: true,
            })
          );
        })
    );
  };
}
export function deleteSelectedSpecialHours(
  businessLocationId,
  specialHoursIDs
) {
  return (dispatch) => {
    dispatch({
      type: DELETE_SPECIAL_HOURS_REQUEST,
    });
    specialHoursIDs.forEach((id, index) =>
      api
        .delete(`businesses/${businessLocationId}/hours-overrides/${id}`)
        .then(() => {
          if (specialHoursIDs.length === index + 1) {
            dispatch({
              type: DELETE_SPECIAL_HOURS_SUCCESS,
            });
            dispatch(
              createAlert({
                type: 'success',
                message: 'Special Hours deleted successfully',
              })
            );
            dispatch(getSpecialHours(businessLocationId));
          }
        })
    );
  };
}
/// *********************************************************************************************
function getBusinessLocationToggles(businessLocationId) {
  return api.get(`businesses/${businessLocationId}`);
}
function getBusinessLocationAddress(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/address`);
}

function getBusinessLocationDetails(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/detail`);
}

function getBusinessLocationRegularHours(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/hours`);
}

function getBusinessLocationSpecialHours(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/hours-overrides`);
}
function getBusinessPaymentIntegrationDetails(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/integrations`);
}

function patchBusinessLocationToggles(businessLocationId, values) {
  return api.patch(`businesses/${businessLocationId}`, values);
}

function patchBusinessLocationDetails(businessLocationId, values) {
  return api.patch(`businesses/${businessLocationId}/detail`, values);
}

function putBusinessLocationRegularHours(businessLocationId, values) {
  return api.put(`businesses/${businessLocationId}/hours`, { hours: values });
}

export function getBusinessPaymentIntegrationSchema() {
  return (dispatch) => {
    if (localStorage.getItem('role') !== 'ROLE_BUSINESS_ADMIN') {
      api.get(`business/integration/configuration`).then((response) => {
        const schema = response.data.data.reduce((acc, data) => {
          let newData = null;
          if (data?.credit_card?.stripe) {
            newData = {
              ...data,
              credit_card: {
                ...data.credit_card,
                stripe: [data.credit_card.stripe['0']],
              },
            };
          } else {
            newData = data;
          }
          acc = { ...acc, ...newData };
          return acc;
        }, {});
        dispatch({
          type: GET_PAYMENT_INTEGRATION_SCHEMA_SUCESS,
          schema,
        });
      });
    }
  };
}

export function updatePaymentIntegration(businessId, values) {
  let payload = {
    integration: Object.keys(values).reduce((acc, paymentType) => {
      return acc.concat({
        type: paymentType,
        provider: values[paymentType].provider,
        configuration: { ...values[paymentType].configuration },
      });
    }, []),
  };

  return (dispatch) => {
    api
      .put(`businesses/${businessId}/integration`, payload)
      .then((response) => {
        dispatch({
          type: UPDATE_PAYMENT_INTEGRATION_SUCESS,
          paymentIntegration: values,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Payment Integration updated successfully',
          })
        );
      })
      .catch((error) => console.log(error));
  };
}

export function getPaymentIntegration(businessId) {
  return (dispatch) => {
    if (localStorage.getItem('role') !== 'ROLE_BUSINESS_ADMIN') {
      api.get(`businesses/${businessId}/integrations`).then((response) => {
        dispatch({
          type: GET_PAYMENT_INTEGRATION_SUCESS,
          paymentIntegration: response.data.data.reduce((acc, data) => {
            acc[data.type] = data;
            return acc;
          }, {}),
        });
      });
    }
  };
}

export function initiateDeleteSpecialHours(
  businessLocationId,
  specialHoursToDelete
) {
  return (dispatch) => {
    dispatch({
      type: DELETE_SPECIAL_HOURS_REQUEST,
    });
    let toDelete = specialHoursToDelete.map((specialHourToDelete) =>
      deleteSpecialHour(businessLocationId, specialHourToDelete)
    );
    Promise.all(toDelete).then((responses) => {
      dispatch({
        type: DELETE_SPECIAL_HOURS_SUCCESS,
      });
      dispatch(
        createAlert({
          type: 'success',
          message: 'Special Hours deleted successfully',
        })
      );
      dispatch(getBusinessLocationDetailsByBusinessId(businessLocationId));
    });
  };
}

export function initiateUpdateSpecialHours(
  businessLocationId,
  postSpecialHours,
  patchSpecialHours,
  deleteSpecialHours
) {
  return (dispatch) => {
    let hoursError = new Set();
    // patchSpecialHours = patchSpecialHours.filter(function (obj) {
    //   return obj.dayName !== undefined;
    // });
    postSpecialHours.forEach((specialHourToPost) => {
      if (specialHourToPost.orderAheadIsOpen) {
        if (
          Moment(specialHourToPost.orderAheadOpenTime).isBefore(
            specialHourToPost.openTime
          ) ||
          Moment(specialHourToPost.orderAheadCloseTime).isAfter(
            specialHourToPost.closeTime
          )
        ) {
          hoursError.add(specialHourToPost.dayName);
        }
      }
    });
    patchSpecialHours.forEach((patchSpecialHour) => {
      if (patchSpecialHour.orderAheadIsOpen) {
        if (
          Moment(patchSpecialHour.orderAheadOpenTime).isBefore(
            patchSpecialHour.openTime
          ) ||
          Moment(patchSpecialHour.orderAheadCloseTime).isAfter(
            patchSpecialHour.closeTime
          )
        ) {
          hoursError.add(patchSpecialHour.dayName);
        }
      }
    });
    if (Array.from(hoursError).length > 0) {
      dispatch(
        createAlert({
          type: 'error',
          message: `Order Ahead Hours should be between Regular Hours.
          Error in days - ${Array.from(hoursError).map(
            (error) => ` ${error} `
          )}`,
        })
      );
    } else {
      dispatch({
        type: UPDATE_SPECIAL_HOURS_REQUEST,
      });
      var postSpecialHoursFinal = postSpecialHours.reduce((unique, o) => {
        if (
          !unique.some(
            (obj) =>
              obj.open_day_of_week === o.open_day_of_week && o.isOpen === false
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, []);
      let formattedPostSpecialHours = postSpecialHoursFinal.map(
        (specialHourToPost) => {
          let checkDuplicate = formatPostSpecialHour(specialHourToPost);
          // specialHourToPost.isOpen &&\
          postSpecialHour(
            businessLocationId,
            formatPostSpecialHour(specialHourToPost)
          );
        }
      );
      let formattedPatchSpecialHours = patchSpecialHours.map(
        (specialHourToPatch) =>
          patchSpecialHour(
            businessLocationId,
            specialHourToPatch.id,
            formatPatchSpecialHour(specialHourToPatch)
          )
      );
      let toDelete = deleteSpecialHours.map((specialHourToDelete) =>
        deleteSpecialHour(businessLocationId, specialHourToDelete)
      );
      Promise.all([
        ...formattedPostSpecialHours,
        ...formattedPatchSpecialHours,
        ...toDelete,
      ])
        .then((responses) => {
          dispatch({
            type: UPDATE_SPECIAL_HOURS_SUCCESS,
          });
          dispatch(getBusinessLocationDetailsByBusinessId(businessLocationId));
          dispatch(
            createAlert({
              type: 'success',
              message: 'Special Hours saved successfully',
            })
          );
        })
        .catch((error) => {
          dispatch(
            createAlert({
              type: 'error',
              message:
                Object.keys(error.response.data.errors.details).length > 0
                  ? Object.entries(error.response.data.errors.details).map(
                      ([key, value]) => value
                    )
                  : formatErrorMessage(error),
            })
          );
          dispatch({
            type: UPDATE_SPECIAL_HOURS_FAILURE,
          });
        });
    }
  };
}

function deleteSpecialHour(businessLocationId, specialHourToDelete) {
  return api.delete(
    `businesses/${businessLocationId}/hours-overrides/${specialHourToDelete.id}`
  );
}

// function postSpecialHour(businessLocationId, specialHourToPost) {
//   return api.post(
//     `businesses/${businessLocationId}/hours-overrides`,
//     specialHourToPost
//   );
// }

/*
 * STORING TO USE ELSEWHERE
 */
export function specialHoursToDelete(specialHoursToDelete) {
  return function (dispatch) {
    dispatch({
      type: SPECIAL_HOURS_TO_DELETE,
      specialHoursToDelete,
    });
  };
}

export function specialHoursToEdit(specialHoursToEdit) {
  return function (dispatch) {
    dispatch({
      type: SPECIAL_HOURS_TO_EDIT,
      specialHoursToEdit,
    });
  };
}

/*
 * MODAL HANDLERS
 */

export function isCreateAndEditSpecialHoursModalOpen(
  isCreateAndEditSpecialHoursModalOpen
) {
  return function (dispatch) {
    dispatch({
      type: IS_CREATE_AND_EDIT_SPECIAL_HOURS_MODAL_OPEN,
      isCreateAndEditSpecialHoursModalOpen,
    });
  };
}

export function isDeleteSpecialHoursConfirmModalOpen(
  isDeleteSpecialHoursConfirmModalOpen
) {
  return function (dispatch) {
    dispatch({
      type: IS_DELETE_SPECIAL_HOURS_CONFIRM_MODAL_OPEN,
      isDeleteSpecialHoursConfirmModalOpen,
    });
  };
}

export function alertError(message) {
  return function (dispatch) {
    dispatch(
      createAlert({
        type: 'error',
        message: message,
      })
    );
  };
}

export function resetBusinessLocationDetailsReducerState() {
  return {
    type: RESET_BUSINESS_LOCATION_DETAILS_REDUCER_STATE,
  };
}

export function initiateCreateLocation() {
  return function (dispatch) {
    dispatch({
      type: INITIATE_CREATE_LOCATION,
      data: constructBusinessLocationFormat(null)(null, null)(null)(null),
    });
  };
}

export function initiateEditLocation(businessId) {
  return function (dispatch) {
    dispatch({
      type: INITIATE_EDIT_LOCATION,
      businessId: businessId,
    });
  };
}

export function createBusinessInformation(businessInformation) {
  delete businessInformation.name;
  delete businessInformation.is_archived;
  // if(!businessInformation['external_id']) delete businessInformation['external_id'];
  return function (dispatch) {
    dispatch({ type: CREATE_BUSINESS_LOCATION_REQUEST });
    console.log(businessInformation);
    return api
      .post(`/businesses`, businessInformation)
      .then((response) => {
        dispatch({
          type: CREATE_BUSINESS_SUCCESS,
          businessId: response.data.data.id,
          businessInformation: businessInformation,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business location created successfully',
          })
        );
        dispatch(getBusinessLocationsOfMerchant());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateBusinessInformation(
  businessInformation,
  businessId,
  initialValues
) {
  const patchData = formatValuesForPatch(businessInformation, initialValues);
  return function (dispatch) {
    dispatch({ type: CREATE_BUSINESS_LOCATION_REQUEST });
    return api
      .patch(`/businesses/${businessId}`, patchData.businessInformation)
      .then((response) => {
        dispatch({
          type: UPDATE_BUSINESS_SUCCESS,
          businessId: businessId,
          businessInformation: businessInformation,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business location updated successfully',
          })
        );
        dispatch(getBusinessLocationsOfMerchant());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function createAddressAndDetailsInformation(data, businessId) {
  const { address, details } = data;
  delete address.deliveryzone;
  const pureAddressPayload = getPurePayload(address);
  const pureDetailPayload = getPurePayload(details);
  return function (dispatch) {
    return Promise.all([
      api.post(`/businesses/${businessId}/addresses`, pureAddressPayload),
      api.post(`/businesses/${businessId}/details`, pureDetailPayload),
    ])
      .then((response) => {
        dispatch({
          type: 'POST_BUSINESS_ADDRESS_AND_DETAILS_SUCCESS',
          data: data,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business address updated successfully',
          })
        );
      })
      .catch((error) => console.log(error));
  };
}

export function updateAddressAndDetailsInformation(
  data,
  businessId,
  initialValues
) {
  const patchData = formatValuesForPatch(data, initialValues, true);
  const purePayload = getPurePayload(patchData);
  if (
    (purePayload?.address && Object.keys(purePayload.address).length > 0) ||
    (purePayload?.details && Object.keys(purePayload.details).length > 0)
  ) {
    return function (dispatch) {
      let promises = [];
      if (patchData.details) {
        promises.push(
          api.patch(`businesses/${businessId}/detail`, purePayload.details)
        );
      }
      if (patchData.address) {
        promises.push(
          api.patch(`businesses/${businessId}/address`, purePayload.address)
        );
      }
      Promise.all(promises)
        .then((responses) => {
          dispatch({
            type: PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
            data: data,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Location Details successfully saved.',
            })
          );
        })
        .catch((error) => {
          dispatch({
            type: PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE,
          });
        });
    };
  } else {
    return function (dispatch) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'No field is available for update.',
        })
      );
    };
  }
}
export function copyBusinessMenu(copyBusinessPayload, copyBusinessName) {
  return function (dispatch) {
    return api
      .post(`/menu/copy`, copyBusinessPayload)
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: `Menu was successfully copied from ${copyBusinessName}`,
          })
        );
        dispatch(getBusinessLocationsOfMerchant());
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: formatErrorMessage(error) || 'Something went wrong!',
            dangerouslySetInnerHTML: true,
          })
        );
      });
  };
}
