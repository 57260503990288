import React, { Fragment, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import {
  RenderSelect,
  RenderTextField,
  required,
} from '../../../../components/FormElements/FormikElements';
import { Button } from 'merchant-portal-components';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import {
  ButtonsWrapper,
  FormWrapper,
  ModalTitle,
} from '../CreateEditModalStyle';
import { getRoles } from '../../utils/utils';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { formatLocations } from '../../../../utils/childLocations/formatLocations';
import FilterButton from '../../../../components/FilterModal/FilterButton';
import LocationFilterModal from '../../../../components/FilterModal/LocationFilterModal';
import timezones from '../../../../utils/constants/timezones';

const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
`;

const CreateUserModal = ({
  roles,
  toggleCreateUserModal,
  submitUser,
  businesses,
  account,
  appConfiguration,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEmailState, setIsEmailState] = useState(false);
  const businessesModified = formatLocations(businesses);

  const [businessSelectAvailability, setBusinessSelectAvailability] = useState(
    account ? account.business_ids.length === businessesModified.length : true
  );

  const handleBusinessToggle = (setFieldValue) => {
    setBusinessSelectAvailability(!businessSelectAvailability);
    if (account) {
      if (businessSelectAvailability) {
        if (account.business_ids.length === businessesModified.length)
          setFieldValue('business_ids', []);
        else setFieldValue('business_ids', account.business_ids);
      } else {
        setFieldValue(
          'business_ids',
          businessesModified.map((business) => business.id)
        );
      }
    }
  };

  const validateUsername = (username) => {
    const isEmail = new RegExp(
      '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'
    );
    setIsEmailState(!isEmail.test(username));
  };

  const [isUserActive, setIsUserActive] = useState(
    account ? account.is_active : true
  );

  const toggleUser = (setFieldValue) => {
    setIsUserActive(!isUserActive);
    setFieldValue('is_active', !isUserActive);
  };

  const handleSubmit = (account, values) => {
    let users = { ...values };
    if (values.business_ids.length == 0 && businessSelectAvailability) {
      users.business_ids = businesses.map((business) => business.id);
    }
    submitUser(account, users);
  };
  const saveButtonValidation = (values) => {
    return (
      !values.username ||
      !values.display_name ||
      !values.role ||
      (!values.business_ids.length && !businessSelectAvailability) ||
      isEmailState ||
      values.business_ids.length === 0||!values.timezone
    );
  };
  const initialBusinessIDs = account
    ? account?.business_ids
    : businesses.map((business) => business.id);
  return (
    <Dialog open fullWidth maxWidth='sm'>
      <DialogTitle>
        <ModalTitle>
          {account ? 'Edit User' : 'Invite a New User'}
          <div onClick={() => toggleCreateUserModal(null)}>
            <i className='fal fa-times' style={{ cursor: 'pointer' }}></i>
          </div>
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        <FormWrapper>
          <Formik
            initialValues={{
              username: account ? account.username : '',
              display_name: account ? account.display_name : '',
              role: account ? account.role : '',
              business_ids: initialBusinessIDs || [],
              is_active: account ? account.is_active : true,
              timezone: account ? account.timezone : '',
            }}
          >
            {({ values, handleChange, setFieldValue }) => (
              <Fragment>
                <Field
                  type='text'
                  name='username'
                  label='Email'
                  component={RenderTextField}
                  validate={required}
                  disabled={account}
                  onChange={(e) => {
                    handleChange(e);
                    validateUsername(e.target.value);
                  }}
                ></Field>
                {isEmailState ? (
                  <span
                    style={{
                      color: 'red',
                      fontSize: '12px',
                      marginTop: '-20px',
                    }}
                  >
                    Please put a valid email format
                  </span>
                ) : null}
                <Field
                  type='text'
                  name='display_name'
                  label='Display name'
                  component={RenderTextField}
                  validate={required}
                ></Field>
                <Field
                  options={getRoles(roles)}
                  name='role'
                  component={RenderSelect}
                  placeholder='Select a User Role'
                  getOptionLabel={(role) => role.label}
                  getOptionValue={(role) => role.value}
                  onChange={(e) => {
                    setFieldValue('role', e.value);
                    if (e.value === 'ROLE_ADMIN') {
                      setFieldValue(
                        'business_ids',
                        businesses.map((item) => item.id)
                      );
                    }
                  }}
                />
                <Field
                  options={timezones.map((timezone) => ({
                    label: timezone,
                    value: timezone,
                  }))}
                  name='timezone'
                  validate={required}
                  component={RenderSelect}
                  placeholder='Select Timezone'
                  getOptionLabel={(timezone) => timezone.label}
                  getOptionValue={(timezone) => timezone.value}
                  onChange={(e) => setFieldValue('timezone', e.value)}
                />
                <div style={{ margin: '15px 0 0 0' }}>
                  <SmallToggleSwitch
                    checked={businesses.length === values.business_ids.length}
                    label='All Locations'
                    onChange={(e) => {
                      if (e.target.checked || values.role === 'ROLE_ADMIN') {
                        setFieldValue(
                          'business_ids',
                          businesses.map((item) => item.id)
                        );
                      } else {
                        setFieldValue('business_ids', []);
                        setIsOpen(true);
                      }
                    }}
                    
                  />
                </div>
                <>
                  {values.role !== 'ROLE_ADMIN' && (
                    <FilterButton
                      message={
                        businesses?.length === values.business_ids?.length
                          ? 'All Locations Selected'
                          : `Selected Locations (${
                              values.business_ids?.length || 0
                            })`
                      }
                      height='55px'
                      setIsOpen={setIsOpen}
                    />
                  )}
                </>

                {account && (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '80px 1fr',
                      alignItems: 'center',
                    }}
                  >
                    <SmallToggleSwitch
                      checked={isUserActive}
                      onChange={() => toggleUser(setFieldValue)}
                      value='checkedB'
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <span> Make {isUserActive ? 'Inactive' : 'Active'}</span>
                  </div>
                )}
                <ButtonsWrapper>
                  <Button
                    style={{
                      background: 'none',
                      color: '#191818',
                      border: 'none',
                    }}
                    onClick={() => toggleCreateUserModal(null)}
                  >
                    Cancel
                  </Button>
                  <div />
                  <Button
                    type='submit'
                    onClick={() => handleSubmit(account, values)}
                    disabled={saveButtonValidation(values)}
                  >
                    {account ? 'Save' : 'Send Invite'}
                  </Button>
                </ButtonsWrapper>
                {isOpen && (
                  <LocationFilterModal
                    placeholder='Enter Internal Location Name'
                    searchLabel='Search by Internal Location Name'
                    filteredLocations={values.business_ids}
                    setFilteredLocations={setFieldValue}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    formField='business_ids'
                    emptyIsEqualToAll={false}
                    isEmptyAccepted={false}
                  />
                )}
              </Fragment>
            )}
          </Formik>
        </FormWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUserModal;
