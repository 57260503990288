import {
  GET_CUSTOMERS_OF_MERCHANT_REQUEST,
  GET_CUSTOMERS_OF_MERCHANT_SUCCESS,
  GET_CUSTOMERS_OF_MERCHANT_FAILURE,
  GET_CUSTOMERS_PAGINATION_SUCCESS,
} from './customerListTypes';

export default function (
  state = {
    getCustomersIsRequesting: false,
    customers: [],
    customerId: '',
    currentPage: 'list',
    meta: [],
    requestParams: {
      page_number: 1,
      page_size: '25',
      sorting_option: 'title-asc',
      search_string: '',
      has_next_page: false,
    },
  },
  action
) {
  switch (action.type) {
    case 'UPDATE_CUSTOMER_OF_MERCHANT_CUSTOMERID':
      return {
        ...state,
        customerId: action.customerId,
      };
    case 'UPDATE_CUSTOMER_OF_MERCHANT_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case GET_CUSTOMERS_OF_MERCHANT_REQUEST: {
      return {
        ...state,
        getCustomersIsRequesting: true,
      };
    }
    case GET_CUSTOMERS_OF_MERCHANT_SUCCESS:
      return {
        ...state,
        getCustomersIsRequesting: false,
        customers: action.customers,
        meta: action.meta,
      };
    case GET_CUSTOMERS_PAGINATION_SUCCESS:
      return {
        ...state,
        customers: action.customers,
        requestParams: {
          ...state.requestParams,
          ...action.meta,
        },
      };
    default:
      return state;
  }
}
