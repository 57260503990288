export const GET_BUSINESS_ACCOUNTS_REQUEST = 'GET_BUSINESS_ACCOUNTS_REQUEST';
export const GET_BUSINESS_ACCOUNTS_SUCCESS = 'GET_BUSINESS_ACCOUNTS_SUCCESS';
export const CREATE_NEW_USER_REQUEST = 'CREATE_NEW_USER_REQUEST';
export const UPDATE_USER = 'UPDATE_USER';
export const PATCH_EDIT_USER = 'PATCH_EDIT_USER';
export const TOGGLE_USER_MODAL = ' TOGGLE_USER_MODAL';
export const RESET_IS_REGISTERED = 'RESET_IS_REGISTERED';

export const ARCHIVE_ADMIN_REQUEST = 'ARCHIVE_ADMIN_REQUEST';
export const ARCHIVE_ADMIN_SUCCESS = 'ARCHIVE_ADMIN_SUCCESS';
export const ARCHIVE_ADMIN_FAILURE = 'ARCHIVE_ADMIN_FAILURE';

export const GET_ARCHIVED_ACCOUNTS_REQUEST = 'GET_ARCHIVED_ACCOUNTS_REQUEST';
export const GET_ARCHIVED_ACCOUNTS_SUCCESS = 'GET_ARCHIVED_ACCOUNTS_SUCCESS';
export const GET_ARCHIVED_ACCOUNTS_FAILURE = 'GET_ARCHIVED_ACCOUNTS_FAILURE';

export const UNARCHIVE_ADMIN_REQUEST='UNARCHIVE_ADMIN_REQUEST'
export const UNARCHIVE_ADMIN_SUCCESS='UNARCHIVE_ADMIN_SUCCESS'
export const UNARCHIVE_ADMIN_FAILURE='UNARCHIVE_ADMIN_FAILURE'

