import React from 'react';
import {
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  // StyledTh
} from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';
import PaginationControls from '../../../../components/Pagination/PaginationControl';
import { LoadingBlueLarge } from 'merchant-portal-components';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import { STRING, BOOLEAN, sortByTypes } from '../../../../utils/sort/sort';

const EmptyStateMessage = styled.p`
  margin-top: 60px;
  text-align: center;
  color: ${(props) => props.theme.gray};
`;
const PaginationWrapper = styled.div`
  padding: 25px 37px;
  display: flex;
  justify-content: right;
`;
const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;
const TextSearch = styled.div`
  // padding: 20px;
`;

export class CouponPromoIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subMenuOpen: null,
    };
  }
  componentDidMount() {
    ReactGA.pageview(window.location.pathname, undefined, 'Credit Code List');
  }
  renderHeaders = () => {
    return (
      <TrHead style={{ background: 'none' }}>
        <Th
          align='left'
          onClick={() => {
            this.props.handleSort({
              value: 'coupon_details.label',
              sortMethod: sortByTypes,
              sortType: STRING,
            });
          }}
          value='coupon_details.label'
          sortingOption={this.props.requestParams.sorting_option}
          isSortable='true'
        >
          Name
        </Th>
        <Th
          align='left'
          onClick={() => {
            this.props.handleSort({
              value: 'coupon_details.coupon_code',
              sortMethod: sortByTypes,
              sortType: STRING,
            });
          }}
          value='coupon_details.coupon_code'
          sortingOption={this.props.requestParams.sorting_option}
          isSortable='true'
        >
          Code
        </Th>
        <Th align='left'>Reward Type</Th>
        <Th align='left'>Recipients</Th>
        <Th
          align='left'
          onClick={() => {
            this.props.handleSort({
              value: 'coupon_details.is_active',
              sortMethod: sortByTypes,
              sortType: BOOLEAN,
            });
          }}
          value='coupon_details.is_active'
          sortingOption={this.props.requestParams.sorting_option}
          isSortable='true'
        >
          Active
        </Th>
      </TrHead>
    );
  };

  renderRows = ({ coupons, props }) => {
    const { requestParams } = props;
    const indexOfFirst =
      (requestParams.page_number - 1) * requestParams.page_size;
    const indexOfLast = requestParams.page_number * requestParams.page_size;
    const couponsData = coupons.response.data;
    if (couponsData.length > 0) {
      return couponsData.map((coupon, index) => {
        return (
          <Tr
            onClick={() => props.initiateEditCoupon({ coupon })}
            key={index}
            style={{ cursor: 'pointer', height: '75px' }}
          >
            <Td align='left'>{coupon.coupon_details.label}</Td>
            <Td align='left'>{coupon.coupon_details.coupon_code}</Td>
            <Td align='left'>
              {coupon.coupon_details.template_values.type == 'money'
                ? 'Dollar Credit'
                : 'Loyalty Credit'}
            </Td>
            <Td align='left'>
              {coupon.customers.length == 0 ? 'All Users' : 'Targeted'}
            </Td>
            <Td
              onClick={(event) => {
                event.stopPropagation();
              }}
              align='left'
              style={{ position: 'relative' }}
            >
              <SmallToggleSwitch
                checked={coupon.coupon_details.is_active}
                onChange={(event) => {
                  event.stopPropagation();
                  props.toggleIsActiveCoupon(coupon);
                }}
              />
            </Td>
          </Tr>
        );
      });
    } else {
      return (
        <Tr style={{ width: '100%' }}>
          <Td colSpan='5' align='center' padding='50px 0'>
            No matching data
          </Td>
        </Tr>
      );
    }
  };

  render() {
    const { coupons, requestParams } = this.props;
    const { requestingGetCoupons, response } = this.props.coupons;
    const props = this.props;
    return (
      <React.Fragment>
        <TableWrapper>
          <TopDiv>
            <TextSearch>
              <TextField
                type='text'
                style={{ width: '100%' }}
                variant='outlined'
                value={this.props.searchString}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon className='fal fa-search' />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Icon
                        className='fas fa-times-circle'
                        style={{ color: '#193053' }}
                        onClick={this.props.handleClearSearch}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => props.handleCouponsSearch(e.target.value)}
                placeholder='Search credit codes'
              />
            </TextSearch>
            <Button
              style={{ fontSize: '16px', fontWeight: '600' }}
              onClick={() => props.initiateCreateCoupon()}
            >
              Create Credit Code
            </Button>
          </TopDiv>
          <Table noBorder>
            <thead>{this.renderHeaders()}</thead>
            <TBody striped>
              {response && this.renderRows({ coupons, props })}
            </TBody>
          </Table>
          <PaginationWrapper>
            {coupons && coupons.response && (
              <PaginationControls
                response={{
                  ...coupons.response,
                  page: requestParams.page_number,
                  page_size: requestParams.page_size,
                  count: this.props.allCouponsLength,
                }}
                requestParams={requestParams}
                updateAction={this.props.fetchCoupons}
                type='coupons'
              />
            )}
          </PaginationWrapper>
        </TableWrapper>
        {!response && <LoadingBlueLarge />}
        {!requestingGetCoupons && response && response.meta.count == 0 && (
          <EmptyStateMessage>
            There are no credit codes available.
          </EmptyStateMessage>
        )}
      </React.Fragment>
    );
  }
}
