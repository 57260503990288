import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from './priceActions';
import { getVariants } from '../Variants/variantsActions';
import { getOptions } from '../OptionManager/optionActions';
import { initiateEditProductWizard } from '../Products/productsActions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Price from './components/Price';
import ReactGA from 'react-ga';
const PriceContainer = ({
  options,
  variants,
  getOptionPrices,
  filterOptionsByVariants,
  putOptionPrices,
  priceReducer,
  resetPriceReduxState,
  getItemsOverrides,
  getProductAssignedBusinesses,
  setIsOptionOverridesModal,
  getAssociatedProductForOption,
  initiateEditProductWizard,
  getVariants,
  getOptions,
  clearOptionItems,
}) => {
  const {
    optionPrices,
    items,
    isRequesting,
    itemOverrides,
    isRequestingItemOverrides,
    formattedBusinesses,
    isOptionOverridesModal,
    accociatedProductList,
  } = priceReducer;

  useEffect(() => {
    getVariants({
      page_number: 1,
      page_size: '1000',
      sorting_option: 'title-asc',
      search_string: '',
      has_next_page: false,
    });
    getOptions({
      page_number: 1,
      page_size: '2000',
      sorting_option: 'title-asc',
      search_string: '',
      has_next_page: false,
    });
    ReactGA.pageview(
      window.location.pathname,
      undefined,
      'Option Price Manager'
    );
    return () => {
      resetPriceReduxState();
    };
  }, []);

  return (
    <Fragment>
      <BreadCrumb />
      {options && variants && (
        <Price
          items={items}
          options={options}
          variants={variants}
          optionPrices={optionPrices}
          itemOverrides={itemOverrides}
          isRequestingItemOverrides={isRequestingItemOverrides}
          formattedBusinesses={formattedBusinesses}
          isOptionOverridesModal={isOptionOverridesModal}
          setIsOptionOverridesModal={setIsOptionOverridesModal}
          accociatedProductList={accociatedProductList}
          getAssociatedProductForOption={getAssociatedProductForOption}
          initiateEditProductWizard={initiateEditProductWizard}
          isRequesting={isRequesting}
          getItemsOverrides={getItemsOverrides}
          getProductAssignedBusinesses={getProductAssignedBusinesses}
          getOptionPrices={getOptionPrices}
          putOptionPrices={putOptionPrices}
          filterOptionsByVariants={filterOptionsByVariants}
          clearOptionItems={clearOptionItems}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  options: state.optionReducer.options,
  variants: state.variantsReducer.allVariants,
  priceReducer: state.priceReducer,
  accociatedProductList: state.priceReducer.accociatedProductList,
});

export default connect(mapStateToProps, {
  ...actions,
  initiateEditProductWizard,
  getVariants,
  getOptions,
})(PriceContainer);
