import styled from 'styled-components';

export const Card = styled.div`
  background-color: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  color: ${(props) => (props.disabled ? '#191818' : 'inherit')};
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;
  padding-right: 20px;
`;

export const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 20px 30px 20px 40px;
  margin: 10px;
`;

export const Title = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : 'none')};
  font-weight: 600;
  font-size: 21px;
`;

export const FormWrapper = styled.div`
  padding: 0px 48px 20px 48px;
  display: grid;
  grid-gap: 20px;
`;

export const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.grid};
  grid-gap: 20px;
  align-items: center;
`;

export const Headline = styled.p`
  font-size: 17px;
  font-weight: 600;
`;

export const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px auto 1fr 100px;
  grid-gap: 20px;
  align-items: center;
`;

export const ChooseImage = styled.div`
  border: 2px dashed #979797;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

export const Error = styled.span`
  font-size: 12px;
  color: rgb(244, 67, 54);
  position: relative;
  top: -15px;
`;
export const PhoneNumberWrapper = styled.div`
  width: 100%;
`;
export const PhoneNumberLabel = styled.div`
  color: #878787;
  font-size: 12px;
`;
