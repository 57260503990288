import React, {
  useEffect,
  Suspense,
  lazy,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import * as actions from '../ReportTableActions.js';
import { getBusinessLocationsOfMerchant } from '../../BusinessLocationDetails/LocationListManager/actions.js';
import styled from 'styled-components';
import MoreLocationsModal from './MoreLocationsModal.js';
import { LoadingBlueLarge } from 'merchant-portal-components';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable.js')
);
const MoreButton = styled.button`
  display: inline-block;
  background-color: #666666;
  color: #ffffff !important;
  padding: 5px;
  margin-left: 5px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #7d7a7a;
  }
`;

const NoDataWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.darkBlue};
  text-align: center;
`;

const DownloadButton = styled.a`
  background-color: #666666;
  color: #ffffff !important;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #7d7a7a;
  }
`;
const GetReportButton = styled.button`
  background-color: #666666;
  color: #ffffff !important;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #7d7a7a;
  }
`;

function ExportReportContainer({
  allExports,
  allBusinesses,
  requestParams,
  setExportingData,
  setExportingFileName,
  clearCouponReport,
  updateReportsRequestParams,
  getBusinessLocationsOfMerchant,
  setMessage,
  resetAllReports,
  setSelectedBusinessesIDs,
  filteredLocations,
  setFilteredLocations,
  getExportReports,
  allExportURLs,
  getReportDownloadLink,
  isExportReportLoading,
}) {
  const columnHeaders = [
    'Report Name',
    'Requested on (UTC)',
    'Start Date',
    'End Date',
    'Location',
    'Status',
    '',
  ];
  const columnNames = [
    'report_name',
    'start_process_at',
    'start_date',
    'end_date',
    'location',
    'status',
    'download',
  ];
  const columnTypes = [
    'string',
    'datetime',
    'date',
    'date',
    'string',
    'string',
    'download',
  ];
  const columnWidth = ['14%', '18%', '14%', '14%', '14%', '14%', '12%'];
  const columnHeaderIsSortableList = [
    true,
    true,
    true,
    true,
    true,
    true,
    false,
  ];
  const [open, setOpen] = useState(false);
  const [moreBusinesses, setMoreBusinesses] = useState([]);
  const updateSelectedBusiness = useCallback(() => {
    if (filteredLocations) {
      setSelectedBusinessesIDs(filteredLocations);
    }
  }, [filteredLocations]);
  useEffect(() => {
    getExportReports();
    updateSelectedBusiness();
  }, [updateSelectedBusiness]);
  useEffect(() => {
    getBusinessLocationsOfMerchant();
    setExportingFileName('credit_code_report');
    setMessage('Select a date range and location to generate a report');
    return () => {
      clearCouponReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setMessage('Select a date range and location to generate a report');
      resetAllReports();
      setFilteredLocations([]);
      setSelectedBusinessesIDs([]);
    };
  }, []);
  useEffect(() => {
    if (allExports?.length === 0) {
      setExportingData([]);
    }
  }, [allExports]);
  const getLocationName = useCallback(
    (businesses) => {
      if (
        businesses.length === 0 ||
        businesses.length === allBusinesses.length ||
        (businesses.length === 1 && businesses[0] === null)
      ) {
        return 'All';
      } else if (businesses.length === 1 && businesses[0] !== null) {
        return businesses[0];
      } else {
        return (
          <span>
            {businesses[0]}
            <MoreButton
              onClick={() => {
                setOpen(true);
                setMoreBusinesses(businesses);
              }}
            >
              + {businesses.length - 1}
            </MoreButton>
          </span>
        );
      }
    },
    [allBusinesses]
  );
  const getDownloadLink = useCallback((id) => {
    getReportDownloadLink(id);
  }, []);
  const downloadButton = useMemo(() => {
    return allExportURLs.map((exportItem) =>
      exportItem.url ? (
        <DownloadButton href={exportItem.url}>
          <i style={{ marginRight: '5px' }} className='fal fa-download'></i>
          Download
        </DownloadButton>
      ) : (
        <GetReportButton onClick={() => getDownloadLink(exportItem.id)}>
          Get Report
        </GetReportButton>
      )
    );
  }, [allExportURLs]);
  const modifiedExports = useMemo(() => {
    return allExports?.map((exportItem, index) => {
      return {
        report_name: exportItem.report_name,
        start_process_at: exportItem?.start_process_at || '',
        start_date: exportItem.filters.start_date,
        end_date: exportItem.filters.end_date,
        location: getLocationName(exportItem.filters.businesses),
        status: exportItem.status,
        download: {
          isDownloadButtonEnabled: exportItem.status === 'completed',
          downloadButton: downloadButton[index],
        },
      };
    });
  }, [allExports, downloadButton]);
  if (isExportReportLoading) {
    return (
      <div>
        <LoadingBlueLarge />
      </div>
    );
  }
  if (allExports.length === 0) {
    return (
      <NoDataWrapper>
        <h2>No data found</h2>
      </NoDataWrapper>
    );
  }
  return (
    <>
      {modifiedExports?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={modifiedExports}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'exportReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(modifiedExports)}
            />
          </Suspense>
          <MoreLocationsModal
            open={open}
            handleClose={() => setOpen(false)}
            businesses={moreBusinesses}
          />
        </div>
      )}
    </>
  );
}
function mapStateToProps(state) {
  return {
    allExports: state.ReportTableReducer.allExports,
    isExportReportLoading: state.ReportTableReducer.isExportReportLoading,
    allExportURLs: state.ReportTableReducer.allExportURLs,
    requestParams: state.ReportTableReducer.requestParams,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  getBusinessLocationsOfMerchant,
})(ExportReportContainer);
