import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import Card from '../../../../components/Card/Card';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { RenderSelect } from '../../../../components/FormElements/FormikElements';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { SmallToggleSwitch } from '../../../../components/ToggleSwitch/ToggleSwitch';
import StickyButtons from '../../../../components/StickyButtons';
import OfferBasicForm from './components/OfferBasicForm';
import OfferImageForm from './components/OfferImageForm';
import LocationSelection from './components/LocationSelection';
import SegmentSelection from './components/SegmentSelection';
import FirstTimeOffer from './components/FirstTimeOffer';
import FreeItemOffer from './components/FreeItemOffer';
import DiscountOffer from './components/DiscountOffer';
import BirthdayFreeItemOffer from './components/BirthdayFreeItemOffer';
import BirthdayDiscountOffer from './components/BirthdayDiscountOffer';
import CarryOverOffer from './components/CarryOverOffer';
import SourceOptions from './components/SourceOptions';
import FilterButton from '../../../../components/FilterModal/FilterButton';
import LocationFilterModal from '../../../../components/FilterModal/LocationFilterModal';
import CustomerSearchSelect from '../../../CustomerSearchManager/components/CustomerSearchSelect';
import { selectCustomer } from '../../../CustomerSearchManager/actions';
import { LoadingBlueLarge } from 'merchant-portal-components';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '0 0 10px 0',
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const AddCustomerButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 18,
    fontWeight: '600',
    padding: '6px 12px',
    border: 'none',
    cursor: 'pointer',
    color: '#ffffff',
    backgroundColor: '#2440AF',
    '&:hover': {
      backgroundColor: '#3051D4',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#CCCCCC',
      boxShadow: 'none',
      color: '#ffffff',
    },
  },
})(Button);
const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const InfoImageWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7.5%;
`;

const LocationSegmentGrid = styled.div`
  display: grid;
  width: 42.5%;
  grid-gap: 50px;
  margin-top: 50px;
`;
const CustomerWrapper = styled.div`
  margin: 25px 0;
`;
const CustomerSearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 4px;
`;
const CustomerListWrapper = styled.div`
  max-height: 400px;
  margin-top: 5px;
`;
const CustomerListRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  padding: 10px 7px;
  background-color: #edeef2;
  border: 1px solid #d5d6db;
  align-items: center;
`;
const HrLine = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 2.5em 0;
  padding: 0;
`;
const RemoveIcon = styled.i`
  cursor: pointer;
`;
const MapTitle = styled.div`
  margin: 10px 0px;
`;
const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

const OfferDetails = ({
  handleSubmit,
  isRequesting,
  editingOffer,
  isPointsEnabled,
  allBusinesses,
  allSegments,
  allProducts,
  customerSearchManager,
  getCustomersByQuery,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAudianceType, setSelectedAudianceType] = useState('');
  const [startUpdating, setStartUpdating] = useState();
  const [isFormReset, setIsFormReset] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [customers, setCustomers] = useState([]);
  const [segments, setSegments] = useState([]);
  const initializeOffer = useCallback(() => {
    if (editingOffer?.customers.length > 0) {
      setSelectedAudianceType('customer');
    }
    if (editingOffer?.segments.length > 0) {
      const filteredSegments = allSegments?.filter((seg) =>
        editingOffer?.segments.includes(seg.segment_id)
      );
      setSegments(filteredSegments);
      setSelectedAudianceType('segment');
    }
  }, [editingOffer, allSegments]);
  useEffect(() => {
    initializeOffer();
  }, [initializeOffer]);
  useEffect(() => {
    if (isFormReset) {
      setIsFormReset(false);
    }
  }, [isFormReset]);
  useEffect(() => {
    const debounce = setTimeout(() => {
      setStartUpdating(true);
    }, 1500);
    return () => {
      setStartUpdating(false);
      clearTimeout(debounce);
    };
  }, [queryString]);
  const handleUpdateAudianceType = (e, setFieldValue) => {
    setSelectedAudianceType(e.target.value);
    if (e.target.value === 'segment') {
      setCustomers([]);
      setFieldValue('customers', []);
    } else {
      setSegments([]);
      setFieldValue('segments', []);
    }
  };
  const handleAddCustomer = (setFieldValue) => {
    const newCustomers = [...customers];
    const filterdCustomer = customers.filter(
      (customer) => customer.id === selectedCustomer.customer_id
    );
    if (selectedCustomer && filterdCustomer.length === 0) {
      const customer = {
        id: selectedCustomer.customer_id,
        email: selectedCustomer.email,
      };
      newCustomers.push(customer);
      const customerIDs = newCustomers.map((cus) => cus.id);
      setCustomers(newCustomers);
    }
  };
  const handleRemoveCustomer = (id, setFieldValue) => {
    const newCustomers = customers.filter((customer) => customer.id !== id);
    const customerIDs = newCustomers.map((cus) => cus.id);
    setFieldValue('customers', customerIDs);
    setCustomers(newCustomers);
  };
  const handleAddSegments = (selectedItems, setFieldValue) => {
    // const modifiedSegments = selectedItems.map((item) => {
    //   return { id: item.segment_id, name: item.name };
    // });
    const segmentIDs = selectedItems.map((seg) => seg.segment_id);
    setFieldValue('segments', segmentIDs);
    setSegments(selectedItems);
  };

  const handleSubmitOffer = (values) => {
    let newOffer = { ...values };
    const newConditions = values?.conditions?.reduce((acc, cur) => {
      let newItemCondition = { ...cur };
      if (cur?.item_conditions?.discounted_products?.length > 0) {
        newItemCondition.item_conditions.discounted_products =
          cur.item_conditions.discounted_products
            .map((discount) => parseInt(discount))
            .filter((des) => des);
      }
      if (cur?.item_conditions?.required_products?.length > 0) {
        newItemCondition.item_conditions.required_products =
          cur.item_conditions.required_products
            .map((required) => parseInt(required))
            .filter((req) => req);
      }

      if (cur?.item_conditions?.eligible_products?.length > 0) {
        newItemCondition.item_conditions.eligible_products =
          cur.item_conditions.eligible_products
            .map((eligible) => parseInt(eligible))
            .filter((el) => el);
      }
      acc.push(newItemCondition);
      return acc;
    }, []);
    newOffer.conditions = newConditions;
    handleSubmit(newOffer);
  };
  return (
    <Formik
      initialValues={
        editingOffer || {
          offer_details: {
            template_name: null,
            title: '',
            subtitle: '',
            description: '',
            label: '',
            image_url: '',
            is_active: true,
            auto_redeem: false,
            reusable: false,
            template_values: {},
          },
          businesses: [],
          segments: [],
          customers: [],
          conditions: [
            // {
            //   is_active: true,
            //   source: "mobile",
            //   item_conditions: {
            //     eligible_products: [],
            //     required_products: [],
            //     eligible_variants: [],
            //     required_variants: []
            //   }
            // }
          ],
        }
      }
      enableReinitialize={true}
    >
      {({ values, handleReset, dirty, errors, touched, setFieldValue }) => (
        <Card>
          <SubTitleWrapper>
            <Subtitle>Offer Information</Subtitle>
            <SmallToggleSwitch
              checked={values.offer_details.is_active}
              label='Active'
              onChange={(e) =>
                setFieldValue('offer_details.is_active', e.target.checked)
              }
            />
          </SubTitleWrapper>
          <InfoImageWrapper>
            <div>
              <OfferBasicForm
                templateName={values.offer_details.template_name}
                setFieldValue={setFieldValue}
                source={
                  (values.conditions &&
                    values.conditions.length &&
                    values.conditions[0].source) ||
                  ''
                }
                isPointsEnabled={isPointsEnabled}
                editingOffer={editingOffer}
              />
              <div>
                <div style={{ margin: '15px 0' }}>
                  <SmallToggleSwitch
                    checked={values.businesses.length === 0}
                    label='All Locations'
                    onChange={(e) => {
                      if (e.target.checked) {
                        setFieldValue('businesses', []);
                      } else {
                        setIsOpen(true);
                      }
                    }}
                  />
                </div>
                <FilterButton
                  message={
                    values.businesses.length === 0
                      ? 'All Locations Selected'
                      : `Selected Locations (${values.businesses.length})`
                  }
                  height='55px'
                  setIsOpen={setIsOpen}
                />
                {isOpen && (
                  <LocationFilterModal
                    placeholder='Enter Internal Location Name'
                    searchLabel='Search by Internal Location Name'
                    filteredLocations={values.businesses}
                    setFilteredLocations={setFieldValue}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    formField='businesses'
                    emptyIsEqualToAll={true}
                    isEmptyAccepted={false}
                  />
                )}
                {values.offer_details.template_name !== 'first-time' &&
                  values.offer_details.template_name !==
                    'carryover-loyalty' && (
                    <SourceOptions
                      templateName={values.offer_details.template_name}
                      setFieldValue={setFieldValue}
                      conditions={values.conditions}
                      isFormReset={isFormReset}
                    />
                  )}
              </div>
            </div>
            <div>
              <OfferImageForm
                imageUrl={values.offer_details.image_url}
                setFieldValue={setFieldValue}
              />
              {/* <CustomerWrapper>
                <MapTitle>Specific audience(s) (optional):</MapTitle>
                <FormControl variant='outlined' className={classes.formControl}>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Specific audience(s)
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={selectedAudianceType}
                    onChange={(e) => handleUpdateAudianceType(e, setFieldValue)}
                    label='Specific audience(s)'
                  >
                    <MenuItem value=''>
                      <em>Select Specific audience(s)</em>
                    </MenuItem>
                    <MenuItem value='segment'>
                      Limit to specific segments
                    </MenuItem>
                    <MenuItem value='customer'>
                      Limit to specific customers
                    </MenuItem>
                  </Select>
                </FormControl>
                {selectedAudianceType === 'segment' && (
                  <div>
                    {allSegments && segments ? (
                      <Autocomplete
                        id='type'
                        multiple
                        limitTags={3}
                        options={allSegments || []}
                        disableCloseOnSelect
                        getOptionLabel={(item) => item.name}
                        value={segments}
                        onChange={(e, selectedItems) => {
                          handleAddSegments(selectedItems, setFieldValue);
                        }}
                        renderOption={(item, option) => (
                          <div>
                            <Checkbox
                              color='primary'
                              style={{
                                marginRight: '5px',
                                transform: 'scale(0.9)',
                              }}
                              checked={option.selected}
                            />
                            {item.name}
                          </div>
                        )}
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Segments'
                            variant='outlined'
                            placeholder=''
                          />
                        )}
                        disabled={false}
                      />
                    ) : (
                      <LoadingWrapper>
                        <LoadingBlueLarge />
                      </LoadingWrapper>
                    )}
                  </div>
                )}
                {selectedAudianceType === 'customer' && (
                  <>
                    <CustomerSearchWrapper>
                      <CustomerSearchSelect
                        value={null}
                        selectCustomer={(customer) => {
                          setSelectedCustomer(customer);
                        }}
                        queryString={queryString}
                        setQueryString={setQueryString}
                        startUpdating={startUpdating}
                        setStartUpdating={setStartUpdating}
                        customerSearchManager={customerSearchManager}
                        getCustomersByQuery={getCustomersByQuery}
                      />
                      <AddCustomerButton
                        onClick={() => handleAddCustomer(setFieldValue)}
                        disabled={!selectedCustomer}
                      >
                        Add
                      </AddCustomerButton>
                    </CustomerSearchWrapper>
                    <CustomerListWrapper>
                      {customers.map((customer, index) => (
                        <CustomerListRow key={index}>
                          <div>{customer.email}</div>
                          <RemoveIcon
                            className='fa fa-minus-circle'
                            onClick={() =>
                              handleRemoveCustomer(customer.id, setFieldValue)
                            }
                          ></RemoveIcon>
                        </CustomerListRow>
                      ))}
                    </CustomerListWrapper>
                  </>
                )}
              </CustomerWrapper> */}
            </div>
          </InfoImageWrapper>
          {values.offer_details.template_name && <HrLine />}
          {values.offer_details.template_name === 'first-time' ? (
            <FirstTimeOffer
              setFieldValue={setFieldValue}
              auto_redeem={values.offer_details.auto_redeem}
              errors={errors}
              touched={touched}
            />
          ) : values.offer_details.template_name === 'free-item' ? (
            <FreeItemOffer
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              conditions={values.conditions}
              auto_redeem={values.offer_details.auto_redeem}
              reusable={values.offer_details.reusable}
              apply_multiple={
                values.offer_details.template_values.apply_multiple
              }
              expiry_date={values.offer_details.template_values.expiry_date}
              errors={errors}
              touched={touched}
            />
          ) : values.offer_details.template_name === 'discounted-item' ? (
            <DiscountOffer
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              conditions={values.conditions}
              auto_redeem={values.offer_details.auto_redeem}
              reusable={values.offer_details.reusable}
              apply_multiple={
                values.offer_details.template_values.apply_multiple
              }
              expiry_date={values.offer_details.template_values.expiry_date}
              discountType={values.offer_details.template_values.discount_type}
              errors={errors}
              touched={touched}
            />
          ) : values.offer_details.template_name === 'birthday-free-item' ? (
            <BirthdayFreeItemOffer
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              conditions={values.conditions}
              auto_redeem={values.offer_details.auto_redeem}
              reusable={values.offer_details.reusable}
              apply_multiple={
                values.offer_details.template_values.apply_multiple
              }
              errors={errors}
              touched={touched}
              allProducts={allProducts}
            />
          ) : values.offer_details.template_name ===
            'birthday-discounted-item' ? (
            <BirthdayDiscountOffer
              templateName={values.offer_details.template_name}
              setFieldValue={setFieldValue}
              conditions={values.conditions}
              auto_redeem={values.offer_details.auto_redeem}
              reusable={values.offer_details.reusable}
              apply_multiple={
                values.offer_details.template_values.apply_multiple
              }
              discountType={values.offer_details.template_values.discount_type}
              errors={errors}
              touched={touched}
            />
          ) : values.offer_details.template_name === 'carryover-loyalty' ? (
            <CarryOverOffer
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
            ></CarryOverOffer>
          ) : null}
          <StickyButtons
            isVisible={dirty}
            handleDiscard={() => {
              handleReset();
              setIsFormReset(true);
            }}
            handleSave={() => handleSubmitOffer(values)}
            title='Offer Management'
            saveIsDisabled={
              Object.keys(errors).length ||
              !values.offer_details.image_url ||
              isRequesting
            }
          />
        </Card>
      )}
    </Formik>
  );
};

export default OfferDetails;
