import React from 'react';
import styled from 'styled-components';
import { Icon } from 'merchant-portal-components';
import CustomerNotes from './CustomerNotes';
import CustomerListTable from './CustomerListTable';
import Pagination from '../../../../components/Pagination/Pagination';
import { SearchInputBar } from 'merchant-portal-components';
import { ComponentCard } from 'merchant-portal-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const NonTableElements = styled.div`
  padding: 20px;
`;

const CustomerSearchWrapper = styled.div`
  padding: 40px 0 0 0;
  width: 100%;
`;

const StyledCard = styled(ComponentCard)`
  padding: 0 0 0 0;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;
const PaginationWrapper = styled.div`
  padding: 25px 37px;
  display: flex;
  justify-content: right;
`;
/**
 * Functional react component to list all customers
 * @param {Object} props - React props.
 */
const CustomerList = (props) => {
  const {
    getCustomersIsRequesting,
    customers,
    handleSearchInput,
    searchInput,
    meta,
    requestParams,
    updateRequestParams,
    handleEditIconClick,
    searchString,
    handleClearSearch,
  } = props;

  return (
    <StyledCard data-test="component-CustomerList">
      <NonTableElements>
        <CustomerNotes data-test="component-CustomerNotes" />
        <CustomerSearchWrapper>
          <TextField
            type="text"
            style={{ width: '100%' }}
            variant="outlined"
            value={searchString}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fal fa-search" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <Icon
                    className="fas fa-times-circle"
                    style={{ color: '#193053' }}
                    onClick={handleClearSearch}
                  />
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleSearchInput(e.target.value)}
            placeholder="Search Customers by name, email, phone number or company name"
          />
        </CustomerSearchWrapper>
      </NonTableElements>
      <CustomerListTable
        customers={customers}
        getCustomersIsRequesting={getCustomersIsRequesting}
        meta={meta}
        requestParams={requestParams}
        updateRequestParams={updateRequestParams}
        handleEditIconClick={handleEditIconClick}
      />
      {!getCustomersIsRequesting && customers?.length > 0 && (
        <PaginationWrapper>
          <Pagination
            data-test="component-Pagination"
            response={{
              page_number: meta.page_number,
              page_size: meta.page_size,
              count: 100000,
            }}
            requestParams={requestParams}
            updateRequestParams={updateRequestParams}
          />
        </PaginationWrapper>
      )}
    </StyledCard>
  );
};

export default CustomerList;
