export const LOAD_DETAIL_FEE_PAGE = 'LOAD_DETAIL_FEE_PAGE';
export const LOAD_LIST_FEE_PAGE = 'LOAD_LIST_FEE_PAGE';
export const GET_FEES_SUCCESS = 'GET_FEES_SUCCESS';
export const GET_SPECIFIC_FEE_SUCCESS = 'GET_SPECIFIC_FEE_SUCCESS';
export const CREATE_FEE_SUCCESS = 'CREATE_FEE_SUCCESS';
export const PATCH_ACTIVE_SERVICEFEE = 'PATCH_ACTIVE_SERVICEFEE';
export const EDIT_FEE_SUCCESS = 'EDIT_FEE_SUCCESS';
export const UPDATE_FEES_REQUEST_PARAMS = 'UPDATE_FEES_REQUEST_PARAMS';
export const GET_FEES_OVERRIDE_SUCCESS = 'GET_FEES_OVERRIDE_SUCCESS';
export const GET_SPECIFIC_OVERRIDE_SUCCESS = 'GET_SPECIFIC_OVERRIDE_SUCCESS';
export const EMPTY_SELECTED_OVERRIDE = 'EMPTY_SELECTED_OVERRIDE';
export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';
export const UPDATE_FEES_OVERRIDE_LIST = 'UPDATE_FEES_OVERRIDE_LIST';
