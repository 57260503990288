import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../Pagination/PaginationControl';
import { Button } from 'merchant-portal-components';
import { Checkbox, TextField, InputAdornment } from '@material-ui/core';
import { Dialog } from '@material-ui/core';
import {
  StyledCard,
  HeaderWrapper,
  SearchWrapper,
  SectionTitle,
  LocationsCounter,
  RowWrapper,
  TH,
  TD,
  PaginationWrapper,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  CloseIcon,
} from './filterModalStyles';
import * as actions from '../../modules/CustomerManager/CustomerList/customerListActions';
import { paginate } from './paginate';

const initiallRequestParams = {
  page_number: 1,
  page_size: 25,
  sorting_option: 'title-asc',
  search_string: '',
  has_next_page: true,
};
const FilterCustomerModal = ({
  allCustomers,
  requestParams,
  filteredCustomers,
  setFilteredCustomers,
  isOpen,
  setIsOpen,
  placeholder,
  searchLabel,
  formField,
  isEmptyAccepted,
  getCustomers,
  updateCustomersRequestParams,
}) => {
  const [searchString, setSearchString] = useState('');
  const [selectedCustomers, setSelectedCustomers] = useState(
    filteredCustomers || []
  );
  useEffect(() => {
    if (!allCustomers?.length > 0) {
      getCustomers();
    }
    updateCustomersRequestParams(initiallRequestParams);
    return () => {
      updateCustomersRequestParams(initiallRequestParams);
    };
  }, []);
  useEffect(() => {
    const modifiedRequestedParams = {
      ...requestParams,
      page_size: searchString ? '100' : '25',
    };
    modifiedRequestedParams.search_string = searchString;
    const timer = setTimeout(() => {
      getCustomers(modifiedRequestedParams);
    }, 1.5 * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchString]);
  const handleAssignCustomers = (e, id) => {
    let newSelectedCustomers = [...selectedCustomers];
    let isSelected = selectedCustomers.includes(id);
    if (isSelected) {
      newSelectedCustomers = selectedCustomers.filter(
        (currentId) => currentId !== id
      );
    } else {
      newSelectedCustomers.push(id);
    }
    setSelectedCustomers(newSelectedCustomers);
  };
  const handleAssignAllCustomers = (e) => {
    let isSelected = e.target.checked;
    let newSelectedCustomers = [];
    if (isSelected) {
      allCustomers.map((customer) => {
        newSelectedCustomers.push(customer.customer_id);
      });
    }
    setSelectedCustomers(newSelectedCustomers);
  };
  const handleAddCustomers = () => {
    const isAllCustomerSelected =
      allCustomers.length === selectedCustomers.length;
    let newSelectedCustomers =
      isAllCustomerSelected && isEmptyAccepted ? [] : selectedCustomers;
    if (formField) {
      setFilteredCustomers(formField, newSelectedCustomers);
    } else {
      setFilteredCustomers(newSelectedCustomers);
    }
    setIsOpen(false);
  };
  const handleReset = () => {
    if (filteredCustomers.length === 0 && !isEmptyAccepted) {
      const newAllCustomers = allCustomers.map((customer) => customer.id);
      setSelectedCustomers(newAllCustomers.getCustomers());
    } else {
      setSelectedCustomers(filteredCustomers?.sort());
    }
  };
  const isSaveDisabled = () => {
    let isDisabled = true;
    let isEqual = _.isEqual(
      filteredCustomers?.sort(),
      selectedCustomers?.sort()
    );

    if (allCustomers) {
      if (isEmptyAccepted && !isEqual) {
        isDisabled = false;
      } else if (
        !isEmptyAccepted &&
        selectedCustomers.length !== 0 &&
        !isEqual
      ) {
        isDisabled = false;
      }
    }
    return isDisabled;
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth='md'>
      <StyledCard>
        <HeaderWrapper padding='0'>
          <div>
            <SectionTitle>Customers</SectionTitle>
            <LocationsCounter>
              {`${selectedCustomers.length} customers selected`}
            </LocationsCounter>
          </div>
          <div style={{ textAlign: 'right' }}>
            <CloseIcon
              className='fa fa-times'
              onClick={() => setIsOpen(false)}
            ></CloseIcon>
          </div>
        </HeaderWrapper>
        <SearchWrapper>
          <TextField
            id='outlined-basic'
            label={searchLabel}
            placeholder={placeholder}
            variant='outlined'
            style={{ width: '100%' }}
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <i className='fa fa-search' style={{ color: '#b5b5b5' }}></i>
                </InputAdornment>
              ),
            }}
          />
        </SearchWrapper>
        <RowWrapper gridColumn='5% 350px auto'>
          <TH>
            {searchString.length > 2 && (
              <Checkbox
                checked={allCustomers.length === selectedCustomers.length}
                onChange={handleAssignAllCustomers}
                color='primary'
                style={{
                  transform: 'scale(1.3)',
                }}
              />
            )}
          </TH>
          <TH>{searchString.length > 2 ? 'All Customers' : 'Full Name'}</TH>
          <TH>Email</TH>
        </RowWrapper>
        {allCustomers.map((customer, index) => (
          <RowWrapper
            key={index}
            hoverBGColor='#f1f1fa'
            onClick={(e) => handleAssignCustomers(e, customer.customer_id)}
            gridColumn='5% 350px auto'
          >
            <TD bgColor='yellow'>
              <Checkbox
                checked={selectedCustomers.includes(customer.customer_id)}
                onChange={(e) => handleAssignCustomers(e, customer.customer_id)}
                color='primary'
                style={{
                  transform: 'scale(1.3)',
                }}
              />
            </TD>
            <TD
              isAssigned={selectedCustomers.includes(customer.customer_id)}
              onClick={(e) => handleAssignCustomers(e, customer.customer_id)}
            >
              {`${customer.first_name} ${customer.last_name}`}
            </TD>
            <TD
              isAssigned={selectedCustomers.includes(customer.customer_id)}
              onClick={(e) => handleAssignCustomers(e, customer.customer_id)}
            >
              {`${customer.email}`}
            </TD>
          </RowWrapper>
        ))}
        <PaginationWrapper>
          <PaginationControl
            response={{
              ...allCustomers,
              page: requestParams.page_number,
              page_size: requestParams.page_size,
              count: allCustomers.length,
            }}
            requestParams={requestParams}
            updateAction={getCustomers}
            type='customers'
          />
        </PaginationWrapper>
        <ButtonWrapper>
          <Left>
            <CancelButton onClick={handleReset}>Cancel</CancelButton>
          </Left>
          <Right>
            <Button onClick={handleAddCustomers} disabled={isSaveDisabled()}>
              Save
            </Button>
          </Right>
        </ButtonWrapper>
      </StyledCard>
    </Dialog>
  );
};
function mapStateToProps(state) {
  return {
    allCustomers: state.customerListReducer.customers,
    requestParams: state.customerListReducer.requestParams,
  };
}
export default connect(mapStateToProps, actions)(FilterCustomerModal);
