import { useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import {
  firebaseDevelopmentConfigs,
  firebaseStagingConfigs,
  firebaseProductionConfigs,
  firebaseSandboxConfigs,
} from './firebaseConfigs';

export const useFirebase = () => {
  const getFirebaseApp = useCallback((appIdentifier) => {
    const firebaseConfig =
      ENV === 'development'
        ? firebaseDevelopmentConfigs[appIdentifier]
        : ENV === 'staging'
        ? firebaseStagingConfigs[appIdentifier]
        : ENV === 'sandbox'
        ? firebaseSandboxConfigs[appIdentifier]
        : firebaseProductionConfigs[appIdentifier];

    const secondDatabaseName =
      ENV === 'development'
        ? 'develop-orders-a6c93'
        : ENV === 'staging'
        ? 'test-firebase-php-86f70'
        : ENV === 'sandbox'
        ? 'sandbox-orders-tracker'
        : 'smoothcommerce-orders-tracker';
    let app;
    try {
      app = firebase.app(secondDatabaseName);
    } catch (error) {
      app = firebase.initializeApp(firebaseConfig, secondDatabaseName);
    }

    const firebaseToken = localStorage.getItem('firebase_token');
    if (firebaseToken) {
      app
        .auth()
        .signInWithCustomToken(firebaseToken)
        .then(() => {
          console.log('successful firebase sign in');
        })
        .catch((error) => {
          console.log('firebase token error:', error.message);
        });
    }
    return app;
  }, []);
  return getFirebaseApp;
};
