import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../productsActions';
import ProductArchiveForm from '../components/ProductArchiveForm';

const ProductArchiveContainer = ({
    productId,
    archiveProduct,
    unArchiveProduct,
    getArchivedProducts,
    archivedProducts,

}) => {

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (productId) {

        }
    }, [productId]);

    useEffect(() => {
    }, []);


    return (
        <>
            <ProductArchiveForm
                archivedProducts={archivedProducts}
                archiveProduct={archiveProduct}
                unArchiveProduct={unArchiveProduct}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                disabled={!productId}
                productId={productId}
                getArchivedProducts={getArchivedProducts}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    productId: state.productsReducer.productId,
    archivedProducts: state.productsReducer.archivedProducts,

});

export default connect(
    mapStateToProps,
    actions
)(ProductArchiveContainer);
