import React, {Component} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan
} from '../../components/DashboardElements';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

export default class OrderTypes extends Component {
  render() {
    const {delivery, drop_off, pickup, scan_and_go} = this.props.data;

    var deliveryData = delivery
    var dropoffData = drop_off
    var pickupData = pickup
    var scanData = scan_and_go

    if (Number.isNaN(deliveryData)) deliveryData = 0;
    if (Number.isNaN(dropoffData)) dropoffData = 0;
    if (Number.isNaN(pickupData)) pickupData = 0;
    if (Number.isNaN(pickupData)) scanData = 0;

    const data = {
      labels: ['Delivery', 'Drop Off', 'Pickup', 'Scan & go'],
      datasets: [
        {
          data: [deliveryData, dropoffData, pickupData, scanData],
          //data: [0,0],
          backgroundColor: ['#296bde', '#00a5db', '#000000', '#a2bae3'],
          hoverBackgroundColor: ['#296bde', '#00a5db', '#000000', '#a2bae3']
        }
      ]
    };

    return (
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>
              Order Types {' '}
              <Tooltip title="The rate of different order types used" placement="top" arrow>
                <span> <TooltipIcon/> </span>
              </Tooltip>
            </TitleSpan> <br />
          </Title>
        </TitleWrapper>
        <ChartWrapper>
          <ChartLegend>
            <div>
            {delivery &&
              <div>
                <svg height="25" width="37.7">
                  <line
                    x1="0"
                    y1="25"
                    x2="37.7"
                    y2="25"
                    style={{stroke: '#296bde', strokeWidth: '27'}}
                  />
                </svg>{' '}
                <span> Delivery </span>
              </div>
              }
              {drop_off &&
              <div>
                <svg height="25" width="37.7">
                  <line
                    x1="0"
                    y1="25"
                    x2="37.7"
                    y2="25"
                    style={{stroke: '#00a5db', strokeWidth: '27'}}
                  />
                </svg>{' '}
                <span> Drop Off </span>
              </div>
              }
              {pickup &&
              <div>
                <svg height="25" width="37.7">
                  <line
                    x1="0"
                    y1="25"
                    x2="37.7"
                    y2="25"
                    style={{stroke: '#000000', strokeWidth: '27'}}
                  />
                </svg>{' '}
                <span> Pickup </span>
              </div>
              }
              {scan_and_go &&
              <div>
                <svg height="25" width="37.7">
                  <line
                    x1="0"
                    y1="25"
                    x2="37.7"
                    y2="25"
                    style={{stroke: '#a2bae3', strokeWidth: '27'}}
                  />
                </svg>{' '}
                <span> Scan and go </span>
              </div>
              }
            </div>
            <div>
              {delivery ? <div> Delivery </div>: null }
              {delivery ? <TitleSpan>{delivery ? delivery.toLocaleString() : "--"}</TitleSpan>: null }
              {drop_off ? <div> Drop Off </div>: null }
              {drop_off ? <TitleSpan>{drop_off ? drop_off.toLocaleString() : "--"}</TitleSpan>: null }
              {pickup ? <div> Pickup </div>: null }
              {pickup ? <TitleSpan>{pickup ? pickup.toLocaleString() : "--"}</TitleSpan>: null }
              {scan_and_go ? <div> Scan and go </div>: null }
              {scan_and_go ? <TitleSpan>{scan_and_go ? scan_and_go.toLocaleString() : "--"}</TitleSpan>: null }
              
            </div> 
          </ChartLegend>
          <div style={{minWidth: '100%', alignSelf: 'center'}}>
            <Doughnut
              data={data}
              options={{
                legend: {
                  display: false
                },
                pieceLabel: {
                  render: 'percentage',
                  fontColor: '#000',
                  position: 'outside'
                },
                tooltips: {
                  custom: tooltipModel => {
                    tooltipModel.opacity = 0;
                    return;
                  }
                }
              }}
            />
          </div>
        </ChartWrapper>
      </ChartCard>
    );
  }
}

const ChartWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 10fr;
  height: 90%;
`;
const ChartLegend = styled.div`
  color: ${props => props.theme.darkBlue};
  font-size: 12px;
  align-items: center;
  grid-template-rows: 3fr 5fr;
  display: grid;
`;

const LegendWrapper = styled.div`
  align-items: center;
  grid-template-rows: 3fr 5fr;
  display: grid;
`;
