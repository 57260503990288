import React, { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import {
  FilterWrapper,
  FieldsRowWrapper,
  FieldsRow,
  FieldsWrapper,
  ButtonWrapper,
  FilterButton,
  AddFilterButton,
  DeleteFilterIcon,
} from './styles/orderHistoryListStyles';
import FilterSelect from './FilterSelect';
import FilterValue from './FilterValue';
const initialParams = {
  customer_id: '',
  order_id: '',
  business_id: '',
  order_date: '',
  order_type: '',
  status: '',
  pos_order_id: '',
};
const initialAvailableFilters = [
  { isFilterActive: true, filterType: '', filterValue: '' },
  { isFilterActive: false, filterType: '', filterValue: '' },
  { isFilterActive: false, filterType: '', filterValue: '' },
  { isFilterActive: false, filterType: '', filterValue: '' },
  { isFilterActive: false, filterType: '', filterValue: '' },
  { isFilterActive: false, filterType: '', filterValue: '' },
  { isFilterActive: false, filterType: '', filterValue: '' },
];

const OrderHistoryFilter = ({ handleFilter }) => {
  const [params, setParams] = useState(initialParams);
  const [isAddFilterVisible, setIsAddFilterVisible] = useState(true);
  const [availableFilters, setAvailableFilters] = useState(
    initialAvailableFilters
  );
  const [availableFilterTypes, setAvailableFilterTypes] = useState([]);
  const [isFilterButtonDisabled, setIsFilterButtonDisabled] = useState(false);
  const [isFilterButtonDisplayed, setIsFilterButtonDisplayed] = useState(false);
  const handleAddFilter = () => {
    const modifiedAvailableFilters = [...availableFilters];
    const firstEmptyIndex = availableFilters.findIndex(
      (item) => !item.isFilterActive
    );
    if (firstEmptyIndex) {
      modifiedAvailableFilters[firstEmptyIndex].isFilterActive = true;
      setAvailableFilters(modifiedAvailableFilters);
    }
  };
  const handleRemoveFilter = (index) => {
    let modifiedAvailableFilters = [...availableFilters];
    modifiedAvailableFilters[index].filterType = '';
    modifiedAvailableFilters[index].filterValue = '';
    if (index !== 0) {
      modifiedAvailableFilters[index].isFilterActive = false;
    } else {
      const currentFiltered = modifiedAvailableFilters.filter(
        (item) => item.isFilterActive
      );
      if (currentFiltered?.length > 1) {
        modifiedAvailableFilters[index].isFilterActive = false;
      } else {
        handleFilter();
      }
    }
    const sortedAvailableFilters = _.orderBy(
      modifiedAvailableFilters,
      ['isFilterActive'],
      ['desc']
    );
    setAvailableFilters(sortedAvailableFilters);
  };
  const updateParams = useCallback(() => {
    // update available filter type after each selection
    const currentlist = [];
    const desiredlist = [];
    availableFilters.forEach((item) => {
      if (item.filterType) {
        currentlist.push(item.filterType);
      }
    });

    Object.keys(initialParams).forEach((item) => {
      if (!currentlist.includes(item)) {
        desiredlist.push(item);
      }
    });
    setAvailableFilterTypes(desiredlist);
    // update add Button visibility
    const filteredAvailables = availableFilters.filter(
      (item) => item.isFilterActive
    );
    const filteredTypes = availableFilters.filter((item) => item.filterType);
    const filteredValues = availableFilters.filter((item) => item.filterValue);
    if (filteredAvailables?.length === 7 || filteredTypes?.length === 0) {
      setIsAddFilterVisible(false);
    } else {
      setIsAddFilterVisible(true);
    }
    if (filteredTypes?.length === 0) {
      setIsFilterButtonDisplayed(false);
    } else {
      setIsFilterButtonDisplayed(true);
    }
    if (
      filteredValues.length > 0 &&
      filteredTypes.length === filteredValues.length
    ) {
      setIsFilterButtonDisabled(false);
    } else {
      setIsFilterButtonDisabled(true);
    }
  }, [availableFilters]);
  useEffect(() => {
    updateParams();
  }, [updateParams]);

  const handleUpdateFilterType = (filterType, filterIndex) => {
    let modifiedAvailableFilters = [...availableFilters];
    modifiedAvailableFilters[filterIndex].filterType = filterType;
    setAvailableFilters(modifiedAvailableFilters);
  };
  const handleUpdateFilterValue = (filterType, filterValue, filterIndex) => {
    let modifiedAvailableFilters = [...availableFilters];
    modifiedAvailableFilters[filterIndex].filterType = filterType;
    modifiedAvailableFilters[filterIndex].filterValue = filterValue;
    setAvailableFilters(modifiedAvailableFilters);
  };
  const handleApplyFilter = () => {
    let filterObject = {};
    availableFilters.forEach((filter) => {
      if (filter.filterType) {
        filterObject[filter.filterType] = filter.filterValue;
      }
    });
    handleFilter(filterObject);
  };
  return (
    <FilterWrapper>
      <FieldsRowWrapper>
        {availableFilters[0].isFilterActive && (
          <FieldsRow margin='0 0 16px 0'>
            <FilterSelect
              selectedFilterType={availableFilters[0].filterType}
              filterIndex={0}
              updateFilterType={handleUpdateFilterType}
              availableFilterTypes={availableFilterTypes}
            />
            <FilterValue
              selectedFilterType={availableFilters[0].filterType}
              selectedFilterValue={availableFilters[0].filterValue}
              filterIndex={0}
              updateFilterValue={handleUpdateFilterValue}
            />
            {availableFilters[0].filterType && (
              <DeleteFilterIcon
                className='fal fa-trash-alt'
                aria-hidden='true'
                onClick={() => handleRemoveFilter(0)}
              ></DeleteFilterIcon>
            )}
          </FieldsRow>
        )}
        {availableFilters[1].isFilterActive && (
          <FieldsRow margin='0 0 16px 0'>
            <FilterSelect
              selectedFilterType={availableFilters[1].filterType}
              filterIndex={1}
              updateFilterType={handleUpdateFilterType}
              availableFilterTypes={availableFilterTypes}
            />
            <FilterValue
              selectedFilterType={availableFilters[1].filterType}
              selectedFilterValue={availableFilters[1].filterValue}
              filterIndex={1}
              updateFilterValue={handleUpdateFilterValue}
            />
            <DeleteFilterIcon
              className='fal fa-trash-alt'
              aria-hidden='true'
              onClick={() => handleRemoveFilter(1)}
            ></DeleteFilterIcon>
          </FieldsRow>
        )}
        {availableFilters[2].isFilterActive && (
          <FieldsRow margin='0 0 16px 0'>
            <FilterSelect
              selectedFilterType={availableFilters[2].filterType}
              filterIndex={2}
              updateFilterType={handleUpdateFilterType}
              availableFilterTypes={availableFilterTypes}
            />
            <FilterValue
              selectedFilterType={availableFilters[2].filterType}
              selectedFilterValue={availableFilters[2].filterValue}
              filterIndex={2}
              updateFilterValue={handleUpdateFilterValue}
            />
            <DeleteFilterIcon
              className='fal fa-trash-alt'
              aria-hidden='true'
              onClick={() => handleRemoveFilter(2)}
            ></DeleteFilterIcon>
          </FieldsRow>
        )}
        {availableFilters[3].isFilterActive && (
          <FieldsRow margin='0 0 16px 0'>
            <FilterSelect
              selectedFilterType={availableFilters[3].filterType}
              filterIndex={3}
              updateFilterType={handleUpdateFilterType}
              availableFilterTypes={availableFilterTypes}
            />
            <FilterValue
              selectedFilterType={availableFilters[3].filterType}
              selectedFilterValue={availableFilters[3].filterValue}
              filterIndex={3}
              updateFilterValue={handleUpdateFilterValue}
            />
            <DeleteFilterIcon
              className='fal fa-trash-alt'
              aria-hidden='true'
              onClick={() => handleRemoveFilter(3)}
            ></DeleteFilterIcon>
          </FieldsRow>
        )}
        {availableFilters[4].isFilterActive && (
          <FieldsRow margin='0 0 16px 0'>
            <FilterSelect
              selectedFilterType={availableFilters[4].filterType}
              filterIndex={4}
              updateFilterType={handleUpdateFilterType}
              availableFilterTypes={availableFilterTypes}
            />
            <FilterValue
              selectedFilterType={availableFilters[4].filterType}
              selectedFilterValue={availableFilters[4].filterValue}
              filterIndex={4}
              updateFilterValue={handleUpdateFilterValue}
            />
            <DeleteFilterIcon
              className='fal fa-trash-alt'
              aria-hidden='true'
              onClick={() => handleRemoveFilter(4)}
            ></DeleteFilterIcon>
          </FieldsRow>
        )}
        {availableFilters[5].isFilterActive && (
          <FieldsRow margin='0 0 16px 0'>
            <FilterSelect
              selectedFilterType={availableFilters[5].filterType}
              filterIndex={5}
              updateFilterType={handleUpdateFilterType}
              availableFilterTypes={availableFilterTypes}
            />
            <FilterValue
              selectedFilterType={availableFilters[5].filterType}
              selectedFilterValue={availableFilters[5].filterValue}
              filterIndex={5}
              updateFilterValue={handleUpdateFilterValue}
            />
            <DeleteFilterIcon
              className='fal fa-trash-alt'
              aria-hidden='true'
              onClick={() => handleRemoveFilter(5)}
            ></DeleteFilterIcon>
          </FieldsRow>
        )}
        {availableFilters[6].isFilterActive && (
          <FieldsRow>
            <FilterSelect
              selectedFilterType={availableFilters[6].filterType}
              filterIndex={6}
              updateFilterType={handleUpdateFilterType}
              availableFilterTypes={availableFilterTypes}
            />
            <FilterValue
              selectedFilterType={availableFilters[6].filterType}
              selectedFilterValue={availableFilters[6].filterValue}
              filterIndex={6}
              updateFilterValue={handleUpdateFilterValue}
            />
            <DeleteFilterIcon
              className='fal fa-trash-alt'
              aria-hidden='true'
              onClick={() => handleRemoveFilter(6)}
            ></DeleteFilterIcon>
          </FieldsRow>
        )}
        {isAddFilterVisible && (
          <FieldsRow margin='15px 0 0 0'>
            <AddFilterButton onClick={handleAddFilter}>
              + Add Filter
            </AddFilterButton>
          </FieldsRow>
        )}
      </FieldsRowWrapper>
      <ButtonWrapper>
        {isFilterButtonDisplayed && (
          <FilterButton
            disabled={isFilterButtonDisabled}
            onClick={handleApplyFilter}
          >
            Apply Filter(s)
          </FilterButton>
        )}
      </ButtonWrapper>
    </FilterWrapper>
  );
};

export default OrderHistoryFilter;
