import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import * as businessActions from '../../../../LocationListManager/actions';
import LocationArchiveForm from '../components/LocationArchiveForm';

const LocationArchiveContainer = ({
  locationId,
  archiveBusiness,
  unArchiveBusiness,
  getArchivedBusinesses,
  archivedLocations,
  getBusinessLocations,
  isELigibleForArchiving,
  isBusinessAdminRole,
  isLoyaltyAdminRole,
  is_archived,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <LocationArchiveForm
        is_archived={is_archived}
        archivedLocations={archivedLocations}
        archiveLocation={archiveBusiness}
        unArchiveLocation={unArchiveBusiness}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        disabled={!locationId}
        locationId={locationId}
        getArchivedLocations={getArchivedBusinesses}
        isELigibleForArchiving={isELigibleForArchiving}
        getBusinessLocations={getBusinessLocations}
        isBusinessAdminRole={isBusinessAdminRole}
        isLoyaltyAdminRole={isLoyaltyAdminRole}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    locationId: state.businessLocationDetails.businessId,
    isELigibleForArchiving:
      !state.businessLocationDetails?.businessInformationInitialValues
        ?.master_is_active,
    archivedLocations: state.businessLocationDetails.archivedLocations,
  };
};

export default connect(mapStateToProps, {
  ...actions,
  ...businessActions,
})(LocationArchiveContainer);
