import styled from 'styled-components';
export const CardWrapper = styled.div`
  padding: 32px 8px;
  border-radius: 12px;
  background-color: #ffffff;
`;
export const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 170px;
  grid-gap: 25px;
  margin: 0 20px;
  margin-bottom: 15px;
`;
export const FieldsRowWrapper = styled.div`
  /* background-color: pink; */
`;
export const ButtonWrapper = styled.div``;
export const FilterButton = styled.button`
  font-family: Montserrat;
  padding: 12px 24px;
  font-size: 17px;
  font-weight: 600;
  border: none;
  border-radius: 31px;
  color: #ffffff;
  background-color: #2440ae;
  cursor: pointer;
  &:hover {
    background-color: #2f4fcc;
  }
  &:disabled {
    background-color: #969eaa;
  }
`;
export const FieldsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 16px;
  grid-gap: 15px;
  align-items: center;
  margin: ${(props) => props.margin || 0};
`;
export const FieldsWrapper = styled.div`
  /* background-color: pink; */
`;
export const AddFilterButton = styled.span`
  max-width: 105px;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 600;
  color: #2440ae;
  letter-spacing: 0.25px;
  cursor: pointer;
  &:hover {
    color: #2f4fcc;
  }
`;
export const DeleteFilterIcon = styled.i`
  font-size: 17px;
  cursor: pointer;
  color: #2440ae;
`;
export const NoDataFoundWrraper = styled.i`
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: 0.4px;
  text-align: left;
  color: #6f6f76;
`;
