import React, { Component } from 'react';
import * as actions from './actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Charts } from '../components/DashboardElements';
import { Button, LoadingBlueLarge } from 'merchant-portal-components';
import SummaryBar from './components/Summary';
import ActiveUsers from './components/ActiveUsers';
import UserSignUps from './components/UserSignUps';
import CumulativeUserSignUps from './components/CumulativeUserSignUps';
import ActiveUserAge from './components/ActiveUserAge';
import ReferralStats from './components/ReferralStats';
import intervalOptions from '../utils/intervalOptions';
import ReactGA from 'react-ga';

import DashboardDateSelection from '../components/DashboardDateSelection';
import moment from 'moment';
const Separation = styled.div`
  border-top: 1px solid #c9cbcf;
  margin: 2em 0;
`;

const DateSelectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 380px 1fr auto;
  grid-gap: 20px;
  align-items: flex-end;
`;
const DateWrapper = styled.div`
  max-width: 380px;
  grid-column: 1;
`;
const ButtonWrapper = styled.div`
  grid-column: 3;
  margin-bottom: 5px;
`;
const PageTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  margin: 20px 0;
`;
const MessageWrapper = styled.div`
  font-size: 28px;
  font-weight: 600;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class UsersDashboard extends Component {
  state = {
    intervalTypeAndValue: intervalOptions[1],
    timePeriodType: 'fixed-period',
    startDate: null,
    endDate: null,
    hasError: false,
    message: '',
  };

  componentDidMount() {
    this.getAllUserDashboardData();
    track.page('dashboard-user');
    ReactGA.pageview(window.location.pathname, undefined, 'Users Summary');
  }

  componentDidUpdate(_, prevState) {
    if (prevState.timePeriodType !== this.state.timePeriodType) {
      this.props.resetUserDashboardData();
      if (this.state.timePeriodType === 'custom-date') {
        this.setState({
          message: 'Select the date range to view dashboard reports',
        });
      } else {
        this.setState({
          message: 'Select the interval to view dashboard reports',
        });
      }
    }

    if (
      prevState.timePeriodType !== this.state.timePeriodType ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.intervalTypeAndValue !== this.state.intervalTypeAndValue
    ) {
      if (this.state.timePeriodType === 'custom-date') {
        if (this.state.startDate && this.state.endDate) {
          this.setState({ hasError: false });
        } else {
          this.setState({ hasError: true });
        }
      }
      if (this.state.timePeriodType === 'fixed-period') {
        this.getAllUserDashboardData();
      }
    }
  }

  getAllUserDashboardData = () => {
    const { intervalTypeAndValue, startDate, endDate } = this.state;
    const payload =
      startDate && endDate
        ? {
            start_datetime: moment(startDate)
              .set({ hour: 0, minute: 0, second: 0 })
              .format('YYYY-MM-DD HH:mm:ss'),
            end_datetime: moment(endDate)
              .set({ hour: 23, minute: 59, second: 59 })
              .format('YYYY-MM-DD HH:mm:ss'),
          }
        : intervalTypeAndValue?.value;
    this.props.getSummary(payload);
    this.props.getActiveUsers(payload);
    this.props.getNewUsers(payload);
    this.props.getReferralStats(payload);
    this.props.getUserAge(payload);
    this.props.getCumulativeUsers(payload);
    this.setState({ hasError: false, message: '' });
  };

  handleDateError = (error) => {
    if (error || !(this.state.startDate && this.state.endDate)) {
      this.setState({ hasError: true });
    } else {
      this.setState({ hasError: false });
    }
  };

  render() {
    const {
      summaryData,
      activeUsersData,
      userSignUpsData,
      referralStatsData,
      usersAgeData,
      cumulativeUsersData,
      loading,
      innerLoading,
    } = this.props.usersDashboard;
    const { startDate, endDate, hasError, message, timePeriodType } =
      this.state;
    const role = localStorage.getItem('role');

    return (
      <div>
        <PageTitle>Users</PageTitle>
        <DateSelectionWrapper>
          <DateWrapper>
            <DashboardDateSelection
              timePeriodType={this.state.timePeriodType}
              setTimePeriodType={(newValue) =>
                this.setState({ timePeriodType: newValue })
              }
              startDate={startDate}
              endDate={endDate}
              setStartDate={(date) => this.setState({ startDate: date })}
              setEndDate={(date) => this.setState({ endDate: date })}
              intervalTypeAndValue={this.state.intervalTypeAndValue}
              setIntervalTypeAndValue={(newIntervalTypeAndValue) =>
                this.setState({ intervalTypeAndValue: newIntervalTypeAndValue })
              }
              handleDateError={this.handleDateError}
            />
          </DateWrapper>
          {timePeriodType !== 'fixed-period' && (
            <ButtonWrapper>
              <Button
                onClick={this.getAllUserDashboardData}
                disabled={hasError}
              >
                View Stats
              </Button>
            </ButtonWrapper>
          )}
        </DateSelectionWrapper>
        {message ? (
          <MessageWrapper>{message}</MessageWrapper>
        ) : (
          <>
            {summaryData && loading ? (
              <div>
                {summaryData ? (
                  <SummaryBar data={summaryData} />
                ) : (
                  <LoadingBlueLarge />
                )}

                <Charts>
                  {activeUsersData && innerLoading ? (
                    <ActiveUsers
                      activeUsersData={activeUsersData}
                      stateInterval={this.state.intervalTypeAndValue.value}
                      timePeriodType={this.state.timePeriodType}
                    />
                  ) : (
                    <LoadingBlueLarge />
                  )}
                  {userSignUpsData ? (
                    <UserSignUps
                      userSignUpsData={userSignUpsData}
                      stateInterval={this.state.intervalTypeAndValue.value}
                      timePeriodType={this.state.timePeriodType}
                    />
                  ) : (
                    <LoadingBlueLarge />
                  )}
                </Charts>

                <Charts>
                  {role !== 'ROLE_LOYALTY_ADMIN' && referralStatsData ? (
                    <ReferralStats referralStatsData={referralStatsData} />
                  ) : (
                    role !== 'ROLE_LOYALTY_ADMIN' && <LoadingBlueLarge />
                  )}
                  {usersAgeData ? (
                    <ActiveUserAge usersAgeData={usersAgeData} />
                  ) : (
                    <LoadingBlueLarge />
                  )}

                  {role == 'ROLE_LOYALTY_ADMIN' && cumulativeUsersData ? (
                    <CumulativeUserSignUps
                      cumulativeUsersData={cumulativeUsersData}
                    />
                  ) : (
                    role == 'ROLE_LOYALTY_ADMIN' && <LoadingBlueLarge />
                  )}
                </Charts>

                <Separation />

                {role !== 'ROLE_LOYALTY_ADMIN' && cumulativeUsersData ? (
                  <CumulativeUserSignUps
                    cumulativeUsersData={cumulativeUsersData}
                  />
                ) : (
                  role !== 'ROLE_LOYALTY_ADMIN' && <LoadingBlueLarge />
                )}
              </div>
            ) : (
              <LoadingBlueLarge />
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    usersDashboard: state.usersDashboard,
    usersDashboardForm: state.form.TimePeriod,
    token: state.accountReducer.token,
  };
}

export default connect(mapStateToProps, { ...actions })(UsersDashboard);
