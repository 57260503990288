import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AccountInformationContainer from '../containers/AccountInformationContainer';
import CustomerLoyaltyRewardsContainer from '../containers/CustomerLoyaltyRewardsContainer';
import CustomerCreditsContainer from '../containers/CustomerCreditsContainer';
import { Button } from 'merchant-portal-components';
import { ComponentCard } from 'merchant-portal-components';
import CustomerOrderHistory from './CustomerOrderHistory';
import actions from 'redux-form/lib/actions';
import { func } from 'prop-types';
import  "./CustomerDetails.css"

import CustomerAuditHistory from '../containers/CustomerAuditHistory';
import CustomerOfferCreditContainer from '../containers/CustomerOfferCreditContainer';
const ButtonWrapper = styled.div`
  margin: 50px 37px 0 37px;
`;

const CustomerDeletedWrapper = styled.div`
height: 74px;
  margin-bottom: 24px;
  padding: 13px 25px 13px 15px;
  background-color: #f9ecdb;
  display: grid;
  grid-template-columns: 32px 1fr auto; /* Updated grid template columns */
  align-items: center;
  color: #191818;
`;
const Customericon = styled.div`
  width: 19px;
  height: 16px;
  margin: 0 16px 32px 0;
  font-family: FontAwesome5Pro-Solid;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  text-align: center;
  color: #e89623;
`;
const Customerlabel = styled.div``;
const CustomerAccount = styled.div`
  width: 1182px;
  height: 18px;
  margin: 0 0 12px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--text-black);
`;
const CustomerDelete = styled.div`
  width: 1182px;
  height: 18px;
  margin: 12px 0 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
`;
const CustomerComponent = styled.div``;

let CustomerDetails = (props) => {
  const {
    customerId,
    handleBackButtonClick,
    loyaltyIsVisible,
    lockMessage,
    customerOrders,
    requestParams,
    getCustomerOrders,
    history,
    updateOrderId,
    updateCurrentPage,
    getCustomerAudit,
    customerAudits,
    patchCustomerRestore
  } = props;
  const [customerdelete, setCustomerdelete] = useState();
  const lockMessages = lockMessage?.lock_reason;
  function customerClickhandle(customerdel) {
    setCustomerdelete(customerdel);
  }
  useEffect(() => {
    getCustomerAudit(requestParams);
  }, []);
  return (
    <CustomerComponent>
      {customerdelete && (
        <CustomerDeletedWrapper>
          <Customericon>
            <i class='fas fa-exclamation-triangle' />
          </Customericon>
          <Customerlabel>
            <CustomerAccount>Customer account is deleted</CustomerAccount>
            <CustomerDelete>
              This customer has deleted their account so their information
              cannot be edited. 
            </CustomerDelete>
          </Customerlabel>
          <button onClick={()=>{
            patchCustomerRestore(customerId)
          }} className="restore-button">RESTORE ACCOUNT</button>
        </CustomerDeletedWrapper>
      )}
      {lockMessages && (
        <CustomerDeletedWrapper>
          <Customericon>
            <i class='fas fa-exclamation-triangle' />
          </Customericon>
          <Customerlabel>
            <CustomerAccount>System Deactivation</CustomerAccount>
            <CustomerDelete>{lockMessages}</CustomerDelete>
          </Customerlabel>
        </CustomerDeletedWrapper>
      )}
      <ComponentCard>
        <AccountInformationContainer
          customerId={customerId}
          customerClick={customerClickhandle}
        />
        <CustomerOrderHistory
          allOrders={customerOrders}
          requestParams={requestParams}
          getCustomerOrders={getCustomerOrders}
          history={history}
          updateOrderId={updateOrderId}
          updateCurrentPage={updateCurrentPage}
        />


        {loyaltyIsVisible ? (
          <CustomerLoyaltyRewardsContainer
            customerId={customerId}
            customerdeletedata={customerdelete}
          />
        ) : null}
        <CustomerCreditsContainer
          customerId={customerId}
          customerIsdelete={customerdelete}
        />
        <CustomerOfferCreditContainer />
        <CustomerAuditHistory
          customerAudits={customerAudits}
          updateCurrentPage={updateCurrentPage}
          requestParams={requestParams}
          getCustomerAudit={getCustomerAudit}
        />
        <ButtonWrapper>
          <Button secondary onClick={handleBackButtonClick}>
            Back
          </Button>
        </ButtonWrapper>
      </ComponentCard>
    </CustomerComponent>
  );
};

export default CustomerDetails;
