import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useRef,
  useMemo,
} from 'react';
import 'firebase/auth';
import firebase from 'firebase/app';
import { useFirebase } from '../Firebase/FirebaseRef';
import { connect } from 'react-redux';

import _ from 'lodash';
import { TabContext, TabPanel } from '@material-ui/lab';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  updateOrderId,
  updateCurrentPage,
  updateRedirectPage,
} from '../OrderManager/OrderHistoryList/actions';
import {
  Button,
  Tooltip,
  TextField,
  InputAdornment,
  Snackbar,
  IconButton,
} from '@material-ui/core';
import { LoadingBlueLarge } from '../../components/Loading/Loading';
import {
  Wrapper,
  NoAccessWrapper,
  TitleWrapper,
  PageTitle,
  BetaBadge,
  SearchWrapper,
  SearchButtonWrapper,
  ListWrapper,
  StatusWrapper,
  ToastWrapper,
  HeaderWrapper,
  CellWrapper,
  AngeledTabList,
  AngeledTab,
} from './styles/OrdersManagementContainerStyles';
import FilterOrdersModal from './components/FilterOrdersModal';
import FilterList from './components/FilterList';
import { formatErrorMessage } from '../../utils/formatErrorMessage';
import { isParentChildClient } from '../../utils/clientCustomizations';
const ActiveOrdersList = React.lazy(() =>
  import('./OrdersList/ActiveOrdersList')
);
const PastOrdersList = React.lazy(() => import('./OrdersList/PastOrdersList'));
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    // backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: '#2440ae',
    maxWidth: 'none',
  },
  searchBox: {
    maxHeight: '40px',
  },
  searchButton: {
    border: '1p solid blue',
    width: '250px',
    padding: '10px 26px',
    borderRadius: '28px',
    border: 'solid 2px #2440ae',
    color: '#2440ae',
    backgroundColor: 'white',
    fontSize: '17px',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#2440ae',
      color: 'white',
    },
  },
  snackbar: {
    opacity: '0.9',
    borderRadius: '8px',
  },
  loadButton: {
    backgroundColor: '#e6ebff',
    color: '#2440ae',
    height: '32px',
    marginBottom: '30px',
    width: '170px',
    borderRadius: '28px',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    textTransform: 'none',
    position: '-webkit-sticky',
    position: 'sticky',
    top: '60px',
    '&:hover': {
      backgroundColor: '#2440ae',
      color: 'white',
    },
    '&:disabled': {
      backgroundColor: '#eff2ff',
      color: '#9facdf',
    },
  },
}));

// const activeOrders = [0, 1];

const isFutureOrder = (order) => {
  const {
    is_future_order: isFutureOrder,
    estimated_time: estimatedTime,
    order_type: orderDate,
    status,
    business_name,
  } = order;
  let newIsFutureOrder = isFutureOrder && status === 0;
  const currentUTC = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  const estimatedTimeInMinutes = moment
    .duration(moment(estimatedTime, 'HH:mm:ss').format('HH:mm'))
    .asMinutes();
  const dateTimeToMoveToNew = moment(orderDate)
    .subtract(estimatedTimeInMinutes, 'minutes')
    .format('YYYY-MM-DD HH:mm:ss');
  if (isFutureOrder) {
    if (moment(currentUTC).diff(moment(dateTimeToMoveToNew), 'seconds') > 0) {
      newIsFutureOrder = false;
    }
  }
  return newIsFutureOrder;
};

const getExpiration = (
  minPrepTime,
  orderDate,
  isFutureOrder,
  id,
  orderType
) => {
  let isExpired = false;
  const currentUTC = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  if (isFutureOrder) {
    if (moment(currentUTC).diff(moment(orderDate), 'seconds') > 0) {
      isExpired = true;
    }
  } else {
    const prepTimeInMinutes = moment
      .duration(moment(minPrepTime, 'HH:mm:ss').format('HH:mm'))
      .asMinutes();
    let expirationTime = null;
    if (orderType === 'delivery') {
      expirationTime = moment(orderDate).format('YYYY-MM-DD HH:mm:ss');
    } else {
      expirationTime = moment(orderDate)
        .add(prepTimeInMinutes, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    isExpired = moment(currentUTC).isAfter(moment(expirationTime));
  }
  return isExpired;
};
const formatedTime = (time) => {
  if (time > 9) {
    return time;
  } else {
    return '0' + time;
  }
};
const getElapsedTime = (
  orderDate,
  isFutureOrder,
  estimatedTime,
  id,
  orderType
) => {
  let time = '';
  let duration = null;
  const currentUTC = moment().utc().format('YYYY-MM-DD HH:mm:ss');
  const estimatedTimeInMinutes = moment
    .duration(moment(estimatedTime, 'HH:mm:ss').format('HH:mm'))
    .asMinutes();
  if (isFutureOrder) {
    const dateTimeToStartTimer = moment(orderDate)
      .subtract(estimatedTimeInMinutes, 'minutes')
      .format('YYYY-MM-DD HH:mm:ss');
    duration = moment.duration(
      moment(currentUTC).diff(moment(dateTimeToStartTimer))
    );
  } else {
    if (orderType === 'delivery') {
      const dateTimeToStartTimer = moment(orderDate)
        .subtract(estimatedTimeInMinutes, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss');
      duration = moment.duration(
        moment(currentUTC).diff(moment(dateTimeToStartTimer))
      );
    } else {
      duration = moment.duration(moment(currentUTC).diff(moment(orderDate)));
    }
  }
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  if (days > 0) {
    time =
      days +
      ' (days) ' +
      formatedTime(hours) +
      ':' +
      formatedTime(minutes) +
      ':' +
      formatedTime(seconds);
  } else {
    time =
      formatedTime(hours) +
      ':' +
      formatedTime(minutes) +
      ':' +
      formatedTime(seconds);
  }
  return time;
};

const formatedOrders = (orders) => {
  let formatedOrdersLocal = [];
  if (orders === null) return [];
  for (const [orderKey, order] of Object.entries(orders)) {
    formatedOrdersLocal.push({
      business_id: order.business_id,
      status: order?.status,
      delay: order?.delay,
      min_prep_time: order.min_prep_time,
      estimated_time: order.estimated_time,
      is_future_order: isFutureOrder(order),
      future: order.is_future_order,
      order_created_at: moment(order.order_created_at),
      create_at: order.order_created_at,
      order_date: order.order_date,
      order_date_utc: order.order_date_utc,
      isExpired: getExpiration(
        order.min_prep_time,
        order.order_date_utc,
        order.is_future_order,
        order.order_id, // redundant
        order.order_type
      ),
      elapsed_time: getElapsedTime(
        order.order_date_utc,
        order.is_future_order,
        order.estimated_time,
        order.order_id, // redundant
        order.order_type
      ),
      order_type: order.order_type,
      order_id: orderKey,
      business_name: order.business_name,
      customer_name: order.customer_name,
      total: order.total,
    });
    // }
  }
  return formatedOrdersLocal;
};
const updateElapsedTimes = (orders) => {
  let formatedOrdersLocal = [];
  orders.forEach((order) => {
    formatedOrdersLocal.push({
      business_id: order.business_id,
      status: order?.status,
      delay: order?.delay,
      min_prep_time: order.min_prep_time,
      estimated_time: order.estimated_time,
      is_future_order: isFutureOrder(order),
      future: order.future,
      order_created_at: moment(order.order_created_at),
      create_at: order.order_created_at,
      order_date: order.order_date,
      isExpired: getExpiration(
        order.min_prep_time,
        order.order_date_utc,
        order.future,
        order.order_id, // redundant
        order.order_type
      ),
      elapsed_time: getElapsedTime(
        order.order_date_utc,
        order.future,
        order.estimated_time,
        order.order_id, // redundant,
        order.order_type
      ),
      order_type: order.order_type,
      order_id: order.order_id,
      business_name: order.business_name,
      customer_name: order.customer_name,
      total: order.total,
    });
  });
  return formatedOrdersLocal;
};
const initialFilters = {
  business_ids: [],
  isNew: false,
  isInProgress: false,
  isFuture: false,
  orderType: 'all',
};

const OrdersManagementContainer = ({
  updateOrderId,
  updateCurrentPage,
  updateRedirectPage,
  history,
  storeAppIdentifier,
}) => {
  const classes = useStyles();
  const getFirebaseApp = useFirebase();
  // Filter Orders Modal
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [searchString, setSearchString] = useState('');
  const [subBusinesses, setSubBusinesses] = useState([]);
  const [selectedParent, setSelectedParent] = useState([]);
  const [selectedChild, setSelectedChild] = useState([]);
  //
  const [latestSnapshot, setLatestSnapshot] = useState([]);
  const [newOrdersQty, setNewOrdersQty] = useState(null);
  const [previousSnapshot, setPreviousSnapshot] = useState([]);
  const [activeOrderList, setActiveOrderList] = useState([]);
  // const [pastOrdersList, setPastOrdersList] = useState([]);
  //
  const [currentTab, setCurrentTab] = useState('1');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [snackbarOrdersQty, setSnackbarOrdersQty] = useState('');
  //
  const timer = useRef(null);

  useEffect(() => {
    const appIdentifier = localStorage.getItem('appIdentifier');
    const firebaseApp = getFirebaseApp(appIdentifier);
    if (!firebaseApp) return;
    const db = firebase.database(firebaseApp);
    const ref = db.ref(`${appIdentifier}/active_orders`);
    // ref
    //   .once('value')
    //   .then((snapshot) => {})
    //   .catch((errors) => {
    //     window.location.reload();
    //   });
    ref.on('value', (snapshot) => {
      const value = snapshot.val();
      const formatedValues = formatedOrders(value);
      const orderedValue = _.orderBy(formatedValues, ['order_id'], ['desc']);
      setLatestSnapshot(orderedValue);
    });
  }, [getFirebaseApp]);

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const updateOrders = useCallback(() => {
    if (previousSnapshot.length > 0) {
      let currentOrderdValue = [];
      if (latestSnapshot.length > previousSnapshot.length) {
        const newOrdersQty = latestSnapshot.length - previousSnapshot.length;
        setNewOrdersQty(newOrdersQty);
        currentOrderdValue = latestSnapshot.splice(newOrdersQty);
      } else {
        setNewOrdersQty(null);
        currentOrderdValue = [...latestSnapshot];
      }
      setPreviousSnapshot(currentOrderdValue);
    } else {
      setPreviousSnapshot(latestSnapshot);
    }
  }, [latestSnapshot]);
  useEffect(() => {
    updateOrders();
  }, [updateOrders]);
  const getFilteredActiveOrderList = (activeOrdersListRefreshed) => {
    const currentOrderList = JSON.parse(
      JSON.stringify(activeOrdersListRefreshed)
    );
    let selectedChildIds = [];
    let filteredBusiness = [];
    if (filters.business_ids.length > 0) {
      selectedChildIds = filters.business_ids.map((child) =>
        isParentChildClient ? child.subBusinessId : child.id
      );
      filteredBusiness = currentOrderList.filter((order) => {
        return selectedChildIds.includes(order.business_id);
      });
    }
    const finalFilteredBusiness =
      filters.business_ids.length > 0 ? filteredBusiness : currentOrderList;
    let finalFilteredType = finalFilteredBusiness.filter((order) => {
      return filters.orderType === 'all'
        ? order
        : filters.orderType === 'pickup'
        ? order.order_type === 'pickup'
        : order.order_type === 'delivery';
    });
    const finalFilterStatus = finalFilteredType.filter((order) => {
      return (
        (order.status === 0 && filters.isNew && !order.is_future_order) ||
        (order.status === 1 && filters.isInProgress) ||
        (order.is_future_order && filters.isFuture)
      );
    });
    if (
      finalFilterStatus.length > 0 ||
      filters.isNew ||
      filters.isInProgress ||
      filters.isFuture
    ) {
      return finalFilterStatus;
    } else {
      if (finalFilteredType.length > 0) {
        return finalFilteredType;
      } else if (filters.business_ids.length > 0) {
        return filteredBusiness;
      } else {
        return currentOrderList;
      }
    }
  };
  const getSearchActiveOrderList = (activeOrdersListRefreshed) => {
    const currentOrderList = JSON.parse(
      JSON.stringify(activeOrdersListRefreshed)
    );
    const searchResult = currentOrderList.filter((order) => {
      return (
        order?.order_id?.includes(searchString) ||
        order?.business_name
          ?.toLowerCase()
          .includes(searchString?.toLowerCase()) ||
        order?.customer_name
          ?.toLowerCase()
          .includes(searchString?.toLowerCase())
      );
    });
    return searchResult;
  };
  const updateUI = useCallback(() => {
    if (previousSnapshot.length > 0) {
      setIsLoading(false);
      // const updatedActiveOrdersList = previousSnapshot?.filter((order) =>
      //   activeOrders.includes(order.status)
      // );
      // const updatedActiveOrdersList = [];
      // var i = 0,
      //   len = previousSnapshot?.length;
      // while (i < len) {
      //   // your code
      //   if (activeOrders.includes(previousSnapshot[i].status)) {
      //     updatedActiveOrdersList.push(previousSnapshot[i]);
      //   }
      //   i++;
      // }
      // // const updatedPastOrdersList = previousSnapshot?.filter(
      // //   (order) => !activeOrders.includes(order.status)
      // // );
      if (previousSnapshot?.length > 152) {
        setActiveOrderList(previousSnapshot.slice(0, 150));
      } else {
        setActiveOrderList(previousSnapshot);
      }

      // setPastOrdersList(updatedPastOrdersList);
      clearInterval(timer.current);
      timer.current = setInterval(() => {
        const updatedElapsedTimeOrders = updateElapsedTimes(previousSnapshot);
        // const activeOrdersListRefreshed = updatedElapsedTimeOrders?.filter(
        //   (order) => activeOrders.includes(order.status)
        // );
        // const pastOrdersListRefresh = updatedElapsedTimeOrders?.filter(
        //   (order) => !activeOrders.includes(order.status)
        // );
        const activeOrdersListRefreshed = [...updatedElapsedTimeOrders];
        const filteredRefreshedActiveOrderList = getFilteredActiveOrderList(
          activeOrdersListRefreshed
        );
        if (searchString) {
          const searchResult = getSearchActiveOrderList(
            filteredRefreshedActiveOrderList
          );
          if (searchResult?.length > 52) {
            setActiveOrderList(searchResult.slice(0, 150));
          } else {
            setActiveOrderList(searchResult);
          }
        } else {
          if (filteredRefreshedActiveOrderList?.length > 152) {
            setActiveOrderList(filteredRefreshedActiveOrderList.slice(0, 150));
          } else {
            setActiveOrderList(filteredRefreshedActiveOrderList);
          }
        }
        // setPastOrdersList(pastOrdersListRefresh);
      }, 1000);
    } else {
      setIsLoading(true);
    }
  }, [previousSnapshot, filters, searchString]);
  useEffect(() => {
    updateUI();
  }, [updateUI]);
  const handleLoadingOrders = () => {
    let newSnapshot = [...previousSnapshot];
    latestSnapshot.reverse().forEach((order) => {
      newSnapshot.unshift(order);
    });
    setPreviousSnapshot(newSnapshot);
    setSnackbarOrdersQty(newOrdersQty.toString());
    setOpenSnackbar(true);
    setNewOrdersQty(null);
  };
  const handleTabChange = (e) => {
    setCurrentTab(e.target.value.toString());
  };
  const handleHideSnackbar = () => {
    setSnackbarOrdersQty('');
    setOpenSnackbar(false);
  };
  return (
    <Wrapper>
      <TitleWrapper>
        <PageTitle>Order Management</PageTitle>
        <div style={{ lineHeight: '50px' }}>
          <Tooltip
            classes={classes.tooltip}
            title='This feature is still under beta testing. If you have any feedback please reach out to support@smooth.tech.'
            placement='right'
          >
            <BetaBadge>Beta</BetaBadge>
          </Tooltip>
        </div>
      </TitleWrapper>
      <TabContext value={currentTab}>
        <AngeledTabList>
          <AngeledTab
            value='1'
            isSelected={currentTab === '1' ? true : false}
            onClick={handleTabChange}
          >
            {`Active Orders (${activeOrderList?.length})`}
          </AngeledTab>
          {/* <AngeledTab
            value="2"
            isSelected={currentTab === '2' ? true : false}
            onClick={handleTabChange}
          >
            {'Past Orders'}
          </AngeledTab> */}
        </AngeledTabList>
        <TabPanel
          value='1'
          style={{
            backgroundColor: '#fff',
            borderStartEndRadius: '20px',
            borderEndEndRadius: '20px',
            borderEndStartRadius: '20px',
            paddingTop: '50px',
            paddingBottom: '50px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
          }}
        >
          <SearchWrapper>
            <TextField
              id='standard-start-adornment'
              className={classes.searchBox}
              variant='outlined'
              placeholder='Search by customer name, locations, and order number'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <i className='fal fa-search'></i>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchString(e.target.value)}
              value={searchString}
            />
            <Button
              className={classes.searchButton}
              variant='contained'
              color='primary'
              onClick={() => setOpenFilter(true)}
            >
              <i
                className='fal fa-filter'
                aria-hidden='true'
                style={{ marginRight: '10px' }}
              ></i>{' '}
              {'Filter orders'}
            </Button>
          </SearchWrapper>
          <FilterList
            initialFilters={initialFilters}
            filters={filters}
            setFilters={setFilters}
            setSelectedChild={setSelectedChild}
            setSelectedParent={setSelectedParent}
          />
          <>
            <Tooltip
              classes={classes.tooltip}
              title='Click to add latest orders to the table'
              placement='bottom'
            >
              <Button
                className={classes.loadButton}
                variant='contained'
                color='primary'
                disabled={!newOrdersQty}
                onClick={handleLoadingOrders}
              >
                <i className='fal fa-sync' style={{ marginRight: '10px' }}></i>
                {newOrdersQty ? `Load ${newOrdersQty} Orders` : `Load Orders`}
              </Button>
            </Tooltip>
            {isLoading ? (
              <>
                <ListWrapper>
                  <div>
                    <div
                      style={{
                        fontSize: '20px',
                        fontWeight: '600',
                      }}
                    >
                      {'Loading active orders. This may take a few seconds...'}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <LoadingBlueLarge height='0' />
                    </div>
                  </div>
                </ListWrapper>
              </>
            ) : (
              <Suspense
                fallback={<div style={{ color: 'red' }}>Loading ....</div>}
              >
                <ActiveOrdersList
                  orderList={activeOrderList}
                  updateOrderId={updateOrderId}
                  updateCurrentPage={updateCurrentPage}
                  history={history}
                  updateRedirectPage={updateRedirectPage}
                />
              </Suspense>
            )}
          </>
        </TabPanel>
        {/* <TabPanel
          value="2"
          style={{
            backgroundColor: '#fff',
            borderStartEndRadius: '20px',
            borderEndEndRadius: '20px',
            borderEndStartRadius: '20px',
            paddingTop: '50px',
            paddingBottom: '50px',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
          }}
        >
          <div>
            <HeaderWrapper tab={currentTab}>
              <CellWrapper isBold>
                <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              </CellWrapper>
              <CellWrapper isBold>Order Time</CellWrapper>
              <CellWrapper isBold>Order Type</CellWrapper>
              <CellWrapper isBold>Order ID</CellWrapper>
              <CellWrapper isBold>Location</CellWrapper>
              <CellWrapper isBold>Customer</CellWrapper>
              <CellWrapper isBold>Total</CellWrapper>
              <CellWrapper isBold>Status</CellWrapper>
            </HeaderWrapper>
            <Suspense fallback={<div>Loading...</div>}>
              <div style={{ minHeight: '500px', alignItems: 'center' }}>
                <PastOrdersList orderList={pastOrdersList} />
              </div>
            </Suspense>
          </div>
        </TabPanel> */}
      </TabContext>
      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={openSnackbar}
        onClose={handleHideSnackbar}
        message={`${snackbarOrdersQty} orders added`}
        autoHideDuration={6000}
        action={
          <React.Fragment>
            <IconButton
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleHideSnackbar}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </React.Fragment>
        }
        // key={snackbarState.vertical + snackbarState.horizontal}
      />
      {openFilter && (
        <FilterOrdersModal
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          initialFilters={initialFilters}
          filters={filters}
          setFilters={setFilters}
          subBusinesses={subBusinesses}
          setSubBusinesses={setSubBusinesses}
          selectedParent={selectedParent}
          setSelectedParent={setSelectedParent}
          selectedChild={selectedChild}
          setSelectedChild={setSelectedChild}
        />
      )}
    </Wrapper>
  );
};
const mapStateToProps = (state) => ({
  currentPage: state.orderHistory.currentPage,
  selectedOrderId: state.orderHistory.selectedOrderId,
  customers: state.customerListReducer.customers,
  storeAppIdentifier: state.accountReducer.appIdentifier,
});
export default connect(mapStateToProps, {
  updateOrderId,
  updateCurrentPage,
  updateRedirectPage,
})(OrdersManagementContainer);
