import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'merchant-portal-components';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
// import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { updateOrderStatus } from '../ordersManagementActions';
const Title = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  color: #191818;
`;
const ContentText = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  color: #191818;
  font-weight: ${(props) => (props.bold ? 600 : 'normal')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)};
`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '24px',
    borderBottom: '1px solid #ebebeb',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fal fa-times"></i>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    minHeight: '100px',
    padding: '16px 25px',
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '16px 25px',
  },
}))(MuiDialogActions);
const RenderTitle = (modalType) => {
  let title = '';
  if (modalType === 'accept') {
    title = 'Accept Order';
  }
  if (modalType === 'accept-delay') {
    title = 'Accept Order With Delay';
  }
  if (modalType === 'ready') {
    title = 'Change to Ready for Pickup';
  }
  if (modalType === 'cancel') {
    title = 'Cancel Order';
  }
  return <Title>{title}</Title>;
};
const RenderBody = (
  modalType,
  delay,
  handleDelay,
  rejectMessage,
  handleRejectMessage,
  rejectDefaultMessage,
  handleRejectDefaultMessage
) => {
  if (modalType === 'accept') {
    return <ContentText>Accept this order on the store’s behalf?</ContentText>;
  }
  if (modalType === 'accept-delay') {
    return (
      <>
        <ContentText bold>Select an amount to delay the order by:</ContentText>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            // name="gender1"
            value={delay}
            onChange={handleDelay}
          >
            <FormControlLabel
              value="10"
              control={<Radio color="primary" />}
              label="10 minutes"
            />
            <FormControlLabel
              value="15"
              control={<Radio color="primary" />}
              label="15 minutes"
            />
            <FormControlLabel
              value="20"
              control={<Radio color="primary" />}
              label="20 minutes"
            />
          </RadioGroup>
        </FormControl>
      </>
    );
  }
  if (modalType === 'reject') {
    return (
      <>
        <ContentText bold>Select a rejection message:</ContentText>
        <FormControl component="fieldset">
          <RadioGroup
            // aria-label="gender"
            // name="gender1"
            value={rejectDefaultMessage}
            onChange={handleRejectDefaultMessage}
          >
            <FormControlLabel
              value="Sorry, an item you selected is currently unavailable."
              control={<Radio color="primary" />}
              label="Sorry, an item you selected is currently unavailable."
            />
            <FormControlLabel
              value="Due to larger order volume, we cannot accommodate your order."
              control={<Radio color="primary" />}
              label="Due to larger order volume, we cannot accommodate your order."
            />
          </RadioGroup>
        </FormControl>
        <ContentText bold marginBottom="20px">
          Or:
        </ContentText>
        <TextField
          id="standard-required"
          value={rejectMessage}
          variant="outlined"
          placeholder="Type custom message"
          fullWidth
          multiline
          rows={4}
          onChange={handleRejectMessage}
          inputProps={{
            maxLength: 100,
          }}
        />
        <div
          style={{
            width: '100%',
            fontSize: '12px',
            color: '#6f6f76',
            textAlign: 'right',
          }}
        >
          {rejectMessage.length}/100
        </div>
      </>
    );
  }
  if (modalType === 'ready') {
    return (
      <>
        <ContentText>
          Change this order’s status to Ready for Pickup on the store’s behalf?
        </ContentText>
      </>
    );
  }
  if (modalType === 'cancel') {
    return (
      <>
        <ContentText>Cancel this order on the store’s behalf?</ContentText>
        <ContentText>
          This will refund the order to the customer. They should see the refund
          in their account in a few days.
        </ContentText>
      </>
    );
  }
};
const saveButtonText = (modalType) => {
  if (modalType === 'accept') {
    return 'Yes, Accept';
  }
  if (modalType === 'accept-delay') {
    return 'Accept With Delay';
  }
  if (modalType === 'reject') {
    return 'Save';
  }
  if (modalType === 'ready') {
    return 'Yes, Change Status';
  }
  if (modalType === 'cancel') {
    return 'Yes, Cancel';
  }
};
function EditOrderStatusModal({
  selectedOrder,
  open,
  modalType,
  handleCloseStatusModal,
  updateOrderStatus,
}) {
  const [delay, setDelay] = useState();
  const [rejectMessage, setRejectMessage] = useState('');
  const [rejectDefaultMessage, setRejectDefaultMessage] = useState('');
  const handleSubmit = () => {
    if (modalType === 'accept') {
      updateOrderStatus(selectedOrder.order_id, 1, '');
    }
    if (modalType === 'accept-delay') {
      updateOrderStatus(
        selectedOrder.order_id,
        1,
        `There will be a delay of ${delay} minutes for this order`,
        parseInt(delay)
      );
    }
    if (modalType === 'reject') {
      updateOrderStatus(
        selectedOrder.order_id,
        -1,
        rejectDefaultMessage || rejectMessage
      );
    }
    if (modalType === 'ready') {
      updateOrderStatus(selectedOrder.order_id, 2, '');
    }
    if (modalType === 'cancel') {
      updateOrderStatus(selectedOrder.order_id, -1, '');
    }
    handleCloseStatusModal();
  };
  const handleDelay = (event) => {
    setDelay(event.target.value);
  };
  const handleRejectMessage = (event) => {
    setRejectDefaultMessage('');
    setRejectMessage(event.target.value);
  };
  const handleRejectDefaultMessage = (event) => {
    setRejectMessage('');
    setRejectDefaultMessage(event.target.value);
  };
  return (
    <div>
      <Dialog
        onClose={handleCloseStatusModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleCloseStatusModal}
        >
          {RenderTitle(modalType)}
        </DialogTitle>
        <DialogContent>
          {RenderBody(
            modalType,
            delay,
            handleDelay,
            rejectMessage,
            handleRejectMessage,
            rejectDefaultMessage,
            handleRejectDefaultMessage
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            disabled={
              (modalType === 'accept-delay' && !delay) ||
              (modalType === 'reject' &&
                !rejectMessage &&
                !rejectDefaultMessage)
            }
          >
            {saveButtonText(modalType)}{' '}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(_, { updateOrderStatus })(EditOrderStatusModal);
