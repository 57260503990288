import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { Icon } from 'merchant-portal-components';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const FieldWrappedDiv = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 70px 1fr;
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 12px 10px 12px 10px;
`;

const CategoryCardDiv = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  align-items: center;
`;

const HeaderDiv = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 70px auto 60px;
  padding: 0 10px 25px 10px;
  font-weight: bold;
  // border-top-left-radius: 12px;
  // border-top-right-radius: 12px;
`;

const StyledSortableContainer = styled.div`
  height: 100%;
  overflow-y: scroll; // need to be in container for auto scroll when sorting
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  padding: 24px;
`;

const styles = {
  switchBase: {
    transition: 'none',
  },
  switchBaseDefault: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const SortableItem = SortableElement(
  ({ category, categoryIndex, index, arrayHelpers, isSorting, classes }) => {
    return (
      <FieldWrappedDiv>
        <Icon
          className='far fa-equals'
          style={{ cursor: 'move', textAlign: 'center' }}
        />
        <CategoryCardDiv
          name={`categories[${categoryIndex}]`}
          style={{ cursor: 'pointer' }}
        >
          <div name={`categories[${categoryIndex}].name`}>{category.name}</div>
          <div style={{ textAlign: 'right' }}>
            <Switch
              checked={category.is_active}
              onClick={(e) => e.stopPropagation()}
              onChange={() => {
                arrayHelpers.form.setFieldValue(
                  `categories[${categoryIndex}].is_active`,
                  !category.is_active
                );
                arrayHelpers.form.setFieldValue(
                  `categories[${categoryIndex}].is_changed`,
                  true
                );
              }}
              classes={{
                switchBase: isSorting
                  ? classes.switchBase
                  : classes.switchBaseDefault,
              }}
              value='checkedB'
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              name={`categories[${categoryIndex}].is_active`}
            />
          </div>
        </CategoryCardDiv>
      </FieldWrappedDiv>
    );
  }
);

const SortableList = SortableContainer(
  ({ categories, arrayHelpers, isSorting, classes }) => {
    return (
      <StyledSortableContainer>
        <HeaderDiv>
          <div />
          <div>Name</div>
          <div style={{ textAlign: 'right' }}>Active</div>
        </HeaderDiv>
        {categories.map((category, index) => (
          <SortableItem
            key={`category-${index}`}
            index={index}
            isSorting={isSorting}
            categoryIndex={index}
            classes={classes}
            category={category}
            arrayHelpers={arrayHelpers}
          />
        ))}
      </StyledSortableContainer>
    );
  }
);

const RenderCategoriesList = ({ sortCategories, arrayHelpers, classes }) => {
  const [isSorting, setIsSorting] = useState(false);
  const categories = arrayHelpers.form.values.categories;
  const setFieldValue = arrayHelpers.form.setFieldValue;
  return (
    <Fragment>
      <SortableList
        helperClass='sortableHelper'
        classes={classes}
        categories={categories}
        sortCategories={sortCategories}
        arrayHelpers={arrayHelpers}
        isSorting={isSorting}
        onSortStart={() => setIsSorting(true)}
        onSortEnd={({ oldIndex, newIndex }) => {
          const newList = arrayMove(categories, oldIndex, newIndex);
          setFieldValue(`categories`, newList);
          setTimeout(() => setIsSorting(false), 250);
        }}
        distance={1}
      />
    </Fragment>
  );
};

export default withStyles(styles)(RenderCategoriesList);
