import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Collapse } from '@material-ui/core';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';

const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 28px;
  align-items: center;
`;

const NoOverridesText = styled.div`
margin-bottom:20px !important;
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;

`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;


let ComboArchiveForm = ({
    archiveCombo,
    unArchiveCombo,
    isExpanded,
    setIsExpanded,
    comboId,
    disabled,
    archivedCombos,
    getArchivedCombos
}) => {

    const [isArchived, setIsArchived] = useState(false);

    useEffect(() => {
        if (archivedCombos && Array.isArray(archivedCombos)) {
            const isComboArchived = archivedCombos.some(combo => combo.id === comboId);
            setIsArchived(isComboArchived);
        } else {
            console.error("Unexpected structure of archivedCombos:", archivedCombos);
        }
    }, [archivedCombos, comboId]);


    return (
        <div>
            <Card>
                <TitleAndCollapseButton>
                    <Title>Archive</Title>

                    {!isExpanded ? (
                        <ChevronDownIcon
                            disabled={disabled}
                            onClick={() => setIsExpanded(true)}
                        />
                    ) : (
                        <ChevronUpIcon
                            onClick={() => setIsExpanded(false)}
                            disabled={disabled}
                        />
                    )}
                </TitleAndCollapseButton>
                {!isArchived ?
                    <NoOverridesText>
                        Archive this combo to hide it from active/inactive listings. You can restore the combo at any time if needed
                    </NoOverridesText>
                    :
                    <NoOverridesText>
                        Unarchive this combo to add it to the active/inactive listings.
                    </NoOverridesText>
                }
                <Collapse in={isExpanded}>
                    <div>
                        <ToggleWrapper>
                            <SmallToggleSwitch
                                disabled={disabled}
                                checked={isArchived}
                                onChange={(e) => {

                                    const checked = e.target.checked;
                                    setIsArchived(checked);

                                    if (checked) {
                                        archiveCombo([comboId], () => {
                                        });
                                    } else {
                                        unArchiveCombo([comboId], () => {
                                            
                                        });
                                    }

                                }}
                            />
                           

                            {!isArchived ?
                                <div> Archive Combo</div>
                                :
                                <div> Unarchive Combo</div>
                            }
                        </ToggleWrapper>


                    </div>
                </Collapse>
            </Card>
        </div>
    );

};

export default ComboArchiveForm;
