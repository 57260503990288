import React, { useState, useEffect } from 'react';
import { Button } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';
import { ActionButtonWrapper } from './styles/styles';

export const OrderActionButtons = (props) => {
  const {
    handleVisibilityCancelOrderModal,
    handleVisibilitySendLoyaltyModal,
    refundedIsHidden,
    refundIsDisabled,
    orderDetails,
    handleResendReceiptEmail,
  } = props;
    const [isRefunded, setIsRefunded] = useState(false);
  useEffect(() => {
    if (orderDetails.status === 'refunded') {
      setIsRefunded(true);
    } else {
      setIsRefunded(false);
    }
  }, [orderDetails.status]);
  return (
    <ActionButtonWrapper>
      <div>
        {!refundedIsHidden && (
          <Tooltip title={refundIsDisabled.reason || ''}>
            <div>
              <Button
                onClick={() => {
                  refundIsDisabled.disabled
                    ? null
                    : handleVisibilityCancelOrderModal();
                }}
                softDisable={refundIsDisabled.disabled}
                disabled={isRefunded}
              >
                {isRefunded ? 'Refunded' : 'Refund Order'}
              </Button>
            </div>
          </Tooltip>
        )}
      </div>
      <Button onClick={handleResendReceiptEmail}>Resend Receipt</Button>
      <Button style={{cursor:orderDetails?.isGuestCustomer?'none':'pointer'}} disabled={orderDetails?.isGuestCustomer} onClick={() => handleVisibilitySendLoyaltyModal()}>
        Send Rewards
      </Button>
    </ActionButtonWrapper>
  );
};

export default OrderActionButtons;
