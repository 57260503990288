import React, { useState } from 'react';
import styled from 'styled-components';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button } from 'merchant-portal-components';
import { EditIcon, DeleteIcon } from '../../../../../../components/Icon';
import { Collapse } from '@material-ui/core';
import { LoadingBlueLarge } from 'merchant-portal-components';
import ComboOverridesModalContainer from '../containers/ComboOverridesModalContainer';
import DeleteCombosOverrideModal from './DeleteCombosOverrideModal';
const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  padding-top: 24px;
  padding-left: 48px;
  padding-right: 20px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ButtonWrapper = styled(Button)`
  margin-top: 24px;
  margin-bottom: 23px;
`;

const NoOverridesText = styled.p`
  font-style: italic;
  padding-bottom: 20px;
`;

const OverridesRow = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-gap: 50px;
`;

const OverridenPrice = styled.div`
  color: #191818;
  margin-top: 16px;
  font-size: 14px;
  letter-spacing: 0.01px;
`;

const EditIconWrapper = styled.div`
  color: #191818;
  font-weight: 600;
  font-size: 14px;
`;

const BusinessName = styled.span`
  color: #191818;
  font-weight: 600;
`;

let ComboBusinessesOverridesForm = ({
  isExpanded,
  setIsExpanded,
  disabled,
  summaryData,
  isOverrideModal,
  setIsOverrideModal,
  formattedBusinesses,
  combo,
  isCreate,
  setIsCreate,
  isSubmitting,
  comboOverrides, // delete func
  reassociateCombosOverrides, // delete func
}) => {
  const summaryDataModified = summaryData ? summaryData : null;
  const [openDeleteOverrideModal, setOpenDeleteOverrideModal] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState(null);
  const handleDeleteOverride = () => {
    const updatedComboOverrides = [];
    if ((selectedBusinesses, comboOverrides?.length > 0)) {
      comboOverrides.forEach((override) => {
        if (!selectedBusinesses.includes(override.business_id)) {
          updatedComboOverrides.push({
            business_id: override.business_id,
            fixed_price: override.fixed_price,
          });
        }
      });

      reassociateCombosOverrides(combo.id, updatedComboOverrides, true);
    }
  };
  const handleConfirmDeleteOverride = () => {
    handleDeleteOverride();
    setOpenDeleteOverrideModal(false);
  };
  const handleOpenDeleteOverrideModal = (businesses) => {
    setIsExpanded(true);
    const selectedBusinessOverrideIDs = businesses.map(
      (business) => business.id
    );
    setSelectedBusinesses(selectedBusinessOverrideIDs);
    setOpenDeleteOverrideModal(true);
  };
  const handleCloseDeleteOverrideModal = () => {
    setSelectedBusinesses(null);
    setOpenDeleteOverrideModal(false);
  };
  if ((isSubmitting || !summaryDataModified) && !disabled)
    return <LoadingBlueLarge />;

  return (
    <div>
      <Card
        disabled={disabled}
        // onClick={() => !disabled && setIsExpanded(!isExpanded)}
      >
        <TitleAndCollapseButton>
          <Title>Business Overrides</Title>
          {!isExpanded ? (
            <ChevronDownIcon
              disabled={disabled}
              onClick={() => setIsExpanded(true)}
            />
          ) : (
            <ChevronUpIcon
              onClick={() => setIsExpanded(false)}
              disabled={disabled}
            />
          )}
        </TitleAndCollapseButton>
        <Collapse in={isExpanded}>
          <div>
            <ButtonWrapper
              onClick={(e) => {
                e.stopPropagation();
                setIsOverrideModal({
                  isShowing: true,
                  price: null,
                  assignedBusinesses: [],
                });
                setIsCreate(true);
              }}
            >
              Add Override
            </ButtonWrapper>
            {!disabled ? (
              !summaryDataModified.length ? (
                <NoOverridesText>
                  There are no overrides for this combo
                </NoOverridesText>
              ) : (
                summaryDataModified.map((data, index) => {
                  return (
                    <OverridesRow key={index}>
                      <div>
                        {data.businesses.map((business, businessIndex) => {
                          return (
                            <BusinessName key={businessIndex}>
                              {business.name},{' '}
                            </BusinessName>
                          );
                        })}

                        <OverridenPrice>
                          Overriden price - ${data.fixed_price}{' '}
                        </OverridenPrice>
                      </div>
                      <div
                        style={{
                          textAlign: 'right',
                          fontWeight: 'bold',
                          color: '#191818',
                          fontSize: '15px',
                        }}
                      >
                        <span>Edit</span>
                        <span>
                          <EditIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsOverrideModal({
                                isShowing: true,
                                price: summaryDataModified[index].fixed_price,
                                assignedBusinesses:
                                  summaryDataModified[index].businesses,
                                initialBusinesses:
                                  summaryDataModified[index].businesses,
                                index: index,
                              });
                              setIsCreate(false);
                            }}
                            style={{ cursor: 'pointer', marginLeft: '5px' }}
                            type="fas"
                            size="fa-sm"
                          />
                        </span>
                        <span style={{ marginLeft: '20px' }}>Delete</span>
                        <span>
                          <DeleteIcon
                            onClick={() =>
                              handleOpenDeleteOverrideModal(
                                summaryDataModified[index].businesses
                              )
                            }
                            style={{ cursor: 'pointer', marginLeft: '5px' }}
                            type="fas"
                            size="fa-sm"
                          />
                        </span>
                      </div>
                    </OverridesRow>
                  );
                })
              )
            ) : null}
          </div>
        </Collapse>
      </Card>

      {isOverrideModal.isShowing && (
        <ComboOverridesModalContainer
          isOverrideModal={isOverrideModal}
          setIsOverrideModal={setIsOverrideModal}
          formattedBusinesses={formattedBusinesses}
          combo={combo}
          comboId={combo.id}
          summaryData={summaryData}
          isCreate={isCreate}
          disabled={!summaryDataModified.length}
        />
      )}
      {openDeleteOverrideModal && (
        <DeleteCombosOverrideModal
          open={openDeleteOverrideModal}
          onCancel={handleCloseDeleteOverrideModal}
          onConfirm={handleConfirmDeleteOverride}
        />
      )}
    </div>
  );
};

export default ComboBusinessesOverridesForm;
