import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Select } from 'merchant-portal-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  Divider,
  FormGroup,
  FormControlLabel,
  TextField,
  Switch,
  RadioGroup,
  Radio,
  Tooltip,
  Collapse,
  IconButton,
} from '@material-ui/core';
import {
  SectionTitle,
  ButtonWrapper,
  Left,
  Right,
  CancelButton,
  FieldLabel,
  CentralizedWrapper,
  CurrencyInputWrapper,
  CurrencyInput,
  Symbol,
} from './Styles/ServiceFeeDetailStyles';
const validationSchema = Yup.object().shape({
  config: Yup.object().shape({
    fee_amount: Yup.number().when('type', {
      is: 'percent',
      then: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .max(100, 'No more than 100')
        .required('Required'),
      otherwise: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .min(0)
        .required('Required'),
    }),
    min_fee_amount: Yup.number()
      .transform((value) => (!value ? null : value))
      .nullable(true),
    max_fee_amount: Yup.number()
      .transform((value) => (!value ? null : value))
      .nullable(true),
    threshold: Yup.number()
      .transform((value) => (!value ? null : value))
      .nullable(true),
    threshold_fee: Yup.number()
      .transform((value) => (!value ? null : value))
      .nullable(true),
  }),
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const ServiceFeeLocationOverrideDetail = (props, ref) => {
  const {
    open,
    onClose,
    editingFee,
    selectedBusinessLocationsOfMerchant,
    createFeeOverrides,
    selectedBusinessName,
    selectedFeeOverride,
    getAllOverrides,
  } = props;
  const initialActiveBusinesses = editingFee.assigned_businesses.active;
  const initialInactiveBusinesses = editingFee.assigned_businesses.inactive;
  const [assignedBusinessList, setAssignedBusinessList] = useState();
  const [selectedBusiness, setSelectedBusiness] = useState({
    id: '',
    name: '',
  });
  const serviceFeeId = editingFee.id;
  const handleClose = () => {
    onClose();
  };
  const updateAssignedBusinessList = useCallback(() => {
    let newAssignedBusinessList = [];
    selectedBusinessLocationsOfMerchant.map((business) => {
      if (
        initialActiveBusinesses.includes(business.id) ||
        initialInactiveBusinesses.includes(business.id)
      ) {
        newAssignedBusinessList.push({
          id: business.id,
          name: business.display_name,
        });
      }
    });
    setAssignedBusinessList(newAssignedBusinessList);
  }, [initialActiveBusinesses, initialInactiveBusinesses]);

  useEffect(() => {
    updateAssignedBusinessList();
  }, [updateAssignedBusinessList]);
  const selectBusiness = (selectedBusiness, setFieldValue) => {
    setFieldValue('selectedBusinessId', selectedBusiness?.id || '');
    if (selectedBusiness) {
      setSelectedBusiness(selectedBusiness);
    } else {
      setSelectedBusiness({
        id: '',
        name: '',
      });
    }
  };
  useEffect(() => {
    if (Object.keys(selectedFeeOverride).length !== 0) {
      setSelectedBusiness({
        id: selectedFeeOverride.business_id,
        name: selectedBusinessName,
      });
    } else {
      setSelectedBusiness({
        id: '',
        name: '',
      });
    }
  }, [selectedFeeOverride]);
  const [minFee, setMinFee] = useState(false);
  const [maxFee, setMaxFee] = useState(false);
  const [thresholdFee, setThresholdFee] = useState(false);
  //
  const [minDollarSign, setMinDollarSign] = useState(false);
  const [maxDollarSign, setMaxDollarSign] = useState(false);
  const [thresholdDollarSign, setThresholdDollarSign] = useState(false);
  const [thresholdFeeDollarSign, setThresholdFeeDollarSign] = useState(false);
  //
  const [minBorderColor, setMinBorderColor] = useState('#cdcdcd');
  const [maxBorderColor, setMaxBorderColor] = useState('#cdcdcd');
  const [thresholdBorderColor, setThresholdBorderColor] = useState('#cdcdcd');
  const [thresholdFeeBorderColor, setThresholdFeeBorderColor] =
    useState('#cdcdcd');
  const serviceFee = useMemo(() => {
    let initialServiceFee = {};
    initialServiceFee.selectedBusinessId =
      Object.keys(selectedFeeOverride).length !== 0
        ? selectedFeeOverride.business_id
        : '';
    initialServiceFee.config = {
      type:
        Object.keys(selectedFeeOverride).length !== 0
          ? selectedFeeOverride.config.type
          : '',
      fee_amount:
        Object.keys(selectedFeeOverride).length !== 0
          ? selectedFeeOverride.config.fee_amount
          : '',
      threshold:
        Object.keys(selectedFeeOverride).length !== 0
          ? selectedFeeOverride.config.threshold || ''
          : '',
      threshold_fee:
        Object.keys(selectedFeeOverride).length !== 0
          ? selectedFeeOverride.config.threshold_fee || ''
          : '',
      max_fee_amount:
        Object.keys(selectedFeeOverride).length !== 0
          ? selectedFeeOverride.config.max_fee_amount || ''
          : '',
      min_fee_amount:
        Object.keys(selectedFeeOverride).length !== 0
          ? selectedFeeOverride.config.min_fee_amount || ''
          : '',
      // waive_fee_amount:
      //   Object.keys(selectedFeeOverride).length !== 0
      //     ? selectedFeeOverride.config.waive_fee_amount
      //     : undefined,
    };
    return initialServiceFee;
  }, [selectedFeeOverride]);
  const resetToggles = useCallback(() => {
    if (
      serviceFee.config.min_fee_amount ||
      serviceFee.config.min_fee_amount === 0
    ) {
      setMinFee(true);
      setMinDollarSign(true);
      setMinBorderColor('black');
    } else {
      setMinFee(false);
      setMinBorderColor('#cdcdcd');
    }
    if (
      serviceFee.config.max_fee_amount ||
      serviceFee.config.max_fee_amount === 0
    ) {
      setMaxFee(true);
      setMaxDollarSign(true);
      setMaxBorderColor('black');
    } else {
      setMaxFee(false);
      setMaxBorderColor('#cdcdcd');
    }
    if (serviceFee.config.threshold || serviceFee.config.threshold === 0) {
      setThresholdFee(true);
      setThresholdDollarSign(true);
      setThresholdBorderColor('black');
    } else {
      setThresholdFee(false);
      setThresholdBorderColor('#cdcdcd');
    }
    if (
      serviceFee.config.threshold_fee ||
      serviceFee.config.threshold_fee === 0
    ) {
      setThresholdFee(true);
      setThresholdFeeDollarSign(true);
      setThresholdFeeBorderColor('black');
    } else {
      setThresholdFeeDollarSign(false);
      setThresholdFeeBorderColor('#cdcdcd');
    }
  }, [selectedFeeOverride]);
  useEffect(() => {
    resetToggles();
  }, [resetToggles]);
  const handleCapFocus = (fieldName, fieldValue) => {
    if (fieldName === 'config.min_fee_amount') {
      setMinDollarSign(true);
      setMinBorderColor('black');
    } else if (fieldName === 'config.max_fee_amount') {
      setMaxBorderColor('black');
      setMaxDollarSign(true);
    } else if (fieldName === 'config.threshold') {
      setThresholdDollarSign(true);
      setThresholdBorderColor('black');
    } else {
      setThresholdFeeDollarSign(true);
      setThresholdFeeBorderColor('black');
    }
  };
  const handleCapBlur = (fieldName, fieldValue) => {
    if (fieldName === 'config.min_fee_amount') {
      if (fieldValue) {
        setMinBorderColor('black');
        setMinDollarSign(true);
      } else {
        setMinDollarSign(false);
        setMinBorderColor('#cdcdcd');
      }
    } else if (fieldName === 'config.max_fee_amount') {
      if (fieldValue) {
        setMaxDollarSign(true);
        setMaxBorderColor('black');
      } else {
        setMaxDollarSign(false);
        setMaxBorderColor('#cdcdcd');
      }
    } else if (fieldName === 'config.threshold') {
      if (fieldValue) {
        setThresholdDollarSign(true);
        setThresholdBorderColor('black');
      } else {
        setThresholdDollarSign(false);
        setThresholdBorderColor('#cdcdcd');
      }
    } else {
      if (fieldValue) {
        setThresholdFeeDollarSign(true);
        setThresholdFeeBorderColor('black');
      } else {
        setThresholdFeeDollarSign(false);
        setThresholdFeeBorderColor('#cdcdcd');
      }
    }
  };
  const handleToggleConfigs = (
    checked,
    fieldName,
    fieldValue,
    setFieldValue,
    fieldName2 = null,
    fieldValue2 = null
  ) => {
    if (fieldName === 'config.min_fee_amount') {
      setMinFee(!checked);
      setFieldValue(fieldName, '');
      setMinDollarSign(false);
      setMinBorderColor('#cdcdcd');
    } else if (fieldName === 'config.max_fee_amount') {
      setMaxFee(!checked);
      setFieldValue(fieldName, '');
      setMaxDollarSign(false);
      setMaxBorderColor('#cdcdcd');
    } else {
      setThresholdFee(!checked);
      setFieldValue(fieldName, '');
      setFieldValue(fieldName2, '');
      setThresholdDollarSign(false);
      setThresholdFeeDollarSign(false);
      setThresholdBorderColor('#cdcdcd');
      setThresholdFeeBorderColor('#cdcdcd');
    }
  };
  const isFormDirty = (values) => {
    let newServiceFee = { ...serviceFee };
    return !_.isEqual(newServiceFee, values);
  };
  const handleResetForm = (setValues) => {
    let newServiceFee = { ...serviceFee };
    setValues(newServiceFee, false);
    resetToggles();
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth='md'
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby='alert-dialog-slide-description'
    >
      <Formik
        initialValues={serviceFee}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          let serviceFee = { ...values };
          if (
            (!serviceFee.config.min_fee_amount &&
              serviceFee.config.min_fee_amount !== 0) ||
            serviceFee.config.type !== 'percent'
          ) {
            delete serviceFee.config.min_fee_amount;
          }
          if (
            (!serviceFee.config.max_fee_amount &&
              serviceFee.config.max_fee_amount !== 0) ||
            serviceFee.config.type !== 'percent'
          ) {
            delete serviceFee.config.max_fee_amount;
          }
          if (
            !serviceFee.config.threshold &&
            serviceFee.config.threshold !== 0
          ) {
            delete serviceFee.config.threshold;
            delete serviceFee.config.threshold_fee;
          }
          if (
            !serviceFee.config.threshold_fee &&
            serviceFee.config.threshold_fee !== 0
          ) {
            delete serviceFee.config.threshold;
            delete serviceFee.config.threshold_fee;
          }
          try {
            createFeeOverrides(serviceFee, serviceFeeId);
            getAllOverrides(serviceFeeId);
            handleClose();
          } catch (error) {}
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <Form
            onSubmit={handleSubmit}
            onReset={() => handleResetForm(setValues)}
          >
            <DialogTitle style={{ alignItems: 'center' }}>
              <SectionTitle display='inline' fontWeight='200'>
                {selectedBusinessName ? 'Edit Override' : 'Overrides'}
              </SectionTitle>
              <i
                className='fa fa-times'
                style={{ fontWeight: '200', float: 'right', margin: '0' }}
                onClick={handleClose}
              ></i>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ minHeight: '500px' }}>
              <Select
                topMod='14px'
                light
                options={assignedBusinessList}
                getOptionValue={(business) => business.id}
                getOptionLabel={(business) => business.name}
                noOptionsMessage={'No results found'}
                isSearchable={true}
                isClearable={true}
                onChange={(selectedBusiness) =>
                  selectBusiness(selectedBusiness, setFieldValue)
                }
                value={selectedBusiness}
              />
              {errors.selectedBusinessId && touched.selectedBusinessId && (
                <div
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    marginTop: '3px',
                  }}
                >
                  {errors.selectedBusinessId}
                </div>
              )}
              {!selectedBusiness.id ? (
                <SectionTitle
                  fontWeight='200'
                  color='#959595'
                  textAlign='center'
                  margin='200px 0 200px 0'
                >
                  Select a location to add overrides
                </SectionTitle>
              ) : (
                <div>
                  <SectionTitle margin='30px 0 10px 3px'>
                    Service Fee Types
                  </SectionTitle>
                  <RadioGroup
                    aria-label='gender'
                    name='config.type'
                    value={values.config.type}
                    onChange={handleChange}
                  >
                    <div>
                      <FormControlLabel
                        value='percent'
                        control={<Radio color='primary' />}
                        label={<FieldLabel>Percentage of Subtotal</FieldLabel>}
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        value='fixed'
                        control={<Radio color='primary' />}
                        label={<FieldLabel>Fixed Dollar Amount</FieldLabel>}
                      />
                    </div>
                  </RadioGroup>
                  <Collapse in={values.config.type ? true : false}>
                    <TextField
                      id='outlined-name'
                      type='number'
                      name='config.fee_amount'
                      label={
                        values.config.type === 'percent'
                          ? 'Service Fee Percentage'
                          : 'Service Fee Amount'
                      }
                      value={values.config.fee_amount}
                      variant='outlined'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        startAdornment: (
                          <IconButton size='small'>
                            {values.config.type === 'percent' ? '%' : '$'}
                          </IconButton>
                        ),
                      }}
                      error={
                        errors.config?.fee_amount && touched.config?.fee_amount
                      }
                      style={{ width: '300px', marginTop: '16px' }}
                    />
                    {errors.config?.fee_amount &&
                      touched.config?.fee_amount && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '12px',
                            marginTop: '3px',
                          }}
                        >
                          {errors.config?.fee_amount}
                        </div>
                      )}
                    <SectionTitle margin='35px 0 0 0'>
                      Service Fee Cap
                    </SectionTitle>
                    <FormGroup>
                      {values.config?.type &&
                        values.config?.type === 'percent' && (
                          <>
                            <div>
                              <FormControlLabel
                                control={
                                  <Switch
                                    name='minFee'
                                    checked={minFee}
                                    onChange={() =>
                                      handleToggleConfigs(
                                        minFee,
                                        'config.min_fee_amount',
                                        values.config.min_fee_amount,
                                        setFieldValue
                                      )
                                    }
                                    color='primary'
                                  />
                                }
                                style={{ marginTop: '19px' }}
                                label={
                                  <FieldLabel>
                                    Minimum Service Fee
                                    <Tooltip
                                      style={{ marginRight: 'auto' }}
                                      title='The minimum $ amount to charge on an order regardless of your Service Fee percentage.'
                                      placement='right-start'
                                    >
                                      <i
                                        style={{ marginLeft: '5px' }}
                                        className='fal fa-info-circle'
                                      ></i>
                                    </Tooltip>
                                  </FieldLabel>
                                }
                              />
                            </div>
                            {minFee && (
                              <>
                                <CentralizedWrapper>
                                  <FieldLabel>
                                    The minimum Service Fee possible is
                                  </FieldLabel>
                                  <CurrencyInputWrapper
                                    width={170}
                                    borderColor={minBorderColor}
                                  >
                                    <Symbol>{minDollarSign && '$'}</Symbol>
                                    <CurrencyInput
                                      type='number'
                                      step='any'
                                      name='config.min_fee_amount'
                                      value={values.config.min_fee_amount}
                                      onFocus={() => {
                                        handleCapFocus(
                                          'config.min_fee_amount',
                                          values.config.min_fee_amount
                                        );
                                      }}
                                      onBlur={() =>
                                        handleCapBlur(
                                          'config.min_fee_amount',
                                          values.config.min_fee_amount
                                        )
                                      }
                                      placeholder={
                                        minDollarSign ? '' : 'e.g. $1.00'
                                      }
                                      onChange={handleChange}
                                    />
                                  </CurrencyInputWrapper>
                                  {errors.config?.min_fee_amount &&
                                    touched.config?.min_fee_amount && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '12px',
                                          marginTop: '3px',
                                        }}
                                      >
                                        {errors.config?.min_fee_amount}
                                      </div>
                                    )}
                                </CentralizedWrapper>
                              </>
                            )}
                            <div>
                              <FormControlLabel
                                control={
                                  <Switch
                                    name='maxFee'
                                    checked={maxFee}
                                    onChange={() =>
                                      handleToggleConfigs(
                                        maxFee,
                                        'config.max_fee_amount',
                                        values.config.max_fee_amount,
                                        setFieldValue
                                      )
                                    }
                                    color='primary'
                                  />
                                }
                                style={{ marginTop: '19px' }}
                                label={
                                  <FieldLabel>
                                    Maximum Service Fee
                                    <Tooltip
                                      style={{ marginRight: 'auto' }}
                                      title='The maximum $ amount to charge on an order regardless of your Service Fee percentage.'
                                      placement='right-start'
                                    >
                                      <i
                                        style={{ marginLeft: '5px' }}
                                        className='fal fa-info-circle'
                                      ></i>
                                    </Tooltip>
                                  </FieldLabel>
                                }
                              />
                            </div>
                            {maxFee && (
                              <CentralizedWrapper>
                                <FieldLabel>
                                  The maximum Service Fee possible is
                                </FieldLabel>
                                <div>
                                  <CurrencyInputWrapper
                                    width={170}
                                    borderColor={maxBorderColor}
                                  >
                                    <Symbol>
                                      {(maxDollarSign ||
                                        values.config.max_fee_amount) &&
                                        '$'}
                                    </Symbol>
                                    <CurrencyInput
                                      type='number'
                                      step='any'
                                      name='config.max_fee_amount'
                                      value={values.config.max_fee_amount}
                                      onFocus={() => {
                                        handleCapFocus(
                                          'config.max_fee_amount',
                                          values.config.max_fee_amount
                                        );
                                      }}
                                      onBlur={() =>
                                        handleCapBlur(
                                          'config.max_fee_amount',
                                          values.config.max_fee_amount
                                        )
                                      }
                                      placeholder={
                                        maxDollarSign ? '' : 'e.g. $10.00'
                                      }
                                      onChange={handleChange}
                                    />
                                  </CurrencyInputWrapper>

                                  {errors.config?.max_fee_amount &&
                                    touched.config?.max_fee_amount && (
                                      <div
                                        style={{
                                          color: 'red',
                                          fontSize: '12px',
                                          marginTop: '3px',
                                        }}
                                      >
                                        {errors.config?.max_fee_amount}
                                      </div>
                                    )}
                                </div>
                              </CentralizedWrapper>
                            )}
                          </>
                        )}
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              name='thresholdFee'
                              checked={thresholdFee}
                              onChange={() =>
                                handleToggleConfigs(
                                  thresholdFee,
                                  'config.threshold',
                                  values.config.threshold,
                                  setFieldValue,
                                  'config.threshold_fee',
                                  values.config.threshold_fee
                                )
                              }
                              color='primary'
                            />
                          }
                          style={{ marginTop: '19px' }}
                          label={
                            <FieldLabel>
                              Service Fee Threshold
                              <Tooltip
                                style={{ marginRight: 'auto' }}
                                title='The subtotal dollar limit in which a reduced Service Fee should be charged on an order. The reduced Service Fee should be equal to or less than the maximum Service Fee (if applicable).'
                                placement='right-start'
                              >
                                <i
                                  style={{ marginLeft: '5px' }}
                                  className='fal fa-info-circle'
                                ></i>
                              </Tooltip>
                            </FieldLabel>
                          }
                        />
                        {thresholdFee && (
                          <>
                            <CentralizedWrapper>
                              <FieldLabel>Any subtotal above </FieldLabel>
                              <div>
                                <CurrencyInputWrapper
                                  width={170}
                                  borderColor={thresholdBorderColor}
                                >
                                  {thresholdDollarSign ||
                                  values.config.threshold !== '' ? (
                                    <Symbol>{'$'}</Symbol>
                                  ) : (
                                    <span></span>
                                  )}
                                  <CurrencyInput
                                    type='number'
                                    step='any'
                                    name='config.threshold'
                                    value={values.config.threshold}
                                    onFocus={() => {
                                      handleCapFocus(
                                        'config.threshold',
                                        values.config.threshold
                                      );
                                    }}
                                    onBlur={() =>
                                      handleCapBlur(
                                        'config.threshold',
                                        values.config.threshold
                                      )
                                    }
                                    placeholder={
                                      thresholdDollarSign ? '' : 'e.g. $50.00'
                                    }
                                    onChange={handleChange}
                                  />
                                </CurrencyInputWrapper>
                                {errors.config?.threshold &&
                                  touched.config?.threshold && (
                                    <div
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '3px',
                                      }}
                                    >
                                      {errors.config?.threshold}
                                    </div>
                                  )}
                              </div>
                              <FieldLabel>
                                will have a Service Fee of{' '}
                              </FieldLabel>
                              <div>
                                <CurrencyInputWrapper
                                  width={170}
                                  borderColor={thresholdFeeBorderColor}
                                >
                                  {thresholdFeeDollarSign ||
                                  values.config.threshold_fee !== '' ? (
                                    <Symbol>{'%'}</Symbol>
                                  ) : (
                                    <span></span>
                                  )}
                                  <CurrencyInput
                                    type='number'
                                    step='any'
                                    name='config.threshold_fee'
                                    value={values.config.threshold_fee}
                                    onFocus={() => {
                                      handleCapFocus(
                                        'config.threshold_fee',
                                        values.config.threshold_fee
                                      );
                                    }}
                                    onBlur={() =>
                                      handleCapBlur(
                                        'config.threshold_fee',
                                        values.config.threshold_fee
                                      )
                                    }
                                    placeholder={
                                      thresholdFeeDollarSign ? '' : 'e.g. %10'
                                    }
                                    onChange={handleChange}
                                  />
                                </CurrencyInputWrapper>
                                {errors.config?.threshold_fee &&
                                  touched.config?.threshold_fee && (
                                    <div
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '3px',
                                      }}
                                    >
                                      {errors.config?.threshold_fee}
                                    </div>
                                  )}
                              </div>
                            </CentralizedWrapper>
                          </>
                        )}
                      </div>
                    </FormGroup>
                  </Collapse>
                </div>
              )}
            </DialogContent>
            <Divider />
            <ButtonWrapper padding='20px 30px' margin='0'>
              <Left>
                <CancelButton type='reset'>Cancel</CancelButton>
              </Left>
              <Right>
                <Button
                  type='submit'
                  disabled={
                    !(Object.keys(errors).length === 0 && isFormDirty(values))
                  }
                >
                  Save
                </Button>
              </Right>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default ServiceFeeLocationOverrideDetail;
