import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from 'merchant-portal-components';

const Wrapper = styled.div`
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  display: grid;
  grid-template-columns: 100px 1fr 50px;
  margin: 13px 0;
  padding: 20px;
  max-width: 1000px;
  cursor: ${(props) => (props.disabled ? 'pointer' : 'context-menu')};
`;

const TextDiv = styled.div``;

const Title = styled.p`
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: ${(props) => (props.disabled ? '#6f6f76' : '#191818')};
  margin-bottom: 11px;
`;

const Description = styled.p`
  font-size: 17px;
  letter-spacing: 0.02px;
  color: ${(props) => (props.disabled ? '#b7b7b7' : '#191818')};
`;

const IconWrapper = styled(Icon)`
  align-self: center;
  text-align: center;
  font-size: 30px;
  color: ${(props) => (props.disabled ? '#6f6f76' : '#191818')};
`;

const Card = ({
  logo,
  title,
  description,
  onArrowClick,
  disabled,
  onDisabledClick,
}) => {
  const handleDisabledClick = () => {
    if (disabled) {
      onDisabledClick();
    }
  };
  return (
    <Wrapper disabled={disabled} onClick={handleDisabledClick}>
      <img
        src={logo}
        width="50px"
        style={{ margin: '0 auto', alignSelf: 'center' }}
      />
      <TextDiv>
        <Title disabled={disabled}>{title}</Title>
        <Description disabled={disabled}>{description}</Description>
      </TextDiv>
      {disabled ? (
        <IconWrapper
          disabled={disabled}
          className="far fa-lock fa-lg fa-bold"
        />
      ) : (
        <IconWrapper className="fal fa-angle-right" onClick={onArrowClick} />
      )}
    </Wrapper>
  );
};

export default Card;
