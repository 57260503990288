import React, { useState, useEffect, useCallback } from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import {
  Wrapper,
  Title,
  CloseIcon,
  Divider,
  CopyButton,
  CopyButtonWrapper,
  DatePickerWrapper,
} from './styles/specialDayTimeSelectionModalStyles';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SpecialDayTimeCreatetab from './SpecialDayTimeCreateTab';
import {
  handleCloseLocation,
  handleCloseOrdering,
} from './utils/handleCloseLocation';
import {
  handleRemoveTime,
  handleRemoveOrderingTime,
} from './utils/handleRemoveTime';
import { timeErrors } from './utils/timeErrors';
import getFormatedSpecialHours from './utils/getFormatedSpecialHours';
import getCandidateCopyHours from './utils/getCandidateCopyHours';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1} style={{ backgroundColor: '#F1F6F5', minHeight: 440 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 62,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#2f4fcc',
      opacity: 1,
    },
    '&$selected': {
      color: '#2440ae',
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: '#F1F6F5',
    },
    '&:focus': {
      color: '#2f4fcc',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
const initialError = {
  openTimeMon1: '',
  closeTimeMon1: '',
  openTimeMon2: '',
  closeTimeMon2: '',
  openOrderingTimeMon1: '',
  closeOrderingTimeMon1: '',
  openOrderingTimeMon2: '',
  closeOrderingTimeMon2: '',
  // Tue
  openTimeTue1: '',
  closeTimeTue1: '',
  openTimeTue2: '',
  closeTimeTue2: '',
  openOrderingTimeTue1: '',
  closeOrderingTimeTue1: '',
  openOrderingTimeTue2: '',
  closeOrderingTimeTue2: '',
  // Wed
  openTimeWed1: '',
  closeTimeWed1: '',
  openTimeWed2: '',
  closeTimeWed2: '',
  openOrderingTimeWed1: '',
  closeOrderingTimeWed1: '',
  openOrderingTimeWed2: '',
  closeOrderingTimeWed2: '',
  // Thu
  openTimeThu1: '',
  closeTimeThu1: '',
  openTimeThu2: '',
  closeTimeThu2: '',
  openOrderingTimeThu1: '',
  closeOrderingTimeThu1: '',
  openOrderingTimeThu2: '',
  closeOrderingTimeThu2: '',
  // Fri
  openTimeFri1: '',
  closeTimeFri1: '',
  openTimeFri2: '',
  closeTimeFri2: '',
  openOrderingTimeFri1: '',
  closeOrderingTimeFri1: '',
  openOrderingTimeFri2: '',
  closeOrderingTimeFri2: '',
  // Sat
  openTimeSat1: '',
  closeTimeSat1: '',
  openTimeSat2: '',
  closeTimeSat2: '',
  openOrderingTimeSat1: '',
  closeOrderingTimeSat1: '',
  openOrderingTimeSat2: '',
  closeOrderingTimeSat2: '',
  // Sun
  openTimeSun1: '',
  closeTimeSun1: '',
  openTimeSun2: '',
  closeTimeSun2: '',
  openOrderingTimeSun1: '',
  closeOrderingTimeSun1: '',
  openOrderingTimeSun2: '',
  closeOrderingTimeSun2: '',
};
const SpecialDayTimeCreateModal = ({
  isOpen,
  setIsOpen,
  postSpecialHour,
  businessId,
}) => {
  //
  const classes = useStyles();
  const defaultOpenTime = moment('09:00', 'HH:mm');
  const defaultCloseTime = moment('17:00', 'HH:mm');
  const [selectedTab, setSelectedTab] = useState();
  const [availableTabs, setAvailableTabs] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [daysRange, setDaysRange] = useState([]);
  const [error, setError] = useState(initialError);
  // Copy Hours Modal
  const [copyLocationHours, setCopyLocationHours] = useState([]);
  const [copyOrderingHours, setCopyOrderingHours] = useState([]);
  const [copyModalType, setCopyModalType] = useState();
  // tab Mon
  const [openTimeMon1, setOpenTimeMon1] = useState();
  const [closeTimeMon1, setCloseTimeMon1] = useState();
  const [openTimeMon2, setOpenTimeMon2] = useState();
  const [closeTimeMon2, setCloseTimeMon2] = useState();
  const [openOrderingTimeMon1, setOpenOrderingTimeMon1] = useState();
  const [closeOrderingTimeMon1, setCloseOrderingTimeMon1] = useState();
  const [openOrderingTimeMon2, setOpenOrderingTimeMon2] = useState();
  const [closeOrderingTimeMon2, setCloseOrderingTimeMon2] = useState();
  const [isLocationClosedMon, setIsLocationClosedMon] = useState(true);
  const [isOrderingClosedMon, setIsOrderingClosedMon] = useState(true);
  const [isLocationHoursSplitedMon, setIsLocationHoursSplitedMon] =
    useState(false);
  const [isOrderingHoursSplitedMon, setIsOrderingHoursSplitedMon] =
    useState(false);
  // tab Tue
  const [openTimeTue1, setOpenTimeTue1] = useState();
  const [closeTimeTue1, setCloseTimeTue1] = useState();
  const [openTimeTue2, setOpenTimeTue2] = useState();
  const [closeTimeTue2, setCloseTimeTue2] = useState();
  const [openOrderingTimeTue1, setOpenOrderingTimeTue1] = useState();
  const [closeOrderingTimeTue1, setCloseOrderingTimeTue1] = useState();
  const [openOrderingTimeTue2, setOpenOrderingTimeTue2] = useState();
  const [closeOrderingTimeTue2, setCloseOrderingTimeTue2] = useState();
  const [isLocationClosedTue, setIsLocationClosedTue] = useState(true);
  const [isOrderingClosedTue, setIsOrderingClosedTue] = useState(true);
  const [isLocationHoursSplitedTue, setIsLocationHoursSplitedTue] =
    useState(false);
  const [isOrderingHoursSplitedTue, setIsOrderingHoursSplitedTue] =
    useState(false);
  // tab Wed
  const [openTimeWed1, setOpenTimeWed1] = useState();
  const [closeTimeWed1, setCloseTimeWed1] = useState();
  const [openTimeWed2, setOpenTimeWed2] = useState();
  const [closeTimeWed2, setCloseTimeWed2] = useState();
  const [openOrderingTimeWed1, setOpenOrderingTimeWed1] = useState();
  const [closeOrderingTimeWed1, setCloseOrderingTimeWed1] = useState();
  const [openOrderingTimeWed2, setOpenOrderingTimeWed2] = useState();
  const [closeOrderingTimeWed2, setCloseOrderingTimeWed2] = useState();
  const [isLocationClosedWed, setIsLocationClosedWed] = useState(true);
  const [isOrderingClosedWed, setIsOrderingClosedWed] = useState(true);
  const [isLocationHoursSplitedWed, setIsLocationHoursSplitedWed] =
    useState(false);
  const [isOrderingHoursSplitedWed, setIsOrderingHoursSplitedWed] =
    useState(false);
  // tab Thu
  const [openTimeThu1, setOpenTimeThu1] = useState();
  const [closeTimeThu1, setCloseTimeThu1] = useState();
  const [openTimeThu2, setOpenTimeThu2] = useState();
  const [closeTimeThu2, setCloseTimeThu2] = useState();
  const [openOrderingTimeThu1, setOpenOrderingTimeThu1] = useState();
  const [closeOrderingTimeThu1, setCloseOrderingTimeThu1] = useState();
  const [openOrderingTimeThu2, setOpenOrderingTimeThu2] = useState();
  const [closeOrderingTimeThu2, setCloseOrderingTimeThu2] = useState();
  const [isLocationClosedThu, setIsLocationClosedThu] = useState(true);
  const [isOrderingClosedThu, setIsOrderingClosedThu] = useState(true);
  const [isLocationHoursSplitedThu, setIsLocationHoursSplitedThu] =
    useState(false);
  const [isOrderingHoursSplitedThu, setIsOrderingHoursSplitedThu] =
    useState(false);
  // tab Fri
  const [openTimeFri1, setOpenTimeFri1] = useState();
  const [closeTimeFri1, setCloseTimeFri1] = useState();
  const [openTimeFri2, setOpenTimeFri2] = useState();
  const [closeTimeFri2, setCloseTimeFri2] = useState();
  const [openOrderingTimeFri1, setOpenOrderingTimeFri1] = useState();
  const [closeOrderingTimeFri1, setCloseOrderingTimeFri1] = useState();
  const [openOrderingTimeFri2, setOpenOrderingTimeFri2] = useState();
  const [closeOrderingTimeFri2, setCloseOrderingTimeFri2] = useState();
  const [isLocationClosedFri, setIsLocationClosedFri] = useState(true);
  const [isOrderingClosedFri, setIsOrderingClosedFri] = useState(true);
  const [isLocationHoursSplitedFri, setIsLocationHoursSplitedFri] =
    useState(false);
  const [isOrderingHoursSplitedFri, setIsOrderingHoursSplitedFri] =
    useState(false);
  // tab Sat
  const [openTimeSat1, setOpenTimeSat1] = useState();
  const [closeTimeSat1, setCloseTimeSat1] = useState();
  const [openTimeSat2, setOpenTimeSat2] = useState();
  const [closeTimeSat2, setCloseTimeSat2] = useState();
  const [openOrderingTimeSat1, setOpenOrderingTimeSat1] = useState();
  const [closeOrderingTimeSat1, setCloseOrderingTimeSat1] = useState();
  const [openOrderingTimeSat2, setOpenOrderingTimeSat2] = useState();
  const [closeOrderingTimeSat2, setCloseOrderingTimeSat2] = useState();
  const [isLocationClosedSat, setIsLocationClosedSat] = useState(true);
  const [isOrderingClosedSat, setIsOrderingClosedSat] = useState(true);
  const [isLocationHoursSplitedSat, setIsLocationHoursSplitedSat] =
    useState(false);
  const [isOrderingHoursSplitedSat, setIsOrderingHoursSplitedSat] =
    useState(false);
  // tab Sun
  const [openTimeSun1, setOpenTimeSun1] = useState();
  const [closeTimeSun1, setCloseTimeSun1] = useState();
  const [openTimeSun2, setOpenTimeSun2] = useState();
  const [closeTimeSun2, setCloseTimeSun2] = useState();
  const [openOrderingTimeSun1, setOpenOrderingTimeSun1] = useState();
  const [closeOrderingTimeSun1, setCloseOrderingTimeSun1] = useState();
  const [openOrderingTimeSun2, setOpenOrderingTimeSun2] = useState();
  const [closeOrderingTimeSun2, setCloseOrderingTimeSun2] = useState();
  const [isLocationClosedSun, setIsLocationClosedSun] = useState(true);
  const [isOrderingClosedSun, setIsOrderingClosedSun] = useState(true);
  const [isLocationHoursSplitedSun, setIsLocationHoursSplitedSun] =
    useState(false);
  const [isOrderingHoursSplitedSun, setIsOrderingHoursSplitedSun] =
    useState(false);
  ///
  const [modifiedSpecialHoursForm, setModifiedSpecialHoursForm] = useState();

  // handle entring hours' errors
  var enumerateDaysBetweenDates = function (startDate, endDate) {
    var days = [];
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');
    days.push(currDate.day() === 0 ? 7 : currDate.day());
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      days.push(currDate.day() === 0 ? 7 : currDate.day());
    }
    days.push(lastDate.day() === 0 ? 7 : lastDate.day());
    return days;
  };
  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
    // Mon
    setOpenTimeMon1(null);
    setCloseTimeMon1(null);
    setOpenTimeMon2(null);
    setCloseTimeMon2(null);
    setOpenOrderingTimeMon1(null);
    setCloseOrderingTimeMon1(null);
    setOpenOrderingTimeMon2(null);
    setCloseOrderingTimeMon2(null);
  }, [isOpen]);
  // Copy Hours
  useEffect(() => {
    let copyLocationHours = [];
    daysRange.forEach((day) => {
      if (day === 1 && !isLocationClosedMon) {
        const candidateItem = getCandidateCopyHours(
          day,
          openTimeMon1,
          closeTimeMon1,
          openTimeMon2,
          closeTimeMon2
        );
        if (candidateItem) {
          copyLocationHours.push(candidateItem);
        }
      } else if (day === 2) {
      } else if (day === 3) {
      } else if (day === 4) {
      } else if (day === 5) {
      } else if (day === 6) {
      } else if (day === 7) {
      }
    });
    setCopyLocationHours(copyLocationHours);
  }, [
    daysRange,
    //Mon
    openTimeMon1,
    closeTimeMon1,
    openTimeMon2,
    closeTimeMon2,
    openOrderingTimeMon1,
    closeOrderingTimeMon1,
    openOrderingTimeMon2,
    closeOrderingTimeMon2,
    // Tue
    openTimeTue1,
    closeTimeTue1,
    openTimeTue2,
    closeTimeTue2,
    openOrderingTimeTue1,
    closeOrderingTimeTue1,
    openOrderingTimeTue2,
    closeOrderingTimeTue2,
    // Wed
    openTimeWed1,
    closeTimeWed1,
    openTimeWed2,
    closeTimeWed2,
    openOrderingTimeWed1,
    closeOrderingTimeWed1,
    openOrderingTimeWed2,
    closeOrderingTimeWed2,
    // Thu
    openTimeThu1,
    closeTimeThu1,
    openTimeThu2,
    closeTimeThu2,
    openOrderingTimeThu1,
    closeOrderingTimeThu1,
    openOrderingTimeThu2,
    closeOrderingTimeThu2,
    // Fri
    openTimeFri1,
    closeTimeFri1,
    openTimeFri2,
    closeTimeFri2,
    openOrderingTimeFri1,
    closeOrderingTimeFri1,
    openOrderingTimeFri2,
    closeOrderingTimeFri2,
    // Sat
    openTimeSat1,
    closeTimeSat1,
    openTimeSat2,
    closeTimeSat2,
    openOrderingTimeSat1,
    closeOrderingTimeSat1,
    openOrderingTimeSat2,
    closeOrderingTimeSat2,
    // Sun
    openTimeSun1,
    closeTimeSun1,
    openTimeSun2,
    closeTimeSun2,
    openOrderingTimeSun1,
    closeOrderingTimeSun1,
    openOrderingTimeSun2,
    closeOrderingTimeSun2,
  ]);
  const handleCopyHours = (day) => {
    if (day) {
    } else if (day === 2) {
    } else if (day === 3) {
    } else if (day === 4) {
    } else if (day === 5) {
    } else if (day === 6) {
    } else if (day === 7) {
    }
  };

  //
  useEffect(() => {
    let newModifiedSpecialHoursForm = [];
    if (startDate && endDate) {
      const allSelectedDays = enumerateDaysBetweenDates(startDate, endDate);
      const newSelectedDays = [...new Set(allSelectedDays)].sort();
      const formatedStartDate = moment(startDate).format('YYYY-MM-DD');
      const formatedEndDate = moment(endDate).format('YYYY-MM-DD');
      newSelectedDays.forEach((day) => {
        const newDay = {
          close_day_of_week: day,
          close_time: '',
          end_date: formatedEndDate,
          is_closed: true,
          is_order_ahead_closed: true,
          open_day_of_week: day,
          open_time: '',
          order_end_time: '',
          order_start_time: '',
          start_date: formatedStartDate,
        };
        newModifiedSpecialHoursForm.push(newDay);
      });
      setDaysRange(newSelectedDays);
      const newAvailableTabs = newSelectedDays.map((item) => item - 1);
      setAvailableTabs(newAvailableTabs);
      setSelectedTab(newAvailableTabs[0]);
      setModifiedSpecialHoursForm(newModifiedSpecialHoursForm);
      if (!openTimeMon1) setOpenTimeMon1(defaultOpenTime);
      if (!closeTimeMon1) setCloseTimeMon1(defaultCloseTime);
    }
  }, [startDate, endDate]);
  useEffect(() => {
    let newError = { ...error };
    if (selectedTab === 0) {
      newError = timeErrors(
        'Mon',
        newError,
        initialError,
        openTimeMon1,
        closeTimeMon1,
        openTimeMon2,
        closeTimeMon2,
        openOrderingTimeMon1,
        closeOrderingTimeMon1,
        openOrderingTimeMon2,
        closeOrderingTimeMon2
      );
    } else if (selectedTab === 1) {
      newError = timeErrors(
        'Tue',
        newError,
        initialError,
        openTimeTue1,
        closeTimeTue1,
        openTimeTue2,
        closeTimeTue2,
        openOrderingTimeTue1,
        closeOrderingTimeTue1,
        openOrderingTimeTue2,
        closeOrderingTimeTue2
      );
    } else if (selectedTab === 2) {
      newError = timeErrors(
        'Wed',
        newError,
        initialError,
        openTimeWed1,
        closeTimeWed1,
        openTimeWed2,
        closeTimeWed2,
        openOrderingTimeWed1,
        closeOrderingTimeWed1,
        openOrderingTimeWed2,
        closeOrderingTimeWed2
      );
    } else if (selectedTab === 3) {
      newError = timeErrors(
        'Thu',
        newError,
        initialError,
        openTimeThu1,
        closeTimeThu1,
        openTimeThu2,
        closeTimeThu2,
        openOrderingTimeThu1,
        closeOrderingTimeThu1,
        openOrderingTimeThu2,
        closeOrderingTimeThu2
      );
    } else if (selectedTab === 4) {
      newError = timeErrors(
        'Fri',
        newError,
        initialError,
        openTimeFri1,
        closeTimeFri1,
        openTimeFri2,
        closeTimeFri2,
        openOrderingTimeFri1,
        closeOrderingTimeFri1,
        openOrderingTimeFri2,
        closeOrderingTimeFri2
      );
    } else if (selectedTab === 5) {
      newError = timeErrors(
        'Sat',
        newError,
        initialError,
        openTimeSat1,
        closeTimeSat1,
        openTimeSat2,
        closeTimeSat2,
        openOrderingTimeSat1,
        closeOrderingTimeSat1,
        openOrderingTimeSat2,
        closeOrderingTimeSat2
      );
    } else if (selectedTab === 6) {
      newError = timeErrors(
        'Sun',
        newError,
        initialError,
        openTimeSun1,
        closeTimeSun1,
        openTimeSun2,
        closeTimeSun2,
        openOrderingTimeSun1,
        closeOrderingTimeSun1,
        openOrderingTimeSun2,
        closeOrderingTimeSun2
      );
    }
    setError(newError);
  }, [
    openTimeMon1,
    closeTimeMon1,
    openTimeMon2,
    closeTimeMon2,
    openOrderingTimeMon1,
    closeOrderingTimeMon1,
    openOrderingTimeMon2,
    closeOrderingTimeMon2,
    // Tue
    openTimeTue1,
    closeTimeTue1,
    openTimeTue2,
    closeTimeTue2,
    openOrderingTimeTue1,
    closeOrderingTimeTue1,
    openOrderingTimeTue2,
    closeOrderingTimeTue2,
    // Wed
    openTimeWed1,
    closeTimeWed1,
    openTimeWed2,
    closeTimeWed2,
    openOrderingTimeWed1,
    closeOrderingTimeWed1,
    openOrderingTimeWed2,
    closeOrderingTimeWed2,
    // Thu
    openTimeThu1,
    closeTimeThu1,
    openTimeThu2,
    closeTimeThu2,
    openOrderingTimeThu1,
    closeOrderingTimeThu1,
    openOrderingTimeThu2,
    closeOrderingTimeThu2,
    // Fri
    openTimeFri1,
    closeTimeFri1,
    openTimeFri2,
    closeTimeFri2,
    openOrderingTimeFri1,
    closeOrderingTimeFri1,
    openOrderingTimeFri2,
    closeOrderingTimeFri2,
    // Sat
    openTimeSat1,
    closeTimeSat1,
    openTimeSat2,
    closeTimeSat2,
    openOrderingTimeSat1,
    closeOrderingTimeSat1,
    openOrderingTimeSat2,
    closeOrderingTimeSat2,
    // Sun
    openTimeSun1,
    closeTimeSun1,
    openTimeSun2,
    closeTimeSun2,
    openOrderingTimeSun1,
    closeOrderingTimeSun1,
    openOrderingTimeSun2,
    closeOrderingTimeSun2,
  ]);
  const handleCloseModal = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedDays([]);
    setIsOpen(false);
  };

  const handleSave = () => {
    let finalSpecialHours = [];
    modifiedSpecialHoursForm.forEach((day) => {
      let modifiedDay1;
      let modifiedDay2;
      if (daysRange.includes(day.open_day_of_week)) {
        if (day.open_day_of_week === 1) {
          const {
            specialOpenTime1,
            specialCloseTime1,
            specialOpenTime2,
            specialCloseTime2,
            specialOpenOrderingTime1,
            specialCloseOrderingTime1,
            specialOpenOrderingTime2,
            specialCloseOrderingTime2,
          } = getFormatedSpecialHours(
            openTimeMon1,
            closeTimeMon1,
            openTimeMon2,
            closeTimeMon2,
            openOrderingTimeMon1,
            closeOrderingTimeMon1,
            openOrderingTimeMon2,
            closeOrderingTimeMon2
          );
          const isNextDay =
            moment(openTimeMon1).isAfter(closeTimeMon1) ||
            moment(openTimeMon2).isAfter(closeTimeMon2);
          const closeDayofWeek = isNextDay
            ? day.open_day_of_week + 1
            : day.open_day_of_week;
          if (isLocationClosedMon) {
            modifiedDay1 = {
              ...day,
              is_closed: true,
              is_order_ahead_closed: true,
              open_time: '',
              close_time: '',
              order_start_time: '',
              order_end_time: '',
            };
            finalSpecialHours.push(modifiedDay1);
          } else {
            const isNotSplited = !specialOpenTime2 && !specialCloseTime2;
            modifiedDay1 = {
              ...day,
              is_closed: false,
              is_order_ahead_closed: false,
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              order_start_time: specialOpenOrderingTime1,
              order_end_time: specialCloseOrderingTime1,
              close_day_of_week: closeDayofWeek,
            };
            finalSpecialHours.push(modifiedDay1);
            if (!isNotSplited) {
              modifiedDay2 = {
                ...modifiedDay1,
                open_time: specialOpenTime2,
                close_time: specialCloseTime2,
                order_start_time: specialOpenOrderingTime2,
                order_end_time: specialCloseOrderingTime2,
                close_day_of_week: closeDayofWeek,
              };
              finalSpecialHours.push(modifiedDay2);
            }
          }
        } else if (day.open_day_of_week === 2) {
          const {
            specialOpenTime1,
            specialCloseTime1,
            specialOpenTime2,
            specialCloseTime2,
            specialOpenOrderingTime1,
            specialCloseOrderingTime1,
            specialOpenOrderingTime2,
            specialCloseOrderingTime2,
          } = getFormatedSpecialHours(
            openTimeTue1,
            closeTimeTue1,
            openTimeTue2,
            closeTimeTue2,
            openOrderingTimeTue1,
            closeOrderingTimeTue1,
            openOrderingTimeTue2,
            closeOrderingTimeTue2
          );
          const isNextDay =
            moment(openTimeTue1).isAfter(closeTimeTue1) ||
            moment(openTimeTue2).isAfter(closeTimeTue2);
          const closeDayofWeek = isNextDay
            ? day.open_day_of_week + 1
            : day.open_day_of_week;
          if (isLocationClosedTue) {
            modifiedDay1 = {
              ...day,
              is_closed: true,
              is_order_ahead_closed: true,
              open_time: '',
              close_time: '',
              order_start_time: '',
              order_end_time: '',
            };
            finalSpecialHours.push(modifiedDay1);
          } else {
            const isNotSplited = !specialOpenTime2 && !specialCloseTime2;
            modifiedDay1 = {
              ...day,
              is_closed: false,
              is_order_ahead_closed: false,
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              order_start_time: specialOpenOrderingTime1,
              order_end_time: specialCloseOrderingTime1,
              close_day_of_week: closeDayofWeek,
            };
            finalSpecialHours.push(modifiedDay1);
            if (!isNotSplited) {
              modifiedDay2 = {
                ...modifiedDay1,
                open_time: specialOpenTime2,
                close_time: specialCloseTime2,
                order_start_time: specialOpenOrderingTime2,
                order_end_time: specialCloseOrderingTime2,
                close_day_of_week: closeDayofWeek,
              };
              finalSpecialHours.push(modifiedDay2);
            }
          }
        } else if (day.open_day_of_week === 3) {
          const {
            specialOpenTime1,
            specialCloseTime1,
            specialOpenTime2,
            specialCloseTime2,
            specialOpenOrderingTime1,
            specialCloseOrderingTime1,
            specialOpenOrderingTime2,
            specialCloseOrderingTime2,
          } = getFormatedSpecialHours(
            openTimeWed1,
            closeTimeWed1,
            openTimeWed2,
            closeTimeWed2,
            openOrderingTimeWed1,
            closeOrderingTimeWed1,
            openOrderingTimeWed2,
            closeOrderingTimeWed2
          );
          const isNextDay =
            moment(openTimeWed1).isAfter(closeTimeWed1) ||
            moment(openTimeWed2).isAfter(closeTimeWed2);
          const closeDayofWeek = isNextDay
            ? day.open_day_of_week + 1
            : day.open_day_of_week;
          if (isLocationClosedWed) {
            modifiedDay1 = {
              ...day,
              is_closed: true,
              is_order_ahead_closed: true,
              open_time: '',
              close_time: '',
              order_start_time: '',
              order_end_time: '',
            };
            finalSpecialHours.push(modifiedDay1);
          } else {
            const isNotSplited = !specialOpenTime2 && !specialCloseTime2;
            modifiedDay1 = {
              ...day,
              is_closed: false,
              is_order_ahead_closed: false,
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              order_start_time: specialOpenOrderingTime1,
              order_end_time: specialCloseOrderingTime1,
              close_day_of_week: closeDayofWeek,
            };
            finalSpecialHours.push(modifiedDay1);
            if (!isNotSplited) {
              modifiedDay2 = {
                ...modifiedDay1,
                open_time: specialOpenTime2,
                close_time: specialCloseTime2,
                order_start_time: specialOpenOrderingTime2,
                order_end_time: specialCloseOrderingTime2,
                close_day_of_week: closeDayofWeek,
              };
              finalSpecialHours.push(modifiedDay2);
            }
          }
        } else if (day.open_day_of_week === 4) {
          const {
            specialOpenTime1,
            specialCloseTime1,
            specialOpenTime2,
            specialCloseTime2,
            specialOpenOrderingTime1,
            specialCloseOrderingTime1,
            specialOpenOrderingTime2,
            specialCloseOrderingTime2,
          } = getFormatedSpecialHours(
            openTimeThu1,
            closeTimeThu1,
            openTimeThu2,
            closeTimeThu2,
            openOrderingTimeThu1,
            closeOrderingTimeThu1,
            openOrderingTimeThu2,
            closeOrderingTimeThu2
          );
          const isNextDay =
            moment(openTimeThu1).isAfter(closeTimeThu1) ||
            moment(openTimeThu2).isAfter(closeTimeThu2);
          const closeDayofWeek = isNextDay
            ? day.open_day_of_week + 1
            : day.open_day_of_week;
          if (isLocationClosedThu) {
            modifiedDay1 = {
              ...day,
              is_closed: true,
              is_order_ahead_closed: true,
              open_time: '',
              close_time: '',
              order_start_time: '',
              order_end_time: '',
            };
            finalSpecialHours.push(modifiedDay1);
          } else {
            const isNotSplited = !specialOpenTime2 && !specialCloseTime2;
            modifiedDay1 = {
              ...day,
              is_closed: false,
              is_order_ahead_closed: false,
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              order_start_time: specialOpenOrderingTime1,
              order_end_time: specialCloseOrderingTime1,
              close_day_of_week: closeDayofWeek,
            };
            finalSpecialHours.push(modifiedDay1);
            if (!isNotSplited) {
              modifiedDay2 = {
                ...modifiedDay1,
                open_time: specialOpenTime2,
                close_time: specialCloseTime2,
                order_start_time: specialOpenOrderingTime2,
                order_end_time: specialCloseOrderingTime2,
                close_day_of_week: closeDayofWeek,
              };
              finalSpecialHours.push(modifiedDay2);
            }
          }
        } else if (day.open_day_of_week === 5) {
          const {
            specialOpenTime1,
            specialCloseTime1,
            specialOpenTime2,
            specialCloseTime2,
            specialOpenOrderingTime1,
            specialCloseOrderingTime1,
            specialOpenOrderingTime2,
            specialCloseOrderingTime2,
          } = getFormatedSpecialHours(
            openTimeFri1,
            closeTimeFri1,
            openTimeFri2,
            closeTimeFri2,
            openOrderingTimeFri1,
            closeOrderingTimeFri1,
            openOrderingTimeFri2,
            closeOrderingTimeFri2
          );
          const isNextDay =
            moment(openTimeFri1).isAfter(closeTimeFri1) ||
            moment(openTimeFri2).isAfter(closeTimeFri2);
          const closeDayofWeek = isNextDay
            ? day.open_day_of_week + 1
            : day.open_day_of_week;
          if (isLocationClosedFri) {
            modifiedDay1 = {
              ...day,
              is_closed: true,
              is_order_ahead_closed: true,
              open_time: '',
              close_time: '',
              order_start_time: '',
              order_end_time: '',
            };
            finalSpecialHours.push(modifiedDay1);
          } else {
            const isNotSplited = !specialOpenTime2 && !specialCloseTime2;
            modifiedDay1 = {
              ...day,
              is_closed: false,
              is_order_ahead_closed: false,
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              order_start_time: specialOpenOrderingTime1,
              order_end_time: specialCloseOrderingTime1,
              close_day_of_week: closeDayofWeek,
            };
            finalSpecialHours.push(modifiedDay1);
            if (!isNotSplited) {
              modifiedDay2 = {
                ...modifiedDay1,
                open_time: specialOpenTime2,
                close_time: specialCloseTime2,
                order_start_time: specialOpenOrderingTime2,
                order_end_time: specialCloseOrderingTime2,
                close_day_of_week: closeDayofWeek,
              };
              finalSpecialHours.push(modifiedDay2);
            }
          }
        } else if (day.open_day_of_week === 6) {
          const {
            specialOpenTime1,
            specialCloseTime1,
            specialOpenTime2,
            specialCloseTime2,
            specialOpenOrderingTime1,
            specialCloseOrderingTime1,
            specialOpenOrderingTime2,
            specialCloseOrderingTime2,
          } = getFormatedSpecialHours(
            openTimeSat1,
            closeTimeSat1,
            openTimeSat2,
            closeTimeSat2,
            openOrderingTimeSat1,
            closeOrderingTimeSat1,
            openOrderingTimeSat2,
            closeOrderingTimeSat2
          );
          const isNextDay =
            moment(openTimeSat1).isAfter(closeTimeSat1) ||
            moment(openTimeSat2).isAfter(closeTimeSat2);
          const closeDayofWeek = isNextDay
            ? day.open_day_of_week + 1
            : day.open_day_of_week;
          if (isLocationClosedSat) {
            modifiedDay1 = {
              ...day,
              is_closed: true,
              is_order_ahead_closed: true,
              open_time: '',
              close_time: '',
              order_start_time: '',
              order_end_time: '',
            };
            finalSpecialHours.push(modifiedDay1);
          } else {
            const isNotSplited = !specialOpenTime2 && !specialCloseTime2;
            modifiedDay1 = {
              ...day,
              is_closed: false,
              is_order_ahead_closed: false,
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              order_start_time: specialOpenOrderingTime1,
              order_end_time: specialCloseOrderingTime1,
              close_day_of_week: closeDayofWeek,
            };
            finalSpecialHours.push(modifiedDay1);
            if (!isNotSplited) {
              modifiedDay2 = {
                ...modifiedDay1,
                open_time: specialOpenTime2,
                close_time: specialCloseTime2,
                order_start_time: specialOpenOrderingTime2,
                order_end_time: specialCloseOrderingTime2,
                close_day_of_week: closeDayofWeek,
              };
              finalSpecialHours.push(modifiedDay2);
            }
          }
        } else if (day.open_day_of_week === 7) {
          const {
            specialOpenTime1,
            specialCloseTime1,
            specialOpenTime2,
            specialCloseTime2,
            specialOpenOrderingTime1,
            specialCloseOrderingTime1,
            specialOpenOrderingTime2,
            specialCloseOrderingTime2,
          } = getFormatedSpecialHours(
            openTimeSun1,
            closeTimeSun1,
            openTimeSun2,
            closeTimeSun2,
            openOrderingTimeSun1,
            closeOrderingTimeSun1,
            openOrderingTimeSun2,
            closeOrderingTimeSun2
          );
          const isNextDay =
            moment(openTimeSun1).isAfter(closeTimeSun1) ||
            moment(openTimeSun2).isAfter(closeTimeSun2);
          const closeDayofWeek = isNextDay ? 1 : day.open_day_of_week;
          if (isLocationClosedSun) {
            modifiedDay1 = {
              ...day,
              is_closed: true,
              is_order_ahead_closed: true,
              open_time: '',
              close_time: '',
              order_start_time: '',
              order_end_time: '',
            };
            finalSpecialHours.push(modifiedDay1);
          } else {
            const isNotSplited = !specialOpenTime2 && !specialCloseTime2;
            modifiedDay1 = {
              ...day,
              is_closed: false,
              is_order_ahead_closed: false,
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              order_start_time: specialOpenOrderingTime1,
              order_end_time: specialCloseOrderingTime1,
              close_day_of_week: closeDayofWeek,
            };
            finalSpecialHours.push(modifiedDay1);
            if (!isNotSplited) {
              modifiedDay2 = {
                ...modifiedDay1,
                open_time: specialOpenTime2,
                close_time: specialCloseTime2,
                order_start_time: specialOpenOrderingTime2,
                order_end_time: specialCloseOrderingTime2,
                close_day_of_week: closeDayofWeek,
              };
              finalSpecialHours.push(modifiedDay2);
            }
          }
        }
      } else {
        modifiedDay1 = {
          ...day,
          is_closed: true,
          is_order_ahead_closed: true,
          open_time: '',
          close_time: '',
          order_start_time: '',
          order_end_time: '',
        };
        finalSpecialHours.push(modifiedDay1);
      }
    });

    postSpecialHour(businessId, finalSpecialHours);
    handleCloseModal();
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
      maxWidth='sm'
    >
      <Wrapper>
        <Title>{`Create Special Hours`}</Title>
        <CloseIcon className='fa fa-times' onClick={handleCloseModal} />
        <DatePickerWrapper>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <KeyboardDatePicker
              clearable
              autoOk
              variant='inline'
              inputVariant='outlined'
              label='Start Date'
              format='DD/MM/yyyy'
              placeholder='DD/MM/YYYY'
              value={startDate}
              InputAdornmentProps={{ position: 'end' }}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{ width: '100%' }}
              size='small'
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <KeyboardDatePicker
              autoOk
              disabled={!startDate}
              variant='inline'
              inputVariant='outlined'
              label='End Date'
              format='DD/MM/yyyy'
              placeholder='DD/MM/YYYY'
              value={endDate}
              onChange={(date) => setEndDate(date)}
              style={{ width: '100%' }}
              size='small'
              minDate={startDate}
              minDateMessage={`End Date should not be before start date: ${startDate
                ?.toString()
                .substring(4, 16)}`}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                padding: '0',
                height: '14px',
                color: 'blue',
                display: 'none',
              }}
              InputAdornmentProps={{
                position: 'end',
                backgroundColor: 'red',
              }}
            />
          </MuiPickersUtilsProvider>
        </DatePickerWrapper>
        <div className={classes.root}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant='fullWidth'
            aria-label='simple tabs example'
            sx={{ color: '#000' }}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <CustomTab
              label='Mon'
              {...a11yProps(0)}
              disabled={!availableTabs.includes(0)}
            />
            <CustomTab
              label='Tue'
              {...a11yProps(1)}
              disabled={!availableTabs.includes(1)}
            />
            <CustomTab
              label='Wed'
              {...a11yProps(2)}
              disabled={!availableTabs.includes(2)}
            />
            <CustomTab
              label='Thu'
              {...a11yProps(3)}
              disabled={!availableTabs.includes(3)}
            />
            <CustomTab
              label='Fri'
              {...a11yProps(4)}
              disabled={!availableTabs.includes(4)}
            />
            <CustomTab
              label='Sat'
              {...a11yProps(5)}
              disabled={!availableTabs.includes(5)}
            />
            <CustomTab
              label='Sun'
              {...a11yProps(6)}
              disabled={!availableTabs.includes(6)}
            />
          </Tabs>
          {availableTabs.length > 0 && (
            <>
              <TabPanel value={selectedTab} index={0}>
                <SpecialDayTimeCreatetab
                  day='Mon'
                  openTime1={openTimeMon1}
                  closeTime1={closeTimeMon1}
                  openTime2={openTimeMon2}
                  closeTime2={closeTimeMon2}
                  openOrderingTime1={openOrderingTimeMon1}
                  closeOrderingTime1={closeOrderingTimeMon1}
                  openOrderingTime2={openOrderingTimeMon2}
                  closeOrderingTime2={closeOrderingTimeMon2}
                  setOpenTime1={setOpenTimeMon1}
                  setCloseTime1={setCloseTimeMon1}
                  setOpenTime2={setOpenTimeMon2}
                  setCloseTime2={setCloseTimeMon2}
                  setOpenOrderingTime1={setOpenOrderingTimeMon1}
                  setCloseOrderingTime1={setCloseOrderingTimeMon1}
                  setOpenOrderingTime2={setOpenOrderingTimeMon2}
                  setCloseOrderingTime2={setCloseOrderingTimeMon2}
                  daysRange={daysRange}
                  isLocationClosed={isLocationClosedMon}
                  setIsLocationClosed={setIsLocationClosedMon}
                  isOrderingClosed={isOrderingClosedMon}
                  setIsOrderingClosed={setIsOrderingClosedMon}
                  handleCloseLocation={handleCloseLocation}
                  handleCloseOrdering={handleCloseOrdering}
                  handleRemoveTime={handleRemoveTime}
                  handleRemoveOrderingTime={handleRemoveOrderingTime}
                  isLocationHoursSplited={isLocationHoursSplitedMon}
                  setIsLocationHoursSplited={setIsLocationHoursSplitedMon}
                  isOrderingHoursSplited={isOrderingHoursSplitedMon}
                  setIsOrderingHoursSplited={setIsOrderingHoursSplitedMon}
                  error={error}
                  copyLocationHours={copyLocationHours}
                  copyOrderingHours={copyOrderingHours}
                  copyModalType={copyModalType}
                  setCopyModalType={setCopyModalType}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <SpecialDayTimeCreatetab
                  day='Tue'
                  openTime1={openTimeTue1}
                  closeTime1={closeTimeTue1}
                  openTime2={openTimeTue2}
                  closeTime2={closeTimeTue2}
                  openOrderingTime1={openOrderingTimeTue1}
                  closeOrderingTime1={closeOrderingTimeTue1}
                  openOrderingTime2={openOrderingTimeTue2}
                  closeOrderingTime2={closeOrderingTimeTue2}
                  setOpenTime1={setOpenTimeTue1}
                  setCloseTime1={setCloseTimeTue1}
                  setOpenTime2={setOpenTimeTue2}
                  setCloseTime2={setCloseTimeTue2}
                  setOpenOrderingTime1={setOpenOrderingTimeTue1}
                  setCloseOrderingTime1={setCloseOrderingTimeTue1}
                  setOpenOrderingTime2={setOpenOrderingTimeTue2}
                  setCloseOrderingTime2={setCloseOrderingTimeTue2}
                  daysRange={daysRange}
                  isLocationClosed={isLocationClosedTue}
                  setIsLocationClosed={setIsLocationClosedTue}
                  isOrderingClosed={isOrderingClosedTue}
                  setIsOrderingClosed={setIsOrderingClosedTue}
                  handleCloseLocation={handleCloseLocation}
                  handleCloseOrdering={handleCloseOrdering}
                  handleRemoveTime={handleRemoveTime}
                  handleRemoveOrderingTime={handleRemoveOrderingTime}
                  isLocationHoursSplited={isLocationHoursSplitedTue}
                  setIsLocationHoursSplited={setIsLocationHoursSplitedTue}
                  isOrderingHoursSplited={isOrderingHoursSplitedTue}
                  setIsOrderingHoursSplited={setIsOrderingHoursSplitedTue}
                  error={error}
                  copyLocationHours={copyLocationHours}
                  copyOrderingHours={copyOrderingHours}
                  copyModalType={copyModalType}
                  setCopyModalType={setCopyModalType}
                  handleCopyHours={handleCopyHours}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={2}>
                <SpecialDayTimeCreatetab
                  day='Wed'
                  openTime1={openTimeWed1}
                  closeTime1={closeTimeWed1}
                  openTime2={openTimeWed2}
                  closeTime2={closeTimeWed2}
                  openOrderingTime1={openOrderingTimeWed1}
                  closeOrderingTime1={closeOrderingTimeWed1}
                  openOrderingTime2={openOrderingTimeWed2}
                  closeOrderingTime2={closeOrderingTimeWed2}
                  setOpenTime1={setOpenTimeWed1}
                  setCloseTime1={setCloseTimeWed1}
                  setOpenTime2={setOpenTimeWed2}
                  setCloseTime2={setCloseTimeWed2}
                  setOpenOrderingTime1={setOpenOrderingTimeWed1}
                  setCloseOrderingTime1={setCloseOrderingTimeWed1}
                  setOpenOrderingTime2={setOpenOrderingTimeWed2}
                  setCloseOrderingTime2={setCloseOrderingTimeWed2}
                  daysRange={daysRange}
                  isLocationClosed={isLocationClosedWed}
                  setIsLocationClosed={setIsLocationClosedWed}
                  isOrderingClosed={isOrderingClosedWed}
                  setIsOrderingClosed={setIsOrderingClosedWed}
                  handleCloseLocation={handleCloseLocation}
                  handleCloseOrdering={handleCloseOrdering}
                  handleRemoveTime={handleRemoveTime}
                  handleRemoveOrderingTime={handleRemoveOrderingTime}
                  isLocationHoursSplited={isLocationHoursSplitedWed}
                  setIsLocationHoursSplited={setIsLocationHoursSplitedWed}
                  isOrderingHoursSplited={isOrderingHoursSplitedWed}
                  setIsOrderingHoursSplited={setIsOrderingHoursSplitedWed}
                  error={error}
                  copyLocationHours={copyLocationHours}
                  copyOrderingHours={copyOrderingHours}
                  copyModalType={copyModalType}
                  setCopyModalType={setCopyModalType}
                  handleCopyHours={handleCopyHours}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={3}>
                <SpecialDayTimeCreatetab
                  day='Thu'
                  openTime1={openTimeThu1}
                  closeTime1={closeTimeThu1}
                  openTime2={openTimeThu2}
                  closeTime2={closeTimeThu2}
                  openOrderingTime1={openOrderingTimeThu1}
                  closeOrderingTime1={closeOrderingTimeThu1}
                  openOrderingTime2={openOrderingTimeThu2}
                  closeOrderingTime2={closeOrderingTimeThu2}
                  setOpenTime1={setOpenTimeThu1}
                  setCloseTime1={setCloseTimeThu1}
                  setOpenTime2={setOpenTimeThu2}
                  setCloseTime2={setCloseTimeThu2}
                  setOpenOrderingTime1={setOpenOrderingTimeThu1}
                  setCloseOrderingTime1={setCloseOrderingTimeThu1}
                  setOpenOrderingTime2={setOpenOrderingTimeThu2}
                  setCloseOrderingTime2={setCloseOrderingTimeThu2}
                  daysRange={daysRange}
                  isLocationClosed={isLocationClosedThu}
                  setIsLocationClosed={setIsLocationClosedThu}
                  isOrderingClosed={isOrderingClosedThu}
                  setIsOrderingClosed={setIsOrderingClosedThu}
                  handleCloseLocation={handleCloseLocation}
                  handleCloseOrdering={handleCloseOrdering}
                  handleRemoveTime={handleRemoveTime}
                  handleRemoveOrderingTime={handleRemoveOrderingTime}
                  isLocationHoursSplited={isLocationHoursSplitedThu}
                  setIsLocationHoursSplited={setIsLocationHoursSplitedThu}
                  isOrderingHoursSplited={isOrderingHoursSplitedThu}
                  setIsOrderingHoursSplited={setIsOrderingHoursSplitedThu}
                  error={error}
                  copyLocationHours={copyLocationHours}
                  copyOrderingHours={copyOrderingHours}
                  copyModalType={copyModalType}
                  setCopyModalType={setCopyModalType}
                  handleCopyHours={handleCopyHours}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={4}>
                <SpecialDayTimeCreatetab
                  day='Fri'
                  openTime1={openTimeFri1}
                  closeTime1={closeTimeFri1}
                  openTime2={openTimeFri2}
                  closeTime2={closeTimeFri2}
                  openOrderingTime1={openOrderingTimeFri1}
                  closeOrderingTime1={closeOrderingTimeFri1}
                  openOrderingTime2={openOrderingTimeFri2}
                  closeOrderingTime2={closeOrderingTimeFri2}
                  setOpenTime1={setOpenTimeFri1}
                  setCloseTime1={setCloseTimeFri1}
                  setOpenTime2={setOpenTimeFri2}
                  setCloseTime2={setCloseTimeFri2}
                  setOpenOrderingTime1={setOpenOrderingTimeFri1}
                  setCloseOrderingTime1={setCloseOrderingTimeFri1}
                  setOpenOrderingTime2={setOpenOrderingTimeFri2}
                  setCloseOrderingTime2={setCloseOrderingTimeFri2}
                  daysRange={daysRange}
                  isLocationClosed={isLocationClosedFri}
                  setIsLocationClosed={setIsLocationClosedFri}
                  isOrderingClosed={isOrderingClosedFri}
                  setIsOrderingClosed={setIsOrderingClosedFri}
                  handleCloseLocation={handleCloseLocation}
                  handleCloseOrdering={handleCloseOrdering}
                  handleRemoveTime={handleRemoveTime}
                  handleRemoveOrderingTime={handleRemoveOrderingTime}
                  isLocationHoursSplited={isLocationHoursSplitedFri}
                  setIsLocationHoursSplited={setIsLocationHoursSplitedFri}
                  isOrderingHoursSplited={isOrderingHoursSplitedFri}
                  setIsOrderingHoursSplited={setIsOrderingHoursSplitedFri}
                  error={error}
                  copyLocationHours={copyLocationHours}
                  copyOrderingHours={copyOrderingHours}
                  copyModalType={copyModalType}
                  setCopyModalType={setCopyModalType}
                  handleCopyHours={handleCopyHours}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={5}>
                <SpecialDayTimeCreatetab
                  day='Sat'
                  openTime1={openTimeSat1}
                  closeTime1={closeTimeSat1}
                  openTime2={openTimeSat2}
                  closeTime2={closeTimeSat2}
                  openOrderingTime1={openOrderingTimeSat1}
                  closeOrderingTime1={closeOrderingTimeSat1}
                  openOrderingTime2={openOrderingTimeSat2}
                  closeOrderingTime2={closeOrderingTimeSat2}
                  setOpenTime1={setOpenTimeSat1}
                  setCloseTime1={setCloseTimeSat1}
                  setOpenTime2={setOpenTimeSat2}
                  setCloseTime2={setCloseTimeSat2}
                  setOpenOrderingTime1={setOpenOrderingTimeSat1}
                  setCloseOrderingTime1={setCloseOrderingTimeSat1}
                  setOpenOrderingTime2={setOpenOrderingTimeSat2}
                  setCloseOrderingTime2={setCloseOrderingTimeSat2}
                  daysRange={daysRange}
                  isLocationClosed={isLocationClosedSat}
                  setIsLocationClosed={setIsLocationClosedSat}
                  isOrderingClosed={isOrderingClosedSat}
                  setIsOrderingClosed={setIsOrderingClosedSat}
                  handleCloseLocation={handleCloseLocation}
                  handleCloseOrdering={handleCloseOrdering}
                  handleRemoveTime={handleRemoveTime}
                  handleRemoveOrderingTime={handleRemoveOrderingTime}
                  isLocationHoursSplited={isLocationHoursSplitedSat}
                  setIsLocationHoursSplited={setIsLocationHoursSplitedSat}
                  isOrderingHoursSplited={isOrderingHoursSplitedSat}
                  setIsOrderingHoursSplited={setIsOrderingHoursSplitedSat}
                  error={error}
                  copyLocationHours={copyLocationHours}
                  copyOrderingHours={copyOrderingHours}
                  copyModalType={copyModalType}
                  setCopyModalType={setCopyModalType}
                  handleCopyHours={handleCopyHours}
                />
              </TabPanel>
              <TabPanel value={selectedTab} index={6}>
                <SpecialDayTimeCreatetab
                  day='Sun'
                  openTime1={openTimeSun1}
                  closeTime1={closeTimeSun1}
                  openTime2={openTimeSun2}
                  closeTime2={closeTimeSun2}
                  openOrderingTime1={openOrderingTimeSun1}
                  closeOrderingTime1={closeOrderingTimeSun1}
                  openOrderingTime2={openOrderingTimeSun2}
                  closeOrderingTime2={closeOrderingTimeSun2}
                  setOpenTime1={setOpenTimeSun1}
                  setCloseTime1={setCloseTimeSun1}
                  setOpenTime2={setOpenTimeSun2}
                  setCloseTime2={setCloseTimeSun2}
                  setOpenOrderingTime1={setOpenOrderingTimeSun1}
                  setCloseOrderingTime1={setCloseOrderingTimeSun1}
                  setOpenOrderingTime2={setOpenOrderingTimeSun2}
                  setCloseOrderingTime2={setCloseOrderingTimeSun2}
                  daysRange={daysRange}
                  isLocationClosed={isLocationClosedSun}
                  setIsLocationClosed={setIsLocationClosedSun}
                  isOrderingClosed={isOrderingClosedSun}
                  setIsOrderingClosed={setIsOrderingClosedSun}
                  handleCloseLocation={handleCloseLocation}
                  handleCloseOrdering={handleCloseOrdering}
                  handleRemoveTime={handleRemoveTime}
                  handleRemoveOrderingTime={handleRemoveOrderingTime}
                  isLocationHoursSplited={isLocationHoursSplitedSun}
                  setIsLocationHoursSplited={setIsLocationHoursSplitedSun}
                  isOrderingHoursSplited={isOrderingHoursSplitedSun}
                  setIsOrderingHoursSplited={setIsOrderingHoursSplitedSun}
                  error={error}
                  copyLocationHours={copyLocationHours}
                  copyOrderingHours={copyOrderingHours}
                  copyModalType={copyModalType}
                  setCopyModalType={setCopyModalType}
                  handleCopyHours={handleCopyHours}
                />
              </TabPanel>
            </>
          )}
        </div>
      </Wrapper>
      <Divider />
      <CopyButtonWrapper>
        <CopyButton
          onClick={handleSave}
          disabled={
            daysRange.length === 0 ||
            error.openTimeMon1 ||
            error.closeTimeMon1 ||
            error.openTimeMon2 ||
            error.closeTimeMon2 ||
            error.openOrderingTimeMon1 ||
            error.closeOrderingTimeMon1 ||
            error.openOrderingTimeMon2 ||
            error.closeOrderingTimeMon2 ||
            error.openTimeTue1 ||
            error.closeTimeTue1 ||
            error.openTimeTue2 ||
            error.closeTimeTue2 ||
            error.openOrderingTimeTue1 ||
            error.closeOrderingTimeTue1 ||
            error.openOrderingTimeTue2 ||
            error.closeOrderingTimeTue2 ||
            error.openTimeWed1 ||
            error.closeTimeWed1 ||
            error.openTimeWed2 ||
            error.closeTimeWed2 ||
            error.openOrderingTimeWed1 ||
            error.closeOrderingTimeWed1 ||
            error.openOrderingTimeWed2 ||
            error.closeOrderingTimeWed2 ||
            error.openTimeThu1 ||
            error.closeTimeThu1 ||
            error.openTimeThu2 ||
            error.closeTimeThu2 ||
            error.openOrderingTimeThu1 ||
            error.closeOrderingTimeThu1 ||
            error.openOrderingTimeThu2 ||
            error.closeOrderingTimeThu2 ||
            error.openTimeFri1 ||
            error.closeTimeFri1 ||
            error.openTimeFri2 ||
            error.closeTimeFri2 ||
            error.openOrderingTimeFri1 ||
            error.closeOrderingTimeFri1 ||
            error.openOrderingTimeFri2 ||
            error.closeOrderingTimeFri2 ||
            error.openTimeSat1 ||
            error.closeTimeSat1 ||
            error.openTimeSat2 ||
            error.closeTimeSat2 ||
            error.openOrderingTimeSat1 ||
            error.closeOrderingTimeSat1 ||
            error.openOrderingTimeSat2 ||
            error.closeOrderingTimeSat2 ||
            error.openTimeSun1 ||
            error.closeTimeSun1 ||
            error.openTimeSun2 ||
            error.closeTimeSun2 ||
            error.openOrderingTimeSun1 ||
            error.closeOrderingTimeSun1 ||
            error.openOrderingTimeSun2 ||
            error.closeOrderingTimeSun2
          }
        >
          Save
        </CopyButton>
      </CopyButtonWrapper>
    </Dialog>
  );
};

export default SpecialDayTimeCreateModal;
