import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Button, Icon } from 'merchant-portal-components';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon className='fa fa-times' />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const RemoveOptionConfirmModal = ({
  isOpen,
  handleClose,
  optionName,
  handleRemoveOption,
}) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
      <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        {'Removing Option Confirmation'}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {`By confirming this modal, option "${optionName}" will be unassigned from the product, do you confirm?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRemoveOption}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoveOptionConfirmModal;
