import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'merchant-portal-components';
import exportIcon from '../assets/export-icon.png';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
export const SectionTitle = styled.div`
  width: 100%;
  display: ${(props) => props.display || 'block'};
  margin: ${(props) => props.margin || '0px 0 12px 0'};
  font-family: 'Montserrat';
  font-size: ${(props) => props.fontSize || '21px'};
  font-weight: ${(props) => props.fontWeight || '600'};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: ${(props) => props.color || '#191818'};
  text-align: ${(props) => props.textAlign || 'left'};
`;
export const SectionSubTitle = styled.div`
  display: ${(props) => props.display || 'block'};
  font-family: 'Montserrat';
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin: ${(props) => props.margin || '0'};
`;
export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: ${(props) => props.padding || '0'};
  margin: ${(props) => props.margin || '30px 0 0 0'};
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  text-align: left;
`;
export const Right = styled.div`
  margin: 0;
  text-align: right;
`;
export const CancelButton = styled.button`
  height: 20px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2440ae;
  cursor: pointer;
  border: none;
  background-color: inherit;
`;
export default function ExportModal({ open, handleClose, history }) {
  const handleNavigate = useCallback(() => {
    history.push('/export-report');
    handleClose(false);
  }, []);
  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='sm'
        onClose={() => handleClose(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          style: { borderRadius: 10, height: '500px' },
        }}
      >
        <DialogTitle id='alert-dialog-title'>
          <i
            className='fa fa-times'
            style={{
              fontWeight: '200',
              float: 'right',
              margin: '0',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          ></i>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={exportIcon} alt='A checklist in a circle' />
          <Typography
            variant='h6'
            style={{ textAlign: 'center', margin: '15px' }}
          >
            Hang in there, We are working on the report. Once ready, it will be
            available to download in Export Reports.
          </Typography>
          <Button onClick={handleNavigate}>Go to Export Reports</Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
