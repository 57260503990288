import moment from "moment";
const getFormatedSpecialHours = (
  openTime1,
  closeTime1,
  openTime2,
  closeTime2,
  openOrderingTime1,
  closeOrderingTime1,
  openOrderingTime2,
  closeOrderingTime2
) => {
  const specialOpenTime1 = openTime1
    ? moment(openTime1, "HH:mm").set("second", 0).format("HH:mm:ss")
    : "";
  const specialCloseTime1 = closeTime1
    ? moment(closeTime1, "HH:mm").set("second", 0).format("HH:mm:ss")
    : "";
  const specialOpenTime2 = openTime2
    ? moment(openTime2, "HH:mm").set("second", 0).format("HH:mm:ss")
    : "";
  const specialCloseTime2 = closeTime2
    ? moment(closeTime2, "HH:mm").set("second", 0).format("HH:mm:ss")
    : "";
  const specialOpenOrderingTime1 = openOrderingTime1
    ? moment(openOrderingTime1, "HH:mm").set("second", 0).format("HH:mm:ss")
    : "";
  const specialCloseOrderingTime1 = closeOrderingTime1
    ? moment(closeOrderingTime1, "HH:mm").set("second", 0).format("HH:mm:ss")
    : "";
  const specialOpenOrderingTime2 = openOrderingTime2
    ? moment(openOrderingTime2, "HH:mm").set("second", 0).format("HH:mm:ss")
    : "";
  const specialCloseOrderingTime2 = closeOrderingTime2
    ? moment(closeOrderingTime2, "HH:mm").set("second", 0).format("HH:mm:ss")
    : "";

  return {
    specialOpenTime1,
    specialCloseTime1,
    specialOpenTime2,
    specialCloseTime2,
    specialOpenOrderingTime1,
    specialCloseOrderingTime1,
    specialOpenOrderingTime2,
    specialCloseOrderingTime2,
  };
};
export default getFormatedSpecialHours;
