import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from './feesActions';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import ServiceFeeCard from './components/ServiceFeeCard';
import ServiceFeeDetailContainer from './components/ServiceFeeDetail/ServiceFeeDetailContainer';
import { Button, ButtonRow } from 'merchant-portal-components';

const CardWrapper = styled.div`
  width: 100%;
  margin: 0 0 20px 0;
`;
const FeesContainer = (props) => {
  const { currentPage, allFees } = props.feesReducer;
  const currentURL = window.location.pathname;
  const {
    getFees,
    toggleIsActiveFee,
    loadServiceFeeDetailPage,
    loadServiceFeeListPage,
  } = props;
  useEffect(() => {
    loadServiceFeeListPage();
  }, [currentURL]);
  useEffect(() => {
    getFees();
  }, [JSON.stringify(allFees), currentPage]);
  return (
    <div>
      {currentPage === 'list' ? (
        <>
          <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr' }}>
            <BreadCrumb pageName="Service Fee" />
            <div
              style={{
                margin: 'auto',
                marginRight: 0,
              }}
            >
              <ButtonRow justifyContent="flex-end">
                <Button onClick={() => loadServiceFeeDetailPage(false)}>
                  Create New Fee
                </Button>
              </ButtonRow>
            </div>
          </div>
          {allFees &&
            allFees.map((fee) => (
              <CardWrapper key={fee.id}>
                <ServiceFeeCard
                  serviceFee={fee}
                  toggleIsActiveFee={toggleIsActiveFee}
                  loadServiceFeeDetailPage={loadServiceFeeDetailPage}
                />
              </CardWrapper>
            ))}
        </>
      ) : (
        <ServiceFeeDetailContainer
          loadServiceFeeListPage={loadServiceFeeListPage}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    feesReducer: state.feesReducer,
  };
};

export default connect(mapStateToProps, actions)(FeesContainer);
