import React from 'react';
import styled from 'styled-components';
import { LoadingBlue, LoadingWhite } from 'merchant-portal-components';

// border: ${props =>
//   props.disabled
//     ? props.theme.blueGray
//     : props.secondary
//     ? 'none'
//     : props.tertiary
//     ? props.theme.tertiaryBorder
//     : props.theme.emergBorder};
export const Button = styled.button`
  border: ${(props) =>
    props.tertiary && (props.disabled || props.softDisable)
      ? props.theme.tertiaryBorderDisabled
      : props.tertiary && !(props.disabled || props.softDisable)
      ? props.theme.tertiaryBorder
      : 'none'};
  border-radius: 25px;
  min-height: ${(props) => (props.small ? '40' : '45')}px;
  padding: 0 20px;
  color: ${(props) =>
    props.disabled || props.softDisable
      ? props.theme.white
      : props.secondary || props.tertiary
      ? props.theme.blue
      : props.theme.white};
  font-weight: 600;
  background: ${(props) =>
    props.disabled || props.softDisable
      ? props.theme.blueGray
      : props.secondary || props.tertiary
      ? props.theme.white
      : props.danger
      ? props.theme.red
      : props.theme.blue};
  /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2); */
  margin-right: ${(props) => (props.marginRight ? '16px' : 'inherit')};
  min-width: ${(props) => (props.small ? '140' : '160')}px;
  cursor: ${(props) =>
    props.disabled || props.softDisable ? 'default' : 'pointer'};
  &:hover {
    background-color: ${(props) =>
      props.secondary || props.disabled || props.softDisable || props.tertiary
        ? 'none'
        : '#3051D4'};
  }
  &:click {
    outline: none;
  }
`;

export const FlatButton = styled.button`
  cursor: pointer;
  font-size: 16px;
  color: ${(props) => props.theme.blue};
  text-transform: uppercase;
  font-weight: 700;
  border: none;
`;

export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  min-height: 32px;
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
  @media print {
    display: none;
  }
`;

export const LoadingButton = ({
  children,
  large,
  height,
  marginTop,
  disabled,
  whiteSpinner,
  ...props
}) => {
  return (
    <Button {...props}>
      <div>
        {children}
        {whiteSpinner ? (
          <LoadingWhite style={{ margin: '0px 0px -2px 0px' }} />
        ) : (
          <LoadingBlue style={{ margin: '0px 0px -2px 0px' }} />
        )}
      </div>
    </Button>
  );
};
