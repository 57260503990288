import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import {
  getArchivedBusinesses,
  getBusinesses,
  getBusinessLocations,
  updateBusinessLocationsRequestParams,
} from './actions';
import {
  initiateCreateLocation,
  initiateEditLocation,
} from '../LocationDetailsManager/actions';

import BusinessLocationsList from './components/BusinessLocationsList';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { formatLocations } from '../../../utils/childLocations/formatLocations';

class BusinessLocationsListContainer extends Component {
  componentDidMount() {
    track.page('app-settings-locations-list');

    this.props.getBusinessLocations();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.businessSearchManager.selectedBusiness !=
      prevProps.businessSearchManager.selectedBusiness
    ) {
      if (!this.props.businessSearchManager.selectedBusiness) {
        this.props.getBusinessLocationsOfMerchant();
      } else {
        this.props.filterBusinessLocationsOfMerchantWithQuery(
          this.props.businessSearchManager.selectedBusiness
        );
      }
    }
  }

  handleBusinessLocationAction = (businessId) => {
    // this.props.history.push(`/locations/${businessId}`);
    this.props.initiateEditLocation(businessId);
  };

  render() {
    const pageName = 'Locations List';
    const {
      requestingGetBusinessLocationsOfMerchant,
      selectedBusinessLocationsOfMerchant,
      getBusinesses,
      requestParams,
      accountReducer,
      token,
    } = this.props.businessLocationsList;
    const { searchString, setSearchString } = this.props;
    return (
      <div>
        <BreadCrumb pageName={pageName} />
        <BusinessLocationsList
          accountReducer={accountReducer}
          token={token}
          allBusinessLocations={this.props.allBusinessLocations}
          requestParams={requestParams}
          getBusinesses={getBusinesses}
          getArchivedBusinesses={getArchivedBusinesses}
          requestingGetBusinessLocationsOfMerchant={
            requestingGetBusinessLocationsOfMerchant
          }
          businessLocationsOfMerchant={formatLocations(
            selectedBusinessLocationsOfMerchant
          )}
          handleBusinessLocationAction={this.handleBusinessLocationAction}
          {...this.props}
          searchString={searchString}
          setSearchString={setSearchString}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allBusinessLocations: state.businessLocationsList?.allBusinessLocations,
    businessLocationsList: state.businessLocationsList,
    businessSearchManager: state.businessSearchManager,
    archivedBusinesses: state.businessLocationsList.archivedBusinesses,
    accountReducer: state.accountReducer,
    token: state.accountReducer.token,
  };
}

export default connect(mapStateToProps, {
  ...actions,
  getBusinesses,
  getArchivedBusinesses,
  updateBusinessLocationsRequestParams,
  initiateCreateLocation,
  initiateEditLocation,
  getBusinessLocations,
})(BusinessLocationsListContainer);
