import api from '../../api';
import {
  GET_IMAGES_REQUEST,
  GET_IMAGES_SUCCESS,
  GET_IMAGES_FAILURE,
  SHOW_CREATE_IMAGE_FORM,
  HIDE_CREATE_IMAGE_FORM,
  SHOW_EDIT_IMAGE_FORM,
  HIDE_EDIT_IMAGE_FORM,
  CREATE_IMAGE_REQUEST,
  CREATE_IMAGE_SUCCESS,
  CREATE_IMAGE_FAILURE,
  EDIT_IMAGE_REQUEST,
  EDIT_IMAGE_SUCCESS,
  EDIT_IMAGE_FAILURE,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAILURE,
  REINIT_IMAGES,
  RESET_MEDIA_REDUCER_STATE,
  UPDATE_MEDIA_REQUEST_PARAMS,
  UPDATE_SORTED_IMAGES_LIST,
  DISPLAY_CONFIRM_MODAL,
} from './mediaTypes';
import { createAlert } from '../Alert/actions';
import { formatErrorMessage } from '../../utils/formatErrorMessage';

export function getImages(params = { page_number: 1, page_size: 1000 }) {
  // if (!params) {
  //   params = {
  //     page_number: 1,
  //     page_size: 10
  //   };
  // }

  return function (dispatch) {
    dispatch({ type: GET_IMAGES_REQUEST });
    return api
      .get(
        `/media?page_number=${params.page_number}&page_size=${params.page_size}`
      )
      .then(
        (response) => {
          dispatch({
            type: GET_IMAGES_SUCCESS,
            images: response.data.data,
            meta: response.data.meta,
          });
        },
        (error) => {
          dispatch({
            type: GET_IMAGES_FAILURE,
            error,
          });
        }
      );
  };
}

export function showCreateImageForm() {
  return {
    type: SHOW_CREATE_IMAGE_FORM,
  };
}

export function hideCreateImageForm() {
  return {
    type: HIDE_CREATE_IMAGE_FORM,
  };
}

export function showEditImageForm(imageBeingEdited) {
  return {
    type: SHOW_EDIT_IMAGE_FORM,
    imageBeingEdited,
  };
}

export function hideEditImageForm() {
  return {
    type: HIDE_EDIT_IMAGE_FORM,
  };
}

export function uploadImage(image, name, alt) {
  let newFormData = new FormData();
  newFormData.set('file', image, 'axios.png');
  newFormData.set('name', name);
  newFormData.set('alt_text', alt);

  return function (dispatch) {
    dispatch({ type: CREATE_IMAGE_REQUEST });
    return api.post('/media', newFormData).then(
      (response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Image successfully created.',
          })
        );
        dispatch({ type: CREATE_IMAGE_SUCCESS });
        dispatch(getImages());
      },
      (error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: formatErrorMessage(error) || 'An error occurred trying to save image.',
            dangerouslySetInnerHTML: true,
          })
        );
        dispatch({ type: CREATE_IMAGE_FAILURE, error });
      }
    );
  };
}

export function updateMediaRequestParams(requestParams) {
  return {
    type: UPDATE_MEDIA_REQUEST_PARAMS,
    payload: requestParams,
  };
}

export function updateSortedImagesList(imagesList, sortOrder) {
  return {
    type: 'UPDATE_SORTED_IMAGES_LIST',
    imagesList: imagesList,
    sortOrder: sortOrder,
  };
}

export function editImage(name, alt, id) {
  let obj = {
    name: name,
    alt_text: alt,
  };

  return function (dispatch) {
    dispatch({ type: EDIT_IMAGE_REQUEST });
    return api.patch(`/media/${id}`, obj).then(
      (response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Image successfully edited.',
          })
        );
        dispatch({
          type: EDIT_IMAGE_SUCCESS,
        });
        dispatch(getImages());
      },
      (error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: formatErrorMessage(error) || 'An error occurred trying to save image.',
            dangerouslySetInnerHTML: true,
          })
        );
        dispatch({ type: EDIT_IMAGE_FAILURE, error });
      }
    );
  };
}

export function deleteImageRequest(image) {
  return function (dispatch) {
    dispatch({ type: DELETE_IMAGE_REQUEST });
    return api.delete(`/media/${image.media_id}`).then(
      (response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Image successfully deleted.',
          })
        );
        dispatch({
          type: DELETE_IMAGE_SUCCESS,
        });
        dispatch({
          type: REINIT_IMAGES,
        });
        dispatch(getImages());
      },
      (error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: formatErrorMessage(error) || 'An error occurred trying to delete image.',
            dangerouslySetInnerHTML: true,
          })
        );
        dispatch({ type: DELETE_IMAGE_FAILURE, error });
      }
    );
  };
}

export function resetMediaReducerState() {
  return {
    type: RESET_MEDIA_REDUCER_STATE,
  };
}

export function displayConfirmModal(boolean) {
  return {
    type: DISPLAY_CONFIRM_MODAL,
    boolean,
  };
}
