export const GETTING_COMBOS = 'GETTING_COMBOS';
export const GET_COMBOS = 'GET_COMBOS';
export const LOAD_EDIT_COMBO_PAGE = 'LOAD_EDIT_COMBO_PAGE';
export const LOAD_COMBOS_LIST_PAGE = 'LOAD_COMBOS_LIST_PAGE';
export const PATCH_ACTIVE_COMBO = 'PATCH_ACTIVE_COMBO';
export const GET_COMBO_BUSINESSES = 'GET_COMBO_BUSINESSES';
export const GET_COMBO_ITEMS_PRODUCTS = 'GET_COMBO_ITEMS_PRODUCTS';
export const GET_COMBO_AVAILABILITIES = 'GET_COMBO_AVAILABILITIES';
export const GET_PRODUCTS_CATEGORIES = 'GET_PRODUCTS_CATEGORIES';
export const GET_PRODUCTS_VARIANTS = 'GET_PRODUCTS_VARIANTS';
export const HANDLE_BASIC_INFORMATION_SAVE_REQUEST =
  'HANDLE_BASIC_INFORMATION_SAVE_REQUEST';
export const GET_COMBOS_OPTIONS_OVERRIDES = 'GET_COMBOS_OPTIONS_OVERRIDES';
export const PATCH_COMBO_BASIC_INFORMATION = 'PATCH_COMBO_BASIC_INFORMATION';
export const INITIATE_CREATE_COMBO = 'INITIATE_CREATE_COMBO';
export const HANDLE_COMBO_ITEMS_REQUEST = 'HANDLE_COMBO_ITEMS_REQUEST';
export const PATCH_COMBO_ITEMS = 'PATCH_COMBO_ITEMS';
export const HANDLE_AVAILABILITIES_SAVE_REQUEST =
  'HANDLE_AVAILABILITIES_SAVE_REQUEST';
export const PATCH_COMBO_AVAILABILITIES = 'PATCH_COMBO_AVAILABILITIES';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ALL_COMBOS_SHARED_OPTIONS = 'GET_ALL_COMBOS_SHARED_OPTIONS';
export const RESET_COMBOS_REDUCER = 'RESET_COMBOS_REDUCER';
export const PATCH_COMBO_BASIC_INFORMATION_ERROR =
  'PATCH_COMBO_BASIC_INFORMATION_ERROR';
export const PATCH_SUCCESS_COMBO_ITEMS = 'PATCH_SUCCESS_COMBO_ITEMS';
export const GET_COMBOS_OPTIONS_OVERRIDES_REQUESTING =
  'GET_COMBOS_OPTIONS_OVERRIDES_REQUESTING';
export const PATCH_COMBO_ITEMS_SUCCESS = 'PATCH_COMBO_ITEMS_SUCCESS';
export const RESET_COMBO_ITEM_SUBMIT = 'RESET_COMBO_ITEM_SUBMIT';
export const SAVING_COMBO_POSITIONS = 'SAVING_COMBO_POSITIONS';
export const SAVING_COMBO_ITEMS_POSITIONS = 'SAVING_COMBO_ITEMS_POSITIONS';
export const UPDATE_SORTED_COMBOS_LIST = 'UPDATE_SORTED_COMBOS_LIST';
export const GET_COMBO_OVERRIDES = 'GET_COMBO_OVERRIDES';
export const SUBMIT_COMBO_OVERRIDES_REQUEST = 'SUBMIT_COMBO_OVERRIDES_REQUEST';
export const SUBMIT_COMBO_OVERRIDES = 'SUBMIT_COMBO_OVERRIDES';
export const GET_COMBOS_POSITIONS = 'GET_COMBOS_POSITIONS';
export const SHOW_LOADER='SHOW_LOADER';
export const DISABLE_COMBO_PROD_REORDER='DISABLE_COMBO_PROD_REORDER'


export const ARCHIVE_COMBO_SUCCESS = 'ARCHIVE_COMBO_SUCCESS';
export const ARCHIVE_COMBO_FAILURE = 'ARCHIVE_COMBO_FAILURE';
export const GET_ARCHIVED_COMBOS_SUCCESS = 'GET_ARCHIVED_COMBOS_SUCCESS';
export const GET_ARCHIVED_COMBOS_FAILURE = 'GET_ARCHIVED_COMBOS_FAILURE';
export const UNARCHIVE_COMBO_SUCCESS = 'UNARCHIVE_COMBO_SUCCESS';
export const UNARCHIVE_COMBO_FAILURE = 'UNARCHIVE_COMBO_FAILURE';

