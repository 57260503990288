import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from './customerListActions';
import fetchOnParamChange from '../../HOCs/fetchOnParamChange';
import CustomerList from './components/CustomerList';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { isThisISOWeek } from 'date-fns';

/**
 * Class Component that handle's how components should work
 */
class CustomerListContainer extends Component {
  constructor(props) {
    super(props);
    this.timer;
    this.state = {
      customers: props.customerListReducer.customers,
    };
  }
  componentDidMount() {
    if (this.props.searchString) {
      this.props.getCustomersOfMerchant({
        ...initialRequestParams,
        search_string: this.props.searchString,
      });
    }
  }
  componentDidUpdate(PrevProps, prevState) {
    if (PrevProps.customers !== this.props.customers) {
      this.setState({ customers: this.props.customers });
    }
    if (
      PrevProps.startUpdating !== this.props.startUpdating &&
      this.props.startUpdating
    ) {
      if (this.props.searchString.length > 2) {
        let searchStringVal;
        if (this.props.searchString.includes('+')) {
          searchStringVal = this.props.searchString.trim().replace(/\+/g, '+');
        } else if (this.props.searchString.includes('&')) {
          searchStringVal = this.props.searchString.trim().replace(/\&/g, '&');
        } else {
          searchStringVal = this.props.searchString.trim();
        }
        this.props.updateRequestParams({
          page_number: initialRequestParams.page_number,
          page_size: initialRequestParams.page_size,
          search_string: this.props.searchString,
        });
      } else if (this.props.searchString.length == 0) {
        this.props.updateRequestParams({
          page_number: initialRequestParams.page_number,
          page_size: initialRequestParams.page_size,
        });
      }
    }
  }
  handleSearchInput = (value) => {
    this.props.setSearchString(value.toLowerCase());
  };

  /**
   * Redirect user to customer detail page
   */
  handleEditIconClick = (customerId) => {
    this.props.setCurrentPage('detail');
    this.props.setCustomerId(customerId);
  };
  handleClearSearch = () => {
    this.props.setSearchString('');
    this.props.getCustomersOfMerchant(initialRequestParams);
  };

  render() {
    const { getCustomersIsRequesting, customers, meta } =
      this.props.customerListReducer;
    const { requestParams, updateRequestParams } = this.props;
    // const { searchInput } = this.state;
    const pageName = 'Customers List';
    return (
      <Fragment>
        <BreadCrumb pageName={pageName} />
        <CustomerList
          getCustomersIsRequesting={getCustomersIsRequesting}
          customers={this.state.customers}
          handleSearchInput={this.handleSearchInput}
          meta={meta}
          requestParams={requestParams}
          updateRequestParams={updateRequestParams}
          handleEditIconClick={this.handleEditIconClick}
          searchString={this.props.searchString}
          handleClearSearch={this.handleClearSearch}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customerListReducer: state.customerListReducer,
});

const initialRequestParams = {
  page_number: 1,
  page_size: 25,
};

CustomerListContainer = fetchOnParamChange(
  CustomerListContainer,
  actions.getCustomersOfMerchant,
  initialRequestParams
);

export default connect(mapStateToProps, actions)(CustomerListContainer);
