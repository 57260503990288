import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  RenderSelect,
  RenderTextField,
  required,
} from '../../../../components/FormElements/FormikElements';
import { Button } from 'merchant-portal-components';
import {
  ButtonsWrapper,
  FormWrapper,
  ModalTitle,
} from '../CreateEditModalStyle';
import { getRoles, getBusinesses } from '../../utils/utils';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
  Checkbox,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
const SwitchText = styled.span`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
`;

const validationSchema = Yup.object().shape({
  business_ids: Yup.array()
    .of(
      Yup.object().shape({
        subBusinessId: Yup.number(),
        subBusinessName: Yup.string(),
        parentName: Yup.string(),
      })
    )
    .required('Required'),
  username: Yup.string().email('Invalid email').required('Required'),
  display_name: Yup.string().required('Required'),
  role: Yup.string().required('Required'),
});
const CreateEditUserParentChildModal = ({
  roles,
  toggleCreateUserModal,
  submitUser,
  account,
  parentLocations,
}) => {
  const [isParentSelectionDisabled, setIsParentSelectionDisabled] =
    useState(false);
  const [isChildSelectionDisabled, setIsChildSelectionDisabled] =
    useState(false);
  const [subBusinesses, setSubBusinesses] = useState([]);
  const [selectedParent, setSelectedParent] = useState([]);
  const [selectedChild, setSelectedChild] = useState([]);
  const handleParentSelection = (parents, setFieldValue) => {
    setSelectedParent(parents);
    let newSelectedChild = [];
    if (parents.length > 0) {
      parents.forEach((parent) => {
        parent.sub_businesses.forEach((subBusiness) => {
          newSelectedChild.push({
            subBusinessId: subBusiness.id,
            subBusinessName: subBusiness.display_name,
            parentName: parent.display_name,
          });
        });
      });
    }
    setSubBusinesses(newSelectedChild);
    if (parents.length > 0) {
      const newSelectedParentNames = parents.map(
        (parent) => parent.display_name
      );
      let newSelectedChild = [];
      if (selectedChild.length > 0) {
        selectedChild.forEach((child) => {
          if (newSelectedParentNames.includes(child.parentName)) {
            newSelectedChild.push(child);
          }
        });
        setFieldValue('business_ids', newSelectedChild);
        setSelectedChild(newSelectedChild);
      }
    }
  };
  const toFindDuplicates = (arry) => {
    return arry.filter((item, index) => arry.indexOf(item) !== index);
  };

  const handleChildSelection = (currentSelectedChilds, setFieldValue) => {
    let newSelcetedChild = [];
    const currentSelectedChildsIDs = currentSelectedChilds.map(
      (sub) => sub.subBusinessId
    );
    const duplicateElements = toFindDuplicates(currentSelectedChildsIDs);
    currentSelectedChilds.forEach((subBusiness) => {
      if (!duplicateElements.includes(subBusiness.subBusinessId)) {
        newSelcetedChild.push(subBusiness);
      }
    });
    setFieldValue('business_ids', newSelcetedChild);
    setSelectedChild(newSelcetedChild);
  };
  useEffect(() => {
    if (account && account.business_ids.length > 0) {
      let newSelectedParent = [];
      let newSelectedParentIDs = [];
      let newSelectedChild = [];
      let newSelectedChildIDs = [];
      let newSubBusinesses = [];
      account.business_ids.forEach((subBusinessId) => {
        const tempFilteredParent = parentLocations.find(
          (parent) => parent.id === subBusinessId
        );
        if (tempFilteredParent) {
          newSelectedParent.push(tempFilteredParent);
          newSelectedParentIDs.push(tempFilteredParent.id);
        }
      });
      // console.log('newSelectedParentIDs=', newSelectedParentIDs);
      account.business_ids.forEach((businessId) => {
        if (!newSelectedParentIDs.includes(businessId)) {
          newSelectedChildIDs.push(businessId);
        }
      });
      // console.log('newSelectedChildIDs=', newSelectedChildIDs);
      newSelectedParent.forEach((parent) => {
        parent.sub_businesses.forEach((subBusiness) => {
          if (newSelectedChildIDs.includes(subBusiness.id)) {
            newSelectedChild.push({
              subBusinessId: subBusiness.id,
              subBusinessName: subBusiness.display_name,
              parentName: parent.display_name,
            });
          }
        });
      });
      // console.log('newSelectedChild=', newSelectedChild);
      newSelectedParent.forEach((parent) => {
        parent.sub_businesses.forEach((subBusiness) => {
          newSubBusinesses.push({
            subBusinessId: subBusiness.id,
            subBusinessName: subBusiness.display_name,
            parentName: parent.display_name,
          });
        });
      });
      setSelectedParent(newSelectedParent);
      setSelectedChild(newSelectedChild);
      setSubBusinesses(newSubBusinesses);
    }
    if (parentLocations.length === 1) {
      let initialSelectedParent = [parentLocations[0]];
      setSelectedParent(initialSelectedParent);
      setIsParentSelectionDisabled(true);
      if (parentLocations[0].sub_businesses.length === 1) {
        let newSelectedChild = [];
        newSelectedChild.push({
          subBusinessId: parentLocations[0].sub_businesses[0].id,
          subBusinessName: parentLocations[0].sub_businesses[0].display_name,
          parentName: parentLocations[0].display_name,
        });
        setSelectedChild(newSelectedChild);
        setIsChildSelectionDisabled(true);
      } else {
        let newSelectedChild = [];
        parentLocations[0].sub_businesses.forEach((subBusiness) => {
          newSelectedChild.push({
            subBusinessId: subBusiness.id,
            subBusinessName: subBusiness.display_name,
            parentName: parentLocations[0].display_name,
          });
        });
        setSubBusinesses(newSelectedChild);
        setIsChildSelectionDisabled(false);
      }
    } else {
      setIsParentSelectionDisabled(false);
    }
  }, [parentLocations.length]);
  const handleSubmit = (account, values) => {
    let user = { ...values };
    const selectedPaterntsIds = selectedParent.map((parent) => parent.id);
    const selectedChildsIds = user.business_ids.map(
      (business) => business.subBusinessId
    );
    user.business_ids = selectedPaterntsIds.concat(selectedChildsIds);
    submitUser(account, user);
  };
  const renderGroup = (params) => [
    <div style={{ paddingLeft: '15px', paddingTop: '10px' }} key={params.key}>
      <span style={{ fontWeight: 600, color: 'black' }}>{params.group}</span>
    </div>,
    params.children,
  ];

  return (
    <Dialog open fullWidth maxWidth='sm'>
      <DialogTitle>
        <ModalTitle>
          {account ? 'Edit User' : 'Invite a New User'}
          <div onClick={() => toggleCreateUserModal(null)}>
            <i
              className='fal fa-times'
              style={{ cursor: 'pointer', float: 'right' }}
            ></i>
          </div>
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        <FormWrapper>
          <Formik
            initialValues={{
              business_ids: selectedChild,
              username: account ? account.username : '',
              display_name: account ? account.display_name : '',
              role: account ? account.role : '',
              is_active: account ? account.is_active : true,
              timezone: account?.timezone
                ? account.timezone
                : Intl.DateTimeFormat().resolvedOptions().timeZone,
            }}
            validationSchema={validationSchema}
          >
            {({ values, handleChange, setFieldValue, dirty, errors }) => (
              <Fragment>
                <Field
                  type='text'
                  name='username'
                  label='Email'
                  component={RenderTextField}
                  validate={required}
                  disabled={account}
                  onChange={handleChange}
                ></Field>
                <ErrorMessage name='username' />

                <Field
                  type='text'
                  name='display_name'
                  label='Display name'
                  component={RenderTextField}
                  validate={required}
                ></Field>
                <ErrorMessage name='display_name' />
                <Field
                  options={getRoles(roles)}
                  name='role'
                  component={RenderSelect}
                  placeholder='Select a User Role'
                  getOptionLabel={(role) => role.label}
                  getOptionValue={(role) => role.value}
                  onChange={(e) => setFieldValue('role', e.value)}
                />
                <ErrorMessage name='role' />
                <Autocomplete
                  multiple
                  id='checkboxes-tags-demo'
                  value={selectedParent}
                  options={parentLocations}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.display_name}
                  onChange={(e, selectedLocations) => {
                    handleParentSelection(selectedLocations, setFieldValue);
                  }}
                  renderOption={(option, { selected }) => (
                    <React.Fragment style={{ marginLeft: 15 }}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color='primary'
                      />
                      {option.display_name}
                    </React.Fragment>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Parent Location(s)'
                    />
                  )}
                  disabled={isParentSelectionDisabled}
                />
                {selectedParent.length > 0 && (
                  <>
                    <Autocomplete
                      multiple
                      id='checkboxes-tags-demo'
                      disableCloseOnSelect
                      name='business_ids'
                      value={selectedChild}
                      options={subBusinesses}
                      getOptionLabel={(option) =>
                        `${option.subBusinessName} (${option.parentName})`
                      }
                      onChange={(e, selectedChild) => {
                        handleChildSelection(selectedChild, setFieldValue);
                      }}
                      disableListWrap={false}
                      groupBy={(option) => option.parentName}
                      renderGroup={renderGroup}
                      renderOption={(option) => (
                        <div style={{ marginLeft: '15px' }}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selectedChild
                              .map((buss) => buss.subBusinessName)
                              .includes(option.subBusinessName)}
                            color='primary'
                          />
                          {option.subBusinessName}
                        </div>
                      )}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Child Location(s)'
                          placeholder=''
                        />
                      )}
                      disabled={isChildSelectionDisabled}
                    />
                    <ErrorMessage name='business_ids' />
                  </>
                )}
                {account && (
                  <div>
                    <Switch
                      checked={values.is_active}
                      onChange={handleChange}
                      style={{ display: 'inline' }}
                      color='primary'
                      name='is_active'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <SwitchText>User is Active</SwitchText>
                  </div>
                )}
                <ButtonsWrapper>
                  <Button
                    style={{
                      background: 'none',
                      color: '#191818',
                      border: 'none',
                    }}
                    onClick={() => toggleCreateUserModal(null)}
                  >
                    Cancel
                  </Button>
                  <div />
                  <Button
                    type='submit'
                    onClick={() => handleSubmit(account, values)}
                    disabled={!(Object.keys(errors).length === 0 && dirty)}
                  >
                    {account ? 'Save' : 'Send Invite'}
                  </Button>
                </ButtonsWrapper>
              </Fragment>
            )}
          </Formik>
        </FormWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default CreateEditUserParentChildModal;
