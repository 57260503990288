import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import {
  NoData,
  ChartCard,
  TitleWrapper,
  Title,
  TotalWrapper,
  TitleSpan,
} from '../../components/DashboardElements';
import { findMaxValue, findStepSize } from '../../utils/chart';
import Moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

class ActiveUsers extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { activeUsersData } = this.props;
    const { interval_value } = this.props.stateInterval;
    const activeUsersCurrent = activeUsersData.period_now || [];
    const activeUsersPrevious = activeUsersData.period_prev || [];
    let userDatesData = [];
    if (Array.isArray(activeUsersData.dates)) {
      userDatesData = [...activeUsersData.dates];
    } else {
      userDatesData = Object.values(activeUsersData.dates);
    }

    const isCustomDateMoreThanThreeMonths =
      this.props.timePeriodType === 'custom-date';
    //DATE INTERVAL
    var userDates = userDatesData?.slice(interval_value); //.reverse();
    if (isCustomDateMoreThanThreeMonths) {
      const dateLength = activeUsersData?.dates?.length ?? 1;
      const sliceSize = dateLength / 2;
      userDates = userDatesData.slice(sliceSize);
    } else if (interval_value == 90) {
      userDates = userDatesData?.slice(90);
    } else if (interval_value == 1) {
      userDates = userDatesData?.slice(12);
    }

    const total = activeUsersData.total;
    var suggestedMaxCurrent = findMaxValue(activeUsersCurrent);
    var stepSize = findStepSize(activeUsersCurrent);

    var suggestedMaxPrevious = findMaxValue(activeUsersPrevious);
    var stepSizePrevious = findStepSize(activeUsersPrevious);

    if (
      stepSize <= 1 &&
      stepSizePrevious <= 1 &&
      suggestedMaxCurrent <= 7 &&
      suggestedMaxPrevious <= 7
    ) {
      stepSize = 1;
      suggestedMaxCurrent = 7;
    } else {
      stepSize = '';
    }

    const userData = {
      labels: userDates,
      datasets: [
        {
          label: 'current period',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,0.4)',
          //borderColor: 'rgba(75,192,192,1)',
          borderColor: '#296bde',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          //pointBorderColor: 'rgba(75,192,192,1)',
          pointBorderColor: '#296bde',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          //pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBackgroundColor: '#296BDE',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1.5,
          pointHitRadius: 10,
          fontWeight: 'bold',
          borderWidth: 1,
          data: activeUsersCurrent,
        },
        {
          label: 'previous period',
          fill: false,
          lineTension: 0,
          backgroundColor: 'rgba(75,192,192,0.4)',
          //borderColor: 'rgba(75,192,192,1)',
          borderColor: '#979eaa',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          //pointBorderColor: 'rgba(75,192,192,1)',
          pointBorderColor: '#979eaa',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          //pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBackgroundColor: '#979eaa',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1.5,
          pointHitRadius: 10,
          fontWeight: 'bold',
          borderWidth: 1,
          data: activeUsersPrevious,
        },
      ],
    };

    return (
      <ChartCard>
        <TitleWrapper>
          <Title>
            <TitleSpan>
              Active Users{' '}
              <Tooltip
                title='The number of users who have accessed the app'
                placement='top'
                arrow
              >
                <span>
                  <TooltipIcon />
                </span>
              </Tooltip>
            </TitleSpan>{' '}
            <br />
            <svg height='10' width='17.7' style={{ paddingTop: '6px' }}>
              <line
                x1='0'
                y1='0'
                x2='17.7'
                y2='0'
                style={{ stroke: '#296bde', strokeWidth: '2' }}
              />
            </svg>{' '}
            <span style={{ color: '#296bde' }}> current period </span>
            &nbsp;
            <svg height='10' width='17.7' style={{ paddingTop: '6px' }}>
              <line
                x1='0'
                y1='0'
                x2='17.7'
                y2='0'
                style={{ stroke: '#979eaa', strokeWidth: '2' }}
              />
            </svg>{' '}
            <span style={{ color: '#979eaa' }}> previous period </span>
          </Title>
          <TotalWrapper>
            <span> Total </span>
            <TitleSpan> {(total || 0).toLocaleString()} </TitleSpan>
          </TotalWrapper>
        </TitleWrapper>
        {(!activeUsersCurrent || activeUsersCurrent.length == 0) && (
          <NoData>No Data available</NoData>
        )}
        <Line
          data={userData}
          options={{
            legend: {
              display: false,
              labels: {
                fontColor: '#296bde',
                useLineStyle: true,
              },
            },
            scales: {
              xAxes: [
                {
                  display: true,
                  // gridLines: {
                  //     //zeroLineWidth: 2,
                  //     color: '#f2f2f2',
                  //     //zeroLineColor:"rgba(255,255,255,10)",
                  // },
                  scaleLabel: {
                    display: false,
                    //labelString: 'Date',
                  },
                  ticks: {
                    callback: function (value) {
                      if (interval_value == 1)
                        var day = Moment(value).format('MMM');
                      //new Date(value).toLocaleDateString('en-EN', { day:'numeric'});
                      else var day = Moment(value).format('DD MMM');
                      //const month = new Date(value).toLocaleDateString('en-EN', { month:'short'});
                      //const str= `${day}`; //`\n${month}`;
                      return day;
                    },
                    //max: maxValue,
                    //autoSkip: false,
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    zeroLineColor: '#979797',
                    color: ['#f2f2f2'],
                  },
                  ticks: {
                    suggestedMin: 0,
                    beginAtZero: true,
                    stepSize: stepSize,
                    suggestedMax: suggestedMaxCurrent,
                  },
                  scaleLabel: {
                    display: false,
                    //labelString: '# Of Sign Ups',
                  },
                },
              ],
            },
          }}
        />
      </ChartCard>
    );
  }
}

export default ActiveUsers;
