import {
  GET_OFFERS_REQUEST,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  TOGGLE_OFFER_AVAILABILITY_SUCCESS,
  INITIATE_CREATE_OFFER,
  CREATE_OFFER_REQUEST,
  CREATE_OFFER_SUCCESS,
  CREATE_OFFER_FAILURE,
  INITIATE_EDIT_OFFER,
  EDIT_OFFER_REQUEST,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAILURE,
  RESET_OFFER_STATE,
} from './offerTypes';
import { api } from '../../../api';
import { createAlert } from '../../Alert/actions';
import moment from 'moment';
import ReactGA from 'react-ga';
import { getPurePayload } from '../../../utils/purePayload';

export function getOffers(
  params = { page_number: 1, page_size: 1000, searchString: '' }
) {
  return function (dispatch, getState) {
    const { role } = getState().accountReducer;
    dispatch({ type: GET_OFFERS_REQUEST });
    let isConfigOnly = false;
    if (['ROLE_BUSINESS_ADMIN', 'ROLE_FINANCE_ADMIN'].includes(role)) {
      isConfigOnly = true;
    }
    const promise = isConfigOnly
      ? Promise.all([api.get(`/offers/config`)])
      : Promise.all([
          api.get(
            `/offers?page_number=${params.page_number}&page_size=${params.page_size}`
          ),
          api.get(`/offers/config`),
        ]);
    return promise.then(
      (response) => {
        if (['ROLE_BUSINESS_ADMIN', 'ROLE_FINANCE_ADMIN'].includes(role)) {
          dispatch({
            type: GET_OFFERS_SUCCESS,
            options: response[0].data.data,
            meta: response[0].data.meta,
          });
        } else {
          dispatch({
            type: GET_OFFERS_SUCCESS,
            allOffers: response[0].data.data,
            options: response[1].data.data,
            meta: response[0].data.meta,
          });
        }
      },
      (error) => {
        ReactGA.event({
          category: 'Unsuccessful Response',
          action: 'Getting Offers',
          label: 'Unsuccessfully Getting Offers',
        });
        dispatch({ type: GET_OFFERS_FAILURE, error });
      }
    );
  };
}

export function getOfferConfig() {
  return function (dispatch, getState) {
    dispatch({ type: GET_OFFERS_REQUEST });

    return api.get(`/offers/config`).then(
      (response) => {
        dispatch({
          type: GET_OFFERS_SUCCESS,
          options: response.data.data,
          meta: response.data.meta,
        });
      },
      (error) => {
        ReactGA.event({
          category: 'Unsuccessful Response',
          action: 'Getting Offers',
          label: 'Unsuccessfully Getting Offers',
        });
        dispatch({ type: GET_OFFERS_FAILURE, error });
      }
    );
  };
}

export function toggleOfferAvailability(offer) {
  return function (dispatch) {
    return api
      .patch(
        `/offers/${offer.offer_details.offer_id}`,
        toggleOfferFormat(offer)
      )
      .then(() => {
        dispatch({ type: TOGGLE_OFFER_AVAILABILITY_SUCCESS, offer });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer updated successfully.',
          })
        );
      });
  };
}

export function initiateCreateOffer() {
  return {
    type: INITIATE_CREATE_OFFER,
  };
}
const truncatedOffer = (offer) => {
  const trncatedConditions = offer?.conditions?.reduce((acc, cur) => {
    if (cur.source !== 'mobile' && cur.source !== 'web') {
      let truncatedCanditionRequirements = { ...cur };
      if (cur.item_conditions?.discounted_products?.length > 0) {
        truncatedCanditionRequirements.item_conditions.discounted_products =
          cur.item_conditions?.discounted_products.map((item) => {
            const product = typeof item === 'string' ? item?.trim() : item;
            return parseInt(product);
          });
      }
      if (cur.item_conditions?.required_products?.length > 0) {
        truncatedCanditionRequirements.item_conditions.required_products =
          cur.item_conditions?.required_products.map((item) => {
            const product = typeof item === 'string' ? item?.trim() : item;
            return parseInt(product);
          });
      }
      if (cur.item_conditions?.eligible_products?.length > 0) {
        truncatedCanditionRequirements.item_conditions.eligible_products =
          cur.item_conditions?.eligible_products.map((item) => {
            const product = typeof item === 'string' ? item?.trim() : item;
            return parseInt(product);
          });
      }
      acc.push(truncatedCanditionRequirements);
    } else {
      acc.push(cur);
    }
    return acc;
  }, []);
  const truncatedOffer = { ...offer, conditions: trncatedConditions };
  return truncatedOffer;
};
export function createOffer(offer) {
  const offerPayload = truncatedOffer(offer);
  return function (dispatch) {
    offer = createOfferStructure(offerPayload);
    if (
      offer.offer_details.template_name === 'first-time' ||
      offer.offer_details.template_name === 'carryover-loyalty'
    ) {
      delete offer.conditions;
    }
    const purePayload = getPurePayload(offer);
    dispatch({ type: CREATE_OFFER_REQUEST });
    api
      .post(`/offers`, purePayload)
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer created successfully',
          })
        );
        dispatch({ type: CREATE_OFFER_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: CREATE_OFFER_FAILURE });
        Object.values(error.response.data.errors.details).forEach((message) => {
          dispatch(
            createAlert({ type: 'error', message: message.replace(/_/g, ' ') })
          );
        });
      });
  };
}

export function editOffer(offer) {
  const offerPayload = truncatedOffer(offer);
  //
  return function (dispatch, getState) {
    const { editingOfferId } = getState().offersReducer;
    if (offerPayload.offer_details.template_name === 'first-time') {
      delete offerPayload.conditions;
    }
    const purePayload = getPurePayload(offerPayload);
    const editingOffer = createOfferStructure(purePayload);
    dispatch({ type: EDIT_OFFER_REQUEST });
    api
      .put(`/offers/${editingOfferId}`, editingOffer)
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Offer edited successfully',
          })
        );
        dispatch({ type: EDIT_OFFER_SUCCESS });
      })
      .catch((error) => {
        dispatch({ type: EDIT_OFFER_FAILURE });
        Object.values(error.response.data.errors.details).forEach((message) => {
          dispatch(
            createAlert({ type: 'error', message: message.replace(/_/g, ' ') })
          );
        });
      });
  };
}

export function initiateEditOffer(offer) {
  return function (dispatch) {
    const editingOffer = createOfferStructure(offer);
    dispatch({
      type: INITIATE_EDIT_OFFER,
      editingOffer,
      editingOfferId: offer.offer_details.offer_id,
    });
  };
}

export function resetOfferStates() {
  return {
    type: RESET_OFFER_STATE,
  };
}

const toggleOfferFormat = (offer) => {
  return {
    is_active: !offer.offer_details.is_active,
  };
};

const createOfferStructure = (offer) => {
  let newOfferStructure = {};
  switch (offer.offer_details.template_name) {
    case 'first-time': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details?.title,
          auto_redeem: offer.offer_details?.auto_redeem,
          description: offer.offer_details?.description,
          details: offer.offer_details?.details,
          image_url: offer.offer_details?.image_url,
          is_active: offer.offer_details?.is_active,
          label: offer.offer_details?.label,
          reusable: offer.offer_details?.reusable,
          subtitle: offer.offer_details?.subtitle,
          template_name: offer.offer_details?.template_name,
          template_values: {
            total_amount: offer.offer_details?.template_values.total_amount,
          },
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments,
      };
      if (offer.offer_details?.template_values.loyalty_tier) {
        newOfferStructure = {
          ...newOfferStructure,
          offer_details: {
            ...newOfferStructure.offer_details,
            template_values: {
              ...newOfferStructure.offer_details.template_values,
              loyalty_tier: offer.offer_details?.template_values.loyalty_tier,
            },
          },
        };
      }
      return newOfferStructure;
    }
    case 'discounted-item': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details?.title,
          auto_redeem: offer.offer_details?.auto_redeem,
          description: offer.offer_details?.description,
          details: offer.offer_details?.details,
          image_url: offer.offer_details?.image_url,
          is_active: offer.offer_details?.is_active,
          label: offer.offer_details?.label,
          reusable: offer.offer_details?.reusable,
          subtitle: offer.offer_details?.subtitle,
          template_name: offer.offer_details?.template_name,
          template_values: {
            //TODO loyalty tier unimplemented check spec
            apply_multiple: offer.offer_details.template_values.apply_multiple,
            discount: offer.offer_details.template_values.discount,
            discount_type: offer.offer_details.template_values.discount_type,
          },
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments,
        conditions: offer.conditions.map((condition) => ({
          ...condition,
          item_conditions: { ...condition.item_conditions, sort_price: 'DESC' },
        })),
      };

      if (offer.offer_details.template_values.expiry_date) {
        newOfferStructure.offer_details.template_values.expiry_date =
          offer.offer_details.template_values.expiry_date;
      }
      if (offer.offer_details?.template_values.loyalty_tier) {
        newOfferStructure = {
          ...newOfferStructure,
          offer_details: {
            ...newOfferStructure.offer_details,
            template_values: {
              ...newOfferStructure.offer_details.template_values,
              loyalty_tier: offer.offer_details?.template_values.loyalty_tier,
            },
          },
        };
      }
      return newOfferStructure;
    }
    case 'free-item': {
      if (offer.offer_details.template_values.points_cost == null) {
        newOfferStructure = {
          offer_details: {
            title: offer.offer_details?.title,
            auto_redeem: offer.offer_details?.auto_redeem,
            description: offer.offer_details?.description,
            details: offer.offer_details?.details,
            image_url: offer.offer_details?.image_url,
            is_active: offer.offer_details?.is_active,
            label: offer.offer_details?.label,
            reusable: offer.offer_details?.reusable,
            subtitle: offer.offer_details?.subtitle,
            template_name: offer.offer_details?.template_name,
            template_values: {
              //TODO loalty tier not being used
              apply_multiple:
                offer.offer_details.template_values.apply_multiple,

              max_discount: Number(
                offer.offer_details.template_values.max_discount
              ),
            },
          },
          businesses: offer.businesses,
          customers: offer.customers,
          segments: offer.segments,
          // conditions: [
          //   {
          //     source: offer.conditions[0].source,
          //     is_active: offer.conditions[0].is_active,
          //     item_conditions: {
          //       // required_product_condition: // TODO why not this
          //       //   offer.conditions[0].item_conditions.required_product_condition,
          //       required_products: offer.conditions[0].item_conditions
          //         .required_products
          //         ? offer.conditions[0].item_conditions.required_products
          //         : [],
          //       required_variants: offer.conditions[0].item_conditions
          //         .required_variants
          //         ? offer.conditions[0].item_conditions.required_variants
          //         : [],
          //       eligible_products:
          //         offer.conditions[0].item_conditions.eligible_products,
          //       eligible_variants:
          //         offer.conditions[0].item_conditions.eligible_variants
          //     }
          //   }
          // ]
          conditions: offer.conditions.map((condition, index) => {
            return {
              ...condition,
              item_conditions: {
                eligible_products: condition.item_conditions.eligible_products,
                eligible_variants: condition.item_conditions.eligible_variants,
                required_products: condition.item_conditions.required_products,
                required_variants: condition.item_conditions.required_variants,
              },
            };
          }),
        };
        if (offer.offer_details.template_values.expiry_date) {
          newOfferStructure.offer_details.template_values.expiry_date =
            offer.offer_details.template_values.expiry_date;
        }
      } else {
        newOfferStructure = {
          offer_details: {
            title: offer.offer_details?.title,
            auto_redeem: offer.offer_details?.auto_redeem,
            description: offer.offer_details?.description,
            details: offer.offer_details?.details,
            image_url: offer.offer_details?.image_url,
            is_active: offer.offer_details?.is_active,
            label: offer.offer_details?.label,
            reusable: offer.offer_details?.reusable,
            subtitle: offer.offer_details?.subtitle,
            template_name: offer.offer_details?.template_name,
            template_values: {
              //TODO loalty tier not being used
              apply_multiple:
                offer.offer_details.template_values.apply_multiple,

              max_discount: Number(
                offer.offer_details.template_values.max_discount
              ),
              points_cost: Number(
                offer.offer_details.template_values.points_cost
              ), // set to zero if not being used
            },
          },
          businesses: offer.businesses,
          customers: offer.customers,
          segments: offer.segments,
          // conditions: [
          //   {
          //     source: offer.conditions[0].source,
          //     is_active: offer.conditions[0].is_active,

          //     item_conditions: {
          //       // required_product_condition: // TODO why not this
          //       //   offer.conditions[0].item_conditions.required_product_condition,
          //       required_products: offer.conditions[0].item_conditions
          //         .required_products
          //         ? offer.conditions[0].item_conditions.required_products
          //         : [],
          //       required_variants: offer.conditions[0].item_conditions
          //         .required_variants
          //         ? offer.conditions[0].item_conditions.required_variants
          //         : [],
          //       eligible_products:
          //         offer.conditions[0].item_conditions.eligible_products,
          //       eligible_variants:
          //         offer.conditions[0].item_conditions.eligible_variants
          //     }
          //   }
          // ]
          conditions: offer.conditions.map((condition, index) => {
            return {
              ...condition,
              item_conditions: {
                eligible_products: condition.item_conditions.eligible_products,
                eligible_variants: condition.item_conditions.eligible_variants,
                required_products: condition.item_conditions.required_products
                  ? condition.item_conditions.required_products
                  : [],
                required_variants: condition.item_conditions.required_variants
                  ? condition.item_conditions.required_variants
                  : [],
              },
            };
          }),
        };
        if (offer.offer_details.template_values.expiry_date) {
          newOfferStructure.offer_details.template_values.expiry_date =
            offer.offer_details.template_values.expiry_date;
        }
      }
      if (offer.offer_details?.template_values.loyalty_tier) {
        newOfferStructure = {
          ...newOfferStructure,
          offer_details: {
            ...newOfferStructure.offer_details,
            template_values: {
              ...newOfferStructure.offer_details.template_values,
              loyalty_tier: offer.offer_details?.template_values.loyalty_tier,
            },
          },
        };
      }
      return newOfferStructure;
    }
    case 'birthday-free-item': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details?.title,
          auto_redeem: offer.offer_details?.auto_redeem,
          description: offer.offer_details?.description,
          details: offer.offer_details?.details,
          image_url: offer.offer_details?.image_url,
          is_active: offer.offer_details?.is_active,
          label: offer.offer_details?.label,
          reusable: offer.offer_details?.reusable,
          subtitle: offer.offer_details?.subtitle,
          template_name: offer.offer_details?.template_name,
          template_values: {
            //TODO loyalty tier
            days_available: Number(
              offer.offer_details.template_values.days_available
            ),
            max_discount: offer.offer_details.template_values.max_discount,
          },
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments,
        // conditions: [
        //   {
        //     source: offer.conditions[0].source,
        //     is_active: offer.conditions[0].is_active,
        //     item_conditions: {
        //       eligible_products:
        //         offer.conditions[0].item_conditions.eligible_products
        //     }
        //   }
        // ]
        conditions: offer.conditions.map((condition, index) => {
          return {
            ...condition,
            item_conditions: {
              eligible_products: condition.item_conditions.eligible_products,
            },
          };
        }),
      };
      if (offer.offer_details?.template_values.loyalty_tier) {
        newOfferStructure = {
          ...newOfferStructure,
          offer_details: {
            ...newOfferStructure.offer_details,
            template_values: {
              ...newOfferStructure.offer_details.template_values,
              loyalty_tier: offer.offer_details?.template_values.loyalty_tier,
            },
          },
        };
      }
      return newOfferStructure;
    }
    case 'birthday-discounted-item': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details?.title,
          auto_redeem: offer.offer_details?.auto_redeem,
          description: offer.offer_details?.description,
          details: offer.offer_details?.details,
          image_url: offer.offer_details?.image_url,
          is_active: offer.offer_details?.is_active,
          label: offer.offer_details?.label,
          reusable: offer.offer_details?.reusable,
          subtitle: offer.offer_details?.subtitle,
          template_name: offer.offer_details?.template_name,
          template_values: {
            //TODO loyalty
            discount: offer.offer_details.template_values.discount,
            discount_type: offer.offer_details.template_values.discount_type,
            days_available: Number(
              offer.offer_details.template_values.days_available
            ),
          },
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments,
        conditions: offer.conditions.map((condition) => ({
          ...condition,
          item_conditions: { ...condition.item_conditions },
        })),
      };
      if (offer.offer_details?.template_values.loyalty_tier) {
        newOfferStructure = {
          ...newOfferStructure,
          offer_details: {
            ...newOfferStructure.offer_details,
            template_values: {
              ...newOfferStructure.offer_details.template_values,
              loyalty_tier: offer.offer_details?.template_values.loyalty_tier,
            },
          },
        };
      }
      return newOfferStructure;
    }
    case 'carryover-loyalty': {
      newOfferStructure = {
        offer_details: {
          title: offer.offer_details?.title,
          auto_redeem: offer.offer_details?.auto_redeem,
          description: offer.offer_details?.description,
          details: offer.offer_details?.details,
          image_url: offer.offer_details?.image_url,
          is_active: offer.offer_details?.is_active,
          label: offer.offer_details?.label,
          reusable: offer.offer_details?.reusable,
          subtitle: offer.offer_details?.subtitle,
          template_name: offer.offer_details?.template_name,
          template_values: {
            total_amount: offer.offer_details?.template_values.total_amount,
            points_cost: offer.offer_details?.template_values.points_cost,
          },
        },
        businesses: offer.businesses,
        customers: offer.customers,
        segments: offer.segments,
      };
      if (offer.offer_details?.template_values.loyalty_tier) {
        newOfferStructure = {
          ...newOfferStructure,
          offer_details: {
            ...newOfferStructure.offer_details,
            template_values: {
              ...newOfferStructure.offer_details.template_values,
              loyalty_tier: offer.offer_details?.template_values.loyalty_tier,
            },
          },
        };
      }
      return newOfferStructure;
    }
  }
};

export function updateOffersRequestParams(requestParams) {
  return {
    type: 'UPDATE_OFFERS_REQUEST_PARAMS',
    payload: requestParams,
  };
}

export function updateSortedOffersList(offersList, sortOrder) {
  return {
    type: 'UPDATE_SORTED_OFFERS_LIST',
    offersList: offersList,
    sortOrder: sortOrder,
  };
}
