import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Table, TBody, TrHead, Td, Tr, Th } from 'merchant-portal-components';

const NoOfferCredits = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 250px;
  font-size: 38px;
  color: #d8d8d8;
`;

const renderHeaders = () => (
  <TrHead data-test='component-TrHead'>
    <Th align='left'>Credit code</Th>
    <Th align='left'>Amount</Th>
  </TrHead>
);

const renderRows = (offerCredits) =>
    offerCredits.map((offerCredit, index) => (
    <Tr
      key={index}
      data-test='component-Tr'
    >
      <Td align='left'>{offerCredit.label}</Td>
      <Td align='left'> {Number(offerCredit.total_amount) 
          ? Number(offerCredit.total_amount).toFixed(2) 
          : '0.00'}</Td>
    </Tr>
  ));

export const CustomerOfferCreditTable = (props) => {
  const { offerCredits , offerCreditBalance} = props;
  return (

    <Fragment>
      <Table data-test='component-Table'>
        <thead>{renderHeaders()}</thead>
        {offerCredits.length ? (
          <TBody striped>
            {renderRows(offerCredits, history)}
          </TBody>
        ) : null}
      </Table>
      {!offerCredits.length ? (
        <NoOfferCredits data-test='component-NoOfferCredits'>
          No Record Found
        </NoOfferCredits>
      ) : null}
    </Fragment>
  );
};

export default CustomerOfferCreditTable;
