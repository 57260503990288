import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from '../OfferEngineActions';
import OfferEngineDetails from './OfferEngineDetails';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import BackButton from '../../../components/BackButton';
import { getProductsVariants } from '../../MenuManagement/Products/productsActions';
import moment from 'moment';
import { isOnlyLoyaltyAdminUser } from '../../../utils/appRoles';

const BackText = styled.div`
  margin-bottom: 15px;
  margin-right: auto;
  &:hover {
    color: #3051d4;
  }
`;
const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;
const OfferDetailsContainer = ({
  createOffer,
  requestingCreateOffer,
  editingOfferEngine,
  offerAvailability,
  requestingEditOffer,
  resetOfferEngineStates,
  appMetaData,
  customerList,
  customerData,
  segmentList,
  businessList,
  offerEngineSource,
  offerEngineProducts,
  offerPromoCode,
  getPromos,
  updatePromoRequestParams,
  requestParamsPromo,
  history,
  allProductVariants,
  getProductsVariants,
  currentAssignedCustomers,
  orderTypesList,
  isCopyingOfferEngine,
  loyaltyTiers,
}) => {
  useEffect(() => {
    if (!isOnlyLoyaltyAdminUser(appMetaData.configuration)) {
      getProductsVariants({
        page_number: 1,
        page_size: '4000',
        search_string: '',
      });
    }
  }, []);
  const isPointsEnabled =
    appMetaData.configuration.program_type &&
    appMetaData.configuration.program_type == 'points';
  return (
    <Fragment>
      <BreadCrumbWrapper>
        <BackButton
          pageName='Offers List'
          onClick={() => resetOfferEngineStates()}
        />
        <BreadCrumb
          style={{ margin: ' 0px' }}
          pageName={
            editingOfferEngine && !isCopyingOfferEngine
              ? 'Edit Offer'
              : 'Create Offer'
          }
        />
      </BreadCrumbWrapper>

      <OfferEngineDetails
        initialValues={
          editingOfferEngine || {
            offer_details: {
              title: '',
              label: '',
              is_active: true,
              auto_redeem: false,
              reusable: false,
              is_visible: true,
              is_carryover: false,
              apply_multiple: true,
              description: '',
              sort_price: 'ASC',
              template_name: '',
              start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
              expiry_date: '',
              businesses: [],
              segments: [],
              order_types: [],
              conditions: [],
              never_expires: true,
              availability: [
                {
                  start_day_of_week: 1,
                  end_day_of_week: 1,
                  start_time: '00:00:00',
                  end_time: '23:59:59',
                },
                {
                  start_day_of_week: 2,
                  end_day_of_week: 2,
                  start_time: '00:00:00',
                  end_time: '23:59:59',
                },
                {
                  start_day_of_week: 3,
                  end_day_of_week: 3,
                  start_time: '00:00:00',
                  end_time: '23:59:59',
                },
                {
                  start_day_of_week: 4,
                  end_day_of_week: 4,
                  start_time: '00:00:00',
                  end_time: '23:59:59',
                },
                {
                  start_day_of_week: 5,
                  end_day_of_week: 5,
                  start_time: '00:00:00',
                  end_time: '23:59:59',
                },
                {
                  start_day_of_week: 6,
                  end_day_of_week: 6,
                  start_time: '00:00:00',
                  end_time: '23:59:59',
                },
                {
                  start_day_of_week: 7,
                  end_day_of_week: 7,
                  start_time: '00:00:00',
                  end_time: '23:59:59',
                },
              ],
            },
            businesses: [],
            segments: [],
            customers: [],
            conditions: [],
          }
        }
        isRequesting={requestingCreateOffer || requestingEditOffer}
        editingOfferEngine={editingOfferEngine}
        isPointsEnabled={isPointsEnabled}
        offerAvailability={offerAvailability}
        customerList={customerList}
        customerData={customerData}
        segmentList={segmentList}
        businessList={businessList}
        offerEngineSource={offerEngineSource}
        offerEngineProducts={offerEngineProducts}
        offerPromoCode={offerPromoCode}
        createOffer={createOffer}
        getPromos={getPromos}
        updatePromoRequestParams={updatePromoRequestParams}
        requestParamsPromo={requestParamsPromo}
        history={history}
        allProductVariants={allProductVariants}
        getProductsVariants={getProductsVariants}
        currentAssignedCustomers={currentAssignedCustomers}
        orderTypesList={orderTypesList}
        loyaltyTiers={loyaltyTiers}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  requestingEditOffer: state.OfferEngineReducer.requestingEditOffer,
  requestParamsPromo: state.OfferEngineReducer.requestParamsPromo,
  requestingCreateOffer: state.OfferEngineReducer.requestingCreateOffer,
  editingOfferEngine: state.OfferEngineReducer.editingOfferEngine,
  offerAvailability: state.OfferEngineReducer.offerAvailability,
  appMetaData: state.accountReducer.appMetaData,
  customerList: state.OfferEngineReducer.customerList,
  customerData: state.OfferEngineReducer.customerData,
  currentAssignedCustomers: state.OfferEngineReducer.currentAssignedCustomers,
  segmentList: state.OfferEngineReducer.segmentList,
  businessList: state.OfferEngineReducer.businessList,
  offerEngineSource: state.OfferEngineReducer.offerEngineSource,
  offerEngineProducts: state.OfferEngineReducer.offerEngineProducts,
  offerPromoCode: state.OfferEngineReducer.offerPromoCode,
  requestParams: state.OfferEngineReducer.requestParams,
  allProductVariants: state.productsReducer.allProductVariants,
  orderTypesList: state.fullReducer.orderTypesList,
  isCopyingOfferEngine: state.OfferEngineReducer.isCopyingOfferEngine,
  loyaltyTiers: state.OfferEngineReducer.loyaltyTiers,
});
export default connect(mapStateToProps, { ...actions, getProductsVariants })(
  OfferDetailsContainer
);
