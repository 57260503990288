import React from "react";
import styled from "styled-components";
import { Title, Card } from "../../AddressAndDetails/components/styles";

const Container = styled(Card)`
  height: 171px;
  padding: 20px 30px 20px 40px;
  border-radius: 20px;
  border: solid 1px #e0e0e0;
  width: inherit;
  background: transparent;
  box-shadow: none;
`;

const BodyContent = styled.div`
  height: 17px;
  flex-grow: 0;
  margin: 16px 0 24px 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
  color: #313131;
`;

const ChooseImageButton = styled.div`
  width: 240px;
  height: 43px;
  border-radius: 8px;
  background-color: #e6ebff;
  font-weight: 600;
  color: #2440ae;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin-top: 24px;

  &:hover {
    background-color: #d9e0ff;
  }
`;

function DeactivateLocation({ setOpenConfirmationModal }) {
  return (
    <Container>
      <Title>Deactivate Location</Title>
      <BodyContent>
        By deactivating this location it will not be visible or able to accept
        orders on mobile and/or web apps. You can reactivate this location at
        any time.
      </BodyContent>
      <ChooseImageButton
        onClick={() => {
          setOpenConfirmationModal(true);
        }}
      >
        Deactivate Location
      </ChooseImageButton>
    </Container>
  );
}

export default DeactivateLocation;
