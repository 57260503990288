import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from '@material-ui/core';
export const SectionTitle = styled.div`
  width: 100%;
  display: ${(props) => props.display || 'block'};
  margin: ${(props) => props.margin || '0px 0 12px 0'};
  font-family: 'Montserrat';
  font-size: ${(props) => props.fontSize || '21px'};
  font-weight: ${(props) => props.fontWeight || '600'};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: ${(props) => props.color || '#191818'};
  text-align: ${(props) => props.textAlign || 'left'};
`;
export const SectionSubTitle = styled.div`
  display: ${(props) => props.display || 'block'};
  font-family: 'Montserrat';
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin: ${(props) => props.margin || '0'};
`;
export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  padding: ${(props) => props.padding || '0'};
  margin: ${(props) => props.margin || '30px 0 0 0'};
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  text-align: left;
`;
export const Right = styled.div`
  margin: 0;
  text-align: right;
`;
export const CancelButton = styled.button`
  height: 20px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2440ae;
  cursor: pointer;
  border: none;
  background-color: inherit;
`;
export default function MoreLocationsModal({ businesses, open, handleClose }) {
  const [filteredBusinesses, setFilteredBusinesses] = useState(businesses);
  useEffect(() => {
    setFilteredBusinesses(businesses);
  }, [businesses]);
  const filterBusinesses = useCallback(
    (searchString) => {
      if (searchString) {
        setFilteredBusinesses(
          businesses.filter((business) =>
            business.toLowerCase().includes(searchString.toLowerCase())
          )
        );
      } else {
        setFilteredBusinesses(businesses);
      }
    },
    [businesses]
  );
  console.log('filteredBusinesses', filteredBusinesses);
  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='sm'
        onClose={() => handleClose(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          style: { borderRadius: 10, height: '700px' },
        }}
      >
        <DialogTitle id='alert-dialog-title'>
          <SectionTitle display='inline' fontWeight='normal' fontSize='21px'>
            Report Locations
          </SectionTitle>
          <i
            className='fa fa-times'
            style={{
              fontWeight: '200',
              float: 'right',
              margin: '0',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          ></i>
        </DialogTitle>
        <DialogContent>
          <TextField
            id='outlined-basic'
            label='Search Location'
            variant='outlined'
            onChange={(e) => filterBusinesses(e.target.value)}
            style={{ width: '100%', marginBottom: '20px' }}
          />
          {filteredBusinesses.map((business) => (
            <SectionSubTitle key={`more-modal-&{business.id}`}>
              {business}
            </SectionSubTitle>
          ))}
        </DialogContent>
      </Dialog>
    </div>
  );
}
