import React from 'react';
import { Table } from '../../../../components/Table/TableElements';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

function SettingsTable(props) {
  const {
    columns,
    rows,
    fields,
    getEmployeeInformation,
    selectedEmployee,
    businessesLocations,
    employeeLocations,
    patchEmployee,
    passwordVerifier,
  } = props;

  return (
    <Table style={{ borderLeft: 'none', borderRight: 'none' }}>
      <TableHeader columns={columns} />
      <TableBody
        rows={rows}
        fields={fields}
        selectedEmployee={selectedEmployee}
        getEmployeeInformation={(employee) => getEmployeeInformation(employee)}
        employeeLocations={employeeLocations}
        businessesLocations={businessesLocations}
        patchEmployee={patchEmployee}
        passwordVerifier={passwordVerifier}
      />
    </Table>
  );
}

export default SettingsTable;
