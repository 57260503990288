import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ComboAvailability from '../components/ComboAvailability';
import { LoadingBlueLarge } from 'merchant-portal-components';
import {
  getComboBusinesses,
  getComboAvailabilities,
  handleComboAvailabilitiesSubmit,
} from '../../../combosAction';

const ComboAvailabilityContainer = ({
  initValues,
  businesses,
  getComboBusinesses,
  getComboAvailabilities,
  comboBusinesses,
  allSubBusinesses,
  comboAvailabilities,
  isRequestingGetCombosBusinesses,
  isRequestingGetCombosAvailabilities,
  handleComboAvailabilitiesSubmit,
  submitAvailabilitiesIsRequesting,
  orderTypesList,
}) => {
  useEffect(() => {
    initValues.id && getComboBusinesses(initValues);
    initValues.id && getComboAvailabilities(initValues);
  }, [initValues.id]);

  const show =
    !initValues.id ||
    (initValues.id &&
      !isRequestingGetCombosBusinesses &&
      !isRequestingGetCombosAvailabilities);
  return (
    <div>
      {show ? (
        <ComboAvailability
          initValues={initValues}
          businesses={
            allSubBusinesses.length > 0 ? allSubBusinesses : businesses
          }
          getComboAvailabilities={getComboAvailabilities}
          comboBusinesses={comboBusinesses}
          allSubBusinesses={allSubBusinesses}
          comboAvailabilities={comboAvailabilities}
          handleComboAvailabilitiesSubmit={handleComboAvailabilitiesSubmit}
          submitAvailabilitiesIsRequesting={submitAvailabilitiesIsRequesting}
          disabled={!initValues.id}
          orderTypesList={orderTypesList.map((orderType) => ({
            value: orderType.id,
            label: orderType.type,
          }))}
        />
      ) : (
        <LoadingBlueLarge />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  comboBusinesses: state.combosReducer.comboBusinesses,
  isRequestingGetCombosBusinesses:
    state.combosReducer.isRequestingGetCombosBusinesses,
  comboAvailabilities: state.combosReducer.comboAvailabilities,
  isRequestingGetCombosAvailabilities:
    state.combosReducer.isRequestingGetCombosAvailabilities,
  submitAvailabilitiesIsRequesting:
    state.combosReducer.submitAvailabilitiesIsRequesting,
  orderTypesList: state.fullReducer.orderTypesList,
  allSubBusinesses: state.businessLocationsList.subBusinessLocationsOfMerchant,
});

export default connect(mapStateToProps, {
  getComboBusinesses,
  getComboAvailabilities,
  handleComboAvailabilitiesSubmit,
})(ComboAvailabilityContainer);
