export const formatedDate = (date, separator, reverse = false) => {
  const day = date.getDate() > 9 ? `${date.getDate()}` : `0${date.getDate()}`;
  const month =
    date.getMonth() + 1 > 9
      ? `${date.getMonth() + 1}`
      : `0${date.getMonth() + 1}`;
  const year = `${date.getFullYear()}`;
  let formatedDate = '';
  if (reverse) {
    formatedDate = year + separator + month + separator + day;
  } else {
    formatedDate = day + separator + month + separator + year;
  }
  return formatedDate;
};
