import _nav from '../../Full/_nav';
import { getRoles } from '../../../utils/appRoles';
import { ROLE_SUPER_ADMIN } from '../../../utils/rolesRepository';

let nav = _nav;

let appIdentifier = localStorage.getItem('appIdentifier');

if (appIdentifier === 'dennys') {
  let newNav = { items: [] };
  nav.items.forEach((item) => {
    if (item.name === 'Engagement') {
      newNav.items.push({
        ...item,
        children: item.children.filter((child) => child.name === 'Media'),
      });
    } else if (item.name === 'Menu Management') {
      newNav.items.push({
        ...item,
        children: item.children.filter(
          (child) =>
            child.name !== 'Upsell' &&
            child.name !== 'Combo Management' &&
            child.name !== 'POS Mapping'
        ),
      });
    } else if (item.name === 'Customer Service') {
      newNav.items.push({
        ...item,
        children: item.children.filter(
          (child) => child.name !== 'Customer Feedback'
        ),
      });
    } else if (item.name === 'App Settings') {
      newNav.items.push({
        ...item,
        children: item.children.filter((child) => child.name !== 'Tags'),
      });
    } else if (item.name === 'Finance') {
      newNav.items.push({
        ...item,
        children: item.children.filter(
          (child) => child.name !== 'Loyalty Report'
        ),
      });
    } else {
      newNav.items.push(item);
    }
  });

  nav = newNav;
}

const getPages = () => {
  let pages = [];

  nav.items.forEach((mainItem) => {
    let isSameAuthorization = true;
    let childPages = [];

    if (mainItem.children) {
      mainItem.children.forEach((childItem) => {
        childPages.push(childItem);
        if (
          !mainItem.authorization.every((role) =>
            childItem.authorization.includes(role)
          )
        ) {
          isSameAuthorization = false;
        }
      });
    }
    if (mainItem.notOnSidebar) {
      mainItem.notOnSidebar.forEach((notOnSidebarItem) => {
        childPages.push(notOnSidebarItem);
        if (
          !mainItem.authorization.every((role) =>
            notOnSidebarItem.authorization.includes(role)
          )
        ) {
          isSameAuthorization = false;
        }
      });
    }
    if (isSameAuthorization) pages.push(mainItem);
    else pages = [...pages, ...childPages];
  });
  return pages;
};

const mapPagesToPermissions = (loyaltyAdminStatus) => {
  const roles = getRoles(loyaltyAdminStatus);
  const pages = getPages();
  // remove parent pages that are only super admin
  let configPages = pages.filter(
    (page) =>
      !(
        page.authorization.length === 1 &&
        page.authorization.includes(ROLE_SUPER_ADMIN)
      )
  );
  // turn authorization list into true/false list
  configPages = configPages.map((page) => ({
    name: page.name,
    authorization: roles.map((role) => page.authorization.includes(role)),
  }));
  return configPages;
};

export const getPagesWithPermissions = (loyaltyAdminStatus) => {
  return {
    headers: ['', ...getRoles(loyaltyAdminStatus)],
    rows: mapPagesToPermissions(loyaltyAdminStatus),
  };
};
