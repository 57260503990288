import React, { useEffect, Suspense, lazy, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as actions from '../ReportTableActions.js';
import ReportSearch from '../components/ReportSearch';
import { Dialog } from '@material-ui/core';
import FilterModal from '../../../components/FilterModal/FilterModal';
import LocationFilterModal from '../../../components/FilterModal/LocationFilterModal.js';
import { formatLocations } from '../../../utils/childLocations/formatLocations';
import { getBusinessLocationsOfMerchant } from '../../BusinessLocationDetails/LocationListManager/actions.js';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);

function ComboReportContainer({
  allCombos,
  allBusinesses,
  requestParams,
  getComboReport,
  setExportingData,
  setExportingHeaders,
  setExportingFileName,
  clearComboReport,
  updateReportsRequestParams,
  getBusinessLocationsOfMerchant,
  setMessage,
  resetAllReports,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
  filteredLocations,
  setFilteredLocations,
}) {
  const [isLocationFilterOpen, setIsLocationFilterOpen] = useState(false);
  const columnHeaders = [
    'Combo Name',
    'Combo Label',
    'Number of Times Ordered',
    'Combo Sales',
    'Average Price Paid',
  ];
  const columnNames = [
    'combo_name',
    'combo_label',
    'num_times_ordered',
    'combo_price',
    'avg_price',
  ];
  const columnTypes = ['string', 'string', 'string', 'money', 'money'];
  const columnWidth = ['120px', '120px', '150px', '110px', '250px'];
  const columnHeaderIsSortableList = [true, true, true, true, true, true];
  const updateSelectedBusiness = useCallback(() => {
    if (filteredLocations) {
      setSelectedBusinessesIDs(filteredLocations);
    }
  }, [filteredLocations]);
  useEffect(() => {
    updateSelectedBusiness();
  }, [updateSelectedBusiness]);
  const getExportHeaders = (headers, fields) => {
    const exportedHeaders = [];
    headers.forEach((header, index) =>
      exportedHeaders.push({ label: header, key: fields[index] })
    );
    return exportedHeaders;
  };
  useEffect(() => {
    getBusinessLocationsOfMerchant();
    setExportingFileName('combo_report');
    setExportingHeaders(getExportHeaders(columnHeaders, columnNames));
    setMessage('Select a date range and location to generate a report');
    return () => {
      clearComboReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setMessage('Select a date range and location to generate a report');
      resetAllReports();
      setFilteredLocations([]);
      setSelectedBusinessesIDs([]);
    };
  }, []);
  useEffect(() => {
    if (allCombos?.length === 0) {
      setExportingData([]);
      setMessage('No Data Found');
    }
  }, [allCombos]);
  return (
    <>
      <ReportSearch
        allBusinesses={allBusinesses}
        generateReport={getComboReport}
        haslocationFiltering
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBusinessesIDs={selectedBusinessesIDs}
        setSelectedBusinessesIDs={setSelectedBusinessesIDs}
        setIsLocationFilterOpen={setIsLocationFilterOpen}
      />
      {allCombos?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allCombos}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'comboReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(allCombos)}
            />
          </Suspense>
        </div>
      )}
      <Dialog open={isLocationFilterOpen} fullWidth maxWidth='md'>
        <LocationFilterModal
          placeholder='Enter Internal Location Name'
          searchLabel='Search by Internal Location Name'
          filteredLocations={filteredLocations}
          setFilteredLocations={setFilteredLocations}
          isOpen={isLocationFilterOpen}
          setIsOpen={setIsLocationFilterOpen}
          emptyIsEqualToAll={true}
          isEmptyAccepted={false}
        />
      </Dialog>
    </>
  );
}
function mapStateToProps(state) {
  return {
    allCombos: state.ReportTableReducer.allCombos,
    requestParams: state.ReportTableReducer.requestParams,
    allBusinesses: state.businessLocationsList.businessLocationsOfMerchant,
  };
}
export default connect(mapStateToProps, {
  ...actions,
  getBusinessLocationsOfMerchant,
})(ComboReportContainer);
