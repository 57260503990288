import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import PaymentIntegrationForm from './components/PaymentIntegrationForm';
import { ROLE_LOYALTY_ADMIN } from '../../../../../utils/rolesRepository';
const PaymentIntegrationContainer = ({
  businessLocationDetailsReducer,
  getBusinessPaymentIntegrationSchema,
  updatePaymentIntegration,
  getPaymentIntegration,
  appConfig,
  deactivateSection,
}) => {
  const {
    businessId,
    isCreatingLocation,
    paymentIntegrationSchema,
    paymentIntegration,
  } = businessLocationDetailsReducer;

  useEffect(() => {
    const isLoyaltyAdminOnly = appConfig?.is_only_enabled_for_loyalty;
    const role = localStorage.getItem('role');
    if (!isLoyaltyAdminOnly && role !== ROLE_LOYALTY_ADMIN) {
      getBusinessPaymentIntegrationSchema();
      !isCreatingLocation && getPaymentIntegration(businessId);
    }
  }, [appConfig?.is_only_enabled_for_loyalty, isCreatingLocation, businessId]);

  const handleSubmit = (values) => {
    updatePaymentIntegration(businessId, values);
  };
  return (
    <Fragment>
      {!isCreatingLocation &&
      appConfig.gift_card_buy_enabled &&
      paymentIntegrationSchema &&
      paymentIntegration &&
      Object.keys(paymentIntegration).length > 0 ? (
        <PaymentIntegrationForm
          businessId={businessId}
          paymentIntegrationSchema={paymentIntegrationSchema}
          initialValues={paymentIntegration ? paymentIntegration : {}}
          handleSubmit={handleSubmit}
          deactivateSection={deactivateSection}
        />
      ) : (
        paymentIntegrationSchema &&
        paymentIntegration &&
        paymentIntegration.credit_card &&
        paymentIntegration.credit_card.provider != 'moneris' && (
          <PaymentIntegrationForm
            businessId={businessId}
            paymentIntegrationSchema={paymentIntegrationSchema}
            initialValues={paymentIntegration ? paymentIntegration : {}}
            handleSubmit={handleSubmit}
            deactivateSection={deactivateSection}
          />
        )
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  businessLocationDetailsReducer: state.businessLocationDetails,
  appConfig: state.accountReducer.appMetaData.configuration,
});

export default connect(mapStateToProps, actions)(PaymentIntegrationContainer);
