import React from 'react';

import {
  SectionWrapper,
  SectionTitle,
  InfoWrapper,
  InfoTitle,
  InfoDesc,
} from './styles/styles';

const OrderInfo = ({ orderDetails }) => {
  return (
    <SectionWrapper>
      <SectionTitle>Order Information</SectionTitle>
      <InfoWrapper>
        <div>
          <InfoTitle>Order Type</InfoTitle>
          <InfoDesc isCapital>{orderDetails.orderType}</InfoDesc>
          <InfoDesc isCapital>
            {orderDetails.isFutureOrder ? 'Scheduled' : 'ASAP'}
          </InfoDesc>
        </div>
        <div>
          <InfoTitle>Order Placed</InfoTitle>
          <InfoDesc>
            {orderDetails.orderDate.substring(
              0,
              orderDetails.orderDate.length - 8
            )}
          </InfoDesc>
          <InfoDesc>
            {orderDetails.orderDate.substring(
              orderDetails.orderDate.length - 8,
              orderDetails.orderDate.length
            )}
          </InfoDesc>
        </div>
        <div>
          <InfoTitle>Order Due</InfoTitle>
          <InfoDesc>
            {orderDetails.orderDue.substring(
              0,
              orderDetails.orderDue.length - 8
            )}
          </InfoDesc>
          <InfoDesc>
            {orderDetails.orderDue.substring(
              orderDetails.orderDue.length - 8,
              orderDetails.orderDue.length
            )}
          </InfoDesc>
        </div>
      </InfoWrapper>
      <InfoWrapper margin='25px 0 0 0'>
        <div>
          <InfoTitle>Store Location</InfoTitle>
          <InfoDesc isCapital>{orderDetails.businessName}</InfoDesc>
          <InfoDesc isCapital>{orderDetails.businessAddress.line1}</InfoDesc>
          <InfoDesc isCapital>{orderDetails.businessAddress.line2}</InfoDesc>
          <InfoDesc isCapital>{orderDetails.businessAddress.line3}</InfoDesc>
        </div>
        <div>
          <InfoTitle>POS #</InfoTitle>
          <InfoDesc>{orderDetails.posId}</InfoDesc>
        </div>
      </InfoWrapper>
    </SectionWrapper>
  );
};

export default OrderInfo;
