import moment from 'moment';

//formatting of get api to show all week days
export const hoursFormatter = (productAvailableHour, editingCombo) => {
  let formatproductAvailableHours = [];
  for (let i = 1; i < 8; i++) {
    const found = productAvailableHour?.filter((productAvailableHours) => {
      if (productAvailableHours?.start_day_of_week === i)
        return productAvailableHour;
    });

    if (!found?.length) {
      formatproductAvailableHours.push({
        product_id: editingCombo?.id,
        start_day_of_week: i,
        end_time: null,
        //id: ,
        start_time: null,
        isAvailableAllDay: false,
      });
    } else {
      const newHoursObj = {
        product_id: found[0].product_id,
        start_day_of_week: found[0].start_day_of_week,
        end_time:
          found[0].end_time === '00:00:00' ||
          found[0].end_time === '00:00:00.000000' ||
          (found[0].end_time === null && found[0].start_time === null)
            ? null
            : moment(found[0].end_time, 'HH:mm:ss'),
        id: found[0].id,
        start_time:
          found[0].end_time === null && found[0].start_time === null
            ? null
            : moment(found[0].start_time, 'HH:mm:ss'),
        isAvailableAllDay: false,
      };

      if (
        (found[0].start_time === '00:00:00' &&
          found[0].end_time === '23:59:59') ||
        (found[0].start_time === '00:00:00.000000' &&
          found[0].end_time === '23:59:59.000000') ||
        (found[0].start_time === null &&
          found[0].end_time === '23:59:59.000000') ||
        (found[0].start_time === null && found[0].end_time === '23:59:59')
      ) {
        newHoursObj.isAvailableAllDay = true;
      }

      formatproductAvailableHours.push(newHoursObj);
    }
  }
  return formatproductAvailableHours;
};

// appear days of week with names
const days = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

export const dayToString = (dayNumber) => days[dayNumber];

//logic of select options
export const setAvailabilityOption = (
  productAvailabilities,
  option,
  index,
  setFieldValue
) => {
  let newArray = [];

  if (option.id === 1) {
    newArray = productAvailabilities.map((productAvailableHours, i) => {
      if (index === i)
        return {
          ...productAvailableHours,
          isAvailableAllDay: true,
          start_time: moment('00:00:00', 'HH:mm:ss'),
          end_time: moment('23:59:59', 'HH:mm:ss'),
        };

      return productAvailableHours;
    });
  } else if (option.id === 2) {
    newArray = productAvailabilities.map((productAvailableHours, i) => {
      if (index === i)
        return {
          ...productAvailableHours,
          isAvailableAllDay: false,
          start_time: null,
          end_time: null,
        };

      return productAvailableHours;
    });
  } else if (option.id === 3) {
    newArray = productAvailabilities.map((productAvailableHours, i) => {
      if (index === i)
        return {
          ...productAvailableHours,
          isAvailableAllDay: false,
          start_time: moment('09:00:00', 'HH:mm:ss'),
          end_time: moment('17:00:00', 'HH:mm:ss'),
        };

      return productAvailableHours;
    });
  }

  //setCombosAvailableHours(newArray);
  setFieldValue('productAvailabilities', newArray);
};

// Dropdown options
export const renderAvailabilityOptions = () => {
  return [
    {
      id: 1,
      name: 'Available All Day',
    },
    {
      id: 2,
      name: 'Not Available All Day',
    },
    {
      id: 3,
      name: 'Select Available Hours',
    },
  ];
};

// check if combo is available all time
export const productAvailableAllTime = () => {
  const comboAllTime = [];
  for (let i = 1; i <= 7; i++) {
    comboAllTime.push({
      start_day_of_week: i,
      start_time: moment('00:00:00', 'HH:mm:ss'),
      end_time: moment('23:59:59', 'HH:mm:ss'),
    });
  }
  return comboAllTime;
};

// set combo availabilities for all time toggle
export const isProductAvailableAllTimeFunction = (
  productAvailabilitiesData
) => {
  let counter = 0;
  if (productAvailabilitiesData?.length === 7) {
    productAvailabilitiesData.map((productDataAvailability, index) => {
      const startTime =
        moment(productDataAvailability.start_time, 'HH:mm:ss').format(
          'HH:mm:ss'
        ) || null;
      const endTime = moment(
        productDataAvailability.end_time,
        'HH:mm:ss'
      ).format('HH:mm:ss');
      if (
        (startTime === null ||
          startTime === 'Invalid date' ||
          startTime === '00:00:00') &&
        endTime === '23:59:59'
      ) {
        counter++;
      }
    });
  }
  return counter === 7;
};
