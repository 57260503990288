import React, { useState } from 'react';
import {
  MessageTokensWrapper,
  MessageTokenDiv,
  MessageTokenIcon,
} from '../styles/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import PersonalizationModal from './PersonalizationModal';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    padding: '16px 12px 14px 18px',
    borderRadius: '1.5px',
    boxShadow: '0 3px 11px 0 rgba(0, 0, 0, 0.09)',
    width: 320,
    fontSize: '12px',
    fontFamily: 'Montserrat',
    color: '#000000',
    pointerEvents: 'auto !important',
  },
}))(Tooltip);

const PersonalizationModalContainer = ({
  insertPersonalization,
  tokens,
  bottom,
  caretPosition,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MessageTokensWrapper
      onClick={() => {
        if (caretPosition !== null) setIsOpen(true);
      }}
      bottom={bottom}
    >
      <MessageTokenDiv disabled={caretPosition !== null ? false : true}>
        <HtmlTooltip
          PopperProps={{ style: { zIndex: '1' } }}
          open={isOpen}
          title={
            <PersonalizationModal
              tokens={tokens}
              setIsOpen={setIsOpen}
              insertPersonalization={insertPersonalization}
            />
          }
        >
          <MessageTokenIcon
            className="far fa-user"
            disabled={caretPosition !== null ? false : true}
          />
        </HtmlTooltip>
      </MessageTokenDiv>
    </MessageTokensWrapper>
  );
};

export default PersonalizationModalContainer;
