import React from 'react';
import { Select } from 'merchant-portal-components';
import DateFnsUtils from '@date-io/date-fns';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import intervalOptions from '../utils/intervalOptions';
import styled from 'styled-components';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
const SelectionWrapper = styled.div`
  width: 100%;
  min-width: 350px;
`;
const DatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
  /* background-color: yellow; */
`;
const DashboardDateSelection = ({
  intervalTypeAndValue,
  setIntervalTypeAndValue,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  timePeriodType,
  setTimePeriodType,
  height,
  handleDateError,
}) => {
  return (
    <SelectionWrapper>
      <RadioGroup
        aria-label='time-period'
        value={timePeriodType}
        onChange={(event, newValue) => {
          setTimePeriodType(newValue);
          setStartDate(null);
          setEndDate(null);
        }}
        row
      >
        <FormControlLabel
          value='fixed-period'
          control={<Radio style={{ color: '#243167' }} />}
          label='Fixed Period'
        />
        <FormControlLabel
          value='custom-date'
          control={<Radio style={{ color: '#243167' }} />}
          label='Custom Date'
        />
      </RadioGroup>
      {timePeriodType === 'fixed-period' ? (
        <Select
          options={intervalOptions}
          selectName='time_period'
          selectLabel='Time Period'
          onChange={(newIntervalTypeAndValue) => {
            setIntervalTypeAndValue(newIntervalTypeAndValue);
          }}
          value={intervalTypeAndValue}
          placeholder='Select period'
          light
          topMod={'-8px'}
          style={{ height: height }}
        />
      ) : (
        <DatePickerWrapper>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              required
              autoOk
              disableFuture
              variant='inline'
              inputVariant='outlined'
              label='Start Date'
              format='dd/MM/yyyy'
              placeholder='DD/MM/YYYY'
              value={startDate}
              InputAdornmentProps={{ position: 'end' }}
              onChange={(date) => setStartDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{ width: '100%', backgroundColor: '#fff' }}
              onError={handleDateError}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              required
              autoOk
              disableFuture
              variant='inline'
              inputVariant='outlined'
              label='End Date'
              name='end_datetime'
              format='dd/MM/yyyy'
              placeholder='DD/MM/YYYY'
              value={endDate}
              onChange={(date) => setEndDate(date)}
              InputAdornmentProps={{
                position: 'end',
                backgroundColor: 'red',
              }}
              style={{ width: '100%', backgroundColor: '#fff' }}
              minDate={startDate && moment(startDate)}
              minDateMessage='End Date should not be before start date'
              maxDate={startDate && moment(startDate).add(1, 'year')}
              maxDateMessage={`End Date should not be more than 1 year after start date: ${startDate
                ?.toString()
                .substring(4, 16)}`}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                padding: '0',
                height: '14px',
                color: 'blue',
                display: 'none',
              }}
              onError={handleDateError}
            />
          </MuiPickersUtilsProvider>
        </DatePickerWrapper>
      )}
    </SelectionWrapper>
  );
};

export default DashboardDateSelection;
