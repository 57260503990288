import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { StyledText } from '../../../../../../components/StyledText/StyledText';
import {
  RenderTextField,
  isComboIncludedOptionValidation,
} from '../../../../../../components/FormElements/FormikElements';
import {
  Modal,
  Button,
  LoadingBlueLarge,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';

const ButtonsWrapper = styled.div`
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // grid-gap: 120px;
  text-align: right;
  padding: 20px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
`;

const ComboOverridesWrapper = styled.div`
  overflow-y: scroll;
  height: 60vh;
`;

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px 20px;
`;

const ModalWrapper = styled.div`
  padding: 24px;
  color: #191818;
`;

const LineDivider = styled.hr`
  border: solid 1px #ebebeb;
  margin-bottom: 23px;
  margin-top: 15px;
`;

const OptionsTable = styled(Table)`
  border: none;
`;

const ThModal = styled(Th)`
  color: #191818;
  font-size: 15px;
`;

const FieldWrapper = styled(Field)`
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const ComboOverridesModal = ({
  toggleCombosOverridesModal,
  comboItem,
  getCombosOptionsOverrides,
  combosOptionsOverride,
  isRequestingGetCombosOptionsOverrides,
  options,
  comboItemIndex,
  handleReset,
  initialValues,
  onSave,
  initValues,
  comboItems,
  setFieldValue,
  comboItemFormik,
  arrayHelpers,
}) => {
  useEffect(() => {
    getCombosOptionsOverrides(
      comboItem,
      options,
      initValues,
      setFieldValue,
      comboItemFormik,
      comboItemIndex
    );
  }, []);

  return comboItem.combosOptionsOverride ? (
    <Modal>
      <div style={{ width: '550px' }}>
        {!isRequestingGetCombosOptionsOverrides ? (
          <div>
            <ModalWrapper>
              <ModalTitle>
                <div>
                  {!combosOptionsOverride.length
                    ? 'Included Options'
                    : `${comboItem.label} Included Options`}
                </div>
                <div />
                <div style={{ cursor: 'pointer' }} onClick={() => toggleCombosOverridesModal()}>
                  x
                </div>
              </ModalTitle>
              <LineDivider />

              <ComboOverridesWrapper>
                {!combosOptionsOverride.length ? (
                  <span style={{ fontStyle: 'italic' }}>
                    There are no common options between products of selected combo
                  </span>
                ) : (
                  <OptionsTable>
                    <TBody>
                      <TrHead>
                        <ThModal align="left">Option</ThModal>
                        <ThModal align="left">Included Amount</ThModal>
                      </TrHead>

                      {combosOptionsOverride.map((option, index) => {
                        return (
                          <Tr key={index}>
                            <Td>
                              <>
                                {`${option.parentName ? option.parentName + ' - ' : ''}${
                                  option.name
                                } - `}
                                <StyledText fontWeight="300" fontStyle="italic">
                                  {option.id}
                                </StyledText>
                              </>
                            </Td>
                            <Td style={{ width: '170px' }}>
                              <FieldWrapper
                                name={`comboItems[${comboItemIndex}].combosOptionsOverride[${index}].included_pick`}
                                component={RenderTextField}
                                isPrice
                                onKeyDown={(evt) =>
                                  (evt.key === 'e' ||
                                    evt.keyCode === 188 ||
                                    evt.keyCode === 190 ||
                                    evt.keyCode === 110 ||
                                    evt.key === '+' ||
                                    evt.key === '-') &&
                                  evt.preventDefault()
                                }
                                min="0"
                                validate={isComboIncludedOptionValidation}
                                error={
                                  arrayHelpers.form.touched.comboItems &&
                                  arrayHelpers.form.touched.comboItems[comboItemIndex] &&
                                  arrayHelpers.form.touched.comboItems[comboItemIndex]
                                    .combosOptionsOverride &&
                                  arrayHelpers.form.touched.comboItems[comboItemIndex]
                                    .combosOptionsOverride[index] &&
                                  arrayHelpers.form.touched.comboItems[comboItemIndex]
                                    .combosOptionsOverride[index].included_pick &&
                                  isComboIncludedOptionValidation(
                                    arrayHelpers.form.values.comboItems[comboItemIndex]
                                      .combosOptionsOverride[index].included_pick
                                  )
                                }
                              />
                            </Td>

                            {option.subOptions &&
                              option.subOptions.map((subOption) => {
                                return (
                                  <div>
                                    <Td>{subOption.name}</Td>
                                    <Td style={{ width: '170px' }}>
                                      <FieldWrapper
                                        name={`comboItems[${comboItemIndex}].combosOptionsOverride[${index}].included_pick`}
                                        component={RenderTextField}
                                        validate={isComboIncludedOptionValidation}
                                        error={
                                          arrayHelpers.form.touched.comboItems &&
                                          arrayHelpers.form.touched.comboItems[comboItemIndex] &&
                                          arrayHelpers.form.touched.comboItems[comboItemIndex]
                                            .combosOptionsOverride &&
                                          arrayHelpers.form.touched.comboItems[comboItemIndex]
                                            .combosOptionsOverride[index] &&
                                          arrayHelpers.form.touched.comboItems[comboItemIndex]
                                            .combosOptionsOverride[index].included_pick &&
                                          isComboIncludedOptionValidation(
                                            arrayHelpers.form.values.comboItems[comboItemIndex]
                                              .combosOptionsOverride[index].included_pick
                                          )
                                        }
                                        isPrice
                                        onKeyDown={(evt) =>
                                          (evt.key === 'e' ||
                                            evt.keyCode === 188 ||
                                            evt.keyCode === 190 ||
                                            evt.keyCode === 110 ||
                                            evt.key === '+' ||
                                            evt.key === '-') &&
                                          evt.preventDefault()
                                        }
                                        min="0"
                                      />
                                    </Td>
                                  </div>
                                );
                              })}
                          </Tr>
                        );
                      })}
                    </TBody>
                  </OptionsTable>
                )}
              </ComboOverridesWrapper>
            </ModalWrapper>

            <ButtonsWrapper>
              {/* <Button
                  secondary
                  onClick={() => {
                    toggleCombosOverridesModal();
                    //handleReset();
                    // setFieldValue(
                    //   `comboItems[${comboItemIndex}].combosOptionsOverride`,
                    //   localComboOverrides
                    // );
                  }}
                >
                  Cancel
                </Button> */}
              <Button
                onClick={() => {
                  toggleCombosOverridesModal();
                  // onSave(values.combosOptionsOverride);
                }}
              >
                Close
              </Button>
            </ButtonsWrapper>
          </div>
        ) : (
          <LoadingBlueLarge />
        )}
      </div>
    </Modal>
  ) : null;
};

export default ComboOverridesModal;
