import Moment from 'moment';
import { toPriceFormat } from '../../../../utils/toPriceFormat';
import store from '../../../../configureStore';
// const calculateOrderReadyTime = (order, dateTime = true) => {
//   let orderReadyTime;
//   if (
//     (!order?.is_future_order && order?.order_type === 'pickup') ||
//     (!order?.is_future_order &&
//       order?.order_type === 'delivery' &&
//       order?.delivery_provider === 'delivery_by_restaurant')
//   ) {
//     orderReadyTime = moment(order?.order_created_at)
//       .add(
//         moment
//           .duration(moment(order?.min_prep_time, 'HH:mm:ss').format('HH:mm'))
//           .asMinutes(),
//         'minutes'
//       )
//       .format(dateTime ? 'YYYY-MM-DD HH:mm:ss' : 'hh:mm A');
//   } else {
//     orderReadyTime = moment(order?.order_date).format(
//       dateTime ? 'YYYY-MM-DD HH:mm:ss' : 'hh:mm A'
//     );
//   }

//   return orderReadyTime;
// };

export const formatTransactionDetails = (transactionDetails) => {
  let formattedTransactionDetails = {};
  // must have
  formattedTransactionDetails.special_instruction =
    transactionDetails.special_instruction;
  formattedTransactionDetails.orderId = transactionDetails.order_id;
  formattedTransactionDetails.isFutureOrder =
    transactionDetails.is_future_order;
  formattedTransactionDetails.orderDue = !transactionDetails.is_future_order
    ? 'ASAP'
    : Moment(transactionDetails.order_date).format('ddd MMMM Do, YYYY hh:mm A');
  formattedTransactionDetails.orderDate = Moment(
    transactionDetails.order_created_at
  ).format('ddd MMMM Do, YYYY hh:mm A');
  formattedTransactionDetails.orderTime = Moment(
    transactionDetails.order_created_at
  ).format('hh:mm A');
  formattedTransactionDetails.subtotal = toPriceFormat(
    transactionDetails.subtotal
  );
  formattedTransactionDetails.loyalty = transactionDetails.loyalty;
  formattedTransactionDetails.customerPhone =
    transactionDetails?.customer.detail?.mobile_phone ||
    transactionDetails?.customer.detail?.home_phone;
  formattedTransactionDetails.posId = transactionDetails?.pos_order_id;

  formattedTransactionDetails.total = toPriceFormat(transactionDetails.total);
  formattedTransactionDetails.tax = toPriceFormat(transactionDetails.tax);
  formattedTransactionDetails.tip = toPriceFormat(transactionDetails.tip);
  formattedTransactionDetails.reports = transactionDetails.reports;
  formattedTransactionDetails.discount = transactionDetails.discount
    ? toPriceFormat(transactionDetails.discount)
    : '$0';
  formattedTransactionDetails.creditsUsed = toPriceFormat(
    transactionDetails.total_credit_applied
  );
  formattedTransactionDetails.products = transactionDetails.products
    ? formatProducts(transactionDetails.products)
    : null;

  formattedTransactionDetails.companyName = transactionDetails.customer.detail
    .company_name
    ? `${transactionDetails.customer.detail.company_name}`
    : '--';

  formattedTransactionDetails.customerName = transactionDetails.customer
    ? `${transactionDetails.customer.first_name} ${transactionDetails.customer.last_name}`
    : 'Unknown Name';

  formattedTransactionDetails.customerEmail = transactionDetails.customer
    ? transactionDetails.customer.email
    : 'Unknown Email';

  formattedTransactionDetails.isGiftCardOrder =
    transactionDetails.is_gift_card_order;
  formattedTransactionDetails.customerId =
    transactionDetails.customer.customer_id;

  formattedTransactionDetails.isGuestCustomer =
    transactionDetails.customer.is_guest;

  formattedTransactionDetails.customerAddress =
    transactionDetails.customer_address &&
    `${transactionDetails.customer_address?.line1}, ${transactionDetails.customer_address?.city}\n${transactionDetails.customer_address?.state}, ${transactionDetails.customer_address?.country}\n${transactionDetails.customer_address?.zip}`;

  formattedTransactionDetails.orderType = transactionDetails.order_type
    ? transactionDetails.order_type
    : 'Unknown';

  formattedTransactionDetails.orderingFrom = transactionDetails.customer_address
    ? formatAddress(transactionDetails.customer_address)
    : formatAddress(transactionDetails.business_address);

  formattedTransactionDetails.businessAddress = formatAddress(
    transactionDetails.business_address
  );
  formattedTransactionDetails.businessName = transactionDetails.business_name;

  // will always be able to refund

  formattedTransactionDetails.refundIsDisabled = false
    ? {
        disabled: true,
        reason: 'Cannot refund order with less than $1 gift card spent',
      }
    : { disabled: false, reason: null };

  formattedTransactionDetails.transactions = transactionDetails.transactions
    ? transactionDetails.transactions.map((transaction) => {
        return { ...transaction, amount: toPriceFormat(transaction.amount) };
      })
    : [];

  if (transactionDetails.fees !== undefined) {
    formattedTransactionDetails.additionalFees = toPriceFormat(
      transactionDetails.fees
    );
  }

  formattedTransactionDetails.serviceFees = transactionDetails.service_fees.map(
    (serviceFee) => {
      return { ...serviceFee, amount: toPriceFormat(serviceFee.amount) };
    }
  );
  formattedTransactionDetails.status = transactionDetails.status;
  // options and must check

  return formattedTransactionDetails;
};

const formatProducts = (products) => {
  return products.map((product) => {
    let formattedProduct = {};
    formattedProduct.special_instruction = product.special_instruction;
    formattedProduct.name = `${product.name}`;
    formattedProduct.price = `${toPriceFormat(product.prices[0].price)}`;
    formattedProduct.imageUrl = formatProductUrl(product.image_url);
    formattedProduct.quantity = product.quantity;
    formattedProduct.calories =
      product.calories && product.calories.length
        ? product.calories[0].max_calories
        : null;
    formattedProduct.options = formatProductOptions(product.options);
    formattedProduct.special_instructions = product.special_instructions;
    return formattedProduct;
  });
};

const formatAddress = (address) => {
  let formattedAddress = {
    line1: address.line2 ? address.line2 + '-' + address.line1 : address.line1,
    line2:
      address.city && address.state
        ? address.city + ', ' + address.state + ','
        : address.city
        ? address.city + ', '
        : address.state
        ? address.state + ', '
        : '',
    line3: address.zip ? address.zip + ', ' : '',
    line4: address.country ? address.country : '',
  };
  return formattedAddress;
};

const formatProductUrl = (url) => {
  if (!url.includes('http://')) {
    return url.replace(/http:\//g, 'http://');
  }
  return url;
};

const formatProductOptions = (options) => {
  return options
    .map((option) => {
      if (option.sub_options.length) {
        const optionsToReturn = {
          ...option,
          sub_options: option.sub_options
            .map((subOption) => mapOption(subOption))
            .filter((subOption) => subOption),
        };
        if (optionsToReturn.sub_options.length) {
          return optionsToReturn;
        }
      } else {
        return mapOption(option);
      }
    })
    .filter((option) => option);
};

const mapOption = (option) => {
  const optionsToReturn = {
    ...option,
    items: option.items
      .map((item) => {
        if (item.sub_items && item.sub_items.length) {
          const itemToReturn = {
            ...item,
            sub_items: item.sub_items
              .map((subItem) => {
                if (subItem.quantity) {
                  return subItem;
                }
              })
              .filter((subItem) => subItem),
          };
          if (itemToReturn.sub_items.length) {
            return itemToReturn;
          }
        } else if (item.quantity) {
          return item;
        }
      })
      .filter((item) => item),
  };
  if (optionsToReturn.items.length) {
    return optionsToReturn;
  }
};

export const selectedItemsToString = (option) => {
  let itemsString = '';

  option.items.forEach((item) => {
    const addCommaAndSpace = itemsString ? ', ' : '';
    if (item.sub_items && item.sub_items.length) {
      item.sub_items.forEach((subItem) => {
        // const showQuantity = subItem.maximum_pick !== 1;
        const quantityString = ` x${subItem.quantity}`;
        if (subItem.quantity) {
          itemsString += `${addCommaAndSpace}${item.name} (${subItem.name}${quantityString})`;
        }
      });
    } else {
      // const showQuantity = item.maximum_pick !== 1;
      const quantityString = ` x${item.quantity}`;
      if (item.quantity) {
        itemsString += `${addCommaAndSpace}${item.name}${quantityString}`;
      }
    }
  });
  return itemsString;
};
