import store from '../configureStore';
import appRolesDatabase from './appRolesDatabase';
import { ROLE_LOYALTY_ADMIN } from './rolesRepository';
export const getRoles = (loyaltyAdminStatus) => {
  const roles =
    appRolesDatabase[store.getState().accountReducer.appIdentifier] ||
    appRolesDatabase.default;
  if (loyaltyAdminStatus === 'NO_LOYALTY') {
    return roles.filter((role) => role !== ROLE_LOYALTY_ADMIN);
  } else if (loyaltyAdminStatus === 'ONLY_LOYALTY') {
    return [ROLE_LOYALTY_ADMIN];
  }
  return roles;
};

export const isOnlyLoyaltyAdminUser = (appConfig) => {
  return appConfig.is_only_enabled_for_loyalty;
};
