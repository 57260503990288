import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './combosAction';
import CombosListContainer from './CombosList/CombosListContainer';
import CombosDetailContainer from './ComboDetail/CombosDetailContainer';

const CombosContainer = ({
  currentPage,
  businesses,
  resetCombosReducer,
  getCombos,
  requestParams,
  updateCombosRequestParams,
  getCombosPosition,
  allCombosPositions,
  getArchivedCombos
}) => {
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    getCombosPosition();
    return () => {
      updateCombosRequestParams({
        ...requestParams,
        page_number: 1,
      });
      setSearchString('');
    };
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === '') {
        getCombos(newRequestParams);
        getArchivedCombos(newRequestParams)
      }
      updateCombosRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString, currentPage]);

  if (currentPage === 'list') {
    return (
      <CombosListContainer
        businesses={businesses}
        searchString={searchString}
        setSearchString={setSearchString}
        allCombosPositions={allCombosPositions}
      />
    );
  } else if (currentPage === 'create') {
    return <CombosDetailContainer businesses={businesses} />;
  } else if (currentPage === 'edit') {
    return <CombosDetailContainer businesses={businesses} isEdit={true} />;
  }
};

const mapStateToProps = (state) => ({
  currentPage: state.combosReducer.currentPage,
  allCombosPositions: state.combosReducer.allCombosPositions,
  requestParams: state.combosReducer.requestParams,
  businesses: state.businessLocationsList.businessLocationsOfMerchant,
});

export default connect(mapStateToProps, actions)(CombosContainer);
