import {
  GET_CUSTOMERS_OF_MERCHANT_REQUEST,
  GET_CUSTOMERS_OF_MERCHANT_SUCCESS,
  GET_CUSTOMERS_PAGINATION_SUCCESS,
} from './customerListTypes';

import api from '../../../api';
import renderQueryString from '../../../utils/renderQueryString';
import { getSearchUrl } from '../../../utils/purePayload';
export function updateCustomersRequestParams(requestParams) {
  return {
    type: 'UPDATE_CUSTOMERS_REQUEST_PARAMS',
    payload: requestParams,
  };
}
export function updateCustomerId(customerId) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_CUSTOMER_OF_MERCHANT_CUSTOMERID',
      customerId: customerId,
    });
  };
}
export function updateCustomerCurrentPage(currentPage) {
  return function (dispatch) {
    dispatch({
      type: 'UPDATE_CUSTOMER_OF_MERCHANT_CURRENT_PAGE',
      currentPage: currentPage,
    });
  };
}
export function getCustomers(
  requestParams = {
    page_number: 1,
    page_size: 25,
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {

  let queryParamsWithOutSearchString = `page_number=${requestParams.page_number}&page_size=${requestParams.page_size}`;
  let url = getSearchUrl(queryParamsWithOutSearchString, requestParams.search_string, 3);
  url = url.replace('+', '%2B');
  return function (dispatch) {
    return api.get(`/customers?${url}`).then((response) => {
      const hasNextPage = response.data.meta.has_next_page;
      dispatch({
        type: GET_CUSTOMERS_PAGINATION_SUCCESS,
        customers: response.data.data,
        meta: response.data.meta,
      });
    });
  };
}

function cleanQueryParams(queryParams) {
  // Create a new URLSearchParams object
  const params = new URLSearchParams(queryParams);
  
  // Check if the 'search_string' param exists and is empty, then remove it
  if (!params.get('search_string')) {
    params.delete('search_string');
  }

  // Return the cleaned-up query parameters
  return params.toString() ? '?' + params.toString() : '';
}

export function getCustomersOfMerchant(params) {
  if(params.search_string !== undefined){
    params.search_string = encodeURIComponent(params.search_string)
  }
  let queryParams = renderQueryString(params);
  queryParams = queryParams.replace('+', '%2B');
  const cleanedQueryParams = cleanQueryParams(queryParams);
  return function (dispatch) {
    dispatch({
      type: GET_CUSTOMERS_OF_MERCHANT_REQUEST,
    });
    return api.get(`/customers${cleanedQueryParams }`).then((response) => {
      dispatch({
        type: GET_CUSTOMERS_OF_MERCHANT_SUCCESS,
        customers: response.data.data,
        meta: response.data.meta,
      });
    });
  };
}
