export const handleCloseLocation = (
  e,
  setOpenTime1,
  setCloseTime1,
  setOpenTime2,
  setCloseTime2,
  setIsLocationClosed
) => {
  const currentIsLocationClosed = e.target.checked;
  if (!currentIsLocationClosed) {
    setOpenTime1(null);
    setCloseTime1(null);
    setOpenTime2(null);
    setCloseTime2(null);
  }
  setIsLocationClosed(currentIsLocationClosed);
};
export const handleCloseOrdering = (
  e,
  setOpenOrderingTime1,
  setCloseOrderingTime1,
  setOpenOrderingTime2,
  setCloseOrderingTime2,
  setIsOrderingClosed
) => {
  const currentIsOrderingClosed = e.target.checked;
  if (!currentIsOrderingClosed) {
    setOpenOrderingTime1(null);
    setCloseOrderingTime1(null);
    setOpenOrderingTime2(null);
    setCloseOrderingTime2(null);
  }
  setIsOrderingClosed(currentIsOrderingClosed);
};
