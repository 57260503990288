import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import {
  PageWrapper,
  ConentWrapper,
  BackToListLink,
  Chevron,
  NavigateText,
  TitleWrapper,
  Title,
  ButtonWrapper,
  DateRangeWrapper,
  TotalSummaryWrapper,
  TotalSummaryCard,
  TotalSummaryTitle,
  DetailCard,
  DetailCardRowWrapper,
  CollapsedCardRowWrapper,
  Divider,
  MiddleTitle,
  NoteWrapper,
  Note,
  Subtitle,
  SignIcon,
  SubtitleWrapper,
  ValueWrapper,
} from '../styles/SummaryReportDetailStyle';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Tooltip, Collapse } from '@material-ui/core';
import { formatedDate } from '../utils/getFormatedDate';
const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#313132',
    color: '#ffffff',
    maxWidth: 'none',
  },
  button: {
    backgroundColor: '#2440ae',
    color: '#ffffff',
    maxHeight: '40px',
    width: '100%',
    borderRadius: '28px',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#3151d4',
    },
  },
}));
const SummaryReportDetail = ({
  startDate,
  endDate,
  selectedLocationSummary,
  setSelectedLocationSummary,
  exportHeaders,
  exportFileName,
}) => {
  const creditDiscount =
    parseFloat(selectedLocationSummary.total_credit_applied) -
    parseFloat(selectedLocationSummary.total_credit_refunded);
  const classes = useStyles();
  const [collapsePayment, setCollapsePayment] = useState(false);
  const [collapsePaymentRefund, setCollapsePaymentRefund] = useState(false);
  const [exportData, setExportData] = useState();
  useEffect(() => {
    let initialExportDate = [];
    if (selectedLocationSummary) {
      initialExportDate.push(selectedLocationSummary);
      setExportData(initialExportDate);
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageWrapper>
      <ConentWrapper>
        <BackToListLink onClick={() => setSelectedLocationSummary(null)}>
          <Chevron className='fa fa-chevron-left' />
          <NavigateText>Back to Transaction Summary Report</NavigateText>
        </BackToListLink>
        <TitleWrapper>
          <Title>{`${selectedLocationSummary.display_name} Summary`}</Title>
          <ButtonWrapper>
            <CSVLink
              data={exportData || []}
              headers={exportHeaders}
              filename={`${exportFileName}.csv`}
            >
              <Button className={classes.button}>Export as CSV</Button>
            </CSVLink>
          </ButtonWrapper>
        </TitleWrapper>
        <DateRangeWrapper>{`${formatedDate(startDate, '/')} - ${formatedDate(
          endDate,
          '/'
        )}`}</DateRangeWrapper>
        <TotalSummaryWrapper>
          <TotalSummaryCard>
            <TotalSummaryTitle>
              <div style={{ marginBottom: '15px' }}>Gross Sales</div>
              <div>{`$${selectedLocationSummary.gross_sales}`}</div>
            </TotalSummaryTitle>
          </TotalSummaryCard>
          <TotalSummaryCard>
            <TotalSummaryTitle>
              <div style={{ marginBottom: '15px' }}>
                <span>Expected Deposit</span>{' '}
                <Tooltip
                  classes={classes}
                  title='The amount of money deposited into your bank account (or owed) prior to 3rd Party fee deductions.'
                  placement='top'
                >
                  <i
                    className='fal fa-info-circle'
                    style={{ fontSize: '20px', fontWeight: '500' }}
                  ></i>
                </Tooltip>
              </div>
              <div>{`$${selectedLocationSummary.expected_deposit}`}</div>
            </TotalSummaryTitle>
          </TotalSummaryCard>
        </TotalSummaryWrapper>
        <DetailCard>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Subotal</Subtitle>
              <Tooltip
                classes={classes}
                title='The sum of subtotal for all orders, including refunded orders.'
                placement='right'
              >
                <i className='fal fa-info-circle'></i>
              </Tooltip>
            </SubtitleWrapper>
            <ValueWrapper>
              {`$${selectedLocationSummary.subtotal}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Tax</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`$${selectedLocationSummary.tax}`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Fees</Subtitle>
              <Tooltip
                classes={classes}
                title='The sum of fees collected for all orders, including refunded orders. (E.g. delivery fee, service fee, convenience fee, etc).'
                placement='right'
              >
                <i className='fal fa-info-circle'></i>
              </Tooltip>
            </SubtitleWrapper>
            <ValueWrapper>{`$${selectedLocationSummary.fees}`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Tip</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`$${selectedLocationSummary.tip}`}</ValueWrapper>
          </DetailCardRowWrapper>
          <Divider />
          <DetailCardRowWrapper isBold={true} marginTop='25px'>
            <SubtitleWrapper>
              <SignIcon isSummary={true}>=</SignIcon>
              <Subtitle isSummary={true}>Gross Sales</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper isSummary={true}>
              {`$${selectedLocationSummary.gross_sales}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
        </DetailCard>
        <DetailCard>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>=</SignIcon>
              <Subtitle>Gross Sales</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>
              {`$${selectedLocationSummary.gross_sales}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>Tax</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`($${selectedLocationSummary.tax})`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>Fees</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`($${selectedLocationSummary.fees})`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>Tip</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`($${selectedLocationSummary.tip})`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>Refunds Subtotal</Subtitle>
              <Tooltip
                classes={classes}
                title='The sum of all refunded orders, where payment was processed with the Smooth Commerce platform. This is the amount that was returned to customers.'
                placement='right'
              >
                <i className='fal fa-info-circle'></i>
              </Tooltip>
            </SubtitleWrapper>
            <ValueWrapper>{`($${selectedLocationSummary.refund_subtotal})`}</ValueWrapper>
          </DetailCardRowWrapper>
          <Divider />
          <DetailCardRowWrapper isBold={true} marginTop='25px'>
            <SubtitleWrapper>
              <SignIcon isSummary={true}>=</SignIcon>
              <Subtitle isSummary={true}>Adjusted Gross</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper isSummary={true}>
              {`$${selectedLocationSummary.adjusted_gross}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
        </DetailCard>
        <DetailCard>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>=</SignIcon>
              <Subtitle>Adjusted Gross</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>
              {`$${selectedLocationSummary.adjusted_gross}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>Credit/Discounts</Subtitle>
              <Tooltip
                classes={classes}
                title='The sum of credits and discounts applied to all orders, including refunded orders.'
                placement='right'
              >
                <i className='fal fa-info-circle'></i>
              </Tooltip>
            </SubtitleWrapper>
            <ValueWrapper>{`($${creditDiscount.toFixed(2)})`}</ValueWrapper>
          </DetailCardRowWrapper>
          <Divider />
          <DetailCardRowWrapper isBold={true} marginTop='25px'>
            <SubtitleWrapper>
              <SignIcon isSummary={true}>=</SignIcon>
              <Subtitle isSummary={true}>Net Sales/(Due)</Subtitle>
              <Tooltip
                classes={classes}
                title='The sum of orders after refunds and credit deductions.'
                placement='right'
              >
                <i className='fal fa-info-circle'></i>
              </Tooltip>
            </SubtitleWrapper>
            <ValueWrapper isSummary={true}>
              {`$${selectedLocationSummary.net_sales}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
        </DetailCard>

        <DetailCard>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>=</SignIcon>
              <Subtitle>Net Sales/(Due)</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>
              {`$${selectedLocationSummary.net_sales}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Tax</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`$${selectedLocationSummary.adjusted_tax}`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Tip</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`$${selectedLocationSummary.adjusted_tip}`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Fees</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`$${selectedLocationSummary.adjusted_fees}`}</ValueWrapper>
          </DetailCardRowWrapper>
          <Divider />
          <DetailCardRowWrapper isBold={true} marginTop='25px'>
            <SubtitleWrapper>
              <SignIcon isSummary={true}>=</SignIcon>
              <Subtitle isSummary={true}>Expected Deposit</Subtitle>
              <Tooltip
                classes={classes}
                title='The amount of money deposited into your bank account (or owed) prior to 3rd Party fee deductions.'
                placement='right'
              >
                <i className='fal fa-info-circle'></i>
              </Tooltip>
            </SubtitleWrapper>
            <ValueWrapper isSummary={true}>
              {`$${selectedLocationSummary.expected_deposit}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
        </DetailCard>
        <MiddleTitle>Detailed Breakdown</MiddleTitle>
        <DetailCard>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Credit Card</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>
              {`$${selectedLocationSummary.amount_collected_credit_card}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>Gift Card</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`$${selectedLocationSummary.amount_collected_gift_card}`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>+</SignIcon>
              <Subtitle>PayPal</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`$${selectedLocationSummary.amount_collected_paypal}`}</ValueWrapper>
          </DetailCardRowWrapper>
          <Divider />
          <DetailCardRowWrapper isBold={true} marginTop='25px'>
            <SubtitleWrapper>
              <SignIcon isSummary={true}>=</SignIcon>
              <Subtitle isSummary={true}>Payments Collected in Smooth</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper isSummary={true}>
              {`$${selectedLocationSummary.amount_collected_in_smooth}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
        </DetailCard>
        <DetailCard>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>Credit Card</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>
              {`($${selectedLocationSummary.amount_refunded_credit_card})`}
            </ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>Gift Card</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`($${selectedLocationSummary.amount_refunded_gift_card})`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>PayPal</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper>{`($${selectedLocationSummary.amount_refunded_paypal})`}</ValueWrapper>
          </DetailCardRowWrapper>
          <DetailCardRowWrapper marginBottom='25px'>
            <SubtitleWrapper>
              <SignIcon>-</SignIcon>
              <Subtitle>External Payment</Subtitle>
              <Tooltip
                classes={classes}
                title='The sum of orders where payment was processed outside the Smooth Commerce platform. This includes Scan for Loyalty orders or Pay on Arrival orders.'
                placement='right'
              >
                <i className='fal fa-info-circle'></i>
              </Tooltip>
            </SubtitleWrapper>
            <ValueWrapper>{`($${selectedLocationSummary.refund_external_payment})`}</ValueWrapper>
          </DetailCardRowWrapper>
          <Divider />
          <DetailCardRowWrapper isBold={true} marginTop='25px'>
            <SubtitleWrapper>
              <SignIcon isSummary={true}>=</SignIcon>
              <Subtitle isSummary={true}>Payments Refunded in Smooth</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper isSummary={true}>
              {`($${selectedLocationSummary.total_refund})`}
            </ValueWrapper>
          </DetailCardRowWrapper>
        </DetailCard>
        <DetailCard>
          <DetailCardRowWrapper isBold={true}>
            <SubtitleWrapper>
              <SignIcon isSummary={true}>-</SignIcon>
              <Subtitle isSummary={true}>Discount Refunds</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper isSummary={true}>
              {`($${selectedLocationSummary.total_credit_refunded})`}
            </ValueWrapper>
          </DetailCardRowWrapper>
        </DetailCard>
        <DetailCard>
          <DetailCardRowWrapper isBold={true}>
            <SubtitleWrapper>
              <SignIcon isSummary={true}>+</SignIcon>
              <Subtitle isSummary={true}>External Payment Collected</Subtitle>
            </SubtitleWrapper>
            <ValueWrapper isSummary={true}>
              {`$${selectedLocationSummary.external_payment}`}
            </ValueWrapper>
          </DetailCardRowWrapper>
        </DetailCard>

        <NoteWrapper>
          <Note>Notes:</Note>
          <Note>
            All values are calculated based on the selected date range. The
            start and end dates are in the local timezone of each business.
          </Note>
          <Note>
            The report does not consider failed orders due to payment issues.
          </Note>
          <Note>
            The refund values do not include any breakdowns of tips, fees,
            taxes. These are all summed together.
          </Note>
        </NoteWrapper>
      </ConentWrapper>
    </PageWrapper>
  );
};

export default SummaryReportDetail;
