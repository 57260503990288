import {
  GET_SUMMARY,
  GET_TRANSACTIONS,
  GET_SALES,
  GET_TRANSACTIONS_PER_HOUR,
  GET_ORDER_TYPES,
  LOADING,
} from './types';
import api from '../../../api';
import moment from 'moment';
import renderQueryString from '../../../utils/renderQueryString';

moment.locale('en');

function checkParams(params) {
  var newParams = params;
  if (params.business_ids == 'all') {
    var { business_ids, ...newParams } = params;
  } else {
    var newParams = params;
  }

  if (params.type == 'all') {
    var { type, ...newParams } = params;
  }

  if (params.business_ids == 'all' && params.type == 'all') {
    var { business_ids, type, ...newParams } = params;
  }
  return newParams;
}

export function getSummary(params) {
  return function (dispatch) {
    dispatch({ type: LOADING });
    const newParams = checkParams(params);
    api
      .get(`/dashboard/transactions/summary${renderQueryString(newParams)}`)
      .then((response) => {
        dispatch({ type: GET_SUMMARY, payload: response.data });
      })
      .catch((error) => {
        // console.log('error occured', error);
      });
  };
}

export function getTransactions(params) {
  return function (dispatch) {
    var newParams = checkParams(params);
    api
      .get(`/dashboard/transactions${renderQueryString(newParams)}`)
      .then((response) => {
        dispatch({ type: GET_TRANSACTIONS, payload: response.data });
      })
      .catch((error) => {
        // console.log('error occured', error);
      });
  };
}

export function getSales(params) {
  return function (dispatch) {
    var newParams = checkParams(params);
    api
      .get(`/dashboard/transactions/sales${renderQueryString(newParams)}`)
      .then((response) => {
        dispatch({ type: GET_SALES, payload: response.data });
      })
      .catch((error) => {
        // console.log('error occured', error);
      });
  };
}

export function getTransactionsPerHour(params) {
  return function (dispatch) {
    var newParams = checkParams(params);
    api
      .get(`/dashboard/transactions/hours${renderQueryString(newParams)}`)
      .then((response) => {
        dispatch({ type: GET_TRANSACTIONS_PER_HOUR, payload: response.data });
      })
      .catch((error) => {
        // console.log('error occured', error);
      });
  };
}

export function getOrderTypes(params) {
  return function (dispatch) {
    var newParams = checkParams(params);
    api
      .get(`/dashboard/transactions/order-types${renderQueryString(newParams)}`)
      .then((response) => {
        dispatch({ type: GET_ORDER_TYPES, payload: response.data });
      })
      .catch((error) => {
        // console.log('error occured', error);
      });
  };
}

export function resetTransactionDashboardData() {
  return function (dispatch) {
    dispatch({ type: GET_SUMMARY, payload: { data: null } });
    dispatch({ type: GET_TRANSACTIONS, payload: { data: null } });
    dispatch({ type: GET_SALES, payload: { data: null } });
    dispatch({ type: GET_TRANSACTIONS_PER_HOUR, payload: { data: null } });
    dispatch({ type: GET_ORDER_TYPES, payload: { data: null } });
  };
}
