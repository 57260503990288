import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import NameAndNotesForm from './components/NameAndNotesForm';
import BusinessAssigned from './components/BusinessAssigned';
import AppFeedContainer from '../../Appfeed/AppFeedContainer';
import StickyButtons from '../../../components/StickyButtons';
import { ChevronLeftIcon } from 'merchant-portal-components';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const DatePickerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  width: 1000px;
  margin: 0 auto;
`;

const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 32px;
  background: white;
  display: grid;
  grid-gap: 24px;
`;

const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 150px;
  grid-gap: 10px;
  align-items: center;
  margin-top: 20px;
  &:hover {
    color: #3051d4;
  }
`;

const AppFeedLayoutForm = ({
  createAppFeedLayout,
  setPageType,
  formInitialData,
  id,
  updateAppFeed,
  listAppFeed,
}) => {
  const [reduxReset, setReduxReset] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BackButton onClick={() => setPageType()}>
        <ChevronLeftIcon />
        <p>Back To App Feed</p>
      </BackButton>
      <Wrapper>
        <BreadCrumb
          pageName={
            formInitialData ? 'Edit App Feed Layout_1' : 'Create App Feed Layout'
          }
        />
        <Formik
          initialValues={
            !formInitialData
              ? {
                is_default: false, template_name: '', notes: '', start_date: null,
                end_date: null,
              }
              : {
                start_date: formInitialData.data.start_date,
                end_date: formInitialData.data.end_date,
                is_default: formInitialData.data.is_default,
                template_name: formInitialData.data.template_name,
                notes: formInitialData.data.notes,
              }
          }
          enableReinitialize={true}
        >
          {({
            dirty,
            values,
            setValues,
            errors,
            setFieldValue,
            handleReset
          }) => (
            <Fragment>
              <NameAndNotesForm />
              <Card>
                <DatePickerWrapper>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      clearable
                      name="start_date"
                      autoOk
                      variant='inline'
                      inputVariant='outlined'
                      label='Start Date'
                      inputFormat='dd/MM/yyyy'
                      placeholder='DD/MM/YYYY'
                      value={values.start_date}
                      InputAdornmentProps={{ position: 'end' }}
                      onChange={value => {

                        if (values.end_date !== null) {
                          const startDateStrVal = values.end_date;
                          const getOnlyStartDateStr = startDateStrVal.substring(0, startDateStrVal.indexOf(" "));
                          const formttd = moment(value).format('YYYY-MM-DD HH:mm:ss');
                          const getOnlyEndDateStr = formttd.substring(0, 10);
                          if (new Date(getOnlyStartDateStr) < new Date(getOnlyEndDateStr)) {
                            alert("Start date cannot be greater than end date")
                            return;
                          } else {
                            setFieldValue("start_date", formttd.substring(0, 10) + " " + "00:00:00")
                          }
                        }else{
                          const formttd = moment(value).format('YYYY-MM-DD HH:mm:ss');
                          setFieldValue("start_date", formttd.substring(0, 10) + " " + "00:00:00")
                       
                        }




                        // const formttd = moment(value).format('YYYY-MM-DD HH:mm:ss');
                        //  setFieldValue("start_date", formttd.substring(0, 10) + " " + "00:00:00")
                      
                        }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ width: '100%' }}
                      size='small'
                      onError={() => { }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      clearable
                      name="end_date"
                      autoOk
                      variant='inline'
                      inputVariant='outlined'
                      label='End Date'
                      inputFormat='dd/MM/yyyy'
                      placeholder='DD/MM/YYYY'
                      value={values.end_date}
                      InputAdornmentProps={{ position: 'end' }}
                      onChange={value => {
                        if (values.start_date !== null) {
                          const startDateStrVal = values.start_date;
                          const getOnlyStartDateStr = startDateStrVal.substring(0, startDateStrVal.indexOf(" "));
                          const formttd = moment(value).format('YYYY-MM-DD HH:mm:ss');
                          const getOnlyEndDateStr = formttd.substring(0, 10);
                          if (new Date(getOnlyStartDateStr) > new Date(getOnlyEndDateStr)) {
                            alert("End date cannot be less than start date")
                            return;
                          } else {
                            setFieldValue("end_date", formttd.substring(0, 10) + " " + "23:59:59")
                          }
                        }else{
                          const formttd = moment(value).format('YYYY-MM-DD HH:mm:ss');
                          setFieldValue("end_date", formttd.substring(0, 10) + " " + "23:59:59")

                        }

                      }
                      }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ width: '100%' }}
                      size='small'
                      onError={() => { }}
                    />
                  </MuiPickersUtilsProvider>
                </DatePickerWrapper>
              </Card>

              <AppFeedContainer
                formInitialData={formInitialData}
                itemId={id}
                reduxReset={reduxReset}
                setReduxReset={(value) => setReduxReset(value)}
                valueChanged={(v) => setValueChanged(v)}
              />
              {formInitialData ? (
                <BusinessAssigned
                  listAppFeed={listAppFeed}
                  id={id}
                ></BusinessAssigned>
              ) : null}

              <StickyButtons
                isVisible={
                  formInitialData
                    ? dirty || valueChanged
                    : dirty || (dirty && valueChanged)
                }
                handleDiscard={() => {
                  handleReset();
                  setReduxReset(true);
                  setValueChanged(false);
                }}
                handleSave={() =>
                  formInitialData == null
                    ? createAppFeedLayout(values)
                    : updateAppFeed(values, id)
                }
                title="App Feed Layout Management"
                saveIsDisabled={Object.keys(errors).length}
              />
            </Fragment>
          )}
        </Formik>
      </Wrapper>
    </div>
  );
};

export default AppFeedLayoutForm;
