import React, { useEffect, useState } from 'react';

import {
  SectionWrapper,
  SectionTitle,
  InfoWrapper,
  InfoTitle,
  InfoDesc,
  TableWrapper,
  RowWrapper,
  DividerLine,
} from './styles/styles';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import styled from 'styled-components';
import moment from 'moment';
import toPriceFormat from '../../../../utils/toPriceFormat';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

const renderHeaders = () => (
  <TrHead data-test='component-TrHead'>
    <Th align='center'>Date</Th>
    <Th align='center'>Time</Th>
    <Th align='center'>Payment Type</Th>
    <Th align='center'>Transaction Type</Th>
    <Th align='center'>Transaction ID</Th>
    <Th align='center'>Amount</Th>
    <Th align='center'>Payment Status</Th>
  </TrHead>
);

const renderRows = (transactions, modifiedReports) =>
  transactions.map((transaction, index) => (
    <Tr data-test='component-Tr' key={index}>
      <Td align='center'>
        {moment(transaction.created_at, 'YYYY-MM-DD HH:mm:ss').format(
          'YYYY-MM-DD'
        )}
      </Td>
      <Td align='center'>
        {moment(transaction.created_at, 'YYYY-MM-DD HH:mm:ss').format(
          'hh:mm:ss a'
        )}
      </Td>
      <Td align='center'>
        {/* {transaction.payment_type
          ? transaction.payment_type == 'credit_card'
            ? transaction.payment_type?.replace('_', ' ').toUpperCase() +
              ` (${transaction.payment_method?.card_type.toUpperCase()})`
            : transaction.payment_type?.replace('_', ' ').toUpperCase()
          : transaction.is_loyalty
          ? 'LOYALTY'
          : transaction.payment_gateway?.replace('_', ' ').toUpperCase()} */}
        {modifiedReports?.length && modifiedReports[index]}
      </Td>
      <Td align='center'>{transaction.type.replace('_', ' ').toUpperCase()}</Td>
      <Td align='center'>{transaction.id}</Td>
      <Td align='center'>{transaction.amount}</Td>
      <Td align='center'>
        {transaction.status.toUpperCase()}{' '}
        {transaction.status === 'failed' && (
          <Tooltip
            title={`${transaction.code} - ${transaction.message}`}
            placement='top'
            arrow
          >
            <span>
              <TooltipIcon />
            </span>
          </Tooltip>
        )}
      </Td>
    </Tr>
  ));

export const OrderTransactionInfo = (props) => {
  const {
    transactions,
    subtotal,
    discount,
    loyalty,
    tip,
    tax,
    total,
    creditsUsed,
    additionalFees,
    serviceFees,
    orderDetailCredit,
    reports,
  } = props;
  const isGray = serviceFees.length % 2 === 0;
  const [modifiedReports, setModifiedReports] = useState();
  useEffect(() => {
    let newReport = [];
    reports?.forEach((report) => {
      const cardType =
        report.card_type.charAt(0).toUpperCase() + report.card_type?.slice(1);
      const paymentTender = report?.payment_tender.startsWith('cred')
        ? 'Credit Card'
        : report.payment_tender;
      const paymentType = report?.payment_type;
      if (
        report.card_type.toLowerCase() === report.payment_tender.toLowerCase()
      ) {
        newReport.push(`${paymentType} - ${cardType}`);
      } else {
        if (cardType) {
          newReport.push(`${paymentType} / ${paymentTender} \n (${cardType})`);
        } else {
          newReport.push(`${paymentType} / ${paymentTender}`);
        }
      }
    });
    setModifiedReports(newReport);
  }, [reports]);
  return (
    <SectionWrapper padding={'15px 0px 0px 0px'}>
      <SectionTitle semiBold>Transaction History</SectionTitle>
      <TableWrapper>
        <Table>
          <thead>{renderHeaders()}</thead>
          <TBody striped>{renderRows(transactions, modifiedReports)}</TBody>
        </Table>
      </TableWrapper>
      <DividerLine />
      <TableWrapper margin='0 auto' width='50%'>
        <RowWrapper hasBottomBorder>
          <InfoDesc>Subtotal </InfoDesc>
          <InfoDesc>{subtotal}</InfoDesc>
        </RowWrapper>
        <RowWrapper hasBottomBorder>
          <InfoDesc>Discount </InfoDesc>
          <InfoDesc>
            {'-'} {creditsUsed}
          </InfoDesc>
        </RowWrapper>

        {orderDetailCredit.credit_breakdown?.combo_discount.map(
          (comboDiscount, i) => (
            <RowWrapper key={i} hasBottomBorder>
              <InfoDesc>
                {' '}
                - {comboDiscount.label} {' (- $'}
                {comboDiscount.combo_discount}
                {')'}
              </InfoDesc>
            </RowWrapper>
          )
        )}

        {orderDetailCredit.credit_breakdown?.coupon_redeemed.map(
          (couponDiscount, i) => (
            <RowWrapper key={i} hasBottomBorder>
              <InfoDesc>
                {' '}
                - {couponDiscount.label} {' (- $'}
                {couponDiscount.amount_spent}
                {')'}
              </InfoDesc>
            </RowWrapper>
          )
        )}
        {orderDetailCredit.credit_breakdown?.offers_redeemed.map(
          (offerRedeemed, i) => (
            <RowWrapper key={i} hasBottomBorder>
              <InfoDesc>
                {' '}
                - {offerRedeemed.title} {' (- $'}
                {offerRedeemed.discount}
                {')'}
              </InfoDesc>
            </RowWrapper>
          )
        )}
        {orderDetailCredit?.credit_breakdown?.global_credits_spent?.map(
          (globalDiscount, i) => (
            <RowWrapper key={i} hasBottomBorder>
              <InfoDesc>
                {' '}
                - Credits Applied {' (- $'}
                {globalDiscount.amount_spent}
                {')'}
              </InfoDesc>
            </RowWrapper>
          )
        )}
        <RowWrapper hasBottomBorder>
          <InfoDesc>Total After Credits Applied </InfoDesc>
          <InfoDesc>
            {toPriceFormat(
              Math.round(
                (parseFloat(subtotal.substring(1)) -
                  parseFloat(creditsUsed.substring(1))) *
                  100
              ) / 100
            )}
          </InfoDesc>
        </RowWrapper>
        {serviceFees.map((serviceFee, i) => (
          <RowWrapper key={i} hasBottomBorder>
            <InfoDesc isCapital>{serviceFee.display_name}</InfoDesc>
            <InfoDesc>{serviceFee.amount}</InfoDesc>
          </RowWrapper>
        ))}

        <RowWrapper hasBottomBorder>
          <InfoDesc>Tax </InfoDesc>
          <InfoDesc>{tax}</InfoDesc>
        </RowWrapper>

        <RowWrapper hasBottomBorder>
          <InfoDesc>Tip </InfoDesc>
          <InfoDesc>{tip}</InfoDesc>
        </RowWrapper>

        <RowWrapper padding='8px 0' margin='0 0 40px 0'>
          <InfoTitle>Total</InfoTitle>
          <InfoTitle>{total}</InfoTitle>
        </RowWrapper>
        <RowWrapper hasBottomBorder>
          <InfoDesc>Earned Loyalty Points</InfoDesc>
          <InfoDesc>{loyalty?.amount_earned}</InfoDesc>
        </RowWrapper>
        <RowWrapper hasBottomBorder>
          <InfoDesc>Redeemed Loyalty Points</InfoDesc>
          <InfoDesc>{loyalty?.amount_redeemed}</InfoDesc>
        </RowWrapper>
      </TableWrapper>
    </SectionWrapper>
  );
};

export default OrderTransactionInfo;
