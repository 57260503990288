import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import {
  RenderTextField,
  RenderSelect,
  required,
} from '../../../components/FormElements/FormikElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../../../components/Icon/Icon';
import { Button, ButtonRow } from '../../../components/Button/Button';
import styled from 'styled-components';
import Alert from '../../Alert/Alert';
import PasswordVerifier from '../../PasswordVerifier/PasswordVerifier';
import Modal from '../../../components/Modal/Modal';
import Typography from '@material-ui/core/Typography';
import { StyledForm, SectionWrapper } from './Styles';
import {
  validateEmail,
  validatePasswordField,
  validateConfirmPasswordField,
  validateBusinessField,
} from '../OrderTabletUsersActions';

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  margin-bottom: 5%;
  font-weight: 500;
`;

function EditUser(props) {
  const {
    businessesLocations,
    employeeLocations,
    selectedEmployee,
    patchEmployee,
    passwordVerifier,
    open,
    close,
  } = props;

  const [state, setState] = useState({
    passwordMaskState: true,
    passwordConfirmMaskState: true,
    changePassword: false,
  });

  useEffect(() => {
    setState({
      passwordMaskState: true,
      passwordConfirmMaskState: true,
      changePassword: false,
    });
  }, [selectedEmployee]);

  const availableLocations =
    businessesLocations && employeeLocations
      ? businessesLocations.filter(
          (business) => !employeeLocations.includes(business.name)
        )
      : null;

  return (
    <Modal open={open} onClose={close} style={{ borderRadius: '25px' }}>
      <div>
        <Formik
          initialValues={{
            first_name: selectedEmployee ? selectedEmployee.first_name : null,
            last_name: selectedEmployee ? selectedEmployee.last_name : null,
            email: selectedEmployee ? selectedEmployee.email : null,
            business_id: selectedEmployee
              ? selectedEmployee.business.name
              : null,
          }}
        >
          {({ values, errors }) => (
            <SectionWrapper style={{ width: '500px', margin: 20 }}>
              <Alert />
              <MessageWrapper>Update Employee</MessageWrapper>
              <StyledForm>
                <Field
                  name="first_name"
                  placeholder="First name"
                  component={RenderTextField}
                  validate={required}
                />
                <Field
                  name="last_name"
                  placeholder="Last name"
                  component={RenderTextField}
                  validate={required}
                />
                <Field
                  name="email"
                  placeholder="Enter email"
                  component={RenderTextField}
                  validate={() => validateEmail(values)}
                />
                {state.changePassword ? (
                  <div>
                    <Field
                      type={state.passwordMaskState ? 'password' : 'text'}
                      name="password"
                      placeholder="Create New Password"
                      component={RenderTextField}
                      validate={() =>
                        validatePasswordField(values, passwordVerifier)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              className={
                                state.passwordMaskState
                                  ? 'fas fa-eye-slash'
                                  : 'fas fa-eye'
                              }
                              style={{ color: 'grey' }}
                              onClick={() =>
                                setState({
                                  ...state,
                                  passwordMaskState: !state.passwordMaskState,
                                })
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <PasswordVerifier
                      password={values.password}
                      passwordConfirm={values.passwordConfirm}
                    />
                    <Field
                      type={
                        state.passwordConfirmMaskState ? 'password' : 'text'
                      }
                      name="passwordConfirm"
                      placeholder="Confirm New Password"
                      component={RenderTextField}
                      validate={() =>
                        validateConfirmPasswordField(values, passwordVerifier)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon
                              className={
                                state.passwordConfirmMaskState
                                  ? 'fas fa-eye-slash'
                                  : 'fas fa-eye'
                              }
                              style={{ color: 'grey' }}
                              onClick={() =>
                                setState({
                                  ...state,
                                  passwordConfirmMaskState:
                                    !state.passwordConfirmMaskState,
                                })
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: 'auto',
                        marginRight: 0,
                        marginBottom: 10,
                      }}
                    >
                      <Typography
                        onClick={() =>
                          setState({
                            ...state,
                            changePassword: false,
                          })
                        }
                        style={{
                          color: 'black',
                          fontWeight: 300,
                          cursor: 'pointer',
                          marginLeft: 'auto',
                          marginRight: 0,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        Cancel
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: 'auto',
                      marginRight: 0,
                      marginBottom: 10,
                    }}
                  >
                    <Typography
                      onClick={() =>
                        setState({
                          ...state,
                          changePassword: true,
                        })
                      }
                      style={{
                        color: 'black',
                        fontWeight: 300,
                        cursor: 'pointer',
                      }}
                    >
                      Change Password
                    </Typography>
                  </div>
                )}
                <Field
                  name="business_id"
                  placeholder="Select Location"
                  component={RenderTextField}
                  disabled
                />
                <ButtonRow
                  style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
                >
                  <div onClick={() => close()}>
                    <Typography
                      style={{
                        color: 'black',
                        fontWeight: 550,
                        cursor: 'pointer',
                      }}
                    >
                      Cancel
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 0,
                    }}
                  >
                    <Button
                      disabled={Object.keys(errors).length > 0}
                      onClick={() => {
                        delete values.passwordConfirm;
                        delete values.business_id;
                        patchEmployee(
                          selectedEmployee.employee_id,
                          values,
                          close
                        );
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </ButtonRow>
              </StyledForm>
            </SectionWrapper>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default EditUser;
