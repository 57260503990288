import React, { useState } from 'react';
import styled from 'styled-components';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button } from 'merchant-portal-components';
import { EditIcon, DeleteIcon } from '../../../../../../components/Icon';
import ProductOverridesModalContainer from '../containers/ProductOverridesModalContainer';
import DeleteProductOverrideModal from './DeleteProductOverrideModal';
import { Collapse } from '@material-ui/core';
import { LoadingBlueLarge } from 'merchant-portal-components';

const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ButtonWrapper = styled(Button)`
  margin-top: 24px;
  margin-bottom: 23px;
`;

const NoOverridesText = styled.p`
  font-style: italic;
`;

const OverridesRow = styled.div`
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-gap: 50px;
`;

const VariantName = styled.div`
  color: #191818;
  margin-top: 20px;
  font-size: 14px;
`;

const BusinessName = styled.span`
  color: #191818;
  font-weight: 600;
`;

let ProductBusinessesOverridesForm = ({
  isExpanded,
  setIsExpanded,
  disabled,
  summaryData,
  isOverrideModal,
  setIsOverrideModal,
  formattedBusinesses,
  productId,
  productVariantsModified,
  productVariantsInitialValues,
  isCreate,
  setIsCreate,
  isSubmiting,
  isRequestingProductVariants,
  productOverrides,
  reassociateProductOverrides,
  selectedAssociatedBusinesses,
  associatedBusinessIdList,
}) => {
  const summaryDataModified = summaryData
    ? summaryData.length
      ? summaryData.filter((data) => data.prices.length > 0)
      : []
    : null;
  const [openDeleteOverrideModal, setOpenDeleteOverrideModal] = useState(false);
  const [selectedBusinesses, setSelectedBusinesses] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState(null);

  const getBusinessObject = (businessId, prices) => {
    let business = { business_id: businessId, prices: prices };
    return business;
  };
  const handleDeleteOverride = () => {
    let finalBusinessOverrides = [];
    let updatedBusinessOverrideList = [];
    const deletedVariantIdList = selectedVariants.map(
      (variant) => variant.product_variant_id
    );
    const deletedBusinessIdList = selectedBusinesses.map(
      (business) => business.id
    );
    if (selectedVariants && selectedBusinesses) {
      let filteredAssociatedOverrides = productOverrides?.filter((override) =>
        associatedBusinessIdList.includes(override.business_id)
      );
      filteredAssociatedOverrides?.map((override) => {
        if (!deletedBusinessIdList.includes(override.business_id)) {
          updatedBusinessOverrideList.push(override);
        } else {
          if (!deletedVariantIdList.includes(override.product_variant_id)) {
            updatedBusinessOverrideList.push(override);
          }
        }
      });
      var grupedOverrideByBusiness = updatedBusinessOverrideList?.reduce(
        function (results, org) {
          (results[org.business_id] = results[org.business_id] || []).push(org);
          return results;
        },
        {}
      );
      for (const [businessId, businessOverrides] of Object.entries(
        grupedOverrideByBusiness
      )) {
        let prices = [];
        businessOverrides?.map((override) => {
          prices.push({
            product_variant_id: override.product_variant_id,
            price: override.price,
            start_time: null,
            end_time: null,
          });
        });
        if (prices.length !== 0) {
          finalBusinessOverrides.push(
            getBusinessObject(Number(businessId), prices)
          );
        }
      }
      reassociateProductOverrides(productId, finalBusinessOverrides, true);
    }
  };
  const handleConfirmDeleteOverride = () => {
    handleDeleteOverride();
    setOpenDeleteOverrideModal(false);
  };
  const handleOpenDeleteOverrideModal = (variants, businesses) => {
    setSelectedVariants(variants);
    setSelectedBusinesses(businesses);
    setOpenDeleteOverrideModal(true);
  };
  const handleCloseDeleteOverrideModal = () => {
    setSelectedVariants(null);
    setSelectedBusinesses(null);
    setOpenDeleteOverrideModal(false);
  };
  if (
    (isSubmiting || !summaryDataModified || isRequestingProductVariants) &&
    !disabled
  ) {
    return <LoadingBlueLarge />;
  } else {
    return (
      <div>
        <Card disabled={disabled}>
          <TitleAndCollapseButton>
            <Title>Business Overrides</Title>
            {!isExpanded ? (
              <ChevronDownIcon
                disabled={disabled}
                onClick={() => setIsExpanded(true)}
              />
            ) : (
              <ChevronUpIcon
                onClick={() => setIsExpanded(false)}
                disabled={disabled}
              />
            )}
          </TitleAndCollapseButton>

          <Collapse in={isExpanded}>
            <div>
              <ButtonWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOverrideModal({
                    isShowing: true,
                    prices: null,
                    assignedBusinesses: [],
                  });
                  setIsCreate(true);
                }}
              >
                Add Override
              </ButtonWrapper>
              {!disabled ? (
                !summaryDataModified.length ? (
                  <NoOverridesText>
                    There are no overrides for this product
                  </NoOverridesText>
                ) : (
                  summaryDataModified.map((data, index) => {
                    return (
                      <OverridesRow key={index}>
                        <div>
                          {data.businesses.map((business, businessIndex) => {
                            return (
                              <BusinessName key={businessIndex}>
                                {business.name},{' '}
                              </BusinessName>
                            );
                          })}

                          {data.prices.map((variant, variantIndex) => {
                            return (
                              <VariantName key={variantIndex}>
                                {variant.name} - {`$${variant.price}`}
                              </VariantName>
                            );
                          })}
                        </div>
                        <div
                          style={{
                            textAlign: 'right',
                            fontWeight: 'bold',
                            color: '#191818',
                            fontSize: '15px',
                          }}
                        >
                          <span>Edit</span>
                          <span>
                            <EditIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsOverrideModal({
                                  isShowing: true,
                                  prices: summaryDataModified[index].prices,
                                  assignedBusinesses:
                                    summaryDataModified[index].businesses,
                                  initialBusinesses:
                                    summaryDataModified[index].businesses,
                                  index: index,
                                });
                                setIsCreate(false);
                              }}
                              style={{ cursor: 'pointer', marginLeft: '5px' }}
                              type="fas"
                              size="fa-sm"
                            />
                          </span>
                          <span style={{ marginLeft: '20px' }}>Delete</span>
                          <span>
                            <DeleteIcon
                              onClick={() =>
                                handleOpenDeleteOverrideModal(
                                  summaryDataModified[index].prices,
                                  summaryDataModified[index].businesses
                                )
                              }
                              style={{ cursor: 'pointer', marginLeft: '5px' }}
                              type="fas"
                              size="fa-sm"
                            />
                          </span>
                        </div>
                      </OverridesRow>
                    );
                  })
                )
              ) : null}
            </div>
          </Collapse>
        </Card>

        {isOverrideModal.isShowing && (
          <ProductOverridesModalContainer
            isOverrideModal={isOverrideModal}
            setIsOverrideModal={setIsOverrideModal}
            formattedBusinesses={formattedBusinesses}
            productId={productId}
            summaryData={summaryData}
            productVariantsModified={productVariantsModified}
            productVariantsInitialValues={productVariantsInitialValues}
            isCreate={isCreate}
            disabled={!summaryDataModified.length}
            selectedAssociatedBusinesses={selectedAssociatedBusinesses}
          />
        )}
        {openDeleteOverrideModal && (
          <DeleteProductOverrideModal
            open={openDeleteOverrideModal}
            onCancel={handleCloseDeleteOverrideModal}
            onConfirm={handleConfirmDeleteOverride}
          />
        )}
      </div>
    );
  }
};

export default ProductBusinessesOverridesForm;
