import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 20px;
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
export const HoursTableWrraper = styled.div`
  margin-top: 25px;
`;
export const HoursTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
export const HoursTableBody = styled.tbody`
  min-width: 100%;
`;
export const HoursTableRow = styled.tr`
  height: 45px;
  border: none;
  border-bottom: ${(props) =>
    props.isHeader ? "2px solid" : "1px solid #ebebeb"};
`;

export const HoursTableHeader = styled.th`
  width: ${(props) => props.width};
  text-align: ${(props) => props.align || "left"};
  font-size: 14px;
`;
export const HoursTableColumn = styled.td`
  width: ${(props) => props.width};
  text-align: ${(props) => props.align || "left"};
  font-size: ${(props) => (props.isClosed ? "12px" : "14px")};
  font-weight: ${(props) => (props.isClosed ? 700 : "normal")};
`;
export const EditButton = styled.i`
  color: #888888;
  cursor: pointer;
  &:hover {
    color: #6f6f76;
  }
`;
