import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { getOrderDetailsByOrderId } from '../OrderDetails/actions';
import * as actions from './actions';
import OrderHistory from './components/OrderHistory';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  button: {
    width: '148px',
    backgroundColor: '#e6ebff',
    color: '#2440ae',
    height: '43px',
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#d9e0ff',
    },
    '&:disabled': {
      backgroundColor: '#eff2ff',
      color: '#9facdf',
    },
  },
}));
const TitleContainer = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 4fr 1fr;
`;
const ExportWrapper = styled.div`
  text-align: right;
  align-self: center;
`;
const exportHeaders = [
  { label: 'Date Placed', key: 'order_created_at' },
  { label: 'Order #', key: 'order_id' },
  { label: 'Customer', key: 'm_customer_name' },
  { label: 'Total', key: 'total' },
  { label: 'Order Type', key: 'order_type' },
  { label: 'Location', key: 'business_name' },
  { label: 'Order Due', key: 'm_order_due' },
  { label: 'Payment Status', key: 'status' },
  { label: 'POS #', key: 'pos_order_id' },
];
const OrderHistoryListContainer = ({
  orders,
  requestedParams,
  updatedRequestedParams,
  getOrderHistoryOfMerchant,
  setCurrentPage,
  updateOrderId,
  ordersOfMerchantIsRequesting,
}) => {
  const classes = useStyles();
  const [exprotData, setExportData] = useState([]);
  useEffect(() => {
    getOrderHistoryOfMerchant(requestedParams);
  }, []);
  const handleFilter = (filterParams) => {
    if (filterParams) {
      let finalFilterParams = {
        ...filterParams,
        page_number: 1,
        page_size: 25,
      };
      updatedRequestedParams(finalFilterParams);
      getOrderHistoryOfMerchant(finalFilterParams);
    } else {
      getOrderHistoryOfMerchant({
        page_number: 1,
        page_size: 25,
      });
      updatedRequestedParams({
        page_number: 1,
        page_size: 25,
      });
    }
  };
  return (
    <>
      <TitleContainer>
        <BreadCrumb
          data-test='component-BreadCrumb'
          pageName={'Order History'}
        />
        <ExportWrapper>
          <CSVLink
            data={exprotData}
            headers={exportHeaders}
            filename={`Order_History.csv`}
            disabled={exprotData?.length === 0}
          >
            <Button
              className={classes.button}
              disabled={exprotData?.length === 0}
            >
              Export as CSV
            </Button>
          </CSVLink>
        </ExportWrapper>
      </TitleContainer>
      <OrderHistory
        orders={orders}
        requestParams={requestedParams}
        handleFilter={handleFilter}
        exportHeaders={exportHeaders}
        setExportData={setExportData}
        setCurrentPage={setCurrentPage}
        updateOrderId={updateOrderId}
        updatedRequestedParams={updatedRequestedParams}
        getOrderHistoryOfMerchant={getOrderHistoryOfMerchant}
        ordersOfMerchantIsRequesting={ordersOfMerchantIsRequesting}
      />
    </>
  );
};
function mapStateToProps(state) {
  return {
    orders: state.orderHistory.orders,
    ordersOfMerchantIsRequesting:
      state.orderHistory.ordersOfMerchantIsRequesting,
    orderHistory: state.orderHistory,
    businesses: state.businessLocationsList.businessLocationsOfMerchant,
    requestedParams: state.orderHistory.requestedParams,
  };
}

export default connect(mapStateToProps, {
  ...actions,
  getOrderDetailsByOrderId,
})(OrderHistoryListContainer);
