import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateRequestParams } from '../../modules/Pagination/actions';
import { Select } from 'merchant-portal-components';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const PaginationRow = styled.div`
  display: grid;
  grid-template-columns: 4fr 4fr;
  align-items: center;
  grid-gap: 40px;
  font-size: 16px;
  .pagination {
    margin: 0;
  }
  padding: 10px;
  width: ${(props) => (props.width ? props.width : '100%')};
`;

const DropDownWrapper = styled.div`
  display: grid;
  grid-template: 40px / auto auto;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
`;

const PaginationWrapper = styled.div`
  display: grid;
  grid-template-columns: 80px 50px 80px;
  grid-gap: 25px;
  align-items: center;
`;

const Chevron = styled.i`
  cursor: pointer;
  color: ${(props) => (props.disabled ? '#959595' : '#191818')};
`;
const ChevronText = styled.div`
  text-align: ${(props) => (props.align ? props.align : 'left')};
  height: 18px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.disabled ? '#959595' : '#191818')};
  cursor: pointer;
`;

export class PaginationControl extends Component {
  constructor(props) {
    super(props);
    this.updatePagination = this.updatePagination.bind(this);
  }

  updatePagination(value) {
    const newsParams = {
      ...this.props.requestParams,
      page_size: value,
      page_number: 1,
    };
    this.props.updateAction(newsParams);
    this.props.updateRequestParams(newsParams, this.props.type);
  }

  updatePageNumber(number) {
    const newsParams = {
      ...this.props.requestParams,
      page_number: number,
    };
    this.props.updateAction(newsParams);
    this.props.updateRequestParams(newsParams, this.props.type);
  }

  changePage(action) {
    const currentPageNumber = this.props.requestParams.page_number;
    let nextPageNumber =
      action === 'next' ? currentPageNumber + 1 : currentPageNumber - 1;
    this.updatePageNumber(nextPageNumber);
  }

  lastNumberOnPage() {
    const { page, page_size, count } = this.props.response;
    const number = page * page_size;
    return number > count ? count : number;
  }

  render() {
    const { page, page_size, count } = this.props.response;
    const isFirstPage = page == 1;
    const isLastPage = !this.props.requestParams.has_next_page;
    const pageIntervalOptions = [
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 25, label: '25' },
      { value: 50, label: '50' },
      { value: 100, label: '100' },
    ];

    return (
      <PaginationRow width={this.props.width}>
        <DropDownWrapper>
          Results Per Page
          <div style={{ width: '90px' }}>
            <Select
              small
              pagination
              isSearchable={false}
              options={pageIntervalOptions}
              value={pageIntervalOptions.filter(
                ({ value }) => value == this.props.requestParams.page_size
              )}
              onChange={(option) => this.updatePagination(option.value)}
              isDisabled={this.props.isResultsPerPageDisabled}
            />
          </div>
        </DropDownWrapper>
        <PaginationWrapper>
          <div>
            {isFirstPage ? (
              <ChevronText disabled align="right">
                <Chevron
                  className="fa fa-chevron-left"
                  style={{ textAlign: 'center', marginRight: '10px' }}
                  disabled
                />
                Back
              </ChevronText>
            ) : (
              <Tooltip
                title={
                  'Please save the changes before searching for a new value!'
                }
                disableHoverListener={!this.props.dirty}
                disableTouchListener={!this.props.dirty}
                disableFocusListener={!this.props.dirty}
                placement="top"
              >
                <ChevronText
                  disabled={this.props.dirty}
                  align="right"
                  onClick={() => this.changePage('prev')}
                >
                  <Chevron
                    className="fa fa-chevron-left"
                    style={{ textAlign: 'center', marginRight: '10px' }}
                    disabled={this.props.dirty}
                  />
                  Back
                </ChevronText>
              </Tooltip>
            )}
          </div>
          <div style={{ textAlign: 'center' }}>
            {this.props.requestParams.page_number}
          </div>
          <div>
            {isLastPage ? (
              <ChevronText disabled>
                {'Forward'}
                <Chevron
                  className="fa fa-chevron-right"
                  disabled
                  style={{ textAlign: 'center', marginLeft: '10px' }}
                />
              </ChevronText>
            ) : (
              <Tooltip
                title={
                  'Please save the changes before searching for a new value!'
                }
                disableHoverListener={!this.props.dirty}
                disableTouchListener={!this.props.dirty}
                disableFocusListener={!this.props.dirty}
                placement="top"
              >
                <ChevronText
                  disabled={this.props.dirty}
                  onClick={() => this.changePage('next')}
                >
                  {'Forward'}
                  <Chevron
                    className="fa fa-chevron-right"
                    style={{ textAlign: 'center', marginLeft: '10px' }}
                    disabled={this.props.dirty}
                  />
                </ChevronText>
              </Tooltip>
            )}
          </div>
        </PaginationWrapper>
      </PaginationRow>
    );
  }
}

PaginationControl.propTypes = {
  response: PropTypes.object.isRequired,
  requestParams: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default connect(null, { updateRequestParams })(PaginationControl);
