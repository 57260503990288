import React, { useState } from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

const StyledCard = styled.div`
  display: grid;
  grid-template-columns: 90% 10%;
  padding: 40px 50px 27px 50px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
  cursor: pointer;
`;
const Left = styled.div`
  margin: 0;
`;
const Right = styled.div`
  position: relative;
  margin: 0;
`;
const ArrowIcon = styled.span``;
const IconWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: right;
  margin: 0;
  font-size: 30px;
  color: black;
`;
const CardTitle = styled.div`
  margin-bottom: 20px;
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #191818;
`;
const ServiceFeeCard = ({
  serviceFee,
  toggleIsActiveFee,
  loadServiceFeeDetailPage,
}) => {
  const { display_name, is_active, id } = serviceFee;
  const toggleSeviceFeeActivation = (id, isActive) => {
    toggleIsActiveFee(id, !isActive);
  };
  const loadEditFeePage = () => {
    loadServiceFeeDetailPage(true, serviceFee);
  };
  return (
    <StyledCard onClick={loadEditFeePage}>
      <Left>
        <CardTitle>{display_name}</CardTitle>
        <Switch
          checked={is_active}
          onClick={(e) => e.stopPropagation()}
          onChange={() => toggleSeviceFeeActivation(id, is_active)}
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        Active
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="Fees will be charged to your customers on your app or website when they are marked Active. You can disable the Active toggle if you do not want to charge your customers this fee."
          placement="right-start"
        >
          <i style={{ marginLeft: '5px' }} className="fal fa-info-circle"></i>
        </Tooltip>
      </Left>
      <Right>
        <IconWrapper>
          <ArrowIcon className="fal fa-angle-right" />
        </IconWrapper>
      </Right>
    </StyledCard>
  );
};
export default ServiceFeeCard;
