import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon, Icon } from 'merchant-portal-components';
import { withRouter } from 'react-router-dom';

import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import { StyledP } from 'merchant-portal-components';
import _ from 'lodash';
import { InputAdornment, TextField } from '@material-ui/core';

const ContainerDiv = styled.div`
  padding: ${(props) => props.padding || '0 0 0 0'};
  margin: ${(props) => props.margin || '0 0 0 0'};
  width: ${(props) => props.width || 'inherit'};

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ListWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;
const SearchWrapper = styled.div`
  padding: 0 20px 20px 20px;
`;

const StyledTd = styled(Td)`
  padding: 10px 30px 10px 20px;
  text-align: ${(props) => props.align || 'left'};
  /* width: ${(props) => props.width || '100%'}; */
`;

const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 200px;
  grid-gap: 10px;
  align-items: center;

  &:hover {
    color: #3051d4;
  }

  margin-top: 30px;
  margin-bottom: 30px;
`;

const ArchivedBusinessLocationsList = (props) => {
  const {
    archivedLocations,
    handleUnArchive,
    isBusinessAdminRole,
    isLoyaltyAdminRole,
    getBusinessLocations,
    getArchivedBusinesses,
    handleBusinessLocationAction,
  } = props;

  const [searchString, setSearchString] = useState('');
  const [sortedBusinesses, setSortedBusinesses] = useState();
  const [sorted, setSorted] = useState(['', '']);
  const [sortIndex, setSortIndex] = useState(-1);
  const [sortColumn, setSortColumn] = useState('');

  const unArchiveThis = (e, businessLocation) => {
    e.stopPropagation();
    handleUnArchive([businessLocation.id]);
    getBusinessLocations();
  };

  useEffect(() => {
    getArchivedBusinesses();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getArchivedBusinesses({
        page_number: 1,
        page_size: 25,
        search_string: searchString,
      });
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchString]);

  const updateBusinessList = useCallback(() => {
    let sortedBusinesses;
    if (sorted[0] || sorted[1]) {
      sortedBusinesses = [...archivedLocations];
      sortedBusinesses = _.orderBy(
        sortedBusinesses,
        [sortColumn],
        sorted[sortIndex]
      );
    } else {
      sortedBusinesses = [...archivedLocations];
    }
    setSortedBusinesses(sortedBusinesses);
  }, [archivedLocations, sorted]);

  useEffect(() => {
    updateBusinessList();
  }, [updateBusinessList]);

  const handleSorting = (sortColumn) => {
    setSortColumn(sortColumn);
    const newSortIndex = sortColumn === 'is_active' ? 0 : 1;
    setSortIndex(newSortIndex);
    let newSort = ['', ''];
    if (!sorted[0] && !sorted[1]) {
      newSort[0] = newSortIndex === 0 ? 'asc' : '';
      newSort[1] = newSortIndex === 1 ? 'asc' : '';
    } else if (newSortIndex === 0) {
      newSort[0] =
        sorted[newSortIndex] === ''
          ? 'asc'
          : sorted[newSortIndex] === 'asc'
          ? 'desc'
          : 'asc';
      newSort[1] = '';
    } else if (newSortIndex === 1) {
      newSort[0] = '';
      newSort[1] =
        sorted[newSortIndex] === ''
          ? 'asc'
          : sorted[newSortIndex] === 'asc'
          ? 'desc'
          : 'asc';
    }
    setSorted(newSort);
  };
  const handleClearSearch = () => {
    setSearchString('');
  };
  return (
    <>
      <BackButton
        onClick={() => {
          getBusinessLocations();
          setTimeout(() => {
            props.history.push('/locations');
          }, 1000);
        }} // Use history.push
      >
        <ChevronLeftIcon />
        <div>Back to Location List</div>
      </BackButton>
      <div
        style={{
          color: '#000',
          fontFamily: 'Montserrat',
          fontSize: '36px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          marginBottom: '30px',
        }}
      >
        <span>Archived Locations</span>
      </div>

      <ListWrapper>
        <ContainerDiv>
          <SearchWrapper>
            <TextField
              variant='outlined'
              style={{ width: 500 }}
              label='Search locations'
              placeholder='Search archived locations'
              onChange={(e) => setSearchString(e.target.value)}
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon className='fal fa-search' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='start'>
                    <Icon
                      className='fas fa-times-circle'
                      style={{ color: '#193053' }}
                      onClick={handleClearSearch}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </SearchWrapper>
          <Table noBorder>
            <TBody striped>
              {sortedBusinesses?.length > 0 &&
                renderHeaders(
                  handleSorting,
                  sorted,
                  isBusinessAdminRole,
                  isLoyaltyAdminRole
                )}
              {renderRows(
                sortedBusinesses,
                unArchiveThis,
                isBusinessAdminRole,
                isLoyaltyAdminRole,
                handleBusinessLocationAction
              )}
            </TBody>
          </Table>
        </ContainerDiv>
      </ListWrapper>
    </>
  );
};

const renderHeaders = (
  handleSorting,
  sorted,
  isBusinessAdminRole,
  isLoyaltyAdminRole
) => {
  return (
    <TrHead
      style={{
        position: 'sticky',
        position: '-webkit-sticky',
        top: 0,
        backgroundColor: 'white',
        zIndex: 10,
      }}
    >
      <Th align='left'>Internal Location Name</Th>
      <Th align='left'>Location Address</Th>
      {!isBusinessAdminRole && !isLoyaltyAdminRole && <Th></Th>}
    </TrHead>
  );
};

const renderRows = (
  sortedBusinesses,
  unArchiveThis,
  isBusinessAdminRole,
  isLoyaltyAdminRole,
  handleBusinessLocationAction
) => {
  if (!sortedBusinesses || sortedBusinesses.length === 0) {
    return (
      <Tr>
        <StyledTd align='center' colSpan={3}>
          <StyledP>No archived locations</StyledP>
        </StyledTd>
      </Tr>
    );
  }

  return sortedBusinesses?.map((businessLocation, index) => {
    return (
      <Tr
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleBusinessLocationAction(businessLocation.id);
        }}
        key={index}
      >
        <StyledTd align='left'>
          <StyledP>{businessLocation.business_name}</StyledP>
        </StyledTd>
        <StyledTd align='left'>
          <StyledP>{businessLocation.address}</StyledP>
        </StyledTd>
        {!isBusinessAdminRole && !isLoyaltyAdminRole && (
          <StyledTd style={{ cursor: 'pointer' }}>
            <div
              style={styles.container}
              onClick={(e) => unArchiveThis(e, businessLocation)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='14'
                height='14'
                viewBox='0 0 14 15'
                fill='white' // Icon color is white
              >
                <path d='M12.2041 4.24172L11.3291 2.49172C11.2927 2.41903 11.2368 2.35792 11.1676 2.31522C11.0985 2.27253 11.0188 2.24994 10.9375 2.25H3.0625C2.98122 2.24994 2.90153 2.27253 2.83237 2.31522C2.76321 2.35792 2.70731 2.41903 2.67094 2.49172L1.79594 4.24172C1.76578 4.30258 1.75006 4.36958 1.75 4.4375V11.875C1.75 12.1071 1.84219 12.3296 2.00628 12.4937C2.17038 12.6578 2.39294 12.75 2.625 12.75H11.375C11.6071 12.75 11.8296 12.6578 11.9937 12.4937C12.1578 12.3296 12.25 12.1071 12.25 11.875V4.4375C12.2499 4.36958 12.2342 4.30258 12.2041 4.24172ZM3.33266 3.125H10.6673L11.1048 4H2.89516L3.33266 3.125ZM11.375 11.875H2.625V4.875H11.375V11.875ZM9.05953 7.62797C9.10018 7.66862 9.13242 7.71687 9.15442 7.76998C9.17642 7.82309 9.18774 7.88001 9.18774 7.9375C9.18774 7.99499 9.17642 8.05191 9.15442 8.10502C9.13242 8.15813 9.10018 8.20638 9.05953 8.24703C9.01888 8.28768 8.97063 8.31992 8.91752 8.34192C8.86441 8.36392 8.80749 8.37524 8.75 8.37524C8.69251 8.37524 8.63559 8.36392 8.58248 8.34192C8.52937 8.31992 8.48112 8.28768 8.44047 8.24703L7.4375 7.24352V10.5625C7.4375 10.6785 7.39141 10.7898 7.30936 10.8719C7.22731 10.9539 7.11603 11 7 11C6.88397 11 6.77269 10.9539 6.69064 10.8719C6.60859 10.7898 6.5625 10.6785 6.5625 10.5625V7.24352L5.55953 8.24703C5.51888 8.28768 5.47063 8.31992 5.41752 8.34192C5.36441 8.36392 5.30749 8.37524 5.25 8.37524C5.19251 8.37524 5.13559 8.36392 5.08248 8.34192C5.02937 8.31992 4.98112 8.28768 4.94047 8.24703C4.89982 8.20638 4.86758 8.15813 4.84558 8.10502C4.82358 8.05191 4.81226 7.99499 4.81226 7.9375C4.81226 7.88001 4.82358 7.82309 4.84558 7.76998C4.86758 7.71687 4.89982 7.66862 4.94047 7.62797L6.69047 5.87797C6.7311 5.83729 6.77935 5.80502 6.83246 5.78301C6.88558 5.76099 6.94251 5.74966 7 5.74966C7.05749 5.74966 7.11442 5.76099 7.16754 5.78301C7.22065 5.80502 7.2689 5.83729 7.30953 5.87797L9.05953 7.62797Z' />
              </svg>
              <span style={styles.text}>Unarchive</span>
            </div>
          </StyledTd>
        )}
      </Tr>
    );
  });
};

export default withRouter(ArchivedBusinessLocationsList);

const styles = {
  container: {
    width: 'fit-content',
    padding: '2px',
    margin: '2px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    background: 'rgba(0, 0, 0, 0.60)',
  },
  text: {
    color: '#FFF',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    marginLeft: '8px',
  },
};
