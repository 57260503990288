import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import MobileMessagesContainer from '../Engagement/MobileMessages/MobileMessagesContainer';
import styled from 'styled-components';
import Profile from '../Profile/Profile';
import SummaryReportContainer from '../SummaryReport/SummaryReportContainer';
import BatchDetailContainer from '../Settlements/BatchDetail/BatchDetailContainer';
import { PageNotFound } from 'merchant-portal-components';
import { withRouter } from 'react-router';
import ListSegment from '../Segmentation/ListSegment';
import UsersDashboard from '../Dashboard/Users/index';
import TransactionsDashboard from '../Dashboard/Transactions/index';
import TopLocationsDashboard from '../Dashboard/TopLocations/index';
import AuthenticateModal from '../../modules/Account/components/AuthenticateModal';
import ProductsContainer from '../MenuManagement/Products/ProductsContainer';
import OptionsContainer from '../MenuManagement/OptionManager/OptionsContainer';
import UpsellContainer from '../MenuManagement/Upsell/UpsellContainer';
import Alert from '../Alert/Alert';
import RouteWrapper from '../HOCs/RouteWrapper';
import CouponPromo from '../Engagement/CouponPromo';
import OrderHistoryContainer from '../OrderManager/OrderHistoryList/OrderHistoryContainer';
import FeedbackContainer from '../FeedbackManager/FeedbackList/FeedbackContainer';
import AppFeedLayoutContainer from '../AppFeedLayout/AppFeedLayoutContainer';
import LayoutAssignmentContainer from '../AppFeedLayout/LayoutAssignment/LayoutAssignmentContainer';
import MediaContainer from '../Media/MediaContainer';
import BusinessLocationsContainer from '../BusinessLocationDetails/BusinessLocationsContainer';
import DeliveryZonesViewContainer from '../BusinessLocationDetails/LocationDetailsManager/BusinessWizard/DeliveryZoneInformation/DeliveryZonesContainer';
import DeliveryZonesCreateContainer from '../BusinessLocationDetails/LocationDetailsManager/BusinessWizard/DeliveryZoneInformation/DeliveryZonesCreate/DeliveryZonesCreateContainer';
import DeliveryZonesEditContainer from '../BusinessLocationDetails/LocationDetailsManager/BusinessWizard/DeliveryZoneInformation/DeliveryZonesEdit/DeliveryZonesEditContainer';
import OrdersManagementContainer from '../OrderManagement/OrdersManagementContainer';
import CustomerContainer from '../CustomerManager/CustomerContainer';
import TagsContainer from '../Tags/TagsContainer';
import * as actions from './actions';
import Layout from './Layout';
import RolesAndPermissionsContainer from '../RolesManager/RolesContainer';
import OffersContainer from '../Engagement/Offers/OffersContainer';
import PriceContainer from '../MenuManagement/PriceManager/PriceContainer';
import CombosContainer from '../MenuManagement/CombosManager/CombosContainer';
import Category from '../MenuManagement/Category/CategoryContainer';
import POSContainer from '../MenuManagement/POSMapping/POSContainer';
import ReportContainer from '../Reports/ReportContainer';
import VariantsContainer from '../MenuManagement/Variants/VariantsContainer';
import FeesContainer from '../ServiceFee/FeesContainer';
import moment from 'moment';
import OneTimeMobileMessageContainer from '../Engagement/MobileMessages/OneTimeMessages/OneTimeMobileMessageContainer';
import AutomatedMessagesContainer from '../Engagement/MobileMessages/AutomatedMessages/AutomatedMessagesContainer';
import OfferEngineContainer from '../OfferEngine/OfferEngineContainer';
import OrderTabletUsers from '../OrderTabletUsers/OrderTabletUsers';
import { orderManagementClients } from '../../utils/clientCustomizations';
import ArchivedBusinessLocationsList from '../BusinessLocationDetails/LocationListManager/components/ArchivedBusinessLocationsList';
import ArchivedLocationsContainer from '../BusinessLocationDetails/LocationDetailsManager/BusinessWizard/ArchiveLocation/container/ArchivedLocationsContainer';
const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding: 0 30px;
  width: 100%;
  @media print {
    padding: 0 !important;
  }
`;

let timer;
class Full extends Component {
  state = {
    portalReloadIsVisible: false,
  };

  sidebarMinimize() {
    document.body.classList.add('sidebar-minimized');
    document
      .querySelectorAll('li.open')
      .forEach((li) => li.classList.remove('open'));
  }

  componentWillMount() {
    this.props.onAppLoad();

    track.boot({
      email: this.props.accountReducer.username,
      created_at: moment(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { username, createdAt } = this.props.accountReducer;
    const pathChanged =
      prevProps.location.pathname !== this.props.location.pathname;
    if (pathChanged) {
      window.scrollTo(0, 0);
    }
    if (username != prevProps.accountReducer.username) {
      this.props.onAppLoad();
    }
  }

  render() {
    const {
      token,
      reauthenticate,
      shouldPromptReauth,
      appIdentifier,
      appMetaData,
    } = this.props.accountReducer;

    if (!token && !reauthenticate) {
      localStorage.setItem('redirectedFrom', this.props.location.pathname);
      return <Redirect to='/' />;
    }
    return (
      <div className='app'>
        <Alert />
        <Layout
          locationPathName={this.props.location.pathname}
          appIdentifier={appIdentifier}
          handleSignoutClick={this.props.signoutUser}
          appConfig={appMetaData ? appMetaData.configuration : null}
          promoteTierUpgradeModalIsVisible={
            this.props.promoteTierUpgradeModalIsVisible
          }
          setPromoteTierUpgradeModalIsVisible={
            this.props.setPromoteTierUpgradeModalIsVisible
          }
        >
          <Container>
            <Switch>
              <RouteWrapper
                path='/OfferEngine'
                component={OfferEngineContainer}
              />
              <RouteWrapper
                path='/users-dashboard'
                component={UsersDashboard}
              />
              <RouteWrapper
                path='/transactions-dashboard'
                component={TransactionsDashboard}
              />
              <RouteWrapper
                path='/top-locations-dashboard'
                component={TopLocationsDashboard}
              />

              <RouteWrapper
                path='/app-feed'
                component={AppFeedLayoutContainer}
              />

              <RouteWrapper
                path='/layout-assignment'
                component={LayoutAssignmentContainer}
              />
              <RouteWrapper
                exact
                path='/summary-report'
                strict={false}
                component={SummaryReportContainer}
              />
              <RouteWrapper
                path='/settlements/:id'
                component={BatchDetailContainer}
              />
              {orderManagementClients.includes(appIdentifier) && (
                <RouteWrapper
                  exact
                  path='/order-management'
                  strict={false}
                  component={OrdersManagementContainer}
                />
              )}

              <RouteWrapper
                exact
                path='/order-history'
                strict={false}
                component={OrderHistoryContainer}
              />

              <RouteWrapper
                exact
                path='/media'
                strict={false}
                component={MediaContainer}
              />

              <RouteWrapper path='/password' component={Profile} />

              <RouteWrapper path='/segments' component={ListSegment} />
              <RouteWrapper
                exact
                path='/transaction-report'
                strict={false}
                component={ReportContainer}
              />

              <RouteWrapper
                path='/loyalty-report'
                component={ReportContainer}
              />
              <RouteWrapper path='/offer-report' component={ReportContainer} />
              <RouteWrapper
                path='/credit-code-report'
                component={ReportContainer}
              />
              <RouteWrapper
                path='/product-performance-report'
                component={ReportContainer}
              />

              <RouteWrapper
                path='/customer-report'
                component={ReportContainer}
              />

              <RouteWrapper path='/export-report' component={ReportContainer} />

              <RouteWrapper
                path='/location-transaction-report'
                component={ReportContainer}
              />

              <RouteWrapper
                path='/combo-performance-report'
                component={ReportContainer}
              />

              {/*<RouteWrapper
                path="/category-manager"
                component={CategoryManager}
              />*/}

              <RouteWrapper path='/categories' component={Category} />

              <RouteWrapper path='/pos-mapping' component={POSContainer} />

              <RouteWrapper path='/products' component={ProductsContainer} />

              <RouteWrapper path='/offers' component={OffersContainer} />

              <RouteWrapper path='/combos' component={CombosContainer} />
              <RouteWrapper path='/offers' component={OffersContainer} />

              <RouteWrapper path='/variants' component={VariantsContainer} />
              {/* <RouteWrapper path="/app-detail" component={AppDetailContainer} />
              <RouteWrapper
                path="/app-integration"
                component={AppIntegrationContainer}
              /> */}
              {/* <RouteWrapper
                path="/variant-manager"
                component={variantsReducer}
              /> */}

              <RouteWrapper path='/upsell' component={UpsellContainer} />

              <RouteWrapper path='/options' component={OptionsContainer} />

              <RouteWrapper
                path='/credit-code-management'
                name='Credit Code Promos'
                component={CouponPromo}
              />

              <RouteWrapper
                exact
                path='/mobile-messages'
                component={MobileMessagesContainer}
              />

              <RouteWrapper
                exact
                path='/mobile-messages/one-time-messages'
                component={OneTimeMobileMessageContainer}
              />

              <RouteWrapper
                exact
                path='/mobile-messages/automated-messages'
                component={AutomatedMessagesContainer}
              />

              {/* <RouteWrapper
                exact
                path="/mobile-messages/automated-messages/:name"
                component={GenericDetailsTemplate}
              /> */}

              <RouteWrapper
                exact
                path='/locations'
                strict={false}
                component={BusinessLocationsContainer}
              />
              <RouteWrapper
                exact
                path='/archivedlocations'
                strict={false}
                component={ArchivedLocationsContainer}
              />

              {/* <RouteWrapper
                exact
                path="/locations/:businessLocationId"
                component={BusinessLocationDetailsContainer}
              /> */}

              <RouteWrapper
                exact
                path='/locations/:businessLocationId/delivery-zones/view/'
                component={DeliveryZonesViewContainer}
              />

              <RouteWrapper
                exact
                path='/locations/:businessLocationId/delivery-zones/create/'
                component={DeliveryZonesCreateContainer}
              />

              <RouteWrapper
                exact
                path='/locations/:businessLocationId/delivery-zones/edit/:deliveryZoneId'
                component={DeliveryZonesEditContainer}
              />

              <RouteWrapper path='/tags' component={TagsContainer} />
              <RouteWrapper path='/fees' component={FeesContainer} />

              <RouteWrapper
                path='/roles'
                component={RolesAndPermissionsContainer}
              />

              <RouteWrapper
                path='/orderTabletUsers'
                component={OrderTabletUsers}
              />

              <RouteWrapper
                exact
                path='/customers'
                name='Customers'
                component={CustomerContainer}
              />

              <RouteWrapper
                exact
                path='/customer-feedback'
                name='Customer Feedback'
                component={FeedbackContainer}
              />

              <RouteWrapper
                exact
                path='/option-price'
                component={PriceContainer}
              />

              {/* <RouteWrapper
                exact
                path="/nutritional-template"
                name="Nutritional Template"
                component={NutritionTemplateContainer}
              /> */}

              <Route component={PageNotFound} />
            </Switch>
          </Container>
        </Layout>

        {(!token && reauthenticate) || shouldPromptReauth ? (
          <AuthenticateModal />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accountReducer: state.accountReducer,
    promoteTierUpgradeModalIsVisible:
      state.fullReducer.promoteTierUpgradeModalIsVisible,
  };
}

Full = withRouter(Full);

export default connect(mapStateToProps, actions)(Full);
