import React, { Fragment, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray, Field } from 'formik';
import { Collapse, TextField } from '@material-ui/core';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button, ButtonRow } from 'merchant-portal-components';
import ConfirmCancelModal from '../../components/ConfirmCancelModal';
// import TextFieldPOS from "../../../../POSMapping/components/TextFieldPOS";
// import ToogleFieldPOS from "../../../../POSMapping/components/ToogleFieldPOS";
import ProductPosMappingSummary from './ProductPosMappingSummary';

const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const ProductsDiv = styled.div``;

const LineDivider = styled.hr`
  margin-top: 15px;
  border-color: rgba(0, 0, 0, 0.1);
  grid-column: span ${(props) => `${props.fieldsNumber}`};
`;

const POSDiv = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

const ProductsHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  height: 25px;
`;

const POSHeader = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #2440ae;
  height: 25px;
`;

const POSBody = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 2fr;
`;

const POSFieldWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.fieldsNumber}, auto)`};
  & > div {
    min-width: 100px;
  }
`;

const VariantCell = styled.div`
  height: 80px;
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
`;

const POSValueCell = styled.div`
  height: 80px;
  margin-top: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 0 10px 15px 0;
`;

let ProductPosMappingForm = ({
  isExpanded,
  setIsExpanded,
  setConfirmCancelModalIsVisible,
  confirmCancelModalIsVisible,
  productId,
  productVariantsInitialValues,
  globalVariants,
  productsMappingFields,
  currentProductMappingValues,
  submitProductPosMappingValues,
  getProductVariants,
  getProductInformation,
  upcs,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const updateIsDisabled = useCallback(() => {
    const hasVarianses =
      Object.keys(currentProductMappingValues?.mappings).length > 0;

    if (hasVarianses) {
      setIsDisabled(false);
    }
  }, [currentProductMappingValues]);
  useEffect(() => {
    updateIsDisabled();
  }, [updateIsDisabled]);
  const setIsExpandedFalse = () => {
    setIsExpanded(false);
    getProductVariants(productId, upcs); // to reload active state if a mapping is deleted
    getProductInformation(productId); // to reload product active and pos mapped state
    return productId;
  };
  const handleFormSubmit = (values) => {
    submitProductPosMappingValues(
      { productId: productId },
      productsMappingFields,
      values,
      setIsExpandedFalse
    );
  };

  let variantsToRender = [];
  productVariantsInitialValues.map((originalProductVariant) => {
    let foundGlobalVariant = globalVariants.find(
      (globalVariant) =>
        globalVariant.id === originalProductVariant.product_variant_id
    );
    if (foundGlobalVariant) {
      variantsToRender = [
        ...variantsToRender,
        {
          variantId: originalProductVariant.product_variant_id,
          variantName: `${foundGlobalVariant.name} - ${foundGlobalVariant.label}`,
        },
      ];
    }
  });
  const posFieldNums = Object.keys(productsMappingFields).length;
  return (
    <Card
      disabled={isDisabled}
      onClick={() => !isDisabled && setIsExpanded(!isExpanded)}
    >
      <Formik
        initialValues={currentProductMappingValues}
        enableReinitialize={true}
      >
        {({
          dirty,
          values,
          setValues,
          validateForm,
          isValid,
          handleChange,
        }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title>POS Mappings</Title>
              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={isDisabled}
                  onClick={() => setIsExpanded(false)}
                />
              ) : (
                <ChevronUpIcon
                  disabled={isDisabled}
                  onClick={() => setIsExpanded(false)}
                />
              )}
            </TitleAndCollapseButton>
            {!isExpanded && (
              <ProductPosMappingSummary
                variantsToRender={variantsToRender}
                currentProductMappingValues={currentProductMappingValues}
                productsMappingFields={productsMappingFields}
              />
            )}
            <Collapse in={isExpanded}>
              <Fragment>
                <POSBody>
                  <ProductsDiv onClick={(e) => e.stopPropagation()}>
                    <ProductsHeader>
                      <div>Variant Name</div>
                    </ProductsHeader>
                    <LineDivider />
                    {variantsToRender.map((variant) => {
                      return (
                        <VariantCell key={variant.variantId}>
                          <span>{variant.variantName}</span>
                        </VariantCell>
                      );
                    })}
                  </ProductsDiv>
                  <POSDiv onClick={(e) => e.stopPropagation()}>
                    <POSFieldWrapper fieldsNumber={posFieldNums}>
                      {Object.values(productsMappingFields).map((item) => {
                        return (
                          <POSHeader key={`c_${item['key']}`}>
                            {item['label']}
                            {!item.optional && '*'}
                          </POSHeader>
                        );
                      })}
                      <LineDivider fieldsNumber={posFieldNums} />
                      <FieldArray
                        name='mappings'
                        render={() => {
                          return variantsToRender.map((variant) => {
                            return (
                              <Fragment key={`v_${variant.variantId}`}>
                                {Object.keys(productsMappingFields).map(
                                  (key) => {
                                    return (
                                      <POSValueCell
                                        key={`p_${variant.variantId}_${key}`}
                                      >
                                        <TextField
                                          name={`mappings.${variant.variantId}.${key}`}
                                          value={
                                            Object.keys(values?.mappings)
                                              .length > 0 &&
                                            values?.mappings[variant.variantId]
                                              ? values?.mappings[
                                                  variant.variantId
                                                ][key]
                                              : null
                                          }
                                          onChange={handleChange}
                                          placeholder='POS Value'
                                          variant='outlined'
                                        />
                                      </POSValueCell>
                                    );
                                  }
                                )}
                              </Fragment>
                            );
                          });
                        }}
                      />
                    </POSFieldWrapper>
                  </POSDiv>
                </POSBody>
                <ButtonRow
                  justifyContent='space-between'
                  style={{
                    marginTop: '24px',
                  }}
                >
                  <Button
                    secondary
                    onClick={(e) => {
                      e.stopPropagation();
                      dirty
                        ? setConfirmCancelModalIsVisible(true)
                        : setIsExpanded(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!dirty || !isValid}
                    onClick={(e) => {
                      e.stopPropagation();
                      validateForm().then(() => {
                        handleFormSubmit(values);
                      });
                    }}
                  >
                    Save
                  </Button>
                </ButtonRow>
                {confirmCancelModalIsVisible && (
                  <ConfirmCancelModal
                    onCancel={() => setConfirmCancelModalIsVisible(false)}
                    onConfirm={() => {
                      setValues(currentProductMappingValues);
                      setConfirmCancelModalIsVisible(false);
                      setIsExpanded(false);
                    }}
                  />
                )}
              </Fragment>
            </Collapse>
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};

export default ProductPosMappingForm;
