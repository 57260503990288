import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import AddressAndDetailsForm from './components/AddressAndDetailsForm';

const AddressAndDetailsContainer = ({
  businessLocationDetailsReducer,
  createAddressAndDetailsInformation,
  updateAddressAndDetailsInformation,
  languagesConfig,
  deactivateSection,
  isBusinessAdminRole,
}) => {
  const {
    businessId,
    addressInformationInitialValues,
    countryList,
    isCreatingLocation,
    isEditingAddress,
  } = businessLocationDetailsReducer;

  const handleSubmit = (addressInformation, businessId) => {
    if (isCreatingLocation && !isEditingAddress) {
      createAddressAndDetailsInformation(addressInformation, businessId);
    } else {
      updateAddressAndDetailsInformation(
        addressInformation,
        businessId,
        addressInformationInitialValues
      );
    }
  };

  return (
    <Fragment>
      <AddressAndDetailsForm
        businessId={businessId}
        initialValues={{
          ...addressInformationInitialValues,
        }}
        countryList={countryList.map((country) => ({
          value: country,
          label: country,
        }))}
        handleSubmit={handleSubmit}
        languagesConfig={languagesConfig}
        deactivateSection={deactivateSection}
        isBusinessAdminRole={isBusinessAdminRole}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  businessLocationDetailsReducer: state.businessLocationDetails,
  languagesConfig: state.fullReducer.languagesConfig,
});

export default connect(mapStateToProps, actions)(AddressAndDetailsContainer);
