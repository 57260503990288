import React, { useState, useEffect, useCallback } from "react";
import RegularDayTimeSelectionModal from "./RegularDayTimeSelectionModal";
import RegularHoursList from "./RegularHoursList";
import {
  Wrapper,
  HoursWrapper,
  RegularHoursWrraper,
} from "./styles/regularHoursContainerStyles";
const ReqularHoursContainer = ({
  businessId,
  regularHoursForm,
  setRegularHoursForm,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedHoursType, setSelectedHoursType] = useState();
  const [openingHours, setOpeningHours] = useState([]);
  const [orderingHours, setOrderingHours] = useState([]);
  const updateHoursTable = useCallback(() => {
    const newRegularHours = [...regularHoursForm];
    let newOpeningHoursTable = [];
    let newOrderigHoursTable = [];
    for (let i = 1; i <= 7; i++) {
      const filteredDay = newRegularHours.filter(
        (day) => day.open_day_of_week === i
      );
      let openingStatus;
      let orderingStatus;
      if (filteredDay.length === 1) {
        const hasNextday =
          filteredDay[0].open_day_of_week !== filteredDay[0].close_day_of_week;
        openingStatus = {
          id: i,
          status: {
            hasNextDay: hasNextday,
            open1: filteredDay[0].open_time,
            open2: "",
            close1: filteredDay[0].close_time,
            close2: "",
          },
        };
        orderingStatus = {
          id: i,
          status: !filteredDay[0].order_start_time
            ? "closed"
            : {
                hasNextDay: hasNextday,
                open1: filteredDay[0].order_start_time,
                open2: "",
                close1: filteredDay[0].order_end_time,
                close2: "",
              },
          isStoreClosed: false,
        };
      } else if (filteredDay.length === 2) {
        const hasNextday =
          filteredDay[0].open_day_of_week !==
            filteredDay[0].close_day_of_week ||
          filteredDay[1].open_day_of_week !== filteredDay[1].close_day_of_week;
        openingStatus = {
          id: i,
          status: {
            hasNextDay: hasNextday,
            open1: filteredDay[0].open_time,
            close1: filteredDay[0].close_time,
            open2: filteredDay[1].open_time,
            close2: filteredDay[1].close_time,
          },
        };
        orderingStatus = {
          id: i,
          status:
            !filteredDay[0].order_start_time && !filteredDay[1].order_start_time
              ? "closed"
              : {
                  hasNextDay: hasNextday,
                  open1: filteredDay[0].order_start_time,
                  close1: filteredDay[0].order_end_time,
                  open2: filteredDay[1].order_start_time,
                  close2: filteredDay[1].order_end_time,
                },
          isStoreClosed: false,
        };
      } else {
        openingStatus = { id: i, status: "closed" };
        orderingStatus = { id: i, status: "closed", isStoreClosed: true };
      }
      newOpeningHoursTable.push(openingStatus);
      newOrderigHoursTable.push(orderingStatus);
    }
    setOpeningHours(newOpeningHoursTable);
    setOrderingHours(newOrderigHoursTable);
  }, [regularHoursForm]);
  useEffect(() => {
    updateHoursTable();
  }, [updateHoursTable]);
  const handleOpenModal = (id, type) => {
    setSelectedDay(id);
    setSelectedHoursType(type);
    setIsOpen(true);
  };
  return (
    <>
      <Wrapper>
        <RegularHoursList
          businessId={businessId}
          title="Location"
          hoursTable={openingHours}
          handleOpenModal={handleOpenModal}
        />
        <RegularHoursList
          businessId={businessId}
          title="Ordering"
          hoursTable={orderingHours}
          handleOpenModal={handleOpenModal}
        />
      </Wrapper>
      {isOpen && (
        <RegularDayTimeSelectionModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedDay={selectedDay}
          selectedHoursType={selectedHoursType}
          regularHoursForm={regularHoursForm}
          setRegularHoursForm={setRegularHoursForm}
          businessId={businessId}
        />
      )}
    </>
  );
};

export default ReqularHoursContainer;
