import {
  GET_BUSINESS_ACCOUNTS_REQUEST,
  GET_BUSINESS_ACCOUNTS_SUCCESS,
  CREATE_NEW_USER_REQUEST,
  SET_ERROR_NEW_USER,
  UPDATE_USER,
  PATCH_EDIT_USER,
  TOGGLE_USER_MODAL,
  RESET_IS_REGISTERED,
  ARCHIVE_ADMIN_SUCCESS,
  UNARCHIVE_ADMIN_SUCCESS,GET_ARCHIVED_ACCOUNTS_REQUEST,GET_ARCHIVED_ACCOUNTS_SUCCESS,GET_ARCHIVED_ACCOUNTS_FAILURE
} from './rolesTypes';
import { NEW_USER_REGISTRATION_MESSAGE } from '../NewUserLoginPage/newUserLoginTypes';

export default function(
  state = {
    getBusinessAcountsIsRequesting: true,
    accounts: null,
    submitUserIsRequesting: true,
    error: null,
    isModal: false,
    account: null,
    isUserRegisteredSuccessfully: false,
    getArchivedAccountsIsRequesting: false,
    archivedAccounts:[]
  },
  action
) {
  switch (action.type) {
    case ARCHIVE_ADMIN_SUCCESS:
      return {
        ...state,
        accounts: state.accounts.filter(
          (account) => account.id !== action.payload.id // Remove the archived admin from active accounts
        ),
        archivedAccounts: [...state.archivedAccounts, action.payload] // Add the archived admin to archived accounts
      };
    case UNARCHIVE_ADMIN_SUCCESS: 
      return {
        ...state,
        archivedAccounts: state.archivedAccounts.filter(
          (account) => account.id !== action.payload.id // Remove the unarchived admin
        ),
      };
     case GET_ARCHIVED_ACCOUNTS_REQUEST:
      return {
        ...state,
        getArchivedAccountsIsRequesting: true,
      };
    case GET_ARCHIVED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        getArchivedAccountsIsRequesting: false,
        archivedAccounts: action.accounts,
      };
    case GET_ARCHIVED_ACCOUNTS_FAILURE:
      return {
        ...state,
        getArchivedAccountsIsRequesting: false,
        error: action.error,
      };
    case GET_BUSINESS_ACCOUNTS_REQUEST:
      return {
        ...state,
        getBusinessAcountsIsRequesting: true
      };
    case GET_BUSINESS_ACCOUNTS_SUCCESS:
      return {
        ...state,
        getBusinessAcountsIsRequesting: false,
        accounts: action.accounts
      };
    case CREATE_NEW_USER_REQUEST:
      return {
        ...state,
        submitUserIsRequesting: true,
        error: null
      };
    case SET_ERROR_NEW_USER:
      return { ...state, error: action.error };
    case UPDATE_USER:
      const newAccounts = state.accounts.map(account => {
        if (account.id === action.payload.id) {
          return action.payload;
        }
        return account;
      });
      return { ...state, accounts: newAccounts };
    case PATCH_EDIT_USER:
      const updatedAccounts = state.accounts.map(account => {
        if (account.id === action.payload.account.id) {
          return action.payload.account;
        }
        return account;
      });
      return {
        ...state,
        accounts: updatedAccounts
      };

    case TOGGLE_USER_MODAL:
      return {
        ...state,
        isModal: !state.isModal,

        account: action.account
      };
    case NEW_USER_REGISTRATION_MESSAGE:
      return {
        ...state,
        isUserRegisteredSuccessfully: true
      };
    case RESET_IS_REGISTERED:
      return {
        ...state,
        isUserRegisteredSuccessfully: false
      };
    default:
      return state;
  }
}
