export const GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST =
  'GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST';
export const GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS =
  'GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS';
export const GET_BUSINESS_LOCATIONS_OF_MERCHANT_FAILURE =
  'GET_BUSINESSES_OF_MERCHANT_FAILURE';
export const FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS =
  'FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS';
  export const GET_BUSINESSES_LOCATIONS ='GET_BUSINESSES_LOCATIONS';
  export const UPDATE_BUSINESS_LOCATIONS_REQUEST_PARAMS ='UPDATE_BUSINESS_LOCATIONS_REQUEST_PARAMS';
  
  

  export const ARCHIVE_BUSINESS_SUCCESS = 'ARCHIVE_BUSINESS_SUCCESS';
  export const ARCHIVE_BUSINESS_FAILURE = 'ARCHIVE_BUSINESS_FAILURE';
  export const GET_ARCHIVED_BUSINESSES_SUCCESS = 'GET_ARCHIVED_BUSINESSES_SUCCESS';
  export const GET_ARCHIVED_BUSINESSES_FAILURE = 'GET_ARCHIVED_BUSINESSES_FAILURE';
  export const UNARCHIVE_BUSINESS_SUCCESS = 'UNARCHIVE_BUSINESS_SUCCESS';
  export const UNARCHIVE_BUSINESS_FAILURE = 'UNARCHIVE_BUSINESS_FAILURE';
  
  