import React, { useState } from 'react';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  renderSelect,
  renderTextField,
} from '../../../../../components/FormElements/ReduxForm';
import {
  ModalWrapper,
  ModalTitle,
  ButtonRowWrapper,
  CancelButton,
} from '../styles/styles';

const required = (value) => (value ? undefined : 'Required');
const amountOfRewardCredits = (value) =>
  value < 1 || value > 200 ? 'Amount must be between 1-200' : undefined;
const amountOfRewardLoyaly = (value) =>
  value < 1 || value > 100000 ? 'Amount must be between 1-100,000' : undefined;

export const SendLoyaltyModal = (props) => {
  const {
    customerName,
    getTypesOfRewardOptions,
    handleVisibilitySendLoyaltyModal,
    handleSendLoyaltySubmit,
    formValues,
    requestingSendLoyaltyPointsByCustomerId,
  } = props;
  const [isConfirm, setIsConfirm] = useState(false);
  return (
    <Modal fullWidth maxWidth='sm'>
      <ModalWrapper>
        <ModalTitle>Send Loyalty Rewards to Customer</ModalTitle>
        <Field
          validate={required}
          name='typeOfReward'
          label='Select a reward'
          whiteBackground
          light
          topMod='-24px'
          inLine
          component={renderSelect}
          options={getTypesOfRewardOptions()}
          getOptionValue={(option) => option}
          getOptionLabel={(option) => option.label}
          simpleValue
          valueKey='value'
        />
        <Field
          validate={[
            required,
            formValues && formValues.typeOfReward == 'global-credits'
              ? amountOfRewardCredits
              : amountOfRewardLoyaly,
          ]}
          name='amountOfReward'
          label='Reward Amount'
          component={renderTextField}
          type='number'
        />
        <Field
          validate={required}
          name='rewardNotes'
          label='Internal Note'
          component={renderTextField}
          rows={3}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isConfirm}
              onChange={() => setIsConfirm((prevState) => !prevState)}
              name='checkedB'
              color='primary'
            />
          }
          label='I understand this action cannot be undone'
        />
        <ButtonRowWrapper>
          <div>
            <CancelButton onClick={() => handleVisibilitySendLoyaltyModal()}>
              Cancel
            </CancelButton>
          </div>
          <Button
            disabled={
              requestingSendLoyaltyPointsByCustomerId ||
              !isConfirm ||
              !formValues ||
              (formValues &&
                (!formValues.typeOfReward ||
                  !formValues.amountOfReward ||
                  !formValues.rewardNotes ||
                  (formValues.typeOfReward == 'global-credits'
                    ? formValues.amountOfReward < 1 ||
                      formValues.amountOfReward > 200
                    : formValues.amountOfReward < 1 ||
                      formValues.amountOfReward > 100000)))
            }
            onClick={() => handleSendLoyaltySubmit(formValues)}
          >
            {requestingSendLoyaltyPointsByCustomerId
              ? 'SENDING...'
              : 'Send Loyalty Reward'}
          </Button>
        </ButtonRowWrapper>
      </ModalWrapper>
    </Modal>
  );
};

let SendLoyaltyModalForm = reduxForm({
  form: 'sendLoyaltyModalForm',
})(SendLoyaltyModal);

SendLoyaltyModalForm = connect((state) => {
  return {
    initialValues: { typeOfReward: 'global-credits' },
    formValues: getFormValues('sendLoyaltyModalForm')(state),
  };
})(SendLoyaltyModalForm);

export default SendLoyaltyModalForm;
