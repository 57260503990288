import React, { Fragment, useState, useEffect } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import {
  Wrapper,
  Title,
  AddComboItemButton,
  TitleAndCollapseButton,
  IconEllipsis,
} from '../styles/style';
import SingleComboItem from './SingleComboItem';
import SharedIncludedOptionsModal from './SharedIncludedOptionsModal';
import { Button, ButtonRow } from 'merchant-portal-components';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Collapse } from '@material-ui/core';
import ComboItemsPopUp from './ComboItemsPopUp';
import ComboItemsSortModal from './ComboItemsSortModal';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { TooltipIcon } from '../../../../../../components/Icon';

const SortDiv = styled.div`
  padding: 0 40px 25px;
  text-align: right;
  position:absolute;
  right:0px;
  top:18px;
`;

const StyledComboGrpBtnContainer=styled.div`
position:relative;
`;
const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  color:rgb(36, 64, 174);
`;

const ComboItems = ({
  isComboProdReordDisab,
  showLoader,
  disableComboProdReord,                            
  initValues,
  comboItems,
  getProductsVariants,
  productsVariants,
  getProductsCategories,
  productsCategories,
  getCombosOptionsOverrides,
  combosOptionsOverride,
  isRequestingGetCombosOptionsOverrides,
  options,
  isRequestingGetComboItems,
  isRequestingGetProductsVariants,
  handleComboItemsSubmit,
  handleSortComboGroupsOnly,
  handleReorderComboGroupProdItems,
  getAllProducts,
  allProducts,
  getSharedOptionsComboItems,
  sharedOptionsCombos,
  isRequestingGetSharedOptionsCombos,
  submitComboItemsIsRequesting,
  disabled,
  isComboItemsSubmitSuccess,
  isRequestingGetAllProducts,
  updateIsComboItemSubmit,
  isSavingComboItemsPositions,
  handleSortingComboItemsSubmit,
}) => {
  const [isChargeExtraModal, setIsChargeExtraModal] = useState(false);
  const [chargeExtraModalState, setChargeExtraModalState] = useState({
    productId: null,
    variantId: null,
    comboItemId: null,
  });
  const [showComboGroupSorter,setShowComboGroupSorter]=useState(false);
  const toggleChargeExtraModal = () => {
    setIsChargeExtraModal(!isChargeExtraModal);
  };

  const [isCombosOverridesModal, setIsCombosOverridesModal] = useState(false);
  const [combosOverrideModalState, setCombosOverrideModalState] = useState({
    comboItemId: null,
  });
  const toggleCombosOverridesModal = () => {
    setIsCombosOverridesModal(!isCombosOverridesModal);
  };

  const [isProductModal, setIsProductModal] = useState(false);
  const [productsModalState, setProductsModalState] = useState({
    comboItemId: null,
    itemLabel: '',
    productVariants: [],
  });
  const toggleProductModal = () => {
    setIsProductModal(!isProductModal);
  };

  const [showProductsState, setShowProductsState] = useState([]);
  const toggleShowProductsState = (comboItemId) => {
    if (showProductsState.includes(comboItemId)) {
      setShowProductsState(
        showProductsState.filter((id) => id !== comboItemId)
      );
      return;
    }
    setShowProductsState([...showProductsState, comboItemId]);
  };

  const beautifyProductVariantNames = (name) => {
    return name
      .toLowerCase()
      .split(' ')
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(' ');
  };

  const isComboGroupName = (comboItems) => {
    let errors = [];
    comboItems.map((comboItem) => {
      if (!comboItem.label) {
        errors.push('error');
      }
    });
    if (errors.length) return true;
    return false;
  };

  const initialValues = {
    comboItems: comboItems,
    sharedOptionsCombos: sharedOptionsCombos,
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [isSharedIncludedOptionsModal, setIsSharedIncludedOptionsModal] =
    useState(false);
  const [isSortComboItemsModal, setIsSortComboItemsModal] = useState(false);

  const removeProductVariant = (
    productId,
    variantId,
    comboItemId,
    setFieldValue,
    comboIndex,
    values
  ) => {
    const comboItemsFiltered = values.comboItems.filter(
      (comboItem) => comboItem.id === comboItemId
    );

    let filtered = comboItemsFiltered[0].productVariants.filter(
      (productVariant) =>
        !(
          productVariant.productId === productId &&
          productVariant.variantId === variantId
        )
    );
    setFieldValue(`comboItems[${comboIndex}].productVariants`, filtered);
  };

  const triggerParent=(data,comboItem)=>{
    handleReorderComboGroupProdItems(initValues,data,comboItem)
  }
  return (
    <Wrapper disabled={disabled}>
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ values, handleReset, dirty, errors, setFieldValue }) => (
          <Fragment>
            <TitleAndCollapseButton
              onClick={() => !disabled && setIsExpanded(!isExpanded)}
            >
              <Title>
                <div style={{ display: 'flex' }}>
                  <div>Combo Groups</div>
                  <div>
                    {initValues &&
                      isExpanded &&
                      (dirty ? (
                        <Tooltip
                          title='Save Combo Groups before proceeding further'
                          arrow
                        >
                          <span>
                            <IconEllipsis
                              className='fas fa-ellipsis-v'
                              style={{ fontSize: '20px', marginLeft: '15px' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsSharedIncludedOptionsModal(true);
                              }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <ComboItemsPopUp
                          setIsSharedIncludedOptionsModal={
                            setIsSharedIncludedOptionsModal
                          }
                          setIsSortComboItemsModal={setIsSortComboItemsModal}
                          comboItemsLength={comboItems.length}
                        />
                      ))}
                  </div>
                </div>
              </Title>

              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={disabled}
                  //disabled={!initValues}
                  onClick={() => setIsExpanded(true)}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => setIsExpanded(false)}
                />
              )}
            </TitleAndCollapseButton>

            <Collapse in={isExpanded}>
              <Fragment>
                <FieldArray
                  name='comboItems'
                  render={(arrayHelpers) => {
                    const comboItems = arrayHelpers.form.values.comboItems;
                    return (
                      <Fragment>
                        {isSharedIncludedOptionsModal && (
                          <SharedIncludedOptionsModal
                            comboItems={comboItems}
                            options={options}
                            getSharedOptionsComboItems={
                              getSharedOptionsComboItems
                            }
                            sharedOptionsCombos={sharedOptionsCombos}
                            setIsSharedIncludedOptionsModal={
                              setIsSharedIncludedOptionsModal
                            }
                            initValues={initValues}
                            isRequestingGetSharedOptionsCombos={
                              isRequestingGetSharedOptionsCombos
                            }
                            arrayHelpers={arrayHelpers}
                          />
                        )}
                        {isSortComboItemsModal && (
                          <ComboItemsSortModal
                            initValues={initValues}
                            comboItems={comboItems}
                            setIsSortComboItemsModal={setIsSortComboItemsModal}
                            isSavingComboItemsPositions={
                              isSavingComboItemsPositions
                            }
                            handleSortingComboItemsSubmit={
                              handleSortingComboItemsSubmit
                            }
                          />
                        )}
                        <StyledComboGrpBtnContainer>
                          <AddComboItemButton
                            disabled={Object.keys(errors).length}
                            onClick={() => {
                              arrayHelpers.push({
                                id: null,
                                label: null,
                                is_active: true,
                                is_required: false,
                                productVariants: [],
                                combosOptionsOverride: [],
                              });
                              updateIsComboItemSubmit();
                            }}
                          >
                            + Add Combo Group
                          </AddComboItemButton>
                          <SortDiv>
                            <SortWrapper onClick={() => {
                              setShowComboGroupSorter(!showComboGroupSorter)
                              disableComboProdReord(!isComboProdReordDisab)
                              }}>
                              <i className='far fa-sort-amount-down' /> Reorder Combo Groups
                              <Tooltip
                                style={{ marginRight: 'auto' }}
                                title='Edit the position of combo groups to change the order that they are presented in to the user'
                              >
                                <span>
                                  <TooltipIcon marginLeft />
                                </span>
                              </Tooltip>
                            </SortWrapper>
                          </SortDiv>
                        </StyledComboGrpBtnContainer>
                        <SingleComboItem
                          isComboProdReordDisab={isComboProdReordDisab}
                          showLoader={showLoader}
                          handleSortComboGroupsOnly={handleSortComboGroupsOnly}
                          triggerParent={triggerParent}
                          showComboGroupSorter={showComboGroupSorter}
                          comboItems={comboItems}
                          arrayHelpers={arrayHelpers}
                          setCombosOverrideModalState={
                            setCombosOverrideModalState
                          }
                          toggleCombosOverridesModal={
                            toggleCombosOverridesModal
                          }
                          isCombosOverridesModal={isCombosOverridesModal}
                          combosOverrideModalState={combosOverrideModalState}
                          toggleShowProductsState={toggleShowProductsState}
                          showProductsState={showProductsState}
                          beautifyProductVariantNames={
                            beautifyProductVariantNames
                          }
                          toggleChargeExtraModal={toggleChargeExtraModal}
                          removeProductVariant={removeProductVariant}
                          toggleProductModal={toggleProductModal}
                          isChargeExtraModal={isChargeExtraModal}
                          isProductModal={isProductModal}
                          chargeExtraModalState={chargeExtraModalState}
                          setChargeExtraModalState={setChargeExtraModalState}
                          initValues={initValues}
                          setFieldValue={setFieldValue}
                          handleReset={handleReset}
                          values={values}
                          productsModalState={productsModalState}
                          setProductsModalState={setProductsModalState}
                          getProductsCategories={getProductsCategories}
                          productsCategories={productsCategories}
                          getProductsVariants={getProductsVariants}
                          productsVariants={productsVariants}
                          isRequestingGetProductsVariants={
                            isRequestingGetProductsVariants
                          }
                          allProducts={allProducts}
                          getAllProducts={getAllProducts}
                          getCombosOptionsOverrides={getCombosOptionsOverrides}
                          combosOptionsOverride={combosOptionsOverride}
                          isRequestingGetCombosOptionsOverrides={
                            isRequestingGetCombosOptionsOverrides
                          }
                          options={options}
                          initialValues={initialValues}
                          isComboItemsSubmitSuccess={isComboItemsSubmitSuccess}
                          isRequestingGetAllProducts={
                            isRequestingGetAllProducts
                          }
                        />
                        {comboItems.length !== 0 && (
                          <ButtonRow
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              padding: '10px 48px 48px 48px',
                            }}
                          >
                            <Button
                              onClick={() => {
                                disableComboProdReord(false)
                                handleComboItemsSubmit(initValues, values);
                                handleReset();
                              }}
                              disabled={
                                !dirty ||
                                submitComboItemsIsRequesting ||
                                isComboGroupName(values.comboItems)
                              }
                            >
                              Save
                            </Button>
                          </ButtonRow>
                        )}
                      </Fragment>
                    );
                  }}
                />
              </Fragment>
            </Collapse>
          </Fragment>
        )}
      </Formik>
    </Wrapper>
  );
};

export default ComboItems;
