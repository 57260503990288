import { api } from '../../../api';
import { GET_COMBOS_REQUEST, GET_COMBOS_RESPONSE } from './types';

export function getCombos() {
  return function (dispatch) {
    dispatch({ type: GET_COMBOS_REQUEST });
    api
      .get('combos?page_number=1&page_size=2000')
      .then((response) => {
        dispatch({ type: GET_COMBOS_RESPONSE, response: response.data });
      })
      .catch((error) => {
        // console.log(error);
      });
  };
}
