import React, { useEffect } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Formik, Field } from 'formik';
import {
  RenderTextField,
  required,
} from '../../components/FormElements/FormikElements';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import * as actions from './actions';
import { displaySuccessMessage } from '../RolesManager/rolesActions';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Button, ButtonRow } from 'merchant-portal-components';
import Alert from '../Alert/Alert';
import nav from '../Full/_nav';
import ReactGA from 'react-ga';

import {
  PageWrapper,
  StyledForm,
  SectionWrapper,
  Section,
  ImageWrapper,
} from './styles';

const LostPasswordWrapper = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: right;
  color: black;
  font-weight: 300;
  margin-top: -15px;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10%;
`;

function Login(props) {
  const { initialFormValues } = props.accountReducer;
  const token = localStorage.getItem('token');
  const role = props.accountReducer.role;
  const isButtonLoading = props.accountReducer.isSigningIn;

  useEffect(() => {
    if (props.isUserRegisteredSuccessfully) {
      props.displaySuccessMessage();
    }
  }, [props.isUserRegisteredSuccessfully]);

  const homeUrl = () => {
    const { role } = props.accountReducer;
    // find the parent item that authorizes the user role
    const parentItem = nav.items.find((parentItem) =>
      parentItem.authorization.includes(role)
    );

    // if the parent item has children,
    // then return the child item url that
    // authorizes the user role. else return parent item url
    if (parentItem.children) {
      return parentItem.children.find((childItem) =>
        childItem.authorization.includes(role)
      ).url;
    } else {
      return parentItem.url;
    }
  };

  if (token && role) {
    localStorage.removeItem('redirectedFrom');
    return <Redirect to={homeUrl()} />;
  }

  const handleFormSubmit = (formProps) => {
    props.signinUser(formProps);
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={(values) => {
        ReactGA.event({
          category: 'Click',
          action: 'Login',
          label: 'Login',
        });
        handleFormSubmit(values);
      }}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => (
        <PageWrapper>
          <Alert />
          <SectionWrapper>
            <Paper component={Section}>
              <ImageWrapper>
                <img
                  src="https://s3.amazonaws.com/assets.smoothpay.com/web/portal-assets/smooth-commerce-new-logo.png"
                  width="40%"
                />
              </ImageWrapper>
              <MessageWrapper id="Login-Message">
                Welcome to the Merchant Portal!
              </MessageWrapper>
              <StyledForm onSubmit={handleSubmit}>
                <Field
                  id="Login-emailField"
                  name="email"
                  placeholder="Username"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          className={'fas fa-user'}
                          style={{ color: 'grey' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  type="text"
                  component={RenderTextField}
                  validate={required}
                  size="small"
                  style={{ height: '48px' }}
                />
                <Field
                  id="Login-passwordField"
                  name="password"
                  placeholder="Password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          className={'fas fa-lock'}
                          style={{ color: 'grey' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  type="password"
                  component={RenderTextField}
                  validate={required}
                  size="small"
                  style={{ height: '48px' }}
                />
                <LostPasswordWrapper>
                  <Link
                    id="Login-requestPasswordChangeButton"
                    to="/request-password-reset"
                    style={{ color: 'black' }}
                  >
                    Forgot password?
                  </Link>
                </LostPasswordWrapper>
                <ButtonRow style={{ justifyContent: 'center' }}>
                  <Button
                    id="Login-submitButton"
                    type="submit"
                    disabled={isButtonLoading}
                  >
                    Login
                  </Button>
                </ButtonRow>
              </StyledForm>
            </Paper>
          </SectionWrapper>
        </PageWrapper>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => ({
  accountReducer: state.accountReducer,
  isUserRegisteredSuccessfully: state.rolesReducer.isUserRegisteredSuccessfully,
});

export default connect(mapStateToProps, { ...actions, displaySuccessMessage })(
  Login
);
