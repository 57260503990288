import React, { useState, useEffect, Fragment, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { styled as materialStyled } from '@material-ui/core/styles';
import ProductCard from './ProductCard';
import { Button } from 'merchant-portal-components';
import {
  getCategoriesUpsellModal,
  getProductsUpsellModal,
  saveUpsellModalSelectedProducts,
} from '../upsellActions';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Select } from 'merchant-portal-components';
import { DialogContent, DialogActions, Dialog } from '@material-ui/core';

const ModalTitle = styled.div`
  text-align: center;
  margin: 30px 0;
  font-weight: bold;
  text-transform: uppercase;
`;

const ProductsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 20px;
  overflow-y: auto;
  cursor: pointer;
  margin-top: 30px;
`;

const ButtonsDiv = styled.div`
  padding: 20px;
`;

const SelectedDiv = styled.div`
  padding-top: 20px;
  text-align: center;
  overflow-y: auto;
  background-color: #f0f2f6;
`;

const SelectedDivTitle = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
`;

const StyledDialogContent = materialStyled(DialogContent)({
  height: '85vh',
  overflowY: 'hidden',
  padding: '0 !important',
});

const StyledDialogActions = materialStyled(DialogActions)({
  justifyContent: 'left',
});

const ContentModal = styled.div`
  display: grid;
  grid-template-columns: auto 255px;
  grid-gap: 20px;
  padding: 10px;
  width: 750px;
  height: 100%;
  overflow-y: hidden;

  @media only screen and (min-width: 1700px) {
    width: 900px;
  }
`;

const LoadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  padding: 10px;
  width: 750px;
  background-color: white;
  display: ${(props) => (props.noChangesState ? 'none' : 'block')};

  @media only screen and (min-width: 1700px) {
    width: 900px;
  }
`;

const IncompleteMessage = styled.span`
  color: #e14b4b;
  font-size: 13px;
  margin-left: 20px;
`;

const CriteriaDiv = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: 84px 56px auto;
`;

const ProductSelector = ({
  categories,
  categoriesLoading,
  products,
  productsUpsellMenu,
  getCategoriesUpsellModal,
  getProductsUpsellModal,
  saveUpsellModalSelectedProducts,
  cancel,
}) => {
  const sidebarEnd = useRef(null);

  const [upsellMessage, setUpsellMessage] = useState('');

  const scrollToBottom = (behavior) => {
    sidebarEnd.current.scrollIntoView({ behavior });
  };

  useEffect(() => {
    getCategoriesUpsellModal();
    setUpsellMessage('Please select a category');
  }, []);

  useEffect(() => {
    return () => {
      if (products) products.length = 0;
      setUpsellMessage('There are no products for the selected category');
    };
  }, [products]);

  const [selectedProducts, setSelectedProducts] = useState([
    ...productsUpsellMenu,
  ]);

  const toggleProduct = (product) => {
    const selectedProductsId = selectedProducts.map((p) => p.id);
    if (selectedProductsId.includes(product.id)) {
      setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id));
    } else {
      setSelectedProducts([...selectedProducts, product]);
    }
    scrollToBottom('smooth');
  };

  const renderProducts = () => {
    return products.map((product) => {
      const selected = selectedProducts.map((p) => p.id).includes(product.id);
      return (
        <div
          key={product.id}
          style={{
            opacity: selected || !product.is_priced ? '0.5' : '1',
          }}
          onClick={() => {
            product.is_priced && toggleProduct(product);
          }}
        >
          <ProductCard
            imageUrl={product.image_url}
            productName={product.name}
            isSelected={selected}
          />
          {!product.is_priced && (
            <IncompleteMessage>
              <i className="fal fa-exclamation-triangle"></i> Incomplete setup
            </IncompleteMessage>
          )}
        </div>
      );
    });
  };

  const renderCategories = () => {
    return categories
      .map((category) => {
        if (category.sub_categories.length) {
          return category.sub_categories.map((subcategory) => ({
            name: category.name + ' - ' + subcategory.name,
            id: subcategory.id,
          }));
        } else {
          return {
            name: category.name,
            id: category.id,
          };
        }
      })
      .flat();
  };

  const onSelectedSave = () => {
    saveUpsellModalSelectedProducts(productsUpsellMenu, [...selectedProducts]);
    cancel();
  };

  const selectedProductRemove = (productId) => {
    setSelectedProducts(selectedProducts.filter((p) => p.id !== productId));
  };

  const objectsAreSame = (existingValue, newValue) => {
    if (existingValue.length && newValue.length) {
      if (existingValue.length === newValue.length) {
        var objectsAreSame = true;
        for (var index in existingValue) {
          for (var index in newValue) {
            if (
              parseInt(existingValue[index].id) !== parseInt(newValue[index].id)
            ) {
              objectsAreSame = false;
              break;
            }
          }
        }
        return objectsAreSame;
      }
    }
  };

  return (
    <Dialog open maxWidth="md">
      {categoriesLoading ? (
        <LoadingWrapper>
          <LoadingBlueLarge />
        </LoadingWrapper>
      ) : (
        <Fragment>
          <StyledDialogContent>
            <ContentModal>
              <CriteriaDiv>
                <ModalTitle>Add Products to Upsell Menu</ModalTitle>
                <Select
                  options={renderCategories()}
                  getOptionLabel={(category) => category.name}
                  getOptionValue={(category) => category}
                  label="Product Category"
                  onChange={(category) => {
                    getProductsUpsellModal(category.id);
                  }}
                />

                <ProductsWrapper border>
                  {!products || !products.length ? (
                    <span style={{ cursor: 'default', fontStyle: 'italic' }}>
                      {upsellMessage}
                    </span>
                  ) : (
                    ''
                  )}
                  {categories && products && renderProducts()}
                </ProductsWrapper>
              </CriteriaDiv>
              <SelectedDiv>
                <SelectedDivTitle>Upsell Menu</SelectedDivTitle>
                {selectedProducts.map((product) => {
                  return (
                    <ProductCard
                      productId={product.id}
                      imageUrl={product.image_url}
                      productName={product.name}
                      key={product.id}
                      imageSidebarSize
                      selectedProductRemove={selectedProductRemove}
                    />
                  );
                })}
                <div
                  style={{ float: 'left', clear: 'both' }}
                  ref={sidebarEnd}
                />
              </SelectedDiv>
            </ContentModal>
          </StyledDialogContent>
          <StyledDialogActions>
            <ButtonsDiv>
              <Button
                style={{ background: 'none', color: '#2440ae', border: 'none' }}
                onClick={cancel}
              >
                CANCEL
              </Button>
              <Button
                onClick={() => onSelectedSave()}
                disabled={objectsAreSame(productsUpsellMenu, selectedProducts)}
              >
                Save
              </Button>
            </ButtonsDiv>
          </StyledDialogActions>
        </Fragment>
      )}
    </Dialog>
  );
};

function mapStateToProps(state) {
  return {
    categories: state.upsellReducer.upsellCategoriesModal,
    categoriesLoading: state.upsellReducer.requestingGetCategoriesUpsellModal,
    products: state.upsellReducer.upsellProductsModal,
    productsUpsellMenu: state.upsellReducer.upsellMenu,
    //reduxError: state.error
  };
}

export default connect(mapStateToProps, {
  getCategoriesUpsellModal,
  getProductsUpsellModal,
  saveUpsellModalSelectedProducts,
})(ProductSelector);
