import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Collapse } from '@material-ui/core';
import { SmallToggleSwitch } from '../../../../../../components/ToggleSwitch/ToggleSwitch';

const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 28px;
  align-items: center;
`;

const NoOverridesText = styled.div`
  margin-bottom: 20px !important;
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

let LocationArchiveForm = ({
  is_archived,
  archiveLocation,
  unArchiveLocation,
  isExpanded,
  setIsExpanded,
  locationId,
  getBusinessLocations,
  disabled,
  archivedLocations,
  isELigibleForArchiving,
}) => {
  const [isArchived, setIsArchived] = useState(is_archived);

  useEffect(() => {
    setIsArchived(is_archived);
  }, [is_archived]);

  const handleToggleChange = (checked) => {
    setIsArchived(checked);

    if (checked) {
      archiveLocation([locationId], () => {
        console.log('Location archived');
      });
    } else {
      unArchiveLocation([locationId], () => {
        console.log('Location unarchived');
      });
    }

    getBusinessLocations();
  };
  return (
    <div>
      <Card disabled={!isELigibleForArchiving}>
        <TitleAndCollapseButton>
          <Title>Archive</Title>

          {!isExpanded ? (
            <ChevronDownIcon
              disabled={disabled}
              onClick={() => setIsExpanded(true)}
            />
          ) : (
            <ChevronUpIcon
              onClick={() => setIsExpanded(false)}
              disabled={disabled}
            />
          )}
        </TitleAndCollapseButton>
        {!isArchived ? (
          <NoOverridesText>
            Archive this location to temporarily remove it from active listings
            without permanently deleting it. You can restore the location at any
            time if needed. Please note that only inactive businesses can be
            archived. To inactivate a business, please contact support.
          </NoOverridesText>
        ) : (
          <NoOverridesText>
            Unarchive this location to include it in active/inactive listings.
          </NoOverridesText>
        )}
        <Collapse in={isExpanded}>
          <div>
            <ToggleWrapper>
              <SmallToggleSwitch
                disabled={!isELigibleForArchiving}
                checked={isArchived}
                onChange={(e) => handleToggleChange(e.target.checked)}
              />
              {isArchived ? (
                <div> Unarchive Location</div>
              ) : (
                <div> Archive Location</div>
              )}
            </ToggleWrapper>
          </div>
        </Collapse>
      </Card>
    </div>
  );
};

export default LocationArchiveForm;
