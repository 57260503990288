import React, { useMemo, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '300px',
    },
    '&:focus': {
      outline: 'none !important',
    },
  },
}));
const Notes = styled.div`
  display: grid;
  margin-bottom: 15px;
  font-size: 15px;
  font-weight: normal;
`;
const RemoveIcon = styled.i`
  cursor: pointer;
  font-size: 20px;
`;
const SharableWrapper = styled.i`
  display: grid;
  grid-template-columns: 1fr 20px;
  align-items: center;
`;
const CodeRowWrraper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 20px;
  grid-gap: 50px;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;
const AddButton = styled.button`
  padding: 15px 5px;
  border-radius: 10px;
  background: #2440ae;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: 0px;
  width: ${(props) => props.width || '100px'};
  margin-top: 30px;
  cursor: pointer;
  &:disabled {
    background: #ebebeb;
    color: #6f6f76;
    cursor: default;
  }
`;

const WrapperMain = styled.div`
  padding-bottom: 20px;
  textarea {
    width: 100%;
    border-color: #cdcdcd;
    border-radius: 6px;
    padding: 10px;
  }
  .actionButton {
    padding: 13px 30px 13px 30px;
    border-radius: 28px;
    background: #2440ae !important;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    border: 0px;
    width: max-content;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .textRight {
    text-align: right;
  }
`;

function TabPanel(props) {
  const { children, value, index, values, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pb: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabPromo = ({
  values,
  setFieldValue,
  setAvailabilityModalState,
  selectedPromoCode,
  setSelectedPromoCode,
}) => {
  const [promoCodeList, setPromoCodeList] = useState([
    {
      code: '',
      is_shareable: false,
      is_used: false,
    },
  ]);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeCode = (e, i) => {
    const newPromoCodeList = [...promoCodeList];
    newPromoCodeList[i] = { ...newPromoCodeList[i], code: e.target.value };
    setPromoCodeList(newPromoCodeList);
  };
  const handleChangeIsShareable = (e, i) => {
    const newPromoCodeList = [...promoCodeList];
    newPromoCodeList[i] = {
      ...newPromoCodeList[i],
      is_shareable: e.target.checked,
    };
    setPromoCodeList(newPromoCodeList);
  };
  const handleAddCode = () => {
    const newPromoCodeList = [...promoCodeList];
    newPromoCodeList.push({ code: '', is_shareable: false });
    setPromoCodeList(newPromoCodeList);
  };
  const handleRemoveCode = (index) => {
    const newPromoCodeList = [...promoCodeList];
    if (newPromoCodeList.length > 1) {
      newPromoCodeList.splice(index, 1);
      setPromoCodeList(newPromoCodeList);
    }
  };
  const isSaveDisabled = useMemo(() => {
    let newIsDisabled = false;
    promoCodeList.forEach((promoCode) => {
      if (promoCode.code.length < 5) newIsDisabled = true;
    });
    return newIsDisabled;
  }, [promoCodeList]);
  const handleSave = () => {
    let newOfferPromoCodes = [...values.offerPromoCode];
    promoCodeList.forEach((promo) => {
      newOfferPromoCodes.push(promo);
    });
    setFieldValue('offerPromoCode', newOfferPromoCodes);
    setAvailabilityModalState(false);
  };
  return (
    <WrapperMain>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          ></Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {promoCodeList.map((promo, i) => (
            <CodeRowWrraper key={i}>
              <TextField
                value={promo?.code}
                variant='outlined'
                placeholder='Enter code'
                onChange={(e) => handleChangeCode(e, i)}
                error={promo?.code.length < 5}
                helperText={promo?.code.length < 5 && 'At least 5 characters'}
                size='small'
              />
              <SharableWrapper>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      onChange={(e) => handleChangeIsShareable(e, i)}
                      checked={promo?.is_shareable}
                    />
                  }
                  label='Shareable?'
                />
                <Tooltip title='A shareable code can be shared with multiple users whereas an unshareable code can be used by one individual customer only. Whether the promo code can be used single time or multiple times by the same user would be defined by Reusability value. If the offer is reusable, the same customer will be able to use the same promo code multiple times'>
                  <i className='fa fa-info-circle fa-lg'></i>
                </Tooltip>
              </SharableWrapper>
              {i !== 0 && (
                <RemoveIcon
                  className='fa fa-minus-circle'
                  onClick={() => handleRemoveCode(i)}
                ></RemoveIcon>
              )}
            </CodeRowWrraper>
          ))}
          <AddButton
            onClick={handleAddCode}
            width='200px'
            disabled={selectedPromoCode}
          >
            + Add New Code
          </AddButton>
        </TabPanel>
      </Box>
      <div className='textRight'>
        <AddButton disabled={isSaveDisabled} onClick={handleSave} width='150px'>
          Save Codes
        </AddButton>
      </div>
    </WrapperMain>
  );
};
export default TabPromo;
