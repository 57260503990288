import React from 'react';
import styled from 'styled-components';
import { ButtonGroup } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
`;
const ButtonTab = styled.button`
  display: flex;
  align-items: center;
  padding: 7px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? '#E6EBFF' : 'transparent'};
  border: none;
`;

const QtyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 35px;
  width: 35px;
  background-color: #2440ae;
  color: #fff;
  border-radius: 50%;
  margin-left: 10px;
`;

const ActiveInactiveTabs = ({
  selectedTab = 'active',
  setSelectedTab,
  inactiveQty = 0,
  activeQty = 0,
}) => {
  const handleSelectStatus = (type) => {
    setSelectedTab(type);
  };
  return (
    <ButtonGroup
      aria-label='small outlined button group'
      style={{ borderRadius: '5px', border: '1px solid #dcdcdc' }}
    >
      <ButtonTab
        isSelected={selectedTab === 'active'}
        onClick={() => handleSelectStatus('active')}
      >
        Active <QtyWrapper>{activeQty}</QtyWrapper>
      </ButtonTab>
      <ButtonTab
        isSelected={selectedTab === 'inactive'}
        onClick={() => handleSelectStatus('inactive')}
      >
        Inactive <QtyWrapper>{inactiveQty}</QtyWrapper>
      </ButtonTab>
    </ButtonGroup>
  );
};

export default ActiveInactiveTabs;
