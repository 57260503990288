import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import moment from 'moment';
import ProductSortModal from '../../CategoryList/CategorySort/ProductSortModal';
import {
  RenderTextField,
  RenderTimePicker,
  required,
  validateTwoChar,
  isNumberValidation,
  maxLengthValidationForNameField,
} from '../../../../../components/FormElements/FormikElements';
import {
  MainDiv,
  TimeSelectionGrid,
  HoursField,
  ImageWrapper,
  ChooseImage,
  Image,
} from './createEditFormStyles';
import MediaModal from '../../../components/MediaModal';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../../components/Icon';
import Icon from '@material-ui/core/Icon';

const MainDivWrapper = styled(MainDiv)`
  margin-left: 40px;
  position: relative;
`;

const IsActiveRow = styled.div`
  display: grid;
  grid-template-columns: 170px 20px;
  align-items: center;
`;

const PositionField = styled.div`
    width: 140px;
  margin-top: 20px;
`;

const RemoveSubCategory = styled.button`
  background: none;
  border: none;
  color: #191818;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  margin-top: 20px;
`;

const SortDiv = styled.div`
  padding: 0 20px 25px;
  text-align: right;
  position: absolute;
  right: 0px;
  top: 10px;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;

const SubCategoryForm = ({
  subCategory,
  categoryIndex,
  setFieldValue,
  touched,
  arrayHelpers,
  editingCategory,
  isSortingRequested,
  index,
  sortCategories,
  subCategoryHoursError,
  parentCategory,
  sortProducts,
  categoryId,
  productsAssociatedToCategory,
  formHeadName,
  categorydata,
}) => {
  const [mediaSelectionModalIsVisible, setMediaSelectionModalIsVisible] =
    useState(false);

  useEffect(() => {
    if (!editingCategory.isCategoryActive) {
      setFieldValue(`categorySubCategories[${categoryIndex}].is_active`, false);
    }
  }, [editingCategory.isCategoryActive]);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const handleIsCategoryAvailableAllDay = () =>
    subCategory.start_time.format('HH:mm:ss') === '00:00:00' &&
    subCategory.end_time.format('HH:mm:ss') === '23:59:59';

  const [isCategoryAvailableAllDay, setIsCategoryAvailableAllDay] = useState(
    handleIsCategoryAvailableAllDay(subCategory)
  );

  const toggleIsCategoryAvailableAllTime = (checked) => {
    if (checked) {
      setFieldValue(
        `categorySubCategories[${categoryIndex}].start_time`,
        moment('00:00:00', 'HH:mm:ss')
      );
      setFieldValue(
        `categorySubCategories[${categoryIndex}].end_time`,
        moment('23:59:59', 'HH:mm:ss')
      );
      setIsCategoryAvailableAllDay(true);
    } else {
      setFieldValue(
        `categorySubCategories[${categoryIndex}].start_time`,
        subCategory.start_time
      );
      setFieldValue(
        `categorySubCategories[${categoryIndex}].end_time`,
        subCategory.end_time
      );
      setIsCategoryAvailableAllDay(false);
    }
  };

  const validatePosition = (arrayHelpers) => {
    let message = null;
    !subCategory.id
      ? arrayHelpers.form.touched.categorySubCategories &&
        arrayHelpers.form.touched.categorySubCategories[categoryIndex] &&
        arrayHelpers.form.touched.categorySubCategories[categoryIndex].position
        ? arrayHelpers.form.values.categorySubCategories[categoryIndex].position
          ? new RegExp(`^[1-9][0-9]*$`).test(
              parseFloat(
                arrayHelpers.form.values.categorySubCategories[categoryIndex]
                  .position
              )
            )
            ? null
            : (message = 'Position must be a valid number greater than 0')
          : (message = 'Required')
        : null
      : arrayHelpers.form.values.categorySubCategories[categoryIndex].position
      ? new RegExp(`^[1-9][0-9]*$`).test(
          parseFloat(
            arrayHelpers.form.values.categorySubCategories[categoryIndex]
              .position
          )
        )
        ? null
        : (message = 'Position must be a valid number greater than 0')
      : (message = 'Required');
    return message;
  };

  return (
    <MainDivWrapper>
      <SortDiv>
        <SortWrapper onClick={() => setIsSortModalOpen(true)}>
          <i className="far fa-sort-amount-down" /> Reorder Products
        </SortWrapper>
      </SortDiv>
      <div style={{ display: 'grid', gridRowGap: '20px' }}>
        <Field
          name={`categorySubCategories[${categoryIndex}].name`}
          component={RenderTextField}
          label="Subcategory Name"
          error={
            arrayHelpers.form.touched.categorySubCategories &&
            arrayHelpers.form.touched.categorySubCategories[categoryIndex] &&
            arrayHelpers.form.touched.categorySubCategories[categoryIndex].name
              ? arrayHelpers.form.values.categorySubCategories[categoryIndex]
                  .name
                ? arrayHelpers.form.values.categorySubCategories[categoryIndex]
                    .name.length < 2
                  ? 'Required at least two characters'
                  : maxLengthValidationForNameField(
                      arrayHelpers.form.values.categorySubCategories[
                        categoryIndex
                      ].name
                    )
                : 'Required'
              : null
          }
        />
        <PositionField>
          <Field
            name={`categorySubCategories[${categoryIndex}].position`}
            component={RenderTextField}
            label="Position"
            error={validatePosition(arrayHelpers)}
            validate={isNumberValidation}
          />
        </PositionField>
        <IsActiveRow>
          <SmallToggleSwitch
            checked={
              editingCategory.isCategoryActive ? subCategory.is_active : false
            }
            onChange={() =>
              setFieldValue(
                `categorySubCategories[${categoryIndex}].is_active`,
                !subCategory.is_active
              )
            }
            value="checkedB"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            label="Activate"
            disabled={!editingCategory.isCategoryActive}
          />
          {!editingCategory.isCategoryActive && (
            <Tooltip title="The subcategory can not be active if the category is inactive">
              <div>
                <Icon
                  className="fal fa-info-circle"
                  style={{
                    cursor: 'inherit',
                    marginTop: '5px',
                    fontSize: '16px',
                  }}
                />
              </div>
            </Tooltip>
          )}
        </IsActiveRow>
        <SmallToggleSwitch
          checked={isCategoryAvailableAllDay}
          onChange={({ target: { checked } }) =>
            toggleIsCategoryAvailableAllTime(checked)
          }
          value="checkedB"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          label="Available All Day"
        />
        {!isCategoryAvailableAllDay && (
          <TimeSelectionGrid>
            <HoursField>
              Start
              <Field
                name={`categorySubCategories[${categoryIndex}].start_time`}
                component={RenderTimePicker}
                onChange={(value) => {
                  setFieldValue(
                    `categorySubCategories[${categoryIndex}].start_time`,
                    moment(value)
                  );
                }}
                hideDate
                min={new Date(moment(editingCategory.categoryStartTime))}
              />
            </HoursField>

            <HoursField>
              End
              <Field
                name={`categorySubCategories[${categoryIndex}].end_time`}
                component={RenderTimePicker}
                onChange={(value) => {
                  setFieldValue(
                    `categorySubCategories[${categoryIndex}].end_time`,
                    moment(value)
                  );
                }}
                hideDate
                max={new Date(moment(editingCategory.categoryEndTime))}
              />
            </HoursField>
          </TimeSelectionGrid>
        )}
        {/* <div>
          {subCategoryHoursError.map(error => {
            if (subCategory.id === error.subCategoryId) {
              return (
                <span
                  key={error.subCategoryId}
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    fontStyle: 'italic'
                  }}
                >
                  Hours selected should be within the range of category time.
                </span>
              );
            }
          })}
        </div> */}
        <div>
          {!subCategory.id && (
            <RemoveSubCategory onClick={() => arrayHelpers.remove(index)}>
              - Remove
            </RemoveSubCategory>
          )}
        </div>
        <div></div>
      </div>
      {/* <div>
        <ImageWrapper>
          <div
            onClick={() => setMediaSelectionModalIsVisible(true)}
            style={{ alignSelf: 'stretch' }}
            style={{ cursor: 'pointer', height: '100%' }}
          >
            {!subCategory.image_url ? (
              <ChooseImage>+ Choose Image</ChooseImage>
            ) : (
              <Image src={subCategory.image_url} />
            )}
          </div>
          <div style={{ textAlign: 'center' }}>or</div>
          <div>
            <Field
              name={`categorySubCategories[${categoryIndex}].image_url`}
              component={RenderTextField}
              label="Image URL"
              // error={
              //   arrayHelpers.form.touched.categorySubCategories &&
              //   arrayHelpers.form.touched.categorySubCategories[
              //     categoryIndex
              //   ] &&
              //   arrayHelpers.form.touched.categorySubCategories[categoryIndex]
              //     .image_url &&
              //   !arrayHelpers.form.values.categorySubCategories[categoryIndex]
              //     .image_url
              //     ? 'Required'
              //     : null
              // }
              //validate={required}
            />
          </div>
        </ImageWrapper>
        {mediaSelectionModalIsVisible && (
          <MediaModal
            closeModal={() => setMediaSelectionModalIsVisible(false)}
            selectProduct={(id, url) => {
              setFieldValue(
                `categorySubCategories[${categoryIndex}].image_url`,
                url
              );
            }}
          />
        )}
      </div> */}
      {isSortModalOpen && (
        <ProductSortModal
          categories={
            arrayHelpers.form.values.categorySubCategories[index].products
          }
          setIsSortModalOpen={setIsSortModalOpen}
          formTypeName="productSorting"
          formHeadName="subcategory"
          sortCategories={sortCategories}
          sortProducts={sortProducts}
          isSortingRequested={isSortingRequested}
          editingCategory={categorydata}
          categoryId={subCategory.id}
          subCategoryIndex={index}
        />
      )}
    </MainDivWrapper>
  );
};

export default SubCategoryForm;
