import React, { Component } from 'react';
import { connect } from 'react-redux';

function fetchOnParamChange(
  WrappedComponent,
  action,
  initialRequestParams,
  newSortMethod
) {
  class Container extends Component {
    state = {
      requestParams: initialRequestParams,
      newSortMethod: newSortMethod || false,
    };

    componentDidMount() {
      this.props.action(this.state.requestParams);
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.requestParams !== this.state.requestParams) {
        this.props.action(this.state.requestParams);
      }
      if (!prevProps.token && this.props.token) {
        this.props.action(this.state.requestParams);
      }
    }

    updateRequestParams = (requestParams) => {
      this.setState({ requestParams });
    };

    sortBy = (option) => {
      if (this.state.newSortMethod) {
        const { requestParams } = this.state;

        this.updateRequestParams({
          ...requestParams,
          sort_by: option,
          sort_direction:
            requestParams.sort_direction === 'asc' ? 'desc' : 'asc',
        });
      } else {
        const { requestParams } = this.state;
        const currentOptionAsArray = requestParams.sorting_option.split('-');
        if (currentOptionAsArray[0] === option) {
          let order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
          this.updateRequestParams({
            ...requestParams,
            sorting_option: `${option}-${order}`,
          });
        } else {
          this.updateRequestParams({
            ...requestParams,
            sorting_option: `${option}-asc`,
          });
        }
      }
    };

    render() {
      return (
        <WrappedComponent
          updateRequestParams={this.updateRequestParams}
          sortBy={this.sortBy}
          requestParams={this.state.requestParams}
          {...this.props}
        />
      );
    }
  }

  function mapStateToProps(state) {
    return {
      token: state.accountReducer.token,
    };
  }

  return connect(mapStateToProps, { action })(Container);
}

export default fetchOnParamChange;
