import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 10px 0 50px 0;
`;
export const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-gap: 15px;
`;
export const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
`;
export const AddSpecialHoursButton = styled.button`
  font-weight: 600;
  cursor: pointer;
  color: #2440ae;
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 24px;
  background: #f1f1fa;
  text-align: center;
`;
export const HoursTableWrraper = styled.div`
  margin-top: 25px;
`;
export const HoursTable = styled.div`
  position: relative;
  display: table;
  border-collapse: collapse;
  width: 100%;
`;
export const HoursTableBody = styled.tbody`
  min-width: 100%;
`;

///

export const HoursTableRow = styled.div`
  display: grid;
  grid-template-columns: 100px 100px 1fr 70px 70px;
  grid-gap: 15px;
  height: 50px;
  border-bottom: ${(props) =>
    props.isHeader ? "2px solid" : "1px solid #ebebeb"};
`;
export const HoursTableHeader = styled.div`
  font-weight: bold;
  width: ${(props) => props.width};
  text-align: ${(props) => props.align || "left"};
  font-size: 14px;
`;
export const HoursTableColumn = styled.div`
  text-align: left;
  column-width: ${(props) => props.width};
  text-align: ${(props) => props.align || "left"};
  margin: auto;
  font-size: ${(props) => (props.isClosed ? "12px" : "14px")};
  font-weight: ${(props) => (props.isClosed ? 700 : "normal")};
`;
export const EditButton = styled.i`
  color: #888888;
  cursor: pointer;
  &:hover {
    color: #6f6f76;
  }
`;
export const DeleteButton = styled.i`
  color: #888888;
  cursor: pointer;
  &:hover {
    color: #6f6f76;
  }
`;
export const DaysWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 60px);
  grid-gap: 25px;
  max-width: 600px;
  margin: auto !important;
`;
export const Day = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  background-color: ${(props) =>
    props.isSelected ? "#BECFEE" : "transparent"};
  border-radius: 50%;
`;
export const DetailWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
`;
export const TableWrapper = styled.div`
  min-height: 400px;
  background-color: #f1f6f5;
  padding: 25px;
  border: 1px solid #dddddd;
`;
export const TableTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  gap: 25px;
`;
