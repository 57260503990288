import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import ActionPopper from '../components/ActionPopper';
import { CircularProgress } from '@material-ui/core';

import {
  HeaderWrapper,
  RowWrapper,
  CellWrapper,
  ElapsedWrapper,
  Badge,
} from '../styles/ActiveOrderListStyles';
const getStatus = (status) => {
  switch (status) {
    case -1:
      return 'Refounded';
    case 0:
      return 'New';
    case 1:
      return 'In Progress';
    case 2:
      return 'Ready For Pick up';
    case 3:
      return 'Rejected';
    case 4:
      return 'On Delivery';
    case 5:
      return 'Delivered';
    case 6:
      return 'Future Order';
    default:
      return 'New';
  }
};
const ActiveOrdersList = ({
  orderList,
  updateOrderId,
  updateCurrentPage,
  updateRedirectPage,
  history,
}) => {
  const [selectedOrder, setSelectedOrder] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const refTable = useRef();
  const handleActionClick = (event, order) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setSelectedOrder(order);
  };
  const handleNavigateToOrderDetails = (orderId) => {
    updateRedirectPage('order-management');
    updateOrderId(orderId);
    updateCurrentPage('detail');
    history.push('/order-history');
  };
  return (
    <>
      <HeaderWrapper>
        <CellWrapper isBold>Status</CellWrapper>
        {/* <CellWrapper isBold>Elapsed Time</CellWrapper> */}
        <CellWrapper isBold>Order Type</CellWrapper>
        <CellWrapper isBold>Order ID</CellWrapper>
        <CellWrapper isBold>Location</CellWrapper>
        <CellWrapper isBold>Customer</CellWrapper>
        <CellWrapper isBold>Total</CellWrapper>
        <CellWrapper isBold></CellWrapper>
      </HeaderWrapper>
      <ActionPopper
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        selectedOrder={selectedOrder}
        setAnchorEl={setAnchorEl}
      />
      <div ref={refTable}>
        {/* <InfiniteScroll
          dataLength={orderList.length}
          next={loadMoreData}
          hasMore={true}
          loader={<h4>Loading...</h4>}
        > */}

        {orderList.map((order, index) => (
          <RowWrapper key={index}>
            <CellWrapper
              onClick={() => handleNavigateToOrderDetails(order.order_id)}
            >
              <Badge status={order.is_future_order ? 6 : order.status}>
                {getStatus(order.is_future_order ? 6 : order.status)}
              </Badge>
            </CellWrapper>
            {/* {order.is_future_order || order.status !== 0 ? (
              <CellWrapper
                onClick={() => handleNavigateToOrderDetails(order.order_id)}
              >
                {'-'}
              </CellWrapper>
            ) : (
              <ElapsedWrapper
                isExpired={order.isExpired}
                onClick={() => handleNavigateToOrderDetails(order.order_id)}
              >
                {order.isExpired && (
                  <FontAwesomeIcon icon={faTriangleExclamation} size='xs' />
                )}{' '}
                {order.elapsed_time}
              </ElapsedWrapper>
            )} */}
            <CellWrapper
              onClick={() => handleNavigateToOrderDetails(order.order_id)}
            >
              {order.order_type?.charAt(0).toUpperCase() +
                order.order_type?.slice(1)}
            </CellWrapper>
            <CellWrapper
              onClick={() => handleNavigateToOrderDetails(order.order_id)}
            >
              {order.order_id}
            </CellWrapper>
            <CellWrapper
              onClick={() => handleNavigateToOrderDetails(order.order_id)}
            >
              {order.business_name}
            </CellWrapper>
            <CellWrapper
              onClick={() => handleNavigateToOrderDetails(order.order_id)}
            >
              {order.customer_name}
            </CellWrapper>
            <CellWrapper
              onClick={() => handleNavigateToOrderDetails(order.order_id)}
            >{`$ ${order.total?.toFixed(2)}`}</CellWrapper>
            <CellWrapper
              onClick={(e) => handleActionClick(e, order)}
              pointer
              padding='0 10px 0 0'
            >
              <i
                className='fal fa-pencil'
                aria-hidden='true'
                style={{ marginRight: '5px' }}
              ></i>
              {'Action'}
              <i
                className='fa fa-caret-down'
                aria-hidden='true'
                style={{ marginLeft: '5px' }}
              ></i>
            </CellWrapper>
          </RowWrapper>
        ))}
        {/* </InfiniteScroll> */}
      </div>
    </>
  );
};

export default ActiveOrdersList;
