import React from 'react';
import styled from 'styled-components';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import Switch from '@material-ui/core/Switch';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../../components/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import NoDataMessage from '../../../../../components/NoDataMessage/NoDataMessage';

const Wrapper = styled.div`
  width: 100%;
`;

const ComboImage = styled.img`
  width: 100px;
  max-height: 64px;
  min-height: 64px;
  object-fit: contain;
  background-color: transparent;
  display: block;
`;

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;

const StyledDiv = styled.div`
  position: relative;
  bottom: 80px;
  right: 33px;
  height: 0px;
  z-index: 1;

  @media (min-width: 750px) and (max-width: 800px){
    position:relative;
    bottom: 50px;
    padding-top:40px;
    right: 30px;
   }


  @media (min-width: 800px) and (max-width: 850px){
    position:relative;
    bottom: 10px;
    padding-top:40px;
    right: 8px;
    top:-19px;
    margin-bottom:25px;
   }
 
   
   @media (min-width: 850px) and (max-width: 900px){
    position:relative;
    bottom: 50px;
    padding-top:40px;
    right: 56px;
   }

   @media (min-width: 900px) and (max-width: 950px){
    position:relative;
    bottom: 50px;
    padding:10px;
    right: 112px;
   }

   @media (min-width: 950px) and (max-width: 1000px){
    position:relative;
    bottom: 50px;
    padding:10px;
    right: 160px;
   }

   @media (min-width: 1000px) and (max-width: 1100px){
    position:relative;
    bottom: 50px;
    padding:10px;
    right: 200px;
   }

   
   @media (min-width: 1100px) and (max-width: 1200px){
    position:relative;
    bottom: 30px;
    padding:10px;
    right: 215px;
   }

   @media (min-width: 1200px) and (max-width: 1230px){
    position:relative;
    bottom: 30px;
    padding:10px;
    right: 215px;
   }

`;

const PaginationWrappr = styled.div`
  padding: 0px 4px;
`;

const SortDiv = styled.div`
  margin-left: 85%;
  bottom: 25px;
  position: relative;
  @media (max-width: 1920px) {
    margin-left: 85%;
    font-size: 17px;
  }
  @media (max-width: 1313px) {
    position: relative;
    margin-left: 75%;
    bottom: 30px;
    font-size: 17px;
  }
  @media (min-width: 800px) and (max-width: 850px){
    width:300px;
    position:relative;
    top: 5px;
    padding:10px;
    left: -300px;

   }


  @media (min-width: 850px) and (max-width: 900px){
    position:relative;
    bottom: 50px;
    padding:0px;
    right: 0px;
   }

  @media (min-width: 900px) and (max-width: 950px){
    position:relative;
    bottom: 50px;
    padding:0px;
    right: 40px;
   }

   @media (min-width: 950px) and (max-width: 1000px){
    position:relative;
    bottom: 50px;
    padding:0px;
    right: 60px;
   }
  @media (min-width: 1000px) and (max-width: 1050px){
    position:relative;
    bottom: 50px;
    padding:0px;
    right: 110px;
   }

  @media (min-width: 1050px) and (max-width: 1100px){
    position:relative;
    bottom: 30px;
    padding:0px;
    right: 150px;
   }


  @media (min-width: 1100px) and (max-width: 1200px){
    position:relative;
    bottom: 50px;
    padding:0px;
    right: 180px;
   }

   @media (min-width: 1300px) and (max-width: 1620px){
    position:relative;
    bottom: -10px;
    padding:0px;
    right: 180px;
   }

`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;

const ComboList = ({
  showArchivedData,
  showOnlyWhenNotSearched,
  combos,
  initiateEditCombo,
  toggleCombo,
  requestParams,
  businesses,
  setIsSortModalOpen,
  getCombos,
  showActiveData,
  searchString
}) => {
  const renderLocatiosNumberString = (combo) => {
    if (!combo.businesses || combo.businesses?.length === 0) return 'No Locations';
    if (combo.businesses?.length === 1) return '1 Location';
    if (combo.businesses?.length == businesses?.length) return 'All Locations';
    else {
      return combo.businesses.length + ' ' + 'Locations';
    }
  };


  return (
    <Wrapper>
      {combos?.length >= 0 && (
        <SortDiv>
          <SortWrapper onClick={() => setIsSortModalOpen(true)}>
            <i className='far fa-sort-amount-down' />{' '}
            <span style={{ color: '#2440AE' }}>Reorder Combos</span>
            <Tooltip
              style={{ marginRight: 'auto' }}
              title='Edit the position of combos to change the order that they are presented in to the user'
            >
              <span>
                <TooltipIcon marginLeft />
              </span>
            </Tooltip>
          </SortWrapper>
        </SortDiv>
      )}
      {showOnlyWhenNotSearched && (
        <StyledDiv>
          <PaginationWrappr>
            <PaginationControl
              response={{
                ...combos,
                page: requestParams.page_number,
                page_size: requestParams.page_size,
                count: combos.length,
              }}
              requestParams={requestParams}
              updateAction={getCombos}
              type='combos'
            />
          </PaginationWrappr>
        </StyledDiv>
      )}
      <Table noBorder>
        <TBody>
          {
            combos && combos?.length > 0 && (
              <TrHead>
              <Th align='left' style={{ paddingLeft: '37px' }}></Th>
              <Th
                align='left'
                value='name'
                sortingOption={requestParams.sorting_option}
              >
                Name
              </Th>
              <Th
                align='left'
                value='locationsNumber'
                sortingOption={requestParams.sorting_option}
              >
                Locations
              </Th>
              <Th
                align='right'
                style={{ paddingRight: '45px' }}
                value='is_active'
                sortingOption={requestParams.sorting_option}
              >
                 {!combos[0]?.is_archived ?"Active":""}
              </Th>
            </TrHead>
            )
          }
         

          {combos?.filter((combo)=>combo.is_active === showActiveData)?.map((combo) => {
            return (
              <Tr
                key={combo.id}
                onClick={() => initiateEditCombo(combo)}
                style={{ cursor: 'pointer', height: '78px' }}
              >
                <Td padding={'0px 0px 0px 37px'} width='70px'>
                  {combo?.image_url ? (
                    <ComboImage src={combo?.image_url} alt={combo.name} />
                  ) : (
                    <div />
                  )}
                </Td>
                <Td>{combo.name}</Td>

                <Td>{renderLocatiosNumberString(combo)}</Td>
                <Td padding={'0px 37px 0px 0px'} style={{ textAlign: 'right' }}>
                  {
                    !combo?.is_archived &&
                    <Switch
                    checked={combo.is_active}
                    onChange={() => toggleCombo(combo, !combo.is_active)}
                    onClick={(e) => e.stopPropagation()}
                    value='checkedB'
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />

                  }
                  
                </Td>
              </Tr>
            );
          })}
        </TBody>
    </Table>
      <NoDataMessage
        showArchivedData={showArchivedData}
        searchString={searchString}
        len={combos?.length || 0}
        lenObj={{act:combos.filter((combo)=>combo.is_active==true).length,inact:combos.filter((combo)=>combo.is_active==false).length}}
        type='Combos'
        isActive={showActiveData}
      />
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...combos,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: combos.length,
          }}
          requestParams={requestParams}
          updateAction={getCombos}
          type='combos'
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default ComboList;
