import React from 'react';
import { StyledText } from '../../../../../../components/StyledText/StyledText';

const OptionsSummary = ({ productOptionsInitialValues, allOptions }) => {
  return productOptionsInitialValues.map((productOption, index) => {
    let foundOption = allOptions.find(
      (globalOption) => globalOption.id === productOption.product_option_id
    );
    return foundOption ? (
      <>
        {`${index !== 0 ? ',' : ''} ${foundOption.name} - `}
        <StyledText fontWeight="300" fontStyle="italic">
          {foundOption.id}
        </StyledText>
      </>
    ) : (
      'Option Not Found'
    );
  });
};

export default OptionsSummary;
