import React, { useState, useEffect, useCallback } from 'react';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { IconButton, InputAdornment } from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import {
  Wrapper,
  Title,
  DaysWrapper,
  DayButton,
  TimeWrapper,
  ClosedWrapper,
  CloseIcon,
  RemoveTimeIcon,
  ButtonRowWrapper,
  ButtonWrapper,
  AddHoursButton,
  CopyHourButton,
  Divider,
  CopyButton,
  CopyButtonWrapper,
  TimePickerWrapper,
} from './styles/specialDayTimeSelectionModalStyles';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getDayOfWeek } from '../../../../utils/actionsFormatters';
import CopyHoursModal from '../CopyHoursModal';
const initialError = {
  openTime1: '',
  closeTime1: '',
  openTime2: '',
  closeTime2: '',
};
const SpecialDayTimeSelectionModal = ({
  businessId,
  isOpen,
  setIsOpen,
  selectedDay,
  selectedHoursType,
  specialHoursForm,
  selectedSpecialHours,
  setSoecialHoursForm,
  postSpecialHour,
  patchSpecialHour,
  deleteSelectedSpecialHours,
  setSelectedIndex,
}) => {
  const defaultOpenTime = moment('09:00', 'HH:mm');
  const defaultCloseTime = moment('17:00', 'HH:mm');
  const [isClosed, setIsClosed] = useState(false);
  const [openTime1, setOpenTime1] = useState();
  const [closeTime1, setCloseTime1] = useState();
  const [openTime2, setOpenTime2] = useState();
  const [closeTime2, setCloseTime2] = useState();
  const [selectedDays, setSelectedDays] = useState([]);
  const [openOrderingDays, setOpenOrderingDays] = useState([]);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [error, setError] = useState(initialError);
  const [modifiedSpecialHoursForm, setModifiedSpecialHoursForm] =
    useState(selectedSpecialHours);
  const [copyCondidateHours, setCopyCondidateHours] = useState();
  const [displayHours1, setDisplayHours1] = useState(false);
  const [displayHours2, setDisplayHours2] = useState(false);

  // hours initial update
  useEffect(() => {
    setModifiedSpecialHoursForm(selectedSpecialHours);
  }, [selectedSpecialHours]);

  // initilized hours files
  const updateHours = (day) => {
    let filteredSelectedHours = selectedSpecialHours?.filter(
      (item) => item.open_day_of_week === day
    );
    if (selectedHoursType === 'location') {
      const isLocationClosed = filteredSelectedHours[0]?.is_closed;
      if (isLocationClosed) {
        setIsClosed(true);
      } else if (filteredSelectedHours.length === 1) {
        let openTime1 = filteredSelectedHours[0]?.open_time;
        let closeTime1 = filteredSelectedHours[0]?.close_time;
        openTime1 = moment(openTime1, 'HH:mm:ss');
        closeTime1 = moment(closeTime1, 'HH:mm:ss');
        setOpenTime1(openTime1);
        setCloseTime1(closeTime1);
        setIsClosed(false);
        setDisplayHours1(true);
      } else if (filteredSelectedHours?.length === 2) {
        let openTime1 = filteredSelectedHours[0].open_time;
        let closeTime1 = filteredSelectedHours[0].close_time;
        let openTime2 = filteredSelectedHours[1].open_time;
        let closeTime2 = filteredSelectedHours[1].close_time;
        openTime1 = moment(openTime1, 'HH:mm:ss');
        closeTime1 = moment(closeTime1, 'HH:mm:ss');
        openTime2 = moment(openTime2, 'HH:mm:ss');
        closeTime2 = moment(closeTime2, 'HH:mm:ss');
        setOpenTime1(openTime1);
        setCloseTime1(closeTime1);
        setOpenTime2(openTime2);
        setCloseTime2(closeTime2);
        setIsClosed(false);
        setDisplayHours1(true);
        setDisplayHours2(true);
      }
    } else {
      const isOrderingClosed1 = !filteredSelectedHours[0]?.order_start_time;
      const isOrderingClosed2 = !filteredSelectedHours[1]?.order_start_time;
      if (isOrderingClosed1) {
        setIsClosed(true);
      } else if (
        filteredSelectedHours.length === 1 ||
        (filteredSelectedHours?.length === 2 &&
          !filteredSelectedHours[1]?.order_start_time)
      ) {
        let openTime1 = filteredSelectedHours[0]?.order_start_time;
        let closeTime1 = filteredSelectedHours[0]?.order_end_time;
        openTime1 = moment(openTime1, 'HH:mm:ss');
        closeTime1 = moment(closeTime1, 'HH:mm:ss');
        setOpenTime1(openTime1);
        setCloseTime1(closeTime1);
        setIsClosed(false);
        setDisplayHours1(true);
      } else if (
        filteredSelectedHours.length === 2 &&
        filteredSelectedHours[1]?.order_start_time
      ) {
        // time 1
        let openTime1 = filteredSelectedHours[0]?.order_start_time;
        let closeTime1 = filteredSelectedHours[0]?.order_end_time;
        openTime1 = moment(openTime1, 'HH:mm:ss');
        closeTime1 = moment(closeTime1, 'HH:mm:ss');
        setOpenTime1(openTime1);
        setCloseTime1(closeTime1);
        setDisplayHours1(true);
        if (!isOrderingClosed2) {
          // time 2
          let openTime2 = filteredSelectedHours[1]?.order_start_time;
          let closeTime2 = filteredSelectedHours[1]?.order_end_time;
          openTime2 = moment(openTime2, 'HH:mm:ss');
          closeTime2 = moment(closeTime2, 'HH:mm:ss');
          setOpenTime2(openTime2);
          setCloseTime2(closeTime2);
          setDisplayHours2(true);
        }
        setIsClosed(false);
      }
    }
  };
  // create available ordering days' list
  useEffect(() => {
    if (selectedHoursType === 'ordering') {
      let modifiedOpenOrderingDays = [];
      selectedSpecialHours.forEach((item) => {
        if (!item.is_closed)
          modifiedOpenOrderingDays.push(item.open_day_of_week);
      });
      setOpenOrderingDays([...new Set(modifiedOpenOrderingDays)]);
    } else {
      let modifiedOpenOrderingDays = [];
      selectedSpecialHours.forEach((item) => {
        modifiedOpenOrderingDays.push(item.open_day_of_week);
      });
      setOpenOrderingDays([...new Set(modifiedOpenOrderingDays)]);
    }
    setSelectedDays([selectedDay]);
    updateHours(selectedDay);
  }, [selectedDay, isOpen]);

  // handle entring hours' errors
  useEffect(() => {
    let newError = { ...error };
    const formatedOpenTime1 = openTime1?.format('HH:mm:ss');
    const formatedCloseTime1 = closeTime1?.format('HH:mm:ss');
    const formatedOpenTime2 = openTime2?.format('HH:mm:ss');
    const formatedCloseTime2 = closeTime2?.format('HH:mm:ss');
    const isOpen2BeforeClose1 = moment(openTime2).isSameOrBefore(closeTime1);
    if (!isClosed && !formatedOpenTime1) {
      newError.openTime1 = 'Required';
      newError.closeTime1 = '';
    } else if (formatedOpenTime1 === 'Invalid date') {
      newError.openTime1 = 'Invalid Time';
      newError.closeTime1 = '';
    } else if (!isClosed && !formatedCloseTime1) {
      newError.openTime1 = '';
      newError.closeTime1 = 'Required';
    } else if (formatedCloseTime1 === 'Invalid date') {
      newError.closeTime1 = 'Invalid Time';
    } else if (displayHours2 && !formatedOpenTime2) {
      newError.openTime2 = 'Required';
      newError.closeTime2 = '';
    } else if (formatedOpenTime2 === 'Invalid date') {
      newError.openTime2 = 'Invalid Time';
      newError.closeTime2 = '';
    } else if (formatedOpenTime2 && isOpen2BeforeClose1) {
      newError.openTime2 = `The time should be after ${formatedCloseTime1}`;
    } else if (displayHours2 && !formatedCloseTime2) {
      newError.openTime2 = '';
      newError.closeTime2 = 'Required';
    } else if (formatedCloseTime2 === 'Invalid date') {
      newError.closeTime2 = 'Invalid Time';
    } else if (formatedOpenTime1 && !formatedCloseTime1) {
      newError.openTime1 = '';
      newError.closeTime1 = 'Required';
    } else if (formatedOpenTime2 && !formatedCloseTime2) {
      newError.openTime2 = '';
      newError.closeTime2 = 'Required';
    } else {
      newError.openTime1 = '';
      newError.closeTime1 = '';
      newError.openTime2 = '';
      newError.closeTime2 = '';
    }
    setError(newError);
  }, [isClosed, displayHours2, openTime1, openTime2, closeTime1, closeTime2]);
  const handleClose = () => {
    setIsOpen(false);
    setOpenTime1();
    setCloseTime1();
    setOpenTime2();
    setCloseTime2();
    setSelectedDays([]);
    setDisplayHours1(false);
    setDisplayHours2(false);
  };
  const handleSelectDay = (day) => {
    let modifiedSelectedDays = [...selectedDays];
    if (modifiedSelectedDays.includes(day)) {
      modifiedSelectedDays = selectedDays.filter((item) => item !== day);
    } else {
      modifiedSelectedDays.push(day);
    }
    setSelectedDays(modifiedSelectedDays);
  };
  const handleCloseLocation = (e) => {
    const isClosed = e.target.checked;
    if (!isClosed) {
      setOpenTime1(null);
      setCloseTime1(null);
      setDisplayHours1(true);
    }
    setIsClosed(isClosed);
  };
  const handleRemoveTime = (id) => {
    if (id === 1) {
      setOpenTime1(null);
      setCloseTime1(null);
    } else {
      setOpenTime2(null);
      setCloseTime2(null);
      setDisplayHours2(false);
    }
  };
  useEffect(() => {
    let copyCondidateHours = [];
    if (openTime1 && closeTime1) {
      setOpenTime2(defaultOpenTime);
      setCloseTime2(defaultCloseTime);
    } else {
      const newSpecialHours = [...modifiedSpecialHoursForm];
      for (let i = 1; i <= 7; i++) {
        const filteredDay = newSpecialHours.filter(
          (day) => day.open_day_of_week === i
        );
        let candidateItem;
        if (selectedHoursType === 'location') {
          if (filteredDay?.length === 1) {
            const newValue = `${filteredDay[0].open_time} - ${filteredDay[0].close_time}`;
            candidateItem = {
              dayId: i,
              dayName: getDayOfWeek(i),
              value: newValue,
            };
            copyCondidateHours.push(candidateItem);
          } else if (filteredDay?.length === 2) {
            const newValue1 = `${filteredDay[0].open_time} - ${filteredDay[0].close_time}`;
            const newValue2 = `${filteredDay[1].open_time} - ${filteredDay[1].close_time}`;
            candidateItem = {
              dayId: i,
              dayName: getDayOfWeek(i),
              value: `${newValue1} / ${newValue2}`,
            };
            copyCondidateHours.push(candidateItem);
          }
        } else {
          if (filteredDay?.length === 1) {
            const newValue = filteredDay[0].order_start_time
              ? `${filteredDay[0].order_start_time} - ${filteredDay[0].order_end_time}`
              : '';
            candidateItem = {
              dayId: i,
              dayName: getDayOfWeek(i),
              value: newValue,
            };
            if (newValue) {
              copyCondidateHours.push(candidateItem);
            }
          } else if (filteredDay?.length === 2) {
            const newValue1 = filteredDay[0].order_start_time
              ? `${filteredDay[0].order_start_time} - ${filteredDay[0].order_end_time}`
              : '';
            const newValue2 = filteredDay[1].order_start_time
              ? `${filteredDay[1].order_start_time} - ${filteredDay[1].order_end_time}`
              : '';
            if (newValue1 && !newValue2) {
              candidateItem = {
                dayId: i,
                dayName: getDayOfWeek(i),
                value: `${newValue1}`,
              };
              copyCondidateHours.push(candidateItem);
            }
            if (newValue1 && newValue2) {
              candidateItem = {
                dayId: i,
                dayName: getDayOfWeek(i),
                value: `${newValue1} / ${newValue2}`,
              };
              copyCondidateHours.push(candidateItem);
            }
          }
        }
      }
    }
    setCopyCondidateHours(copyCondidateHours);
  }, [modifiedSpecialHoursForm]);
  const handleCopyHours = (copiedDay) => {
    let openTime1;
    let closeTime1;
    let openTime2;
    let closeTime2;
    let filteredSelectedHours = selectedSpecialHours?.filter(
      (item) => item.open_day_of_week === copiedDay
    );

    if (selectedHoursType === 'location') {
      if (filteredSelectedHours?.length === 1) {
        openTime1 = filteredSelectedHours[0]?.open_time;
        closeTime1 = filteredSelectedHours[0]?.close_time;
        openTime1 = moment(openTime1, 'HH:mm:ss');
        closeTime1 = moment(closeTime1, 'HH:mm:ss');
        setOpenTime1(openTime1);
        setCloseTime1(closeTime1);
        setDisplayHours1(true);
      } else if (filteredSelectedHours?.length === 2) {
        openTime1 = filteredSelectedHours[0]?.open_time;
        closeTime1 = filteredSelectedHours[0]?.close_time;
        openTime2 = filteredSelectedHours[1]?.open_time;
        closeTime2 = filteredSelectedHours[1]?.close_time;
        openTime1 = moment(openTime1, 'HH:mm:ss');
        closeTime1 = moment(closeTime1, 'HH:mm:ss');
        openTime2 = moment(openTime2, 'HH:mm:ss');
        closeTime2 = moment(closeTime2, 'HH:mm:ss');
        setOpenTime1(openTime1);
        setCloseTime1(closeTime1);
        setOpenTime2(openTime2);
        setCloseTime2(closeTime2);
        setDisplayHours1(true);
        setDisplayHours2(true);
      }
    } else {
      if (
        filteredSelectedHours?.length === 1 ||
        (filteredSelectedHours?.length === 2 &&
          !filteredSelectedHours[1]?.order_start_time)
      ) {
        openTime1 = filteredSelectedHours[0]?.order_start_time;
        closeTime1 = filteredSelectedHours[0]?.order_end_time;
        openTime1 = moment(openTime1, 'HH:mm:ss');
        closeTime1 = moment(closeTime1, 'HH:mm:ss');
        setOpenTime1(openTime1);
        setCloseTime1(closeTime1);
        setDisplayHours1(true);
      } else if (
        filteredSelectedHours?.length === 2 &&
        filteredSelectedHours[1]?.order_start_time
      ) {
        openTime1 = filteredSelectedHours[0]?.order_start_time;
        closeTime1 = filteredSelectedHours[0]?.order_end_time;
        openTime2 = filteredSelectedHours[1]?.order_start_time;
        closeTime2 = filteredSelectedHours[1]?.order_end_time;
        openTime1 = moment(openTime1, 'HH:mm:ss');
        closeTime1 = moment(closeTime1, 'HH:mm:ss');
        openTime2 = moment(openTime2, 'HH:mm:ss');
        closeTime2 = moment(closeTime2, 'HH:mm:ss');
        setOpenTime1(openTime1);
        setCloseTime1(closeTime1);
        setOpenTime2(openTime2);
        setCloseTime2(closeTime2);
        setDisplayHours1(true);
        setDisplayHours2(true);
      }
    }
  };
  const handleSave = () => {
    let postList = [];
    let patchList = [];
    let deleteList = [];
    selectedDays.forEach((day) => {
      let filteredSpecialHours = modifiedSpecialHoursForm.filter(
        (item) => item.open_day_of_week === day
      );
      const specialOpenTime1 = openTime1
        ? moment(openTime1, 'HH:mm').set('second', 0).format('HH:mm:ss')
        : '';
      const specialCloseTime1 = closeTime1
        ? moment(closeTime1, 'HH:mm').set('second', 0).format('HH:mm:ss')
        : '';
      const specialOpenTime2 = openTime2
        ? moment(openTime2, 'HH:mm').set('second', 0).format('HH:mm:ss')
        : '';
      const specialCloseTime2 = closeTime2
        ? moment(closeTime2, 'HH:mm').set('second', 0).format('HH:mm:ss')
        : '';
      const openDayofWeek1 = filteredSpecialHours[0]?.open_day_of_week;
      const openDayofWeek2 = filteredSpecialHours[1]?.open_day_of_week;
      // conditions
      const isNotSplited = openTime1 && !openTime2;
      const isNextDay =
        moment(openTime1).isAfter(closeTime1) ||
        moment(openTime2).isAfter(closeTime2);
      if (selectedHoursType === 'location') {
        if (filteredSpecialHours.length === 1) {
          let splitHours1;
          let splitHours2;
          if (isClosed) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              is_closed: isClosed,
              is_order_ahead_closed: true,
              order_start_time: '',
              order_end_time: '',
            };
            patchList.push(splitHours1);
          } else if (specialOpenTime1 && !specialOpenTime2) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              is_closed: false,
              is_order_ahead_closed: false,
              close_day_of_week: isNextDay
                ? openDayofWeek1 === 7
                  ? 1
                  : openDayofWeek1 + 1
                : openDayofWeek1,
            };
            patchList.push(splitHours1);
          } else if (specialOpenTime1 && specialOpenTime2) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              is_closed: false,
              is_order_ahead_closed: false,
              close_day_of_week: isNextDay
                ? openDayofWeek1 === 7
                  ? 1
                  : openDayofWeek1 + 1
                : openDayofWeek1,
            };
            splitHours2 = {
              ...filteredSpecialHours[0],
              open_time: specialOpenTime2,
              close_time: specialCloseTime2,
              is_closed: false,
              is_order_ahead_closed: false,
              close_day_of_week: isNextDay
                ? openDayofWeek1 === 7
                  ? 1
                  : openDayofWeek1 + 1
                : openDayofWeek1,
              order_start_time: '',
              order_end_time: '',
            };
            patchList.push(splitHours1);
            postList.push(splitHours2);
          }
        } else {
          let splitHours1;
          let splitHours2;
          if (isClosed) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              is_closed: isClosed,
              is_order_ahead_closed: true,
              order_start_time: '',
              order_end_time: '',
            };
            splitHours2 = {
              ...filteredSpecialHours[1],
              is_closed: isClosed,
              is_order_ahead_closed: true,
              order_start_time: '',
              order_end_time: '',
            };
            patchList.push(splitHours1, splitHours2);
          } else if (specialOpenTime1 && !specialOpenTime2) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              is_closed: false,
              is_order_ahead_closed: false,
              close_day_of_week: isNextDay
                ? openDayofWeek1 === 7
                  ? 1
                  : openDayofWeek1 + 1
                : openDayofWeek1,
            };
            patchList.push(splitHours1);
            deleteList.push(filteredSpecialHours[1]);
          } else if (specialOpenTime1 && specialOpenTime2) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              open_time: specialOpenTime1,
              close_time: specialCloseTime1,
              is_closed: false,
              is_order_ahead_closed: false,
              close_day_of_week: isNextDay
                ? openDayofWeek1 === 7
                  ? 1
                  : openDayofWeek1 + 1
                : openDayofWeek1,
            };
            splitHours2 = {
              ...filteredSpecialHours[1],
              open_time: specialOpenTime2,
              close_time: specialCloseTime2,
              is_closed: false,
              is_order_ahead_closed: false,
              close_day_of_week: isNextDay
                ? openDayofWeek1 === 7
                  ? 1
                  : openDayofWeek1 + 1
                : openDayofWeek1,
            };
            patchList.push(splitHours1, splitHours2);
          }
        }
      } else {
        if (filteredSpecialHours.length === 1) {
          let splitHours1;
          let splitHours2;
          if (isClosed) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              order_start_time: '',
              order_end_time: '',
            };
            patchList.push(splitHours1);
          } else if (specialOpenTime1 && !specialOpenTime2) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              order_start_time: specialOpenTime1,
              order_end_time: specialCloseTime1,
            };
            patchList.push(splitHours1);
          } else if (specialOpenTime1 && specialOpenTime2) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              order_start_time: specialOpenTime1,
              order_end_time: specialCloseTime1,
            };
            patchList.push(splitHours1);
          }
        } else {
          let splitHours1;
          let splitHours2;
          if (isClosed) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              order_start_time: '',
              order_end_time: '',
            };
            splitHours2 = {
              ...filteredSpecialHours[1],
              order_start_time: '',
              order_end_time: '',
            };
            patchList.push(splitHours1, splitHours2);
          } else if (specialOpenTime1 && !specialOpenTime2) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              order_start_time: specialOpenTime1,
              order_end_time: specialCloseTime1,
            };
            splitHours2 = {
              ...filteredSpecialHours[1],
              order_start_time: '',
              order_end_time: '',
            };
            patchList.push(splitHours1, splitHours2);
          } else if (specialOpenTime1 && specialOpenTime2) {
            splitHours1 = {
              ...filteredSpecialHours[0],
              order_start_time: specialOpenTime1,
              order_end_time: specialCloseTime1,
            };
            splitHours2 = {
              ...filteredSpecialHours[1],
              order_start_time: specialOpenTime2,
              order_end_time: specialCloseTime2,
            };
            patchList.push(splitHours1, splitHours2);
          }
        }
      }
    });
    let modifiedPostList = [];
    let modifiedPatchList = [];
    let modifiedDeleteList = deleteList.map((item) => item.id);
    postList.forEach((item) => {
      delete item.business_id;
      delete item.id;
      modifiedPostList.push(item);
    });
    patchList.forEach((item) => {
      const newitem = {
        id: item.id,
        hours: {
          close_day_of_week: item.close_day_of_week,
          close_time: item.close_time,
          end_date: item.end_date,
          is_closed: item.is_closed,
          is_order_ahead_closed: item.is_order_ahead_closed,
          open_day_of_week: item.open_day_of_week,
          open_time: item.open_time,
          order_end_time: item.order_end_time,
          order_start_time: item.order_start_time,
          start_date: item.start_date,
        },
      };
      modifiedPatchList.push(newitem);
    });

    if (modifiedPostList.length > 0) {
      postSpecialHour(businessId, modifiedPostList);
    }
    if (modifiedPatchList.length > 0) {
      patchSpecialHour(businessId, modifiedPatchList);
    }
    if (modifiedDeleteList.length > 0) {
      deleteSelectedSpecialHours(businessId, modifiedDeleteList);
    }
    handleClose();
    setSelectedIndex(null);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
      maxWidth='sm'
    >
      <Wrapper>
        <Title>{`Select ${selectedHoursType} Hours`}</Title>
        <CloseIcon className='fa fa-times' onClick={handleClose} />
        <DaysWrapper>
          <DayButton
            isSelected={selectedDays.includes(1)}
            onClick={() => handleSelectDay(1)}
            disabled={!openOrderingDays.includes(1)}
          >
            Mon
          </DayButton>
          <DayButton
            isSelected={selectedDays.includes(2)}
            onClick={() => handleSelectDay(2)}
            disabled={!openOrderingDays.includes(2)}
          >
            Tue
          </DayButton>
          <DayButton
            isSelected={selectedDays.includes(3)}
            onClick={() => handleSelectDay(3)}
            disabled={!openOrderingDays.includes(3)}
          >
            Wed
          </DayButton>
          <DayButton
            isSelected={selectedDays.includes(4)}
            onClick={() => handleSelectDay(4)}
            disabled={!openOrderingDays.includes(4)}
          >
            Thu
          </DayButton>
          <DayButton
            isSelected={selectedDays.includes(5)}
            onClick={() => handleSelectDay(5)}
            disabled={!openOrderingDays.includes(5)}
          >
            Fri
          </DayButton>
          <DayButton
            isSelected={selectedDays.includes(6)}
            onClick={() => handleSelectDay(6)}
            disabled={!openOrderingDays.includes(6)}
          >
            Sat
          </DayButton>
          <DayButton
            isSelected={selectedDays.includes(7)}
            onClick={() => handleSelectDay(7)}
            disabled={!openOrderingDays.includes(7)}
          >
            Sun
          </DayButton>
        </DaysWrapper>
        <ClosedWrapper>
          <FormControlLabel
            control={
              <Checkbox
                checked={isClosed}
                onChange={handleCloseLocation}
                name='checkedB'
                color='primary'
              />
            }
            label='Closed'
          />
          <ButtonWrapper align='right'>
            {copyCondidateHours?.length > 0 && !isClosed && (
              <CopyHourButton onClick={() => setIsCopyModalOpen(true)}>
                Copy Hours
              </CopyHourButton>
            )}
          </ButtonWrapper>
        </ClosedWrapper>
        <TimePickerWrapper>
          {!isClosed && displayHours1 && (
            <>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <TimeWrapper>
                  <KeyboardTimePicker
                    label={
                      selectedHoursType === 'ordering'
                        ? 'Start Time'
                        : 'Opening Time'
                    }
                    placeholder='08:00'
                    inputVariant='outlined'
                    // ampm={false}
                    mask='__:__'
                    openTo='hours'
                    format='HH:mm'
                    value={openTime1}
                    onChange={(date) => setOpenTime1(date)}
                    keyboardIcon={
                      <InputAdornment>
                        <i className='fa fa-clock'></i>
                      </InputAdornment>
                    }
                    error={error.openTime1}
                    helperText={error.openTime1}
                    // minutesStep={15}
                  />
                  <KeyboardTimePicker
                    label={
                      selectedHoursType === 'ordering'
                        ? 'End Time'
                        : 'Close Time'
                    }
                    inputVariant='outlined'
                    placeholder='17:00'
                    // ampm={false}
                    mask='__:__'
                    openTo='hours'
                    format='HH:mm'
                    value={closeTime1}
                    onChange={(date) => setCloseTime1(date)}
                    keyboardIcon={
                      <InputAdornment>
                        <i className='fa fa-clock'></i>
                      </InputAdornment>
                    }
                    error={error.closeTime1}
                    helperText={error.closeTime1}
                  />
                </TimeWrapper>
              </MuiPickersUtilsProvider>
            </>
          )}
          {!isClosed && displayHours2 && (
            <>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <TimeWrapper>
                  <KeyboardTimePicker
                    label={
                      selectedHoursType === 'ordering'
                        ? 'Start Time'
                        : 'Opening Time'
                    }
                    placeholder='08:00'
                    inputVariant='outlined'
                    // ampm={false}
                    mask='__:__'
                    openTo='hours'
                    format='HH:mm'
                    value={openTime2}
                    onChange={(date) => setOpenTime2(date)}
                    keyboardIcon={
                      <InputAdornment>
                        <i className='fa fa-clock'></i>
                      </InputAdornment>
                    }
                    error={error.openTime2}
                    helperText={error.openTime2}
                  />
                  <KeyboardTimePicker
                    label={
                      selectedHoursType === 'ordering'
                        ? 'End Time'
                        : 'Close Time'
                    }
                    inputVariant='outlined'
                    placeholder='17:00'
                    // ampm={false}
                    mask='__:__'
                    openTo='hours'
                    format='HH:mm'
                    value={closeTime2}
                    onChange={(date) => setCloseTime2(date)}
                    keyboardIcon={
                      <InputAdornment>
                        <i className='fa fa-clock'></i>
                      </InputAdornment>
                    }
                    error={error.closeTime2}
                    helperText={error.closeTime2}
                  />
                  <RemoveTimeIcon
                    className='fal fa-times'
                    onClick={() => handleRemoveTime(2)}
                  />
                </TimeWrapper>
              </MuiPickersUtilsProvider>
            </>
          )}
        </TimePickerWrapper>
        {!isClosed && !displayHours2 && (
          <ButtonRowWrapper>
            <ButtonWrapper>
              <AddHoursButton
                align='left'
                onClick={() => {
                  setDisplayHours2(true);
                  setOpenTime2(null);
                  setCloseTime2(null);
                }}
              >
                Split Hours
              </AddHoursButton>
            </ButtonWrapper>
          </ButtonRowWrapper>
        )}
      </Wrapper>
      <Divider />
      <CopyButtonWrapper>
        <CopyButton
          onClick={handleSave}
          disabled={
            selectedDays?.length === 0 ||
            error.openTime1 ||
            error.closeTime1 ||
            error.openTime2 ||
            error.closeTime2
          }
        >
          Save
        </CopyButton>
      </CopyButtonWrapper>
      <CopyHoursModal
        selectedHoursType={selectedHoursType}
        isCopyModalOpen={isCopyModalOpen}
        setIsCopyModalOpen={setIsCopyModalOpen}
        copyCondidateHours={copyCondidateHours}
        handleCopyHours={handleCopyHours}
      />
    </Dialog>
  );
};

export default SpecialDayTimeSelectionModal;
