import { api } from '../../../api';
import {
  GET_VARIANTS_REQUEST,
  GET_VARIANTS_SUCCESS,
  GET_VARIANTS_FAILURE,
  CHANGE_COMPONENT_TYPE,
  GET_ACTIVE_REQUEST,
  GET_ACTIVE_SUCCESS,
  GET_ACTIVE_FAILURE,
  SET_EDIT_VARIANT,
  EDIT_VARIANT_REQUEST,
  EDIT_VARIANT_SUCCESS,
  EDIT_VARIANT_FAILURE,
  CREATE_VARIANT_REQUEST,
  CREATE_VARIANT_SUCCESS,
  CREATE_VARIANT_FAILURE,
  RESET_TO_INITIAL_VALUES,
  SET_LOCAL_VARIANT_ISACTIVE,
  UPDATE_VARIANTS_REQUEST_PARAMS,
  UPDATE_SORTED_VARIANTS_LIST,
} from './variantsTypes';
import { createAlert } from '../../Alert/actions';
import { formatErrorMessage } from '../../../utils/formatErrorMessage';
import { getSearchUrl } from '../../../utils/purePayload';

const formVariantIsUnique = (formVariant, allVariants) =>
  allVariants.find(
    (existingVariant) =>
      existingVariant.name === formVariant.name &&
      existingVariant.label === formVariant.label
  );

export function getVariants(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch) {
    dispatch({ type: GET_VARIANTS_REQUEST });
    const urlwithOutSearchString=`/menu/variants?page_number=${requestParams.page_number}&page_size=${requestParams.page_size}`
    const url = getSearchUrl(urlwithOutSearchString, requestParams.search_string, 3);
    api
      .get(
        url
        )
      .then((response) => {
        const hasNextPage = response.data.meta.has_next_page;
        dispatch({
          type: GET_VARIANTS_SUCCESS,
          allVariants: response.data.data,
          requestParams: response.data.meta,
          hasNextPage: hasNextPage,
        });
      }),
      (error) => {
        dispatch({ type: GET_VARIANTS_FAILURE, error });
      };
  };
}

export function editVariant(variant, shouldValidateNameAdLabel) {
  return function (dispatch, getState) {
    const { allVariants } = getState().variantsReducer;

    if (
      shouldValidateNameAdLabel &&
      formVariantIsUnique(variant, allVariants)
    ) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Variant with same name and label already exists',
        })
      );
    } else {
      dispatch({ type: EDIT_VARIANT_REQUEST });
      return (
        api
          .patch(`/menu/variants/${variant.id}`, {
            name: variant.name,
            label: variant.label,
            is_active: variant.is_active,
          })
          .then((response) => {
            dispatch(getVariants());
            dispatch({
              type: EDIT_VARIANT_SUCCESS,
            });
            dispatch(
              createAlert({
                type: 'success',
                message: 'Variant updated successfully',
              })
            );
          }),
        (error) => {
          dispatch({ type: EDIT_VARIANT_FAILURE });
          dispatch(
            createAlert({
              type: 'error',
              message: formatErrorMessage(error) || 'error occurred while saving variant',
              dangerouslySetInnerHTML: true,
            })
          );
        }
      );
    }
  };
}
export function createVariant(variant) {
  return function (dispatch, getState) {
    const { allVariants } = getState().variantsReducer;
    if (formVariantIsUnique(variant, allVariants)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Variant with same name and label already exists',
        })
      );
    } else {
      dispatch({
        type: CREATE_VARIANT_REQUEST,
      });

      return (
        api
          .post(`/menu/variants`, {
            name: variant.name,
            label: variant.label,
            is_active: variant.is_active,
          })
          .then((response) => {
            dispatch(getVariants());
            dispatch({
              type: CREATE_VARIANT_SUCCESS,
            });

            dispatch(
              createAlert({
                type: 'success',
                message: 'Variant created successfully',
              })
            );
          }),
        (error) => {
          dispatch({
            type: CREATE_VARIANT_FAILURE,
          });
          dispatch(
            createAlert({
              type: 'error',
              message: formatErrorMessage(error) || 'error occurred while creating variant',
              dangerouslySetInnerHTML: true,
            })
          );
        }
      );
    }
  };
}

export function changeComponentType(componentType) {
  return {
    type: CHANGE_COMPONENT_TYPE,
    componentType,
  };
}

export function updateVariantsRequestParams(requestParams) {
  return {
    type: UPDATE_VARIANTS_REQUEST_PARAMS,
    payload: requestParams,
  };
}

export function updateSortedVariantsList(variantsList, sortOrder) {
  return {
    type: 'UPDATE_SORTED_VARIANTS_LIST',
    variantsList: variantsList,
    sortOrder: sortOrder,
  };
}

export function setEditVariant(variant) {
  return {
    type: SET_EDIT_VARIANT,
    variant,
  };
}
export function resetToInitialValues() {
  return {
    type: RESET_TO_INITIAL_VALUES,
  };
}
export function toggleIsActive(variant) {
  return function (dispatch) {
    dispatch({ type: GET_ACTIVE_REQUEST });

    return (
      api
        .patch(`/menu/variants/${variant.id}`, {
          is_active: !variant.is_active,
        })
        .then((response) => {
          dispatch({
            type: SET_LOCAL_VARIANT_ISACTIVE,
            id: variant.id,
            is_active: !variant.is_active,
          });
          dispatch({
            type: GET_ACTIVE_SUCCESS,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Variant updated successfully',
            })
          );
        }),
      (error) => {
        dispatch({ type: GET_ACTIVE_FAILURE, error });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Error occurred while saving variant',
          })
        );
      }
    );
  };
}
