import React, { useEffect, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import * as actions from '../ReportTableActions.js';
import ReportSearch from '../components/ReportSearch.js';
const GenericTable = lazy(() =>
  import('../../../components/Table/GenericTable')
);
function LoyaltyReportContainer({
  allLoyalties,
  requestParams,
  getLoyaltyReport,
  setExportingData,
  setExportingHeaders,
  setExportingFileName,
  clearLoyaltyReport,
  updateReportsRequestParams,
  setMessage,
  resetAllReports,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectedBusinessesIDs,
  setSelectedBusinessesIDs,
}) {
  const columnHeaders = [
    'Location',
    'Revenue',
    'Loyalty Points Earned',
    'Loyalty Points Redeemed',
    'Loyalty Funds Redeemed',
    'Loyalty Funds Redeemed (%)',
    'Offer Funds Redeemed',
    'Offer Funds Redeemed (%)',
    'Credit Code Funds Redeemed',
    'Credit Code Funds Redeemed (%)',
    'Head Office Funds Redeemed',
    'Head Office Funds Redeemed (%)',
    'Total Loyalty Program Expense',
    'Total Loyalty Program Expense (%)',
  ];
  const columnNames = [
    'business_name',
    'revenue',
    'points_earned',
    'points_redeemed',
    'loyalty_funds_redeemed',
    'loyalty_funds_percent',
    'offer_funds_redeemed',
    'offer_funds_percent',
    'coupon_funds_redeemed',
    'coupon_funds_percent',
    'global_credit_funds_redeemed',
    'global_credit_funds_percent',
    'total_expense',
    'total_expense_percent',
  ];
  const columnWidth = [
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
    '200px',
  ];
  // Types money, number, percent ,
  const columnTypes = [
    'string',
    'money',
    'string',
    'string',
    'money',
    'percent',
    'money',
    'percent',
    'money',
    'percent',
    'money',
    'percent',
    'money',
    'percent',
  ];
  const columnHeaderIsSortableList = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];

  const getExportHeaders = (headers, fields) => {
    const exportedHeaders = [];
    headers.forEach((header, index) =>
      exportedHeaders.push({ label: header, key: fields[index] })
    );
    return exportedHeaders;
  };
  const searchField = '';
  useEffect(() => {
    setExportingFileName('loyalty_report');
    setExportingHeaders(getExportHeaders(columnHeaders, columnNames));
    setMessage('Select a date range to generate a report');
    return () => {
      clearLoyaltyReport();
      setExportingData([]);
      updateReportsRequestParams({
        page_number: 1,
        page_size: 25,
      });
      setMessage('Select a date range to generate a report');
      resetAllReports();
    };
  }, []);
  useEffect(() => {
    if (allLoyalties?.length === 0) {
      setExportingData([]);
      setMessage('No Data Found');
    }
  }, [allLoyalties]);
  return (
    <>
      <ReportSearch
        generateReport={getLoyaltyReport}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedBusinessesIDs={selectedBusinessesIDs}
        setSelectedBusinessesIDs={setSelectedBusinessesIDs}
      />
      {allLoyalties?.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <Suspense fallback={<div>Loading...</div>}>
            <GenericTable
              dataList={allLoyalties}
              columnHeaders={columnHeaders}
              columnHeaderIsSortableList={columnHeaderIsSortableList}
              columnNames={columnNames}
              columnWidth={columnWidth}
              columnTypes={columnTypes}
              searchField={searchField}
              placeholder=''
              requestParams={requestParams}
              requestParamsType={'loyaltyReport'}
              handleClickRow={null}
              handleCheckboxSelect={null}
              checkboxList={[]}
              setCheckboxList={null}
              setExportingData={() => setExportingData(allLoyalties)}
            />
          </Suspense>
        </div>
      )}
    </>
  );
}
function mapStateToProps(state) {
  return {
    allLoyalties: state.ReportTableReducer.allLoyalties,
    requestParams: state.ReportTableReducer.requestParams,
  };
}
export default connect(mapStateToProps, actions)(LoyaltyReportContainer);
