import React from "react";
import styled from "styled-components";
import { Modal } from "merchant-portal-components";
import { ButtonRow, Button, LoadingButton } from "merchant-portal-components";
import { ModalBody } from "../../../../../../components/FormElements/FormElements.js";

const Title = styled.p`
  width: 500px;
  height: 24px;
  flex-grow: 0;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
  padding-left: 36px;
  font-family: Montserrat;
  color: #191818;
`;

const BodyContent = styled.div`
  width: 502px;
  height: 22px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.25px;
  text-align: left;
  padding-left: 36px;
  font-family: Montserrat;
  color: #191818;
`;

const StyledModal = styled(Modal)`
  width: 550px;
  height: 187px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 24px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
`;

function LocationDeactivationModal({
  location,
  businessId,
  requestingDeactivation,
  cancel,
  updateBusinessInformation,
}) {
  return (
    <StyledModal>
      <ModalBody medium>
        <Title>Deactivate {location?.name}?</Title>
        <BodyContent>You can reactivate this location at any time.</BodyContent>
        <ButtonRow justifyContent="space-between" marginLeft={0}>
          <Button
            small
            type="button"
            secondary
            onClick={(e) => {
              cancel && cancel();
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            Cancel
          </Button>
          {requestingDeactivation ? (
            <LoadingButton whiteSpinner small type="button" danger>
              Yes, Deactivate
            </LoadingButton>
          ) : (
            <Button
              small
              type="button"
              onClick={(e) => {
                updateBusinessInformation(
                  {
                    ...location,
                    master_is_active: false,
                  },
                  businessId,
                  location
                );
                localStorage.setItem(
                  `location-${location.name}-deactivation-time`,
                  new Date().toString()
                );
                cancel && cancel();
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              Yes, Deactivate
            </Button>
          )}
        </ButtonRow>
      </ModalBody>
    </StyledModal>
  );
}

export default LocationDeactivationModal;
