import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import PermissionsIndex from './PermissionsIndex';
import RolesIndex from './RolesIndex';
import { getPagesWithPermissions } from '../configs/pagesConfig';
import { ROLE_LOYALTY_ADMIN } from '../../../utils/rolesRepository';
import ArchivedUsersSection from './ArchivedUsersSection';

const ComponentCardWrapper = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  background-color: white;
  padding: 0;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1.3fr 1fr 50px;
  gird-gap: 0;
  margin: 0;
  padding: 7px 30px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1.3fr 1fr 50px;
  gird-gap: 0;
  margin: 0;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
  border-top: 1px solid #e1e1e1;
`;

const ArchivedSectionHeader = styled.h3`
  padding: 20px 30px;
  font-weight: 600;
`;
const ArchivedTableRow = styled(TableRow)`
  background-color: #f5f5f5;
`;

const UnarchiveButton = styled.button`
  background-color: #e0e0e0;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #c8c8c8;
  }
`;


const Roles = (props) => {
  const {
    accounts,
    businesses,
    postResendInvitation,
    updateUser,
    patchUpdateRole,
    authUser,
    isModal,
    toggleCreateUserModal,
    appConfig,
    archiveAdmins,
    getArchivedAccounts,
    archivedAccounts,
    unarchiveAdmins
  } = props;

  const modifiedRoles = useMemo(() => {
    const notRoyaltyAdmin =
      !appConfig?.configuration.is_only_enabled_for_loyalty &&
      !appConfig?.configuration.is_generic_loyalty_enabled;
    const role = localStorage.getItem('role');
    if (notRoyaltyAdmin) {
      return getPagesWithPermissions('NO_LOYALTY');
    } else if (
      appConfig?.configuration.is_only_enabled_for_loyalty &&
      role === ROLE_LOYALTY_ADMIN
    ) {
      return getPagesWithPermissions('ONLY_LOYALTY');
    }
    return getPagesWithPermissions();
  }, [
    appConfig?.configuration.is_only_enabled_for_loyalty,
    appConfig?.configuration.is_generic_loyalty_enabled,
  ]);
  return (
    <Fragment>
      <ComponentCardWrapper>
        <PermissionsIndex accounts={accounts} roles={modifiedRoles} />
      </ComponentCardWrapper>
      <ComponentCardWrapper>
        <RolesIndex
          getArchivedAccounts={getArchivedAccounts}
          archivedAccounts={archivedAccounts}
          archiveAdmins={archiveAdmins}
          accounts={accounts}
          roles={modifiedRoles}
          businesses={businesses}
          postResendInvitation={postResendInvitation}
          updateUser={updateUser}
          patchUpdateRole={patchUpdateRole}
          authUser={authUser}
          isModal={isModal}
          toggleCreateUserModal={toggleCreateUserModal}
        />
      </ComponentCardWrapper>
      <ArchivedUsersSection
        archivedAccountsData={archivedAccounts}
        handleUnarchive={(account) => { 
          unarchiveAdmins(account);
        }} 
      />
    </Fragment>
  );
};
export default Roles;
