import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  loadListCategoryPage,
  sortCategories,
  sortProducts,
  handleCategorySave,
  setProductCategoryAssignmentModalIsVisible,
  handleProductCategorySave,
} from '../categoryActions';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import CategoryDetail from './components/CategoryDetail';
import ReactGA from 'react-ga';
const Wrapper = styled.div`
  width: 1000px;
  margin: 0 auto;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

const BackText = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;

  &:hover {
    color: #3051d4;
  }
`;

const CategoryDetailContainer = ({
  isEdit,
  loadListCategoryPage,
  editingCategory,
  handleCategorySave,
  createPatchCategoryIsRequesting,
  subCategoryHoursError,
  productsAssociatedToCategory,
  setProductCategoryAssignmentModalIsVisible,
  productCategoryAssignmentModalIsVisible,
  sortCategories,
  sortProducts,
  categoryId,
  // handleProductCategorySave
}) => {
  useEffect(() => {
    if (isEdit) {
      track.page('menu-management-category-edit');
    } else {
      track.page('menu-management-category-create');
    }
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname, undefined, 'Category Detail');
  }, []);
  return (
    <Wrapper>
      <BackText onClick={() => loadListCategoryPage()}>
        <i className="fa fa-chevron-left" />
        <div>Back To Categories List</div>
      </BackText>
      <BreadCrumb
        style={{ margin: ' 0px' }}
        pageName={isEdit ? 'Edit Category' : 'Create A Category'}
      />
      <CategoryDetail
        sortCategories={sortCategories}
        sortProducts={sortProducts}
        categoryId={categoryId}
        initialValues={
          isEdit
            ? editingCategory
            : {
                end_time: '23:59:59',
                image_url: '',
                is_active: true,
                name: '',
                parent_id: null,
                start_time: '00:00:00',
                subCategories: [],
              }
        }
        productsAssociatedToCategory={productsAssociatedToCategory}
        handleCategorySave={handleCategorySave}
        isRequesting={createPatchCategoryIsRequesting}
        subCategoryHoursError={subCategoryHoursError}
        productCategoryAssignmentModalIsVisible={
          productCategoryAssignmentModalIsVisible
        }
        setProductCategoryAssignmentModalIsVisible={
          setProductCategoryAssignmentModalIsVisible
        }
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  editingCategory: state.categoryReducer.editingCategory,
  createPatchCategoryIsRequesting:
    state.categoryReducer.createPatchCategoryIsRequesting,
  subCategoryHoursError: state.categoryReducer.subCategoryHoursError,
  productsAssociatedToCategory:
    state.categoryReducer.productsAssociatedToCategory,
  setProductCategoryAssignmentModalIsVisible:
    state.categoryReducer.setProductCategoryAssignmentModalIsVisible,
  productCategoryAssignmentModalIsVisible:
    state.categoryReducer.productCategoryAssignmentModalIsVisible,
});

export default connect(mapStateToProps, {
  loadListCategoryPage,
  handleCategorySave,
  sortCategories,
  sortProducts,
  setProductCategoryAssignmentModalIsVisible,
  handleProductCategorySave,
})(CategoryDetailContainer);
