import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "./actions";
import CustomerSearchSelect from "./components/CustomerSearchSelect";

const CustomerSearchManager = (props) => {
  const [queryString, setQueryString] = useState("");
  const [startUpdating, setStartUpdating] = useState(false);
  useEffect(() => {
    if (props.defaultValue) {
      props.selectCustomerByDefaultValue(props.defaultValue);
    } else {
      props.resetState();
    }
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      setStartUpdating(true);
    }, 1500);
    return () => {
      setStartUpdating(false);
      clearTimeout(debounce);
    };
  }, [queryString]);
  return (
    <CustomerSearchSelect
      {...props}
      queryString={queryString}
      setQueryString={setQueryString}
      startUpdating={startUpdating}
      setStartUpdating={setStartUpdating}
      selectCustomer={props.selectCustomer}
    />
  );
};

function mapStateToProps(state) {
  return { customerSearchManager: state.customerSearchManager };
}

export default connect(mapStateToProps, actions)(CustomerSearchManager);
