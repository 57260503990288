import React, { Fragment } from 'react';
import styled from 'styled-components';
import CustomerCreditsTable from '../components/CustomerCreditsTable';
import toPriceFormat from '../../../../utils/toPriceFormat';
import { Wrapper } from '../../../../components/FormElements/FormElements';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const Title = styled.div`
  font-weight: 600;
  margin: 0 45px 45px 0;
  font-size: 20px;
`;

const BalanceContainerWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-areas: 'a b c';
  grid-gap: 75px;
  @media screen and (max-width: 1232px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'a c';
  }
`;

const BalanceContainer = styled.div`
  grid-area: a;
`;

const SendReward = styled.div`
  color: ${(props) => (props.disabled ? '#919FD6' : props.theme.blue)};
  font-weight: 600;
  padding: 10px 0;
  grid-area: c;
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
  margin-right: auto;
`;

const BalanceWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 10px;
  padding: 5px;
`;

const BalanceName = styled.div`
  font-size: 14px;
`;

const BalanceValue = styled.div`
  font-weight: 600;
  font-size: 14px;
`;
const CreditWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CustomerCredits = (props) => {
  const {
    toggleSendCreditsModal,
    currentCreditsBalance,
    lifetimeCreditsBalance,
    customerGlobalCredits,
    customerDelete,
    customerDetails,
  } = props;

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      maxWidth: '453px',
      borderRadius: '7px',
      padding: '8px 9px 9px 12px',
      borderRadius: '7px',
      fontSize: '12px',
      marginTop: '30px',
      fontFamily: 'Montserrat',
      backgroundColor: 'rgba(0, 0, 0, 0.8);',
      color: '#ffffff',
    },
    button: {
      backgroundColor: '#2440ae',
      color: '#ffffff',
      maxHeight: '40px',
      width: '100%',
      borderRadius: '28px',
      fontSize: '14px',
      fontWeight: '600',
      fontStretch: 'normal',
      letterSpacing: 'normal',
      textAlign: 'right',
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3151d4',
      },
    },
  }));
  const classes = useStyles();

  function customerDeleteToggle() {
    if (!customerDelete) {
      toggleSendCreditsModal();
    }
  }
  return (
    <Fragment>
      <Wrapper>
        <Title data-test='title'>Customer Global Credit History</Title>
        <BalanceContainerWrapper>
          <BalanceContainer>
            <BalanceWrapper>
              <BalanceName data-test='label-currentCreditBalance'>
                Current Credit Balance
              </BalanceName>
              <BalanceValue data-test='value-currentCreditBalance'>
                {toPriceFormat(currentCreditsBalance)}
              </BalanceValue>
            </BalanceWrapper>
            <BalanceWrapper>
              <BalanceName data-test='label-lifetimeCreditAccured'>
                Lifetime Credit Accrued
              </BalanceName>
              <BalanceValue data-test='value-lifetimeCreditAccured'>
                {toPriceFormat(lifetimeCreditsBalance)}
              </BalanceValue>
            </BalanceWrapper>
          </BalanceContainer>
          {customerDelete ? (
            <Tooltip
              classes={classes}
              title='Action unavailable because this account is deleted.'
              placement='right'
            >
              <SendReward
                data-test='button-sendRewards'
                onClick={!customerDetails?.is_guest && customerDeleteToggle}
                disabled={customerDelete || customerDetails?.is_guest}
              >
                + SEND CREDITS
              </SendReward>
            </Tooltip>
          ) : (
            <SendReward
              data-test='button-sendRewards'
              onClick={!customerDetails?.is_guest && customerDeleteToggle}
              disabled={customerDetails?.is_guest}
            >
              + SEND CREDITS
            </SendReward>
          )}
        </BalanceContainerWrapper>
      </Wrapper>
      <CustomerCreditsTable
        customerGlobalCredits={customerGlobalCredits
          .slice(0, 10)
          .map((credit) => {
            return {
              ...credit,
              initial_amount: toPriceFormat(credit.initial_amount),
            };
          })}
      />
    </Fragment>
  );
};

export default CustomerCredits;
