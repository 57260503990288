import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import ArchivedBusinessLocationsList from '../../../../LocationListManager/components/ArchivedBusinessLocationsList';
import { initiateEditLocation } from '../../../../LocationDetailsManager/actions';
import * as businessActions from '../../../../LocationListManager/actions';
import { getBusinessLocations } from '../../../../LocationListManager/actions';
import BusinessWizardContainer from '../../BusinessWizardContainer';

const ArchivedLocationsContainer = ({
  archivedLocations,
  unArchiveBusiness,
  isEditingLocation,
  initiateEditLocation,
  getBusinessLocations,
  getArchivedBusinesses,
}) => {
  const isBusinessAdminRole =
    localStorage.getItem('role') === 'ROLE_BUSINESS_ADMIN';

  const isLoyaltyAdminRole =
    localStorage.getItem('role') === 'ROLE_LOYALTY_ADMIN';

  const handleBusinessLocationAction = (businessId) => {
    initiateEditLocation(businessId);
  };

  return isEditingLocation ? (
    <BusinessWizardContainer
      isBusinessAdminRole={isBusinessAdminRole}
      isLoyaltyAdminRole={isLoyaltyAdminRole}
    />
  ) : (
    <ArchivedBusinessLocationsList
      archivedLocations={archivedLocations}
      handleUnArchive={unArchiveBusiness}
      isBusinessAdminRole={isBusinessAdminRole}
      getBusinessLocations={getBusinessLocations}
      getArchivedBusinesses={getArchivedBusinesses}
      isLoyaltyAdminRole={isLoyaltyAdminRole}
      handleBusinessLocationAction={handleBusinessLocationAction}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    locationId: state.businessLocationDetails.businessId,
    archivedLocations: state.businessLocationsList.archivedBusinesses,
    isEditingLocation: state.businessLocationDetails.isEditingLocation,
  };
};

export default connect(mapStateToProps, {
  ...actions,
  ...businessActions,
  initiateEditLocation,
  getBusinessLocations,
})(ArchivedLocationsContainer);
