import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'merchant-portal-components';
import { Collapse } from '@material-ui/core';
import ServiceFeeLocationOverrideDetail from './ServiceFeeLocationOverrideDetail';
import DeleteOverrideConfirmationModal from './DeleteOverrideConfirmationModal';
import {
  StyledCard,
  HeaderWrapper,
  SectionTitle,
  SectionSubTitle,
  BusinessOverridesWrapper,
  IconText,
  OverrideFields,
  CollapseWrapper,
} from './Styles/ServiceFeeDetailStyles';

const ServiceFeeLocationOverrides = (props) => {
  const {
    editingFee,
    selectedBusinessLocationsOfMerchant,
    orderTypesList,
    allFeeOverrides,
    selectedFeeOverride,
    EmptyOverride,
    createFeeOverrides,
    getSpecificOverride,
    getAllOverrides,
    deleteSpecificOverride,
  } = props;
  const [openOverrideDetailModal, setOpenOverrideDetailModal] = useState(false);
  const [openDeleteOverrideConfirmation, setOpenDeleteOverrideConfirmation] =
    useState(false);
  const [selectedBusinessName, setSelectedBusinessName] = useState(null);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleOpenOverrideDetailModal = () => {
    EmptyOverride();
    setSelectedBusinessName(null);
    setOpenOverrideDetailModal(true);
  };
  const handleCloseOverrideDetailModal = () => {
    setSelectedBusinessName(null);
    setOpenOverrideDetailModal(false);
  };
  const handleEditOverride = (businessId, businessName) => {
    getSpecificOverride(editingFee.id, businessId);
    setSelectedBusinessName(businessName);
    setOpenOverrideDetailModal(true);
  };
  // delete override
  const handleDeleteOverride = (businessId) => {
    setSelectedBusinessId(businessId);
    setOpenDeleteOverrideConfirmation(true);
  };
  const handleConfirmDeleteOverride = (businessId) => {
    deleteSpecificOverride(editingFee.id, businessId);
    setOpenDeleteOverrideConfirmation(false);
    // getAllOverrides(editingFee.id);
  };
  const handleCloseDeleteOverrideModal = () => {
    setOpenDeleteOverrideConfirmation(false);
  };
  const getAllOverridesCallback = useCallback(() => {
    getAllOverrides(editingFee.id);
  }, [openOverrideDetailModal, openDeleteOverrideConfirmation]);
  useEffect(() => {
    getAllOverridesCallback();
  }, [getAllOverridesCallback]);
  return (
    <div>
      <StyledCard>
        <CollapseWrapper>
          <HeaderWrapper padding="0" margin="0 0 15px 0">
            <div style={{ marginBottom: '12px' }}>
              <SectionTitle display="inline">Location Overrides</SectionTitle>
              <SectionSubTitle display="inline" margin="0 0 0 5px">
                (Optional)
              </SectionSubTitle>
            </div>
            <SectionSubTitle>
              Override this Service Fee for specific locations
            </SectionSubTitle>
          </HeaderWrapper>
          <div
            style={{ textAlign: 'right', fontWeight: '200', cursor: 'pointer' }}
          >
            <i
              className={
                isCollapsed
                  ? 'fal fa-angle-down fa-2x'
                  : 'fal fa-angle-up fa-2x'
              }
              onClick={() => setIsCollapsed((prevState) => !prevState)}
            ></i>
          </div>
        </CollapseWrapper>
        <Collapse in={!isCollapsed}>
          <Button onClick={handleOpenOverrideDetailModal}>Add Override</Button>
          {allFeeOverrides.map((businessOverride, index) => (
            <BusinessOverridesWrapper key={index}>
              <div>
                <SectionTitle>
                  {
                    selectedBusinessLocationsOfMerchant.filter(
                      (business) => business.id === businessOverride.business_id
                    )[0]?.display_name
                  }
                </SectionTitle>
                <OverrideFields>
                  {businessOverride.config.type === 'percent'
                    ? `Percentage of Subtotal - ${businessOverride.config.fee_amount}%`
                    : `Fixed Dollar Amount - $${businessOverride.config.fee_amount}`}
                </OverrideFields>
                {(businessOverride.config.min_fee_amount ||
                  businessOverride.config.min_fee_amount === 0) && (
                  <OverrideFields>{`Minimum Service Fee - ${businessOverride.config.min_fee_amount}`}</OverrideFields>
                )}
                {(businessOverride.config.max_fee_amount ||
                  businessOverride.config.max_fee_amount === 0) && (
                  <OverrideFields>{`Maximum Service Fee - ${businessOverride.config.max_fee_amount}`}</OverrideFields>
                )}
                {(businessOverride.config.threshold ||
                  businessOverride.config.threshold === 0) && (
                  <OverrideFields>{`Service Fee Threshold - Any subtotal above $${businessOverride.config.threshold} will have a Service Fee of %${businessOverride.config.threshold_fee}`}</OverrideFields>
                )}
              </div>
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <div
                  style={{ cursor: 'pointer', color: '#191818' }}
                  onClick={() =>
                    handleEditOverride(
                      businessOverride.business_id,
                      selectedBusinessLocationsOfMerchant.filter(
                        (business) =>
                          business.id === businessOverride.business_id
                      )[0].display_name
                    )
                  }
                >
                  <IconText>Edit</IconText>
                  <i className="fas fa-pencil fa-sm"></i>
                </div>
              </div>
              <div
                style={{
                  textAlign: 'right',
                }}
              >
                <div
                  style={{ cursor: 'pointer', color: '#191818' }}
                  onClick={() =>
                    handleDeleteOverride(businessOverride.business_id)
                  }
                >
                  <IconText>Delete</IconText>
                  <i className="fas fa-trash fa-sm"></i>
                </div>
              </div>
            </BusinessOverridesWrapper>
          ))}
        </Collapse>
      </StyledCard>
      {openOverrideDetailModal && (
        <ServiceFeeLocationOverrideDetail
          open={openOverrideDetailModal}
          onClose={handleCloseOverrideDetailModal}
          editingFee={editingFee}
          selectedBusinessLocationsOfMerchant={
            selectedBusinessLocationsOfMerchant
          }
          orderTypesList={orderTypesList}
          createFeeOverrides={createFeeOverrides}
          selectedBusinessName={selectedBusinessName}
          selectedFeeOverride={selectedFeeOverride}
          getAllOverrides={getAllOverrides}
        />
      )}
      {openDeleteOverrideConfirmation && (
        <DeleteOverrideConfirmationModal
          businessId={selectedBusinessId}
          open={openDeleteOverrideConfirmation}
          onCancel={handleCloseDeleteOverrideModal}
          onConfirm={handleConfirmDeleteOverride}
        />
      )}
    </div>
  );
};
export default ServiceFeeLocationOverrides;
