import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '../../../components/Icon/Icon';

const SearchAndButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 0px 30px 30px 30px;
`;

const TextSearch = styled.div`
  padding: 0 37px 37px 0px;
`;

function SearchUser(props) {
  return (
    <SearchAndButtonWrapper>
      <TextSearch>
        <TextField
          value={props.searchString}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon className="fal fa-search" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <Icon
                  className="fas fa-times-circle"
                  style={{ color: '#193053' }}
                  onClick={props.handleClearSearch}
                />
              </InputAdornment>
            ),
          }}
          style={{ width: '100%' }}
          variant="outlined"
          type="text"
          placeholder="Search Users"
          onChange={(ev) => props.onSearchUsers(ev)}
        />
      </TextSearch>
      <Button style={{ height: '60px' }} onClick={() => props.open()}>
        Add New User
      </Button>
    </SearchAndButtonWrapper>
  );
}

export default SearchUser;
