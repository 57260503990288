import { formatErrorMessage } from "../../../utils/formatErrorMessage";
const copyToClipboard = (item, createAlert) => {
  let textArea = document.createElement('textarea');
  textArea.value = item.url;
  document.body.appendChild(textArea);
  textArea.select();

  try {
    document.execCommand('copy');
    createAlert({
      type: 'success',
      message: 'Copied ' + item.name + ' successfully'
    });
  } catch (error) {
    createAlert({
      type: 'error',
      message: formatErrorMessage(error) || 'Not copied!',
      dangerouslySetInnerHTML: true,
    });
  }
  document.body.removeChild(textArea);
};

export default copyToClipboard;
