import React from 'react';
import { connect } from 'react-redux';
import ComboInformationForm from '../components/ComboInformationForm';
import { handleComboInformationSubmit } from '../../../combosAction';

const ComboInformationFormContainer = ({
  initValues,
  handleComboInformationSubmit,
  submitBasicInformationIsRequesting,
  combosList,
  taxCategoryList,
  showTax,
}) => {
  return (
    <div>
      <ComboInformationForm
        initValues={initValues}
        handleComboInformationSubmit={handleComboInformationSubmit}
        submitBasicInformationIsRequesting={submitBasicInformationIsRequesting}
        combosList={combosList}
        taxCategoryList={taxCategoryList.map((taxCategory) => ({
          value: taxCategory.tax_category_id,
          label: taxCategory.description,
        }))}
        showTax={showTax}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  submitBasicInformationIsRequesting:
    state.combosReducer.submitBasicInformationIsRequesting,
  combosList: state.combosReducer.combosList,
  taxCategoryList: state.fullReducer.taxCategoryList,
  showTax:
    state.accountReducer.appMetaData.configuration
      .smoothpay_integration_enabled,
});

export default connect(mapStateToProps, { handleComboInformationSubmit })(
  ComboInformationFormContainer
);
