export const GET_OFFERS_ENGINE_REQUEST = 'GET_OFFERS_ENGINE_REQUEST';
export const GET_OFFERS_ENGINE_SUCCESS = 'GET_OFFERS_ENGINE_SUCCESS';
export const GET_OFFERS_ENGINE_FAILURE = 'GET_OFFERS_ENGINE_FAILURE';
export const SHOW_OFFER_ENGINE_INDEX = 'SHOW_OFFER_INDEX';
export const TOGGLE_OFFER_ENGINE_REQUEST = 'TOGGLE_OFFER_REQUEST';
export const TOGGLE_OFFER_ENGINE_SUCCESS = 'TOGGLE_OFFER_SUCCESS';
export const TOGGLE_OFFER_ENGINE_FAILURE = 'TOGGLE_OFFER_FAILURE';
export const INITIATE_CREATE_OFFER_ENGINE = 'INITIATE_CREATE_OFFER';
export const INITIATE_CREATE_EDIT_OFFER_ENGINE =
  'INITIATE_CREATE_EDIT_OFFER_ENGINE';
export const CREATE_OFFER_ENGINE_REQUEST = 'CREATE_OFFER_REQUEST';
export const CREATE_OFFER_ENGINE_SUCCESS = 'CREATE_OFFER_SUCCESS';
export const INITIATE_EDIT_OFFER_ENGINE = 'INITIATE_EDIT_OFFER';
export const SEARCH_CUSTOMER_ENGINE_REQUEST = 'SEARCH_CUSTOMER_REQUEST';
export const SEARCH_CUSTOMER_ENGINE_SUCCESS = 'SEARCH_CUSTOMER_SUCCESS';
export const CLEAR_CUSTOMER_ENGINE_SEARCH = 'CLEAR_CUSTOMER_SEARCH';
export const GET_CUSTOMER_DETAIL_ENGINE_REQUEST = 'GET_CUSTOMER_DETAIL_REQUEST';
export const CANCEL_CREATE_OFFER_ENGINE = 'CANCEL_CREATE_OFFER';
export const CREATE_OFFER_ENGINE_FAILURE = 'CREATE_OFFER_FAILURE';
export const SORT_OFFER_LIST = 'SORT_OFFER_LIST';
export const EDIT_OFFER_ENGINE_REQUEST = 'EDIT_OFFER_REQUEST';
export const EDIT_OFFER_ENGINE_SUCCESS = 'EDIT_OFFER_SUCCESS';
export const EDIT_OFFER_ENGINE_FAILURE = 'EDIT_OFFER_FAILURE';
export const GET_PROMO_REQUEST = 'GET_PROMO_REQUEST';
export const GET_PROMO_SUCCESS = 'GET_PROMO_SUCCESS';
export const GET_PROMO_FAILURE = 'GET_PROMO_FAILURE';

export const TOGGLE_OFFER_ENGINE_AVAILABILITY_SUCCESS =
  'TOGGLE_OFFER_ENGINE_AVAILABILITY_SUCCESS';

export const RESET_OFFER_ENGINE_STATE = 'RESET_OFFER_ENGINE_STATE';
export const UPDATE_SORTED_OFFERS_ENGINE_LIST = 'UPDATE_SORTED_OFFERS_LIST';
export const UPDATE_PROMO_REQUEST_PARAMS = 'UPDATE_PROMO_REQUEST_PARAMS';
export const UPDATE_OFFERS_REQUEST_PARAMS = 'UPDATE_OFFERS_REQUEST_PARAMS';
export const GET_OFFERS_POSITIONS = 'GET_OFFERS_POSITIONS';
