import React from 'react';
import styled from 'styled-components';
import { Button } from 'merchant-portal-components';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
export const SectionTitle = styled.div`
  width: 100%;
  display: ${(props) => props.display || 'block'};
  margin: ${(props) => props.margin || '0px 0 12px 0'};
  font-family: 'Montserrat';
  font-size: ${(props) => props.fontSize || '21px'};
  font-weight: ${(props) => props.fontWeight || '600'};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: ${(props) => props.color || '#191818'};
  text-align: ${(props) => props.textAlign || 'left'};
`;
export const SectionSubTitle = styled.div`
  display: ${(props) => props.display || 'block'};
  font-family: 'Montserrat';
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin: ${(props) => props.margin || '0'};
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 5px 15px 20px 0;
`;
export const Left = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  text-align: left;
`;
export const Right = styled.div`
  margin: 0;
  text-align: right;
`;

export const CancelButton = styled.button`
  height: 20px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2440ae;
  cursor: pointer;
  border: none;
  background-color: inherit;
`;
export default function MBConfirmationModal({
  open,
  handleClose,
  handleConfirm,
  startDate,
}) {
  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='sm'
        onClose={() => handleClose(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle id='alert-dialog-title'>
          <i
            className='fa fa-times'
            style={{
              fontWeight: '200',
              float: 'right',
              margin: '0',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          ></i>
        </DialogTitle>
        <DialogContent>
          <Typography variant='h6' style={{ margin: '15px' }}>
            Transaction reports can be generated for a single day for instant
            viewing. To generate a report for up to a month, please use the
            "Export Report" button.
          </Typography>
          <ButtonWrapper>
            <Button onClick={handleConfirm}>
              {`Get Instant Report for ${moment(startDate).format(
                'DD/MM/YYYY'
              )}`}
            </Button>
          </ButtonWrapper>
        </DialogContent>
      </Dialog>
    </div>
  );
}
