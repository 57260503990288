import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import TextFieldPOS from '../../components/TextFieldPOS';
import ToogleFieldPOS from '../../components/ToogleFieldPOS';
import Pagination from '../../../../../components/Pagination/Pagination';
import CreatableSelect from '../../../../../components/Select/CreatableSelect';
import StickyButtons from '../../../../../components/StickyButtons';
import ConfirmUnsavedChangesModal from '../../components/ConfirmUnsavedChangesModal';
import { discountOptionsModified } from '../../utils/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
  MainDiv,
  PaginationWrapper,
  Body,
  DiscountDiv,
  DiscountNameCell,
  POSDiv,
  DiscountHeader,
  POSHeader,
  POSFieldWrapper,
  POSValueCell,
  LineDivider,
  ToogleFieldPOSWrapper,
  SelectDiv,
} from '../../styles/styles';

const ComboItemGroupMappingTable = ({
  comboItemsGroup,
  meta,
  dirty,
  handleReset,
  submitPOSDiscountValuesCombos,
  values,
  initialValues,
  type,
  selectedValuePOS,
  posDiscountsMappingFields,
  posDiscountsFieldsNum,
  selectedComboId,
  updateComboItemGroupPOSMapping,
  errors,
}) => {
  const [selectedItemsGroupPOSValues, setSelectedItemsGroupPOSValues] =
    useState(initialValues);
  const [selectedItemsGroupNames, setSelectedItemsGroupNames] =
    useState(comboItemsGroup);
  const [selectedItemGroupIndex, setSelectedItemGroupIndex] = useState(null);
  const updateSelectedItemsGroup = useCallback(() => {
    setSelectedItemsGroupPOSValues(initialValues);
    setSelectedItemsGroupNames(comboItemsGroup);
  }, [initialValues, comboItemsGroup]);
  useEffect(() => {
    updateSelectedItemsGroup();
  }, [updateSelectedItemsGroup]);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [newValue, setNewValue] = useState(null);
  const [newSearch, setNewSearch] = useState(null);
  const [newPage, setNewPage] = useState(null);

  const handleChange = (itemGroup) => {
    if (!itemGroup) {
      setSelectedItemsGroupPOSValues(initialValues);
      setSelectedItemsGroupNames(comboItemsGroup);
      setSelectedItemGroupIndex(null);
    } else {
      // POS Valuse
      const itemGroupId = itemGroup?.id;
      const selectedIndex = values.mappings.findIndex(
        (item) => item.combo_item_group_id === itemGroupId
      );
      setSelectedItemGroupIndex(selectedIndex);
      const newMappings = values.mappings.filter(
        (item) => item.combo_item_group_id === itemGroupId
      );
      const newValues = { mappings: newMappings };
      setSelectedItemsGroupPOSValues(newValues);
      // POS Names
      const newComboItemsGroup = comboItemsGroup.filter(
        (item) => item.id === itemGroupId
      );
      setSelectedItemsGroupNames(newComboItemsGroup);
    }
  };
  return (
    <MainDiv>
      <SelectDiv>
        <Autocomplete
          id='combo-box-demo'
          options={comboItemsGroup}
          getOptionLabel={(option) => option.label}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search for item groups'
              variant='outlined'
            />
          )}
        />
      </SelectDiv>

      <Body>
        <DiscountDiv>
          <DiscountHeader>
            <div>{type.charAt(0).toUpperCase() + type.slice(1)} Name</div>
          </DiscountHeader>
          <LineDivider />
          {selectedItemsGroupNames.map((combo, index) => {
            return (
              <div key={combo.id}>
                <DiscountNameCell>
                  {selectedItemsGroupNames[index].label}
                </DiscountNameCell>
              </div>
            );
          })}
        </DiscountDiv>

        <POSDiv>
          <POSFieldWrapper fieldsNumber={posDiscountsFieldsNum}>
            {Object.keys(posDiscountsMappingFields).map((item, key) => {
              return <POSHeader key={key}>{item}*</POSHeader>;
            })}
            <LineDivider fieldsNumber={posDiscountsFieldsNum} />
            <FieldArray
              render={(arrayHelpers) => (
                <Fragment>
                  {selectedItemsGroupPOSValues?.mappings?.map((item, index) => (
                    <POSValueCell key={index}>
                      <TextFieldPOS
                        name={`mappings[${
                          selectedItemGroupIndex || index
                        }].pos_component_id`}
                        value={item.pos_component_id}
                        setFieldValue={arrayHelpers.form.setFieldValue}
                      />
                    </POSValueCell>
                  ))}
                  <StickyButtons
                    isVisible={dirty && Object.keys(errors).length === 0}
                    handleDiscard={() => handleReset()}
                    handleSave={() => {
                      setSelectedItemsGroupPOSValues(initialValues);
                      setSelectedItemsGroupNames(comboItemsGroup);
                      setSelectedItemGroupIndex(null);
                      updateComboItemGroupPOSMapping(selectedComboId, values);
                      setNewValue('');
                    }}
                    title='Combo POS Mapping'
                  />
                </Fragment>
              )}
            />
          </POSFieldWrapper>
        </POSDiv>
      </Body>
      {isConfirmModal && (
        <ConfirmUnsavedChangesModal
          toggleConfirmModal={() => setIsConfirmModal(false)}
          handleSave={() => {
            setIsConfirmModal(false);
            submitPOSDiscountValuesCombos(
              values,
              type,
              initialValues,
              selectedValuePOS,
              newValue,
              newSearch,
              meta.page_size,
              newPage ? newPage : meta.page_number,
              posDiscountsMappingFields
            );
            setNewSearch(null);
            setNewPage(null);
          }}
        />
      )}
    </MainDiv>
  );
};

export default ComboItemGroupMappingTable;
