export const handleRemoveTime = (setOpenTime2, setCloseTime2) => {
  setOpenTime2(null);
  setCloseTime2(null);
};

export const handleRemoveOrderingTime = (
  setOpenOrderingTime2,
  setCloseOrderingTime2
) => {
  setOpenOrderingTime2(null);
  setCloseOrderingTime2(null);
};
